// (C) Copyright 2016-2024 Hewlett Packard Enterprise Development LP

import * as c from '../../routes/consts.js';
import * as mb from '../../containers/generic/MetaBuilder';
import MetaBuilder from '../../containers/generic/MetaBuilder';
import ItemViewContainer from '../../containers/generic/ItemViewContainer';
import CreateViewContainer from '../../containers/generic/CreateViewContainer';
import { STATUS_CRITICAL, STATUS_OK, STATUS_WARNING } from '../../lib/consts';
import HealthSummary from '../../components/HealthSummary';
import { ArrayAlertListView } from './alerts';

const BASE_URL = c.URL_ARRAYS;

const settings = {
  authUrl: BASE_URL, // used to filter create/trash icons from the view; using the defined roledef permissions mappings to this auth URL entry
  homeUrl: c.makeSecUrl(BASE_URL), // homepage for this list view (e.g. /s/hosters/:pid/) is where this view is located; for return from create / item views
  baseUrl: BASE_URL, // base url to be used for creating all associated URLS for this reasource, e.g. pageItem, pageCreate, restUrl, restItemUrl
  meta: (props) => metabuilder(props),
};

function metaCreate(props) {
  const m = new MetaBuilder(props);

  const dsPods = m.newDataSource(c.URL_PODS);
  const dsRacks = m.newDataSource(c.URL_RACKS);
  const dsArrayTypes = m.newDataSource(c.URL_ARRAYTYPES);

  m.addColumn('rackID', 'RackID')
    .Input()
    .Required()
    .DataXform(dsRacks, (json) => json.map((t) => ({ id: t.id, name: t.name })))
    .DropDown()
    .Width(mb.SMALL);

  m.addColumn('podID', 'Pod')
    .Input()
    .Required()
    .DataXform(dsPods, (json) => json.map((t) => ({ id: t.id, name: t.name })))
    .DropDown()
    .Width(mb.SMALL);

  m.addColumn('acs', 'Array Connection String')
    .Input()
    .Required()
    .Width(mb.LARGE);

  m.addColumn('driver', 'Array Type')
    .Input()
    .Required()
    .DropDown()
    .DataXform(dsArrayTypes, (json) =>
      json.map((t) => ({ id: t.id, name: t.name })),
    )
    .Width(mb.XXSMALL);

  m.addColumn('name', 'Array Name').Input().Required().Width(mb.SMALL);

  return m;
}

const DS_STORAGE_POOLS = 'capacitypools';
const DS_STORAGE_INTERFACES = 'storageinterfaces';
const DS_VOLUME_FLAVORS = 'volumeflavors';

function metabuilder(props) {
  const m = new MetaBuilder(props);
  const id = m.view.prop('itemId', false);

  m.newDataSource(c.URL_ARRAYS)
    .Item(id)
    .OnLoad((json) => {
      m.view.initForm(json.data);
    });

  m.addColumn('name', 'Name');
  m.addColumn('id', 'ID');
  m.addColumn('driver', 'Driver');
  m.addColumn('acs', 'ACS');
  m.addColumn('lastSeen', 'Last seen');

  const ds = m
    .newDataSource(c.URL_STORAGE_POOLS, DS_STORAGE_POOLS)
    .Filter((t) => t.externalArrayID === id);

  const vf = m.newDataSource(c.URL_VOLUME_FLAVOR, DS_VOLUME_FLAVORS);

  // Health Summary section
  m.addSection('Health Summary', 'health_status').MaxWidth(mb.LARGE).Expanded();

  m.addColumn('health_status', 'Health').Custom(HealthSummary);

  // // CapacityPools section
  m.addSection('Capacity Pools')
    .MaxWidth(mb.LARGE)
    .OverviewText(
      'Capacity Pools that are available on the array for volume provision.',
    );

  const capTable = m
    .addInputTable('capacitypools', 'Capacity Pools')
    .DataXform(ds, (json) => json);

  capTable
    .addField('freeSpace', 'Free Space')
    .ReadOnly()
    .CellXform(
      (rowData) => `${(rowData.freeSpace / 1024 / 1024).toFixed()} GiB`,
    );

  capTable
    .addField('capacity', 'Total Capacity')
    .ReadOnly()
    .CellXform(
      (rowData) => `${(rowData.capacity / 1024 / 1024).toFixed()} GiB`,
    );

  capTable
    .addField('volumes', 'Volume Count')
    .ReadOnly()
    .CellXform((rowData) => rowData.totalVolumes);

  capTable
    .addField('latency', 'Latency')
    .ReadOnly()
    .CellXform((rowData) => rowData.latency.replace('Latency', ''));

  capTable
    .addField('volumeFlavors', 'Flavors')
    .ReadOnly()
    .CellXform((rowData) => {
      const names = [];
      const data = vf.Data();
      if (rowData.volumeFlavors != null) {
        for (let i = 0; i < rowData.volumeFlavors.length; i++) {
          const volf = data.find((t) => t.id === rowData.volumeFlavors[i]);
          if (volf !== undefined) {
            names.push(volf.name);
          }
        }
      }
      return names;
    });

  // Interfaces section
  const ns = m
    .newDataSource(c.URL_STORAGE_INTERFACES, DS_STORAGE_INTERFACES)
    .Filter((t) => t.externalArrayID === id);

  m.addSection('Network Interfaces')
    .MaxWidth(mb.LARGE)
    .OverviewText('Network Interfaces on which the array may expose volumes.');

  const netTable = m
    .addInputTable('storageinterfaces', 'Network Interfaces')
    .DataXform(ns, (json) => json);

  netTable
    .addField('arrayNetworkInterfaceID', 'Array NIC ID')
    .ReadOnly()
    .CellXform((rowData) => rowData.arrayNetworkInterfaceID);

  netTable
    .addField('ipAddress', 'IP Address')
    .ReadOnly()
    .CellXform((rowData) => rowData.ipAddress);

  netTable
    .addField('netmask', 'Netmask')
    .ReadOnly()
    .CellXform((rowData) => rowData.netmask);

  netTable
    .addField('gateway', 'Gateway')
    .ReadOnly()
    .CellXform((rowData) => rowData.gateway);

  // Alerts section
  m.addSection('Alerts').Table(ArrayAlertListView).Expanded();

  return m;
}

export { default as ArrayListView } from './ArrayListView';

export const ArrayItemView = ItemViewContainer({
  ...settings,
  meta: (props) => metabuilder(props),
  title: 'External Storage Array',
});

export const ArrayCreateView = CreateViewContainer({
  ...settings,
  meta: (props) => metaCreate(props),
  title: 'Add Array',
});
