// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

import React from 'react';
import { URL_MACHINESIZES } from '../../routes/consts';
import ListPageWithNav from '../../containers/generic/ListPageWithNav';
import { useGetMachineSizes } from '../../services/machineSizes';
import { NAV_COMPUTE } from '../../components/HybridNav/consts.js';

const MachineSizeListView = () => (
  <ListPageWithNav
    navSection={NAV_COMPUTE}
    actions={{
      allowCreate: 'Create machine size',
    }}
    path={URL_MACHINESIZES}
    sort={{
      property: 'name',
      direction: 'asc',
    }}
    title='Machine sizes'
    useGetData={useGetMachineSizes}
  />
);

export default MachineSizeListView;
