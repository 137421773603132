// (C) Copyright 2023-2024 Hewlett Packard Enterprise Development LP

import * as c from '../../routes/consts';
import MetaBuilder, { LARGE } from '../../containers/generic/MetaBuilder';
import ItemViewContainer from '../../containers/generic/ItemViewContainer';
import HealthStatus from '../../components/HealthStatus';
import { AllocatedCapacity } from './AllocatedCapacity';
import { LABEL_STORAGE_POOLS } from '../../components/HybridNav/consts.js';

const BASE_URL = c.URL_STORAGE_POOLS;

function metabuilder(props) {
  const mb = new MetaBuilder(props);
  const itemId = mb.view.prop('itemId', false);

  mb.newDataSource(c.URL_STORAGE_POOLS)
    .Item(itemId)
    .OnLoad((json) => {
      mb.view.initForm(json.data);
    });

  const dsStorageGroups = mb.newDataSource(c.URL_STORAGE_GROUPS);
  const dsStorageSystems = mb
    .newDataSource(c.URL_STORAGE_SYSTEMS)
    .Filter(({ id }) =>
      mb.view.formDefaultTo('storage_system_id_list', []).includes(id),
    );
  const dsVolumeFlavors = mb
    .newDataSource(c.URL_VOLUME_FLAVOR)
    .Filter(({ id }) =>
      mb.view.formDefaultTo('volumeFlavors', []).includes(id),
    );

  mb.addColumn('capacityPoolName', 'Name');

  mb.addColumn('storage_type', 'Storage type');

  mb.addColumn('totalVolumes', 'Volume count');

  mb.addColumn('id', 'ID');

  mb.addColumn('used', 'Allocated capacity').Custom(AllocatedCapacity);

  mb.addSection('Volume flavors').MaxWidth(LARGE);

  const volumeFlavorsTable = mb
    .addInputTable('volumeFlavors', 'Volume flavors')
    .DataXform(dsVolumeFlavors, (json) => json);

  volumeFlavorsTable
    .addField('name', 'Name')
    .ReadOnly()
    .CellXform((rowData) => rowData.name);

  volumeFlavorsTable
    .addField('id', 'ID')
    .ReadOnly()
    .CellXform((rowData) => rowData.id);

  mb.addSection('Storage group').MaxWidth(LARGE);

  mb.addColumn('storage_group_id', 'Name').FieldXform((storageGroupId) => {
    const storageGroup = dsStorageGroups
      .Data()
      ?.find(({ id }) => id === storageGroupId);

    return storageGroup?.name || '';
  });

  mb.addColumn('storage_group_id', 'Management IP address').FieldXform(
    (storageGroupId) => {
      const storageGroup = dsStorageGroups
        .Data()
        ?.find(({ id }) => id === storageGroupId);

      return storageGroup?.management_ip || '';
    },
  );

  mb.addSection('Storage systems').MaxWidth(LARGE);

  const storageSystemsTable = mb
    .addInputTable('storage_system_id_list', 'Storage systems')
    .DataXform(dsStorageSystems, (json) => json);

  storageSystemsTable
    .addField('name', 'Name')
    .ReadOnly()
    .CellXform((rowData) => rowData.name);

  storageSystemsTable
    .addField('health_status', 'Health')
    .ReadOnly()
    .CellXform((rowData) => (
      <HealthStatus status={rowData.health_status} showLabel />
    ));

  return mb;
}

const settings = {
  authUrl: BASE_URL,
  homeUrl: c.makeSecUrl(BASE_URL),
  homeLabel: LABEL_STORAGE_POOLS,
  baseUrl: BASE_URL,
  meta: (props) => metabuilder(props),
};

export default ItemViewContainer({
  ...settings,
  title: 'Storage pool',
});
