// (C) Copyright 2022-2024 Hewlett Packard Enterprise Development LP

import React from 'react';

import ListPageWithNav from '../../containers/generic/ListPageWithNav';
import { URL_FILES } from '../../routes/consts';
import { useGetFiles } from '../../services/files';
import { NAV_TRACKING } from '../../components/HybridNav/consts.js';

const FileListView = () => (
  <ListPageWithNav
    navSection={NAV_TRACKING}
    actions={{}}
    path={URL_FILES}
    sort={{
      property: 'name',
      direction: 'asc',
    }}
    title='Files'
    useGetData={useGetFiles}
    limitRecords={true}
  />
);

export default FileListView;
