// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

import { useQuery } from 'react-query';

import { get } from '../lib/rest';
import { makeRestUrl, URL_ORGANIZATIONS } from '../routes/consts.js';
import auth from '../lib/auth';
import { renderLink } from './helper';
import { toDateTime } from '../lib/formatters';

const columns = [
  {
    header: 'Name',
    isDefault: true,
    property: 'name',
    render: renderLink({
      base: URL_ORGANIZATIONS,
    }),
  },
  {
    header: 'ID',
    property: 'id',
  },
  {
    header: 'Created',
    isDefault: true,
    property: 'created',
    render: ({ created }) => toDateTime(created),
  },
  {
    header: 'Modified',
    isDefault: true,
    property: 'modified',
    render: ({ modified }) => toDateTime(modified),
  },
  {
    header: 'Auth method',
    isDefault: true,
    property: 'auth_method',
    render: ({ auth_method: authMethod }) =>
      authMethod === 'greenlake' ? 'Greenlake IAM' : '',
  },
  {
    header: 'Tenant',
    isDefault: true,
    property: 'tenant_id',
  },
];

export const getOrganizations = async () => {
  const isProjectRole = auth.activeRoleDef?.id.startsWith('project');

  if (!auth.isIAM() && !isProjectRole) {
    const response = await get(makeRestUrl(URL_ORGANIZATIONS));

    return response.json();
  }
};

export const useGetOrganizations = () => {
  const { data, error, isLoading, isError } = useQuery(
    'organizations',
    getOrganizations,
  );

  return {
    columns: columns.map((column) => ({
      ...column,
      columnName: column.property,
      displayName: column.header,
    })),
    data,
    error,
    isLoading,
    isError,
  };
};
