// (C) Copyright 2020-2024 Hewlett Packard Enterprise Development LP

import React from 'react';
import PropTypes from 'prop-types';
import { Box, Menu } from 'grommet';
import { Desktop, FormDown } from 'grommet-icons';

const ConsoleButton = ({ a11yTitle, disabled, items }) => (
  <Menu
    a11yTitle={a11yTitle || 'Open serial console window to this machine.'}
    disabled={disabled}
    dropAlign={{
      right: 'right',
      top: 'bottom',
    }}
    items={items}
  >
    <Box pad='xsmall' direction='row' align='center'>
      <Desktop />
      <FormDown size='small' />
    </Box>
  </Menu>
);

ConsoleButton.propTypes = {
  disabled: PropTypes.bool,
  children: PropTypes.node,
};

export default ConsoleButton;
