// (C) Copyright 2020-2024 Hewlett Packard Enterprise Development LP

import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Meter, Stack, Text, ThemeContext } from 'grommet';

export const Doughnut = ({ data }) => {
  const theme = useContext(ThemeContext);
  const [active, setActive] = useState();
  const [label, setLabel] = useState();
  const colors = theme.dashboard.fiveColor;

  if (!data?.values) {
    return <Text>Loading</Text>;
  }

  const values = data.values.map((item, index) => ({
    ...item,
    color: item.color || colors[index % colors.length],
    highlight: label === item.label,
    onHover: (over) => {
      setActive(over ? item.value : 0);
      setLabel(over ? item.label : undefined);
    },
  }));

  return (
    <Stack anchor='center' fill>
      <Meter
        type='circle'
        background='background-contrast'
        size='full'
        values={values}
        thickness='large'
      />
      <Box align='center'>
        <Box direction='row' align='center' pad={{ bottom: 'xsmall' }}>
          <Text size='xxlarge' weight='bold'>
            {active || (values[1] ? values[1].value : '')}
          </Text>
        </Box>
        <Text>{label || (values[1] ? values[1].label : '')}</Text>
      </Box>
    </Stack>
  );
};

Doughnut.propTypes = {
  data: PropTypes.shape({
    total: PropTypes.number,
    values: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.number,
        color: PropTypes.string,
      }),
    ),
  }),
};
