// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, DataTable, Grid, Text } from 'grommet';
import { Edit, FormAdd, Trash } from 'grommet-icons';
import styled from 'styled-components';
import { AddCustomNetworkConnectionsPanel } from './AddCustomNetworkConnectionsPanel';
import { isEmpty } from '../../../utils';
import { Link, Modal } from '../../../components';
import { makeSecItemUrl, setId } from '../../../routes/consts';

const Table = styled.div`
  padding: 24px 0;

  table {
    border-spacing: 0;

    th,
    td {
      padding: 8px 24px;

      &.description {
        width: 180px;
      }

      &.redundancy-mode {
        width: 190px;
      }

      &.buttons {
        text-align: center;
      }
    }

    th {
      border-bottom: 1px solid #999999;
      text-align: left;
    }

    td {
      vertical-align: top;

      > * {
        min-height: 40px;
      }
    }
  }
`;

const DetailModal = ({ data, setModal }) => {
  if (!data) {
    return null;
  }

  const { cNetworks, description, lag, name, ports } = data;
  const networks = !isEmpty(cNetworks) ? cNetworks : [];

  const handleClose = () => setModal(null);

  return (
    <Modal show onHide={handleClose} size='large'>
      <Modal.Header onDismiss={handleClose}>
        <Modal.Title>Custom Network Connection</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Grid columns={['small', 'auto']} gap='medium'>
          <Box>
            <Text weight={600}>Name</Text>
          </Box>
          <Box>
            <Text>{name}</Text>
          </Box>
          <Box>
            <Text weight={600}>Description</Text>
          </Box>
          <Box>
            <Text>{description}</Text>
          </Box>
          <Box>
            <Text weight={600}>Switch Ports</Text>
          </Box>
          <Box gap='medium'>
            {ports.map(({ networkSwitch, portNames }, i) => (
              <Grid key={i} columns={['xxsmall', 'auto']} gap='small'>
                <Text weight={600}>Name</Text>
                <Text>{networkSwitch?.name}</Text>
                <Text weight={600}>Ports</Text>
                <Box>
                  {portNames.map((port) => (
                    <Text key={port}>{port}</Text>
                  ))}
                </Box>
              </Grid>
            ))}
          </Box>
          <Box>
            <Text weight={600}>Redundancy Mode</Text>
          </Box>
          <Box>
            <DataTable
              columns={[
                {
                  property: 'type',
                  header: <Text>Type</Text>,
                },
                {
                  property: 'lacp_interval',
                  header: <Text>LACP Interval</Text>,
                },
                {
                  property: 'lacp_mode',
                  header: <Text>LACP Mode</Text>,
                },
                {
                  property: 'lacp_priority',
                  header: <Text>LACP Priority</Text>,
                },
              ]}
              data={[lag]}
            />
          </Box>
          <Box weight={600}>Networks</Box>
          <Box direction='row' gap='medium'>
            <DataTable
              columns={[
                {
                  property: 'network_name',
                  header: <Text>Name</Text>,
                  render: ({ id, network_name: networkName }) => (
                    <Link
                      title={networkName}
                      to={setId(makeSecItemUrl('networks'), id)}
                    />
                  ),
                },
                {
                  property: 'macs',
                  header: <Text>MAC</Text>,
                  render: ({ macs }) => (
                    <Box direction='row' gap='medium' wrap>
                      {macs.map((mac) => (
                        <Box key={mac}>{mac}</Box>
                      ))}
                    </Box>
                  ),
                },
                {
                  property: 'untagged',
                  header: <Text>Untagged</Text>,
                  render: ({ untagged }) => (untagged ? 'Yes' : 'No'),
                },
              ]}
              data={networks}
            />
          </Box>
        </Grid>
      </Modal.Body>
      <Modal.Footer>
        <Button label='Close' onClick={handleClose} secondary />
      </Modal.Footer>
    </Modal>
  );
};

DetailModal.propTypes = {
  data: PropTypes.object,
  setModal: PropTypes.func,
};

const FormCustomConnections = (props) => {
  const [customNetworks, setCustomNetworks] = useState([]);
  const [open, setOpen] = useState(false);
  const [modal, setModal] = useState(null);
  const [editing, setEditing] = useState(0);
  const isEdit = useMemo(() => props.col.mb.props.mode === 'edit', [props]);

  useEffect(() => {
    const customConnections = props.data?.custom_connections;

    if (!isEmpty(customConnections)) {
      setCustomNetworks(customConnections);
    }
  }, [props]);

  useEffect(() => {
    props.col.mb.view.setFormValue('custom_connections', customNetworks);
  }, [customNetworks]);

  const networks = useMemo(
    () => props.col?.customComponentMetadata?.networks.data,
    [props],
  );

  const racks = useMemo(
    () => props.col?.customComponentMetadata?.racks.data,
    [props],
  );

  const switches = useMemo(
    () => props.col?.customComponentMetadata?.switches.data,
    [props],
  );

  const switchTypes = useMemo(
    () => props.col?.customComponentMetadata?.switchTypes.data,
    [props],
  );

  const getPortNames = ({ networkSwitch, portNames }) => {
    if (!portNames) return [];

    const name = networkSwitch?.name ?? '';

    return portNames.map((port) =>
      name ? `${port}` : 'Error - switch not found',
    );
  };

  const customNetworkConnections = useMemo(
    () =>
      customNetworks.map((customNetwork) => ({
        ...customNetwork,
        ports: customNetwork.ports.map(
          ({ switch_id: switchId, port_names: portNames }) => {
            const networkSwitch = switches.find(({ id }) => id === switchId);

            return {
              networkSwitch,
              portNames,
            };
          },
        ),
        networks: customNetwork.networks
          .filter(
            ({ network_id: networkId }) =>
              !!networks.find(({ id }) => id === networkId),
          )
          .map(({ network_id: networkId, provider_macs: macs, untagged }) => {
            const network = networks.find(({ id }) => id === networkId);

            return {
              ...network,
              macs,
              untagged,
            };
          }),
      })),
    [customNetworks],
  );

  const addCustomNetwork = (customNetwork) => {
    if (editing) {
      setCustomNetworks((currentCustomNetworkConnections) => [
        ...currentCustomNetworkConnections.slice(0, editing - 1),
        customNetwork,
        ...currentCustomNetworkConnections.slice(editing),
      ]);

      setEditing(0);
    } else {
      setCustomNetworks((currentCustomNetworkConnections) => [
        ...currentCustomNetworkConnections,
        customNetwork,
      ]);
    }

    setOpen(false);
  };

  const handleDelete = (index) => () => {
    setCustomNetworks((prev) => [
      ...prev.slice(0, index),
      ...prev.slice(index + 1),
    ]);
  };

  const handleEdit = (index) => () => {
    setEditing(index + 1);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);

    if (editing) {
      setEditing(0);
    }
  };

  return (
    <Table style={{ gridColumn: '1 / span 2' }}>
      <table>
        <thead>
          <tr>
            {[
              'Name',
              'Description',
              'Switch Ports',
              'Redundancy Mode',
              'Networks',
              'MAC',
            ].map((heading) => (
              <th
                className={heading.toLocaleLowerCase().replace(' ', '-')}
                key={heading}
              >
                <Text weight='bold'>{heading}</Text>
              </th>
            ))}
            <th className='buttons' />
          </tr>
        </thead>
        <tbody>
          {!!customNetworkConnections.length &&
            customNetworkConnections.map(
              ({ description, ports, lag, name, networks: cNetworks }, i) => (
                <tr
                  key={i}
                  onClick={
                    isEdit
                      ? undefined
                      : () =>
                          setModal({
                            description,
                            ports,
                            lag,
                            name,
                            cNetworks,
                          })
                  }
                >
                  <td className='name'>
                    <Text>{name}</Text>
                  </td>
                  <td className='description'>
                    <Text>{description}</Text>
                  </td>
                  <td className='switch-ports'>
                    <Box direction='row' wrap gap='medium'>
                      {ports.flatMap(getPortNames).map((port) => (
                        <Text key={port}>{port}</Text>
                      ))}
                    </Box>
                  </td>
                  <td className='redundancy-mode'>
                    <Text>{lag.type}</Text>
                  </td>
                  <td className='networks'>
                    {!isEmpty(cNetworks) &&
                      cNetworks.map(({ id, network_name: networkName }) => (
                        <Link
                          key={id}
                          title={networkName}
                          to={setId(makeSecItemUrl('networks'), id)}
                        />
                      ))}
                  </td>
                  <td className='mac'>
                    {!isEmpty(cNetworks) &&
                      cNetworks.map(({ id, macs = [] }, j) => (
                        <Box key={id + j} gap='xsmall'>
                          {macs.map((mac) => (
                            <Text key={mac}>{mac}</Text>
                          ))}
                        </Box>
                      ))}
                  </td>
                  <td className='buttons'>
                    {isEdit && (
                      <Box direction='row' gap='xsmall'>
                        <Button
                          icon={<Edit color='brand' />}
                          onClick={handleEdit(i)}
                        />
                        <Button
                          icon={<Trash color='brand' />}
                          onClick={handleDelete(i)}
                        />
                      </Box>
                    )}
                  </td>
                </tr>
              ),
            )}
        </tbody>
      </table>
      {isEdit && (
        <Box direction='row' pad={{ vertical: 'small' }}>
          <Button
            icon={<FormAdd />}
            label='Add Custom Network'
            onClick={() => setOpen(true)}
            reverse
            secondary
          />
        </Box>
      )}

      {open ? (
        <AddCustomNetworkConnectionsPanel
          customConnections={customNetworks}
          editIndex={editing}
          networks={networks}
          onClose={handleClose}
          onSubmit={addCustomNetwork}
          pod={props.data}
          racks={racks}
          switches={switches}
          switchTypes={switchTypes}
        />
      ) : null}

      <DetailModal data={modal} setModal={setModal} />
    </Table>
  );
};

export default FormCustomConnections;

FormCustomConnections.propTypes = {
  data: PropTypes.object,
  col: PropTypes.object,
};
