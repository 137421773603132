// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

import { useMemo } from 'react';

import { createStorage } from '../createStorage';
import { isEmpty } from '../lodash';

export default function useVisibleColumns(page, newColumns, columns = []) {
  const { get } = createStorage(global.localStorage);
  const cachedColumnNames = get(page);

  const visibleColumnNames = useMemo(() => {
    if (!isEmpty(newColumns)) {
      return newColumns;
    }

    if (!isEmpty(cachedColumnNames)) {
      return cachedColumnNames;
    }

    return columns
      .filter(({ isDefault }) => isDefault)
      .map(({ property }) => property);
  }, [cachedColumnNames, columns, newColumns]);

  const visibleColumns = useMemo(
    () =>
      visibleColumnNames
        .map((property) =>
          columns.find((column) => column.property === property),
        )
        .filter((name) => name),
    [columns, visibleColumnNames],
  );

  return {
    visibleColumns,
    visibleColumnNames,
  };
}
