// (C) Copyright 2022-2024 Hewlett Packard Enterprise Development LP

import React from 'react';
import PropTypes from 'prop-types';
import { Box, Text } from 'grommet';

import { Link } from '..';

const CustomLink = ({ col, data }) => {
  const infoFunc = col?.customComponentMetadata?.info;
  const info = infoFunc(data);

  return (
    <Box direction='row' gap='medium' style={{ gridColumn: '1 / span 2' }}>
      <Box width='small'>
        <Text weight='bold'>{col.displayName}</Text>
      </Box>
      <Link
        to={info.url}
        icon={info.icon}
        style={info.style}
        tip={info.tip}
        title={info.name}
        external={info.external}
      />
    </Box>
  );
};

CustomLink.propTypes = {
  col: PropTypes.object,
  data: PropTypes.object,
};

export default CustomLink;
