// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  CheckBox,
  Collapsible,
  FormField,
  Grid,
  Select,
  Text,
  TextInput,
} from 'grommet';
import { useGetProjectResources } from '../../services/projects';
import { Trash } from 'grommet-icons';

import { isEmpty } from '../../utils';

const defaultSelected = {
  id: '',
  limit: 0,
};

const HostTypes = ({ col, data }) => {
  const [selected, setSelected] = useState([]);
  const {
    data: resources = {
      machine_sizes: [],
    },
  } = useGetProjectResources();

  const setParentForm = useCallback(
    (key, value) => col.mb.view.setFormValue(key, value),
    [col.mb.view],
  );

  const instanceTypeOptions = useMemo(
    () =>
      resources.machine_sizes
        .filter(({ restricted }) => restricted === false)
        .map(({ id, name }) => ({
          label: name,
          value: id,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [resources],
  );

  const getInstanceTypeOptions = (selectedId) =>
    instanceTypeOptions.filter(({ value }) => {
      const used = selected.some(({ id }) => value === id);

      return !used || (used && value === selectedId);
    });

  const canAdd = useMemo(
    () =>
      (selected.every(({ id }) => !!id) || !selected.length) &&
      instanceTypeOptions.length > selected.length,
    [instanceTypeOptions, selected],
  );

  useEffect(() => {
    const { id, limits } = data;

    if (id && limits?.machine_sizes) {
      const { __uniqueid, ...instanceTypes } = limits.machine_sizes;

      if (__uniqueid) {
        setParentForm('limits.machine_sizes', {});
      }

      if (isEmpty(instanceTypes)) {
        return;
      }

      const selectedData = Object.entries(instanceTypes).map(([id, limit]) => ({
        id,
        limit,
      }));

      setSelected(selectedData);
    }
  }, [data, setParentForm]);

  useEffect(() => {
    const value = selected.reduce(
      (acc, { id, limit }) => ({
        // biome-ignore lint/performance/noAccumulatingSpread: <explanation>
        ...acc,
        [id]: limit,
      }),
      {},
    );

    setParentForm('limits.machine_sizes', value);
  }, [selected, setParentForm]);

  const handleAdd = () => {
    setSelected((prev) => [...prev, defaultSelected]);
  };

  const handleDelete = (index) => () => {
    setSelected((prev) => [...prev.slice(0, index), ...prev.slice(index + 1)]);
  };

  const updateValue = (key, value, index) => {
    setSelected((prev) => [
      ...prev.slice(0, index),
      {
        ...prev[index],
        [key]: value,
      },
      ...prev.slice(index + 1),
    ]);
  };

  const handleLimitChange =
    (index) =>
    ({ target: { value } }) => {
      const number = value === '' ? value : Number(value);

      updateValue('limit', number, index);
    };

  const handleSelect =
    (index) =>
    ({ value }) => {
      updateValue('id', value, index);
    };

  const handleLimitToggle =
    (index) =>
    ({ target: { checked } }) => {
      updateValue('limit', checked ? 1 : 0, index);
    };

  if (col.isReadOnly) {
    return (
      <Grid columns={['auto', '1fr']} gap='medium'>
        {selected.map(({ id, limit }) => {
          const name = instanceTypeOptions.find(
            ({ value }) => value === id,
          )?.label;

          return (
            <Fragment key={id}>
              <Text weight={600}>{name}: </Text>
              <Text>{limit}</Text>
            </Fragment>
          );
        })}
      </Grid>
    );
  }
  return (
    <Box gap='medium'>
      {selected.map(({ id, limit }, index) => (
        <Box
          align='start'
          border={{
            color: 'border',
            size: 'xsmall',
            side: 'all',
          }}
          direction='row'
          gap='medium'
          key={id}
          pad={{
            horizontal: 'medium',
            vertical: 'small',
          }}
          round='xsmall'
        >
          <Box>
            <FormField label='Instance type'>
              <Select
                labelKey='label'
                onChange={handleSelect(index)}
                options={getInstanceTypeOptions(id)}
                placeholder='Select Instance type'
                value={id}
                valueKey={{ key: 'value', reduce: true }}
              />
            </FormField>
            <Box style={{ border: 'none' }}>
              <CheckBox
                checked={limit > 0}
                disabled={!id}
                label='Limit this instance type'
                onChange={handleLimitToggle(index)}
                toggle={false}
              />
            </Box>
            <Collapsible direction='vertical' open={limit > 0}>
              <Box pad='small' width='100%'>
                <FormField label='Maximum number of this type'>
                  <TextInput
                    min={1}
                    onChange={handleLimitChange(index)}
                    type='number'
                    value={limit}
                  />
                </FormField>
              </Box>
            </Collapsible>
          </Box>
          <Box pad={{ vertical: '30px' }}>
            <Button icon={<Trash />} onClick={handleDelete(index)} />
          </Box>
        </Box>
      ))}
      <div>
        <Button
          disabled={!canAdd}
          label='Add'
          onClick={handleAdd}
          secondary={true}
          type='button'
        />
      </div>
    </Box>
  );
};

HostTypes.propTypes = {
  col: PropTypes.object,
};

export default HostTypes;
