// (C) Copyright 2023-2024 Hewlett Packard Enterprise Development LP

import { useQueries } from 'react-query';

import { get } from '../lib/rest';
import { toDateTime } from '../lib/formatters';
import {
  makeRestUrl,
  URL_STORAGE_GROUPS,
  URL_PODS,
  URL_RACKS,
} from '../routes/consts';
import { getDefaultQueriesResponse, renderLink } from './helper';
import TruncateText from '../components/Griddle/TruncateText';

const columns = () => [
  {
    header: 'Name',
    isDefault: true,
    property: 'name',
    render: renderLink({
      base: URL_STORAGE_GROUPS,
    }),
  },
  {
    header: 'Driver',
    isDefault: true,
    property: 'driver',
  },
  {
    header: 'Last seen',
    isDefault: true,
    property: 'lastSeen',
    render: ({ lastSeen }) => <TruncateText content={toDateTime(lastSeen)} />,
  },
  {
    header: 'ID',
    property: 'id',
    render: ({ id }) => <TruncateText content={id} />,
  },
  {
    header: 'Created',
    property: 'created',
    render: ({ created }) => <TruncateText content={toDateTime(created)} />,
  },
  {
    header: 'Modified',
    property: 'modified',
    render: ({ modified }) => <TruncateText content={toDateTime(modified)} />,
  },
  {
    header: 'Pod ID',
    property: 'podID',
    render: renderLink({
      base: URL_PODS,
      idKey: 'podID',
      titleKey: 'podID',
    }),
  },
  {
    header: 'Rack ID',
    property: 'rackID',
    render: renderLink({
      base: URL_RACKS,
      idKey: 'rackID',
      titleKey: 'rackID',
    }),
  },
];

export const getStorageGroups = async () => {
  const url = makeRestUrl(URL_STORAGE_GROUPS);
  const response = await get(url);

  return response.json();
};

export const useGetStorageGroups = () => {
  const queryResults = useQueries([
    {
      queryKey: URL_STORAGE_GROUPS,
      queryFn: getStorageGroups,
    },
  ]);

  const isLoaded = queryResults.every(
    ({ error, isLoading }) => !isLoading && !error,
  );

  if (isLoaded) {
    const [storageGroups] = queryResults;

    return {
      columns: columns()
        // adding columnName and displayName for backwards compatibility
        .map((column) => ({
          ...column,
          columnName: column.property,
          displayName: column.header,
        })),
      data: storageGroups.data,
      isLoading: false,
      refetch: storageGroups.refetch,
      error: null,
    };
  }

  return getDefaultQueriesResponse(queryResults);
};
