// (C) Copyright 2016-2024 Hewlett Packard Enterprise Development LP

import React from 'react';
import defaultTo from 'lodash/defaultTo';
import * as c from '../../routes/consts.js';
import * as mb from '../../containers/generic/MetaBuilder';
import MetaBuilder from '../../containers/generic/MetaBuilder';
import ItemViewContainer from '../../containers/generic/ItemViewContainer';
import CreateViewContainer from '../../containers/generic/CreateViewContainer';
import EditViewContainer from '../../containers/generic/EditViewContainer';
import debugLogger from '../../lib/debug';
import * as log from '../../lib/debug';
import * as REGEX from '../../data/regex.js';
import { netmaskIPv4 } from '../../data/network.js';
import FormIpPools from '../../containers/generic/MetaForm/FormIpPools';
import FormIpSourceInline from '../../containers/generic/MetaForm/FormIpSourceInline';
import FormDnsSearchDomain from '../../containers/generic/MetaForm/FormDnsSearchDomain.js';
import { LABEL_IPPOOLS } from '../../components/HybridNav/consts.js';

const debug = debugLogger('IpPool settings', log.LOG_LEVEL_WARN);

const EXTERNAL_NAME = c.URL_IPPOOLS;

export const settings = {
  authUrl: EXTERNAL_NAME, // used to filter create/trash icons from the view; using the defined roledef permissions mappings to this auth URL entry
  homeUrl: c.makeSecUrl(EXTERNAL_NAME), // homepage for this list view (e.g. /s/hosters/:pid/) is where this view is located; for return from create / item views
  baseUrl: EXTERNAL_NAME, // base url to be used for creating all associated URLS for this reasource, e.g. pageItem, pageCreate, restUrl, restItemUrl
  stateKey: EXTERNAL_NAME,
  homeLabel: LABEL_IPPOOLS,
};

const DS_IP_POOLS = 'ippools';

function metaCreate(props) {
  const m = new MetaBuilder(props);

  m.formDefaults((form) => {
    form.dns = defaultTo(form.dns, []);
    form.ntp = defaultTo(form.ntp, []);
    form.sources = defaultTo(form.sources, []);
  });

  const id = m.view.prop('itemId', false);
  debug.debug('got ippool id:', id);

  let ds;
  if (id === undefined) {
    ds = m.newStaticDataSource({}, DS_IP_POOLS);
  } else {
    ds = m
      .newDataSource(c.URL_IPPOOLS, DS_IP_POOLS)
      .Item(id)
      .OnLoad((json) => {
        json.data.sources = defaultTo(json.data.sources, []);
        json.data.dns = defaultTo(json.data.dns, []);
        json.data.ntp = defaultTo(json.data.ntp, []);
        debug.debug('got IP Pool:', json);
        m.view.initForm(json.data);
      });
  }

  if (props.mode !== 'create') {
    m.addTab('Config');
  }

  m.addSection('1').NoChrome().MaxWidth(mb.LARGE);

  m.addColumn('name', 'Name')
    .Input()
    .Required()
    .MaxLength(150)
    .MaxWidth(mb.SMALL);

  if (props.mode === 'view') {
    m.addColumn('id', 'ID').MaxWidth(mb.SMALL);
  }

  m.addColumn('description', 'Description').Input().MaxWidth(mb.SMALL);

  m.addColumn('network_id', 'Network ID').MaxWidth(mb.SMALL);

  m.addColumn('ip_version', 'Protocol')
    .Input()
    .Required()
    .RadioGroup()
    .DataXform(
      m.newStaticDataSource([
        { id: 'IPv4', name: 'IPv4' },
        { id: 'IPv6', name: 'IPv6', disabled: true },
      ]),
      (json) => json,
    )
    .MaxWidth(mb.SMALL);

  m.addColumn('default_route', 'Default route')
    .Input()
    .MaxWidth(mb.SMALL)
    .RegEx(REGEX.IPV4_ADDRESS, REGEX.IPV4_ADDRESS_MSG);

  m.addColumn('base_ip', 'Base IP').Input().MaxWidth(mb.SMALL).Required();

  m.addColumn('netmask', 'Netmask CIDR')
    .Input('/24')
    .MaxWidth(mb.SMALL)
    .DropDown('/24')
    .Required()
    .DataXform(m.newStaticDataSource(netmaskIPv4), (json) =>
      json.map((t) => ({ id: t, name: t })),
    );

  m.addColumn('dns[0]', 'DNS 1')
    .Input()
    .MaxWidth(mb.SMALL)
    .RegEx(REGEX.IPV4_ADDRESS, REGEX.IPV4_ADDRESS_MSG);

  m.addColumn('dns[1]', 'DNS 2')
    .Input()
    .MaxWidth(mb.SMALL)
    .RegEx(REGEX.IPV4_ADDRESS, REGEX.IPV4_ADDRESS_MSG);

  m.addColumn('dns_search_domains', 'DNS search domain')
    .Input()
    .Custom(FormDnsSearchDomain, {});

  m.addColumn('ntp[0]', 'NTP 1')
    .Input()
    .MaxWidth(mb.SMALL)
    .RegEx(REGEX.IPV4_ADDRESS, REGEX.IPV4_ADDRESS_MSG);

  m.addColumn('ntp[1]', 'NTP 2')
    .Input()
    .MaxWidth(mb.SMALL)
    .RegEx(REGEX.IPV4_ADDRESS, REGEX.IPV4_ADDRESS_MSG);

  m.addColumn('proxy', 'Proxy server')
    .Input()
    .MaxWidth(mb.SMALL)
    .RegEx(REGEX.PROXY, REGEX.PROXY_MSG);

  m.addColumn('no_proxy', 'No proxy').Input().MaxWidth(mb.SMALL);

  // networks
  m.addSection('IP address sources')
    .MaxWidth(mb.LARGE)
    .OverviewText('The set of IP addresses that are part of the pool.');

  m.addColumn('sources', 'IP sources')
    .Input()
    .Custom(FormIpSourceInline, { ds });

  if (props.mode !== 'create') {
    m.addTab('Allocation');

    m.addSection('IP addresses in use').NoChrome(false).MaxWidth(mb.XXLARGE);

    m.addColumn('name', 'Name').Input('name').Hidden();
    m.addColumn('base_ip', 'base_ip').Input('base_ip').Hidden();

    m.addColumn('use_records', 'IP pools').Input().Custom(FormIpPools, { ds });
  }

  return m;
}

export { default as IPPoolListView } from './IpPoolListView';

export const IPPoolItemView = ItemViewContainer({
  ...settings,
  allowEdit: true,
  meta: (props) => metaCreate(props),
  title: 'IP pool',
});

export const IPPoolCreateView = CreateViewContainer({
  ...settings,
  meta: (props) => metaCreate(props),
  title: 'Create IP pool',
});

export const IPPoolEditView = EditViewContainer({
  ...settings,
  meta: (props) => metaCreate(props),
  title: 'Edit IP pool',
});
