// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, FormField, Heading, Select, Text, TextInput } from 'grommet';

import { MultiSelect } from '../../components';
import { MAC, MAC_MSG } from '../../data/regex';
import { useGetVolumeAttachments } from '../../services/volumeAttachments';
import { isEmpty } from '../../utils';
import { useGetAvailableResources } from '../../services/hosts';
import { TagsEditDisplay } from '../../components/tags/TagsEditDisplay';
import { ISCSI } from '../../data/volume';

const initialForm = {
  network_for_default_route: '',
  network_untagged: '',
  description: '',
  labels: {},
  macs: {},
  networks: [],
  iscsi: '',
};

const HostEditForm = (props) => {
  const [form, setForm] = useState(initialForm);
  const [errors, setErrors] = useState({});
  const { data: volAttachments } = useGetVolumeAttachments();
  const { availableResources: resources, getAvailableResources } =
    useGetAvailableResources();

  useEffect(() => {
    getAvailableResources(props.data.project_id);
  }, [getAvailableResources, props.data.project_id]);

  const hasVolAttachments = useMemo(() => {
    if (isEmpty(props.data) || isEmpty(volAttachments)) {
      return false;
    }

    return !!volAttachments.find(({ hostID }) => hostID === props.data.id);
  }, [props.data, volAttachments]);

  const setParentForm = useMemo(
    () => props.col.mb.view.setFormValue,
    [props.col.mb.view.setFormValue],
  );

  const networks = useMemo(() => resources?.networks || [], [resources]);

  const dsNetworks = useMemo(() => {
    if (!props?.col?.customComponentMetadata?.dsNetworks?.data) {
      return [];
    }

    return props?.col?.customComponentMetadata?.dsNetworks?.data;
  }, [props]);

  const networkOptions = useMemo(() => {
    const availableNetworks = networks
      .filter((network) => network.location_id === props.data.location_id)
      .map(({ __uniqueid, ...network }) => network);

    return availableNetworks.map((network) => ({
      network: dsNetworks.find(({ id }) => id === network.id) || network,
      value: network.id,
      label: network.name,
      required: network.host_use === 'Required',
    }));
  }, [dsNetworks, networks, props.data]);

  const defaultNetworkOptions = useMemo(
    () =>
      networkOptions.filter(({ value }) =>
        form.networks.some((network) => network.value === value),
      ),
    [form, networkOptions],
  );

  const preAllocatedIpMap = useMemo(
    () =>
      props.data.pre_allocated_ips && props.data.networks
        ? props.data.pre_allocated_ips.reduce(
            (acc, cur, i) => ({
              // biome-ignore lint/performance/noAccumulatingSpread: <explanation>
              ...acc,
              ...(cur ? { [props.data.networks[i]]: cur } : {}),
            }),
            {},
          )
        : {},
    [props.data],
  );

  const serviceNetworks = useMemo(
    () =>
      form.networks.filter(
        ({ network }) => network?.subtype === 'service-provider',
      ),
    [form.networks],
  );

  useEffect(() => {
    if (props.data?.id) {
      const serviceMacs = props.data.service_nets_provider_mac || {};
      const { __uniqueid, ...macs } = serviceMacs;

      setForm({
        description: props.data.description ?? '',
        iscsi: props.data.iscsi_config.initiator_name ?? '',
        network_for_default_route: props.data.network_for_default_route,
        network_untagged: props.data.network_untagged ?? '',
        labels: props.data.labels,
        macs,
        name: props.data.name,
        networks: networkOptions.filter((network) =>
          props.data.networks.includes(network.value),
        ),
      });
    }
  }, [props.data, networkOptions]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (serviceNetworks.length) {
      const macs = serviceNetworks.reduce(
        (acc, cur) => ({
          // biome-ignore lint/performance/noAccumulatingSpread: <explanation>
          ...acc,
          [cur.value]: form.macs[cur.value],
        }),
        {},
      );
      setParentForm('service_nets_provider_mac', macs);
    } else {
      setParentForm('service_nets_provider_mac', {});
    }
  }, [form.macs, form.networks, serviceNetworks, setParentForm]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (
      form.networks?.length === 1 ||
      (!form.network_for_default_route && form.networks)
    ) {
      setForm((prevValue) => ({
        ...prevValue,
        network_for_default_route: form.networks[0]?.value ?? '',
      }));
    }
  }, [form.networks]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    const shouldRemoveUntaggedNetwork =
      form.networks.length > 0 &&
      form.networks.every(({ value }) => value !== form.network_untagged);

    if (shouldRemoveUntaggedNetwork === true) {
      setParentForm('network_untagged', '');
      setForm((prevForm) => ({
        ...prevForm,
        network_untagged: '',
      }));
    }
  }, [form.networks]);

  const handleInputChange = (key) => (e) => {
    const { value } = e.target || e;

    setForm((prevForm) => ({
      ...prevForm,
      [key]: value,
    }));

    if (key === 'networks') {
      setParentForm(
        'pre_allocated_ips',
        value.map((network) => preAllocatedIpMap[network.key] || ''),
      );
      setParentForm(
        key,
        value.map((network) => network.value),
      );

      if (value.every((network) => network.value !== form.network_untagged)) {
        setParentForm('network_untagged', '');
      }
    } else if (key === ISCSI) {
      setParentForm('iscsi_config.initiator_name', value);
    } else {
      setParentForm(key, value);
    }
  };

  const handleMacChange = (networkId) => (e) => {
    e.persist();

    const mac = e.target.value;
    const macs = {
      ...form.macs,
      [networkId]: mac,
    };

    const newForm = {
      ...form,
      macs,
    };

    setForm(newForm);
    setParentForm('service_nets_provider_mac', macs);

    if (mac && !MAC.test(mac)) {
      setErrors({ [networkId]: MAC_MSG });
    } else {
      setErrors({ [networkId]: '' });
    }
  };

  return (
    <Box width='medium'>
      <Box direction='row' pad={{ top: 'small' }}>
        <Box width='xsmall'>
          <Text weight={600}>Name</Text>
        </Box>
        <Box>
          <Text color='text-strong'>{props.data.name}</Text>
        </Box>
      </Box>
      <Box direction='row' pad={{ top: 'small' }}>
        <Box width='xsmall'>
          <Text weight={600}>ID</Text>
        </Box>
        <Box>
          <Text color='text-strong'>{props.data.id}</Text>
        </Box>
      </Box>
      <FormField label='Description'>
        <TextInput
          onChange={handleInputChange('description')}
          value={form.description}
        />
      </FormField>
      <FormField label='Networks'>
        <MultiSelect
          onChange={handleInputChange('networks')}
          options={networkOptions}
          value={form.networks}
        />
      </FormField>
      {serviceNetworks.length ? (
        <Box border round='xxsmall'>
          {serviceNetworks.map(({ label, value }) => (
            <Box pad='xsmall' key={value}>
              <FormField
                error={errors[value]}
                label={`${label} MAC Address Override (Optional)`}
              >
                <TextInput
                  onChange={handleMacChange(value)}
                  placeholder='XX:XX:XX:XX:XX:XX'
                  value={form.macs[value] || ''}
                />
              </FormField>
            </Box>
          ))}
        </Box>
      ) : null}
      <FormField label='Untagged Network'>
        <Select
          clear
          labelKey='label'
          onChange={handleInputChange('network_untagged')}
          options={defaultNetworkOptions}
          placeholder='Select network'
          value={form.network_untagged}
          valueKey={{ key: 'value', reduce: true }}
        />
      </FormField>
      <FormField label='Network for Default Route'>
        <Select
          onChange={handleInputChange('network_for_default_route')}
          value={form.network_for_default_route}
          options={defaultNetworkOptions}
          required
          labelKey='label'
          valueKey={{ key: 'value', reduce: true }}
        />
      </FormField>
      <Heading level={4} margin={{ top: 'large' }}>
        iSCSI configuration
      </Heading>
      <FormField label='Initiator name'>
        <TextInput
          disabled={hasVolAttachments}
          onChange={handleInputChange(ISCSI)}
          value={form.iscsi}
        />
      </FormField>
      <TagsEditDisplay
        form={form}
        setForm={setForm}
        setParentForm={setParentForm}
      />
    </Box>
  );
};

export default HostEditForm;

HostEditForm.propTypes = {
  col: PropTypes.object,
  data: PropTypes.object,
};
