// (C) Copyright 2020-2024 Hewlett Packard Enterprise Development LP

import React, { useMemo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import includes from 'lodash/includes';
import isArrayLike from 'lodash/isArrayLike';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { Box, FormField, Select, Text } from 'grommet';
import LinksCell from './LinksCell.js';
import FormReadOnlyField from './FormReadOnlyField';
import styled from 'styled-components';

const FormFieldCustomBorder = styled(FormField)`
  ${(props) =>
    props?.removeStyles
      ? `
    div { border: none }
    *:disabled {
        opacity: 1
      }
    `
      : ''}
`;

const getOptions = (items) => {
  if (!isArrayLike(items)) {
    this.debug.throw('getMultiOptions() requires an items array, got: ', items);
  }
  return items.map(({ id, name, disabled, required, help, info }) => ({
    value: id,
    label: help ? (
      <Box direction='row' justify='between' width='380px' gap='small'>
        <Text>{name}</Text>
        <Text color='text-weak'>{help}</Text>
      </Box>
    ) : (
      name
    ),
    name,
    disabled,
    clearableValue: !required, // unused for single select
    help,
    info,
  }));
};

const getMultiValue = (options, valueCSV) => {
  const multiValue = valueCSV.split(',');

  const selected = options.filter(({ value }) => includes(multiValue, value));

  if (selected.length) {
    return selected[0];
  }

  return selected.length === 0 ? undefined : selected[0];
};

const Placeholder = ({ children }) => (
  <Box pad={{ horizontal: 'small' }}>
    <Text color='text-weak'>{children}</Text>
  </Box>
);

const SelectValue = (props) => (
  <Box pad={{ horizontal: 'small', vertical: 'xsmall' }} margin='xxsmall'>
    <Text style={{ wordBreak: 'break-all' }} {...props} />
  </Box>
);

const FormDropDown = ({
  clear,
  col,
  data,
  onChange,
  validationResult = {},
  value,
  ...rest
}) => {
  if (col.isReadOnly) {
    return (
      <FormReadOnlyField label={col.displayName}>
        <LinksCell col={col} ids={[value]} data={data} />
      </FormReadOnlyField>
    );
  }

  // TODO need to add 'px' on these styles?
  const inputStyle = { style: {} };

  if (col.width) {
    inputStyle.style.minWidth = col.width;
    inputStyle.style.maxWidth = col.width;
  }

  if (col.minWidth) {
    inputStyle.style.minWidth = col.minWidth;
  }

  if (col.maxWidth) {
    inputStyle.style.maxWidth = col.maxWidth;
  }

  // editable
  const options = useMemo(() => {
    const result = getOptions(data);
    if (col?.columnName === 'volumeFlavorID' && result?.length === 2) {
      return result?.filter(
        (option) => option?.value !== 'select_volume_flavor',
      );
    }
    return result;
  }, [data, col?.columnName]);

  const selectedOption = getMultiValue(options, value, clear);
  const placeholder = isEmpty(data) ? col.placeholderIfEmpty : col.placeholder;

  const autoSelectFirstValueFields = [
    'podID',
    'volumeFlavorID',
    'storage_pool_id',
  ];
  const onlyHasOneOption = options.length === 1;
  const autoSelectFirstOption =
    col.isRequired &&
    autoSelectFirstValueFields.includes(col?.columnName) &&
    onlyHasOneOption;

  const setFormValue = useCallback(
    ({ value }) => {
      if (isNil(value)) {
        value = undefined; // undefined values are unset from the form
      }

      onChange?.(col.keyPath, get(value, 'value'));
    },
    [col.keyPath, onChange],
  );

  useEffect(() => {
    if (autoSelectFirstOption) {
      const firstOption = options?.[0];
      setFormValue({ value: firstOption });
    }
  }, [autoSelectFirstOption, options, setFormValue]);

  return (
    <FormFieldCustomBorder
      label={col.isRequired ? `${col.displayName}*` : col.displayName}
      name={col.keyPath}
      error={validationResult.msg}
      style={{ gridColumn: '1 / span 2', ...inputStyle.style }}
      info={selectedOption ? selectedOption.info : undefined}
      removeStyles={autoSelectFirstOption}
    >
      <Select
        disabled={autoSelectFirstOption}
        icon={!autoSelectFirstOption}
        clear={col.isClearable || clear}
        name={col.keyPath}
        value={selectedOption}
        labelKey='label'
        valueKey='value'
        disabledKey='disabled'
        placeholder={placeholder}
        options={options}
        valueLabel={
          <Box wrap direction='row' height={{ min: '42px' }} align='center'>
            {!selectedOption ? (
              <Placeholder>{placeholder}</Placeholder>
            ) : (
              <SelectValue>{selectedOption.name}</SelectValue>
            )}
          </Box>
        }
        onChange={setFormValue}
        {...rest}
      />
    </FormFieldCustomBorder>
  );
};

export default FormDropDown;

FormDropDown.propTypes = {
  col: PropTypes.shape({
    displayName: PropTypes.string.isRequired,
    keyPath: PropTypes.string,
    placeholderIfEmpty: PropTypes.string,
    placeholder: PropTypes.string,
    isClearable: PropTypes.bool,
    showLink: PropTypes.bool,
    width: PropTypes.any,
    minWidth: PropTypes.any,
    maxWidth: PropTypes.any,
  }).isRequired,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      disabled: PropTypes.bool,
      required: PropTypes.bool,
    }),
  ),
};
