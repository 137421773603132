// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

import React from 'react';

import ListPageWithNav from '../../containers/generic/ListPageWithNav';
import { URL_ALERTS } from '../../routes/consts';
import { useGetAlerts } from '../../services/alerts';
import { NAV_TRACKING } from '../../components/HybridNav/consts.js';

const AlertListView = () => (
  <ListPageWithNav
    navSection={NAV_TRACKING}
    path={URL_ALERTS}
    sort={{
      property: 'modified',
      direction: 'desc',
    }}
    title='Alerts'
    useGetData={useGetAlerts}
    limitRecords={true}
  />
);

export default AlertListView;
