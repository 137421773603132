// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

import { useEffect, useState } from 'react';

export default function useRowProps(selected) {
  const [rowProps, setRowProps] = useState({});

  useEffect(() => {
    const backgrounds = selected.reduce(
      (acc, { id }) => ({
        // biome-ignore lint/performance/noAccumulatingSpread: <explanation>
        ...acc,
        [id]: { background: '#17EBA01A' },
      }),
      {},
    );

    setRowProps(backgrounds);
  }, [selected]);

  return rowProps;
}
