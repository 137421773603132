// (C) Copyright 2023-2024 Hewlett Packard Enterprise Development LP

import React, { useRef, useState } from 'react';
import { Box, Button, CheckBox, FormField, Text, TextInput } from 'grommet';
import { StatusCriticalSmall, StatusWarningSmall } from 'grommet-icons';
import { Modal } from '../../../components';

export const UnmanagedSwitch = ({ col, data }) => {
  const CONFIRM = 'unmanaged-switch-mode';
  const setParent = col.mb.view.setFormValue;
  const checked = data.pod_features.unmanaged_switch;
  const isUnmanagedSwitch = data._temp?.unmanaged;
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const checkboxRef = useRef();

  const handleCheckBoxChange = (e) => {
    if (e.target.checked) {
      setOpen(true);
    } else {
      setParent('pod_features.unmanaged_switch', false);
    }
  };

  const handleConfirm = () => {
    setParent('pod_features.unmanaged_switch', true);
    setOpen(false);
    setInputValue('');
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleModalClose = () => {
    setParent('pod_features.unmanaged_switch', false);
    setOpen(false);
    setInputValue('');
  };

  if (col.isReadOnly) {
    return (
      <Box style={{ gridColumn: '1 / span 2', pointerEvents: 'none' }}>
        <CheckBox
          name={col.keyPath}
          label={col.displayName}
          key={col.keyPath}
          checked={checked}
          color='text-strong'
        />
      </Box>
    );
  }

  return (
    <>
      <FormField
        name={col.keyPath}
        help={col.inputHelp}
        style={{ gridColumn: '1 / span 2', maxWidth: col.maxWidth }}
      >
        <CheckBox
          checked={checked}
          disabled={isUnmanagedSwitch}
          key={col.keyPath}
          label={col.displayName}
          name={col.keyPath}
          onChange={handleCheckBoxChange}
          ref={checkboxRef}
        />
      </FormField>
      {isUnmanagedSwitch !== true && checked === true ? (
        <Box
          background='#ffefd2'
          pad='small'
          direction='row'
          align='start'
          gap='small'
          margin={{ top: '-8px' }}
        >
          <StatusWarningSmall color='status-warning' />
          <Text>
            You have opted for an unmanaged switch mode, please note once you
            change to an unmanaged switch mode it cannot be reversed. This
            change will be applied once you save this form by clicking on
            update.
          </Text>
        </Box>
      ) : null}
      <Modal show={open} onHide={handleModalClose} size='medium'>
        <Modal.Header onDismiss={handleModalClose}>
          <Modal.Title>Unmanaged switch mode</Modal.Title>
        </Modal.Header>
        <Box gap='medium' pad={{ horizontal: 'medium' }}>
          <Box
            background='#FC61613D'
            round='xsmall'
            pad='small'
            direction='row'
            justify='between'
            align='start'
            gap='small'
          >
            <StatusCriticalSmall color='status-critical' />
            <Text>
              You have checked the "unmanaged switch mode" checkbox. Once you
              change the switch into unmanaged mode, this decision cannot be
              reversed.
            </Text>
          </Box>
          <Box>
            <Text>
              This change will be permanent, and network operations will no
              longer occur. The maintenance network will be used exclusively for
              compute instance lifecycle operations.
            </Text>
          </Box>
          <Box>
            <FormField label='To confirm this action, please type: unmanaged-switch-mode'>
              <TextInput
                onChange={handleInputChange}
                name='confirm-input'
                value={inputValue}
              />
            </FormField>
          </Box>
        </Box>
        <Modal.Footer>
          <Box
            direction='row'
            fill={true}
            gap='small'
            justify='end'
            pad={{ vertical: 'small' }}
          >
            <Button
              label='Cancel'
              onClick={handleModalClose}
              secondary={true}
            />
            <Button
              disabled={inputValue !== CONFIRM}
              label='Confirm'
              onClick={handleConfirm}
              primary={true}
            />
          </Box>
        </Modal.Footer>
      </Modal>
    </>
  );
};
