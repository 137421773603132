// (C) Copyright 2023-2024 Hewlett Packard Enterprise Development LP

import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, CheckBox, FormField, Select, TextInput } from 'grommet';
import { FormAdd, Trash } from 'grommet-icons';
import styled from 'styled-components';
import { isEmpty } from '../../../utils';

const countOptions = [1, 2, 3, 4, 5, 6, 7, 8];

const defaultConnection = {
  name: '',
  count: 1,
  ha: false,
  switch_lag: false,
};

const defaultForm = [];

const Container = styled(Box)`
  grid-column: 1 / span 2;
`;

const StyledButton = styled(Button)`
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  visibility: ${({ disabled }) => (disabled ? 'hidden' : 'visible')};
`;

const StyledField = styled(FormField)`
  button, input {
    opacity: 1 !important;
  }

  ${({ isView }) =>
    isView
      ? `
    * {
      pointer-events: none;
    }

    label + div {
      border: none;
    }

    svg {
      display: none;
    }
  `
      : ''}
`;

const FormCustomServerConnections = (props) => {
  const [form, setForm] = useState(defaultForm);
  const [isDirty, setIsDirty] = useState(false);
  const isView = useMemo(() => props.col.mb.props.mode === 'view', [props]);
  const canAdd = !form.find(({ name }) => !name) && !isView;
  const { setFormValue } = props.col.mb.view;
  const isFabricHa = useMemo(() => props.data?.ha, [props.data?.ha]);

  useEffect(() => {
    const conns = props.data?.custom_server_conns;

    if (isEmpty(conns)) {
      setFormValue('custom_server_conns', []);

      return;
    }

    const formData = conns.map(
      ({ match_value, nic_count, ha, switch_lag }) => ({
        name: match_value,
        count: nic_count,
        ha,
        switch_lag,
      }),
    );

    setForm(formData);
  }, [props.data, setFormValue]);

  useEffect(() => {
    if (!isView && isDirty) {
      const payload = form
        .filter(({ name }) => name)
        .map(({ name, count, ha, switch_lag }) => ({
          match_value: name,
          nic_count: count,
          ha,
          switch_lag,
        }));

      setFormValue('custom_server_conns', payload);
    }
  }, [form, isDirty, isView, setFormValue]);

  const updateForm = (index, key, value) => {
    if (!isDirty) {
      setIsDirty(true);
    }

    setForm((prevForm) => [
      ...prevForm.slice(0, index),
      {
        ...prevForm[index],
        [key]: value,
      },
      ...prevForm.slice(index + 1),
    ]);
  };

  const handleNameChange =
    (index) =>
    ({ target }) => {
      updateForm(index, 'name', target.value);
    };

  const handleCountChange =
    (index) =>
    ({ value }) => {
      updateForm(index, 'count', value);
    };

  const handleCheckBoxChange =
    (index, key) =>
    ({ target }) => {
      updateForm(index, key, target.checked);
    };

  const handleDelete = (index) => () => {
    if (!isDirty) {
      setIsDirty(true);
    }

    setForm((prevForm) => [
      ...prevForm.slice(0, index),
      ...prevForm.slice(index + 1),
    ]);
  };

  const handleAdd = () => {
    setForm((prevForm) => [...prevForm, defaultConnection]);
  };

  return (
    <Container gap='small'>
      {form.map(({ count, ha, name, switch_lag }, i) => (
        <Box
          className='server-row'
          direction='row'
          justify='start'
          // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
          key={i}
          gap='medium'
        >
          <Box
            border
            round='xsmall'
            pad={{ left: 'xsmall', vertical: 'xsmall' }}
          >
            <Box direction='row' justify='start'>
              <Box width='medium' margin={{ right: 'small' }}>
                <StyledField label='Machine model/size' isView={isView}>
                  <TextInput
                    onChange={handleNameChange(i)}
                    placeholder='Enter'
                    value={name}
                  />
                </StyledField>
              </Box>
              <Box>
                <StyledField label='NIC count' isView={isView}>
                  <Select
                    options={countOptions}
                    onChange={handleCountChange(i)}
                    placeholder='Select'
                    value={count}
                  />
                </StyledField>
              </Box>
              <StyledButton
                icon={<Trash color='brand' />}
                disabled={isView}
                margin={{ top: !i ? 'medium' : 'none' }}
                onClick={handleDelete(i)}
              />
            </Box>
            <Box direction='row' gap='medium'>
              {
                /* temporarily hide Switch LAG checkbox */
                // biome-ignore lint/correctness/noConstantCondition: <explanation>
                false ? (
                  <CheckBox
                    checked={switch_lag}
                    disabled={isView}
                    label='Switch LAG'
                    onChange={handleCheckBoxChange(i, 'switch_lag')}
                  />
                ) : null
              }
              {isFabricHa ? (
                <CheckBox
                  checked={ha}
                  disabled={isView}
                  label='HA'
                  onChange={handleCheckBoxChange(i, 'ha')}
                />
              ) : null}
            </Box>
          </Box>
        </Box>
      ))}
      <Box direction='row' pad={{ vertical: 'small' }}>
        {!isView ? (
          <Button
            disabled={!canAdd}
            icon={<FormAdd />}
            label='Add'
            onClick={handleAdd}
            reverse
            secondary
          />
        ) : null}
      </Box>
    </Container>
  );
};

export default FormCustomServerConnections;

FormCustomServerConnections.propTypes = {
  data: PropTypes.object,
  col: PropTypes.object,
};
