// (C) Copyright 2016-2024 Hewlett Packard Enterprise Development LP

import * as c from '../../routes/consts.js';
import MetaBuilder from '../../containers/generic/MetaBuilder';
import ColumnLink from '../../components/Griddle/ColumnLink';
import ItemViewContainer from '../../containers/generic/ItemViewContainer';
import { LABEL_OPERATIONS } from '../../components/HybridNav/consts.js';
import { toDateTime } from '../../lib/formatters';

const BASE_URL = c.URL_OPS;

function metabuilder(props) {
  const mb = new MetaBuilder(props);

  mb.addColumn('name', 'Name')
    .Custom(ColumnLink, {
      info: (rowData) => {
        const url = c.setId(c.makeSecItemUrl(BASE_URL), rowData.id);
        const { name } = rowData;
        return { url, name };
      },
    })
    .Default()
    .Input()
    .Required();

  mb.addColumn('id', 'ID');
  mb.addColumn('state', 'State').Default();
  mb.addColumn('status', 'Status').Default();
  mb.addColumn('result', 'Result');
  mb.addColumn('result_info', 'Result Info');
  mb.addColumn('result_msg', 'Result Message');
  mb.addColumn('op', 'Op').Default();
  mb.addColumn('opcmd', 'Op Command').Default();
  mb.addColumn('modified', 'Modified').FieldXform((modified) =>
    modified ? toDateTime(modified) : '--',
  );
  mb.addColumn('created', 'Created').FieldXform((created) =>
    created ? toDateTime(created) : '--',
  );
  mb.addColumn('task_id', 'Task ID');
  mb.addColumn('host_id', 'Host ID');
  mb.addColumn('machine_id', 'Machine ID');
  mb.addColumn('service_id', 'Service ID');
  mb.addColumn('device_id', 'Device ID');
  mb.addColumn('pre_reqs', 'Pre reqs');
  mb.addColumn('type_name', 'Type name');
  mb.addColumn('rack_id', 'Rack ID');

  return mb;
}

const settings = {
  authUrl: BASE_URL, // used to filter create/trash icons from the view; using the defined roledef permissions mappings to this auth URL entry
  homeUrl: c.makeSecUrl(BASE_URL), // homepage for this list view (e.g. /s/hosters/:pid/) is where this view is located; for return from create / item views
  homeLabel: LABEL_OPERATIONS,
  baseUrl: BASE_URL, // base url to be used for creating all associated URLS for this reasource, e.g. pageItem, pageCreate, restUrl, restItemUrl
  meta: (props) => metabuilder(props),
};

export { default as OpListView } from './OperationListView';

export const OpItemView = ItemViewContainer({
  ...settings,
  title: 'Operation',
});
