// (C) Copyright 2020-2024 Hewlett Packard Enterprise Development LP

import React from 'react';
import { Box, Layer, Heading } from 'grommet';
import { Close } from 'grommet-icons';
import IconButton from './generic/IconButton';

const sizes = {
  small: 'small',
  medium: '598px',
  large: 'large',
};

const Modal = ({ show = false, size, children, onHide, ...rest }) => {
  if (!show) {
    return null;
  }

  return (
    <Layer
      modal
      position='center'
      onClickOutside={onHide}
      onEsc={onHide}
      {...rest}
    >
      <Box width={sizes[size]} overflow='auto'>
        {children}
      </Box>
    </Layer>
  );
};

Modal.Header = ({ onDismiss, children }) => (
  <Box
    direction='row'
    justify='between'
    flex={false}
    pad={{ horizontal: 'medium', top: 'medium', bottom: 'small' }}
  >
    {children}
    {onDismiss && (
      <IconButton icon={<Close />} onClick={onDismiss} tip='Close' />
    )}
  </Box>
);

Modal.Title = ({ children }) => (
  <Heading level={3} margin='none'>
    {children}
  </Heading>
);

Modal.Body = ({ pad, children }) => (
  <Box overflow='auto'>
    <Box pad={pad || 'medium'}>{children}</Box>
  </Box>
);

Modal.Footer = ({ children }) => (
  <Box
    flex={false}
    direction='row'
    justify='start'
    gap='medium'
    pad={{ horizontal: 'medium', top: 'small', bottom: 'medium' }}
  >
    {children}
  </Box>
);
export default Modal;
