// (C) Copyright 2022-2024 Hewlett Packard Enterprise Development LP

import { useQuery } from 'react-query';

import { get } from '../lib/rest';
import { toDateTime } from '../lib/formatters';
import { renderLink } from './helper';
import { makeRestUrl, URL_NETWORKPOLICIES } from '../routes/consts';

const columns = [
  {
    header: 'Name',
    isDefault: true,
    property: 'name',
    render: renderLink({
      base: URL_NETWORKPOLICIES,
    }),
  },
  {
    header: 'ID',
    property: 'id',
  },
  {
    header: 'Created',
    isDefault: true,
    property: 'created',
    render: ({ created }) => toDateTime(created),
  },
  {
    header: 'Description',
    property: 'description',
    isDefault: true,
  },
  {
    header: 'Modified',
    property: 'modified',
    render: ({ modified }) => toDateTime(modified),
  },
];

export const getNetworkPolicies = async () => {
  const response = await get(makeRestUrl(URL_NETWORKPOLICIES));

  return response.json();
};

export const useGetNetworkPolicies = () => {
  const { data, error, isLoading, isError, refetch } = useQuery(
    URL_NETWORKPOLICIES,
    getNetworkPolicies,
  );

  return {
    columns: columns.map((column) => ({
      ...column,
      columnName: column.property,
      displayName: column.header,
    })),
    data,
    error,
    isError,
    isLoading,
    refetch,
  };
};
