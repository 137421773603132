// (C) Copyright 2023-2024 Hewlett Packard Enterprise Development LP

import React from 'react';

import { URL_STORAGE_GROUPS } from '../../routes/consts';
import ListPageWithNav from '../../containers/generic/ListPageWithNav';
import { useGetStorageGroups } from '../../services/storageGroups';
import { NAV_STORAGE } from '../../components/HybridNav/consts.js';

export const StorageGroupsListView = () => (
  <ListPageWithNav
    navSection={NAV_STORAGE}
    actions={{
      allowCreate: 'Create storage group',
    }}
    path={URL_STORAGE_GROUPS}
    sort={{
      property: 'name',
      direction: 'asc',
    }}
    title='Storage groups'
    useGetData={useGetStorageGroups}
  />
);
