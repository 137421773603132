// (C) Copyright 2022-2024 Hewlett Packard Enterprise Development LP

import React from 'react';

import ListPageWithNav from '../../containers/generic/ListPageWithNav';
import { URL_TAGS } from '../../routes/consts';
import { useGetTags } from '../../services/tags';
import { NAV_COMPUTE } from '../../components/HybridNav/consts.js';

const TagListView = () => (
  <ListPageWithNav
    navSection={NAV_COMPUTE}
    actions={{
      allowCreate: 'Create tag',
    }}
    path={URL_TAGS}
    sort={{
      property: 'name',
      direction: 'asc',
    }}
    title='Tags'
    useGetData={useGetTags}
  />
);

export default TagListView;
