// (C) Copyright 2016-2024 Hewlett Packard Enterprise Development LP

import React, { useEffect, useState } from 'react';
import isNil from 'lodash/isNil';
import { Button } from 'grommet';
import HomeView from '../HomeView/HomeView';
import HosterDashboard from '../HostersView/dashboard';
import auth from '../../lib/auth';
import { URL_LOGIN } from '../../routes/consts';
import { useMembershipContext } from '../../utils/context/membershipContext';

const styles = {
  title: {
    paddingTop: 35,
    fontSize: 32,
    fontWeight: 'bold',
  },
  content: {
    paddingTop: 20,
    fontSize: 16,
    fontWeight: 'normal',
  },
  login: {},
};

const Welcome = () => {
  const [userToken, setUserToken] = useState(null);
  const { activeMembership, role } = useMembershipContext();

  useEffect(() => {
    setUserToken(auth.getAccessToken);
  }, []);

  if (!isNil(role)) {
    switch (activeMembership?.scope) {
      case 'hoster':
        return <HosterDashboard />;
      default:
        return <HomeView />;
    }
    // biome-ignore lint/style/noUselessElse: <explanation>
  } else if (isNil(userToken)) {
    return (
      <div>
        <div>
          <div style={styles.title}>Welcome</div>
          <div style={styles.content}>
            <p>To get started, please login.</p>
            <Button href={URL_LOGIN} primary label='login' />
            {/* biome-ignore lint/a11y/useHeadingContent: <explanation> */}
            <h1> </h1>
          </div>
        </div>
      </div>
    );
    // biome-ignore lint/style/noUselessElse: <explanation>
  } else if (isNil(activeMembership) || activeMembership.id === 'none') {
    return (
      <div>
        <div>
          <div style={styles.title}>Welcome back</div>
          <div style={styles.content}>
            To get started you need an active membership. Contact your hoster
            for an invite.
          </div>
        </div>
      </div>
    );
    // biome-ignore lint/style/noUselessElse: <explanation>
  } else {
    return <div>hello, we'll be back soon</div>;
  }
};

export default Welcome;
