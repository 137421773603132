// (C) Copyright 2016-2024 Hewlett Packard Enterprise Development LP

import get from 'lodash/get';
import * as c from '../../routes/consts';
import * as mb from '../../containers/generic/MetaBuilder';
import MetaBuilder from '../../containers/generic/MetaBuilder';
import ItemViewContainer from '../../containers/generic/ItemViewContainer';
import CreateViewContainer from '../../containers/generic/CreateViewContainer';
import EditViewContainer from '../../containers/generic/EditViewContainer';
import auth from '../../lib/auth';
import debugLogger from '../../lib/debug';
import * as log from '../../lib/debug';
import FormRadioGroupInlineAdd from '../../containers/generic/MetaForm/FormRadioGroupInlineAdd';
import DnsUpdateDropdown from './DnsUpdateDropdown';
import FormVlan from '../../containers/generic/MetaForm/FormVlan';
import { LABEL_NETWORKS } from '../../components/HybridNav/consts.js';

const debug = debugLogger('Network settings', log.LOG_LEVEL_WARN);

const EXTERNAL_NAME = c.URL_NETWORKS;
const DS_PROJECTS = 'projects';
const DS_NETWORK_PURPOSES = 'networkpurposes';
const DS_NETWORKS = 'networks';
// const DS_NETWORK_TYPES = 'networktypes'
const DS_HOST_USE = 'hostuse';

export const settings = {
  // used to filter create/trash icons from the view; using the
  // defined roledef permissions mappings to this auth URL entry
  authUrl: EXTERNAL_NAME,
  // homepage for this list view (e.g. /s/hosters/:pid/) is where
  // this view is located; for return from create / item views
  homeUrl: c.makeSecUrl(EXTERNAL_NAME),
  // base url to be used for creating all associated URLS for
  // this reasource, e.g. pageItem, pageCreate, restUrl, restItemUrl
  baseUrl: EXTERNAL_NAME,
  stateKey: EXTERNAL_NAME,
  beforeSubmitCb: () => new Promise(() => ({})),
  homeLabel: LABEL_NETWORKS,
};

function metaCreate(props) {
  const m = new MetaBuilder(props);

  const id = m.view.prop('itemId', false);

  let dsProjects = [];
  const dsAvail = m.newDataSource(c.URL_AVAIL_NETWORK_RESOURCES);
  let ds;
  let createView;

  if (id === undefined) {
    createView = true;
    ds = m.newStaticDataSource({}, DS_NETWORKS);
  } else {
    createView = false;
    ds = m
      .newDataSource(c.URL_NETWORKS, DS_NETWORKS)
      .Item(id)
      .OnLoad(({ data }) => {
        m.view.initForm({
          ...data,
          host_use: data.host_use === '' ? 'Optional' : data.host_use,
        });
      });
  }
  debug.debug('NetworksView ds', ds);

  const dsHostUse = m.newStaticDataSource(
    ['Optional', 'Default', 'Required'],
    DS_HOST_USE,
  );

  const inHosterScope = auth.inScope(c.SCOPE_HOSTER);

  if (inHosterScope) {
    dsProjects = m.newDataSource(c.URL_PROJECTS, DS_PROJECTS);
    dsProjects.Fetch();
  }

  m.addSection('1').NoChrome().MaxWidth(mb.LARGE);

  if (inHosterScope) {
    if (createView) {
      m.addField('_temp.project', 'On behalf of project')
        .Input()
        .DropDown()
        .DataXform(dsProjects, (json) => {
          // using id of 'self' because a value of '' causes loop
          const tms = [{ id: 'self', name: 'Self' }];
          return tms.concat(json.map((t) => ({ id: t.id, name: t.name })));
        })
        .MaxWidth(mb.SMALL)
        .OnChange((val) => {
          let value = val;
          if (value !== undefined) {
            if (value === 'self') {
              dsAvail.DeleteQuery('project');
              value = '';
            } else {
              dsAvail.AddQuery('project', value);
            }
            dsAvail.Fetch();
            m.view.setFormValue('project_id', value);
          }
        });
    } else {
      m.addField('project_id', 'Project')
        .Input()
        .DropDown()
        .MaxWidth(mb.SMALL)
        .DataXform(dsProjects, (json) => {
          const tms = [{ id: '', name: '<Self>' }];
          return tms.concat(json.map((t) => ({ id: t.id, name: t.name })));
        })
        .ReadOnly();
    }
  }

  m.addColumn('location_id', 'Location')
    .Input()
    .Required()
    .DropDown()
    .MaxWidth(mb.SMALL)
    .DataXform(dsAvail, (json) =>
      get(json, 'locations', []).map((i) => ({
        id: i.id,
        name: `${i.country}: ${i.region}: ${i.data_center}`,
      })),
    )
    .OnChange((val) => {
      const locs = dsAvail.Data().locations.filter((a) => a.id === val);

      m.view.setFormValue(
        'location.country',
        get(locs, ['0', 'country'], 'no country'),
      );

      m.view.setFormValue(
        'location.region',
        get(locs, ['0', 'region'], 'no region'),
      );

      m.view.setFormValue(
        'location.data_center',
        get(locs, ['0', 'data_center'], 'no dc'),
      );
    })
    .ReadOnly(props.mode !== 'create');

  m.addColumn('network_name', 'Name')
    .Input()
    .Required()
    .MaxWidth(mb.SMALL)
    .MaxLength(150)
    .ReadOnly(props.mode !== 'create');

  m.addColumn('fabric', 'Fabric').ReadOnly();

  m.addColumn('description', 'Description').Input().MaxWidth(mb.SMALL);

  m.addColumn('id', 'ID');

  m.addColumn('host_use', 'Host network use policy')
    .Input()
    .Required()
    .DropDown()
    .MaxWidth(mb.SMALL)
    .DataXform(dsHostUse, (json) =>
      json.map((item) => ({ id: item, name: item })),
    );

  const dsPurposes = m.newDataSource(DS_NETWORK_PURPOSES);

  m.addColumn('purpose', 'Purpose')
    .Input()
    .DropDown('Select purpose', '', true)
    .MaxWidth(mb.SMALL)
    .DataXform(dsPurposes, (json) =>
      json.filter((option) => !!option).map((t) => ({ id: t, name: t })),
    );

  m.addColumn('ip_pool_id', 'IP Pool')
    .Input()
    .Custom(FormRadioGroupInlineAdd, {});

  m.addColumn('dns_update_config_ip', 'DNS update')
    .Input()
    .MaxWidth(mb.SMALL)
    .Custom(DnsUpdateDropdown, {});

  m.addColumn('vlan_and_vni', 'VLAN and VNI')
    .Input()
    .Custom(FormVlan, { dsAvail });

  if (inHosterScope) {
    if (props.mode === 'create' || props.mode === 'edit') {
      m.addSection('Restricted Use')
        .OverviewText(
          `Set restricted use for this network to the selected projects below.
          If no restrictions are set the network is available to no one.`,
        )
        .MaxWidth(mb.LARGE);

      m.addField('permitted_projects', 'Permitted')
        .Input()
        .MultiSelect()
        .MaxWidth(mb.SMALL)
        .DataXform(m.newDataSource(c.URL_PROJECTS), (json) =>
          json.map((t) => ({ id: t.id, name: t.name })),
        )
        .ReadOnly((formData) => {
          const project_id = formData?.project_id;
          if (props.mode === 'edit') {
            return !!project_id;
          }
          return !m.view.formDefaultTo('_temp.project') === 'self';
        });
    } else {
      m.addField('permitted_projects', 'Permitted projects')
        .Input()
        .ReadOnly()
        .MakeLink('projects');
    }
  }

  return m;
}

export { default as NetworkListView } from './NetworkListView';

export const NetworkItemView = ItemViewContainer({
  ...settings,
  allowEdit: (form) => !!form?.custom,
  meta: (props) => metaCreate(props), // use edit meta
  title: 'Network',
});

export const NetworkCreateView = CreateViewContainer({
  ...settings,
  meta: (props) => metaCreate(props),
  title: 'Create Private Network',
});

export const NetworkEditView = EditViewContainer({
  ...settings,
  meta: (props) => metaCreate(props),
  title: 'Edit Network',
});
