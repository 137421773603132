// (C) Copyright 2023-2024 Hewlett Packard Enterprise Development LP

import React, { useMemo, useState, useEffect } from 'react';
import {
  Box,
  Button,
  Footer,
  FormField,
  Heading,
  Tag,
  TextInput,
  Tip,
} from 'grommet';
import { HelpOption } from 'grommet-icons';
import isEqual from 'lodash/isEqual';
import { useMembershipContext } from '../../utils/context/membershipContext';

const TAGS_TIP =
  'Tags allow you to categorize resources (such as by purpose, owner, environment) for easy management, searching, and filtering.';

export const TagsEditDisplay = (props) => {
  const { data } = props;
  const [form, setForm] = useState({});
  const [isAddingTag, setIsAddingTag] = useState(false);
  const [isEditingTagName, setIsEditingTagName] = useState('');
  const [hasEditedTags, setHasEditedTags] = useState(false);
  const [showTagNameInput, setShowTagNameInput] = useState(false);
  const [tagNameInputError, setTagNameInputError] = useState('');
  const [tagValueInputError, setTagValueInputError] = useState('');
  const { activeMembership } = useMembershipContext();

  const role = useMemo(
    () => activeMembership?.role_id,
    [activeMembership?.role_id],
  );

  const canEdit = useMemo(
    () => ['hoster_owner', 'hoster_admin', 'hoster_viewer'].includes(role),
    [role],
  );

  useEffect(() => {
    if (!hasEditedTags) {
      if (data && !isEqual(data.labels, form.labels)) {
        setForm(data);
      }

      if (props.form && !isEqual(props.form.labels, form.labels)) {
        setForm(props.form);
      }

      if (!data && !props.form && !isEqual(props.labels, form.labels)) {
        setForm(props);
      }
    }
  }, [data, props, form, hasEditedTags]);

  const handleClickAddTag = (evt) => {
    if (!form.labels) {
      setForm({ ...form, labels: {} });
    }

    setIsAddingTag(true);
    setIsEditingTagName('');
    setShowTagNameInput(true);
  };

  const checkTagValidity = (formLabels) => {
    let numUserTags = 0;
    let numSystemTags = 0;
    const entries = Object.entries(formLabels);

    // biome-ignore lint/complexity/noForEach: <explanation>
    entries.forEach(([key]) => {
      if (key.toLowerCase().startsWith('hpe:')) {
        numSystemTags++;
      } else {
        numUserTags++;
      }
    });

    const tagregex = /^[\p{L}\p{N}\p{Z}_.:=+\-@]*$/u;

    if (numSystemTags >= 50) {
      setTagNameInputError(
        'You have reached the maximum number of system tags allowed',
      );
      return false;
    }

    if (numUserTags >= 50) {
      setTagNameInputError(
        'You have reached the maximum number of user tags allowed',
      );
      return false;
    }

    if (!form.tagName) {
      setTagNameInputError('Tag name must be at least one character');
      return false;
    }

    if (formLabels[form.tagName] && form.tagName !== isEditingTagName) {
      setTagNameInputError('Tag name already exists');
      return false;
    }

    if (form.tagName?.length > 128) {
      setTagNameInputError('Tag name cannot be more than 128 characters');
      return false;
    }

    if (form.tagName?.toLowerCase().startsWith('hpe:') && !canEdit) {
      setTagNameInputError('You do not have permission to use HPE system tags');
      return false;
      // biome-ignore lint/style/noUselessElse: <explanation>
    } else if (!tagregex.test(form.tagName)) {
      setTagNameInputError('Tag name contains invalid characters');
      return false;
    }

    if (!tagregex.test(form.tagName)) {
      setTagNameInputError('Tag name contains invalid characters');
      return false;
    }

    if (form.tagValue?.length > 256) {
      setTagValueInputError('Tag value cannot be more than 256 characters');
      return false;
    }

    if (!tagregex.test(form.tagValue)) {
      setTagValueInputError('Tag value contains invalid characters');
      return false;
    }

    return true;
  };

  const handleClickConfirmAddNewTag = () => {
    const formLabels = form.labels || {};

    if (checkTagValidity(formLabels)) {
      if (data && props.setForm) {
        props.setForm({
          ...data,
          labels: { ...form.labels, [form.tagName]: form.tagValue || '' },
          tagName: '',
          tagValue: '',
        });
      } else if (props.form && props.setForm) {
        props.setForm({
          ...props.form,
          labels: { ...form.labels, [form.tagName]: form.tagValue || '' },
          tagName: '',
          tagValue: '',
        });
        if (props.setParentForm) {
          props.setParentForm('labels', {
            ...form.labels,
            [form.tagName]: form.tagValue || '',
          });
        }
      } else if (props.col) {
        props?.col?.mb?.view?.setFormValue('labels', {
          ...form.labels,
          [form.tagName]: form.tagValue || '',
        });
        props?.col?.mb?.parentView?.setFormValue('labels', {
          ...form.labels,
          [form.tagName]: form.tagValue || '',
        });
      }

      if (isEditingTagName && form.tagName !== isEditingTagName) {
        const finalLabels = formLabels;
        delete finalLabels[isEditingTagName];
        setForm({
          ...form,
          labels: { ...finalLabels, [form.tagName]: form.tagValue },
          tagName: '',
          tagValue: '',
        });
      } else {
        setForm({
          ...form,
          labels: { ...formLabels, [form.tagName]: form.tagValue },
          tagName: '',
          tagValue: '',
        });
      }

      setIsEditingTagName('');
      setIsAddingTag(false);
      setShowTagNameInput(false);
      setHasEditedTags(true);
      return true;
    }

    return false;
  };

  const handleClickRemoveTag = (key, evt) => {
    evt.stopPropagation();
    const updatedLabels = { ...form.labels };
    delete updatedLabels[key];

    if (data) {
      props.setForm
        ? props.setForm({ ...data, labels: updatedLabels })
        : props?.col?.mb?.view?.setFormValue('labels', updatedLabels);
    }

    if (props.form) {
      props.setForm
        ? props.setForm({ ...props.form, labels: updatedLabels })
        : props?.col?.mb?.view?.setFormValue('labels', updatedLabels);

      if (props.setParentForm) {
        props.setParentForm('labels', updatedLabels);
      }
    }

    setForm({ ...form, labels: updatedLabels });
    setHasEditedTags(true);
  };

  const handleClickEditTag = (key, value) => {
    setForm({ ...form, tagName: key, tagValue: value });
    setIsAddingTag(false);
    setIsEditingTagName(key);
    setShowTagNameInput(true);
  };

  const tags = Object.entries(form.labels || {})
    .filter(([key]) => canEdit && key !== '__uniqueid')
    .map(([key, value]) => (
      <Box
        key={key}
        margin='small'
        onClick={() => {
          handleClickEditTag(key, value);
        }}
      >
        <Tag
          alignSelf='start'
          name={key}
          value={value}
          onRemove={(evt) => handleClickRemoveTag(key, evt)}
        />
      </Box>
    ));

  let addTagButton = (
    <Button
      fill={false}
      label='+ Add Tag'
      secondary
      type='button'
      onClick={() => handleClickAddTag()}
    />
  );

  if (isAddingTag) {
    addTagButton = (
      <Button
        fill={false}
        label='Add'
        secondary
        type='button'
        onClick={() => handleClickConfirmAddNewTag()}
      />
    );
  } else if (isEditingTagName) {
    addTagButton = (
      <Button
        fill={false}
        label='Update Tag'
        secondary
        type='button'
        onClick={() => handleClickConfirmAddNewTag()}
      />
    );
  }

  return (
    <Box direction='column'>
      <Box direction='row' align='center'>
        <Heading
          level={4}
          size='small'
          fill={false}
          margin={{ bottom: 'xxsmall' }}
        >
          Tags
        </Heading>
        <Box margin={{ left: 'xxsmall', bottom: 'xxsmall', top: 'medium' }}>
          <Tip content={TAGS_TIP}>
            <HelpOption />
          </Tip>
        </Box>
      </Box>
      <Box direction='row' align='center' wrap='wrap'>
        {tags}
      </Box>
      <Box direction='row' align='start' margin='small' pad='small'>
        {showTagNameInput && (
          <FormField label={'Name'} error={tagNameInputError}>
            <TextInput
              id='tagName'
              htmlFor='tagName'
              label='Name'
              name='tagName'
              placeholder='Enter tag name'
              error={tagNameInputError}
              onChange={(evt) => {
                setForm({ ...form, tagName: evt.target.value });
                setTagNameInputError('');
              }}
              value={form.tagName}
            />
          </FormField>
        )}
        {form.tagName && (
          <FormField
            label={'Value'}
            error={tagValueInputError}
            margin={{ left: 'small' }}
          >
            <TextInput
              id='tagValue'
              htmlFor='tagValue'
              label='Value'
              name='tagValue'
              placeholder='Enter tag value'
              error={tagNameInputError}
              onChange={(evt) => {
                setForm({ ...form, tagValue: evt.target.value });
                setTagNameInputError('');
              }}
              value={form.tagValue}
            />
          </FormField>
        )}
      </Box>
      <Footer align='start' gap='small' justify='start' pad={{ top: 'medium' }}>
        {addTagButton}
      </Footer>
    </Box>
  );
};
