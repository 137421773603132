// (C) Copyright 2016-2024 Hewlett Packard Enterprise Development LP

import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Box, Page, Text } from 'grommet';
import styled from 'styled-components';
import qs from '../../lib/querystring';
import { MainHeader, SideNavBar, ViewArea, ViewFooter } from '../../components';
import { CONFIDENTIAL } from '../../routes/consts.js';
import CoreRoutes from '../../routes/CoreRoutes.js';
import auth from '../../lib/auth';
import { usePortalCert } from '../../utils';
import * as c from '../../routes/consts';
import { useMembershipContext } from '../../utils/context/membershipContext';

const MS_PER_DAY = 24 * 60 * 60 * 1000;
const REFRESH_CERT = MS_PER_DAY / 2;

const PortalCertStatus = ({ scope }) => {
  const certificates = usePortalCert(REFRESH_CERT, scope);

  const hasMessage = useMemo(
    () => certificates.some(({ statusMessage }) => !!statusMessage),
    [certificates],
  );

  return hasMessage ? (
    <Box
      pad='small'
      align='center'
      justify='center'
      background='status-critical'
    >
      {certificates.map(({ id, statusMessage }) => (
        <Box key={id} pad={{ vertical: 'xxsmall' }}>
          <Text color='text-strong'>{statusMessage}</Text>
        </Box>
      ))}
    </Box>
  ) : null;
};

const MainLayout = styled(Box)`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const MainContent = styled(Box)`
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
`;

const Main = styled(Page)`
  padding-top: 75px;
  padding-left: 24px;
  padding-right: 48px;
  width: 1536px;
  margin: auto;
  display: flex;
  flex-direction: row;
  flex: 1 0 auto;
`;

const Column = styled.nav`
  flex: 0 0 auto;
  &:first-child {
    width: 192px;
  }
 
  &:nth-child(2) {
    flex: 1;
    overflow-x: auto;
  }
 
  @media (max-width: 768px) {
    &:first-child {
      width: 140px;
    }
  }
`;

const CoreLayout = ({ children }) => {
  const { role } = useMembershipContext();
  const isIAM = auth.isIAM();
  const nav = qs.param('nav') !== '0';

  return nav ? (
    <MainLayout>
      <MainHeader
        confidential={CONFIDENTIAL}
        hideMemberships={isIAM}
        hideHelp={isIAM}
      >
        {role?.scope === c.SCOPE_PORTAL ? <PortalCertStatus /> : null}
      </MainHeader>
      <MainContent>
        <Main>
          <Column>
            <SideNavBar />
          </Column>
          <Column>
            <ViewArea>
              <CoreRoutes />
            </ViewArea>
          </Column>
        </Main>
      </MainContent>
      <Box flex>
        <ViewFooter />
      </Box>
    </MainLayout>
  ) : (
    <div className='standalone-page-container'>
      <ViewArea>{children}</ViewArea>
      <ViewFooter />
    </div>
  );
};

CoreLayout.propTypes = {
  children: PropTypes.element,
  scope: PropTypes.string,
};

export default CoreLayout;
