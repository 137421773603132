// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

import React, { createContext, useReducer } from 'react';

export const alertsActions = {
  add: 'ADD_ALERT',
  remove: 'REMOVE_ALERT',
};

export const alertsInitialState = {
  alerts: [],
};

export const alertsReducer = (state, { type, payload }) => {
  switch (type) {
    case alertsActions.add:
      return {
        alerts: [payload, ...state.alerts],
      };
    case alertsActions.remove:
      return {
        alerts: state.alerts.filter((alert) => alert.id !== payload.id),
      };
    default:
      return state;
  }
};

export const AlertsContext = createContext(alertsInitialState);

export const AlertsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(alertsReducer, alertsInitialState);

  const removeAlert = (alert) => {
    dispatch({
      type: alertsActions.remove,
      payload: alert,
    });
  };

  const addAlert = (message, style = 'info') => {
    const payload = {
      id: state.alerts.length + 1,
      message,
      style,
    };

    dispatch({
      type: alertsActions.add,
      payload,
    });

    setTimeout(() => {
      removeAlert(payload);
    }, 5000);
  };

  return (
    <AlertsContext.Provider
      value={{
        addAlert,
        alerts: state.alerts,
        removeAlert,
      }}
    >
      {children}
    </AlertsContext.Provider>
  );
};
