// (C) Copyright 2016-2024 Hewlett Packard Enterprise Development LP

// TODO refactor to remove lint issues

import differenceWith from 'lodash/differenceWith';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import find from 'lodash/find';
import matches from 'lodash/matches';

import * as c from '../../routes/consts';
import * as mb from '../../containers/generic/MetaBuilder';
import MetaBuilder, {
  ValidationResult,
} from '../../containers/generic/MetaBuilder';
import {
  CONTROLLER_TYPE_ENUMS,
  CONTROLLER_TYPE_POD_CTLR,
  DEFAULT_CONTROLLER_TYPE,
  DEFAULT_PODTYPE,
  POD_TYPE_AFC,
  POD_TYPE_PLEXXI,
} from '../../data/pod';
import {
  BMC_ROUTING_ENUMS,
  BMC_SUBNET_ROUTABLE,
  DEFAULT_BMC_ROUTING,
} from '../../data/bmcRouting';
import * as REGEX from '../../data/regex';
import FormIpPool from '../../containers/generic/MetaForm/FormIpPool';
import FormCustomConnections from '../../containers/generic/MetaForm/FormCustomConnections';
import NamesField from '../../containers/generic/MetaForm/NamesField';
import debugLogger, * as log from '../../lib/debug';
import { UnmanagedSwitch } from '../../containers/generic/MetaForm/UnmanagedSwitch';

const debug = debugLogger('Pod settings', log.LOG_LEVEL_DEBUG);

const DS_PODS = 'pods';
const DS_RACK_TEMPLATES = 'racktemplates';
const DS_ALL_IP_POOLS = 'allippools';
const DS_NETWORK_TYPES = 'networktypes';
const DS_POD_TYPES = 'podtypes';
const DS_NETWORK_PURPOSES = 'networkpurposes';
const DS_PROJECTS = 'projects';
const DS_RACKS = 'racks';
const DS_SWITCHES = 'switches';
const DS_SWITCHTYPES = 'switchtypes';
const DS_NETWORKS = 'networks';
const serviceNetworks = ['service-vni', 'service-vlan'];

export function metaFabricAdd(props) {
  const m = new MetaBuilder(props);

  let otherFabrics = m.parentView?.stateDefaultTo('form.fabrics', []) || [];
  const origForm = m.view.prop('formData', false);
  if (!isNil(origForm)) {
    otherFabrics = differenceWith(otherFabrics, [origForm], isEqual);
  }

  const dsIpPools = m.getDataSource(DS_ALL_IP_POOLS);
  const dsPodTypes = m.getDataSource(DS_POD_TYPES);

  let curFabricType = {};
  const setCurFabricType = (val) => {
    const pt = dsPodTypes.Data();
    curFabricType = pt ? pt.find((v) => v.id === val) : {};
  };

  m.addField('name', 'Name')
    .Input()
    .MaxWidth(mb.SMALL)
    .Required()
    .UniqueIn(otherFabrics);

  m.addField('description', 'Description').Input().MaxWidth(mb.SMALL);

  m.addField('type', 'Type')
    .Input()
    .Required()
    .DropDown()
    .MaxWidth(mb.SMALL)
    .DataXform(dsPodTypes, (json) =>
      json.map((t) => ({ id: t.id, name: t.name, info: t.description })),
    )
    .OnChange((val) => {
      setCurFabricType(val);
      m.view.setFormValue('ip_pool_id', '');
      if (get(curFabricType, 'vid_pool_required', false)) {
        m.view.setFormValue('reserved_vlans', '3900:3990');
      } else {
        m.view.setFormValue('reserved_vlans', '');
      }
      if (get(curFabricType, 'vni_pool_required', false)) {
        m.view.setFormValue('reserved_vnis', '10001:30000');
      } else {
        m.view.setFormValue('reserved_vnis', '');
      }
      if (!get(curFabricType, 'centralized_fabric_mgr', false)) {
        m.view.setFormValue('cfm_address', '');
      }
    })
    .OnInit(() => {
      setCurFabricType(m.view.formDefaultTo('type'));
    });

  m.addField('ip_pool_id', 'IP Pool')
    .Input()
    .RequiredSometimes(() => get(curFabricType, 'router_ip_pool', false))
    .Visible(() => get(curFabricType, 'router_ip_pool', false))
    .MaxWidth(mb.SMALL)
    .DropDown()
    .DataXform(dsIpPools, (json) => json);

  m.addField('reserved_vlans', 'Reserved VIDs')
    .Input('3900:3990')
    .MaxWidth(mb.SMALL)
    .Visible(() => get(curFabricType, 'vid_pool_required', false))
    .RequiredSometimes(() => get(curFabricType, 'vid_pool_required', false))
    .RegEx(REGEX.VLAN_POOL, REGEX.VLAN_POOL_MSG);

  m.addField('reserved_vnis', 'Reserved VNIs')
    .Input('10001:30000')
    .Visible(() => get(curFabricType, 'vni_pool_required', false))
    .RequiredSometimes(() => get(curFabricType, 'vni_pool_required', false))
    .MaxWidth(mb.SMALL)
    .RegEx(REGEX.VNI_POOL, REGEX.VNI_POOL_MSG);

  m.addField('asn_prefix', 'ASN prefix')
    .Input(62000)
    .MaxWidth(mb.SMALL)
    .Visible(() => get(curFabricType, 'vni_pool_required', true))
    .RegEx(REGEX.ASN_PREFIX, REGEX.ASN_PREFIX_MSG)
    .Number();

  m.addField('evpn_system_mac_lower', 'EVPN system MAC lower')
    .Input('02:00:00:55:01:00')
    .MaxWidth(mb.SMALL)
    .Visible(() => get(curFabricType, 'vni_pool_required', true))
    .RegEx(REGEX.MAC_ADDRESS, REGEX.MAC_ADDRESS_MSG);

  m.addField('evpn_system_mac_upper', 'EVPN system MAC upper')
    .Input('02:00:00:55:01:ff')
    .MaxWidth(mb.SMALL)
    .Visible(() => get(curFabricType, 'vni_pool_required', true))
    .RegEx(REGEX.MAC_ADDRESS, REGEX.MAC_ADDRESS_MSG);

  m.addField('mgr_address', 'Manager Address')
    .Input('')
    .MaxWidth(mb.SMALL)
    .Visible(() => get(curFabricType, 'centralized_fabric_mgr', false))
    .RequiredSometimes(() =>
      get(curFabricType, 'centralized_fabric_mgr', false),
    )
    .RegEx(REGEX.IPV4_ADDRESS_PORT, REGEX.IPV4_ADDRESS_PORT_MSG);

  return m;
}

export function metaNetworkAdd(props) {
  const m = new MetaBuilder(props);

  // if edit, then remove item being edited from unique set
  let otherNetworks = m.parentView?.stateDefaultTo('form.networks', []) || [];
  const dsNetworks = m.getDataSource(DS_NETWORKS).Data();
  const origForm = m.view.prop('formData', false);

  if (!isNil(origForm)) {
    const networks = otherNetworks.map((formNetwork) => {
      const network = dsNetworks.find(
        ({ id: dsId }) => formNetwork.id === dsId,
      );

      return {
        ...formNetwork,
        ...(network?.peer_id ? { peer_id: network.peer_id } : {}),
      };
    });

    otherNetworks = networks.filter(
      ({ id, peer_id: pId }) => origForm.id !== pId && origForm.id !== id,
    );
  }

  const dsIpPools = m.getDataSource(DS_ALL_IP_POOLS);
  const dsIpPoolsFiltered = m.newStaticDataSource([]);
  const dsPodTypes = m.getDataSource(DS_POD_TYPES);
  const dsNetTypes = m.getDataSource(DS_NETWORK_TYPES);

  let curNetworkFabricType = {};
  const setCurNetworkFabricType = (val) => {
    const dsPods = m.getDataSource(DS_PODS);
    const podData = dsPods.Data();
    const filteredPodFabric = podData.fabrics.find((s) => s.name === val);
    const pt = dsPodTypes.Data();
    curNetworkFabricType = pt
      ? pt.find((v) => v.id === filteredPodFabric.type)
      : {};
    dsNetTypes.Filter((t) =>
      t.pod_types.some((pts) => pts === curNetworkFabricType?.id),
    );
  };

  // local placeholder for NetType description object corresponding to
  // the currently selected network type
  let curNetType = {};

  // set dsCurNetType to the NetworkType description of the
  // selected network type
  const setCurNetType = (netType) => {
    const dsNT = m.getDataSource(DS_NETWORK_TYPES);
    const nts = isArray(dsNT.Data()) ? dsNT.Data() : [];
    curNetType = nts.find((t) => t.id === netType);
  };

  // set dsIpPoolsFiltered to be the IP pools that match the ip_pool_type
  const refilterIpPools = () => {
    const pools = isArray(dsIpPools.Data()) ? dsIpPools.Data() : [];
    dsIpPoolsFiltered.SetData(pools);
  };

  const dsHostUse = m.newStaticDataSource(['Required', 'Default', 'Optional']);

  m.addField('name', 'Name')
    .Input()
    .Custom(NamesField)
    .CustomValidator(() => {
      const { name } = m.view.getForm();

      if (!name) {
        return new ValidationResult(false, 'Name is a required field.');
      }

      return new ValidationResult(true);
    });

  m.addField('description', 'Description').Input().MaxWidth(mb.SMALL);

  const ds = m.getDataSource(DS_PODS);
  m.addField('fabric', 'Fabric')
    .Input()
    .Required()
    .MaxWidth(mb.SMALL)
    .DropDown()
    .DataXform(ds, (json) =>
      json.fabrics.map((t) => ({ id: t.name, name: t.name })),
    )
    .OnChange((val) => {
      setCurNetworkFabricType(val);
    })
    .OnInit(() => {
      setCurNetworkFabricType(m.view.formDefaultTo('fabric'));
    });

  // type is set when the Network name is selected, and can't be modified.
  m.addField('type', 'Type')
    .Input()
    .Required()
    .MaxWidth(mb.SMALL)
    .DropDown()
    .DataXform(dsNetTypes, (json) =>
      json.map((t) => ({ id: t.id, name: t.name })),
    )
    .OnChange((val) => {
      setCurNetType(val);
      refilterIpPools();
    })
    .OnInit(() => {
      setCurNetType(m.view.formDefaultTo('type'));
      refilterIpPools();
    });

  m.addField('host_use', 'Host network use policy')
    .Input()
    .Required()
    .MaxWidth(mb.SMALL)
    .DropDown()
    .DataXform(dsHostUse, (json) => json.map((t) => ({ id: t, name: t })));

  const dsPurposes = m.getDataSource(DS_NETWORK_PURPOSES);
  m.addField('purpose', 'Purpose')
    .Input()
    .MaxWidth(mb.SMALL)
    .DropDown('Select purpose', '', true)
    .DataXform(dsPurposes, (json) =>
      json.filter((option) => !!option).map((t) => ({ id: t, name: t })),
    );

  // Future network types may need vrf_name (direct connection to named VRF)
  m.addField('vrf_name', 'VRF name')
    .Input()
    .Visible(() => get(curNetType, 'vrf_name', false))
    .RequiredSometimes(() => get(curNetType, 'vrf_name', false))
    .MaxWidth(mb.SMALL);

  // Hack for Plexxi & AFC.  AFC requires a VID, but not Plexxi CFM.
  m.addField('vlan_id', 'VLAN ID')
    .Input()
    .Visible(
      () =>
        get(curNetType, 'vlan_id', false) &&
        curNetworkFabricType?.id !== POD_TYPE_PLEXXI,
    )
    .RequiredSometimes(
      () =>
        get(curNetType, 'vlan_id', false) &&
        curNetworkFabricType?.id !== POD_TYPE_PLEXXI,
    )
    .UniqueIn(otherNetworks)
    .MaxWidth(mb.SMALL)
    .RegEx(REGEX.VLAN, REGEX.VLAN_MSG);

  m.addField('vni', 'VNI')
    .Input()
    .Visible(() => get(curNetType, 'vni', false))
    .RequiredSometimes(() => get(curNetType, 'vni', false))
    .MaxWidth(mb.SMALL)
    .RegEx(REGEX.VNI, REGEX.VNI_MSG);

  m.addField('ip_pool_id', 'IP pool')
    .Input()
    .DropDown()
    .MaxWidth(mb.SMALL)
    .DataXform(dsIpPoolsFiltered, (json) => {
      const otherIpPoolOptions = get(curNetType, 'private', false)
        ? [{ id: '', name: 'Default IP Pool' }]
        : json
            .filter((ipPool) => !ipPool.ip_pool_factory_id)
            .map((p) => ({ id: p.id, name: p.name }));

      return [
        ...otherIpPoolOptions,
        { id: 'new_ip_pool', name: 'Add new IP pool...' },
      ];
    })
    .OnInit(() => {
      const { type } = m.view.getForm();

      // biome-ignore lint/complexity/useOptionalChain: <explanation>
      if (type && type.includes('private')) {
        m.view.setFormValue('ip_pool_id', '');
      }
    });

  m.addField('new_ip_pool', 'New IP pool')
    .Input()
    .MaxWidth(mb.SMALL)
    .Custom(FormIpPool, {})
    .Visible(() => m.view.formDefaultTo('ip_pool_id') === 'new_ip_pool')
    .CustomValidator(() => {
      const form = m.view.getForm();

      if (form.payload) {
        const { ip_pool_id: ipPoolId, payload } = m.view.getForm();

        const msg = {};

        if (
          ipPoolId === 'new_ip_pool' &&
          (!payload?.base_ip || !payload?.default_route || !payload?.name)
        ) {
          if (!payload?.base_ip) {
            msg.base_ip = 'Base IP is a required field.';
          }

          if (!payload?.default_route) {
            msg.default_route = 'Default Route is a required field.';
          }

          if (!payload?.name) {
            msg.name = 'Name is a required field.';
          }

          return new ValidationResult(false, msg);
        }
      }

      return new ValidationResult(true);
    });

  m.addField('dns_update_config_ip', 'DNS server')
    .Input()
    .MaxWidth(mb.SMALL)
    .DropDown('Select DNS server', '', true)
    .Clearable()
    .DataXform(m.newStaticDataSource([]), () => {
      const { dns_update_configs: list = [], id } =
        m.parentView?.getForm() || {};

      const usedIps = dsNetworks
        .filter(
          ({ location_id: podId, dns_update_config_ip: ip }) =>
            podId === id && !!ip,
        )
        .map(({ dns_update_config_ip: ip }) => ip);

      const formIp = props.formData?.dns_update_config_ip;

      return list
        .filter(({ ip }) => formIp === ip || !usedIps.includes(ip))
        .map(({ ip }) => ({
          id: ip,
          name: ip,
        }));
    });

  // Networking v3 addition
  const dsProjects = m.getDataSource(DS_PROJECTS);
  m.addSection('Restricted use')
    .MaxWidth(1200)
    .OverviewText(
      `Set restricted use for this network to the selected projects below.
      If no restrictions are set the network is available to anyone for
      Primary/Service networks, or no one for Restricted networks.`,
    );

  if (
    props.formData &&
    serviceNetworks.some((type) => type === props.formData.type)
  ) {
    m.addColumn('permitted_projects', 'Permitted projects')
      .Input()
      .MaxWidth(mb.SMALL)
      .MultiSelect()
      .DataXform(dsProjects, (json) =>
        json.map(({ id, name }) => ({ id, name })),
      );
  } else {
    m.addColumn('permitted_projects', 'Permitted projects')
      .Input()
      .MultiSelect()
      .MaxWidth(mb.SMALL)
      .DataXform(dsProjects, (json) =>
        json.map(({ id, name }) => ({ id, name })),
      )
      .Visible(() =>
        serviceNetworks.every((type) => type !== m.view.getForm().type),
      );

    m.addColumn('multi.client.projects', 'Permitted client projects')
      .Input()
      .MultiSelect()
      .MaxWidth(mb.SMALL)
      .DataXform(dsProjects, (json) =>
        json.map(({ id, name }) => ({ id, name })),
      )
      .Visible(() =>
        serviceNetworks.some((type) => type === m.view.getForm().type),
      );

    m.addColumn('multi.provider.projects', 'Permitted provider projects')
      .Input()
      .MultiSelect()
      .MaxWidth(mb.SMALL)
      .DataXform(dsProjects, (json) =>
        json.map(({ id, name }) => ({ id, name })),
      )
      .Visible(() =>
        serviceNetworks.some((type) => type === m.view.getForm().type),
      );
  }

  return m;
}

export function metaDnsConfigAdd(props) {
  const m = new MetaBuilder(props);

  m.addField('ip', 'DNS server IP')
    .Input()
    .MaxWidth(mb.SMALL)
    .Required()
    .CustomValidator((value) => {
      const regex = new RegExp(REGEX.IPV4_ADDRESS);

      if (!regex.test(value)) {
        return new ValidationResult(false, REGEX.IPV4_ADDRESS_MSG);
      }

      const { dns_update_configs: configs = [] } =
        m.parentView?.getForm() || {};

      const usedIps = configs.map(({ ip }) => ip);

      const { ip } = props.formData || {};

      if (usedIps.includes(value) && ip !== value) {
        return new ValidationResult(false, 'IP is being used.');
      }

      return new ValidationResult(true, '');
    });

  m.addField('domain', 'DNS domain name')
    .Input()
    .MaxWidth(mb.SMALL)
    .Required()
    .RegEx(REGEX.HOSTNAME, REGEX.DOMAIN_MSG);

  m.addField('dns_update_style', 'Update method')
    .Input()
    .Required()
    .MaxWidth(mb.SMALL)
    .DropDown()
    .DataXform(m.newStaticDataSource([]), () => [
      { id: 'pdns', name: 'PDNS' },
      { id: 'rfc2136', name: 'RFC2136' },
    ]);

  m.addField('port', 'DNS server port')
    .Input()
    .MaxWidth(mb.SMALL)
    .RegEx(REGEX.PORT, REGEX.PORT_MSG)
    .Required();

  if (props.formData) {
    m.addField('api_key', 'DNS server API key').Input().MaxWidth(mb.SMALL);
  } else {
    m.addField('api_key', 'DNS server API key')
      .Input()
      .Required()
      .MaxWidth(mb.SMALL);
  }

  return m;
}

export function metaCreate(props) {
  const m = new MetaBuilder(props);

  m.view.formDefaultTo('pod_features.multi_fabric');
  const dsNetworks = m.newDataSource(DS_NETWORKS);
  const bmcRouting = m.newStaticDataSource(BMC_ROUTING_ENUMS);

  // static data pulled from portal
  const dsNetTypes = m.newDataSource(c.URL_NETWORKTYPES, DS_NETWORK_TYPES);
  const dsPodTypes = m.newDataSource(c.URL_PODTYPES, DS_POD_TYPES);

  let rackTemplateData;
  const dsRackTemplate = m
    .newDataSource(c.URL_RACKTEMPLATES, DS_RACK_TEMPLATES)
    .OnLoad((json) => {
      rackTemplateData = json.data;
    });

  const dsAllIpPools = m.newDataSource(c.URL_IPPOOLS, DS_ALL_IP_POOLS);

  m.newDataSource(c.URL_NETWORKPURPOSES, DS_NETWORK_PURPOSES);
  m.newDataSource(c.URL_PROJECTS, DS_PROJECTS);

  const id = m.view.prop('itemId', false);

  let ds;
  let hostProvisioning = '';
  let privateNetworkFabric = '';

  if (!id) {
    ds = m.newStaticDataSource({}, DS_PODS);
  } else {
    ds = m
      .newDataSource(c.URL_PODS, DS_PODS)
      .Item(id)
      .OnLoad((json) => {
        // host provisioning
        json.data?.fabrics.map((fabric) => {
          const provisioning = fabric?.provisioning;
          if (provisioning) {
            hostProvisioning = fabric.name;
            return true;
          }
          return false;
        });
        json.data?.fabrics.map((fabric) => {
          const privateNetSelected = fabric?.project_private_nets;
          if (privateNetSelected) {
            privateNetworkFabric = fabric.name;
            return true;
          }
          return false;
        });

        const podData = json.data;
        if (hostProvisioning !== '' && !isNil(hostProvisioning)) {
          podData.hostProvisioning = hostProvisioning;
        }
        if (privateNetworkFabric !== '' && !isNil(privateNetworkFabric)) {
          podData.privateNetworkFabric = privateNetworkFabric;
        }
        m.view.initForm(podData);
        m.view.setFormValue(
          '_temp.unmanaged',
          podData.pod_features.unmanaged_switch,
        );
      });
  }

  // Map of currently available networks
  const availNetworks = new Map();
  const updateAvailNetworks = (form) => {
    availNetworks.clear();

    if (form.networks) {
      // biome-ignore lint/complexity/noForEach: <explanation>
      form.networks.forEach((network) => {
        availNetworks.set(network.name, network);
      });
      // trigger validation update via setState()
      m.view.setFormValue('_temp.availNetworks', availNetworks);
    }
  };

  // Map of currently available fabrics
  let availFabrics = [];
  const updateAvailFabrics = (form) => {
    availFabrics = [];

    if (form.fabrics) {
      // biome-ignore lint/complexity/noForEach: <explanation>
      form.fabrics.forEach((fabric) => {
        // availFabrics.set(fabric.name, fabric);
        availFabrics.push(fabric.name);
      });
      // trigger validation update via setState()
      m.view.setFormValue('_temp.availFabrics', availFabrics);
    }

    if (!isNil(rackTemplateData)) {
      const filteredArray = rackTemplateData
        .filter((fabric) =>
          fabric.switch_sets.some((switchSet) =>
            availFabrics.includes(switchSet.name),
          ),
        )
        .map((fabric) => ({
          ...fabric,
          switch_sets: fabric.switch_sets.filter((switchSet) =>
            availFabrics.includes(switchSet.name),
          ),
        }));

      dsRackTemplate.SetData(filteredArray);
    }

    if (form.rack_Templates) {
      const result = form.rack_templates.filter((o1) =>
        dsRackTemplate.Data().some((o2) => o1.id === o2.id),
      );
      if (result.length <= 0) {
        m.view.setFormValue('rack_templates', []);
      }
    }
  };

  const updateNetworks = () => {
    if (!isNil(ds.data) && !isNil(ds.data.networks)) {
      const filteredNetwork = ds.data?.networks.filter((network) =>
        availFabrics.includes(network.fabric),
      );
      m.view.setFormValue('networks', filteredNetwork);
    }
  };

  let curProvisionType = {};
  const setCurProvisionType = (val) => {
    const data = ds.Data();
    const pt = dsPodTypes.Data();
    if (!isNil(data) && !isNil(data.fabrics) && !isNil(pt)) {
      data.fabrics.map((t) => {
        if (t.name === val) {
          pt.map((v) => {
            if (v.id === t.type) {
              curProvisionType = v;
            }
            return true;
          });
        }
        return true;
      });
    }
  };

  let curFabricForPrivateNet = {};
  const setCurFabricForPrivateNet = (val) => {
    const pt = dsPodTypes.Data();
    curFabricForPrivateNet = pt ? pt.find((v) => v.id === val) : {};
  };

  const setHostProvision = (form, val) => {
    if (form.fabrics) {
      // biome-ignore lint/complexity/noForEach: <explanation>
      form.fabrics.forEach((fabric) => {
        if (fabric.name === val) {
          fabric.provisioning = true;
        } else {
          // biome-ignore lint/performance/noDelete: <explanation>
          delete fabric.provisioning;
        }
      });
      debug.debug('form.fabrics host', form.fabrics);
    }
  };

  const setPrivateNetworkFabric = (form, val) => {
    if (form.fabrics) {
      // biome-ignore lint/complexity/noForEach: <explanation>
      form.fabrics.forEach((fabric) => {
        if (fabric.name === val) {
          fabric.project_private_nets = true;
        } else {
          fabric.project_private_nets = undefined;
        }
      });
      debug.debug('form.fabrics private', form.fabrics);
    }
  };

  m.addSection('1').NoChrome().MaxWidth(mb.XXLARGE);

  m.addColumn('name', 'Name').Input().Required().MaxWidth(mb.SMALL);

  m.addColumn('description', 'Description').Input().MaxWidth(mb.SMALL);

  if (props.mode === 'view') {
    m.addColumn('id', 'ID').MaxWidth(mb.SMALL);
  }

  // Location section
  m.addSection('Location')
    .MaxWidth(mb.XXLARGE)
    .OverviewText(
      `Pod location information will be used when offering users possible
      sites for their servers(hosts).`,
    );

  m.addField('location.country', 'Country')
    .Input('USA')
    .Required()
    .MaxWidth(mb.SMALL)
    .CountryDropDown();

  m.addField('location.region', 'Region').Input().Required().MaxWidth(mb.SMALL);

  m.addField('location.data_center', 'Data center')
    .Input()
    .Required()
    .MaxWidth(mb.SMALL);

  // Fabrics section
  m.addSection('Fabrics')
    .MaxWidth(mb.XXLARGE)
    .OverviewText('Configure network fabrics to be used');

  m.addField('_temp.fabricValidator', '')
    .Input()
    .Hidden()
    .CustomValidator(() => {
      if (availFabrics.length === 0) {
        return new ValidationResult(false, 'Fabrics are required.');
      }
      return new ValidationResult(true, '');
    });

  const fabricTable = m
    .addInputTable('fabrics', 'Fabric')
    .DataXform(ds, (json) => {
      debug.debug('fabricTable:DataXform', ds, json);
      return json.fabrics || [];
    })
    .OnInit(() => {
      updateAvailFabrics(m.view.state('form'));
    });

  fabricTable.addRowClick(() => () => {});

  if (m.className.indexOf('EditViewContainer<Edit Data Center (Pod)>') < 0) {
    fabricTable.AddDialogMeta(metaFabricAdd);
    fabricTable.OnAdd((data) => {
      m.view.addFormValue('fabrics', data);
      ds.Push('fabrics', data);
      updateAvailFabrics(m.view.state('form'));
    });

    fabricTable.OnDelete((data) => {
      const fabricMatch = matches({ name: data.name });
      m.view.removeFormValue('fabrics', fabricMatch);
      ds.Remove('fabrics', fabricMatch);
      updateAvailFabrics(m.view.state('form'));
      updateNetworks();
    });
  }

  fabricTable.EditDialogMeta(metaFabricAdd);
  fabricTable.OnEdit((nextData, oldData) => {
    const fabricMatch = matches({ name: oldData.name });
    m.view.updateFormValue('fabrics', fabricMatch, nextData, true);
    ds.Update('fabrics', fabricMatch, nextData, true);
    updateAvailFabrics(m.view.state('form'));
    updateNetworks();
  });

  fabricTable
    .addField('name', 'Name')
    .MaxWidth(mb.SMALL)
    .CellXform((rowData) => rowData.name);

  fabricTable
    .addField('description', 'Description')
    .CellXform((rowData) => rowData.description);

  fabricTable.addField('type', 'Type').CellXform((rowData) => {
    const newVal = find(dsNetTypes.data, { id: rowData.type });
    return newVal ? newVal.name : rowData.type;
  });

  fabricTable
    .addField('mgr_address', 'Manager address')
    .CellXform((rowData) => rowData.mgr_address);

  // Host provisioning
  m.addField('hostProvisioning', 'Host provisioning on')
    .Input()
    .Required()
    .Visible(() => availFabrics.length > 0)
    .MaxWidth(mb.SMALL)
    .DropDown()
    .DataXform(ds, (json) =>
      !isNil(json) && !isNil(json.fabrics)
        ? json.fabrics.map((t) => ({ id: t.name, name: t.name }))
        : [],
    )
    .OnInit(() => {
      setCurProvisionType(m.view.formDefaultTo('hostProvisioning'));
      setHostProvision(
        m.view.state('form'),
        m.view.formDefaultTo('hostProvisioning'),
      );
    })
    .OnChange((val) => {
      setCurProvisionType(val);
      setHostProvision(m.view.state('form'), val);
    });

  m.addField('privateNetworkFabric', 'Private networks on')
    .Input()
    .Required()
    .Visible(() => availFabrics.length > 0)
    .MaxWidth(mb.SMALL)
    .DropDown()
    .DataXform(ds, (json) =>
      !isNil(json) && !isNil(json.fabrics)
        ? json.fabrics.map((t) => ({ id: t.name, name: t.name }))
        : [],
    )
    .OnInit(() => {
      setCurFabricForPrivateNet(m.view.formDefaultTo('privateNetworkFabric'));
      setPrivateNetworkFabric(
        m.view.state('form'),
        m.view.formDefaultTo('privateNetworkFabric'),
      );
    })
    .OnChange((val) => {
      setCurFabricForPrivateNet(val);
      setPrivateNetworkFabric(m.view.state('form'), val);
    });

  // Management section
  m.addSection('Management')
    .MaxWidth(mb.XXLARGE)
    .OverviewText(
      'Configure how management networks are to be set up for the Pod.',
    );

  // Controller Type
  m.addField('controller_type', 'Controller type')
    .Input(DEFAULT_CONTROLLER_TYPE)
    .Required()
    .MaxWidth(mb.MED)
    .DropDown()
    .DataXform(m.newStaticDataSource(CONTROLLER_TYPE_ENUMS), (json) =>
      json.map((opt) => ({ id: opt.id, name: opt.name })),
    )
    .ReadOnly();

  // Data Center (Pod) Controllers - v2

  // Get list of unassigned controllers or controllers already assigned
  // to this pod.
  const dsControllers = m
    .newDataSource(c.URL_RACKCONTROLLERS)
    .Filter((v) => !v.pod_id || v.pod_id === id);

  const controllersField = m
    .addField('controllers', 'Controllers')
    .Input()
    .MaxWidth(mb.SMALL)
    .MultiSelect()
    .Visible(
      () =>
        m.view.formDefaultTo('controller_type') === CONTROLLER_TYPE_POD_CTLR,
    )
    .DataXform(dsControllers, (json) =>
      json.map((t) => ({ id: t.id, name: t.name })),
    );

  if (m.readOnly) {
    controllersField.ShowLink();
  }

  // BMC Access
  m.addField('bmc.routing', 'BMC routing')
    .Input(DEFAULT_BMC_ROUTING)
    .Required()
    .MaxWidth(mb.SMALL)
    .DropDown()
    .DataXform(bmcRouting, (json) =>
      json.map((opt) => ({ id: opt.id, name: opt.name })),
    );

  m.addField('bmc.ip_pool_id', 'BMC IP pool')
    .Input()
    .RequiredSometimes(
      () =>
        m.view.formDefaultTo('bmc.routing', DEFAULT_BMC_ROUTING) ===
        BMC_SUBNET_ROUTABLE,
    )
    .Visible(
      () =>
        m.view.formDefaultTo('bmc.routing', DEFAULT_BMC_ROUTING) ===
        BMC_SUBNET_ROUTABLE,
    )
    .MaxWidth(mb.SMALL)
    .DropDown()
    .DataXform(dsAllIpPools, (json) => {
      const availableIpPools = json
        .filter((ipPool) => !ipPool.ip_pool_factory_id)
        .map((p) => ({ id: p.id, name: p.name }));

      return [
        ...availableIpPools,
        { id: 'new_ip_pool', name: 'Add new IP pool...' },
      ];
    });

  m.addField('bmc.new_ip_pool', 'New IP pool')
    .Input()
    .Custom(FormIpPool, {})
    .Visible(() => m.view.formDefaultTo('bmc.ip_pool_id') === 'new_ip_pool');

  // BMC & Provisioning network config.  Applicable only if controller
  // type is Data-Center-Wide Controller.
  m.addField('controller_config.bmc_vid', 'BMC network VID')
    .Input('')
    .Visible(
      () =>
        m.view.formDefaultTo('controller_type') === CONTROLLER_TYPE_POD_CTLR,
    )
    .MaxWidth(mb.SMALL)
    .RegEx(REGEX.VLAN, REGEX.VLAN_MSG);

  m.addField('controller_config.bmc_vni', 'BMC network VNI')
    .Input('')
    .Visible(
      () =>
        m.view.formDefaultTo('controller_type') === CONTROLLER_TYPE_POD_CTLR &&
        get(curProvisionType, 'vni_pool_required', false),
    )
    .MaxWidth(mb.SMALL)
    .RegEx(REGEX.VNI, REGEX.VNI_MSG);

  m.addField('controller_config.provision_vid', 'Provisioning network VID')
    .Input('')
    .MaxWidth(mb.SMALL)
    .Visible(
      () =>
        m.view.formDefaultTo('controller_type') === CONTROLLER_TYPE_POD_CTLR,
    )
    .RequiredSometimes(
      () =>
        m.view.formDefaultTo('controller_type') === CONTROLLER_TYPE_POD_CTLR,
    )
    .MaxWidth(mb.SMALL)
    .RegEx(REGEX.VLAN, REGEX.VLAN_MSG);

  m.addField('controller_config.provision_vni', 'Provisioning network VNI')
    .Input('')
    .Visible(
      () =>
        m.view.formDefaultTo('controller_type') === CONTROLLER_TYPE_POD_CTLR &&
        get(curProvisionType, 'vni_pool_required', false),
    )
    .RequiredSometimes(
      () =>
        m.view.formDefaultTo('controller_type') === CONTROLLER_TYPE_POD_CTLR &&
        get(curProvisionType, 'vni_pool_required', false),
    )
    .MaxWidth(mb.SMALL)
    .RegEx(REGEX.VNI, REGEX.VNI_MSG);

  m.addField(
    'pod_features.disable_service_net_protection',
    'Disable Service Network Protection',
  )
    .Input()
    .CheckBox(false)
    .MaxWidth(mb.SMALL);

  m.addField('pod_features.unmanaged_switch', 'Unmanaged switch mode')
    .Input()
    .Custom(UnmanagedSwitch, {})
    .MaxWidth(mb.SMALL);

  // Rack Templates section
  m.addSection('Rack templates')
    .MaxWidth(mb.XXLARGE)
    .OverviewText(
      'Rack Templates that will be recommended when adding racks to the pod.',
    );

  m.addField('rack_templates', 'Rack templates')
    .Input()
    .Required()
    .MaxWidth(mb.SMALL)
    .MultiSelect()
    .DataXform(dsRackTemplate, (json) =>
      json.map((t) => ({ id: t.id, name: t.name })),
    );

  // DNS
  m.addSection('DNS update configurations')
    .MaxWidth(mb.XXLARGE)
    .OverviewText(
      `If associated with a host network, a DNS update configuration
      is used to update the DNS server records with the host name.`,
    );

  const dnsConfigTable = m
    .addInputTable('dns_update_configs', 'DNS update configuration')
    .DataXform(ds, ({ dns_update_configs: configs }) => configs);

  dnsConfigTable.addRowClick(() => () => {});

  dnsConfigTable.AddDialogMeta(metaDnsConfigAdd);
  dnsConfigTable.OnAdd((data) => {
    ds.Push('dns_update_configs', data);
    m.view.addFormValue('dns_update_configs', data);
  });

  dnsConfigTable.OnDelete((data) => {
    ds.Remove('dns_update_configs', data);
    m.view.removeFormValue('dns_update_configs', data);
  });

  dnsConfigTable.EditDialogMeta(metaDnsConfigAdd);
  dnsConfigTable.OnEdit((nextData, oldData) => {
    ds.Remove('dns_update_configs', oldData);
    m.view.removeFormValue('dns_update_configs', oldData);
    ds.Push('dns_update_configs', nextData);
    m.view.addFormValue('dns_update_configs', nextData);
  });

  dnsConfigTable.addField('ip', 'DNS server IP').CellXform(({ ip }) => ip);

  dnsConfigTable
    .addField('domain', 'DNS domain name')
    .ReadOnly()
    .CellXform(({ domain }) => domain);

  const dnsStyleMap = {
    pdns: 'PDNS',
    rfc2136: 'RFC2136',
  };

  dnsConfigTable
    .addField('dns_update_style', 'Update method')
    .CellXform(({ dns_update_style: style }) => dnsStyleMap[style]);

  dnsConfigTable
    .addField('port', 'DNS server port')
    .CellXform(({ port }) => port);

  // Networks section
  m.addSection('Networks').OverviewText(
    `Networks that may be used by racks within the pod to provide
      connections to servers.`,
  );

  m.addField('_temp.netValidator', '')
    .Input()
    .Hidden()
    .CustomValidator(() => {
      if (availNetworks.size === 0) {
        return new ValidationResult(false, 'Networks are required.');
      }
      return new ValidationResult(true, '');
    });

  const dsProjects = m.getDataSource(DS_PROJECTS);
  const netTable = m
    .addInputTable('networks', 'Network')
    .DataXform(ds, (json) => {
      if (!isNil(json.networks)) {
        const filteredNetwork = json.networks.filter((network) =>
          availFabrics.includes(network.fabric),
        );
        return filteredNetwork || [];
      }
      return [];
    })
    .OnInit(() => {
      updateAvailNetworks(m.view.state('form'));
    });

  netTable.addRowClick(() => () => {});

  netTable.AddDialogMeta(metaNetworkAdd);
  netTable.OnAdd((data) => {
    if (serviceNetworks.includes(data.type)) {
      // biome-ignore lint/complexity/noForEach: <explanation>
      ['client', 'provider'].forEach((key) => {
        const { multi, payload, ...form } = data;

        const serviceNetwork = {
          ...form,
          name: multi[key].name,
          subtype: `service-${key}`,
        };

        const permittedProjects = multi[key].projects;

        if (permittedProjects) {
          serviceNetwork.permitted_projects = permittedProjects;
        }

        ds.Push('networks', serviceNetwork);
        m.view.addFormValue('networks', serviceNetwork);
      });
    } else {
      ds.Push('networks', data);
      m.view.addFormValue('networks', data);
    }

    updateAvailNetworks(m.view.state('form'));
  });

  netTable.OnDelete((data) => {
    const peerId = dsNetworks
      .Data()
      .find((network) => network.id === data.id)?.peer_id;

    // remove network
    const netMatch = (network) =>
      network.name === data.name || network.id === peerId;
    ds.Remove('networks', netMatch);
    m.view.removeFormValue('networks', netMatch);
    updateAvailNetworks(m.view.state('form'));
  });

  netTable.EditDialogMeta(metaNetworkAdd); // use same for now
  netTable.OnEdit((nextData, oldData) => {
    debug.debug('onEdit(networks)', oldData, nextData);

    const peerId = dsNetworks
      .Data()
      .find((network) => network.id === oldData.id)?.peer_id;

    if (
      serviceNetworks.includes(oldData.type) &&
      !serviceNetworks.includes(nextData.type)
    ) {
      // remove the two service - current and peer - networks
      const netMatch = (network) =>
        network.name === oldData.name || network.id === peerId;
      ds.Remove('networks', netMatch);
      m.view.removeFormValue('networks', netMatch);

      // add new network
      ds.Push('networks', nextData);
      m.view.addFormValue('networks', nextData);
    } else if (
      !serviceNetworks.includes(oldData.type) &&
      serviceNetworks.includes(nextData.type)
    ) {
      // remove the current network
      const netMatch = (network) => network.name === oldData.name;
      ds.Remove('networks', netMatch);
      m.view.removeFormValue('networks', netMatch);

      // add new provider and client networks
      // biome-ignore lint/complexity/noForEach: <explanation>
      ['client', 'provider'].forEach((key) => {
        const { id: idToBeRemoved, ...form } = nextData;
        const serviceNetwork = {
          ...form,
          name: `${form.name}-${key}`,
          subtype: `service-${key}`,
        };
        ds.Push('networks', serviceNetwork);
        m.view.addFormValue('networks', serviceNetwork);
      });
    } else {
      const netMatch = (network) => network.name === oldData.name;
      ds.Update('networks', netMatch, nextData, true);
      m.view.updateFormValue('networks', netMatch, nextData, true);

      if (peerId) {
        const peerMatch = ({ id: netId }) => netId === peerId;
        const peerData = {
          vni: nextData.vni,
          vlan_id: nextData.vlan_id,
          ip_pool_id: nextData.ip_pool_id,
        };

        ds.Update('networks', peerMatch, peerData);
        m.view.updateFormValue('networks', peerMatch, peerData);
      }
    }

    updateAvailNetworks(m.view.state('form'));
  });

  netTable
    .addField('name', 'Name')
    .MaxWidth(mb.SMALL)
    .CellXform((rowData) => rowData.name);

  netTable
    .addField('description', 'Description')
    .CellXform((rowData) => rowData.description);

  netTable.addField('fabric', 'Fabric').CellXform((rowData) => rowData.fabric);

  netTable.addField('type', 'Type').CellXform((rowData) => {
    const newVal = find(dsNetTypes.data, { id: rowData.type });
    return newVal ? newVal.name : rowData.type;
  });

  netTable
    .addField('host_use', 'Host network use policy')
    .ReadOnly()
    .CellXform((rowData) => rowData.host_use);

  netTable
    .addField('purpose', 'Purpose')
    .CellXform((rowData) => rowData.purpose)
    .ReadOnly();

  netTable
    .addField('vlan_id', 'VLAN ID')
    .CellXform((rowData) => rowData.vlan_id)
    .ReadOnly()
    .Visible(
      () =>
        props.mode === 'view' ||
        get(curFabricForPrivateNet, 'vid_pool_required', false) ||
        m.view.formDefaultTo('pod_type', DEFAULT_PODTYPE) === POD_TYPE_AFC,
      // fabrics type should be an array and see for pod_type_afc
    );

  netTable
    .addField('vni', 'VNI')
    .CellXform((rowData) => rowData.vni)
    .ReadOnly()
    .Visible(
      () =>
        props.mode === 'view' ||
        get(curFabricForPrivateNet, 'vni_pool_required', false),
    );

  netTable
    .addField('vrf_name', 'VRF Name')
    .MaxWidth(mb.SMALL)
    .CellXform((rowData) => rowData.vrf_name)
    .ReadOnly()
    .Visible(
      () =>
        props.mode === 'view' ||
        (get(curFabricForPrivateNet, 'vni_pool_required', false) &&
          !get(curFabricForPrivateNet, 'centralized_fabric_mgr', false)),
    );

  netTable
    .addField('ip_pool_id', 'IP pool')
    .DropDown()
    .ReadOnly()
    .DataXform(dsAllIpPools, (json) => json);

  netTable
    .addField('dns_update_config_ip', 'DNS update config IP')
    .CellXform((rowData) => rowData.dns_update_config_ip)
    .ReadOnly();

  netTable
    .addField('permitted_projects', 'Permitted projects')
    .CellXform((rowData) =>
      rowData.permitted_projects?.map(
        (projectId) =>
          dsProjects.Data().find(({ id }) => id === projectId)?.name,
      ),
    )
    .ReadOnly()
    .MaxWidth(mb.SMALL)
    .ViewModalItemLink();

  // Custom Network Connections
  m.addSection('Custom network connections')
    .MaxWidth(mb.XXLARGE)
    .OverviewText(
      `Add custom network connections for connecting any network to devices
      network appliances, etc.`,
    );

  const networks = m.newDataSource(c.URL_NETWORKS, DS_NETWORKS);
  const racks = m.newDataSource(c.URL_RACKS, DS_RACKS);
  const switches = m.newDataSource(c.URL_SWITCHES, DS_SWITCHES);
  const switchTypes = m.newDataSource(c.URL_SWITCHTYPES, DS_SWITCHTYPES);

  m.addField('custom_connections', 'Custom connections')
    .Input()
    .Custom(FormCustomConnections, {
      networks,
      racks,
      switches,
      switchTypes,
    });

  return m;
}
