// (C) Copyright 2016-2022, 2024 Hewlett Packard Enterprise Development LP

import defaultTo from 'lodash/defaultTo';
import differenceWith from 'lodash/differenceWith';
import find from 'lodash/find';
import set from 'lodash/set';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import matches from 'lodash/matches';
import * as c from '../../routes/consts.js';
import MetaBuilder, * as mb from '../../containers/generic/MetaBuilder';
import ColumnLink from '../../components/Griddle/ColumnLink';
import ItemViewContainer from '../../containers/generic/ItemViewContainer';
import CreateViewContainer from '../../containers/generic/CreateViewContainer';
import EditViewContainer from '../../containers/generic/EditViewContainer';
import { ValidationResult } from '../../containers/generic/MetaBuilder';
import * as REGEX from '../../data/regex.js';
import { netmaskIPv4 } from '../../data/network.js';
import { HosterMemberListView } from './members.js';
import debugLogger from '../../lib/debug';
import * as log from '../../lib/debug';
import { toDateTime } from '../../lib/formatters';
import FormAutoConfig from '../../containers/generic/MetaForm/FormAutoConfig';
import { LABEL_HOSTERS } from '../../components/HybridNav/consts.js';

const debug = debugLogger('Hoster', log.LOG_LEVEL_WARN);

const BASE_URL = c.URL_HOSTERS; // e.g. hosters or hosters/:pid/members for child tables

/*
//customize these for list view that are children of other views (e.g. hoster/:pid/members)
const AUTH_URL = BASE_URL // hosters, customize for child tables, used to return after create item
const HOME_URL = c.makeSecUrl(BASE_URL) // /s/hosters, customize for child tables, used to return after create item

//these don't need to be modified per view
const SEC_PAGE_ITEM_URL = c.makeSecItemUrl(BASE_URL) // /s/hosters/:id
const SEC_PAGE_CREATE_URL = c.makeSecCreateUrl(BASE_URL) // /s/hosters/create
const REST_URL = c.makeRestUrl(BASE_URL) // /rest/hosters
const REST_ITEM_URL = c.makeRestItemUrl(BASE_URL)  // /rest/hosters/:id
*/

const settings = {
  authUrl: BASE_URL, // used to filter create/trash icons from the view; using the defined roledef permissions mappings to this auth URL entry
  homeUrl: c.makeSecUrl(BASE_URL), // homepage for this list view (e.g. /s/hosters/:pid/) is where this view is located; for return from create / item views
  homeLabel: LABEL_HOSTERS,
  baseUrl: BASE_URL, // base url to be used for creating all associated URLS for this reasource, e.g. pageItem, pageCreate, restUrl, restItemUrl
};

function metaCreate(props) {
  const m = new MetaBuilder(props);

  m.addSection('Basic info').Expanded().MaxWidth(mb.LARGE);

  m.addRowClick((navigate) => ({ datum: { id } }) => {
    navigate(c.setId(c.makeSecItemUrl(BASE_URL), id));
  });

  // resource base
  m.addColumn('name', 'Name')
    .Custom(ColumnLink, {
      info: (rowData) => {
        const url = c.setId(c.makeSecItemUrl(BASE_URL), rowData.id);
        const { name } = rowData;
        return { url, name };
      },
    })
    .Default()
    .Input()
    .MaxWidth(mb.SMALL)
    .Required()
    .RegEx('^[A-Za-z0-9!"#$%&\'()*+,.\\/:;<=>?@\\[\\] ^_`{|}~-]*$');

  //-----------------------------------------
  m.addSection('Profile').Expanded().MaxWidth(mb.LARGE);

  m.addColumn('profile.team_name', 'Team name')
    .Input()
    .Default()
    .MaxWidth(mb.SMALL);

  m.addColumn('profile.team_desc', 'Description')
    .Input()
    .Default()
    .MaxWidth(mb.SMALL);

  m.addColumn('profile.company', 'Company').Input().MaxWidth(mb.SMALL);

  m.addColumn('profile.address', 'Address').Input().MaxWidth(mb.SMALL);

  m.addColumn('profile.email', 'Email').Input().Default().MaxWidth(mb.SMALL);

  m.addColumn('profile.phone_number', 'Phone')
    .Input()
    .Default()
    .MaxWidth(mb.SMALL);

  m.addColumn('created', 'Created')
    .FieldXform((created) => (created ? toDateTime(created) : '--'))
    .Default();
  m.addColumn('modified', 'Modified').FieldXform((modified) =>
    modified ? toDateTime(modified) : '--',
  );
  m.addColumn('id', 'ID');

  //-----------------------------------------
  m.addSection().Table(HosterMemberListView).Expanded().MaxWidth(mb.XXLARGE);

  //-----------------------------------------
  m.addSection('Private network IP addressing').Expanded().MaxWidth(mb.LARGE);

  m.addColumn(
    'private_ip_pool_factory.unique_ip_ranges',
    'How private IPs are allocated for projects',
  )
    .Input()
    .Required()
    .RadioGroup()
    .Direction()
    .DataXform(
      m.newStaticDataSource([
        {
          name: 'Unique IP ranges',
          id: true,
          tip: 'A single base IP address pool (CIDR) is subdivided to create unique, non-overlapping sub-network IP address pools for each private network -- regardless of project. This approach will ensure that there are not IP address clashes if private networks are later shared across projects.',
        },
        {
          name: 'Re-use IP ranges in each project',
          id: false,
          tip: 'The same base IP address poll (CIDR) may be used by each project to create sub-network IP address pools for the private networks within that project. This approach allows for many more private networks, but can result in colliding IP addressing if private networks from a project ever shared with another project.',
        },
      ]),
      (json) => json.map(({ id, name, tip }) => ({ id, name, tip })),
    )
    .MaxWidth(mb.SMALL);

  // Base IP Pool
  m.addSection('Base IP pool')
    .Expanded()
    .MaxWidth(mb.MED)
    .FooterNote(() => {
      const { private_ip_pool_factory } = m.view.getForm();
      const subpool = Number(private_ip_pool_factory.ip_pool_cidr.substring(1));
      const basepool = Number(private_ip_pool_factory.base_cidr.substring(1));
      const networks = 2 ** Math.abs(subpool - basepool);
      const devices = 2 ** (32 - subpool) - 3;

      return `Note: Supports up to ${networks} private networks with up to ${devices} devices per network`;
    });

  m.addColumn('private_ip_pool_factory.base', 'Base network address')
    .Input('10.0.0.0')
    .MaxWidth(mb.SMALL)
    .RegEx(REGEX.IPV4_ADDRESS, REGEX.IPV4_ADDRESS_MSG);

  m.addColumn('private_ip_pool_factory.base_cidr', 'Base pool size')
    .Input('/8')
    .DropDown('/8')
    .MaxWidth(mb.SMALL)
    .DataXform(m.newStaticDataSource(netmaskIPv4), (json) =>
      json.map((t) => ({ id: t, name: t })),
    )
    .RequiredSometimes(() => !!m.view.formDefaultTo('base'));

  m.addColumn('private_ip_pool_factory.ip_pool_cidr', 'Sub-Pool size')
    .Input('/16')
    .DropDown('/16')
    .MaxWidth(mb.SMALL)
    .DataXform(m.newStaticDataSource(netmaskIPv4), (json) =>
      json.map((t) => ({ id: t, name: t })),
    )
    .RequiredSometimes(() => !!m.view.formDefaultTo('base'));

  // Hoster Limits
  m.addSection('Hoster limits')
    .Expanded()
    .MaxWidth(mb.SMALL)
    .OverviewText(
      'Limits on what the hoster can create and manage within the portal. Only editable by portal admin. Zero values indicate no limit.',
    );

  m.addColumn('limits.pods', 'Pods').Input('0').Required().RegEx('^[0-9]+$');

  m.addColumn('limits.racks', 'Racks').Input('0').Required().RegEx('^[0-9]+$');

  m.addColumn('limits.switches_per_rack', 'Switches/Rack')
    .Input('0')
    .Required()
    .RegEx('^[0-9]+$');

  m.addColumn('limits.hosts', 'Hosts').Input('10').Required().RegEx('^[0-9]+$');

  m.addColumn('limits.volumes', 'Volumes')
    .Input('10')
    .Required()
    .RegEx('^[0-9]+$');

  m.addColumn('limits.volume_capacity', 'Volume capacity (TiB)')
    .Input('10')
    .Required()
    .RegEx('^[0-9]+$');

  m.addColumn('limits.projects', 'Projects')
    .Input('0')
    .Required()
    .RegEx('^[0-9]+$');

  // Auto Configuration
  m.addSection('Auto configuration')
    .Expanded()
    .MaxWidth(mb.LARGE)
    .OverviewText(
      'When enabled, hoster resources are automatically added, modified, and removed when the source repository is modified. Disabling automatic configuration deletes all hoster resources added from the source repository.',
    );

  m.addColumn('auto_config', 'Auto config')
    .Input()
    .Custom(FormAutoConfig)
    .CustomValidator((form) => {
      let errors = {};
      const { mode, repo, branch, private_key } = form;

      if (mode && (!repo || !branch || !private_key)) {
        if (!repo) {
          errors.repo = 'Source repository is required.';
        }

        if (!branch) {
          errors.branch = 'Branch is required';
        }

        if (!private_key) {
          errors.private_key = 'Deploy Key is required';
        }
      } else {
        errors = {};
      }

      if (repo && !REGEX.GIT_REPO.test(repo)) {
        errors.repo = REGEX.GIT_REPO_MSG;
      }

      if (branch && !REGEX.GIT_BRANCH.test(branch)) {
        errors.branch = REGEX.GIT_BRANCH_MSG;
      }

      if (
        private_key &&
        !private_key.includes('*') &&
        !REGEX.RSA_PRIVATE_KEY.test(private_key)
      ) {
        errors.private_key = REGEX.RSA_PRIVATE_KEY_MSG;
      }

      const hasErrors = !!Object.keys(errors).length;

      if (hasErrors) {
        return new ValidationResult(false, errors);
      }
      return new ValidationResult(true);
    });

  return m;
}

function metaEdit(props) {
  const m = metaCreate(props);

  m.formDefaults((form) => {
    form.bmc_credentials = form.bmc_credentials || [];
    form.switch_credentials = form.switch_credentials || [];
  });

  const ds = m
    .newDataSource(props.itemUrl)
    .Item(props.itemId)
    .OnLoad((json) => {
      m.view.initForm(json.data);
    });

  //-----------------------------------------
  m.addSection('Switch credentials')
    .MaxWidth(mb.LARGE)
    .OverviewText(
      'Credentials that will be used to authenticate with switch or fabric manager during switch discovery',
    );

  const switchTable = m
    .addInputTable('switch_credentials', 'Switch credential')
    .DataXform(ds, (json) => {
      debug.debug('switch creds:DataXform', ds, json);
      return json.switch_credentials || [];
    });

  switchTable.AddDialogMeta(metaSwitchCredAdd);

  switchTable.OnAdd((data) => {
    data.dirty = true;
    ds.Push('switch_credentials', data); // add to dataset used to render the page
    m.view.addFormValue('switch_credentials', data); // add to form to be posted/put
  });

  switchTable.OnDelete((data) => {
    ds.Remove('switch_credentials', data);
    m.view.removeFormValue('switch_credentials', data);
  });

  switchTable.EditDialogMeta(metaSwitchCredAdd); // use same for now

  switchTable.OnEdit((nextData, oldData) => {
    nextData.dirty = true;
    ds.Update('switch_credentials', oldData, nextData, true);
    m.view.updateFormValue('switch_credentials', oldData, nextData, true);
  });

  switchTable
    .addField('user_name', 'User name')
    .CellXform((rowData) => rowData.user_name);

  switchTable
    .addField('password', 'Password')
    .Password()
    .CellXform((rowData) => rowData.password);

  //-----------------------------------------
  m.addSection('BMC credentials')
    .MaxWidth(mb.LARGE)
    .OverviewText(
      "Credentials that will be used to authenticate with server's BMC during machine discovery",
    );

  const bmcTable = m
    .addInputTable('bmc_credentials', 'BMC credential')
    .DataXform(ds, (json) => {
      debug.debug('bmc creds:DataXform', ds, json);
      return json.bmc_credentials || [];
    });

  bmcTable.AddDialogMeta(metaBmcCredAdd);

  bmcTable.OnAdd((data) => {
    data.dirty = true;
    ds.Push('bmc_credentials', data); // add to dataset used to render the page
    m.view.addFormValue('bmc_credentials', data); // add to form to be posted/put
  });

  bmcTable.OnDelete((data) => {
    ds.Remove('bmc_credentials', data);
    m.view.removeFormValue('bmc_credentials', data);
  });

  bmcTable.EditDialogMeta(metaBmcCredAdd); // use same for now

  bmcTable.OnEdit((nextData, oldData) => {
    nextData.dirty = true;
    ds.Update('bmc_credentials', oldData, nextData, true);
    m.view.updateFormValue('bmc_credentials', oldData, nextData, true);
  });

  bmcTable
    .addField('user_name', 'User name')
    .CellXform((rowData) => rowData.user_name);

  bmcTable
    .addField('password', 'Password')
    .Password()
    .CellXform((rowData) => rowData.password);

  return m;
}

function metaBmcCredAdd(props) {
  const m = new MetaBuilder(props);

  m.formDefaults((form) => {
    set(form, 'password', defaultTo(form.password, ''));
    set(form, '_temp.password', defaultTo(form.password, ''));
  });

  // if edit, then remove item being edited from unique set
  let other_creds = m.parentView.stateDefaultTo('form.bmc_credentials', []);

  const origForm = m.view.prop('formData', false);
  if (!isNil(origForm)) {
    other_creds = differenceWith(other_creds, [origForm], isEqual);
  }

  // m.addField('user_name', 'User Name').Input().Required().UniqueIn(other_creds)
  m.addField('user_name', 'User name').Input().Required();
  const pass = m.addField('password', 'Password').Input().Password();
  m.addField('_temp.password', 'Confirm password')
    .Input()
    .Password()
    .MustMatch(pass);

  m.addField('_temp.domain_validator', 'Domain validator')
    .Input()
    .Hidden()
    .CustomValidator(() => {
      const match = matches({
        user_name: m.view.formDefaultTo('user_name'),
        password: m.view.formDefaultTo('password'),
      });
      if (isNil(find(other_creds, match))) {
        return new ValidationResult(true, '');
      }
      return new ValidationResult(
        false,
        'Username and password is already defined, please enter a different combination.',
      );
    });

  return m;
}

function metaSwitchCredAdd(props) {
  const m = new MetaBuilder(props);

  m.formDefaults((form) => {
    set(form, 'password', defaultTo(form.password, ''));
    set(form, '_temp.password', defaultTo(form.password, ''));
  });

  // if edit, then remove item being edited from unique set
  let other_creds = m.parentView.stateDefaultTo('form.switch_credentials', []);
  const origForm = m.view.prop('formData', false);
  if (!isNil(origForm)) {
    other_creds = differenceWith(other_creds, [origForm], isEqual);
  }

  // m.addField('user_name', 'User Name').Input().Required().UniqueIn(other_creds)
  m.addField('user_name', 'User name').Input().Required();
  const pass = m.addField('password', 'Password').Input().Password();
  m.addField('_temp.password', 'Confirm password')
    .Input()
    .Password()
    .MustMatch(pass);

  m.addField('_temp.domain_validator', 'Domain validator')
    .Input()
    .Hidden()
    .CustomValidator(() => {
      const match = matches({
        user_name: m.view.formDefaultTo('user_name'),
        password: m.view.formDefaultTo('password'),
      });
      if (isNil(find(other_creds, match))) {
        return new ValidationResult(true, '');
      }
      return new ValidationResult(
        false,
        'Username and password is already defined, please enter a different combination.',
      );
    });

  return m;
}

export { default as HosterListView } from './HosterListView';

export const HosterItemView = ItemViewContainer({
  ...settings,
  meta: (props) => metaEdit(props),
  title: 'Hoster',
  allowEdit: true,
});

export const HosterCreateView = CreateViewContainer({
  ...settings,
  meta: (props) => metaCreate(props),
  title: 'Create hoster',
});

export const HosterEditView = EditViewContainer({
  ...settings,
  meta: (props) => metaEdit(props),
  title: 'Edit hoster',
});
