// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  CheckBox,
  FormField,
  Select,
  Text,
  TextArea,
  TextInput,
} from 'grommet';
import { CircleInformation, Trash } from 'grommet-icons';

const modeMap = {
  enabled: 'Safe Update',
  'enabled-overwrite': 'Full Replace',
  paused: 'Paused',
};

const modeOptions = [
  {
    label: 'Safe Update',
    value: 'enabled',
  },
  {
    label: 'Full Replace',
    value: 'enabled-overwrite',
  },
  {
    label: 'Paused',
    value: 'paused',
  },
];

const DEFAULT_MODE = 'enabled';
const repoPlaceholder = 'git@github.com:org/repo.git';
const privateKeyPlaceholder =
  '----BEGIN RSA PRIVATE KEY----\n-----\n-----END RSA PRIVATE KEY-----';
const disabledInfo =
  'Auto update is disabled. Any previously imported resources are deleted when not in use.';

const defaultForm = {
  branch: '',
  mode: '',
  private_key: '',
  repo: '',
};

const ReadOnlyField = ({ label, value }) => (
  <Box direction='row' pad='xsmall'>
    <Box width='300px'>
      <Text color='text-strong' weight='bold'>
        {label}
      </Text>
    </Box>
    <Text color='text-strong'>{value}</Text>
  </Box>
);

const Info = ({ children }) => (
  <Box
    align='center'
    direction='row'
    gap='xsmall'
    pad={{ horizontal: 'xsmall' }}
  >
    <CircleInformation size='small' />
    <Text size='small'>{children}</Text>
  </Box>
);

const FormAutoConfig = ({ col, data, validationResult }) => {
  const [enabled, setEnabled] = useState(false);
  const [form, setForm] = useState(defaultForm);
  const [savedForm, setSavedForm] = useState({});
  const [errors, setErrors] = useState({});

  const isView = useMemo(() => col.mb.props.mode === 'view', [col.mb.props]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const hosterData = useMemo(
    () => col.mb.dataSources.find(({ baseUrl }) => baseUrl === 'hosters')?.data,
    [data],
  );

  const modeInfo = useMemo(() => {
    switch (form.mode) {
      case 'enabled':
        return 'Auto update is enabled. Existing resources are left alone.';
      case 'enabled-overwrite':
        return 'Auto update is enabled. Existing resources not in remote repo are deleted.';
      case 'paused':
        return 'Auto update is disabled. Previously imported resources are kept but are still read-only.';
      default:
        return '';
    }
  }, [form.mode]);

  const branchesInfo = useMemo(() => {
    return 'Comma separated list of branches.';
  });

  useEffect(() => {
    const { ok, msg } = validationResult;
    if (!ok && msg) {
      setErrors(msg);
    } else {
      setErrors({});
    }
  }, [validationResult]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    const dataForm = hosterData?.auto_config || defaultForm;

    setEnabled(!!dataForm.mode);
    setSavedForm(dataForm);
    setForm({
      ...dataForm,
      mode: dataForm.mode,
    });
  }, [data]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    col.mb.view.setFormValue('auto_config', form);
  }, [form]);

  const handleToggle = () => {
    setEnabled(!enabled);

    if (enabled) {
      setSavedForm(form);
      setForm({
        ...form,
        ...defaultForm,
      });
    } else {
      setForm({
        ...savedForm,
        mode: savedForm.mode || DEFAULT_MODE,
      });
    }
  };

  const handleInputChange =
    (key) =>
    ({ option, target }) => {
      const { value } = option || target;

      if ((/\s/.test(value) && key !== 'private_key') || /\*/.test(value)) {
        return;
      }

      setForm({
        ...form,
        [key]: value,
      });
    };

  const handleDelete = (e) => {
    setForm({
      ...form,
      private_key: '',
    });
  };

  return (
    <Box>
      <Box
        border={{
          color: '#bbbbbb',
          side: 'all',
        }}
        style={{
          borderRadius: 4,
        }}
      >
        <Box pad='xsmall'>
          <CheckBox
            checked={enabled}
            disabled={isView}
            label={enabled ? 'Enabled' : 'Disabled'}
            onChange={handleToggle}
            toggle
          />
        </Box>
        {enabled && (
          <Box
            border={{
              color: '#bbbbbb',
              side: 'top',
              style: 'solid',
            }}
          >
            {isView ? (
              <Box gap='small' pad='small'>
                <ReadOnlyField label='Mode' value={modeMap[form.mode]} />
                <ReadOnlyField label='Source Repository' value={form.repo} />
                <ReadOnlyField
                  label='Branches on Source Repository'
                  value={form.branch}
                />
              </Box>
            ) : (
              <Box gap='medium' pad='small'>
                <Box>
                  <FormField label='Mode' required>
                    <Select
                      onChange={handleInputChange('mode')}
                      options={modeOptions}
                      value={form.mode}
                      valueKey={{
                        key: 'value',
                        reduce: true,
                      }}
                      labelKey='label'
                    />
                  </FormField>
                  <Info>{modeInfo}</Info>
                </Box>
                <FormField
                  error={errors.repo}
                  label='Source Repository (SSH)'
                  required
                >
                  <TextInput
                    onChange={handleInputChange('repo')}
                    placeholder={repoPlaceholder}
                    value={form.repo}
                  />
                </FormField>
                <Box>
                  <FormField
                    error={errors.branch}
                    label='Branches on Source Repository'
                    required
                  >
                    <TextInput
                      onChange={handleInputChange('branch')}
                      placeholder='master'
                      value={form.branch}
                    />
                  </FormField>
                  <Info>{branchesInfo}</Info>
                </Box>
                <Box direction='row' gap='small'>
                  <Button
                    disabled={!form.private_key}
                    icon={<Trash />}
                    label='Delete Deploy Key'
                    onClick={handleDelete}
                    reverse
                    style={{
                      border: !form.private_key
                        ? '2px solid #999999'
                        : '2px solid #444444',
                    }}
                  />
                </Box>
                {!form.private_key.includes('*') && (
                  <FormField
                    error={errors.private_key || ''}
                    label='Deploy Key for Source Repository'
                    required
                  >
                    <TextArea
                      onChange={handleInputChange('private_key')}
                      placeholder={privateKeyPlaceholder}
                      rows={8}
                      value={form.private_key}
                    />
                  </FormField>
                )}
              </Box>
            )}
          </Box>
        )}
      </Box>
      {!enabled && !isView && <Info>{disabledInfo}</Info>}
    </Box>
  );
};

export default FormAutoConfig;
