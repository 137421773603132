// (C) Copyright 2016-2022, 2024 Hewlett Packard Enterprise Development LP

import * as c from '../../routes/consts';
import MetaBuilder, { SMALL } from '../../containers/generic/MetaBuilder';
import ColumnLink from '../../components/Griddle/ColumnLink';
import ItemViewContainer from '../../containers/generic/ItemViewContainer';
import CreateViewContainer from '../../containers/generic/CreateViewContainer';
import { LABEL_SSH_KEYS } from '../../components/HybridNav/consts.js';
import { toDateTime } from '../../lib/formatters';
import auth from '../../lib/auth';
import debugLogger from '../../lib/debug';
import * as log from '../../lib/debug';

const BASE_URL = c.URL_SSHKEYS;
const debug = debugLogger('VolumeView::index::', log.LOG_LEVEL_DEBUG);

function metabuilder(props) {
  const mb = new MetaBuilder(props);

  mb.addRowClick((navigate) => ({ datum: { id } }) => {
    navigate(c.setId(c.makeSecItemUrl(BASE_URL), id));
  });

  mb.addColumn('name', 'Name')
    .Custom(ColumnLink, {
      info: (rowData) => {
        const url = c.setId(c.makeSecItemUrl(BASE_URL), rowData.id);
        const { name } = rowData;
        return { url, name };
      },
    })
    .Default()
    .Input()
    .Required()
    .MaxWidth(SMALL);

  mb.addColumn('id', 'ID');

  mb.addColumn('modified', 'Modified')
    .FieldXform((modified) => (modified ? toDateTime(modified) : '--'))
    .Default();
  mb.addColumn('created', 'Created')
    .FieldXform((created) => (created ? toDateTime(created) : '--'))
    .Default();

  mb.addColumn('key', 'SSH key').Input().TextArea().Required();

  return mb;
}

function metaCreate(props) {
  const mb = new MetaBuilder(props);

  const dsVolumeFlavors = mb.newStaticDataSource([]);
  const dsStoragePools = mb.newStaticDataSource([]);
  const dsVolumeCollections = mb.newStaticDataSource([]);

  const dsAvail = mb
    .newDataSource(c.URL_AVAIL_STORAGE_RESOURCES)
    .OnLoad(({ data }) => {
      const uniqueFlavors = getUniqueFlavors(
        data?.inventory,
        data?.locations?.[0].id,
      );

      dsVolumeFlavors.SetData([
        { id: 'select_volume_flavor', name: 'Select volume flavor' },
        ...uniqueFlavors,
      ]);
      dsStoragePools.SetData([{ id: 'auto', name: 'Auto' }]);
      dsVolumeCollections.SetData([{ id: 'none', name: 'None' }]);
    });

  const inHosterScope = auth.inScope(c.SCOPE_HOSTER);
  const dsProjects = inHosterScope ? mb.newDataSource(c.URL_PROJECTS) : [];

  debug.debug('metaCreate: inHosterScope:', inHosterScope);

  // If hoster, select project that will own the host (for on-behalf-of)
  if (inHosterScope) {
    mb.addField('_temp.project', 'On behalf of project')
      .Input()
      .DropDown()
      .DataXform(dsProjects, (json) => {
        // using id of 'self' because a value of '' causes loop
        const tms = [{ id: 'self', name: 'Self' }];
        return tms.concat(json.map((t) => ({ id: t.id, name: t.name })));
      })
      .MaxWidth(400)
      .OnChange((val) => {
        let value = val;

        if (value !== undefined) {
          if (value === 'self') {
            dsAvail.DeleteQuery('project');
            value = '';
          } else {
            dsAvail.AddQuery('project', value);
          }

          dsAvail.Fetch();
          mb.view.setFormValue('project_id', value);
        }
      });
  }

  mb.addRowClick((navigate) => ({ datum: { id } }) => {
    navigate(c.setId(c.makeSecItemUrl(BASE_URL), id));
  });

  mb.addColumn('name', 'Name')
    .Custom(ColumnLink, {
      info: (rowData) => {
        const url = c.setId(c.makeSecItemUrl(BASE_URL), rowData.id);
        const { name } = rowData;
        return { url, name };
      },
    })
    .Default()
    .Input()
    .Required()
    .MaxWidth(SMALL);

  mb.addColumn('id', 'ID');

  mb.addColumn('modified', 'Modified')
    .FieldXform((modified) => (modified ? toDateTime(modified) : '--'))
    .Default();
  mb.addColumn('created', 'Created')
    .FieldXform((created) => (created ? toDateTime(created) : '--'))
    .Default();

  mb.addColumn('key', 'SSH key').Input().TextArea().Required();

  return mb;
}

const settings = {
  // used to filter create/trash icons from the view; using the
  // defined roledef permissions mappings to this auth URL entry
  authUrl: BASE_URL,
  // homepage for this list view (e.g. /s/hosters/:pid/) is where
  // this view is located; for return from create / item views
  homeUrl: c.makeSecUrl(BASE_URL),
  homeLabel: LABEL_SSH_KEYS,
  // base url to be used for creating all associated URLS for this reasource,
  // e.g. pageItem, pageCreate, restUrl, restItemUrl
  baseUrl: BASE_URL,
  meta: (props) => metabuilder(props),
};

export { default as SSHKeyListView } from './SSHKeyListView';

export const SSHKeyItemView = ItemViewContainer({
  ...settings,
  title: 'SSH keys',
});

export const SSHKeyCreateView = CreateViewContainer({
  ...settings,
  meta: (props) => metaCreate(props),
  title: 'Create SSH key',
});
