// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import React from 'react';
import { Box } from 'grommet';

function ViewArea({ children, ...rest }) {
  return (
    <Box flex {...rest} margin={{ bottom: 'small', top: 'medium' }}>
      {children}
    </Box>
  );
}

export default ViewArea;
