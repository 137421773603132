// (C) Copyright 2020-2024 Hewlett Packard Enterprise Development LP

import React from 'react';
import PropTypes from 'prop-types';
import { Box, Tip } from 'grommet';
import {
  StatusGoodSmall,
  StatusCriticalSmall,
  StatusWarningSmall,
  StatusUnknownSmall,
  StatusDisabledSmall,
} from 'grommet-icons';

export const StatusIcon = ({ status }) => {
  switch (status) {
    case 'status-ok':
      return <StatusGoodSmall color='status-ok' />;
    case 'status-critical':
      return <StatusCriticalSmall color='status-critical' />;
    case 'status-warning':
      return <StatusWarningSmall color='status-warning' />;
    case 'status-unknown':
      return <StatusUnknownSmall color='status-unknown' />;
    case 'status-disabled':
      return <StatusDisabledSmall color='status-unknown' />;
    default:
      return undefined;
  }
};

StatusIcon.propTypes = {
  status: PropTypes.string,
};

const ColumnStatus = ({ metadata, rowData }) => {
  if (!(rowData && metadata)) {
    return null;
  }

  const { status, content, tooltip, icon } =
    metadata.customComponentMetadata.info(rowData);

  const Status = () => (
    <Box
      direction='row'
      round='xsmall'
      pad={{ horizontal: 'small' }}
      background={{ color: icon ? undefined : status, size: '2px' }}
    >
      {icon && <StatusIcon status={status} />}
      <span>{content}</span>
    </Box>
  );

  return (
    <Box align='start'>
      {tooltip ? (
        <Tip content={tooltip}>
          {/*
            NOTE: At the moment, Tip will throw an error if you use
            grommet-icon as direct child, so wrapping in a span is the
            workaround recommended by the grommet team.
            See https://github.com/grommet/grommet/issues/5256
          */}
          <span>
            <Status />
          </span>
        </Tip>
      ) : (
        <Status />
      )}
    </Box>
  );
};

ColumnStatus.propTypes = {
  rowData: PropTypes.object.isRequired,
  metadata: PropTypes.object.isRequired,
};

export default ColumnStatus;
