// (C) Copyright 2022-2024 Hewlett Packard Enterprise Development LP

import React, { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { getRole } from '../../services/membership';
import auth from '../../lib/auth';

export const MembershipContext = createContext();

export const MembershipProvider = ({
  activeMembership = {},
  children,
  memberships = [],
  role = {},
}) => {
  const [context, setContext] = useState({});

  useEffect(() => {
    setContext((prevContext) => ({
      ...prevContext,
      activeMembership,
    }));
  }, [activeMembership]);

  useEffect(() => {
    setContext((prevContext) => ({
      ...prevContext,
      memberships,
    }));
  }, [memberships]);

  useEffect(() => {
    setContext((prevContext) => ({
      ...prevContext,
      role,
    }));
  }, [role]);

  useEffect(() => {
    if (context.activeMembership?.id) {
      getRole().then((roleDef) => {
        setContext((prevContext) => ({
          ...prevContext,
          role: roleDef,
        }));
      });
    }
  }, [context.activeMembership]);

  useEffect(() => {
    auth.setActiveRole(context.role);
    auth.setActiveScope(context.role?.scope);
  }, [context.role]);

  return (
    <MembershipContext.Provider
      value={{
        ...context,
        setContext,
      }}
    >
      {children}
    </MembershipContext.Provider>
  );
};

MembershipProvider.propTypes = {
  activeMembership: PropTypes.object,
  children: PropTypes.node.isRequired,
  memberships: PropTypes.array,
  role: PropTypes.object,
};

export const useMembershipContext = () => {
  const context = useContext(MembershipContext);

  if (!context) {
    throw new Error('MembershipContext must be used within a AppProvider');
  }

  return context;
};
