// (C) Copyright 2023-2024 Hewlett Packard Enterprise Development LP

import * as c from '../../routes/consts.js';
import * as mb from '../../containers/generic/MetaBuilder';
import MetaBuilder from '../../containers/generic/MetaBuilder';
import ItemViewContainer from '../../containers/generic/ItemViewContainer';
import { AllocatedCapacity } from '../StoragePoolsView/AllocatedCapacity.jsx';
import HealthSummary from '../../components/HealthSummary';
import { LABEL_STORAGE_SYSTEMS } from '../../components/HybridNav/consts.js';

const BASE_URL = c.URL_STORAGE_SYSTEMS;

const settings = {
  authUrl: BASE_URL, // used to filter create/trash icons from the view; using the defined roledef permissions mappings to this auth URL entry
  homeUrl: c.makeSecUrl(BASE_URL), // homepage for this list view (e.g. /s/hosters/:pid/) is where this view is located; for return from create / item views
  homeLabel: LABEL_STORAGE_SYSTEMS,
  baseUrl: BASE_URL, // base url to be used for creating all associated URLS for this reasource, e.g. pageItem, pageCreate, restUrl, restItemUrl
  meta: (props) => metabuilder(props),
};

const DS_STORAGE_GROUPS = 'storagegroups';
const DS_STORAGE_POOLS = 'capacitypools';
const DS_STORAGE_INTERFACES = 'storageinterfaces';

function metabuilder(props) {
  const m = new MetaBuilder(props);
  const id = m.view.prop('itemId', false);

  const dsStoragePools = m
    .newDataSource(c.URL_STORAGE_POOLS, DS_STORAGE_POOLS)
    .Filter(({ storage_system_id_list }) =>
      storage_system_id_list.includes(id),
    );

  m.newDataSource(BASE_URL)
    .Item(id)
    .OnLoad((json) => {
      m.view.initForm(json.data);
    });

  m.addColumn('name', 'Name');

  m.addColumn('volume_family', 'Volume family');

  m.addColumn('storage_type', 'Storage type').FieldXform(() => {
    const [storagePool] = dsStoragePools.Data() || [];

    return storagePool?.storage_type;
  });

  m.addColumn('id', 'ID');

  m.addSection('Health summary', 'health_status').MaxWidth(mb.LARGE).Expanded();

  m.addColumn('health_status', 'Health').Custom(HealthSummary);

  m.addSection('Storage pools').MaxWidth(mb.LARGE);

  const capTable = m
    .addInputTable('storagepools', 'Storage pools')
    .DataXform(dsStoragePools, (json) => json);

  capTable
    .addField('capacityPoolName', 'Name')
    .ReadOnly()
    .CellXform((rowData) => rowData.capacityPoolName);

  capTable
    .addField('capacity', 'Space utilization')
    .ReadOnly()
    .CellXform((rowData) => <AllocatedCapacity data={{ id: rowData?.id }} />);

  capTable
    .addField('totalVolumes', 'Volume count')
    .ReadOnly()
    .CellXform((rowData) => rowData.totalVolumes);

  m.addSection('Storage group').MaxWidth(mb.LARGE);

  const dsStorageGroups = m
    .newDataSource(c.URL_STORAGE_GROUPS, DS_STORAGE_GROUPS)
    .Filter(
      ({ id: storageGroupId }) =>
        m.view.formDefaultTo('storage_group_id') === storageGroupId,
    );

  m.addColumn('storage_group_id', 'Name').FieldXform((storageGroupId) => {
    const storageGroup = dsStorageGroups
      .Data()
      ?.find(({ id }) => id === storageGroupId);

    return storageGroup?.name || '';
  });

  m.addColumn('storage_group_id', 'Management IP address').FieldXform(
    (storageGroupId) => {
      const storageGroup = dsStorageGroups
        .Data()
        ?.find(({ id }) => id === storageGroupId);

      return storageGroup?.management_ip || '';
    },
  );

  const ns = m
    .newDataSource(c.URL_STORAGE_INTERFACES, DS_STORAGE_INTERFACES)
    .Filter((t) => t.storage_system_id === id);

  m.addSection('Network interfaces').MaxWidth(mb.LARGE);

  const netTable = m
    .addInputTable('storageinterfaces', 'Network interfaces')
    .DataXform(ns, (json) => json);

  netTable
    .addField('arrayNetworkInterfaceID', 'Array NIC ID')
    .ReadOnly()
    .CellXform((rowData) => rowData.arrayNetworkInterfaceID);

  netTable
    .addField('ipAddress', 'IP Address')
    .ReadOnly()
    .CellXform((rowData) => rowData.ipAddress);

  netTable
    .addField('netmask', 'Netmask')
    .ReadOnly()
    .CellXform((rowData) => rowData.netmask);

  netTable
    .addField('gateway', 'Gateway')
    .ReadOnly()
    .CellXform((rowData) => rowData.gateway);

  return m;
}

export { StorageSystemsListView } from './StorageSystemsListView';

export const StorageSystemItemView = ItemViewContainer({
  ...settings,
  meta: (props) => metabuilder(props),
  title: 'Storage system',
});
