// (C) Copyright 2023-2024 Hewlett Packard Enterprise Development LP

import React from 'react';
import { Box, Button, Text } from 'grommet';
import { Modal } from '..';

export const UnmanagedSwitchConfirmModal = ({
  onClose,
  onConfirm,
  willShow,
}) => (
  <Modal show={willShow} onHide={onClose} size='medium'>
    <Modal.Header onDismiss={onClose}>
      <Modal.Title>Update</Modal.Title>
    </Modal.Header>
    <Box gap='medium' pad={{ horizontal: 'medium' }}>
      <Text as='div'>
        Applying these settings will permanently change the switch mode to
        unmanaged. This change cannot be reversed.
      </Text>
    </Box>
    <Modal.Footer>
      <Box
        direction='row'
        fill={true}
        gap='small'
        justify='end'
        pad={{ vertical: 'small' }}
      >
        <Button label='Cancel' onClick={onClose} secondary={true} />
        <Button label='Yes, update' onClick={onConfirm} primary={true} />
      </Box>
    </Modal.Footer>
  </Modal>
);
