// (C) Copyright 2016-2024 Hewlett Packard Enterprise Development LP

import * as c from '../../routes/consts.js';
import MetaBuilder, { SMALL } from '../../containers/generic/MetaBuilder';
import ColumnLink from '../../components/Griddle/ColumnLink';
import ItemViewContainer from '../../containers/generic/ItemViewContainer';
import CreateViewContainer from '../../containers/generic/CreateViewContainer';
import * as REGEX from '../../data/regex.js';
import { toDateTime } from '../../lib/formatters';

const BASE_URL = c.URL_PROJECTS;
const ITEM_URL = c.makeSecChildItemUrl(c.URL_PROJECTS, c.URL_MEMBERS);

const settings = {
  authUrl: BASE_URL, // used to filter create/trash icons from the view; using the defined roledef permissions mappings to this auth URL entry
  homeUrl: c.makeSecChildUrl(BASE_URL, ''), // homepage for this list view (e.g. /s/hosters/:pid/) is where this view is located; for return from create / item views
  baseUrl: c.makeChildUrl(BASE_URL, c.URL_MEMBERS), // base url to be used for creating all associated URLS for this reasource, e.g. pageItem, pageCreate, restUrl, restItemUrl
  meta: (props) => metabuilder(props),
};

export { default as ProjectMemberListView } from './ProjectMemberList';

export const ProjectMemberItemView = ItemViewContainer({
  ...settings,
  title: 'Project member',
});

export const ProjectMemberCreateView = CreateViewContainer({
  ...settings,
  title: 'Create project member',
});

function metabuilder(props) {
  const mb = new MetaBuilder(props);

  if (props.itemUrl) {
    mb.newDataSource(props.itemUrl)
      .Item(props.itemId)
      .OnLoad((json) => {
        mb.view.initForm(json.data);
      });
  }

  // resource base
  mb.addColumn('name', 'Name')
    .Custom(ColumnLink, {
      info: (rowData) => {
        const url = c.setParentId(
          c.setId(ITEM_URL, rowData.id),
          mb.view.prop('parentId'),
        );
        const { name } = rowData;
        return { url, name };
      },
    })
    .Default()
    .MaxWidth(SMALL);

  mb.addColumn('email', 'Email')
    .Default()
    .Input()
    .MaxWidth(SMALL)
    .Required()
    .MinLength(3)
    .RegEx(REGEX.EMAIL, REGEX.EMAIL_MSG);

  mb.addColumn('id', 'ID');
  mb.addColumn('created', 'Created')
    .FieldXform((created) => (created ? toDateTime(created) : '--'))
    .Default();
  mb.addColumn('modified', 'Modified')
    .FieldXform((modified) => (modified ? toDateTime(modified) : '--'))
    .Default();

  // state
  mb.addColumn('state', 'State').Default();

  mb.addColumn('portal_id', 'Portal ID');
  mb.addColumn('hoster_id', 'Hoster ID');
  mb.addColumn('project_id', 'Project ID');

  mb.addColumn('user_id', 'User ID');
  mb.addColumn('role_id', 'Role ID')
    .Input('project_member')
    .DropDown()
    .MaxWidth(SMALL)
    .DataXform(
      mb.newStaticDataSource([
        { id: 'project_member', name: 'Member' },
        { id: 'project_owner', name: 'Owner' },
        { id: 'project_viewer', name: 'Viewer' },
      ]),
      (json) => json,
    )
    .Default();
  mb.addColumn('scope', 'Scope');

  return mb;
}
