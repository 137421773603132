// (C) Copyright 2016-2024 Hewlett Packard Enterprise Development LP

import React from 'react';
import defaultTo from 'lodash/defaultTo';
import get from 'lodash/get';
import set from 'lodash/set';
import isEqual from 'lodash/isEqual';
import matches from 'lodash/matches';
import differenceWith from 'lodash/differenceWith';
import { Box } from 'grommet';
import * as c from '../../routes/consts';
import MetaBuilder, * as mb from '../../containers/generic/MetaBuilder';
import { toDateTime } from '../../lib/formatters';
import ItemViewContainer from '../../containers/generic/ItemViewContainer';
import CreateViewContainer from '../../containers/generic/CreateViewContainer';
import EditViewContainer from '../../containers/generic/EditViewContainer';
import debugLogger from '../../lib/debug';
import * as log from '../../lib/debug';
import rest from '../../lib/rest';
import { ValidationResult } from '../../containers/generic/MetaBuilder';
import { isEmpty, urlIcon, urlText } from '../../utils';
import { machOperators, subattributeOperators } from '../../data/machine';
import FormInlineSubattributes from '../../containers/generic/MetaForm/FormInlineSubattributes';
import {
  SERVICE_IMAGE_SECURE_URL,
  SERVICE_IMAGE_URL,
  SERVICE_IMAGE_URL_MSG,
  URL_MAX_LENGTH,
} from '../../data/regex';
import { LABEL_SERVICES } from '../../components/HybridNav/consts.js';

const debug = debugLogger('Service Meta', log.LOG_LEVEL_WARN);

const EXTERNAL_NAME = c.URL_SERVICES;

export const settings = {
  authUrl: EXTERNAL_NAME, // used to filter create/trash icons from the view; using the defined roledef permissions mappings to this auth URL entry
  homeUrl: c.makeSecUrl(EXTERNAL_NAME), // homepage for this list view (e.g. /s/hosters/:pid/) is where this view is located; for return from create / item views
  homeLabel: LABEL_SERVICES,
  baseUrl: EXTERNAL_NAME, // base url to be used for creating all associated URLS for this reasource, e.g. pageItem, pageCreate, restUrl, restItemUrl
  stateKey: EXTERNAL_NAME,
};

const APPROACH_ISOLATED = '';
const APPROACH_CLIENT = 'svc-client';
const APPROACH_IMAGE_SERVER = 'imagesvr';
const APPROACH_VIRTUAL_MEDIA = 'vmedia';

const SERVICE_APPROACHES = [
  { id: APPROACH_CLIENT, name: 'Service Client' },
  { id: APPROACH_IMAGE_SERVER, name: 'Deprecated: Image Server' },
  { id: APPROACH_VIRTUAL_MEDIA, name: 'Virtual Media' },
];

const SERVICE_DEPLOY = 'deploy';
const SERVICE_SYSTEM = 'system';
const SERVICE_TYPES = [SERVICE_DEPLOY, SERVICE_SYSTEM];

const SERVICE_SHA_256 = 'sha256sum';
const SERVICE_SHA_512 = 'sha512sum';
const SERVICE_HASHES = [SERVICE_SHA_256, SERVICE_SHA_512];

const ENCODING_NONE = 'none';
const ENCODING_HEX = 'hex';
const ENCODING_BASE64 = 'base64';
const ENCODING_METHODS = [ENCODING_NONE, ENCODING_HEX, ENCODING_BASE64];

const TARGET_IMAGESERVER = 'image-server';
const TARGET_FLOPPY = 'vmedia-floppy';
const TARGET_CD = 'vmedia-cd';
const TARGET_HDD = 'hdd';
const TARGETS = [TARGET_IMAGESERVER, TARGET_FLOPPY, TARGET_CD, TARGET_HDD];

const TEMPLATING_NONE = 'none';
const TEMPLATING_GO = 'go-text-template';
const TEMPLATING = [TEMPLATING_NONE, TEMPLATING_GO];

const SCHEMA_HOSTDEF_V1 = 'hostdef-v1';
const SCHEMA_HOSTDEF_V2 = 'hostdef-v2';
const SCHEMA_HOSTDEF_V3 = 'hostdef-v3';
const SCHEMA_INSTALLENV = 'install-env-v1';
const SCHEMAS = [
  SCHEMA_HOSTDEF_V1,
  SCHEMA_HOSTDEF_V2,
  SCHEMA_HOSTDEF_V3,
  SCHEMA_INSTALLENV,
];

const DS_SERVICE = 'service';
const DS_FWBASELINES = 'fwbaselines';
const DS_USER_OPS = 'userops';

const STATE_IMAGING_PREP = 'Imaging Prep';
const STATE_IMAGING_COMPLETE = 'Imaging Complete';
const STATE_MAINTENANCE = 'Maintenance';

const INTERFACE_VERSIONS = ['1.0', '1.1', '2.0'];

const DISK_PARTITION_TYPES = [
  'swap',
  'ext2',
  'ext3',
  'ext4',
  'xfs',
  'btrfs',
  'efi',
  'bios',
  'basic',
  'lvm',
  'luks',
  'vmdiag',
  'vmfs',
];

const FORMULA_MASK = [
  {
    length: [1, 4],
    options: [1, 2, 4, 8, 16, 32, 64, 128, 256, 512, 1024],
    regexp: /^\d{1,4}$/, // 1 to 4 digit number,
    placeholder: 'nnn',
  },
  { fixed: ' ' },
  {
    length: 0,
    options: ['M', 'G', 'T', '% memory', '% disk'],
    regexp:
      /^[MGT]$|^((%[ ]?)|(%m)|(%me)|(%mem)|(%memo)|(%memor)|(%memory)|(%d)|(%di)|(%dis)|(%disk))$/,
    placeholder: ' M,G,T or %resource',
  },
];

const FORMULA_RE =
  /^$|^([0-9]+[ ]?([MGT](iB)?)?)$|^([0-9]+[ ]?%[ ]?(memory|disk))$/;
const FORMULA_MSG =
  'Must be of the format "nnn M", or "nnn %disk" or "nnn %memory"';

function metaEdit(props) {
  const m = new MetaBuilder(props);

  // For create:
  // Called by CreateViewContainer->MetaForm
  // props.view is CreateViewContainer::MetaForm
  // m.view is CASTED CreateViewContainer::MetaForm
  // ds is a static empty object

  const id = m.view.prop('itemId', false);

  let ds;
  if (id === undefined) {
    ds = m.newStaticDataSource({}, DS_SERVICE);
  } else {
    ds = m
      .newDataSource(c.URL_SERVICES, DS_SERVICE)
      .Item(id)
      .OnLoad((json) => {
        debug.debug('got service:', json);
        m.view.initForm(json.data);
      });
  }

  m.addColumn('name', 'Name').Input().Required().MaxWidth(mb.SMALL);
  m.addColumn('description', 'Description').Input().MaxWidth(mb.SMALL);
  m.addColumn('project_use', 'Visible to project users when creating hosts')
    .Input()
    .MaxWidth(mb.SMALL)
    .CheckBox(false)
    .Visible(() => m.view.formDefaultTo('type') === SERVICE_DEPLOY);
  m.addColumn('hoster_use', 'Visible to hoster users when creating hosts')
    .Input()
    .MaxWidth(mb.SMALL)
    .CheckBox(false)
    .Visible(() => m.view.formDefaultTo('type') === SERVICE_DEPLOY);
  m.addColumn('no_switch_lag', 'Enable Switch-Side LAG')
    .Input()
    .MaxWidth(mb.SMALL)
    .Help(
      'Hosts shall make use of Switch-Side LAG or not based on this attribute during their deployment. In case HA is not available in a RACK then this attribute is irrelevant.'
    )
    .CheckBox(false)
    .Visible(() => m.view.formDefaultTo('type') === SERVICE_DEPLOY);

  m.addColumn('id', 'ID');
  m.addColumn('created', 'Created').FieldXform((created) =>
    created ? toDateTime(created) : '--'
  );
  m.addColumn('modified', 'Modified').FieldXform((modified) =>
    modified ? toDateTime(modified) : '--'
  );
  m.addColumn('type', 'Type')
    .Input(SERVICE_DEPLOY)
    .DropDown()
    .DataXform(m.newStaticDataSource(SERVICE_TYPES), (json) =>
      json.map((t) => ({ id: t, name: t }))
    )
    .MaxWidth(mb.SMALL)
    .OnChange((val) => {
      if (val !== SERVICE_DEPLOY) {
        m.view.setFormValue('project_use', false);
        m.view.setFormValue('hoster_use', false);
      }
    });

  if (props.mode === 'view') {
    m.addColumn('origin', 'Origin').Input().Default().MaxWidth(mb.SMALL);
    m.addColumn('organization_id', 'Organization ID')
      .Input()
      .MaxWidth(m.SMALL)
      .Visible(() => m.view.getForm().origin === 'Custom')
      .Default();
  }

  m.addColumn('svc_category', 'Category').Input().MaxWidth(mb.SMALL);
  m.addColumn('svc_flavor', 'Flavor')
    .Input()
    .MaxWidth(mb.SMALL);
  m.addColumn('svc_ver', 'Flavor version')
    .Input()
    .MaxWidth(mb.SMALL)
  m.addColumn('timeout', 'Service timeout (seconds)')
    .Input(1800)
    .Number()
    .MaxWidth(mb.SMALL)

  const DS_MACHINETYPES = 'machinetypes';
  const dsMachTypes = m.newDataSource(c.URL_MACHINETYPES, DS_MACHINETYPES);

  m.addColumn('type_ids', 'Machine types')
    .Input()
    .MaxWidth(mb.SMALL)
    .MultiSelect()
    .Width(mb.SMALL)
    .DataXform(dsMachTypes, (json) =>
      json.map((t) => ({ id: t.id, name: t.name }))
    )
    .OnChange((val) => {
      ds.Set('type_ids', val);
    });

  m.addSection('Service approach')
    .OverviewText(
      `Describes the way that the service is to be setup and consumed.
      Some services will only work with specific Pod topologies.`
    )
    .MaxWidth(mb.LARGE);

  m.addColumn('approach', 'Approach')
    .Input()
    .DropDown()
    .DataXform(m.newStaticDataSource(SERVICE_APPROACHES), (json) =>
      json.map(({ id, name }) => ({ id, name }))
    )
    .MaxWidth(mb.SMALL);

  //-----------------------------------------

  m.addSection('Files')
    .OverviewText('Files to be used by the service.')
    .MaxWidth(mb.LARGE);

  const fileTable = m
    .addInputTable('files', 'Files')
    .DataXform(ds, ({ files = [] }) => files);

  fileTable.AddDialogMeta(metaFile);
  fileTable.OnAdd((data) => {
    ds.Push('files', data);
    m.view.addFormValue('files', data);
  });
  fileTable.OnDelete((data) => {
    ds.Remove('files', data);
    m.view.removeFormValue('files', data);
  });
  fileTable.EditDialogMeta(metaFile);
  fileTable.OnEdit((nextData, oldData) => {
    ds.Update('files', oldData, nextData, true);
    m.view.updateFormValue('files', oldData, nextData, true);
  });

  fileTable
    .addField('path', 'Relative path')
    .CellXform((rowData) => rowData.path);
  fileTable
    .addField('file_size', 'File size')
    .CellXform((rowData) => rowData.file_size);
  fileTable
    .addField('secure_url', 'Security')
    .CellXform((rowData) => urlIcon(rowData));
  fileTable
    .addField('display_url', 'URL')
    .CellXform((rowData) => urlText(rowData));

  m.addSection('Info')
    .OverviewText(
      `Describes emedded contents that can be converted into file
      that, depending on the service approach.`
    )
    .MaxWidth(mb.LARGE);

  const infoTable = m
    .addInputTable('info', 'Info')
    .DataXform(ds, (json) => get(json, 'info', []));

  infoTable.AddDialogMeta(metaInfo);
  infoTable.OnAdd((data, modal, view) => {
    // biome-ignore lint/suspicious/noPrototypeBuiltins: <explanation>
    if (!data.hasOwnProperty('templating_input')) {
      data.templating_input = SCHEMA_HOSTDEF_V1;
    }
    rest
      .post('/rest/services/validate-info', data)
      .then((res) => {
        if (!res.ok) {
          view.setFormValue('_temp.info_validator', res.statusText);
        } else {
          view.setFormValue('_temp.info_validator', '');
          ds.Push('info', data);
          m.view.addFormValue('info', data);
          modal.hide();
        }
      })
      .catch((err) =>
        rest.errorInfo(err, (errInfo) => {
          view.setFormValue('_temp.info_validator', errInfo.text);
        })
      );
    return false;
  });
  infoTable.OnDelete((data) => {
    ds.Remove('info', data);
    m.view.removeFormValue('info', data);
  });
  infoTable.EditDialogMeta(metaInfo);
  infoTable.OnEdit((nextData, oldData, modal, view) => {
    // biome-ignore lint/suspicious/noPrototypeBuiltins: <explanation>
    if (!nextData.hasOwnProperty('templating_input')) {
      nextData.templating_input = SCHEMA_HOSTDEF_V1;
    }
    rest
      .post('/rest/services/validate-info', nextData)
      .then((res) => {
        if (!res.ok) {
          view.setFormValue('_temp.info_validator', res.statusText);
        } else {
          view.setFormValue('_temp.info_validator', '');
          ds.Update('info', oldData, nextData, true);
          m.view.updateFormValue('info', oldData, nextData, true);
          modal.hide();
        }
      })
      .catch((err) =>
        rest.errorInfo(err, (errInfo) => {
          view.setFormValue('_temp.info_validator', errInfo.text);
        })
      );
    return false;
  });

  infoTable.addField('path', 'Path').CellXform((rowData) => rowData.path);
  infoTable.addField('target', 'Target').CellXform((rowData) => rowData.target);
  infoTable
    .addField('templating', 'Templating')
    .CellXform((rowData) => rowData.templating);
  infoTable
    .addField('templating_input', 'Templating input')
    .CellXform((rowData) => rowData.templating_input);

  //-----------------------------------------

  userDefinedStepsSection({
    m,
    ds,
    title: 'Imaging preparation steps',
    overview:
      'To perform imaging preparation, actions are organized into steps. This may include updating firmware.',
    path: 'user_defined_steps.imgprep_steps',
    dialogMeta: metaHandlerImagingPrepStepsAdd,
  });

  userDefinedStepsSection({
    m,
    ds,
    title: 'Imaging complete steps',
    overview:
      'To perform imaging complete, actions are organized into steps. This may include updating firmware.',
    path: 'user_defined_steps.imgcomplete_steps',
    dialogMeta: metaHandlerImagingCompleteStepsAdd,
  });

  userDefinedStepsSection({
    m,
    ds,
    title: 'Maintenance steps',
    overview:
      'To perform machine maintenance, actions are organized into steps. This may include updating firmware.',
    path: 'user_defined_steps.mtc_steps',
    dialogMeta: metaHandlerMaintenanceStepsAdd,
    max_width: '1200px',
  });

  //-----------------------------------------

  m.addSection('Firmware Baseline')
    .OverviewText(
      'The Firmware Baseline is installed by update firmware. Update firmware can be defined in the Maintenance, Imaging Prep, and Imaging Complete steps.'
    )
    .MaxWidth(mb.LARGE);

  const dsFWBaselines = m.newDataSource(c.URL_FWBASELINES, DS_FWBASELINES);

  m.addColumn('fw_baseline_id', 'FW baseline')
    .Input()
    .DropDown('Select an item ...', 'No items found.', true)
    .DataXform(dsFWBaselines, (json) =>
      json?.length ? json.map(({ id, name }) => ({ id, name })) : []
    );

  //-----------------------------------------
  const dsProjects = m.newDataSource(c.URL_PROJECTS);
  m.addSection('Restricted use')
    .OverviewText(
      `Set restricted use for this service to the selected projects or
      classifiers below. If no restrictions are set the service is
      available to everyone.`
    )
    .MaxWidth(mb.LARGE);

  m.addColumn('permitted_projects', 'Permitted')
    .Input()
    .MultiSelect()
    .DataXform(dsProjects, (json) =>
      json.map((t) => ({ id: t.id, name: t.name }))
    )
    .MaxWidth(mb.SMALL);

  //-----------------------------------------
  m.addSection('Rules (Machine attributes)')
    .OverviewText(
      'Rules restrict which machines are allowed to use this OS service. This can affect the available machines in Host Create'
    )
    .MaxWidth(mb.LARGE);

  const classifierTable = m
    .addInputTable('classifiers', 'Rules')
    .DataXform(ds, (json) => json.classifiers || []);

  classifierTable.AddDialogMeta(metaClassifierAdd, 'large');
  classifierTable.EditDialogMeta(metaClassifierAdd, 'large');

  classifierTable.OnAdd((data) => {
    ds.Push('classifiers', data);
    m.view.addFormValue('classifiers', data);
  });

  classifierTable.OnDelete((data) => {
    ds.Remove('classifiers', data);
    m.view.removeFormValue('classifiers', data);
  });

  classifierTable.OnEdit((nextData, oldData) => {
    ds.Update('classifiers', oldData, nextData, true);
    m.view.updateFormValue('classifiers', oldData, nextData, true);
  });

  const classifierName = classifierTable
    .addField('name', 'Name')
    .CellXform(({ name }) => name);

  if (m.readOnly) {
    classifierName.ViewModalItemLink();
  }

  //-----------------------------------------
  m.addSection('Partitions')
    .OverviewText('Device partitioning information.')
    .MaxWidth(mb.LARGE);

  const layoutsTableTable = m
    .addInputTable('device_layouts', 'Layouts')
    .DataXform(ds, (json) => {
      debug.debug('device_layouts:DataXform', ds, json);
      return defaultTo(get(json, 'device_layouts', []), []);
    });
  layoutsTableTable.ViewDialogMeta(metaLayouts);

  layoutsTableTable.AddDialogMeta(metaLayouts);
  layoutsTableTable.OnAdd((data) => {
    ds.Push('device_layouts', data); // add to dataset used to render the page
    m.view.addFormValue('device_layouts', data); // add to form to be posted/put
  });

  layoutsTableTable.OnDelete((data) => {
    debug.debug('onDelete(device_layouts)', data);
    ds.Remove('device_layouts', data);
    m.view.removeFormValue('device_layouts', data);
  });

  layoutsTableTable.EditDialogMeta(metaLayouts);
  layoutsTableTable.OnEdit((nextData, oldData) => {
    debug.debug('onEdit(device_layouts)', oldData, nextData);

    ds.Update('device_layouts', oldData, nextData, true);
    m.view.updateFormValue('device_layouts', oldData, nextData, true);
  });

  layoutsTableTable
    .addField('device', 'Device')
    .CellXform((rowData) => rowData.device)
    .ViewModalItemLink();

  layoutsTableTable
    .addField('description', 'Description')
    .CellXform((rowData) => rowData.description);

  layoutsTableTable
    .addField('table_type', 'Partitioning')
    .CellXform((rowData) => rowData.table_type);

  return m;
}

function isUpdateFirmware(m) {
  const { user_defined_steps: steps } = m.view.getForm();

  const imgPrepFwUpdate = steps?.imgprep_steps?.some(
    ({ operation }) => !!operation?.startsWith('Update Firmware')
  );

  const imgCompleteFwUpdate = steps?.imgcomplete_steps?.some(
    ({ operation }) => !!operation?.startsWith('Update Firmware')
  );

  const mtcFwUpdate = steps?.mtc_steps?.some(
    ({ operation }) => !!operation?.startsWith('Update Firmware')
  );

  return imgPrepFwUpdate || imgCompleteFwUpdate || mtcFwUpdate;
}

function metaHandlerMaintenanceStepsAdd(props) {
  return metaUserOpsAdd(props, STATE_MAINTENANCE);
}

function metaHandlerImagingPrepStepsAdd(props) {
  return metaUserOpsAdd(props, STATE_IMAGING_PREP);
}

function metaHandlerImagingCompleteStepsAdd(props) {
  return metaUserOpsAdd(props, STATE_IMAGING_COMPLETE);
}

function userDefinedStepsSection({ m, ds, title, overview, path, dialogMeta }) {
  m.addSection(title).OverviewText(overview).MaxWidth(mb.LARGE);

  const t = m
    .addInputTable(path, title)
    .DataXform(ds, (json) => get(json, path, []));

  t.OnUpDown((action, data) => {
    const match = matches(data);

    if (ds.UpDown(path, action, match)) {
      m.view.setFormValue(path, get(ds.Data(), path));
    }
  });

  t.AddDialogMeta(dialogMeta);
  t.OnAdd((data) => {
    const form = m.view.getForm();
    const steps = m.view.formDefaultTo(path, []);
    ds.Push(path, data);
    m.view.addFormValue(path, data);
  });

  t.OnDelete((data) => {
    ds.Remove(path, data);
    m.view.removeFormValue(path, data);
  });

  t.EditDialogMeta(dialogMeta);
  t.OnEdit((nextData, oldData) => {
    ds.Update(path, oldData, nextData, true);
    m.view.updateFormValue(path, oldData, nextData, true);
  });

  t.addField('operation', 'Operation')
    .CellXform(({ operation }) => operation)
    .MaxWidth(mb.SMALL);

  t.addField('parameters', 'Parameters').CellXform(
    ({ parameters }) =>
      parameters?.map(({ name, value }) => `${name}=${value}`) || ''
  );
}

function metaUserOpsAdd(props, state) {
  const m = new MetaBuilder(props);

  const origForm = m.view.prop('formData', false);
  const ds = m.newStaticDataSource(origForm);
  const dsUserOps = m
    .newDataSource(c.URL_USER_OPS, DS_USER_OPS)
    .Filter(({ states }) => states?.[state] === true);

  m.addField('operation', 'Operation')
    .Input()
    .Required()
    .DropDown()
    .MaxWidth(mb.SMALL)
    .DataXform(dsUserOps, (json) =>
      json.map(({ description, operation }) => ({
        id: operation,
        name: operation,
        info: description,
      }))
    )
    .OnChange(() => {
      const { description, parameters } =
        dsUserOps
          .Data()
          .find((v) => v.operation === m.view.formDefaultTo('operation')) || {};

      const opArgs = defaultTo(parameters, []).map((opArg) => ({
        ...opArg,
      }));

      m.view.setFormValue('description', description);
      m.view.setFormValue('parameters', opArgs);
      ds.Set('parameters', opArgs);
    });

  const t = opParameterTable(m, ds);

  return m;
}

function opParameterTable(m, ds) {
  const t = m
    .addInputTable('parameters', 'Parameters')
    .DataXform(ds, (json) => defaultTo(get(json, 'parameters', []), []));

  t.EditDialogMeta(metaOpArgsEdit);
  t.OnEdit((nextData, oldData) => {
    ds.Update('parameters', oldData, nextData, true);
    m.view.updateFormValue('parameters', oldData, nextData, true);
  });

  t.addField('name', 'Parameter Name')
    .Input()
    .CellXform(({ name }) => name);

  t.addField('secure_value', '')
    .Input()
    .CellXform(({ secure_value, type, value }) =>
      type === 'URL'
        ? urlIcon({ secure_url: secure_value, display_url: value })
        : ''
    );

  t.addField('value', 'Value')
    .Input()
    .CellXform(({ value }) => value);

  return t;
}

function metaOpArgsEdit(props) {
  const m = new MetaBuilder(props);

  const origForm = m.view.prop('formData', false);
  const form = m.view.getForm();

  m.addField('description', 'Description').Input().ReadOnly();

  m.addField('value', origForm.name).Input().Required();

  if (form.type === 'URL') {
    m.addField('secure_value', 'Secure URL').Input();
  }

  return m;
}

const TABLE_TYPES = ['gpt' /* , "dos", "sun", "sgi" */]; // Currently only support gpt

function metaLayouts(props) {
  const m = new MetaBuilder(props);

  // props.view is FormInputTable::MetaForm in the Modal for addDialogMeta
  // m.view is the CASTED version props.view
  // props.parentView is the CreateViewContainer::MetaForm
  // m.parentView is the casted version of props.parentView
  // props.parentMeta
  // OK button in this modal does InputTable.FireOnAdd
  m.formDefaults((form) => {
    set(form, 'partitions', defaultTo(get(form, 'partitions', []), []));
  });

  m.addColumn('device', 'Device').Input().Required().MaxWidth(mb.SMALL);

  m.addColumn('description', 'Description').Input().MaxWidth(mb.SMALL);
  m.addColumn('start_offset', 'Start offset')
    .Input()
    .Number()
    .MaxWidth(mb.SMALL);
  m.addColumn('table_type', 'Table type')
    .Input()
    .DropDown()
    .DataXform(m.newStaticDataSource(TABLE_TYPES), (json) =>
      json.map((t) => ({ id: t, name: t }))
    )
    .MaxWidth(mb.SMALL);

  const origForm = m.view.propDefaultTo('formData', {});
  const ds = m.newStaticDataSource(origForm);
  const partitionsTable = m
    .addInputTable('partitions', 'Partitions')
    .DataXform(ds, (json) => defaultTo(get(json, 'partitions', []), []));
  partitionsTable.ViewDialogMeta(metaPartition);
  partitionsTable.AddDialogMeta(metaPartition);
  partitionsTable.OnAdd((data) => {
    ds.Push('partitions', data);
    m.view.addFormValue('partitions', data);
  });

  partitionsTable.OnDelete((data) => {
    ds.Remove('partitions', data);
    m.view.removeFormValue('partitions', data);
  });

  partitionsTable.EditDialogMeta(metaPartition);
  partitionsTable.OnEdit((nextData, oldData) => {
    ds.Update('partitions', oldData, nextData, true);
    m.view.updateFormValue('partitions', oldData, nextData, true);
  });

  partitionsTable
    .addField('type', 'Partition type')
    .Input()
    .CellXform((rowData) => rowData.type)
    .ViewModalItemLink();

  partitionsTable
    .addField('mount', 'Mount')
    .Input()
    .CellXform((rowData) => rowData.mount);

  partitionsTable
    .addField('formula.equation', 'Size')
    .Input()
    .CellXform((rowData) => {
      if (rowData.formula.equation === undefined) {
        return 'free disk';
      }
      return rowData.formula.equation;
    });

  partitionsTable
    .addField('formula.min_size', 'Min')
    .Input()
    .CellXform((rowData) => rowData.formula.min_size);

  partitionsTable
    .addField('formula.max_size', 'Max')
    .Input()
    .CellXform((rowData) => rowData.formula.max_size);

  partitionsTable
    .addField('bootable', 'Boot')
    .Input()
    .CellXform((rowData) => (!rowData.bootable ? '' : '*'));

  return m;
}

function metaClassifierAdd(props) {
  const m = new MetaBuilder(props);

  m.formDefaults((form) => {
    form.rules = defaultTo(form.rules, []);
  });

  // if edit, then remove item being edited from unique set
  const origForm = m.view.propDefaultTo('formData', {});
  const dsOrigForm = m.newStaticDataSource(origForm);

  // unique name for classifier
  let other_classifiers = m.parent.view.formDefaultTo('classifiers', []);

  if (!isEmpty(origForm)) {
    other_classifiers = differenceWith(other_classifiers, [origForm], isEqual);
  }

  m.addField('name', 'Name')
    .Input()
    .Required()
    .UniqueIn(other_classifiers)
    .MaxWidth(mb.SMALL);

  // rules
  m.addSection('Rule (Machine Attributes)').OverviewText(
    "All rules within a classifier must match a machine's attributes for a machine to match the classifier (Logical AND)."
  );

  const rulesTable = m
    .addInputTable('rules', 'Rules')
    .DataXform(dsOrigForm, (json) => json.rules || [])
    .Required();

  rulesTable.AddDialogMeta(metaRuleAdd, 'large');

  rulesTable.OnAdd((data) => {
    debug.debug('onAdd(rules)', data);
    dsOrigForm.Push('rules', data);
    m.view.addFormValue('rules', data);
  });

  rulesTable.OnDelete((data) => {
    debug.debug('onDelete(rules)', data);
    dsOrigForm.Remove('rules', data);
    m.view.removeFormValue('rules', data);
  });

  rulesTable.EditDialogMeta(metaRuleAdd, 'large');

  rulesTable.OnEdit((nextData, oldData) => {
    debug.debug('onEdit(rules)', oldData, nextData);
    dsOrigForm.Update('rules', oldData, nextData, true);
    m.view.updateFormValue('rules', oldData, nextData, true);
  });

  rulesTable
    .addField('attribute', 'Attribute')
    .CellXform((rowData) => rowData.attribute);

  rulesTable
    .addField('operator', 'Operator')
    .CellXform((rowData) => rowData.operator);

  rulesTable.addField('value', 'Value').CellXform((rowData) => rowData.value);

  rulesTable
    .addField('subattributes', '') // Hide column but pass the data
    .Custom()
    .CellXform((rowData) => rowData.subattribute_rules);

  return m;
}

function metaRuleAdd(props) {
  const m = new MetaBuilder(props);

  const dsMachineOSClassifierInfo = m.newDataSource(
    c.URL_MACHINE_OS_CLASSIFIER_INFO
  );
  const dsOperators = m.newStaticDataSource(machOperators);

  const hasSubattributes = () => {
    const { attribute } = m.view.getForm();

    const attributeData = dsMachineOSClassifierInfo
      .Data()
      ?.rule_attributes.find(({ name }) => attribute === name);

    return attributeData?.has_subattributes;
  };

  const withSubattributes = () => hasSubattributes();
  const withoutSubattributes = () => !hasSubattributes();

  // TODO: validate unique rule for all 3 attributes

  m.addField('attribute', 'Attribute', true)
    .Input()
    .Required()
    .DropDown()
    .MaxWidth(mb.SMALL)
    .DataXform(dsMachineOSClassifierInfo, (json) =>
      json.rule_attributes.map((t) => ({ ...t, id: t.name }))
    )
    .OnInit(() => {
      if (hasSubattributes()) {
        dsOperators.SetData(['match']);
      }
    })
    .OnChange(() => {
      if (hasSubattributes()) {
        m.view.setFormValue('operator', 'match');
        dsOperators.SetData(['match']);
      } else {
        m.view.setFormValue('subattribute_rules', []);
        dsOperators.SetData(machOperators);
      }
    });

  m.addField('operator', 'Operator')
    .Input()
    .Required()
    .DropDown()
    .MaxWidth(mb.SMALL)
    .DataXform(dsOperators, (json) => json.map((t) => ({ id: t, name: t })))
    .CustomValidator((value) => {
      if (withSubattributes() && value !== 'match') {
        return new ValidationResult(
          false,
          "'match' is only allowed for attribute with subattributes"
        );
      }

      return new ValidationResult(true, '');
    });

  m.addField('value', 'Value')
    .Input()
    .MaxWidth(mb.SMALL)
    .RequiredSometimes(withoutSubattributes)
    .Visible(withoutSubattributes);

  m.addField('subattribute_rules', 'Subattributes')
    .Input()
    .Custom(FormInlineSubattributes, {
      operators: subattributeOperators,
      attributes: dsMachineOSClassifierInfo,
    })
    .Visible(withSubattributes)
    .CustomValidator((value) => {
      if (
        !value ||
        (!value.length && !hasSubattributes()) ||
        (value.length &&
          value.every((row) => Object.keys(row).every((key) => !!row[key])))
      ) {
        return new ValidationResult(true);
      }
      const validations = value.map((subattribute) =>
        Object.keys(subattribute).reduce(
          (acc, key) =>
            !subattribute[key] ? { ...acc, [key]: `${key} is required` } : acc,
          {}
        )
      );

      return new ValidationResult(false, validations);
    });

  return m;
}

function metaPartition(props) {
  const m = new MetaBuilder(props);

  m.formDefaults((form) => {
    set(form, 'formula', defaultTo(get(form, 'formula', {}), {}));
  });

  m.addColumn('type', 'Type')
    .Input()
    .Required()
    .DropDown()
    .DataXform(m.newStaticDataSource(DISK_PARTITION_TYPES), (json) =>
      json.map((t) => ({ id: t, name: t }))
    )
    .MaxWidth(mb.SMALL);

  m.addColumn('formula.equation', 'Equation')
    .Input()
    .Mask(FORMULA_MASK)
    .MaxWidth(mb.SMALL)
    .RegEx(FORMULA_RE, FORMULA_MSG);

  m.addColumn('formula.min_size', 'Min size')
    .Input()
    .Mask(FORMULA_MASK)
    .MaxWidth(mb.SMALL)
    .RegEx(FORMULA_RE, FORMULA_MSG);

  m.addColumn('formula.max_size', 'Max size')
    .Input()
    .Mask(FORMULA_MASK)
    .MaxWidth(mb.SMALL)
    .RegEx(FORMULA_RE, FORMULA_MSG);

  m.addColumn('mount', 'Mount point').Input().MaxWidth(mb.SMALL);
  m.addColumn('name', 'Name').Input().MaxWidth(mb.SMALL);
  m.addColumn('partition_id', 'Partition ID')
    .Input()
    .Number()
    .MaxWidth(mb.SMALL);
  m.addColumn('bootable', 'Bootable').Input().CheckBox().MaxWidth(mb.SMALL);
  m.addColumn('make_options', 'Make options').Input().MaxWidth(mb.SMALL); // TODO array of strings
  m.addColumn('mount_options', 'Mount options').Input().MaxWidth(mb.SMALL);

  return m;
}

function metaFile(props) {
  const m = new MetaBuilder(props);

  m.addColumn('path', 'Path').Input().Required().MaxWidth(mb.SMALL);

  m.addColumn('file_size', 'File size')
    .Number()
    .Input()
    .Required()
    .MaxWidth(mb.SMALL);

  m.addColumn('display_url', 'Display URL')
    .Input()
    .MaxLength(URL_MAX_LENGTH)
    .MaxWidth(mb.SMALL)
    .RegEx(SERVICE_IMAGE_URL, SERVICE_IMAGE_URL_MSG());

  // TODO: improve handling for secure_url default value vs new one
  //  - if secure_url gets focus, all is selected/replaced
  //  - use type=password to prevent secure_url value from being seen?
  m.addColumn('secure_url', 'Secure URL')
    .Input()
    .MaxLength(URL_MAX_LENGTH)
    .MaxWidth(mb.SMALL)
    .RegEx(SERVICE_IMAGE_SECURE_URL, SERVICE_IMAGE_URL_MSG());

  m.addColumn('download_timeout', 'Download timeout')
    .Input(300)
    .MaxWidth(mb.SMALL)
    .Required()
    .Number();

  m.addColumn('signature', 'File signature')
    .Input()
    .Required()
    .MaxWidth(mb.SMALL);

  m.addColumn('algorithm', 'File hash algorithm')
    .Input(SERVICE_SHA_256)
    .DropDown()
    .DataXform(m.newStaticDataSource(SERVICE_HASHES), (json) =>
      json.map((t) => ({ id: t, name: t }))
    )
    .Width(mb.SMALL)
    .Required();

  return m;
}

function metaUserDefinedSteps(props) {
  const m = new MetaBuilder(props);

  m.addColumn('operation', 'Operation').Input().Required().MaxWidth(mb.SMALL);

  m.addColumn('parameters', 'Parameters').Input().Required();

  return m;
}

function metaInfo(props) {
  const m = new MetaBuilder(props);

  // The OK and Edit callbacks for this form call an
  // asynchronous rest call to validate the fields and
  // stores the results in _temp.info_validator. These
  // functions get any messages for a particular field
  // and use it as a validation method for the field as
  // well as clear that message when the field changes
  const getValidatorMessage = (name) => {
    const validator = m.view.formDefaultTo('_temp.info_validator', '');
    const fields = validator.split(':');
    const messageIndex = validator.lastIndexOf(':') + 1;
    if (
      defaultTo(fields[0], '').toLowerCase() === name ||
      defaultTo(fields[1], '').toLowerCase() === name
    ) {
      return defaultTo(validator.substr(messageIndex), '');
    }
    return name ? '' : defaultTo(validator.substr(messageIndex), '');
  };

  const infoValidator = (name) => {
    const message = getValidatorMessage(name);

    return new ValidationResult(!message, message);
  };

  const updateValidator = () => {
    // clear the validator message
    m.view.setFormValue('_temp.info_validator', '');
  };

  const encode = (data, encoding) => {
    let encodedData = data;
    switch (encoding) {
      case ENCODING_BASE64:
        encodedData = btoa(data);
        break;
      case ENCODING_HEX:
        encodedData = new Buffer(data).toString('hex');
        break;
      default:
        break;
    }
    return encodedData;
  };

  const decode = (data, encoding) => {
    let decodedData = data;
    switch (encoding) {
      case ENCODING_BASE64:
        decodedData = atob(data);
        break;
      case ENCODING_HEX:
        decodedData = new Buffer(data, 'hex').toString();
        break;
      default:
        break;
    }
    return decodedData;
  };

  m.formDefaults((form) => {
    const encodedContents = m.view.formDefaultTo('contents', '');
    const encoding = m.view.formDefaultTo('encoding', ENCODING_NONE);

    set(
      form,
      'templating_input',
      defaultTo(get(form, 'templating_input', SCHEMAS[0]), SCHEMAS[0])
    );
    set(form, '_temp.contentsDecoded', decode(encodedContents, encoding));
  });

  m.addColumn('target', 'Target')
    .Input(TARGET_IMAGESERVER)
    .DropDown()
    .DataXform(m.newStaticDataSource(TARGETS), (json) =>
      json.map((t) => ({ id: t, name: t }))
    )
    .Width(mb.SMALL)
    .CustomValidator((value) => infoValidator('target', value))
    .OnChange(() => updateValidator('target'));

  m.addColumn('path', 'Path')
    .Input()
    .Required()
    .MaxWidth(mb.SMALL)
    .CustomValidator((value) => infoValidator('path', value))
    .OnChange(() => updateValidator('path'));

  m.addColumn('encoding', 'Data Encoding')
    .Input(ENCODING_NONE)
    .DropDown()
    .DataXform(m.newStaticDataSource(ENCODING_METHODS), (json) =>
      json.map((t) => ({ id: t, name: t }))
    )
    .Width(mb.SMALL)
    .CustomValidator((value) => infoValidator('encoding', value))
    .OnChange((value) => {
      const decodedContent = m.view.formDefaultTo('_temp.contentsDecoded');
      const encodedContents = encode(decodedContent, value);
      m.view.setFormValue('contents', encodedContents);
      updateValidator('encoding');
    });

  m.addColumn('_temp.contentsDecoded', 'Decoded Data')
    .Input()
    .TextArea()
    .MaxWidth(mb.SMALL)
    .Required()
    .CustomValidator((value) => infoValidator('content', value))
    .OnChange((value) => {
      const encoding = m.view.formDefaultTo('encoding', ENCODING_NONE);
      const encodedContents = encode(value, encoding);
      m.view.setFormValue('contents', encodedContents);
      updateValidator('contents');
    });

  m.addColumn('contents', 'Encoded Data').Input().Hidden();

  m.addColumn('templating', 'Templating')
    .Input(TEMPLATING_NONE)
    .DropDown()
    .DataXform(m.newStaticDataSource(TEMPLATING), (json) =>
      json.map((t) => ({ id: t, name: t }))
    )
    .Width(mb.SMALL)
    .CustomValidator((value) => infoValidator('templating', value))
    .OnChange(() => updateValidator('templating'));

  m.addColumn('templating_input', 'Templating Input')
    .Input(SCHEMA_HOSTDEF_V1)
    .DropDown()
    .DataXform(m.newStaticDataSource(SCHEMAS), (json) =>
      json.map((t) => ({ id: t, name: t }))
    )
    .Width(mb.SMALL)
    .Visible(() => m.view.formDefaultTo('templating') === TEMPLATING_GO)
    .CustomValidator((value) => infoValidator('templating_input', value))
    .OnChange(() => updateValidator('templating_input'));

  m.addField('_temp.info_validator', '').Input().Hidden();

  return m;
}

export const ServiceItemView = ItemViewContainer({
  ...settings,
  meta: (props) => metaEdit(props),
  allowEdit: true,
  title: 'Service',
});

export const ServiceCreateView = CreateViewContainer({
  ...settings,
  meta: (props) => metaEdit(props),
  title: 'Create Service',
});

export const ServiceEditView = EditViewContainer({
  ...settings,
  meta: (props) => metaEdit(props),
  title: 'Edit Service',
});

export { default as ServiceListView } from './ServiceListView';

export { default as HosterServiceList } from './HosterServiceList';

export { default as HosterServiceCatalogList } from './HosterServiceCatalogList';

export const HosterServiceCatalogItemView = ItemViewContainer({
  ...settings,
  // meta: metaList({ itemUrl: c.URL_HOSTER_SERVICES }),
  meta: (props) => metaEdit(props),
  homeUrl: c.makeSecUrl(c.URL_HOSTER_SERVICES),
  allowEdit: false,
  title: 'Service',
});

export const HosterServiceItemView = ItemViewContainer({
  ...settings,
  meta: (props) => metaEdit(props),
  homeUrl: c.makeSecUrl(c.URL_HOSTER_SERVICES),
  allowEdit: true,
  editUrl: c.makeSecEditUrl(c.URL_HOSTER_SERVICES),
  title: 'Service',
});

export const HosterServiceCreateView = CreateViewContainer({
  ...settings,
  meta: (props) => metaEdit(props),
  homeUrl: c.makeSecUrl(c.URL_HOSTER_SERVICES),
  title: 'Create service',
});

export const HosterServiceEditView = EditViewContainer({
  ...settings,
  meta: (props) => metaEdit(props),
  homeUrl: c.makeSecUrl(c.URL_HOSTER_SERVICES),
  title: 'Edit service',
});
