// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

import React from 'react';
import PropTypes from 'prop-types';
import { Text } from 'grommet';
import { SelectCard } from './SelectCard';

const Attr = ({ value, ...rest }) =>
  value ? (
    <Text as='div' size='small' truncate {...rest}>
      {value}
    </Text>
  ) : null;

export const MachineSize = ({
  available,
  desc,
  disabled,
  name,
  onClick,
  selected,
}) => {
  const textColor = disabled ? 'text-weak' : 'text';
  const strong = disabled ? 'text-weak' : 'text-strong';

  return (
    <SelectCard
      disabled={disabled}
      onClick={onClick}
      selected={selected}
      title={desc.banner1 || name}
      footer={`${available ? '' : 'none '}available`}
    >
      <Attr value={desc.banner2} weight='bold' color={strong} />
      {desc.bullets?.map((value) => (
        <Attr value={value} key={value} color={textColor} />
      ))}
    </SelectCard>
  );
};

Attr.propTypes = {
  value: PropTypes.string,
};

MachineSize.propTypes = {
  name: PropTypes.string,
  desc: PropTypes.shape({
    banner1: PropTypes.string,
    banner2: PropTypes.string,
    bullets: PropTypes.arrayOf(PropTypes.string),
  }),
  disabled: PropTypes.bool,
  available: PropTypes.number,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
};
