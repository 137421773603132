// (C) Copyright 2023-2024 Hewlett Packard Enterprise Development LP

import { useQueries } from 'react-query';
import { getStorageGroups } from './storageGroups';
import { get } from '../lib/rest';
import { toDateTime } from '../lib/formatters';
import {
  makeRestUrl,
  URL_STORAGE_GROUPS,
  URL_STORAGE_SYSTEMS,
} from '../routes/consts';
import { getDefaultQueriesResponse, renderLink } from './helper';
import HealthStatus from '../components/HealthStatus';
import TruncateText from '../components/Griddle/TruncateText';

const columns = ({ storageGroups }) => [
  {
    header: 'Health',
    isDefault: true,
    property: 'health_status',
    render: ({ health_status: status }) => (
      <HealthStatus status={status} showLabel />
    ),
  },
  {
    header: 'Name',
    isDefault: true,
    property: 'name',
  },
  {
    header: 'Volume family',
    isDefault: true,
    property: 'volume_family',
  },
  {
    header: 'Storage group',
    property: 'storage_group_id',
    render: ({ storage_group_id }) => (
      <TruncateText
        content={storageGroups?.find(({ id }) => id === storage_group_id)?.name}
      />
    ),
  },
  {
    header: 'ID',
    property: 'id',
    render: ({ id }) => <TruncateText content={id} />,
  },
  {
    header: 'Created',
    property: 'created',
    render: ({ created }) => <TruncateText content={toDateTime(created)} />,
  },
  {
    header: 'Modified',
    property: 'modified',
    render: ({ modified }) => <TruncateText content={toDateTime(modified)} />,
  },
];

export const getStorageSystems = async () => {
  const url = makeRestUrl(URL_STORAGE_SYSTEMS);
  const response = await get(url);

  return response.json();
};

export const useGetStorageSystems = () => {
  const queryResults = useQueries([
    {
      queryKey: URL_STORAGE_SYSTEMS,
      queryFn: getStorageSystems,
    },
    {
      queryKey: URL_STORAGE_GROUPS,
      queryFn: getStorageGroups,
    },
  ]);

  const isLoaded = queryResults.every(
    ({ error, isLoading }) => !isLoading && !error,
  );

  if (isLoaded) {
    const [storageSystems, storageGroups] = queryResults;

    return {
      columns: columns({ storageGroups: storageGroups.data })
        // adding columnName and displayName for backwards compatibility
        .map((column) => ({
          ...column,
          columnName: column.property,
          displayName: column.header,
        })),
      data: storageSystems.data,
      isLoading: false,
      refetch: storageSystems.refetch,
      error: null,
    };
  }

  return getDefaultQueriesResponse(queryResults);
};
