// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

import { useCallback, useEffect, useState } from 'react';
import rest from '../../lib/rest';

import { useInterval } from './useInterval';
import { makeRestUrl, URL_CERT } from '../../routes/consts';

// Refresh interval in ms
const DEFAULT_REFRESH = 5000;

// 24 hrs * 60 m/hr * 60 s/m * 1000 ms/s
const MS_PER_DAY = 24 * 60 * 60 * 1000;
const EXP_WINDOW = 30; // 30 days

export const usePortalCert = (refresh = null) => {
  const [_certificates, _setCertificates] = useState([]);
  const [certificates, setCertificates] = useState([]);
  const [interval, setInterval] = useState(refresh);

  const fetchCert = useCallback(() => {
    const url = makeRestUrl(URL_CERT);

    rest
      .get(url)
      .then((response) => response.json())
      .then((json) => {
        _setCertificates(Array.from(json));
        setInterval(refresh !== undefined ? refresh : null);
      })
      .catch((err) => {
        rest.handleError(err);
        // try again soonish if the call failed
        setInterval(DEFAULT_REFRESH);
      });
  }, [refresh]);

  useInterval(fetchCert, interval);

  useEffect(fetchCert, []);

  useEffect(() => {
    const getExpiryMessage = ({
      issuer = '',
      serial = '',
      valid_to: validTo,
    }) => {
      if (!validTo) {
        return '';
      }

      const msLeft = Date.parse(validTo) - Date.now();
      const daysLeft = msLeft / MS_PER_DAY;

      const name = `${issuer}-${serial.slice(0, 5)}...`;

      if (daysLeft <= 0) {
        return `Portal certificate (${name}) is expired.`;
        // biome-ignore lint/style/noUselessElse: <explanation>
      } else {
        let left;

        if (daysLeft < 1) {
          left = '< 1 day';
        } else if (daysLeft < 2) {
          left = '1 day';
        } else if (daysLeft <= EXP_WINDOW) {
          left = `${Math.floor(daysLeft)} days`;
        }

        if (left) {
          return `Portal certificate (${name}) expires ${new Date(
            validTo,
          ).toLocaleDateString()} (in ${left}.)`;
        }

        return '';
      }
    };

    const formattedCertificates = _certificates.map((certificate) => {
      const statusMessage = getExpiryMessage(certificate);

      return {
        ...certificate,
        statusMessage,
      };
    });

    setCertificates(formattedCertificates);
  }, [_certificates]);

  return certificates;
};
