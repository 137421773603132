// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

export const policyAttributesIn = [
  'Custom TCP',
  'Custom UDP',
  'Custom ICMP -IPv4',
  'Custom ICMP -IPv6',
  'Custom Protocol',
  'All TCP',
  'All UDP',
  'All ICMP -IPv4',
  'All ICMP -IPv6',
  'All Traffic',
  'SSH',
  'SMTP',
  'DNS (UDP)',
  'DNS (TCP)',
  'HTTP',
  'IMAP',
  'LDAP',
  'HTTPS',
  'SMB',
  'SMTPS',
  'IMAPS',
  'POP3S',
  'MSSQL',
];

export const policyAttributesOut = [
  'Custom TCP',
  'Custom UDP',
  'Custom ICMP -IPv4',
  'Custom ICMP -IPv6',
  'Custom Protocol',
  'All TCP',
  'All UDP',
  'All ICMP -IPv4',
  'All ICMP -IPv6',
  'All Traffic',
  'SSH',
  'SMTP',
  'DNS (UDP)',
  'DNS (TCP)',
  'HTTP',
  'POP3',
  'IMAP',
  'LDAP',
  'HTTPS',
];

export const policyActions = ['Deny', 'Allow', 'Limit'];
