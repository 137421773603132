// (C) Copyright 2022-2024 Hewlett Packard Enterprise Development LP

import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import auth from '../../lib/auth';
import ListPage from '../../containers/generic/ListPage';
import { URL_HOSTERS, URL_MEMBERS } from '../../routes/consts';
import { useGetHosterMembers } from '../../services/members';

const HosterMemberListView = () => {
  const { id } = useParams();
  const history = useHistory();
  const isHosterOwner = auth.activeRoleDef?.id === 'hoster_owner';
  const isHosterViewer = auth.activeRoleDef?.id === 'hoster_viewer';
  const portalOwners = ['portal_owner', 'portal_super'];
  const isPortalOwner = portalOwners.includes(auth.activeRoleDef?.id);
  const canAddMember = isHosterOwner || isPortalOwner;

  return (
    <ListPage
      actions={{
        allowCreate: canAddMember ? 'Add member' : undefined,
        allowCreateCb: () => {
          const { pathname } = history.location;
          const path = pathname.endsWith('/')
            ? pathname.slice(0, -1)
            : pathname;

          history.push(`${path}/members/create`);
        },
        allowDelete: !isHosterViewer, // members need to set manually
      }}
      path={`${URL_HOSTERS}/${id}/${URL_MEMBERS}`}
      sort={{
        property: 'name',
        direction: 'asc',
      }}
      parentId={id}
      title='Members'
      dynamicLevel={3}
      useGetData={useGetHosterMembers}
    />
  );
};

export default HosterMemberListView;
