// (C) Copyright 2022-2024 Hewlett Packard Enterprise Development LP

import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import ListPage from '../../containers/generic/ListPage';
import { URL_MEMBERS, URL_PORTALS } from '../../routes/consts';
import { useGetPortalMembers } from '../../services/members';

const PortalMemberList = () => {
  const { id } = useParams();
  const history = useHistory();

  return (
    <ListPage
      actions={{
        allowCreate: 'Add Member',
        allowCreateCb: () => {
          const { pathname } = history.location;
          const path = pathname.endsWith('/')
            ? pathname.slice(0, -1)
            : pathname;

          history.push(`${path}/members/create`);
        },
        allowDelete: true, // members need to set manually
      }}
      path={`${URL_PORTALS}/${id}/${URL_MEMBERS}`}
      sort={{
        property: 'name',
        direction: 'asc',
      }}
      parentId={id}
      useGetData={useGetPortalMembers}
    />
  );
};

export default PortalMemberList;
