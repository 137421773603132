// (C) Copyright 2022-2024 Hewlett Packard Enterprise Development LP

import { useQueries } from 'react-query';
import auth from '../lib/auth';
import { get } from '../lib/rest';
import {
  makeRestUrl,
  SCOPE_HOSTER,
  URL_NETWORKS,
  URL_PROJECTS,
} from '../routes/consts';
import { renderLink, getDefaultQueriesResponse } from './helper';
import { toDateTime } from '../lib/formatters';
import { getProjects } from './projects';

const columns = ({ projects }) => [
  {
    header: 'ID',
    property: 'id',
  },
  {
    header: 'Location',
    isDefault: true,
    property: 'location',
  },
  {
    header: 'Name',
    isDefault: true,
    property: 'network_name',
    render: renderLink({
      base: URL_NETWORKS,
      titleKey: 'network_name',
    }),
  },
  {
    header: 'Description',
    isDefault: true,
    property: 'description',
  },
  {
    header: 'Fabric',
    isDefault: true,
    property: 'fabric',
  },
  {
    header: 'Host network use policy',
    isDefault: true,
    property: 'host_use',
  },
  {
    header: 'VID',
    property: 'vid',
  },
  {
    header: 'VNI',
    property: 'vni',
  },
  {
    header: 'IP Pool',
    isDefault: true,
    property: 'ip_pool_id',
  },
  {
    header: 'Purpose',
    isDefault: true,
    property: 'purpose',
  },
  {
    header: 'DNS update IP',
    property: 'dns_update_config_ip',
  },
  ...(auth.inScope(SCOPE_HOSTER)
    ? [
        {
          header: 'Project',
          isDefault: true,
          property: 'project_id',
          render: ({ project_id: projectId }) => {
            switch (projectId) {
              case '*':
                return '<Any>';
              case '':
              case undefined:
                return '<Self>';
              default:
                return projects?.find(({ id }) => id === projectId)?.name;
            }
          },
        },
        {
          header: 'Permitted projects',
          property: 'permitted_projects',
          render: (data) => (data?.permitted_projects || [])?.join(', '),
        },
      ]
    : []),
  {
    header: 'Created',
    property: 'created',
    render: ({ created }) => toDateTime(created),
  },
  {
    header: 'Modified',
    property: 'modified',
    render: ({ modified }) => toDateTime(modified),
  },
];

export const getNetworks = async () => {
  const response = await get(makeRestUrl(URL_NETWORKS));

  return response.json();
};

export const useGetNetworks = () => {
  const queryResults = useQueries([
    {
      queryKey: URL_NETWORKS,
      queryFn: getNetworks,
    },
    {
      queryKey: URL_PROJECTS,
      queryFn: getProjects,
    },
  ]);

  const isLoaded = queryResults.every(
    ({ error, isLoading }) => !isLoading && !error,
  );

  if (isLoaded) {
    const [networks, projects] = queryResults;
    // adding columnName and displayName for backwards compatibility
    return {
      columns: columns({
        projects: projects.data,
      }).map((column) => ({
        ...column,
        columnName: column.property,
        displayName: column.header,
      })),
      data: networks.data.map(
        ({ location, project_id: projectId, ...datum }) => {
          const { data_center: pod, country, region } = location || {};
          const locationLabel = `${country}: ${region}: ${pod}`;

          return {
            ...datum,
            ...(auth.inScope(SCOPE_HOSTER)
              ? { project_id: projectId || '' }
              : {}),
            location: locationLabel,
          };
        },
      ),
      isLoading: false,
      refetch: () => {
        networks.refetch();
        projects.refetch();
      },
      error: null,
    };
  }

  return getDefaultQueriesResponse(queryResults);
};
