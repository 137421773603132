// (C) Copyright 2022-2024 Hewlett Packard Enterprise Development LP

import { useQuery } from 'react-query';

import { get } from '../lib/rest';
import { toDateTime } from '../lib/formatters';
import { renderLink } from './helper';
import { makeRestUrl, SCOPE_PORTAL, URL_SWITCHTYPES } from '../routes/consts';
import auth from '../lib/auth';

const columns = [
  {
    header: 'Name',
    isDefault: true,
    property: 'name',
    render: renderLink({
      base: URL_SWITCHTYPES,
    }),
  },
  {
    header: 'Description',
    isDefault: true,
    property: 'description',
  },
  {
    header: 'Switch family',
    property: 'sw_family',
  },
  {
    header: 'ID',
    property: 'id',
  },
  {
    header: 'Created',
    property: 'created',
    render: ({ created }) => toDateTime(created),
  },
  {
    header: 'Modified',
    property: 'modified',
    render: ({ modified }) => toDateTime(modified),
  },
  {
    header: 'Manufacturer',
    isDefault: true,
    property: 'manufacturer',
  },
  {
    header: 'Part numbers',
    isDefault: true,
    property: 'part_numbers',
  },
  {
    header: 'Rack controller',
    isDefault: true,
    property: 'rack_controller',
  },
  ...(auth.inScope(SCOPE_PORTAL)
    ? [
        {
          header: 'Published',
          isDefault: true,
          property: 'hoster_published',
          render: ({ hoster_id: hosterId }) =>
            hosterId === '*' ? 'yes' : 'no',
        },
      ]
    : []),
];

export const getSwitchTypes = async () => {
  const response = await get(makeRestUrl(URL_SWITCHTYPES));

  return response.json();
};

export const useGetSwitchTypes = () => {
  const queryResult = useQuery(URL_SWITCHTYPES, getSwitchTypes);

  return {
    ...queryResult,
    columns: columns.map((column) => ({
      ...column,
      columnName: column.property,
      displayName: column.header,
    })),
    data:
      queryResult.data?.map((switchType) => ({
        ...switchType,
        part_numbers: switchType.part_numbers.join(', '),
        rack_controller: switchType.rack_controller
          ? 'embedded / external'
          : 'external',
      })) ?? [],
  };
};
