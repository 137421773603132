// (C) Copyright 2022-2024 Hewlett Packard Enterprise Development LP

import { useQuery } from 'react-query';

import { toDateTime } from '../lib/formatters';
import { get } from '../lib/rest';
import { makeRestUrl, URL_VOLUME_GROUPS } from '../routes/consts';

const columns = [
  {
    header: 'Name',
    isDefault: true,
    property: 'name',
  },
  {
    header: 'ID',
    property: 'id',
  },
  {
    header: 'Created',
    property: 'created',
    render: ({ created }) => toDateTime(created),
  },
  {
    header: 'Modified',
    property: 'modified',
    render: ({ modified }) => toDateTime(modified),
  },
  {
    header: 'Array group ID',
    isDefault: true,
    property: 'arrayVolumeGroupID',
  },
];

export const getVolumeGroups = async () => {
  const response = await get(makeRestUrl(URL_VOLUME_GROUPS));

  return response.json();
};

export const useGetVolumeGroups = () => {
  const queryResult = useQuery(URL_VOLUME_GROUPS, getVolumeGroups);

  return {
    ...queryResult,
    columns: columns.map((column) => ({
      ...column,
      columnName: column.property,
      displayName: column.header,
    })),
  };
};
