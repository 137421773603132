// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { Box, Button, ResponsiveContext, Text, ThemeContext } from 'grommet';
import { Next, Previous } from 'grommet-icons';

const Indicator = styled(Box)`
  border-radius: 50%;
  height: 12px;
  margin: 0 3px;
  padding: 0;
  width: 12px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  transform: translateX(${({ translateX }) => translateX}px);
  transition: transform 300ms ease-in-out;
`;

export const HorizontalSelect = ({
  noOptionMessage = '',
  itemsPerSlide = 5, // TODO: make this dependent on responsive context
  onChange,
  options,
  value,
  ValueComponent,
  ...rest
}) => {
  const theme = useContext(ThemeContext);
  const [col, setCol] = useState(itemsPerSlide);
  const [selectedOption, setSelectedOption] = useState(value);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [itemWidth, setItemWidth] = useState(0);
  const screenSize = useContext(ResponsiveContext);
  const slide = useRef();
  const indicators = useMemo(
    () =>
      options.reduce(
        (acc, _, i) => [...acc, ...(i % col === 0 ? [acc.length] : [])],
        [],
      ),
    [col, options],
  );
  const translateX = useMemo(
    () => currentIndex * col * -itemWidth,
    [currentIndex, col, itemWidth],
  );
  const showCarouselButtons = useMemo(
    () => indicators.length > 1,
    [indicators],
  );
  const isLast = useMemo(
    () => indicators.length === currentIndex + 1,
    [currentIndex, indicators],
  );
  const isFirst = useMemo(() => currentIndex === 0, [currentIndex]);

  useEffect(() => {
    switch (screenSize) {
      case 'xsmall':
        setCol(1);
        break;
      case 'small':
        setCol(2);
        break;
      default:
        setCol(itemsPerSlide);
    }
  }, [itemsPerSlide, screenSize, setCol]);

  useEffect(() => value && setSelectedOption(value), [value]);

  useEffect(() => {
    if (slide.current) {
      const slideWidth = slide.current.offsetWidth;
      const newItemWidth = Math.ceil(slideWidth / col);
      setItemWidth(newItemWidth);
    }
  }, [col, setItemWidth, slide]);

  const onOptionClick = (option) => {
    setSelectedOption(option);
    if (onChange) {
      onChange({ value: option });
    }
  };

  const handleArrowClick = (direction) => (e) => {
    e.stopPropagation();
    e.preventDefault();

    setCurrentIndex(currentIndex + (direction === 'prev' ? -1 : 1));
  };

  const handleIndicatorClick = (index) => (e) => {
    e.stopPropagation();
    e.preventDefault();

    setCurrentIndex(index);
  };

  return noOptionMessage ? (
    <Box
      round='xsmall'
      style={{
        background: theme.dark ? '#50422f' : '#ffbc443d',
        padding: '8px 16px',
      }}
      width='large'
    >
      <Text
        style={{
          color: theme.dark ? '#ffffff' : '#9B6310',
          fontWeight: 300,
          letterSpacing: 0.5,
        }}
      >
        {noOptionMessage}
      </Text>
    </Box>
  ) : (
    <Box align='center' {...rest}>
      <Box direction='row' fill='horizontal' justify='between'>
        <Button
          disabled={isFirst}
          icon={<Previous color={isFirst ? 'disabled-text' : 'text'} />}
          onClick={handleArrowClick('prev')}
          style={{
            padding: 0,
            visibility: showCarouselButtons ? 'visible' : 'hidden',
          }}
        />
        <Box overflow='hidden' align='start' fill ref={slide}>
          <Container translateX={translateX}>
            {options.map((option, i) => (
              <Box
                key={option.id + i}
                direction='row'
                align='stretch'
                justify='stretch'
                margin='none'
                pad='none'
                style={{ width: `${itemWidth}px` }}
              >
                <ValueComponent
                  {...option}
                  option={option}
                  selected={option === selectedOption}
                  onClick={() => onOptionClick(option)}
                  onChange={onChange}
                />
              </Box>
            ))}
          </Container>
        </Box>
        <Button
          disabled={isLast}
          icon={<Next color={isLast ? 'disabled-text' : 'text'} />}
          onClick={handleArrowClick('next')}
          style={{
            padding: 0,
            visibility: showCarouselButtons ? 'visible' : 'hidden',
          }}
        />
      </Box>
      <Box direction='row' margin={{ bottom: 'small' }}>
        {showCarouselButtons &&
          indicators.map((i) => (
            <Indicator
              border={
                currentIndex === i
                  ? { color: 'text-strong' }
                  : { color: 'border' }
              }
              background={currentIndex === i ? 'text-strong' : 'background'}
              key={i}
              onClick={handleIndicatorClick(i)}
              style={{
                pointerEvents: currentIndex === i ? 'none' : 'auto',
              }}
            />
          ))}
      </Box>
    </Box>
  );
};
