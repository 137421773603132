// (C) Copyright 2016-2022, 2024 Hewlett Packard Enterprise Development LP

import * as c from '../../routes/consts.js';
import MetaBuilder from '../../containers/generic/MetaBuilder';
import ColumnLink from '../../components/Griddle/ColumnLink';
import ItemViewContainer from '../../containers/generic/ItemViewContainer';
import CreateViewContainer from '../../containers/generic/CreateViewContainer';
import { LABEL_USERS } from '../../components/HybridNav/consts.js';

const BASE_URL = c.URL_USERS;

const settings = {
  authUrl: BASE_URL, // used to filter create/trash icons from the view; using the defined roledef permissions mappings to this auth URL entry
  homeUrl: c.makeSecUrl(BASE_URL), // homepage for this list view (e.g. /s/hosters/:pid/) is where this view is located; for return from create / item views
  homeLabel: LABEL_USERS,
  baseUrl: BASE_URL, // base url to be used for creating all associated URLS for this reasource, e.g. pageItem, pageCreate, restUrl, restItemUrl
  meta: (props) => metabuilder(props),
};

function metabuilder(props) {
  const mb = new MetaBuilder(props);

  mb.addRowClick((navigate) => ({ datum: { id } }) => {
    navigate(c.setId(c.makeSecItemUrl(BASE_URL), id));
  });

  // resourcebase
  mb.addColumn('name', 'Name')
    .Custom(ColumnLink, {
      info: (rowData) => {
        const url = c.setId(c.makeSecItemUrl(BASE_URL), rowData.id);
        const { name } = rowData;
        return { url, name };
      },
    })
    .Default();

  mb.addColumn('id', 'ID');
  mb.addColumn('created', 'Created').Default();
  mb.addColumn('modified', 'Modified').Default();
  mb.addColumn('portal_id', 'Portal ID');
  mb.addColumn('hoster_id', 'Hoster ID');
  mb.addColumn('project_id', 'Project ID');

  // userinfo
  mb.addColumn('username', 'Username');
  mb.addColumn('email', 'Email').Default();
  mb.addColumn('email_verified', 'Email Verified');
  mb.addColumn('phone_number', 'Phone');
  mb.addColumn('phone_verified', 'Phone Verified');
  mb.addColumn('full_name', 'Full Name');
  mb.addColumn('nickname', 'Nickname');
  mb.addColumn('picture', 'Picture');
  mb.addColumn('blocked', 'Blocked');
  mb.addColumn('default_role', 'Default Role');
  mb.addColumn('password', 'Password');

  return mb;
}

export { default as UserListView } from './UserListView';

export const UserItemView = ItemViewContainer({
  ...settings,
  title: 'User',
});

export const UserCreateView = CreateViewContainer({
  ...settings,
  title: 'Create User',
});
