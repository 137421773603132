// (C) Copyright 2022-2024 Hewlett Packard Enterprise Development LP

import React from 'react';

import auth from '../../lib/auth';
import ListPageWithNav from '../../containers/generic/ListPageWithNav';
import { SCOPE_PROJECT, URL_IPPOOLS } from '../../routes/consts';
import { useGetIpPools } from '../../services/ipPools';
import { NAV_NETWORKING } from '../../components/HybridNav/consts.js';

const IpPoolListView = () => (
  <ListPageWithNav
    navSection={NAV_NETWORKING}
    actions={{
      allowCreate: !auth.inScope(SCOPE_PROJECT) ? 'Create IP pool' : undefined,
    }}
    path={URL_IPPOOLS}
    sort={{
      property: 'name',
      direction: 'asc',
    }}
    title='IP pools'
    useGetData={useGetIpPools}
  />
);

export default IpPoolListView;
