// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

import React from 'react';
import { Blank } from 'grommet-icons';

export const InfoSolid = (props) => (
  <Blank {...props}>
    <path
      d='M6.4 13.8C5.3 12.2 4 10.5 4 8c0-4.6 3.4-8 8-8s8 3.4 8 8c0 2.5-1.3 4.2-2.4 5.8-.9 1.1-1.6 2.1-1.6 3.2H8c0-1.1-.7-2.1-1.6-3.2zM8 19v1c0 2.5 1.5 4 4 4s4-1.5 4-4v-1H8z'
      fill='#000'
      stroke='none'
    />
  </Blank>
);
