// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

import React, { useState } from 'react';
import { URL_HOSTS } from '../../routes/consts';
import { useGetHosts } from '../../services/hosts';
import ListPageWithNav from '../../containers/generic/ListPageWithNav';
import { AddHost } from '../../containers/hosts/AddHost';
import { NAV_HOSTS } from '../../components/HybridNav/consts.js';

const HostListView = () => {
  const [showCreate, setShowCreate] = useState(false);
  const handleHide = () => setShowCreate(false);
  const allowCreateCb = () => setShowCreate(true);

  return (
    <>
      <ListPageWithNav
        navSection={NAV_HOSTS}
        actions={{
          allowCreate: 'Create host',
          allowCreateCb,
          allowPause: true,
          poll: true,
        }}
        path={URL_HOSTS}
        sort={{
          property: 'name',
          direction: 'asc',
        }}
        title='Hosts'
        useGetData={useGetHosts}
        limitRecords={true}
      />
      {showCreate && <AddHost onHide={handleHide} />}
    </>
  );
};

export default HostListView;
