// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

import React from 'react';

import ListPageWithNav from '../../containers/generic/ListPageWithNav';
import { URL_RELEASES } from '../../routes/consts';
import { useGetReleases } from '../../services/releases';
import { useMembershipContext } from '../../utils/context/membershipContext';
import {
  NAV_FOR_HOSTERS,
  NAV_RACKS,
} from '../../components/HybridNav/consts.js';

const ReleaseListView = () => {
  const { activeMembership } = useMembershipContext();
  const navSection =
    activeMembership?.scope === 'portal' ? NAV_FOR_HOSTERS : NAV_RACKS;

  return (
    <ListPageWithNav
      navSection={navSection}
      actions={{}}
      path={URL_RELEASES}
      sort={{
        property: 'name',
        direction: 'asc',
      }}
      title='Releases'
      useGetData={useGetReleases}
    />
  );
};

export default ReleaseListView;
