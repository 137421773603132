// (C) Copyright 2020-2024 Hewlett Packard Enterprise Development LP

import React, { useEffect, useState } from 'react';
import { Avatar, Box, Menu, Text } from 'grommet';
import { User as UserIcon } from 'grommet-icons';
import auth from '../lib/auth';

const User = () => {
  const [nickname, setNickname] = useState('');
  const [picture, setPicture] = useState('');
  const [preferredUsername, setPreferredUsername] = useState('');

  useEffect(() => {
    (async () => {
      const userProfile = await auth.getUserProfile();

      if (userProfile) {
        setNickname(userProfile.nickname);
        setPicture(userProfile.picture);
        setPreferredUsername(userProfile.preferredUsername);
      }
    })();
  }, []);

  const menuItems = [
    {
      label: nickname || preferredUsername,
      disabled: true,
    },
    {
      label: 'Logout',
      onClick: () => auth.logout(),
    },
  ];

  return preferredUsername || nickname ? (
    <Box flex={false}>
      <Menu
        icon={false}
        pad={{ horizontal: '6px' }}
        items={menuItems.map(({ label, onClick }) => ({
          label: <Text size='small'>{label}</Text>,
          onClick,
        }))}
        label={
          nickname ? (
            <Avatar size='small' src={picture} flex={false} />
          ) : (
            <Avatar size='small' background='dark-6'>
              <UserIcon color='light-3' />
            </Avatar>
          )
        }
      />
    </Box>
  ) : (
    <Box flex={false} />
  );
};

export default User;
