// (C) Copyright 2020-2024 Hewlett Packard Enterprise Development LP

import React, { useState } from 'react';
import isArray from 'lodash/isArray';
import { Box, Select, Text } from 'grommet';
import { Close } from 'grommet-icons';
import IconButton from './generic/IconButton';

const getValues = (value) => {
  let content = '';
  if (value) {
    content = isArray(value)
      ? value.map(({ label }) => label).join(', ')
      : value.label;
  }
  return content;
};

const SearchableSelect = ({
  options,
  value,
  placeholder,
  onChange,
  ...rest
}) => {
  const [searchText, setSearchText] = useState('');
  let visibleOptions = options;

  if (searchText) {
    // The line below escapes regular expression special characters:
    // [ \ ^ $ . | ? * + ( )
    const escapedText = searchText.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');

    // Create the regular expression with modified value which
    // handles escaping special characters. Without escaping special
    // characters, errors will appear in the console
    const exp = new RegExp(escapedText, 'i');
    visibleOptions = options.filter(({ label }) => exp.test(label));
  }
  const valueText = getValues(value);

  return (
    <Select
      value={value}
      placeholder={placeholder}
      options={visibleOptions}
      labelKey='label'
      valueKey='value'
      disabledKey='disabled'
      onChange={onChange}
      onClose={() => setSearchText('')}
      onSearch={(text) => setSearchText(text)}
      valueLabel={
        <Box
          direction='row'
          gap='xsmall'
          pad={{ left: 'small', vertical: 'small' }}
        >
          <Text color={valueText ? 'text' : '#bbbbbb'}>
            {valueText || placeholder}
          </Text>
          {valueText && (
            <IconButton
              icon={<Close />}
              tip='Close'
              onClick={(event) => {
                event.stopPropagation();
                onChange({ value: { value: '' } });
              }}
            />
          )}
        </Box>
      }
      {...rest}
    />
  );
};

export default SearchableSelect;
