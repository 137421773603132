// (C) Copyright 2016-2024 Hewlett Packard Enterprise Development LP

import auth from '../../lib/auth';
import * as c from '../../routes/consts.js';
import MetaBuilder from '../../containers/generic/MetaBuilder';
import * as mtb from '../../containers/generic/MetaBuilder';
import ItemViewContainer from '../../containers/generic/ItemViewContainer';
import CreateViewContainer from '../../containers/generic/CreateViewContainer';
import { toDateTime } from '../../lib/formatters';
import debugLogger from '../../lib/debug';
import * as log from '../../lib/debug';
import { LABEL_SWITCHTYPES } from '../../components/HybridNav/consts.js';

const debug = debugLogger('SwitchType', log.LOG_LEVEL_WARN);

const EXTERNAL_NAME = c.URL_SWITCHTYPES;

const settings = {
  authUrl: EXTERNAL_NAME, // used to filter create/trash icons from the view; using the defined roledef permissions mappings to this auth URL entry
  homeUrl: c.makeSecUrl(EXTERNAL_NAME), // homepage for this list view (e.g. /s/hosters/:pid/) is where this view is located; for return from create / item views
  baseUrl: EXTERNAL_NAME, // base url to be used for creating all associated URLS for this reasource, e.g. pageItem, pageCreate, restUrl, restItemUrl
  stateKey: EXTERNAL_NAME,
  meta: (props) => metaItem(props),
  homeLabel: LABEL_SWITCHTYPES,
};

function metaItem(props) {
  const mb = new MetaBuilder(props);

  // setup base data source
  const id = mb.view.prop('itemId', false);
  const ds = mb
    .newDataSource(c.URL_SWITCHTYPES)
    .Item(id)
    .OnLoad((json) => {
      debug.debug('got switchtype: ', json);
      mb.view.initForm(json.data);
    });

  mb.addSection('1').NoChrome().MaxWidth(mtb.LARGE);

  mb.addColumn('name', 'Name').Input().Required();

  mb.addColumn('description', 'Description');
  mb.addColumn('sw_family', 'Switch family');
  mb.addColumn('id', 'ID');

  mb.addColumn('created', 'Created').FieldXform((created) =>
    created ? toDateTime(created) : '--',
  );
  mb.addColumn('modified', 'Modified').FieldXform((modified) =>
    modified ? toDateTime(modified) : '--',
  );

  mb.addColumn('manufacturer', 'Manufacturer');
  mb.addColumn('part_numbers', 'Part numbers');

  mb.addColumn('rack_controller', 'Rack controller').FieldXform((val) =>
    val ? 'embedded / external' : 'external',
  );

  if (auth.inScope(c.SCOPE_PORTAL)) {
    mb.addColumn('hoster_published', 'Published').FieldXform(() =>
      mb.view.formDefaultTo('hoster_id') === '*' ? 'yes' : 'no',
    );
  }

  //-----------------------------------------------------------------
  mb.addSection('Ports')
    .MaxWidth(mtb.LARGE)
    .OverviewText('Switch port descriptions');

  const portTable = mb
    .addInputTable('ports', 'Ports')
    .DataXform(ds, (json) => json.ports || []);

  portTable.addField('name', 'Name').CellXform((rowData) => rowData.name);

  // DEPRECATED
  // portTable.addField('speeds', 'Speeds')
  //  .CellXform( (rowData) => rowData.speeds )

  portTable
    .addField('pluggable', 'Pluggable')
    .CellXform((rowData) =>
      rowData.pluggable === undefined ? ' ' : rowData.pluggable ? 'yes' : 'no',
    );

  portTable
    .addField('splittable', 'Splittable')
    .CellXform((rowData) =>
      rowData.splittable === undefined
        ? ' '
        : rowData.splittable
          ? 'yes'
          : 'no',
    );

  portTable
    .addField('port_speeds', 'Port speeds')
    .CellXform((rowData) =>
      rowData.port_speeds === undefined ? ' ' : rowData.port_speeds,
    );

  portTable
    .addField('children', 'Children')
    .CellXform((rowData) =>
      rowData.children === undefined ? ' ' : rowData.children,
    );

  portTable
    .addField('parent', 'Parent')
    .CellXform((rowData) =>
      rowData.parent === undefined ? ' ' : rowData.parent,
    );

  return mb;
}

export { SwitchTypeListView } from './SwitchTypeListView';

export const SwitchTypeItemView = ItemViewContainer({
  ...settings,
  title: 'Switch type',
});

export const SwitchTypeCreateView = CreateViewContainer({
  ...settings,
  title: 'Create switch type',
});
