// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

export const urlText = ({ display_url, secure_url }) => {
  const securePlaceholder = '*****************';
  switch (true) {
    case Boolean(display_url):
      return display_url;
    case Boolean(!display_url && secure_url === securePlaceholder):
      return '[Secure URL has been defined, but Display URL is missing.]';
    default:
      return null;
  }
};
