// (C) Copyright 2022, 2024 Hewlett Packard Enterprise Development LP

import { useQuery } from 'react-query';
import { Edit } from 'grommet-icons';

import auth from '../lib/auth';
import { get } from '../lib/rest';
import { toDateTime } from '../lib/formatters';
import { makeRestUrl, URL_FWBASELINES } from '../routes/consts';
import { renderLink } from './helper';
import TruncateText from '../components/Griddle/TruncateText';

const columns = (role = '') => [
  {
    header: 'Name',
    isDefault: true,
    property: 'name',
    render: renderLink({
      base: URL_FWBASELINES,
    }),
  },
  {
    header: 'Description',
    isDefault: true,
    property: 'description',
  },
  {
    header: 'ID',
    property: 'id',
    render: ({ id }) => <TruncateText content={id} />,
  },
  {
    header: 'Created',
    property: 'created',
    render: ({ created }) => <TruncateText content={toDateTime(created)} />,
  },
  {
    header: 'Modified',
    isDefault: true,
    property: 'modified',
    render: ({ modified }) => <TruncateText content={toDateTime(modified)} />,
  },
  {
    header: 'Version',
    isDefault: true,
    property: 'version',
  },
  {
    header: 'Manufacturer',
    property: 'manufacturer',
  },
  ...(role.includes('_viewer')
    ? []
    : [
        {
          header: 'Edit',
          isDefault: true,
          property: 'edit',
          render: renderLink({
            base: URL_FWBASELINES,
            icon: <Edit color='brand' />,
            isEdit: true,
          }),
        },
      ]),
];

export const getFWBaselines = async () => {
  const response = await get(makeRestUrl(URL_FWBASELINES));

  return response.json();
};

export const useGetFWBaselines = () => {
  const queryResult = useQuery(URL_FWBASELINES, getFWBaselines);

  return {
    ...queryResult,
    columns: columns(auth.activeRoleDef?.id).map((column) => ({
      ...column,
      columnName: column.property,
      displayName: column.header,
    })),
  };
};
