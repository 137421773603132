// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  CheckBox,
  Footer,
  Form,
  FormField,
  Heading,
  Layer,
  RadioButtonGroup,
  Select,
  Text,
  TextInput,
} from 'grommet';
import { FormAdd, Trash } from 'grommet-icons';
import styled from 'styled-components';
import { MultiSelect } from '../../../components/host/MultiSelect';
import { isEmpty } from '../../../utils';
import { MAC } from '../../../data/regex';

const REQUIRED = 'Required field';
const INVALID_MAC = 'Invalid MAC address';

const initialFormValue = {
  description: '',
  lacpInterval: '',
  lacpMode: '',
  lacpPriority: 0,
  name: '',
  networks: [{ networkId: '', macs: [], untagged: false }],
  ports: [],
  rack: '',
  speed: '',
  type: 'none',
};

const protocolOptions = [
  { label: 'Failover', value: 'none' },
  { label: 'Static LAG', value: 'static' },
  { label: 'Dynamic LAG', value: 'lacp' },
];

const lacpModeOptions = [
  { label: 'Active', value: 'active' },
  { label: 'Passive', value: 'passive' },
];

const lacpIntervalOptions = [
  { label: 'Long', value: 'long' },
  { label: 'Short', value: 'short' },
];

const Field = styled(FormField)`
  div[role='radiogroup'] {
    margin: 0;

    label {
      padding: 0;

      > div {
        align-items: center;
        display: flex;
        padding: 0;

        input + div {
          height: 36px;
          padding: 6px 16px;
        }
      }
    }
  }
`;

const RadioField = ({ label, name, options, required, value }) => (
  <Field alignSelf='start' label={label} name={name} required={required}>
    <RadioButtonGroup
      direction='row'
      gap='none'
      name={name}
      options={options}
      value={value}
    >
      {(option, { checked, focus, hover }) => {
        let background;
        if (checked) background = '#eee';
        else if (hover) background = 'light-4';
        else if (focus) background = 'light-4';
        else background = 'transparent';

        return <Box background={background}>{option.label}</Box>;
      }}
    </RadioButtonGroup>
  </Field>
);

RadioField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.array,
  required: PropTypes.bool,
  value: PropTypes.string,
};

export const AddCustomNetworkConnectionsPanel = ({
  customConnections,
  editIndex,
  networks,
  onClose,
  onSubmit,
  pod,
  racks,
  switches,
  switchTypes,
}) => {
  const [form, setForm] = useState(initialFormValue);
  const [errors, setErrors] = useState({});

  const editForm = useMemo(
    () => (editIndex ? customConnections[editIndex - 1] : null),
    [customConnections, editIndex],
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (editForm) {
      const {
        lag: {
          lacp_interval: lacpInterval,
          lacp_mode: lacpMode,
          lacp_priority: lacpPriority,
          type,
        },
        networks: editFormNetworks,
        ports: editFormPorts,
        rack_id: rack,
        ...rest
      } = editForm;

      const initForm = {
        ...rest,
        lacpInterval,
        lacpMode,
        lacpPriority: lacpPriority.toString(),
        networks: editFormNetworks.map(
          ({ network_id: networkId, provider_macs: macs = [], untagged }) => ({
            macs,
            networkId,
            untagged,
          }),
        ),
        ports: editFormPorts.reduce(
          (acc, { port_names: portNames, switch_id: switchId }) => {
            const { name, type_id: typeId } = switches.find(
              ({ id }) => id === switchId,
            );

            return [
              // biome-ignore lint/performance/noAccumulatingSpread: <explanation>
              ...acc,
              ...portNames.map((value) => ({
                id: switchId,
                label: `${name}:${value}`,
                value: `${switchId}_${value}`,
                typeId,
              })),
            ];
          },
          [],
        ),
        rack,
        type,
      };

      setForm(initForm);
    }
  }, [editForm]);

  useEffect(() => {
    if (editForm && form.rack && editForm.rack_id !== form.rack) {
      setForm((prev) => ({
        ...prev,
        ports: [],
      }));
    }
  }, [editForm, form.rack]);

  const networkOptions = useMemo(
    () =>
      networks
        .filter(({ location_id: locationId }) => locationId === pod.id)
        .map(({ id, network_name: name }) => ({
          disabled: form.networks.some(({ networkId }) => networkId === id),
          label: name,
          value: id,
        })),
    [networks, form.networks, pod],
  );

  const rackOptions = useMemo(
    () =>
      racks
        .filter(
          ({ switches: rSwitches, template_id: templateId }) =>
            rSwitches &&
            !!rSwitches.length &&
            pod.rack_templates &&
            pod.rack_templates.includes(templateId),
        )
        .map(({ id, name }) => ({ label: name, value: id })),
    [pod, racks],
  );

  const usedPorts = useMemo(() => {
    const rack = racks.find(({ id }) => id === form.rack);

    if (!rack) {
      return [];
    }

    const {
      template_config: {
        switch_sets: [
          {
            port_usage2: {
              server_ports: [{ ports }],
            },
          },
        ],
      },
    } = rack;

    return ports;
  }, [form.rack, racks]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const availableSwitches = useMemo(() => {
    if (isEmpty(racks) || isEmpty(form.rack)) {
      return [];
    }

    const switchIds = racks
      .find((rack) => rack.id === form.rack)
      .switches.map(({ id }) => id);

    return switches
      .filter(({ id, type_id: typeId }) => switchIds.includes(id) && !!typeId)
      .map(({ id, name, type_id: typeId }) => ({
        switchId: id,
        switchName: name,
        typeId,
      }));
  }, [form.rack, racks]);

  const allPorts = useMemo(
    () =>
      switchTypes
        .filter(({ id }) =>
          availableSwitches.some(({ typeId }) => typeId === id),
        )
        .reduce(
          (acc, cur) => ({
            ...acc,
            [cur.id]: cur.ports.map((port) => ({
              name: port.name,
              switchTypeName: cur.name,
              port_speeds: port.port_speeds,
            })),
          }),
          {},
        ),
    [availableSwitches, switchTypes],
  );

  const otherConnectionPorts = useMemo(() => {
    const otherConnections = [...customConnections];

    if (editIndex) {
      otherConnections.splice(editIndex - 1, 1);
    }

    return otherConnections
      .reduce((acc, { ports }) => [...acc, ...ports], [])
      .reduce(
        (acc, { switch_id: switchId, port_names: portNames }) => ({
          ...acc,
          [switchId]: acc[switchId]
            ? [
                ...acc[switchId],
                ...portNames.filter(
                  (portName) => !acc[switchId].includes(portName),
                ),
              ]
            : portNames,
        }),
        {},
      );
  }, [customConnections, editIndex]);

  const filterPort = useCallback(
    (used, name) =>
      used.every((usedPort) => {
        const [usedParentPort, usedChildPort] = usedPort.split(':');
        const [parentPort] = name.split(':');

        if (usedChildPort) {
          return usedPort !== name && usedParentPort !== name;
        }

        return usedParentPort !== parentPort;
      }),
    [],
  );

  const availablePorts = useMemo(
    () =>
      Object.keys(allPorts).reduce(
        (acc, switchTypeId) => ({
          ...acc,
          [switchTypeId]: allPorts[switchTypeId].filter(({ name }) =>
            filterPort(usedPorts, name),
          ),
        }),
        {},
      ),
    [allPorts, filterPort, usedPorts],
  );

  const usedPortValues = useMemo(
    () => [
      ...form.ports.map(({ value }) => value),
      ...Object.keys(otherConnectionPorts).reduce(
        (acc, switchId) => [
          ...acc,
          ...otherConnectionPorts[switchId].map(
            (port) => `${switchId}_${port}`,
          ),
        ],
        [],
      ),
    ],
    [form.ports, otherConnectionPorts],
  );

  const switchPortOptions = useMemo(() => {
    if (!form.rack) {
      return [];
    }

    return availableSwitches.reduce(
      (acc, { switchId: id, switchName, typeId }) => {
        const ports = availablePorts[typeId]
          ? availablePorts[typeId]
              // filter out parent/children ports
              .filter(({ name }) => {
                if (isEmpty(usedPortValues)) {
                  return true;
                }

                return usedPortValues.every((value) => {
                  const [switchId, port] = value.split('_');
                  const [parentPort, childPort] = port.split(':');

                  if (switchId !== id) {
                    return true;
                  }

                  if (!childPort) {
                    return !name.startsWith(parentPort);
                  }

                  return name !== parentPort && name !== port;
                });
              })
              .map(({ name: portName }) => ({
                value: `${id}_${portName}`,
                label: `${switchName}:${portName}`,
                id,
                typeId,
              }))
          : [];

        return [...acc, ...ports];
      },
      [],
    );
  }, [availablePorts, availableSwitches, form.rack, usedPortValues]);

  useEffect(() => {
    const noPorts = Object.keys(allPorts).every((id) => isEmpty(allPorts[id]));

    const noAvailablePorts = Object.keys(availablePorts).every((id) =>
      isEmpty(availablePorts[id]),
    );

    if (
      form.rack &&
      !errors.rack &&
      (isEmpty(availableSwitches) || noPorts || noAvailablePorts)
    ) {
      setErrors({
        rack: 'No ports available. Please select other rack.',
      });
    } else if (errors.rack) {
      setErrors({
        rack: '',
      });
    }
  }, [allPorts, availablePorts, availableSwitches, form.rack]);

  const speedOptions = useMemo(() => {
    if (isEmpty(form.ports) || isEmpty(availablePorts)) {
      return [];
    }

    const allSpeedOptions = form.ports.reduce((acc, { typeId, value }) => {
      const portSpeeds = allPorts[typeId];
      const selectedPortSpeed = portSpeeds.find(
        ({ name }) => name === value.split('_')[1],
      ).port_speeds;

      return [...acc, selectedPortSpeed];
    }, []);

    const commonSpeedOptions = allSpeedOptions.reduce(
      (acc, cur) => [
        ...acc,
        ...cur
          .filter((speed) => !acc.includes(speed))
          .filter((speed) =>
            allSpeedOptions.every((options) => options.includes(speed)),
          ),
      ],
      [],
    );

    return commonSpeedOptions.map((speed) => ({
      label: speed,
      value: speed,
    }));
  }, [availablePorts, form.ports]);

  useEffect(() => {
    if (!isEmpty(form.ports) && isEmpty(speedOptions)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        speed: 'No common speed is available. Please change Switch Ports.',
      }));
    } else if (errors.speed) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        speed: '',
      }));
    }
  }, [form.ports, speedOptions, errors.speed]);

  const isProviderNetwork = useCallback(
    (networkId) =>
      networks &&
      networks.length &&
      networks.find(({ id }) => id === networkId)?.subtype ===
        'service-provider',
    [networks],
  );

  const handleMacChange =
    (index, subIndex) =>
    ({ target }) => {
      setForm((prev) => ({
        ...prev,
        networks: [
          ...prev.networks.slice(0, index),
          {
            ...prev.networks[index],
            macs: [
              ...prev.networks[index].macs.slice(0, subIndex),
              target.value,
              ...prev.networks[index].macs.slice(subIndex + 1),
            ],
          },
          ...prev.networks.slice(index + 1),
        ],
      }));
    };

  const handleDelete = (index) => () => {
    setForm((prev) => ({
      ...prev,
      networks: [
        ...prev.networks.slice(0, index),
        ...prev.networks.slice(index + 1),
      ],
    }));
  };

  const handleChange = (nextForm) => {
    setForm(nextForm);
  };

  const handleNetworkChange =
    (index) =>
    ({ target }) => {
      setForm((prev) => ({
        ...prev,
        networks: [
          ...prev.networks.slice(0, index),
          {
            ...prev.networks[index],
            networkId: target.value,
          },
          ...prev.networks.slice(index + 1),
        ],
      }));
    };

  const handleTaggedChange =
    (index) =>
    ({ target }) => {
      const untaggedNetworkIndex = form.networks.findIndex(
        ({ untagged }) => untagged,
      );

      const hasUntaggedNetwork =
        untaggedNetworkIndex > -1 && untaggedNetworkIndex !== index;

      if (hasUntaggedNetwork) {
        return;
      }

      setForm((prev) => ({
        ...prev,
        networks: [
          ...prev.networks.slice(0, index),
          {
            ...prev.networks[index],
            untagged: target.checked,
          },
          ...prev.networks.slice(index + 1),
        ],
      }));
    };

  const handleAddNetwork = () => {
    setForm((prev) => ({
      ...prev,
      networks: [
        ...prev.networks,
        {
          networkId: '',
          untagged: false,
          macs: [],
        },
      ],
    }));
  };

  const validateForm = ({
    description,
    name,
    networks: formNetworks,
    ports: formPorts,
    rack,
    type,
  }) => {
    const formError = {
      name: name ? '' : REQUIRED,
      description: description ? '' : REQUIRED,
      ports: formPorts.length ? '' : REQUIRED,
      rack: rack ? '' : REQUIRED,
      networks: formNetworks.map(({ macs, networkId }) => {
        const macFieldsLength = type !== 'none' ? 1 : formPorts.length;
        const macFields = macs.slice(0);

        for (let i = 0; i < macFieldsLength; i += 1) {
          if (!macFields[i]) {
            macFields[i] = '';
          }
        }

        return {
          network: networkId ? '' : REQUIRED,
          macs: macFields.map((mac) => {
            if (mac && !MAC.test(mac)) {
              return INVALID_MAC;
            }

            if (!mac && macFields.some((m) => m && MAC.test(m))) {
              return REQUIRED;
            }

            return '';
          }),
        };
      }),
    };

    setErrors(formError);

    const isValid = Object.keys(formError).every((key) =>
      key === 'networks'
        ? formError[key].every(
            ({ network, macs }) => !network && macs.every((mac) => !mac),
          )
        : !formError[key],
    );

    return isValid;
  };

  const handleSubmit = ({ value: formValue }) => {
    if (!validateForm(formValue)) {
      return;
    }

    const {
      description,
      lacpInterval,
      lacpMode,
      lacpPriority,
      name,
      networks: formNetworks,
      ports: formPorts,
      rack,
      speed,
      type,
    } = formValue;
    const portsMap = formPorts.reduce(
      (acc, { id, value }) => ({
        ...acc,
        [id]: [...(acc[id] ? acc[id] : []), value.split('_')[1]],
      }),
      {},
    );

    const ports = Object.keys(portsMap).map((switchId) => ({
      switch_id: switchId,
      port_names: portsMap[switchId],
    }));

    const payload = {
      description,
      lag: {
        type,
        lacp_mode: type === 'lacp' ? lacpMode : '',
        lacp_interval: type === 'lacp' ? lacpInterval : '',
        lacp_priority: Number(lacpPriority),
      },
      mode: 'L2', // only L2 for now
      name,
      networks: formNetworks.map(({ networkId, macs, untagged }) => ({
        provider_macs: isProviderNetwork(networkId)
          ? macs.slice(0, formPorts.length)
          : [],
        network_id: networkId,
        untagged,
      })),
      ports,
      rack_id: rack,
      speed,
    };

    onSubmit(payload);
  };

  return (
    <Layer
      margin={{
        left: '192px',
        top: '75px',
      }}
      modal={false}
      position='right'
      full
      animate={false}
      onEsc={onClose}
    >
      <Box overflow='auto' width='large'>
        <Form value={form} onSubmit={handleSubmit} onChange={handleChange}>
          <Box gap='medium' pad='medium'>
            <Box>
              <FormField
                component={TextInput}
                error={errors.name}
                label='Name'
                name='name'
                placeholder='Custom network name'
              />
              <FormField
                component={TextInput}
                error={errors.description}
                label='Description'
                name='description'
                placeholder='Short description'
              />
            </Box>

            <Box>
              <Heading level={4} size='small'>
                Interfaces
              </Heading>
              <Text size='small'>Defines switch ports</Text>
              <FormField
                component={Select}
                error={errors.rack}
                htmlFor='rack'
                label='Rack'
                labelKey='label'
                name='rack'
                options={rackOptions}
                valueKey={{ key: 'value', reduce: true }}
              />
              <FormField
                component={MultiSelect}
                error={errors.ports || errors.speed}
                htmlFor='ports'
                label='Switch Ports'
                labelKey='label'
                name='ports'
                options={switchPortOptions}
                placeholder='Select switch ports'
                valueKey='value'
              />
              {speedOptions.length ? (
                <RadioField
                  label='Speed'
                  name='speed'
                  options={speedOptions}
                  value={form.speed || speedOptions[0].value}
                />
              ) : null}
              <RadioField
                label='Protocol'
                name='type'
                options={protocolOptions}
              />
              {form.type === 'lacp' ? (
                <>
                  <RadioField
                    label='LACP mode'
                    name='lacpMode'
                    options={lacpModeOptions}
                  />
                  <RadioField
                    label='LACP interval'
                    name='lacpInterval'
                    options={lacpIntervalOptions}
                  />
                  <FormField
                    component={TextInput}
                    label='LACP priority'
                    min='1'
                    max='32768'
                    name='lacpPriority'
                    placeholder='1 - 32768'
                    style={{ maxWidth: 140 }}
                    type='number'
                  />
                </>
              ) : null}
            </Box>
            <Box>
              <Heading level={4} size='small'>
                Networks
              </Heading>
              <Text size='small'>Select networks for this connection</Text>
              <Box margin={{ top: 'small' }}>
                <Box
                  border={{
                    side: 'bottom',
                    style: 'solid',
                    color: 'border',
                  }}
                  direction='row'
                  gap='small'
                  justify='start'
                >
                  <Box width='medium'>
                    <Text weight='bold'>Network</Text>
                  </Box>
                  <Box width='small'>
                    <Text weight='bold'>MAC address</Text>
                  </Box>
                  <Box align='center' width='xsmall'>
                    <Text weight='bold'>Untagged</Text>
                  </Box>
                  <Box pad={{ vertical: 'small', horizontal: 'none' }} />
                </Box>
                <Box pad={{ vertical: 'small' }}>
                  {!!form.networks.length &&
                    form.networks.map(({ networkId, macs, untagged }, i) => (
                      <Box
                        direction='row'
                        gap='small'
                        justify='start'
                        key={networkId || i}
                        margin={{ vertical: 'xsmall' }}
                      >
                        <Box width='medium'>
                          <FormField
                            component={Select}
                            disabledKey='disabled'
                            error={errors.networks?.[i]?.network}
                            labelKey='label'
                            onChange={handleNetworkChange(i)}
                            options={networkOptions}
                            value={networkId}
                            valueKey={{ key: 'value', reduce: true }}
                          />
                        </Box>
                        <Box width='small'>
                          {isProviderNetwork(networkId) &&
                            (form.type !== 'none' ? [0] : form.ports).map(
                              (_, j) => (
                                <FormField
                                  component={TextInput}
                                  error={errors.networks?.[i]?.macs?.[j]}
                                  key={j}
                                  onChange={handleMacChange(i, j)}
                                  placeholder='XX:XX:XX:XX:XX:XX'
                                  value={macs[j] || ''}
                                />
                              )
                            )}
                        </Box>
                        <Box align='center' pad='xsmall' width='xsmall'>
                          <CheckBox
                            checked={untagged}
                            onChange={handleTaggedChange(i)}
                          />
                        </Box>
                        <Box align='center' pad='xsmall'>
                          <Button
                            icon={<Trash color='brand' />}
                            onClick={handleDelete(i)}
                          />
                        </Box>
                      </Box>
                    ))}
                  <Box direction='row' pad={{ vertical: 'small' }}>
                    <Button
                      icon={<FormAdd />}
                      label='Add network'
                      onClick={handleAddNetwork}
                      reverse
                      secondary
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
            <Footer
              align='start'
              gap='small'
              justify='start'
              pad={{ top: 'medium' }}
            >
              <Button type='submit' primary label='OK' />
              <Button
                type='button'
                secondary
                label='Cancel'
                onClick={onClose}
              />
            </Footer>
          </Box>
        </Form>
      </Box>
    </Layer>
  );
};

AddCustomNetworkConnectionsPanel.propTypes = {
  customConnections: PropTypes.array,
  editIndex: PropTypes.number,
  networks: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  pod: PropTypes.object.isRequired,
  racks: PropTypes.array.isRequired,
  switches: PropTypes.array.isRequired,
  switchTypes: PropTypes.array.isRequired,
};
