// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

import { useQueries, useQuery } from 'react-query';

import { get } from '../lib/rest';
import * as c from '../routes/consts';
import { getDefaultQueriesResponse, renderLink } from './helper';
import { getOrganizations } from './organizations';
import auth from '../lib/auth';
import { Edit } from 'grommet-icons';
import { toDateTime } from '../lib/formatters';

const columns = ({ organizations }) => [
  {
    header: 'Name',
    isDefault: true,
    property: 'name',
    render: renderLink({
      base: c.URL_PROJECTS,
    }),
  },
  {
    header: 'ID',
    property: 'id',
  },
  {
    header: 'Organization',
    isDefault: true,
    property: 'organization_id',
    render: renderLink({
      base: c.URL_ORGANIZATIONS,
      foreignData: organizations,
      foreignKey: 'organization_id',
    }),
  },
  {
    header: 'Created',
    isDefault: true,
    property: 'created',
    render: ({ created }) => toDateTime(created),
  },
  {
    header: 'Modified',
    isDefault: true,
    property: 'modified',
    render: ({ modified }) => toDateTime(modified),
  },
  {
    header: 'Project name',
    isDefault: true,
    property: 'profile.team_name',
  },
  {
    header: 'Project description',
    property: 'profile.team_desc',
  },
  {
    header: 'Company',
    property: 'profile.company',
  },
  {
    header: 'Address',
    property: 'profile.address',
  },
  {
    header: 'Email',
    property: 'profile.email',
  },
  {
    header: 'Phone',
    property: 'profile.phone_number',
  },
  {
    header: 'Base address',
    property: 'private_ip_pool_factory.base',
  },
  {
    header: 'Pool size',
    property: 'private_ip_pool_factory.base_cidr',
  },
  {
    header: 'Sub-Pool size',
    property: 'private_ip_pool_factory.ip_pool_cidr',
  },
  {
    header: 'Max hosts',
    isDefault: true,
    property: 'limits.hosts',
  },
  {
    header: 'Max private networks',
    isDefault: true,
    property: 'limits.private_networks',
  },
  {
    header: 'Max volumes',
    isDefault: true,
    property: 'limits.volumes',
  },
  {
    header: 'Max storage capacity (TiB)',
    isDefault: true,
    property: 'limits.volume_capacity',
  },
  {
    header: 'High availability storage',
    property: 'replication_enabled',
    render: ({ replication_enabled }) => (replication_enabled ? 'Yes' : 'No'),
  },
  ...(auth.inScope(c.SCOPE_HOSTER)
    ? [
        {
          header: 'Edit',
          isDefault: true,
          property: 'edit',
          render: renderLink({
            base: c.URL_PROJECTS,
            icon: <Edit color='brand' />,
            isEdit: true,
          }),
        },
      ]
    : []),
];

export const getProjects = async () => {
  if (!auth.isIAM()) {
    const response = await get(c.makeRestUrl(c.URL_PROJECTS));

    return response.json();
  }
};

export const getProjectResources = async () => {
  const response = await get(c.makeRestUrl(c.URL_AVAIL_PROJECT_RESOURCES));

  return response.json();
};

export const useGetProjects = () => {
  const queryResults = useQueries([
    {
      queryKey: c.URL_PROJECTS,
      queryFn: getProjects,
    },
    {
      queryKey: c.URL_ORGANIZATIONS,
      queryFn: getOrganizations,
    },
  ]);

  const isLoaded = queryResults.every(
    ({ error, isLoading }) => !isLoading && !error,
  );

  if (isLoaded) {
    const [projects, organizations] = queryResults;

    return {
      columns: columns({
        organizations: organizations?.data || [],
      })
        // adding columnName and displayName for backwards compatibility
        .map((column) => ({
          ...column,
          columnName: column.property,
          displayName: column.header,
        })),
      data: projects.data,
      isLoading: false,
      refetch: () => {
        projects.refetch();
        organizations.refetch();
      },
      error: null,
    };
  }

  return getDefaultQueriesResponse(queryResults);
};

export const useGetProjectResources = () => {
  const queryResult = useQuery(
    c.URL_AVAIL_PROJECT_RESOURCES,
    getProjectResources,
  );

  return queryResult;
};
