// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

import React, { useEffect, useMemo, useState } from 'react';
import { Box, Button, FormField, Select, Text, TextInput } from 'grommet';
import { FormAdd, Trash } from 'grommet-icons';

const defaultSubattribute = {
  attribute: '',
  operator: '',
  value: '',
};

const FormInlineSubattributes = ({ col, data, validationResult = [] }) => {
  const [attributeOptions, setAttributeOptions] = useState([]);
  const [operatorOptions, setOperatorOptions] = useState([]);
  const [subattributes, setSubattributes] = useState(
    data.subattribute_rules && data.subattribute_rules.length
      ? data.subattribute_rules
      : [defaultSubattribute],
  );
  const [dirty, setDirty] = useState({});

  const ruleAttributes = useMemo(
    () => col?.customComponentMetadata?.attributes.data.rule_attributes,
    [col],
  );

  const operators = useMemo(() => {
    const prop = col?.customComponentMetadata?.operators;
    return prop.data || prop;
  }, [col]);

  useEffect(() => {
    subattributes.forEach((_, i) =>
      setDirty((prevDirty) => ({
        ...prevDirty,
        [i]: true,
      })),
    );
  }, []);

  useEffect(() => {
    const attributeData = ruleAttributes.find(
      ({ name }) => name === data.attribute,
    );
    const options = attributeData.subattributes.map(({ name }) => name);
    setAttributeOptions(options);
    setOperatorOptions(operators);
  }, [data, operators, ruleAttributes]);

  useEffect(() => {
    col.mb.view.setFormValue('subattribute_rules', subattributes);
  }, [col, subattributes]);

  const addSubattribute = () => {
    setSubattributes((currentSubAttributes) => [
      ...currentSubAttributes,
      defaultSubattribute,
    ]);
  };

  const handleInputChange =
    (index, key) =>
    ({ option, target }) => {
      if (!dirty[index] && dirty[index] && !dirty[index][key]) {
        setDirty((prevDirty) => ({
          ...prevDirty,
          [index]: {
            ...prevDirty[index],
            [key]: true,
          },
        }));
      }

      const inputValue = option || target.value;

      setSubattributes((currentSubAttributes) => [
        ...currentSubAttributes.slice(0, index),
        {
          ...currentSubAttributes[index],
          [key]: inputValue,
        },
        ...currentSubAttributes.slice(index + 1),
      ]);
    };

  const handleDelete = (index) => (e) => {
    e.preventDefault();

    setSubattributes((currentSubAttributes) => [
      ...currentSubAttributes.slice(0, index),
      ...currentSubAttributes.slice(index + 1),
    ]);
  };

  return (
    <Box style={{ gridColumn: '1 / span 2' }}>
      <Box
        direction='row'
        border={{ side: 'bottom', style: 'solid', color: 'border' }}
        justify='between'
      >
        <Box flex>
          <Text margin={{ horizontal: 'small' }} weight='bold'>
            Sub Attribute
          </Text>
        </Box>
        <Box flex>
          <Text margin={{ horizontal: 'small' }} weight='bold'>
            Operator
          </Text>
        </Box>
        <Box flex>
          <Text margin={{ horizontal: 'small' }} weight='bold'>
            Value
          </Text>
        </Box>
      </Box>
      <Box pad={{ vertical: 'small' }} style={{ minHeight: 300 }}>
        {!!subattributes.length &&
          subattributes.map(({ attribute, operator, value }, i) => (
            <Box
              direction='row'
              gap='small'
              justify='between'
              key={`sub-attribute-${i}`}
              margin={{ vertical: 'xsmall' }}
            >
              <Box flex>
                <FormField
                  error={
                    dirty[i]?.attribute && validationResult.msg[i]?.attribute
                  }
                >
                  <Select
                    onChange={handleInputChange(i, 'attribute')}
                    options={attributeOptions}
                    value={attribute}
                  />
                </FormField>
              </Box>
              <Box flex>
                <FormField
                  error={
                    dirty[i]?.operator && validationResult.msg[i]?.operator
                  }
                >
                  <Select
                    onChange={handleInputChange(i, 'operator')}
                    options={operatorOptions}
                    value={operator}
                  />
                </FormField>
              </Box>
              <Box flex>
                <FormField
                  error={dirty[i]?.value && validationResult.msg[i]?.value}
                >
                  <TextInput
                    onChange={handleInputChange(i, 'value')}
                    value={value}
                  />
                </FormField>
              </Box>
              <Button
                icon={<Trash color='brand' />}
                onClick={handleDelete(i)}
                tip='Delete'
              />
            </Box>
          ))}
        <Box direction='row' pad={{ vertical: 'small' }}>
          <Button
            icon={<FormAdd />}
            label='Add Sub Attribute'
            onClick={addSubattribute}
            reverse
            secondary
          />
        </Box>
      </Box>
    </Box>
  );
};

export default FormInlineSubattributes;
