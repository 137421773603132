// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

import React, { useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import {
  Anchor,
  Box,
  Button,
  Footer,
  Form,
  FormField,
  Paragraph,
  ResponsiveContext,
  Select,
  TextInput,
  ThemeContext,
} from 'grommet';

import { FormFieldPlain } from './FormFieldPlain';
import { HorizontalSelect } from '../HorizontalSelect';
import { MachineSize } from './MachineSize';
import { Location } from './Location';
import { ImageSelect } from './ImageSelect';
import { MultiSelect } from './MultiSelect';
import { AddSshKey } from '../../containers/sshkeys/AddSshKey';
import { AddVolume } from '../storage/AddVolume';
import { HOSTNAME, HOSTNAME_MSG, MAC, MAC_MSG } from '../../data/regex';
import { useMembershipContext } from '../../utils/context/membershipContext';
import { SCOPE_HOSTER } from '../../routes/consts';
import { TagsEditDisplay } from '../tags/TagsEditDisplay';

const PROJECT_SELF = { name: 'Self', id: '__self' };

const processProjects = (projects) =>
  projects
    ? [PROJECT_SELF, ...projects.map(({ name, id }) => ({ name, id }))]
    : undefined;

const processSizes = (locationId, sizes = [], inventory = []) =>
  sizes
    .map(({ id, name, desc }) => {
      const available = inventory
        .filter(
          ({ loc_id: locId, size_id: sizeId }) =>
            sizeId === id && (locationId ? locationId === locId : true),
        )
        .map(({ number }) => number)
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
      return {
        id,
        name,
        desc,
        available,
        disabled: available <= 0,
      };
    })
    .filter(({ disabled }) => !disabled);

const processLocations = (inventory = [], locations = []) =>
  locations.map((loc) => {
    const available = inventory
      .filter(({ loc_id: locId }) => locId === loc.id)
      .map(({ number }) => number)
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    return {
      id: loc.id,
      country: loc.country,
      data_center: loc.data_center,
      region: loc.region,
      available,
      disabled: false,
      name: `${loc.data_center} ${loc.region}`,
    };
  });

// biome-ignore lint/style/useDefaultParameterLast: <explanation>
const processNetworks = (locationId, networks = [], fullNetworks) =>
  networks
    .filter(({ location_id: locId }) => locId === locationId)
    .map((network) => ({
      label: network.name,
      value: network.id,
      network: fullNetworks.find(({ id }) => id === network.id) || network,
      required: network.host_use === 'Required',
    }));

const processStorage = (locationId, inventory, volumes = []) => {
  const availableFlavorIds = inventory
    .filter((location) => location.location_id === locationId)
    .flatMap((location) => location.flavor_infos.map((flavor) => flavor.id));

  return volumes
    .filter(
      ({ flavor_id: flavorId, pod_id: podId }) =>
        podId === locationId && availableFlavorIds.includes(flavorId),
    )
    .map((volume) => ({
      label: volume.name,
      value: volume.id,
      volume,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
};

const initialFormValue = {
  name: '',
  network_for_default_route: '',
  network_untagged: '',
  description: '',
  project: PROJECT_SELF,
  networks: [],
};

export const AddHostPanel = ({
  resources,
  storageResources,
  projects,
  onSubmit,
  onHide,
  error,
  setProjectId,
  networks,
}) => {
  const size = useContext(ResponsiveContext);
  const theme = useContext(ThemeContext);
  const [form, setForm] = useState(initialFormValue);
  const [showAddKey, setShowAddKey] = useState(false);
  const [addedKeys, setAddedKeys] = useState([]);
  const [showAddVolume, setShowAddVolume] = useState(false);
  const [addedVolumes, setAddedVolumes] = useState([]);
  const [invalidHostnameMessage, setInvalidHostnameMessage] = useState('');
  const { activeMembership } = useMembershipContext();

  const locationId = get(form, 'location.id');

  const availableProjects = useMemo(
    () => processProjects(projects),
    [projects],
  );

  const showProjects = useMemo(
    () => availableProjects && activeMembership.scope === SCOPE_HOSTER,
    [availableProjects, activeMembership],
  );

  const sizes = useMemo(
    () => processSizes(locationId, resources.sizes, resources.inventory),
    [locationId, resources],
  );

  const locations = useMemo(
    () => processLocations(resources.inventory, resources.locations),
    [resources],
  );

  const availableNetworks = useMemo(
    () => processNetworks(locationId, resources.networks, networks) || [],
    [locationId, networks, resources],
  );

  const defaultNetworks = useMemo(() => form.networks || [], [form.networks]);

  const availableVolumes = useMemo(
    () =>
      processStorage(locationId, storageResources.inventory, [
        ...storageResources.volumes,
        ...addedVolumes,
      ]),
    [locationId, storageResources, addedVolumes],
  );

  const availableSshKeys = useMemo(
    () => [...resources.ssh_keys, ...addedKeys],
    [addedKeys, resources],
  );

  const onKeyAdded = ({ id, name }) => {
    const newKey = { key: id, value: name };
    setAddedKeys((prev) => [...prev, newKey]);
    setForm((prev) => ({
      ...prev,
      ssh_keys: [...(prev.ssh_keys || []), newKey],
    }));
  };

  const onVolumeAdded = ({ value: volume }) => {
    const newVolumeOpt = {
      label: volume.name,
      value: volume.id,
      volume: {
        ...volume,
        flavor: volume.flavor.label,
        flavor_id: volume.flavor.value,
        pool_id: volume.storagePool.value,
        vc_id: volume.volumeCollection.value,
        capacity: Math.round(Number.parseInt(volume.capacity, 10) * 976562.5), // convert user input from GB to KiB for Operator API call.
        existing: 'new',
        shareable: volume.shareable,
      },
    };
    setAddedVolumes((prev) => [...prev, volume]);
    setForm((prev) => {
      const volumes = prev.volumes || [];
      return {
        ...prev,
        volumes: [...volumes, newVolumeOpt],
      };
    });
    setShowAddVolume(false);
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    setProjectId(form.project.id);
    setForm(({ description, name, project }) => ({
      ...initialFormValue,
      name,
      description,
      project,
    }));
  }, [form.project]);

  // When available networks changes, reset the chosen
  // list of of networks to the default and required ones.
  useEffect(() => {
    const requiredNetworks = availableNetworks.filter(
      ({ network: { host_use: hostUse } }) =>
        hostUse === 'Required' || hostUse === 'Default',
    );

    const defaultRoute = requiredNetworks.find(
      ({ network }) => network.host_use === 'Required',
    );

    const formNetworks = requiredNetworks.filter(
      ({ network }) => network.subtype !== 'service-provider',
    );

    setForm(({ networks: prevNetworks, ...prevForm }) => {
      const prevNetworkIds = prevNetworks.map(({ value }) => value);
      const isNew =
        !prevNetworks.length ||
        formNetworks.every(({ value }) => !prevNetworkIds.includes(value));

      return {
        ...prevForm,
        networks: isNew ? formNetworks : prevNetworks,
        network_for_default_route: isNew
          ? defaultRoute || requiredNetworks[0]
          : prevForm.network_for_default_route,
      };
    });
  }, [availableNetworks]);

  useEffect(() => {
    setForm((previousValue) => ({
      ...previousValue,
      location: locations ? locations[0] : undefined,
    }));
  }, [locations]);

  useEffect(() => {
    if (
      form.networks?.length === 1 ||
      (!form.network_for_default_route && form.networks)
    ) {
      setForm((prevValue) => ({
        ...prevValue,
        network_for_default_route: form.networks[0],
      }));
    }

    // check if untagged network got removed
    if (form.network_untagged.value) {
      const hasUntaggedNetwork = form.networks.find(
        ({ value }) => value === form.network_untagged.value,
      );

      if (!hasUntaggedNetwork) {
        setForm((prevValue) => ({
          ...prevValue,
          network_untagged: { label: 'None', value: '' },
        }));
      }
    }
  }, [form.networks]); // eslint-disable-line

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (!form.name) {
      return;
    }

    const isValidHostName = HOSTNAME.test(form.name);

    if (isValidHostName) {
      if (invalidHostnameMessage) {
        setInvalidHostnameMessage('');
      }
    } else if (!invalidHostnameMessage) {
      setInvalidHostnameMessage(HOSTNAME_MSG);
    }
  }, [invalidHostnameMessage, setInvalidHostnameMessage, form.name]);

  const noMachineOptionsMessage = useMemo(() => {
    if (locations.length < 2 && !sizes.length) {
      return 'No machines are available.';
    }

    if (locations.length > 1 && !sizes.length) {
      return `No machines are available for this location.
        Please select another location.`;
    }

    return '';
  }, [locations, sizes]);

  const noImageOptionsMessage = useMemo(() => {
    if (locations.length < 2 && !resources.images.length) {
      return 'No images are available.';
    }

    if (locations.length > 1 && !resources.images.length) {
      return `No images are available for this location.
      Please select another location.`;
    }

    return '';
  }, [locations, resources.images]);
  return (
    <Box
      flex
      pad={{ bottom: 'medium', horizontal: 'medium', top: 'xsmall' }}
      fill='vertical'
      overflow='auto'
    >
      <Form
        validate='change'
        value={form}
        onSubmit={onSubmit}
        onChange={(nextForm) => {
          setForm(nextForm);
        }}
      >
        <Box>
          {showProjects && (
            <Box width='medium'>
              <FormField
                label='On Behalf of Project'
                name='project'
                htmlFor='project'
                required
                component={Select}
                placeholder='Choose a project'
                options={availableProjects}
                defaultValue={PROJECT_SELF}
                labelKey='name'
                valueKey='id'
              />
            </Box>
          )}
          <Box width='medium'>
            <FormField
              label='Host Name'
              name='name'
              htmlFor='name'
              required
              component={TextInput}
              placeholder='Enter a host name'
              err
              error={invalidHostnameMessage}
              maxLength={63}
            />
            <FormField
              label='Description'
              name='description'
              htmlFor='description'
              component={TextInput}
              placeholder='Enter a description'
              maxLength={256}
            />
          </Box>
          {locations.length > 1 && (
            <FormFieldPlain
              label='Select a Datacenter'
              name='location'
              htmlFor='location'
              component={HorizontalSelect}
              options={locations}
              ValueComponent={Location}
              required
            />
          )}
          <FormFieldPlain
            label='Select a Machine Size'
            name='machine_size'
            htmlFor='machine_size'
            component={HorizontalSelect}
            options={sizes}
            ValueComponent={MachineSize}
            required
            noOptionMessage={noMachineOptionsMessage}
          />
          <FormFieldPlain
            label='Select an Image'
            name='image'
            htmlFor='image'
            component={ImageSelect}
            images={resources.images}
            required
            noOptionMessage={noImageOptionsMessage}
          />
          <Box direction='row-responsive' gap='small' fill='horizontal'>
            <FormField
              label='SSH Keys'
              name='ssh_keys'
              htmlFor='ssh_keys'
              contentProps={{ width: 'medium' }}
              component={MultiSelect}
              options={availableSshKeys || []}
              placeholder='Select SSH Keys'
              labelKey='value'
              valueKey='key'
              required
            />
            <Box
              justify='start'
              pad={{ top: size === 'small' ? '0px' : '40px', bottom: 'medium' }}
            >
              <Anchor onClick={() => setShowAddKey(true)}>Add SSH Key</Anchor>
            </Box>
          </Box>
          <Box width='medium'>
            <FormField
              label='Networks'
              name='networks'
              htmlFor='networks'
              component={MultiSelect}
              options={availableNetworks}
              placeholder={
                locationId ? 'Select networks' : 'Select a datacenter first'
              }
              required
            />
          </Box>
          {form.networks?.length ? (
            <Box>
              {form.networks
                .filter(({ network }) => network.subtype === 'service-provider')
                .map(({ label, value }) => (
                  <Box
                    background={
                      theme.dark
                        ? theme.global.colors.background.dark
                        : theme.global.colors.background.light
                    }
                    border
                    margin={{ top: '-8px' }}
                    pad='xsmall'
                    width='medium'
                    key={value}
                  >
                    <FormField
                      component={TextInput}
                      htmlFor={value}
                      label={`${label} MAC Address Override (Optional)`}
                      name={value}
                      placeholder='XX:XX:XX:XX:XX:XX'
                      validate={(macAddress) => {
                        if (macAddress && !MAC.test(macAddress)) {
                          return MAC_MSG;
                        }

                        return undefined;
                      }}
                      value={form[value] || ''}
                    />
                  </Box>
                ))}
            </Box>
          ) : null}
          <Box width='medium'>
            <FormField
              htmlFor='network_untagged'
              label='Untagged Network'
              name='network_untagged'
            >
              <Select
                clear
                labelKey='label'
                onChange={({ value }) => {
                  setForm((prevForm) => ({
                    ...prevForm,
                    network_untagged: value,
                  }));
                }}
                value={form.network_untagged}
                valueKey='value'
                options={defaultNetworks}
                placeholder='Select network'
              />
            </FormField>
          </Box>
          <Box width='medium'>
            <FormField
              component={Select}
              htmlFor='network_for_default_route'
              label='Network for Default Route'
              labelKey='label'
              name='network_for_default_route'
              options={defaultNetworks}
              placeholder='Choose a default network for route'
              required
              valueKey='value'
            />
          </Box>
          <Box direction='row-responsive' gap='medium' fill='horizontal'>
            <Box width='medium'>
              <FormField
                label='Volumes'
                name='volumes'
                htmlFor='volumes'
                component={MultiSelect}
                options={availableVolumes}
                placeholder='Choose volumes'
              />
            </Box>
            <Box
              justify='start'
              pad={{ top: size === 'small' ? '0px' : '40px', bottom: 'medium' }}
            >
              <Anchor onClick={() => setShowAddVolume(true)}>
                Add new volume
              </Anchor>
            </Box>
          </Box>
          <TagsEditDisplay data={form} setForm={setForm} />
        </Box>
        {error && (
          <Box
            border
            round='xsmall'
            pad='small'
            margin={{ top: 'medium' }}
            background='validation-critical'
          >
            <Paragraph>{error}</Paragraph>
          </Box>
        )}
        <Footer
          align='start'
          gap='small'
          justify='start'
          pad={{ top: 'medium' }}
        >
          <Button
            type='submit'
            primary
            label='Add Host'
            disabled={!!invalidHostnameMessage}
          />
          <Button type='button' secondary label='Cancel' onClick={onHide} />
        </Footer>
      </Form>
      {showAddKey && (
        <AddSshKey
          onKeyAdded={onKeyAdded}
          onHide={() => setShowAddKey(false)}
          project={form.project}
        />
      )}
      {showAddVolume && (
        <AddVolume
          storageResources={storageResources || []}
          onSubmit={onVolumeAdded}
          onHide={() => setShowAddVolume(false)}
        />
      )}
    </Box>
  );
};

AddHostPanel.propTypes = {
  resources: PropTypes.shape({
    images: PropTypes.arrayOf(
      PropTypes.shape({
        category: PropTypes.string,
        flavor: PropTypes.string,
        id: PropTypes.string,
        version: PropTypes.string,
      }),
    ),
    inventory: PropTypes.arrayOf(
      PropTypes.shape({
        loc_id: PropTypes.string,
        number: PropTypes.number,
        size_id: PropTypes.string,
      }),
    ),
    locations: PropTypes.arrayOf(
      PropTypes.shape({
        country: PropTypes.string,
        data_center: PropTypes.string,
        id: PropTypes.string,
        region: PropTypes.string,
      }),
    ),
    networks: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        location_id: PropTypes.string,
        host_use: PropTypes.string,
        kind: PropTypes.string,
      }),
    ),
    sizes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        desc: PropTypes.shape({
          collection: PropTypes.string,
          banner1: PropTypes.string,
          banner2: PropTypes.string,
          bullets: PropTypes.arrayOf(PropTypes.string),
          info_link: PropTypes.string,
          tooltip: PropTypes.string,
        }),
      }),
    ),
    ssh_keys: PropTypes.arrayOf(
      PropTypes.shape({
        Key: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
  }),
  networks: PropTypes.array,
  projects: PropTypes.array,
  storageResources: PropTypes.object,
  onSubmit: PropTypes.func,
  onHide: PropTypes.func,
  error: PropTypes.any,
  setProjectId: PropTypes.func,
};
