// (C) Copyright 2022-2024 Hewlett Packard Enterprise Development LP

import { useQueries } from 'react-query';

import { get } from '../lib/rest';
import {
  makeRestUrl,
  URL_HOSTS,
  URL_VOLUME_ATTACHMENTS,
  URL_VOLUMES,
} from '../routes/consts';
import { getVolumes } from './volumes';
import { getHosts } from './hosts';
import { getDefaultQueriesResponse, renderLink } from './helper';
import { attachProtocolLabels } from '../data/volume';

const columns = ({ hosts, volumes }) => [
  {
    header: 'Name',
    isDefault: true,
    property: 'name',
    render: renderLink({
      base: URL_VOLUME_ATTACHMENTS,
    }),
  },
  {
    header: 'Host',
    property: 'hostID',
    isDefault: true,
    render: renderLink({
      base: URL_HOSTS,
      foreignData: hosts,
      foreignKey: 'hostID',
    }),
  },
  {
    header: 'Volume',
    property: 'volumeID',
    isDefault: true,
    render: renderLink({
      base: URL_VOLUMES,
      foreignData: volumes,
      foreignKey: 'volumeID',
    }),
  },
  {
    header: 'State',
    property: 'state',
    isDefault: true,
  },
  {
    header: 'Protocol',
    property: 'attach_protocol',
    isDefault: true,
    render: ({ attach_protocol }) =>
      attachProtocolLabels[attach_protocol] || '',
  },
  {
    header: 'Logical unit number',
    property: 'lun',
  },
];

export const getVolumeAttachments = async () => {
  const response = await get(makeRestUrl(URL_VOLUME_ATTACHMENTS));

  return response.json();
};

export const useGetVolumeAttachments = () => {
  const queryResults = useQueries([
    {
      queryKey: URL_VOLUME_ATTACHMENTS,
      queryFn: getVolumeAttachments,
    },
    {
      queryKey: URL_VOLUMES,
      queryFn: getVolumes,
    },
    {
      queryKey: URL_HOSTS,
      queryFn: getHosts,
    },
  ]);

  const isLoaded = queryResults.every(
    ({ error, isLoading }) => !isLoading && !error,
  );

  if (!isLoaded) {
    return getDefaultQueriesResponse(queryResults);
  }

  const [volumeAttachments, volumes, hosts] = queryResults;

  return {
    columns: columns({ hosts: hosts.data, volumes: volumes.data }).map(
      (column) => ({
        ...column,
        columnName: column.property,
        displayName: column.header,
      }),
    ),
    data: volumeAttachments.data,
    error: null,
    isError: false,
    isLoading: false,
    refetch: () => {
      volumeAttachments.refetch();
      volumes.refetch();
    },
  };
};
