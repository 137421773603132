// (C) Copyright 2023-2024 Hewlett Packard Enterprise Development LP

import { useMemo } from 'react';

import { get } from '../lodash';

// biome-ignore lint/style/useDefaultParameterLast: <explanation>
export default function useDataSort(data = [], sort) {
  const sortedData = useMemo(() => {
    const { property, direction } = sort;

    return data.sort((a, b) => {
      const A = get(a, property);
      const B = get(b, property);

      if (property.includes('health_status')) {
        return direction === 'asc'
          ? A.summary.localeCompare(B.summary)
          : B.summary.localeCompare(A.summary);
      }

      if (property.includes('alerts')) {
        return direction === 'asc'
          ? A.status.localeCompare(B.status)
          : B.status.localeCompare(A.status);
      }

      if (property === 'bmc') {
        const stringA = `${A.manufacturer} ${A.model}`;
        const stringB = `${B.manufacturer} ${B.model}`;

        return direction === 'asc'
          ? stringA.localeCompare(stringB)
          : stringB.localeCompare(stringA);
      }

      if (typeof A === 'string' && typeof B === 'string') {
        const numeric = !!(Number(A) && Number(B));
        const options = { numeric, sensitivity: 'base' };

        return direction === 'asc'
          ? A.localeCompare(B, undefined, options)
          : B.localeCompare(A, undefined, options);
      }

      if (Array.isArray(A) && Array.isArray(B)) {
        const aString = A.join(',');
        const bString = B.join(',');

        return direction === 'asc' ? aString - bString : bString - aString;
      }

      return direction === 'asc' ? A - B : B - A;
    });
  }, [data, sort]);

  return {
    sortedData,
  };
}
