// (C) Copyright 2016-2024 Hewlett Packard Enterprise Development LP

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import isFunction from 'lodash/isFunction';
import { sprintf } from 'sprintf-js';

import { Box, Text } from 'grommet';
import {
  Camera,
  Clear,
  Configure,
  History,
  Info,
  Install,
  Power,
  Sync,
  Terminal,
} from 'grommet-icons';
import rest from '../../lib/rest';
import { settings, IS_FIRST_SETTING } from '../../lib/settings.js';
import qs from '../../lib/querystring.js';
import auth from '../../lib/auth';
import debugLogger from '../../lib/debug';
import { ConsoleButton } from '..';
import ErrorDialog from '../controls/ErrorDialog';
import IconButton from '../generic/IconButton';
import { InfoSolid } from '../icons';

const debug = debugLogger('machineactions', false);

const PowerStatusOn = 'ON';
const PowerStatusOff = 'OFF';

const IdentifyStatusOff = 'OFF';
const IdentifyStatusOn = 'ON';
const IdentifyStatusOnIndefinitely = 'ON INDEFINITE';

/* ==============================================
 * Console Actions
 * ============================================ */
const openConsole = (info) => {
  const token = auth.getAccessToken();
  const membership = auth.getActiveMemberID();

  debug.log('ID token, membership:', token, membership);

  let id;
  let type;

  switch (info.pageType) {
    case 'host':
      id = info.hostID;
      type = 'host';
      break;
    case 'device':
      id = info.deviceID;
      type = 'device';
      break;
    default:
      id = info.machineID;
      type = 'machine';
  }

  const query = [
    qs.makeUrlValue('?', 'deviceID', id),
    qs.makeUrlValue('&', 'deviceType', type),
    qs.makeUrlValue('&', 'name', info.consoleName),
    qs.makeUrlValue('&', 'membershipId', membership),
    settings.exportQueryString(),
  ].join('');

  const url = `${window.location.protocol}//${window.location.host}/console/console.html${query}`;

  window.open(url, id);
};

const handleMaintenanceClick = (info) => {
  if (!isFunction(info.addAlert)) throw new Error('info.addAlert not defined');

  rest
    .post(`/rest/hosts/${info.hostID}/maintenance`, {})
    .then(() =>
      info.addAlert(`${info.consoleName} is being put into maintenance`),
    )
    .catch((err) =>
      rest.errorInfo(err, (errInfo) => {
        info.addAlert(
          sprintf(
            `Unable to put ${info.consoleName} into maintenance, please try again: %s`,
            errInfo.text,
          ),
          'danger',
        );
      }),
    );
};

const confirmReimageAction = (info) => {
  if (!isFunction(info.addAlert)) throw new Error('info.addAlert not defined');

  rest
    .post(`/rest/hosts/${info.hostID}/reimage`, {})
    .then(() => info.addAlert(`${info.consoleName} is being reimaged`))
    .catch((err) =>
      rest.errorInfo(err, (errInfo) => {
        info.addAlert(
          sprintf(
            `Unable to reimage ${info.consoleName}, please try again: %s`,
            errInfo.text,
          ),
          'danger',
        );
      }),
    );
};

const handleReplaceClick = (info) => {
  if (!isFunction(info.addAlert)) throw new Error('info.addAlert not defined');

  rest
    .post(`/rest/hosts/${info.hostID}/replace`, {})
    .then(() => info.addAlert(`${info.consoleName} is being replaced`))
    .catch((err) =>
      rest.errorInfo(err, (errInfo) => {
        info.addAlert(
          sprintf(
            `Unable to replace ${info.consoleName}, please try again: %s`,
            errInfo.text,
          ),
          'danger',
        );
      }),
    );
};

/* ==============================================
 * Power Actions
 * ============================================ */
const powerAction = (info, subUrl, name) => {
  if (!isFunction(info.addAlert)) throw new Error('info.addAlert not defined');

  // Post to create new snapshot
  switch (info.pageType) {
    case 'host':
      rest
        .post(`/rest/hosts/${info.hostID}${subUrl}`)
        .then(() => info.addAlert(`${name} host request submitted.`))
        .catch((err) =>
          rest.errorInfo(err, (errInfo) => {
            info.addAlert(
              sprintf(
                'Unable to %s host, please try again: %s',
                name,
                errInfo.text,
              ),
              'danger',
            );
          }),
        );
      break;
    case 'machine':
      rest
        .post(`/rest/machines/${info.machineID}${subUrl}`)
        .then(() => info.addAlert(`${name} machine request submitted.`))
        .catch((err) =>
          rest.errorInfo(err, (errInfo) => {
            info.addAlert(
              sprintf(
                'Unable to %s machine, please try again: %s',
                name,
                errInfo.text,
              ),
              'danger',
            );
          }),
        );
      break;
    case 'device':
      rest
        .post(`/rest/devices/${info.deviceID}${subUrl}`)
        .then(() => info.addAlert(`${name} device request submitted.`))
        .catch((err) =>
          rest.errorInfo(err, (errInfo) => {
            info.addAlert(
              sprintf(
                'Unable to %s device, please try again: %s',
                name,
                errInfo.text,
              ),
              'danger',
            );
          }),
        );
      break;
    default:
      info.addAlert('Unknown page type for power action', 'danger');
  }
};

const powerStatusDesc = (powerStatus) => {
  switch (powerStatus) {
    case PowerStatusOn:
      return 'Machine is powered ON. Click to power off.';
    case PowerStatusOff:
      return 'Machine is powered OFF. Click to power on.';
    default:
      return `Unknown power status: ${powerStatus}`;
  }
};

const identifyStatusDesc = (identifyStatus) => {
  switch (identifyStatus) {
    case IdentifyStatusOn:
    case IdentifyStatusOnIndefinitely:
      return 'Machine identify is ON. Click to turn off.';
    case IdentifyStatusOff:
      return 'Machine identify is OFF. Click to turn on.';
    default:
      return `Unknown identify status: ${identifyStatus}`;
  }
};

/* ==============================================
 * Button Helpers
 * ============================================ */

export const MaintenanceLaunchButton = ({ info, disabled }) => (
  <IconButton
    color='brand'
    icon={<Configure />}
    tip='Run Maintenance for this Host.'
    onClick={() => {
      handleMaintenanceClick(info);
    }}
    disabled={disabled}
  />
);

MaintenanceLaunchButton.propTypes = {
  info: PropTypes.shape({
    consoleName: PropTypes.string,
    deviceID: PropTypes.string,
    machineID: PropTypes.string,
    pageType: PropTypes.string,
    addAlert: PropTypes.func,
  }),
  disabled: PropTypes.bool,
};

export const ReplaceLaunchButton = ({ info, disabled }) => (
  <IconButton
    color='brand'
    icon={<Sync />}
    tip='Replace the machine  for this Host.'
    onClick={() => {
      handleReplaceClick(info);
    }}
    disabled={disabled}
  />
);

ReplaceLaunchButton.propTypes = {
  info: PropTypes.shape({
    consoleName: PropTypes.string,
    deviceID: PropTypes.string,
    machineID: PropTypes.string,
    pageType: PropTypes.string,
    addAlert: PropTypes.func,
  }),
  disabled: PropTypes.bool,
};

export const ConsoleLaunchButton = ({ info, disabled }) => (
  <IconButton
    color='brand'
    icon={<Terminal />}
    tip='Open serial console window to this machine.'
    onClick={() => {
      openConsole(info);
    }}
    disabled={disabled}
  />
);

ConsoleLaunchButton.propTypes = {
  info: PropTypes.shape({
    consoleName: PropTypes.string,
    deviceID: PropTypes.string,
    machineID: PropTypes.string,
    pageType: PropTypes.string,
    addAlert: PropTypes.func,
  }),
  disabled: PropTypes.bool,
};

export const ConsoleActionsMenu = ({ info, disabled }) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const handleSnapshotClick = (e) => {
    if (!isFunction(info.addAlert))
      throw new Error('info.addAlert not defined');

    // Post to create new snapshot
    rest
      .post(`/rest/hosts/${info.hostID}/console-snapshot`, '')
      .then(() => info.addAlert('Snapshot request submitted.'))
      .catch((err) =>
        rest.errorInfo(err, (errInfo) => {
          info.addAlert(
            sprintf(
              'Unable to create console snapshot, please try again: %s',
              errInfo.text,
            ),
            'danger',
          );
        }),
      );
  };

  const handleClearClick = (e) => {
    if (!isFunction(info.addAlert))
      throw new Error('info.addAlert not defined');

    // Post to clear snapshot
    rest
      .post(`/rest/hosts/${info.hostID}/console-snapshot-clear`, '')
      .then(() => info.addAlert('Snapshot recording cleared.'))
      .catch((err) =>
        rest.errorInfo(err, (errInfo) => {
          info.addAlert(
            sprintf(
              'Unable to clear console recording, please try again: %s',
              errInfo.text,
            ),
            'danger',
          );
        }),
      );
  };

  const handleHistoryClick = (e) => {
    // export const HostConsoleLogsViewLink = NoNavPath + '/host/:id/consolelogs'
    const url = `${window.location.protocol}//${
      window.location.host
    }/nonav/hosts/${encodeURIComponent(
      info.hostID,
    )}/consolelogs/${settings.exportQueryString(IS_FIRST_SETTING)}`;
    window.open(url, info.hostID);
  };

  const handleReimageClick = () => {
    setShowConfirm(true);
  };

  const confirmReimageClick = (e, info) => {
    setShowConfirm(false);
    confirmReimageAction(info);
    // stop the click from getting to the power button again
    e.preventDefault();
    e.stopPropagation();
  };

  switch (info.pageType) {
    case 'host':
      return (
        <>
          <ConsoleButton
            disabled={disabled}
            items={[
              {
                label: <Box fill>Open Serial Console Window</Box>,
                onClick: () => openConsole(info),
                icon: <Terminal />,
              },
              {
                label: <Box fill>Snapshot Serial Console recording</Box>,
                onClick: handleSnapshotClick,
                icon: <Camera />,
              },
              {
                label: <Box fill>Clear Serial Console recording</Box>,
                onClick: handleClearClick,
                icon: <Clear />,
              },
              {
                label: (
                  <Box fill>Open Serial Console history in new window</Box>
                ),
                onClick: handleHistoryClick,
                icon: <History />,
              },
              ...(info.canReimage
                ? [
                    {
                      label: <Box fill>Reimage</Box>,
                      onClick: handleReimageClick,
                      icon: <Install />,
                    },
                  ]
                : []),
              ...(info.canReplace
                ? [
                    {
                      label: <Box fill>Replace</Box>,
                      onClick: () => handleReplaceClick(info),
                      icon: <Sync />,
                    },
                  ]
                : []),
              ...(info.canMaintenance
                ? [
                    {
                      label: <Box fill>Maintenance</Box>,
                      onClick: () => handleMaintenanceClick(info),
                      icon: <Configure />,
                    },
                  ]
                : []),
            ]}
          />
          <ErrorDialog
            show={showConfirm}
            action1Text='Yes, reimage'
            dismissText='Cancel'
            title='Host Reimage'
            onAction1={(e) => confirmReimageClick(e, info)}
            onDismiss={() => setShowConfirm(false)}
          >
            <Text>
              All data will be erased! Are you sure you want to reimage{' '}
              <Text weight='bold'>{info.consoleName || info.hostID}?</Text>
            </Text>
          </ErrorDialog>
        </>
      );
    case 'machine':
      // only show launch console button for machine
      return <ConsoleLaunchButton info={info} disabled={disabled} />;
    case 'device':
      // only show launch console button for device
      return <ConsoleLaunchButton info={info} disabled={disabled} />;
    default:
  }
};

export const IdentifyButton = ({ info, disabled }) => {
  const onClick = (e) => {
    switch (info.identifyStatus) {
      case IdentifyStatusOn:
      case IdentifyStatusOnIndefinitely:
        powerAction(info, '/identify-off', 'Identify off');
        break;
      case IdentifyStatusOff:
        powerAction(info, '/identify-on', 'Identify on');
        break;
      default:
        info.addAlert('Unknown identify status', 'danger');
    }
    e.preventDefault();
  };

  const uidOn =
    info.identifyStatus === IdentifyStatusOn ||
    info.identifyStatus === IdentifyStatusOnIndefinitely;

  return (
    <IconButton
      icon={uidOn ? <InfoSolid color='status-ok' /> : <Info />}
      tip={identifyStatusDesc(info.identifyStatus)}
      disabled={disabled}
      onClick={onClick}
    />
  );
};

export const PowerButton = ({ info, disabled }) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const { powerStatus } = info;

  const onPowerOffConfirm = (e) => {
    setShowConfirm(false);
    powerAction(info, '/power-off', 'Power off');
    // stop the click from getting to the power button again
    e.preventDefault();
    e.stopPropagation();
  };

  const onDismiss = (e) => {
    setShowConfirm(false);
    // stop the click from getting to the power button again
    e.preventDefault();
    e.stopPropagation();
  };

  const onClick = (e) => {
    switch (powerStatus) {
      case PowerStatusOn:
        // if on, we will power OFF the machine to toggle
        setShowConfirm(true);
        break;
      case PowerStatusOff:
        // if off, we will power ON the machine to toggle
        powerAction(info, '/power-on', 'Power on');
        break;
      default:
        info.addAlert('Unknown power status', 'danger');
    }
    e.preventDefault();
  };

  return (
    <>
      <IconButton
        icon={
          <Power color={powerStatus === PowerStatusOn ? 'status-ok' : 'text'} />
        }
        tip={powerStatusDesc(powerStatus)}
        disabled={disabled}
        onClick={onClick}
      />
      <ErrorDialog
        show={showConfirm}
        action1Text='Yes, power off'
        dismissText='Cancel'
        title='Power off'
        onAction1={onPowerOffConfirm}
        onDismiss={onDismiss}
      >
        {info.state === 'Allocated' && (
          <Box
            background='status-warning'
            pad='small'
            round='small'
            margin={{ bottom: 'medium' }}
          >
            <Text>This machine is currently allocated to a host.</Text>
          </Box>
        )}
        <Text>
          Are you sure you want to power off{' '}
          <Text weight='bold'>{info.consoleName || info.hostID}?</Text>
        </Text>
      </ErrorDialog>
    </>
  );
};

PowerButton.propTypes = {
  info: PropTypes.shape({
    powerStatus: PropTypes.string,
    state: PropTypes.string,
    consoleName: PropTypes.string,
    hostID: PropTypes.string,
    machineID: PropTypes.string,
    deviceID: PropTypes.string,
    pageType: PropTypes.string,
    addAlert: PropTypes.func,
  }),
  disabled: PropTypes.bool,
};

const MachineActions = ({ metadata, rowData }) => {
  if (!(rowData && metadata)) {
    return null;
  }

  const infoFunc = metadata.customComponentMetadata.info;
  const info = infoFunc(rowData);
  const disabled = info.portalCommOkay === false;

  if (info.visible !== undefined && !info.visible) {
    return <div />;
  }

  return (
    <Box
      className='action-buttons'
      direction='row'
      gap='xxsmall'
      onClick={(e) => e.stopPropagation()}
      focusIndicator={false}
    >
      <ConsoleActionsMenu info={info} disabled={disabled} />
      {info.pageType !== 'host' && (
        <IdentifyButton disabled={disabled} info={info} />
      )}
      <PowerButton disabled={disabled} info={info} />
    </Box>
  );
};

MachineActions.propTypes = {
  rowData: PropTypes.object.isRequired,
  metadata: PropTypes.object.isRequired,
};

export default MachineActions;
