// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  FormField,
  Heading,
  RadioButton,
  RadioButtonGroup,
  Select,
  Text,
  TextInput,
} from 'grommet';
import { FormAdd, Trash } from 'grommet-icons';

import FormReadOnlyField from './FormReadOnlyField';
import { netmaskIPv4 } from '../../../data/network';

const defaultForm = {
  name: '',
  description: '',
  ip_version: 'IPv4',
  default_route: '',
  base_ip: '',
  netmask: '/24',
  dns: ['', ''],
  ntp: ['', ''],
  proxy: '',
  no_proxy: '',
  sources: [],
};

const defaultIpSource = {
  base: '',
  count: '',
};

const FormRadioGroupInlineAdd = ({ col, data, validationResult = [] }) => {
  const [form, setForm] = useState(defaultForm);
  const [optionValue, setOptionValue] = useState('default');
  const [sources, setIpSources] = useState([]);
  const [dirty, setDirty] = useState({});
  const isNew = useMemo(() => optionValue === 'new', [optionValue]);

  const isViewOnly = useMemo(() => {
    const { mode } = col.mb.props;

    return mode === 'view' || mode === 'edit';
  }, [col.mb.props]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    col.mb.view.setFormValue('ip_pool', optionValue);
  }, [optionValue]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    col.mb.view.setFormValue('payload', form);
  }, [form]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    sources.forEach((_, i) =>
      setDirty((prevDirty) => ({
        ...prevDirty,
        [i]: true,
      })),
    );
  }, []);

  useEffect(() => {
    setForm((prevForm) => ({
      ...prevForm,
      sources,
    }));
  }, [sources]);

  const addIpSource = (e) => {
    setIpSources((currentIpSources) => [...currentIpSources, defaultIpSource]);
  };

  const handleInputChange =
    (key) =>
    ({ option, target }) => {
      const inputValue = option || target.value;

      if (['dns0', 'dns1', 'ntp0', 'ntp1'].includes(key)) {
        const property = key.startsWith('dns') ? 'dns' : 'ntp';
        const index = Number(key.substr(-1));

        setForm((form) => {
          const value = form[property];
          value[index] = inputValue;

          return {
            ...form,
            [property]: value,
          };
        });
      } else {
        setForm((form) => ({
          ...form,
          [key]: inputValue,
        }));
      }
    };

  const handleIpResourceInputChange =
    (index, key) =>
    ({ option, target }) => {
      if (!dirty[index] && dirty[index] && !dirty[index][key]) {
        setDirty((prevDirty) => ({
          ...prevDirty,
          [index]: {
            ...prevDirty[index],
            [key]: true,
          },
        }));
      }

      const inputValue = option || target.value;

      setIpSources((currentIpSources) => [
        ...currentIpSources.slice(0, index),
        {
          ...currentIpSources[index],
          [key]: key === 'count' ? Number(inputValue) : inputValue,
        },
        ...currentIpSources.slice(index + 1),
      ]);
    };

  const handleDelete = (index) => (e) => {
    e.preventDefault();

    setIpSources((currentIpSources) => [
      ...currentIpSources.slice(0, index),
      ...currentIpSources.slice(index + 1),
    ]);
  };

  return isViewOnly ? (
    <FormReadOnlyField label={col.displayName}>
      <Text id={col.keyPath} key={col.keyPath} color='text-strong'>
        {data.ip_pool_id}
      </Text>
    </FormReadOnlyField>
  ) : (
    <Box style={{ gridColumn: '1 / span 2', width: '400px' }}>
      <Box border pad='small' round='xsmall'>
        <Box pad='small'>
          <RadioButton
            checked={optionValue === 'default'}
            label='Default IP Pool'
            name='default'
            onChange={() => setOptionValue('default')}
          />
        </Box>
        <Box border={isNew} pad='small' round='xsmall'>
          <RadioButton
            checked={isNew}
            label='Add New IP Pool'
            name='new'
            onChange={() => setOptionValue('new')}
          />
          {isNew && (
            <Box pad='small'>
              <FormField error='' label='Name'>
                <TextInput
                  onChange={handleInputChange('name')}
                  value={form.name}
                />
              </FormField>
              <FormField error='' label='Description'>
                <TextInput
                  onChange={handleInputChange('description')}
                  value={form.description}
                />
              </FormField>
              <FormField label='Protocol' htmlFor='Protocol'>
                <RadioButtonGroup
                  name='Protocol'
                  options={['IPv4', 'IPv6']}
                  value={form.ip_version}
                  onChange={handleInputChange('ip_version')}
                />
              </FormField>
              <FormField error='' label='Default Route'>
                <TextInput
                  onChange={handleInputChange('default_route')}
                  value={form.defaultRoute}
                />
              </FormField>
              <FormField error='' label='Base IP'>
                <TextInput
                  onChange={handleInputChange('base_ip')}
                  value={form.baseIp}
                />
              </FormField>
              <FormField error='' label='Netmask CIDR'>
                <Select
                  onChange={handleInputChange('netmask')}
                  options={netmaskIPv4}
                  value={form.netmask}
                />
              </FormField>
              <FormField error='' label='DNS 1'>
                <TextInput
                  onChange={handleInputChange('dns0')}
                  value={form.dns[0]}
                />
              </FormField>
              <FormField error='' label='DNS 2'>
                <TextInput
                  onChange={handleInputChange('dns1')}
                  value={form.dns[1]}
                />
              </FormField>
              <FormField error='' label='NTP 1'>
                <TextInput
                  onChange={handleInputChange('ntp0')}
                  value={form.ntp[0]}
                />
              </FormField>
              <FormField error='' label='NTP 2'>
                <TextInput
                  onChange={handleInputChange('ntp1')}
                  value={form.ntp[1]}
                />
              </FormField>
              <FormField error='' label='Proxy Server'>
                <TextInput
                  onChange={handleInputChange('proxy')}
                  value={form.proxyServer}
                />
              </FormField>
              <FormField error='' label='No Proxy'>
                <TextInput
                  onChange={handleInputChange('no_proxy')}
                  value={form.noProxy}
                />
              </FormField>
              <Box pad={{ vertical: 'medium' }}>
                <Heading
                  level={3}
                  color='text-strong'
                  size='small'
                  margin='none'
                >
                  IP Address Sources
                </Heading>
                <Text>The set of IP addresses that are part of the pool.</Text>
                <Box
                  border={{
                    side: 'bottom',
                    style: 'solid',
                    color: 'border',
                  }}
                  direction='row'
                  justify='between'
                >
                  <Box flex>
                    <Text margin={{ horizontal: 'small' }} weight='bold'>
                      Start/Base
                    </Text>
                  </Box>
                  <Box flex>
                    <Text margin={{ horizontal: 'small' }} weight='bold'>
                      Count
                    </Text>
                  </Box>
                </Box>
                <Box pad={{ vertical: 'small' }}>
                  {!!sources.length &&
                    sources.map(({ base, count }, i) => (
                      <Box
                        direction='row'
                        gap='small'
                        justify='between'
                        key={`resource-${i}`}
                        margin={{ vertical: 'xsmall' }}
                      >
                        <Box flex>
                          <FormField
                            error={
                              dirty[i]?.base && validationResult.msg[i]?.base
                            }
                          >
                            <TextInput
                              onChange={handleIpResourceInputChange(i, 'base')}
                              value={base}
                            />
                          </FormField>
                        </Box>
                        <Box flex>
                          <FormField
                            error={
                              dirty[i]?.cidr && validationResult.msg[i]?.count
                            }
                          >
                            <TextInput
                              onChange={handleIpResourceInputChange(i, 'count')}
                              value={count}
                            />
                          </FormField>
                        </Box>
                        <Button
                          icon={<Trash color='brand' />}
                          onClick={handleDelete(i)}
                          tip='Delete'
                        />
                      </Box>
                    ))}
                  <Box direction='row' pad={{ vertical: 'small' }}>
                    <Button
                      icon={<FormAdd />}
                      label='Add IP Address Sources'
                      onClick={addIpSource}
                      reverse
                      secondary
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default FormRadioGroupInlineAdd;
