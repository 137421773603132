// (C) Copyright 2020-2024 Hewlett Packard Enterprise Development LP

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import isString from 'lodash/isString';
import { Box, FormField, Text, TextArea, ThemeContext } from 'grommet';
import { normalizeColor } from 'grommet/utils';
import FormReadOnlyField from './FormReadOnlyField';

const format = (text) => {
  if (isString(text)) {
    return text.split('\n').map((item, index) => (
      <Box key={index} style={{ wordBreak: 'break-word' }}>
        <Text color='text-strong'>{item}</Text>
      </Box>
    ));
  }
  return text;
};
const FormTextArea = ({ col, value, validationResult, onChange, ...rest }) => {
  const theme = useContext(ThemeContext);
  const inputStyle = {
    style: { background: normalizeColor('background', theme) },
  };

  // read-only
  if (col.isReadOnly) {
    return (
      <FormReadOnlyField label={col.displayName}>
        <Box
          key={col.keyPath}
          id={col.keyPath}
          style={{ wordBreak: 'break-all' }}
        >
          {format(value)}
        </Box>
      </FormReadOnlyField>
    );
  }

  // editable
  return (
    <FormField
      label={col.displayName}
      name={col.keyPath}
      error={validationResult.msg}
      htmlFor={col.keyPath}
      style={{ width: '400px' }}
    >
      <TextArea
        key={col.keyPath}
        id={col.keyPath}
        name={col.keyPath}
        placeholder={col.inputDefaultValue}
        onChange={onChange}
        value={value}
        rows={10}
        cols={40}
        resize='vertical'
        {...inputStyle}
        {...rest}
      />
    </FormField>
  );
};

FormTextArea.propTypes = {
  col: PropTypes.shape({
    displayName: PropTypes.string.isRequired,
    keyPath: PropTypes.string,
    width: PropTypes.any,
    minWidth: PropTypes.any,
    maxWidth: PropTypes.any,
    isReadOnly: PropTypes.bool,
    makeLink: PropTypes.bool,
    makeLinkBaseUrl: PropTypes.string,
    inputDefaultValue: PropTypes.string,
  }).isRequired,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func,
  validationResult: PropTypes.shape({
    msg: PropTypes.string,
  }),
};

export default FormTextArea;
