// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

import React from 'react';

import { URL_MACHINETYPES } from '../../routes/consts';
import ListPageWithNav from '../../containers/generic/ListPageWithNav';
import { useGetMachineTypes } from '../../services/machineTypes';
import { NAV_COMPUTE } from '../../components/HybridNav/consts.js';

const MachineTypeListView = () => (
  <ListPageWithNav
    navSection={NAV_COMPUTE}
    actions={{
      allowCreate: 'Create machine type',
    }}
    path={URL_MACHINETYPES}
    sort={{
      property: 'weight',
      direction: 'asc',
    }}
    title='Machine types'
    useGetData={useGetMachineTypes}
  />
);

export default MachineTypeListView;
