// (C) Copyright 2020-2024 Hewlett Packard Enterprise Development LP

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Text, FormField, TextInput, ThemeContext } from 'grommet';
import { View, Hide } from 'grommet-icons';
import FormReadOnlyField from './FormReadOnlyField';

const FormPassword = ({ col, value, validationResult, onChange, ...rest }) => {
  const [reveal, setReveal] = useState(false);

  if (col.isReadOnly) {
    return (
      <FormReadOnlyField label={col.displayName}>
        <Text>*****</Text>
      </FormReadOnlyField>
    );
  }

  return (
    <ThemeContext.Consumer>
      {(theme) => (
        <FormField
          label={col.displayName}
          name={col.keyPath}
          htmlFor={col.keyPath}
          error={validationResult.msg}
          style={{ gridColumn: '1 / span 2' }}
        >
          <Box
            direction='row'
            round={theme.global.control.border.radius}
            background='background'
          >
            <TextInput
              id={col.keyPath}
              name={col.keyPath}
              plain
              type={reveal ? 'text' : 'password'}
              placeholder={col.inputDefaultValue}
              onChange={onChange}
              value={value}
              {...rest}
            />
            <Button
              plain
              focusIndicator={false}
              margin={{ horizontal: 'small' }}
              icon={reveal ? <View size='medium' /> : <Hide size='medium' />}
              onClick={() => setReveal(!reveal)}
            />
          </Box>
        </FormField>
      )}
    </ThemeContext.Consumer>
  );
};

FormPassword.propTypes = {
  col: PropTypes.shape({
    displayName: PropTypes.string.isRequired,
    keyPath: PropTypes.string,
    isReadOnly: PropTypes.bool,
    inputDefaultValue: PropTypes.string,
  }).isRequired,
  value: PropTypes.any.isRequired,
  validationResult: PropTypes.shape({
    msg: PropTypes.string,
  }),
  onChange: PropTypes.func,
};

export default FormPassword;
