// (C) Copyright 2022, 2024 Hewlett Packard Enterprise Development LP

import { useQuery } from 'react-query';

import { get } from '../lib/rest';
import { toDateTime } from '../lib/formatters';
import { makeRestUrl, URL_USERS } from '../routes/consts';

const columns = [
  {
    header: 'ID',
    property: 'id',
  },
  {
    header: 'Created',
    isDefault: true,
    property: 'created',
    render: ({ created }) => toDateTime(created),
  },
  {
    header: 'Modified',
    isDefault: true,
    property: 'modified',
    render: ({ modified }) => toDateTime(modified),
  },
  {
    header: 'Portal ID',
    property: 'portal_id',
  },
  {
    header: 'Hoster ID',
    property: 'hoster_id',
  },
  {
    header: 'Project ID',
    property: 'project_id',
  },
  {
    header: 'Username',
    property: 'username',
  },
  {
    header: 'Email',
    isDefault: true,
    property: 'email',
  },
  {
    header: 'Email verified',
    property: 'email_verified',
  },
  {
    header: 'Phone',
    property: 'phone_number',
  },
  {
    header: 'Phone verified',
    property: 'phone_verified',
  },
  {
    header: 'Full name',
    property: 'full_name',
  },
  {
    header: 'Nickname',
    property: 'nickname',
  },
  {
    header: 'Picture',
    property: 'picture',
  },
  {
    header: 'Blocked',
    property: 'blocked',
  },
  {
    header: 'Default role',
    property: 'default_role',
  },
  {
    header: 'Password',
    property: 'password',
  },
];

export const getUsers = async () => {
  const response = await get(makeRestUrl(URL_USERS));

  return response.json();
};

export const useGetUsers = () => {
  const queryResult = useQuery(URL_USERS, getUsers);

  return {
    ...queryResult,
    columns: columns.map((column) => ({
      ...column,
      columnName: column.property,
      displayName: column.header,
    })),
  };
};
