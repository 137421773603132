// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, FormField, ResponsiveContext, Select, Text } from 'grommet';
import { FormClose } from 'grommet-icons';

const Placeholder = ({ children }) => (
  <Box
    margin={{
      bottom: '8px',
    }}
    pad={{ horizontal: 'small' }}
  >
    <Text color='text-weak'>{children}</Text>
  </Box>
);

const SelectValue = ({ onClick, children }) => (
  <Box
    align='center'
    background='selected-background'
    direction='row'
    gap='xsmall'
    height='28px'
    justify='center'
    margin={{
      bottom: '9px',
      right: '9px',
    }}
    pad={{ horizontal: '9px' }}
    round='xxsmall'
  >
    <Text style={{ wordBreak: 'break-all' }}>{children}</Text>
    {onClick && <FormClose onClick={onClick} />}
  </Box>
);

const MultiSelect = ({
  allowCreate,
  emptySearchMessage,
  options,
  placeholder,
  values = [],
  onChange,
  ...rest
}) => {
  const [searchValue, setSearchValue] = useState('');
  const onSearch = (text) => {
    setSearchValue(text);
  };

  const onSelectOption = (event) => {
    // The selection in 'value' in the event will be what item
    // was selected but won't include the other existing selections
    // from the 'values' prop. We need to add the new selection to
    // the old ones
    const nextValue = [...values, ...event.value];
    onChange(nextValue);
  };

  // Only show items that aren't selected already. Since all the
  // selected items can be seen and de-selected in the main input
  // we don't need them cluttering up the drop down
  const remainingOpts = options.filter(({ value }) => !values.includes(value));

  const opts =
    allowCreate && searchValue
      ? [{ label: `Create '${searchValue}'`, value: searchValue }]
      : remainingOpts;

  return (
    <Select
      closeOnChange={allowCreate}
      disabledKey='disabled'
      emptySearchMessage={
        emptySearchMessage || 'All existing tags applied to this machine.'
      }
      labelKey='label'
      multiple
      options={opts}
      placeholder={placeholder}
      plain
      value={values}
      valueKey={{ key: 'value', reduce: true }}
      valueLabel={
        <Box
          align='center'
          alignContent='center'
          alignSelf='center'
          direction='row'
          height={{ min: '42px' }}
          justify='start'
          margin={{
            bottom: '-8px',
          }}
          pad={{
            top: '3px',
            bottom: '2px',
            left: '4px',
          }}
          wrap
        >
          {values.length === 0 ? (
            <Placeholder>{placeholder}</Placeholder>
          ) : (
            values.map((value) => {
              const item = options.find((option) => option.value === value);

              return item ? (
                <SelectValue
                  key={item.value}
                  onClick={
                    !item.required
                      ? (event) => {
                          event.stopPropagation();
                          onChange(
                            values.filter((value) => value !== item.value),
                          );
                        }
                      : undefined
                  }
                >
                  {item.label}
                </SelectValue>
              ) : null;
            })
          )}
        </Box>
      }
      onChange={onSelectOption}
      searchPlaceholder={
        allowCreate ? 'Type to create a new option' : undefined
      }
      onSearch={allowCreate ? onSearch : undefined}
      onClose={() => setSearchValue('')}
      {...rest}
    />
  );
};

const MultiDropDown = ({
  allowCreate,
  emptySearchMessage,
  keyPath,
  minWidth,
  maxWidth,
  onChange,
  options,
  placeholderIfEmpty,
  placeholder,
  usuallySingleSelect,
  value,
  width,
  validationResult = '',
  ...rest
}) => {
  const inputStyle = { style: {} };
  if (width) {
    inputStyle.style.minWidth = width;
    inputStyle.style.maxWidth = width;
  }
  if (minWidth) {
    inputStyle.style.minWidth = minWidth;
  }
  if (maxWidth) {
    inputStyle.style.maxWidth = maxWidth;
  }
  const size = useContext(ResponsiveContext);
  const respMargin =
    size === 'small'
      ? {
          left: '-5px',
        }
      : {
          left: '-5px',
          top: '-10px',
        };

  return (
    <FormField
      contentProps={{
        // this is necessary so the tags themselves visually align with the vertical column
        // & the select border doesn't compete against that column alignment
        border: { color: { light: '#9993', dark: '#7887a133' } },
      }}
      label=''
      name={keyPath}
      margin={respMargin}
      error={validationResult}
      style={{ gridColumn: '1 / span 2', ...inputStyle.style }}
    >
      <MultiSelect
        allowCreate={!!allowCreate}
        emptySearchMessage={emptySearchMessage}
        placeholder={options.length ? placeholder : placeholderIfEmpty}
        options={options}
        values={value}
        onChange={(value) => {
          onChange(value);
        }}
        {...inputStyle}
        {...rest}
      />
    </FormField>
  );
};

MultiDropDown.propTypes = {
  allowCreate: PropTypes.bool,
  emptySearchMessage: PropTypes.string,
  keyPath: PropTypes.string,
  minWidth: PropTypes.any,
  maxWidth: PropTypes.any,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      disabled: PropTypes.bool,
      required: PropTypes.bool,
    }),
  ),
  placeholderIfEmpty: PropTypes.string,
  placeholder: PropTypes.string,
  usuallySingleSelect: PropTypes.bool,
  values: PropTypes.arrayOf(PropTypes.string),
  width: PropTypes.any,
  validationResult: PropTypes.string,
};

export default MultiDropDown;
