// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

import React from 'react';

import ListPageWithNav from '../../containers/generic/ListPageWithNav';
import { URL_RACKS } from '../../routes/consts';
import { useGetRacks } from '../../services/racks';
import { NAV_RACKS } from '../../components/HybridNav/consts.js';

const RackListView = () => (
  <ListPageWithNav
    navSection={NAV_RACKS}
    actions={{
      allowCreate: 'Create rack',
    }}
    path={URL_RACKS}
    sort={{
      property: 'name',
      direction: 'asc',
    }}
    title='Racks'
    useGetData={useGetRacks}
    limitRecords={true}
  />
);

export default RackListView;
