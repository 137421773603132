// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

import React from 'react';
import { Tip } from 'grommet';
import { Insecure, Secure } from 'grommet-icons';

export const urlIcon = ({ display_url, secure_url }) => {
  switch (true) {
    case Boolean(display_url && secure_url):
      return (
        <Tip content='Secure URL has been defined separately from this Display URL.'>
          {/*
            NOTE: At the moment, Tip will throw an error if you use grommet-icon as
            direct child, so wrapping in a span is the workaround recommended by the
            grommet team. See https://github.com/grommet/grommet/issues/5256
          */}
          <span>
            <Secure color='green' />
          </span>
        </Tip>
      );
    case Boolean(!display_url && secure_url):
      return (
        <Tip content='Secure URL has been defined, but Display URL is missing.'>
          {/* see note above */}
          <span>
            <Secure color='green' />
          </span>
        </Tip>
      );
    case Boolean(display_url && !secure_url):
      return (
        <Tip content='No Secure URL has been defined for this Display URL.'>
          {/* see note above */}
          <span>
            <Insecure color='red' />
          </span>
        </Tip>
      );
    default:
      return null;
  }
};
