// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

// Gets version as setup by webpack
export function getVersion() {
  let version = process.env.REACT_APP_VERSION || '';

  // strip preceding v if present
  if (version.length > 1 && version.toLowerCase().charAt(0) === 'v') {
    version = version.substring(1);
  }
  return version;
}
