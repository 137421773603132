// (C) Copyright 2016-2024 Hewlett Packard Enterprise Development LP

import { sprintf } from 'sprintf-js';
import HttpStatus from 'http-status-codes';
import isEmpty from 'lodash/isEmpty';
import auth from './auth';
import settings from './settings';
import debugLogger from './debug';
import * as log from './debug';
import { makeUrl } from '../routes/consts';
import { getVersion } from './version';
import { PORT_FROM_URL } from '../data/regex';

const QuattroRestErrorType = 'QuattroRestError';

const debug = debugLogger('REST', log.LOG_LEVEL_WARN);

const options = () => {
  const projOpt = auth.isIAM()
    ? { Project: auth.getActiveProjectID() }
    : { Membership: auth.getActiveMemberID() };

  const restUrlMatched = PORT_FROM_URL.exec(settings.restUrlPrefix);
  // biome-ignore lint: <explanation>
  const restUrlPortExists = Boolean(restUrlMatched && restUrlMatched[1]);

  const opts = {
    mode: 'cors',
    credentials: restUrlPortExists ? 'include' : 'same-origin',
    headers: {
      Accept: 'application/json',
      Membership: auth.getActiveMemberID(),
      'API-Version': getVersion(),
      aud: auth.auth_audience,
      ...projOpt,
    },
  };

  debug.debug('options:', opts);
  return opts;
};

// ---------------------------------------------------------------------------

export const checkStatus = (response) => {
  if (response.ok) {
    return response;
  }

  switch (response.status) {
    case HttpStatus.UNAUTHORIZED:
      document.location.pathname = '/login';
      break;

    default:
  }

  const error = new Error(
    sprintf(
      '%s %s: \n%s.',
      response.status,
      response.statusText,
      `${response.url}`,
    ),
  );
  error.type = QuattroRestErrorType;
  error.response = response;

  // will be thrown to promise catch() handler
  throw error;
};

class ErrorInfo {
  constructor(status, text) {
    this.status = status;
    this.text = text;
  }
}

export const errorInfo = (err, handler) => {
  if (err.type === QuattroRestErrorType) {
    err.response.text().then((text) => {
      handler(new ErrorInfo(err.response.status, text));
    });
    return;
  }
  return handler(new ErrorInfo(err.toString(), -1));
};

// get error text for display to user
export const errorText = (err, handler) => {
  if (err.type === QuattroRestErrorType) {
    err.response.text().then((text) => {
      handler(text);
    });
    return;
  }
  return handler(err.toString());
};

// deprecated, use errorText instead to get response text and display to user
export const handleError = (err) => {
  if (err.type === QuattroRestErrorType) {
    err.response.text().then((text) => {
      debug.error(text);
    });
    return;
  }
  debug.error(err);
};

// depricated, use errorInfo instead to get response text and display to user
export const handleErrorAlert = (err, msg) => {
  debug.warn(
    'handleErrorAlert() is deprecated. Use rest.errorInfo() and the ErrorDialog control instead.',
  );
  if (err.type === QuattroRestErrorType) {
    err.response.text().then((text) => {
      debug.error(`${err}: ${text}`);
      alert(`${msg}\n\nError: ${text}`);
    });
    return;
  }

  debug.error(err);
  alert(msg);
};

const _fetch = (url, options) => {
  return fetch(url, options);
};

// skipCheck used to avoid recursive loop with auth.js
// in general you should never need to use skipCheck
export const get = (
  url,
  apiParams = {},
  skipCheck = false,
  skipLog = false,
) => {
  if (!skipLog) {
    debug.log(`GET: ${url} [${settings.restUrlPrefix}]`);
  }
  const searchParams = new URLSearchParams(apiParams).toString();
  const fullUrl = searchParams
    ? `${makeUrl(url)}?${searchParams}`
    : makeUrl(url);

  if (skipCheck) {
    return _fetch(fullUrl, { ...options(), method: 'get' });
  }

  return _fetch(fullUrl, { ...options(), method: 'get' }).then(checkStatus);
};

const removeTempReplacer = (k, v) =>
  k === '_temp' || k === '__uniqueid' ? undefined : v;
const stringifySpace = '  ';

function bodyDebug(body) {
  if (isEmpty(body)) {
    return body;
  }
  try {
    return JSON.parse(body);
  } catch (err) {
    return body;
  }
}

export const put = (
  url,
  data,
  replacer = removeTempReplacer,
  space = stringifySpace,
) => {
  const request = {
    ...options(),
    method: 'put',
    body: JSON.stringify(data, replacer, space),
  };
  debug.log(`PUT: ${url} [${settings.restUrlPrefix}]`, bodyDebug(request.body));
  debug.debug(request.body);
  // biome-ignore lint: <explanation>
  url = makeUrl(url);
  return _fetch(url, request).then(checkStatus);
};

export const patch = (
  url,
  data,
  replacer = removeTempReplacer,
  space = stringifySpace,
) => {
  const request = {
    ...options(),
    method: 'PATCH',
    body: JSON.stringify(data, replacer, space),
  };
  debug.log(
    `PATCH: ${url} [${settings.restUrlPrefix}]`,
    bodyDebug(request.body),
  );
  debug.debug(request.body);
  // biome-ignore lint: <explanation>
  url = makeUrl(url);
  return _fetch(url, request).then(checkStatus);
};

export const post = (
  url,
  data,
  replacer = removeTempReplacer,
  space = stringifySpace,
) => {
  const request = {
    ...options(),
    method: 'post',
    body: JSON.stringify(data, replacer, space),
  };
  debug.log(
    `POST: ${url} [${settings.restUrlPrefix}]`,
    bodyDebug(request.body),
  );
  debug.debug(request.body);
  // biome-ignore lint: <explanation>
  url = makeUrl(url);
  return _fetch(url, request).then(checkStatus);
};

export const del = (url) => {
  debug.log(`DELETE: ${url} [${settings.restUrlPrefix}]`);
  // biome-ignore lint: <explanation>
  url = makeUrl(url);
  return _fetch(url, { ...options(), method: 'delete' }).then(checkStatus);
};

export default {
  get,
  makeUrl,
  put,
  patch,
  post,
  del,
  errorText,
  errorInfo,
  handleError,
  handleErrorAlert,
};
