// (C) Copyright 2022-2024 Hewlett Packard Enterprise Development LP

import React from 'react';

import ListPage from '../../containers/generic/ListPage';
import { URL_NETWORKPOLICIES } from '../../routes/consts';
import { useGetNetworkPolicies } from '../../services/networkPolicies';

const NetworkPolicyListView = () => (
  <ListPage
    actions={{
      allowCreate: 'Add Network Policy',
    }}
    path={URL_NETWORKPOLICIES}
    sort={{
      property: 'name',
      direction: 'asc',
    }}
    title='Network Policies'
    useGetData={useGetNetworkPolicies}
  />
);

export default NetworkPolicyListView;
