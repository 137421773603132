// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

import React from 'react';

import { URL_ARRAYS } from '../../routes/consts';
import ListPage from '../../containers/generic/ListPage';
import { useGetArrays } from '../../services/arrays';

const ArrayListView = () => (
  <ListPage
    actions={{
      allowCreate: 'Add Array',
    }}
    path={URL_ARRAYS}
    sort={{
      property: 'name',
      direction: 'asc',
    }}
    title='Arrays'
    useGetData={useGetArrays}
  />
);

export default ArrayListView;
