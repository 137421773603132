// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, DataTable, ThemeContext } from 'grommet';
import { View, ViewHeader } from '../../components';
import rest from '../../lib/rest';
import { OsIcon } from '../../components/icons';

const plainIcons = ['coreos', 'linux'];

const DownloadsView = () => {
  const [downloads, setDownloads] = useState([]);
  // the host.replace is to remove ":3000" from path when in development mode
  const { host, protocol } = window.location;
  const baseUrl = `${protocol}//${host.replace(':3000', '')}/downloads`;
  const { dark } = useContext(ThemeContext);
  const buttonStyle = {
    padding: '10px',
  };
  const iconColor = (os) => {
    if (plainIcons.includes(os?.toLowerCase())) {
      return 'plain';
    }

    return dark ? '#eee' : '#444';
  };

  useEffect(() => {
    rest
      .get('/rest/available-downloads')
      .then((response) => response.json())
      .then((data) => {
        setDownloads(data);
      })
      .catch((err) => {
        rest.handleError(err);
      });
  }, []);

  return (
    <View>
      <ViewHeader>Downloads</ViewHeader>
      <DataTable
        data={downloads}
        primaryKey={false}
        style={{ maxWidth: '800px' }}
        background={['background-contrast', 'background']}
        columns={[
          { property: 'description', header: 'Item' },
          { property: 'version', header: 'Version' },
          {
            property: 'locations',
            header: 'Download',
            render: ({ locations }) => (
              <Box direction='row' gap='small'>
                {locations.map(({ os, path }, index) => (
                  <Button
                    // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                    key={index}
                    href={`${baseUrl}/${path}`}
                    icon={
                      <OsIcon flavor={os} size='medium' color={iconColor(os)} />
                    }
                    tip={`Download for ${os}`}
                    style={buttonStyle}
                  />
                ))}
              </Box>
            ),
          },
        ]}
      />
    </View>
  );
};

export default DownloadsView;
