// (C) Copyright 2020-2024 Hewlett Packard Enterprise Development LP

import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'grommet';
import { StatusGood, StatusCritical } from 'grommet-icons';

const alertColors = {
  info: 'status-ok',
  danger: 'status-critical',
  warning: 'status-warning',
};

const Alert = ({ status, children }) => (
  <Box
    background={alertColors[status] || 'background'}
    align='center'
    direction='row'
    gap='small'
    justify='start'
    round='medium'
    elevation='medium'
    pad={{ vertical: 'xsmall', horizontal: 'small' }}
  >
    {status === 'danger' ? <StatusCritical /> : <StatusGood />}
    <Box>{children}</Box>
  </Box>
);

Alert.propTypes = {
  status: PropTypes.string,
  children: PropTypes.node,
};

export default Alert;
