// (C) Copyright 2022-2024 Hewlett Packard Enterprise Development LP

import { useQuery } from 'react-query';

import { get } from '../lib/rest';
import { makeRestUrl, setId, URL_HOSTER_DASHBOARD } from '../routes/consts';

export const getDashboard = (id) => async () => {
  const url = makeRestUrl(setId(URL_HOSTER_DASHBOARD, id));
  const response = await get(url, true);

  return response.json();
};

export const useGetDashboard = (membership = {}) => {
  const { hoster_id: id, scope } = membership;
  const enabled = id && scope === 'hoster';

  const queryResult = useQuery(
    `${URL_HOSTER_DASHBOARD}${id}`,
    getDashboard(id),
    {
      enabled,
    },
  );

  return queryResult;
};
