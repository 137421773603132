// (C) Copyright 2016-2024 Hewlett Packard Enterprise Development LP

import { useQuery } from 'react-query';

import { get } from '../lib/rest';
import { toDateTime } from '../lib/formatters';
import {
  makeChildUrl,
  makeRestUrl,
  setParentId,
  URL_HOSTERS,
  URL_MEMBERS,
  URL_PORTALS,
  URL_PROJECTS,
  SCOPE_HOSTER,
} from '../routes/consts';
import { renderLink } from './helper';
import { Edit } from 'grommet-icons';
import auth from '../lib/auth';

const columns = (url, id) => [
  {
    header: 'Email',
    isDefault: true,
    property: 'email',
    render: renderLink({
      base: `${url}/${id}/${URL_MEMBERS}`,
      titleKey: 'email',
    }),
  },
  {
    header: 'Role ID',
    isDefault: true,
    property: 'role_id',
  },
  {
    header: 'State',
    isDefault: true,
    property: 'state',
  },
  {
    header: 'ID',
    property: 'id',
  },
  {
    header: 'Created',
    isDefault: true,
    property: 'created',
    render: ({ created }) => toDateTime(created),
  },
  {
    header: 'Modified',
    isDefault: true,
    property: 'modified',
    render: ({ modified }) => toDateTime(modified),
  },
  {
    header: 'Portal ID',
    property: 'portal_id',
  },
  {
    header: 'Hoster ID',
    property: 'hoster_id',
  },
  {
    header: 'Project ID',
    property: 'project_id',
  },
  {
    header: 'User ID',
    property: 'user_id',
  },
  {
    header: 'Scope',
    property: 'scope',
  },
  ...(auth.inScope(SCOPE_HOSTER)
    ? [
        {
          header: 'Edit',
          isDefault: true,
          property: 'edit',
          render: renderLink({
            base: `${url}/${id}/${URL_MEMBERS}`,
            icon: <Edit color='brand' />,
            isEdit: true,
          }),
        },
      ]
    : []),
];

export const getHosterMembers = (hosterId) => async () => {
  const response = await get(
    makeRestUrl(setParentId(makeChildUrl(URL_HOSTERS, URL_MEMBERS), hosterId)),
  );

  return response.json();
};

export const getPortalMembers = (portalId) => async () => {
  const response = await get(
    makeRestUrl(setParentId(makeChildUrl(URL_PORTALS, URL_MEMBERS), portalId)),
  );

  return response.json();
};

export const getProjectMembers = (projectId) => async () => {
  const response = await get(
    makeRestUrl(
      setParentId(makeChildUrl(URL_PROJECTS, URL_MEMBERS), projectId),
    ),
  );

  return response.json();
};

export const useGetHosterMembers = ({ parentId }) => {
  const queryResult = useQuery(
    `HOSTER_MEMBRS_${parentId}`,
    getHosterMembers(parentId),
  );

  return {
    ...queryResult,
    columns: columns(URL_HOSTERS, parentId).map((column) => ({
      ...column,
      columnName: column.property,
      displayName: column.header,
    })),
  };
};

export const useGetPortalMembers = ({ parentId }) => {
  const queryResult = useQuery(
    `PORTAL_MEMBERS_${parentId}`,
    getPortalMembers(parentId),
  );

  return {
    ...queryResult,
    columns: columns(URL_PORTALS, parentId).map((column) => ({
      ...column,
      columnName: column.property,
      displayName: column.header,
    })),
  };
};

const projectColumns = (url, id) => [
  {
    header: 'Email',
    isDefault: true,
    property: 'email',
  },
  {
    header: 'ID',
    property: 'id',
  },
  {
    header: 'Created',
    isDefault: true,
    property: 'created',
    render: ({ created }) => toDateTime(created),
  },
  {
    header: 'Modified',
    isDefault: true,
    property: 'modified',
    render: ({ modified }) => toDateTime(modified),
  },
  {
    header: 'State',
    isDefault: true,
    property: 'state',
  },
  {
    header: 'Portal ID',
    property: 'portal_id',
  },
  {
    header: 'Hoster ID',
    property: 'hoster_id',
  },
  {
    header: 'Project ID',
    property: 'project_id',
  },
  {
    header: 'User ID',
    property: 'user_id',
  },
  {
    header: 'Role ID',
    isDefault: true,
    property: 'role_id',
  },
  {
    header: 'Scope',
    property: 'scope',
  },
];

export const useGetProjectMembers = ({ parentId }) => {
  const queryResult = useQuery(
    `PROJECT_MEMBERS_${parentId}`,
    getProjectMembers(parentId),
  );

  return {
    ...queryResult,
    columns: projectColumns(URL_PROJECTS, parentId).map((column) => ({
      ...column,
      columnName: column.property,
      displayName: column.header,
    })),
  };
};
