// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

import React from 'react';
import { Box, Text } from 'grommet';
import { StatusWarning } from 'grommet-icons';

import FormReadOnlyField from '../../../containers/generic/MetaForm/FormReadOnlyField';

const CommStatus = ({ col, value, data }) => (
  <FormReadOnlyField label={col.displayName}>
    <Box direction='row' gap='small'>
      {!data.portal_comm_okay && <StatusWarning color='status-warning' />}
      <Text id={col.keyPath} key={col.keyPath}>
        {value}
      </Text>
    </Box>
  </FormReadOnlyField>
);

export default CommStatus;
