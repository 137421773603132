// (C) Copyright 2022-2024 Hewlett Packard Enterprise Development LP

import { useQuery } from 'react-query';

import { get } from '../lib/rest';
import { toDateTime } from '../lib/formatters';
import {
  makeRestUrl,
  URL_DEVICES,
  URL_HOSTS,
  URL_MACHINES,
  URL_RACKS,
  URL_SWITCHES,
  URL_SWITCHPORTS,
} from '../routes/consts';
import { renderLink } from './helper';
import HealthStatus from '../components/HealthStatus';

const columns = [
  {
    header: 'Status',
    isDefault: true,
    property: 'health_status',
    render: ({ health_status: status }) => (
      <HealthStatus status={status} showLabel />
    ),
  },
  {
    header: 'Name',
    isDefault: true,
    property: 'name',
  },
  {
    header: 'ID',
    property: 'id',
  },
  {
    header: 'Created',
    property: 'created',
    render: ({ created }) => toDateTime(created),
  },
  {
    header: 'Modified',
    property: 'modified',
    render: ({ modified }) => toDateTime(modified),
  },
  {
    header: 'Rack ID',
    isDefault: true,
    property: 'rack_id',
    render: renderLink({
      base: URL_RACKS,
      idKey: 'rack_id',
      titleKey: 'rack_id',
    }),
  },
  {
    header: 'Switch ID',
    isDefault: true,
    property: 'switch_id',
  },
  {
    header: 'Switch',
    property: 'switch',
  },
  {
    header: 'Fabric',
    property: 'fabric',
  },
  {
    header: 'Port name',
    isDefault: true,
    property: 'port_name',
  },
  {
    header: 'Port role',
    isDefault: true,
    property: 'role',
  },
  {
    header: 'Port speed GB',
    isDefault: true,
    property: 'port_speed',
  },
  {
    header: 'Host ID',
    property: 'host_id',
  },
  {
    header: 'Machine ID',
    property: 'machine_id',
  },
  {
    header: 'Device ID',
    property: 'device_id',
  },
];

export const getSwitchPorts = async () => {
  const response = await get(makeRestUrl(URL_SWITCHPORTS));

  return response.json();
};

export const useGetSwitchPorts = () => {
  const queryResult = useQuery(URL_SWITCHPORTS, getSwitchPorts);

  return {
    ...queryResult,
    columns: columns.map((column) => ({
      ...column,
      columnName: column.property,
      displayName: column.header,
    })),
  };
};
