// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

import React from 'react';
import PropTypes from 'prop-types';
import { Box, Select, Text, TextInput } from 'grommet';
import { SelectCard } from './SelectCard';
import { OsIcon } from '../icons';

export const Image = (props) => {
  const {
    flavor,
    images,
    selectedImage,
    disabled,
    selected,
    onClick,
    option,
    onChange,
  } = props;
  const textColor = disabled ? 'text-weak' : 'text';

  const onVersionChange = (evt) => {
    evt.stopPropagation();
    const eventSelectedImage = evt.value;

    if (onChange) {
      onChange({
        value: {
          ...option,
          selectedImage: eventSelectedImage || selectedImage,
        },
      });
    }
  };

  return (
    <SelectCard
      disabled={disabled}
      onClick={onClick}
      selected={selected}
      title={flavor}
    >
      <Box pad='xsmall' align='center'>
        <OsIcon flavor={flavor} size='64px' />
      </Box>
      <Text size='xsmall' color={textColor}>
        OS Version
      </Text>
      <Select
        disabled={disabled}
        options={images}
        onChange={onVersionChange}
        labelKey='version'
        valueKey='id'
        value={selectedImage}
        valueLabel={
          <TextInput
            plain
            readOnly
            tabIndex='-1'
            size='small'
            value={selectedImage.version}
            style={{ paddingRight: '0px' }}
          />
        }
      />
    </SelectCard>
  );
};

Image.propTypes = {
  disabled: PropTypes.bool,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  flavor: PropTypes.string,
  images: PropTypes.array,
  onChange: PropTypes.func,
  option: PropTypes.object,
  selectedImage: PropTypes.object,
};
