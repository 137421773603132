// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

import { useCallback, useState } from 'react';

import rest from '../lib/rest';
import * as c from '../routes/consts.js';

export const getAvailableStorageResources = async (projectId) => {
  const baseUrl = c.makeRestUrl(c.URL_AVAIL_STORAGE_RESOURCES);
  const params =
    projectId && projectId !== '__self' ? `?project=${projectId}` : '';
  const response = await rest.get(`${baseUrl}${params}`);
  return await response.json();
};

export const useGetAvailableStorageResources = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [availableStorageResources, setAvailableStorageResources] =
    useState(null);

  const execute = async (projectId) => {
    try {
      setIsLoading(true);
      const resources = await getAvailableStorageResources(projectId);
      setAvailableStorageResources(resources);
      setIsLoading(false);
      return resources;
    } catch (e) {
      setError(e);
      setIsLoading(false);
      throw e;
    }
  };

  return {
    isLoading,
    error,
    availableStorageResources,
    getAvailableStorageResources: useCallback(execute, []), // avoid infinite callbacks in useEffect
  };
};

export const createVolume = async (data) => {
  const response = await rest.post(c.makeRestUrl(c.URL_VOLUMES), data);
  if (response.ok) {
    return await response.json();
  }
  throw await response.text();
};

export const useCreateVolume = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [volume, setVolume] = useState(null);

  const execute = async (requestBody) => {
    try {
      setIsLoading(true);
      setError(null);
      const data = await createVolume(requestBody);
      setVolume(data);
      setIsLoading(false);
      return data;
    } catch (e) {
      setError(e);
      setIsLoading(false);
      throw e;
    }
  };

  return {
    isLoading,
    error,
    volume,
    createVolume: useCallback(execute, []), // avoid infinite callbacks in useEffect
  };
};
