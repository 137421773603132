// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

import React from 'react';
import { Blank } from 'grommet-icons';

export const CoreOs = (props) => (
  <Blank {...props}>
    <path
      d='M12 .062A11.938 11.938 0 1024 12 11.983 11.983 0 0012 .062z'
      fill='#3c6eb4'
    />
    <path
      d='M12 1.157c-1.93 0-3.566 3.071-4.158 7.34-.018.137-.037.276-.053.416-.081.671-.135 1.37-.163 2.089q-.019.493-.019 1t.019 1c.028.719.082 1.418.163 2.09.905.117 1.873.2 2.882.233.436.016.879.026 1.329.026s.894-.01 1.328-.026a31.585 31.585 0 002.883-.233l.3-.041c3.767-.53 6.389-1.696 6.389-3.051A10.872 10.872 0 0012 1.157z'
      fill='#db3279'
    />
    <path
      d='M16.509 8.953q-.165-.237-.352-.456A5.445 5.445 0 0012 6.579c-.54 0-1.022.823-1.33 2.1a12.743 12.743 0 00-.278 1.722c-.048.505-.074 1.043-.074 1.6s.026 1.1.074 1.6c.508.048 1.048.073 1.608.073s1.1-.025 1.607-.073a10.1 10.1 0 002.767-.6c.675-.278 1.074-.624 1.074-1a5.388 5.388 0 00-.94-3.047z'
      fill='#fff'
    />
  </Blank>
);
