// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

export const STATUS_OK = 'OK';
export const STATUS_WARNING = 'Warning';
export const STATUS_CRITICAL = 'Critical';
export const STATUS_NOT_PRESENT = 'NotPresent';
export const STATUS_UNKNOWN = 'Unknown';

export const MIN_GIB_ALLETRA_9K = 16;
export const MIN_GB_ALLETRA_9K = 17.18;
