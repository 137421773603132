// (C) Copyright 2016-2024 Hewlett Packard Enterprise Development LP

import React from 'react';
import MainHeaderComponent from '../../components/MainHeader/MainHeaderComponent';
import ViewArea from '../../components/ViewArea';
import ViewFooter from '../../components/ViewFooter';

const NoAuthLayout = ({ children }) => (
  <div className='page-container'>
    <MainHeaderComponent hideMemberships />

    <ViewArea>{children}</ViewArea>

    <ViewFooter />
  </div>
);

export default NoAuthLayout;
