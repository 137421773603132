// (C) Copyright 2022, 2024 Hewlett Packard Enterprise Development LP

import { useQuery } from 'react-query';
import { Box } from 'grommet';

import auth from '../lib/auth';
import { get } from '../lib/rest';
import { toDateTime } from '../lib/formatters';
import { makeRestUrl, URL_RACKCONTROLLERS } from '../routes/consts';
import { renderLink, Status } from './helper';
import HealthStatus from '../components/HealthStatus';
import { ConsoleLaunchButton } from '../components/Griddle/ControllerActions';

const columns = ({ addAlert, role = '' }) => [
  {
    header: 'Health',
    isDefault: true,
    property: 'health_status',
    render: ({ health_status: status }) => (
      <HealthStatus status={status} showLabel />
    ),
  },
  {
    header: 'Name',
    isDefault: true,
    property: 'name',
    render: renderLink({
      base: URL_RACKCONTROLLERS,
    }),
  },
  {
    header: 'Description',
    property: 'description',
  },
  {
    header: 'ID',
    property: 'id',
  },
  {
    header: 'Created',
    property: 'created',
    render: ({ created }) => toDateTime(created),
  },
  {
    header: 'Modified',
    isDefault: true,
    property: 'modified',
    render: ({ modified }) => toDateTime(modified),
  },
  {
    header: 'Pod ID',
    isDefault: true,
    property: 'pod_id',
  },
  {
    header: 'Rack ID',
    isDefault: true,
    property: 'rack_id',
  },
  {
    header: 'Comm status',
    isDefault: true,
    property: 'portal_comm_okay',
    render: ({ portal_comm_okay: commOkay }) => {
      const content = commOkay ? 'OK' : 'NC';
      const status = commOkay ? '' : 'status-warning';
      const tooltip = commOkay
        ? ''
        : 'Management connection lost to this rack controller';

      return <Status content={content} status={status} tooltip={tooltip} />;
    },
  },
  {
    header: 'Portal comm reason',
    property: 'portal_comm_reason',
  },
  {
    header: 'Device Status',
    property: 'health_status.summary',
  },
  {
    header: 'Embedded',
    isDefault: true,
    property: 'embedded',
    render: ({ embedded }) => (embedded ? 'Yes' : 'No'),
  },
  {
    header: 'Mfg',
    isDefault: true,
    property: 'info.manufacturer',
  },
  {
    header: 'Product',
    isDefault: true,
    property: 'info.product_name',
  },
  {
    header: 'Revision',
    property: 'info.label_revision',
  },
  {
    header: 'Serial number',
    isDefault: true,
    property: 'info.serial_number',
  },
  {
    header: 'Part number',
    property: 'info.part_number',
  },
  {
    header: 'Base MAC Addr',
    property: 'info.base_mac_address',
  },
  {
    header: 'Platform',
    property: 'info.platform_name',
  },
  {
    header: 'HW vendor',
    property: 'info.vendor',
  },
  {
    header: 'Mfg. date',
    property: 'info.manufacture_date',
  },
  {
    header: 'HW version',
    property: 'info.device_version',
  },
  ...(role.includes('_viewer')
    ? []
    : [
        {
          header: 'Actions',
          isDefault: true,
          property: 'guiactions',
          render: ({ id, name }) => {
            if (!id || !name) {
              return null;
            }

            const info = {
              addAlert,
              consoleName: name,
              deviceID: id,
            };

            return (
              <Box
                direction='row'
                onClick={(e) => e.stopPropagation()}
                focusIndicator={false}
              >
                <ConsoleLaunchButton
                  a11yTitle='Open serial console window to this rack controller.'
                  info={info}
                />
              </Box>
            );
          },
        },
      ]),
];

export const getRackControllers = async (apiParams = {}) => {
  const response = await get(makeRestUrl(URL_RACKCONTROLLERS), apiParams);
  return response.json();
};

export const useGetRackControllers = ({ addAlert, apiParams }) => {
  const queryResult = useQuery([URL_RACKCONTROLLERS, apiParams], () =>
    getRackControllers(apiParams),
  );

  return {
    ...queryResult,
    columns: columns({ addAlert, role: auth.activeRoleDef?.id }).map(
      (column) => ({
        ...column,
        columnName: column.property,
        displayName: column.header,
      }),
    ),
  };
};
