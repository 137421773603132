// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

import { useQuery } from 'react-query';

import { toDateTime } from '../lib/formatters';
import { get, post } from '../lib/rest';
import { makeRestUrl, URL_SSHKEYS } from '../routes/consts';
import { renderLink } from './helper';

export const createSshKey = async (data) => {
  const response = await post(makeRestUrl(URL_SSHKEYS), data);

  if (response.ok) {
    return response.json();
  }

  throw response.text();
};

const columns = [
  {
    header: 'Name',
    isDefault: true,
    property: 'name',
    render: renderLink({
      base: URL_SSHKEYS,
    }),
  },
  {
    header: 'ID',
    property: 'id',
  },
  {
    header: 'Created',
    isDefault: true,
    property: 'created',
    render: ({ created }) => toDateTime(created),
  },
  {
    header: 'Modified',
    isDefault: true,
    property: 'modified',
    render: ({ modified }) => toDateTime(modified),
  },
  {
    header: 'SSH key',
    property: 'key',
  },
];

export const getSSHKeys = async () => {
  const response = await get(makeRestUrl(URL_SSHKEYS));

  return response.json();
};

export const useGetSSHKeys = () => {
  const { data, error, isLoading, isError, refetch } = useQuery(
    URL_SSHKEYS,
    getSSHKeys,
  );

  return {
    columns: columns.map((column) => ({
      ...column,
      columnName: column.property,
      displayName: column.header,
    })),
    data,
    error,
    isError,
    isLoading,
    refetch,
  };
};
