// (C) Copyright 2022-2024 Hewlett Packard Enterprise Development LP

import React from 'react';

import ListPageWithNav from '../../containers/generic/ListPageWithNav';
import { URL_FWBASELINES } from '../../routes/consts';
import { useGetFWBaselines } from '../../services/fwBaselines';
import { NAV_COMPUTE } from '../../components/HybridNav/consts.js';

const FWBaselineListView = () => (
  <ListPageWithNav
    navSection={NAV_COMPUTE}
    actions={{
      allowCreate: 'Create FW baseline',
    }}
    path={URL_FWBASELINES}
    sort={{
      property: 'name',
      direction: 'asc',
    }}
    title='FW baselines'
    useGetData={useGetFWBaselines}
  />
);

export default FWBaselineListView;
