// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

import React from 'react';

import ListPage from '../../containers/generic/ListPage';
import { URL_HOSTER_SERVICE_CATALOG, URL_SERVICES } from '../../routes/consts';
import { useGetHosterServiceCatalogs } from '../../services/services';

const HosterServiceCatalogListView = () => (
  <ListPage
    actions={{
      allowImport: true,
      base: URL_SERVICES,
    }}
    path={URL_HOSTER_SERVICE_CATALOG}
    sort={{
      property: 'name',
      direction: 'asc',
    }}
    useGetData={useGetHosterServiceCatalogs}
  />
);

export default HosterServiceCatalogListView;
