// (C) Copyright 2016-2024 Hewlett Packard Enterprise Development LP

import auth from '../../lib/auth';
import * as c from '../../routes/consts.js';
import MetaBuilder from '../../containers/generic/MetaBuilder';
import ColumnLink from '../../components/Griddle/ColumnLink';
import ColumnFormatter from '../../components/Griddle/ColumnFormatter';
import ItemViewContainer from '../../containers/generic/ItemViewContainer';
import CreateViewContainer from '../../containers/generic/CreateViewContainer';
import { LABEL_FILES } from '../../components/HybridNav/consts.js';
import { toDateTime } from '../../lib/formatters';

const BASE_URL = c.URL_FILES;

const settings = {
  authUrl: BASE_URL, // used to filter create/trash icons from the view; using the defined roledef permissions mappings to this auth URL entry
  homeUrl: c.makeSecUrl(BASE_URL), // homepage for this list view (e.g. /s/hosters/:pid/) is where this view is located; for return from create / item views
  homeLabel: LABEL_FILES,
  baseUrl: BASE_URL, // base url to be used for creating all associated URLS for this reasource, e.g. pageItem, pageCreate, restUrl, restItemUrl
};

function metabuilder(props) {
  const mb = new MetaBuilder(props);

  mb.addRowClick((navigate) => ({ datum: { id } }) => {
    navigate(c.setId(c.makeSecItemUrl(BASE_URL), id));
  });

  mb.addColumn('name', 'Name')
    .Custom(ColumnLink, {
      info: (rowData) => {
        const url = c.setId(c.makeSecItemUrl(BASE_URL), rowData.id);
        const { name } = rowData;
        return { url, name };
      },
    })
    .Default()
    .Input()
    .Required();

  mb.addColumn('id', 'ID');
  mb.addColumn('created', 'Created');
  mb.addColumn('modified', 'Modified').Default();
  mb.addColumn('type', 'Type').Default();
  mb.addColumn('encoding', 'Encoding').Default();
  mb.addColumn('file_name', 'Filename').Default();

  if (auth.inScope(c.SCOPE_PORTAL)) {
    mb.addColumn('hoster_published', 'Published')
      .Custom(ColumnFormatter, {
        format: (rowData) => (rowData.hoster_id === '*' ? 'yes' : 'no'),
      })
      .Default();
  }

  mb.addColumn('content', 'Content').TextArea();

  return mb;
}

function metaItem(props) {
  const mb = new MetaBuilder(props);

  mb.newDataSource(mb.view.prop('itemUrl'))
    .Item(mb.view.prop('itemId'))
    .OnLoad((json) => {
      const { data } = json;
      if (data.encoding === 'base64') {
        data.content = atob(data.content);
      }
      mb.view.initForm(data);
    });

  mb.addColumn('name', 'Name')
    .Custom(ColumnLink, {
      info: (rowData) => {
        const url = c.setId(c.makeSecItemUrl(BASE_URL), rowData.id);
        const { name } = rowData;
        return { url, name };
      },
    })
    .Default()
    .Input()
    .Required();

  mb.addColumn('id', 'ID');
  mb.addColumn('modified', 'Modified')
    .FieldXform((modified) => (modified ? toDateTime(modified) : '--'))
    .Default();
  mb.addColumn('created', 'Created').FieldXform((created) =>
    created ? toDateTime(created) : '--',
  );
  mb.addColumn('type', 'Type').Default();
  mb.addColumn('encoding', 'Encoding').Default();
  mb.addColumn('file_name', 'Filename').Default();

  if (auth.inScope(c.SCOPE_PORTAL)) {
    mb.addColumn('hoster_published', 'Published')
      .Custom(ColumnFormatter, {
        format: (rowData) => (rowData.hoster_id === '*' ? 'yes' : 'no'),
      })
      .Default();
  }

  mb.addColumn('content', 'Content').TextArea();

  return mb;
}

export { default as FileListView } from './FileListView';

export const FileItemView = ItemViewContainer({
  ...settings,
  meta: (props) => metaItem(props),
  title: 'File',
});

export const FileCreateView = CreateViewContainer({
  ...settings,
  meta: (props) => metabuilder(props),
  title: 'Create File',
});
