// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import isArray from 'lodash/isArray';
import isNil from 'lodash/isNil';
import isPlainObject from 'lodash/isPlainObject';
import values from 'lodash/values';
import debugLogger from './debug';
import * as log from './debug';

const debug = debugLogger('lib/uniqueids', log.LOG_LEVEL_WARN);

export function assignUniqueIds(data) {
  _assignUniqueIds(data);
  return data;
}

function _assignUniqueIds(data) {
  if (isPlainObject(data)) {
    if (typeof data.__uniqueid === 'undefined') {
      data.__uniqueid = Symbol();
    }
    for (const item of values(data)) {
      _assignUniqueIds(item);
    }
  } else if (isArray(data)) {
    for (const item of data) {
      _assignUniqueIds(item);
    }
  }
}

export function getUniqueIdPredicate(val) {
  if (!isPlainObject(val) || isNil(val.__uniqueid)) {
    debug.throw('val must be plain object with __uniqueid set');
  }
  return (v) => v.__uniqueid === val.__uniqueid;
}

export default assignUniqueIds;
