// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import React from 'react';
import { Box, Text, Paragraph } from 'grommet';
import { CONFIDENTIAL } from '../../routes/consts.js';
import MainHeader from '../../components/MainHeader/MainHeader';
import ViewArea from '../../components/ViewArea';
import ViewFooter from '../../components/ViewFooter';
import MainHeaderComponent from '../../components/MainHeader/MainHeaderComponent.js';

export default function GetMemberships() {
  return (
    <div>
      <MainHeaderComponent hideMemberships={true} />
      <ViewArea
        pad={{ horizontal: 'none', vertical: 'large' }}
        align='center'
        style={{ height: 'calc(100vh - 161px)' }}
      >
        <Box gap='medium'>
          <Text size='xxlarge' weight='bold'>
            Welcome back
          </Text>
          <Paragraph>
            To get started you need an active membership. Contact your hoster
            for an invite.
          </Paragraph>
        </Box>
      </ViewArea>
      <ViewFooter />
    </div>
  );
}
