// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

import { useContext } from 'react';
import { DELETE, makeRestUrl } from '../../routes/consts';
import { del, errorInfo, patch, post } from '../../lib/rest';
import { AlertsContext } from '../context/alertsContext';
import { useMembershipContext } from '../context/membershipContext';
import permissions from '../../routes/permissions';

export default function useHeaderActions({
  actions,
  selected,
  refetch,
  resetSelected,
}) {
  const { allowImport, allowPublish, allowUpdate, base } = actions;
  const { addAlert } = useContext(AlertsContext);
  const { role } = useMembershipContext();
  const canDelete =
    permissions.authorized(base, DELETE, role) || actions.allowDelete;

  const handleDelete =
    canDelete && selected.length
      ? () => {
          // biome-ignore lint/complexity/noForEach: <explanation>
          selected.forEach((item) => {
            del(`/rest/${base}/${item.id}`)
              .then((res) => {
                if (!res.ok) {
                  addAlert(
                    `Unable to delete ${item.name}: ${res.statusText}`,
                    'danger',
                  );
                } else {
                  addAlert(`${item.name} deleted`);

                  if (refetch) {
                    refetch();
                  }
                }
              })
              .catch((err) => {
                errorInfo(err, (error) => {
                  addAlert(
                    `Unable to delete ${item.name}: ${error.text}`,
                    'danger',
                  );
                });
              });
          });

          resetSelected();
        }
      : undefined;

  const handleImport =
    allowImport && selected.length
      ? () => {
          for (const item of selected) {
            const service = {
              ...item,
              hostere_id: undefined,
              hoster_use: true,
              id: undefined,
              name: `${item.name}(Copy)`,
            };

            const cloneUrl = `${makeRestUrl(base)}/${item.id}/clone`;

            post(cloneUrl, service)
              .then(() => {
                addAlert(`${item.name} imported`);

                if (refetch) {
                  refetch();
                }
              })
              .catch((err) =>
                errorInfo(err, (error) => {
                  addAlert(
                    `Unable to import ${item.name}: ${error.text}`,
                    'danger',
                  );
                }),
              );
          }

          return true;
        }
      : undefined;

  const handlePublish =
    allowPublish && selected.length
      ? (event) => {
          event.preventDefault();

          const data = [{ op: 'replace', path: '/hoster_id', value: '*' }];

          for (const item of selected) {
            patch(makeRestUrl(`${base}/${item.id}`), data)
              .then((res) => {
                if (!res.ok) {
                  addAlert(
                    `Unable to publish ${item.name}: ${res.statusText}`,
                    'danger',
                  );
                } else {
                  addAlert(`${item.name} published`);

                  if (refetch) {
                    refetch();
                  }
                }
              })
              .catch((err) =>
                errorInfo(err, (error) => {
                  addAlert(
                    `Unable to publish ${item.name}: ${error.text}`,
                    'danger',
                  );
                }),
              );
          }
        }
      : undefined;

  const handleUnpublish =
    allowPublish && selected.length
      ? (event) => {
          event.preventDefault();

          const data = [{ op: 'replace', path: '/hoster_id', value: '' }];

          for (const item of selected) {
            patch(makeRestUrl(`${base}/${item.id}`), data)
              .then((res) => {
                if (!res.ok) {
                  addAlert(
                    `Unable to unpublish ${item.name}: ${res.statusText}`,
                    'danger',
                  );
                } else {
                  addAlert(`${item.name} unpublished`);

                  if (refetch) {
                    refetch();
                  }
                }
              })
              .catch((err) =>
                errorInfo(err, (error) => {
                  addAlert(
                    `Unable to unpublish ${item.name}: ${error.text}`,
                    'danger',
                  );
                }),
              );
          }
        }
      : undefined;

  const handleUpdate =
    allowUpdate && selected.length ? () => allowUpdate(selected) : undefined;

  return {
    handleDelete,
    handleImport,
    handlePublish,
    handleUnpublish,
    handleUpdate,
  };
}
