// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, ResponsiveContext, Spinner } from 'grommet';
import rest from '../../../lib/rest';
import debugLogger from '../../../lib/debug';
import * as log from '../../../lib/debug';
import FormReadOnlyField from '../../../containers/generic/MetaForm/FormReadOnlyField';
import { Badge } from './Badge';
import MultiDropDown from './MultiDropDown';
import {
  makeRestUrl,
  URL_MACHINES_SET_TAGS,
  URL_TAGS,
} from '../../../routes/consts';
import auth from '../../../lib/auth';

const debug = debugLogger('Tags', log.LOG_LEVEL_WARN);

export const Tags = ({ displayName, keyPath, tagList, machineId }) => {
  const [tagsChanged, setTagsChanged] = useState(false);
  const [editing, setEditing] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [allTags, setAllTags] = useState(false);
  const [resettingMachineTagNames, setResettingMachineTagNames] =
    useState(false);
  const [machineTagNames, setMachineTagNames] = useState([]);
  const [machineOriginalTagNames, setMachineOriginalTagNames] = useState([]);
  const labelText = tagList.length ? 'Edit Tags' : 'Add Tags';
  const isHosterViewer = auth.activeRoleDef?.id === 'hoster_viewer';

  // initialization
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (machineId && !allTags) {
      rest
        .get(makeRestUrl(URL_TAGS))
        .then((response) => response.json())
        .then((json) => {
          const tagsFormatted = json
            .map((tag) => ({
              label: tag.name,
              value: tag.name, // <-- this is intentional
              // since machines are associated with tag names, not ids,
              // tag names are submitted to prevent orphaned tags
            }))
            .sort((a, b) =>
              a.label.localeCompare(b.label, undefined, {
                sensitivity: 'base',
              }),
            );
          setAllTags(tagsFormatted);
          if (tagList?.length) {
            const machineTagNamesFiltered = [...tagsFormatted]
              .filter((tag) => tagList.includes(tag.label))
              .map((tag) => tag.label)
              .sort((a, b) =>
                a.localeCompare(b, undefined, {
                  sensitivity: 'base',
                }),
              );
            setMachineOriginalTagNames(machineTagNamesFiltered);
            setMachineTagNames(machineTagNamesFiltered);
          }
        })
        .catch((err) => {
          debug.debug('get tags', rest.handleError(err));
        });
    }
  }, [displayName, keyPath, tagList, machineId, allTags]);

  useEffect(() => {
    if (
      resettingMachineTagNames &&
      JSON.stringify(machineOriginalTagNames) ===
        JSON.stringify(machineTagNames)
    ) {
      setResettingMachineTagNames(false);
      setSubmitting(false);
      setTagsChanged(false);
      setEditing(false);
    }
  }, [machineTagNames, machineOriginalTagNames, resettingMachineTagNames]);

  const handleEdit = (e) => {
    setEditing(true);
    e.preventDefault();
  };
  const handleCancel = (e) => {
    setEditing(false);
    setMachineTagNames(machineOriginalTagNames);
    setTagsChanged(false);
    e.preventDefault();
  };
  const handleTagChange = (values) => {
    debug.debug('handleTagChange', values);
    setMachineTagNames(values);
    setTagsChanged(
      JSON.stringify(machineOriginalTagNames) !== JSON.stringify(values),
    );
  };
  const handleApplyEdit = () => {
    setSubmitting(true);
    const addTags = machineTagNames.filter(
      (tagId) => !machineOriginalTagNames.includes(tagId),
    );
    const removeTags = machineOriginalTagNames.filter(
      (tagId) => !machineTagNames.includes(tagId),
    );
    const combinedPayload = {
      ids: [machineId],
      add: addTags,
      remove: removeTags,
    };

    rest
      .post(makeRestUrl(URL_MACHINES_SET_TAGS), combinedPayload)
      .then((response) => response.json())
      .then((json) => {
        if (json.errors?.length) {
          debug.debug('add tags errors:', json.errors);
        }
        setMachineOriginalTagNames(machineTagNames);
        setResettingMachineTagNames(true);
      })
      .catch((err) => {
        debug.debug('add tags', rest.handleError(err));
      });
  };
  const size = useContext(ResponsiveContext);
  const respMargin =
    size === 'small'
      ? {
          top: '7px',
        }
      : {};

  if (displayName && machineId) {
    return (
      <FormReadOnlyField label={displayName}>
        {editing ? (
          <Box direction='column'>
            <MultiDropDown
              allowCreate={false}
              disabled={submitting}
              keyPath={keyPath}
              options={allTags || []}
              placeholder='Click to add tags...'
              values={machineTagNames}
              onChange={handleTagChange}
            />
            <Box direction='row'>
              <Button
                disabled={!tagsChanged || submitting}
                icon={submitting ? <Spinner /> : null}
                label={submitting ? 'Applying...' : 'Apply Edit'}
                onClick={handleApplyEdit}
                margin={{ right: 'small' }}
                primary
                reverse
              />
              <Button
                disabled={submitting}
                label='Cancel Edit'
                onClick={handleCancel}
                secondary
                reverse
              />
            </Box>
          </Box>
        ) : (
          <Box direction='column' margin={respMargin}>
            {machineTagNames.length ? (
              <Box direction='row'>
                <Box direction='row' wrap>
                  {machineTagNames
                    .sort((a, b) =>
                      a.localeCompare(b, undefined, {
                        sensitivity: 'base',
                      }),
                    )
                    .map((tag, idx) => (
                      <Badge key={idx} tag={tag} />
                    ))}
                </Box>
              </Box>
            ) : null}
            {!isHosterViewer && (
              <Box direction='row'>
                <Button
                  label={labelText}
                  onClick={handleEdit}
                  secondary
                  reverse
                />
              </Box>
            )}
          </Box>
        )}
      </FormReadOnlyField>
    );
  }
  return null;
};

Tags.propTypes = {
  displayName: PropTypes.string,
  keyPath: PropTypes.string,
  machineId: PropTypes.string,
  tagList: PropTypes.arrayOf(PropTypes.string),
};
