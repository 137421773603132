// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

import { useMemo, useState } from 'react';

export default function useDataSearch(data = []) {
  const [search, setSearch] = useState('');

  const regex = useMemo(() => {
    const query = search.trim().replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');

    if (query) {
      return new RegExp(query, 'i');
    }
  }, [search]);

  const filteredData = useMemo(() => {
    const filterRow = (row) => {
      // Filter out non-string values for now
      const keys = Object.keys(row).filter(
        (key) => typeof row[key] === 'string',
      );

      for (const key of keys) {
        if (regex?.test(row[key])) {
          return true;
        }
      }

      return false;
    };

    if (regex) {
      return data.filter(filterRow);
    }
    return data;
  }, [data, regex]);

  return {
    filteredData,
    search,
    setSearch,
  };
}
