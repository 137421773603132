// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

import React, { useContext, useMemo } from 'react';
import { FormField, ThemeContext } from 'grommet';
import { deepMerge } from 'grommet/utils';

export const FormFieldPlain = (props) => {
  const theme = useContext(ThemeContext);
  const formFieldTheme = useMemo(
    () =>
      deepMerge(theme, {
        formField: {
          label: {
            size: 'large',
          },
          border: {
            // This makes the content box available for validation
            // error red backgrounds.
            color: 'transparent',
          },
          focus: {
            border: false,
          },
        },
      }),
    [theme],
  );
  return (
    <ThemeContext.Provider value={formFieldTheme}>
      <FormField {...props} contentProps={{ style: { boxShadow: 'none' } }} />
    </ThemeContext.Provider>
  );
};
