// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

import React from 'react';
import {
  Apple,
  Centos,
  Cloudlinux,
  Configure,
  Debian,
  Fedora,
  Redhat,
  Sco,
  Suse,
  Ubuntu,
  Vmware,
  Windows,
} from 'grommet-icons';
import { CoreOs } from './CoreOs';
import { Linux } from './Linux';

export const OsIcon = ({ flavor = '', size = 'large', color = 'plain' }) => {
  // note that color='plain' allows icon to avoid being overridden by theme
  // and avoids the need to otherwise specify a color
  const props = {
    a11yTitle: flavor,
    color,
    size,
  };
  switch (flavor.toLowerCase()) {
    case 'centos':
      return <Centos {...props} />;
    case 'cloudlinux':
      return <Cloudlinux {...props} />;
    case 'coreos':
      return <CoreOs {...props} />;
    case 'debian':
      return <Debian {...props} />;
    case 'esxi':
      return <Vmware {...props} />;
    case 'fedora':
      return <Fedora {...props} />;
    case 'linux':
      return <Linux {...props} />;
    case 'mac':
      return <Apple {...props} />;
    case 'redhat':
    case 'rhel':
      return <Redhat {...props} />;
    case 'sco':
      return <Sco {...props} />;
    case 'sles':
    case 'suse':
      return <Suse {...props} />;
    case 'ubuntu':
      return <Ubuntu {...props} />;
    case 'windows':
      return <Windows {...props} />;
    default:
      return <Configure size={size} a11yTitle={flavor} />;
  }
};
