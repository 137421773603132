// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

export const SPEED_100_MBPS = '100Mb';
export const SPEED_1_GBPS = '1Gb';
export const SPEED_10_GBPS = '10Gb';
export const SPEED_25_GBPS = '25Gb';
export const SPEED_40_GBPS = '40Gb';
export const SPEED_50_GBPS = '50Gb';
export const SPEED_100_GBPS = '100Gb';
export const SPEED_200_GBPS = '200Gb';
export const SPEED_400_GBPS = '400Gb';

export const netSpeeds = [
  SPEED_100_MBPS,
  SPEED_1_GBPS,
  SPEED_10_GBPS,
  SPEED_25_GBPS,
  SPEED_40_GBPS,
  SPEED_50_GBPS,
  SPEED_100_GBPS,
  SPEED_200_GBPS,
  SPEED_400_GBPS,
];

export const ipAllocSizeIPv4 = ['/28', '/29', '/30', '/31'];

export const netmaskIPv4 = [
  '/8',
  '/9',
  '/10',
  '/11',
  '/12',
  '/13',
  '/14',
  '/15',
  '/16',
  '/17',
  '/18',
  '/19',
  '/20',
  '/21',
  '/22',
  '/23',
  '/24',
  '/25',
  '/26',
  '/27',
  '/28',
  '/29',
  '/30',
  '/31',
];
