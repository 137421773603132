// (C) Copyright 2020-2024 Hewlett Packard Enterprise Development LP

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Box, Meter, Text, ThemeContext } from 'grommet';
import { StatusGoodSmall } from 'grommet-icons';

const Legend = ({ values }) => {
  const theme = useContext(ThemeContext);
  const colors = theme.dashboard.fiveColor;

  return (
    <Box direction='row' wrap gap='medium' justify='center'>
      {values.map(({ label, value, color }, index) => (
        <Box direction='row' gap='xsmall' align='center' key={label}>
          <StatusGoodSmall
            size='small'
            color={color || colors[index % colors.length]}
          />
          <Text size='xsmall'>
            {value} {label}
          </Text>
        </Box>
      ))}
    </Box>
  );
};

export const Pie = ({ data }) => {
  const theme = useContext(ThemeContext);
  const colors = [...theme.dashboard.fiveColor, 'background-contrast'];

  if (!data?.values) {
    return <Text>Loading</Text>;
  }

  const values = data.values.map((item, index) => ({
    ...item,
    color: colors[index % colors.length],
  }));

  return (
    <Box height='100vh' gap='small'>
      <Legend values={values} />
      <Meter
        type='circle'
        background='background-front'
        size='full'
        values={values}
        thickness='144%'
      />
    </Box>
  );
};

Pie.propTypes = {
  data: PropTypes.shape({
    total: PropTypes.number,
    values: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.number,
      }),
    ),
  }),
};
