// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

import React from 'react';
import PropTypes from 'prop-types';
import { Box, Text } from 'grommet';

const Legend = ({ color, label, value, ...rest }) => (
  <Box align='center' direction='row' gap='xsmall' justify='start' {...rest}>
    <Box background={color} height='16px' round='4px' width='16px' />
    <Box direction='row' gap='xxsmall'>
      <Text weight='bold'>{value}</Text>
      <Text>{label}</Text>
    </Box>
  </Box>
);

const Legends = ({ values, ...rest }) => (
  <Box
    align='start'
    justify='between'
    direction='row'
    fill='horizontal'
    gap='small'
    {...rest}
  >
    {values.map((value) => (
      <Legend key={value.label} {...value} />
    ))}
  </Box>
);

Legends.propTypes = {
  values: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      label: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
};

Legends.defaultProps = {
  values: [
    {
      color: '',
      label: '',
      value: 0,
    },
  ],
};

export default Legends;
