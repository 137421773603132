// (C) Copyright 2022-2024 Hewlett Packard Enterprise Development LP

import React from 'react';
import { useHistory, useParams } from 'react-router';

import auth from '../../lib/auth';
import ListPage from '../../containers/generic/ListPage';
import { URL_MEMBERS, URL_PROJECTS } from '../../routes/consts';
import { useGetProjectMembers } from '../../services/members';

const ProjectMemberList = () => {
  const { id } = useParams();
  const history = useHistory();
  const { activeRoleDef: role } = auth || {};
  const isProjectOwner = role?.id === 'project_owner';
  const isHosterViewer = role?.id === 'hoster_viewer';
  const isHoster = ['hoster_owner', 'hoster_admin'].includes(role?.id);
  const canAdd = isProjectOwner || isHoster;

  return (
    <ListPage
      actions={{
        allowCreate: canAdd ? 'Add member' : undefined,
        allowCreateCb: () => {
          const { pathname } = history.location;
          const path = pathname.endsWith('/')
            ? pathname.slice(0, -1)
            : pathname;

          history.push(`${path}/members/create`);
        },
        allowDelete: !isHosterViewer, // members need to set manually
      }}
      path={`${URL_PROJECTS}/${id}/${URL_MEMBERS}`}
      sort={{
        property: 'name',
        direction: 'asc',
      }}
      title='Members'
      parentId={id}
      useGetData={useGetProjectMembers}
    />
  );
};

export default ProjectMemberList;
