// (C) Copyright 2022-2024 Hewlett Packard Enterprise Development LP

import React from 'react';
import { Text } from 'grommet';

export const GetFilterMessage = ({ data, filteredData }) => {
  return (
    <div
      style={{
        opacity: data?.length === 0 ? 0 : 1,
        transition: 'opacity 0.5s ease-in-out',
      }}
    >
      {filteredData.length < data?.length ? (
        <>
          <Text weight='bold'>{filteredData.length}</Text> of{' '}
          <Text weight='bold'>{data?.length}</Text> item
          {data?.length !== 1 ? 's' : ''}
        </>
      ) : (
        <>
          <Text weight='bold'>{filteredData.length}</Text> item
          {data?.length !== 1 ? 's' : ''}
        </>
      )}
    </div>
  );
};
