// (C) Copyright 2016-2024 Hewlett Packard Enterprise Development LP

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from 'grommet';
import styled from 'styled-components';
import auth from '../../lib/auth';
import MainHeaderComponent from './MainHeaderComponent';
import { makeUrl } from '../../routes/consts';
import { getVersion } from '../../lib/version';
import { useMembershipContext } from '../../utils/context/membershipContext';

const Container = styled(Box)`
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 30;
`;

const MainHeader = ({
  confidential,
  hideMemberships,
  hideHelp,
  showSession = false,
  children,
  ...rest
}) => {
  const [nickname, setNickname] = useState('');
  const [picture, setPicture] = useState('');
  const [preferredUsername, setPreferredUsername] = useState('');
  const [projectName, setProjectName] = useState('');
  const { activeMembership } = useMembershipContext();
  const teamName = activeMembership?.team_name || '';
  const projectId = auth.getActiveProjectID();

  useEffect(() => {
    (async () => {
      if (!nickname && !picture && !preferredUsername) {
        const userProfile = await auth.getUserProfile();

        if (userProfile) {
          setNickname(userProfile.nickname);
          setPicture(userProfile.picture);
          setPreferredUsername(userProfile.preferredUsername);
        }
      }
    })();
  });

  useEffect(() => {
    if (projectId) {
      fetch(makeUrl('/rest/projects-info'), {
        method: 'get',
        mode: 'cors',
        headers: {
          Accept: 'application/json',
          'API-Version': getVersion(),
        },
      })
        .then((response) => response.json())
        .then(({ projects = [] }) => {
          const project = projects.find(({ id }) => id === projectId);
          setProjectName(project?.name || '');
        })
        .catch((err) => {
          console.warn('Could not get project name, error: ', err);
        });
    }
  }, [projectId]);

  return (
    <Container {...rest}>
      {children}
      <MainHeaderComponent
        confidential={confidential}
        hideHelp={hideHelp}
        hideMemberships={hideMemberships}
        projectName={projectName}
        showLogin={!nickname}
        showLogout={!!nickname && (showSession || auth.isIAM())}
        teamName={teamName}
      />
    </Container>
  );
};

MainHeader.propTypes = {
  children: PropTypes.element,
  confidential: PropTypes.bool,
  hideHelp: PropTypes.bool,
  hideMemberships: PropTypes.bool,
  showSession: PropTypes.bool,
};

export default MainHeader;
