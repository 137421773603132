// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import PropTypes from 'prop-types';
import React, { useState } from 'react';
import get from 'lodash/get';
import { Box } from 'grommet';
import { Close, Redo, Resume } from 'grommet-icons';
import { settings } from './index.js';
import * as c from '../../routes/consts.js';
import rest from '../../lib/rest';
import ErrorDialog from '../../components/controls/ErrorDialog';
import ItemViewContainer from '../../containers/generic/ItemViewContainer.js';
import {
  PowerButton,
  ConsoleLaunchButton,
} from '../../components/Griddle/MachineActions';
import IconButton from '../../components/generic/IconButton.js';
import auth from '../../lib/auth';

export const RetryButton = ({ machineID, disabled, restItemUrl, addAlert }) => {
  const [showError, setShowError] = useState(false);
  const [errorText, setErrorText] = useState('');

  const onDismiss = (e) => {
    setShowError(false);
    // stop the click from getting to the button again
    e.preventDefault();
    e.stopPropagation();
  };

  const onClick = (e) => {
    // make rest call to reset "POST /machines/{id}/retry"
    rest
      .post(c.setId(`${restItemUrl}/retry`, machineID), '')
      .then((res) => {
        if (!res.ok) {
          setShowError(true);
          setErrorText(
            `Error found during submit: ${res.status} ${res.statusText}.`,
          );
        } else {
          addAlert(`Retrying previous state for machine ${machineID}`);
        }
      })
      .catch((err) =>
        rest.errorInfo(err, (errInfo) => {
          setShowError(true);
          setErrorText(errInfo.text);
        }),
      );
    e.preventDefault();
  };

  return (
    <>
      <IconButton
        icon={<Redo color={disabled ? 'text' : 'brand'} />}
        tip='Retry the previous machine state'
        disabled={disabled}
        onClick={onClick}
      />
      <ErrorDialog
        show={showError}
        title='Unable to retry'
        body={errorText}
        onDismiss={onDismiss}
      />
    </>
  );
};

RetryButton.propTypes = {
  machineID: PropTypes.string,
  disabled: PropTypes.bool,
  restItemUrl: PropTypes.string,
  addAlert: PropTypes.func,
};

export const AbortButton = ({ machineID, disabled, restItemUrl, addAlert }) => {
  const [showError, setShowError] = useState(false);
  const [errorText, setErrorText] = useState('');

  const onDismiss = (e) => {
    setShowError(false);
    // stop the click from getting to the button again
    e.preventDefault();
    e.stopPropagation();
  };

  const onClick = (e) => {
    // make rest call to reset "POST /machines/{id}/abort"
    rest
      .post(c.setId(`${restItemUrl}/abort`, machineID), '')
      .then((res) => {
        if (!res.ok) {
          setShowError(true);
          setErrorText(
            `Error found during submit: ${res.status} ${res.statusText}.`,
          );
        } else {
          addAlert(`Abort state processing for machine ${machineID}`);
        }
      })
      .catch((err) =>
        rest.errorInfo(err, (errInfo) => {
          setShowError(true);
          setErrorText(errInfo.text);
        }),
      );
    e.preventDefault();
  };

  return (
    <>
      <IconButton
        icon={<Close color={disabled ? 'text' : 'brand'} />}
        tip='Abort state processing for machine'
        disabled={disabled}
        onClick={onClick}
      />
      <ErrorDialog
        show={showError}
        title='Unable to abort'
        body={errorText}
        onDismiss={onDismiss}
      />
    </>
  );
};

AbortButton.propTypes = {
  machineID: PropTypes.string,
  disabled: PropTypes.bool,
  restItemUrl: PropTypes.string,
  addAlert: PropTypes.func,
};

export const ResumeButton = ({
  machineID,
  disabled,
  restItemUrl,
  addAlert,
}) => {
  const [showError, setShowError] = useState(false);
  const [errorText, setErrorText] = useState('');

  const onDismiss = (e) => {
    setShowError(false);
    // stop the click from getting to the button again
    e.preventDefault();
    e.stopPropagation();
  };

  const onClick = (e) => {
    // make rest call to reset "POST /machines/{id}/resume"
    rest
      .post(c.setId(`${restItemUrl}/resume`, machineID), '')
      .then((res) => {
        if (!res.ok) {
          setShowError(true);
          setErrorText(
            `Error found during submit: ${res.status} ${res.statusText}.`,
          );
        } else {
          addAlert(`Resume machine ${machineID}`);
        }
      })
      .catch((err) =>
        rest.errorInfo(err, (errInfo) => {
          setShowError(true);
          setErrorText(errInfo.text);
        }),
      );
    e.preventDefault();
  };

  return (
    <>
      <IconButton
        icon={<Resume color={disabled ? 'text' : 'brand'} />}
        tip='Resume machine'
        disabled={disabled}
        onClick={onClick}
      />
      <ErrorDialog
        show={showError}
        title='Unable to resume'
        body={errorText}
        onDismiss={onDismiss}
      />
    </>
  );
};

ResumeButton.propTypes = {
  machineID: PropTypes.string,
  disabled: PropTypes.bool,
  restItemUrl: PropTypes.string,
  addAlert: PropTypes.func,
};

const MachineItemHeadingControls = ({ form, addAlert }) => {
  const restBaseUrl = c.makeRestUrl(settings.baseUrl);
  const restItemUrl = c.makeItemUrl(restBaseUrl);
  const actionInfo = {
    powerStatus: get(form, 'bmc.power_status', 'OFF'),
    addAlert,
    machineID: form.id,
    pageType: 'machine',
    state: form.state,
    consoleName: form.name,
  };
  const isHosterViewer = auth.activeRoleDef?.id === 'hoster_viewer';
  const disabled = isHosterViewer || form.portal_comm_okay !== true;

  return (
    <Box
      align='center'
      direction='row'
      gap='xsmall'
      margin={{ left: 'medium' }}
    >
      <PowerButton disabled={disabled} info={actionInfo} />
      <ConsoleLaunchButton disabled={disabled} info={actionInfo} />
      <AbortButton
        disabled={disabled}
        machineID={form.id}
        restItemUrl={restItemUrl}
        addAlert={addAlert}
      />
      <RetryButton
        disabled={disabled}
        machineID={form.id}
        restItemUrl={restItemUrl}
        addAlert={addAlert}
      />
      <ResumeButton
        disabled={disabled}
        machineID={form.id}
        restItemUrl={restItemUrl}
        addAlert={addAlert}
      />
    </Box>
  );
};

export const MachineItemView = ItemViewContainer({
  ...settings,
  title: 'Machine',
  HeadingControls: MachineItemHeadingControls,
});
