// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP
import { URL_VOLUME_FLAVOR } from '../../routes/consts';
import { get as getHttp } from '../../lib/rest';
import { makeRestUrl } from '../../routes/consts';
import { useState, useEffect } from 'react';

const getVolumeFlavorMeta = async () => {
  const response = await getHttp(makeRestUrl(URL_VOLUME_FLAVOR));
  return response.json();
};

const parseA9k = (result) =>
  result.reduce((prev, current) => {
    const hasA9kCondition = current?.classifiers?.some((classifier) =>
      classifier?.rules?.some(
        (val) =>
          (val?.operator === 'equals' || val?.operator === 'contains') &&
          (val?.value === '9060' || val?.value === '9080'),
      ),
    );

    if (hasA9kCondition && current?.id) {
      prev[current.id] = current;
      return prev;
    }
    return prev;
  }, {}) || {};

const useGetVolumeFlavorMetaAlletra = () => {
  const [result, setResult] = useState({
    isLoading: true,
    result: {},
  });

  useEffect(() => {
    getVolumeFlavorMeta()
      .then((result) => {
        const Alletra_9060_9080 = parseA9k(result);

        setResult({
          isLoading: false,
          result: Alletra_9060_9080,
        });
      })
      .catch(() => {
        setResult({
          isLoading: false,
          result: {},
        });
      });
  }, []);

  return result;
};

export { useGetVolumeFlavorMetaAlletra };
