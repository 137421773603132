// (C) Copyright 2016-2024 Hewlett Packard Enterprise Development LP

import PropTypes from 'prop-types';
import React from 'react';
import { Box, Button, Text } from 'grommet';
import { Book } from 'grommet-icons';
import MemberDropdown from './MemberDropdown';
import auth from '../../lib/auth';
import { ThemeModeToggle } from '../ThemeModeToggle';
import { Title } from './Title';
import { URL_LOGIN } from '../../routes/consts';
import User from '../User';

const MainHeaderComponent = ({
  confidential,
  hideHelp,
  hideMemberships,
  projectName,
  showLogin,
  showLogout,
  teamName,
}) => {
  const { host, protocol } = window.location;
  const helpUrl = `${protocol}//${host}/docs/index.html`;

  return (
    <Box
      direction='row'
      height='75px'
      width='full'
      background={
        JSON.parse(process.env.REACT_APP_DISABLE_SECURITY)
          ? 'status-critical'
          : 'background'
      }
      align='center'
      justify='between'
      gap='medium'
      pad={{ horizontal: 'medium' }}
      border={{ side: 'bottom' }}
    >
      <Title projectName={projectName} teamName={teamName} />
      {confidential ? <Text>HPE Confidential per NDA</Text> : null}
      <Box direction='row' gap='xsmall'>
        <User />
        <ThemeModeToggle />
        {!hideMemberships ? <MemberDropdown /> : null}
        {!hideHelp ? (
          <Button
            icon={<Book />}
            href={helpUrl}
            target='quakehelp'
            style={{
              marginTop: '3px',
              padding: '6px',
            }}
            tip='Docs'
          />
        ) : null}
        {showLogin ? <Button label='Login' href={URL_LOGIN} /> : null}
        {showLogout ? (
          <Button onClick={() => auth.logout()} hoverIndicator>
            <Box pad={{ horizontal: 'medium' }}>
              <Text>Logout</Text>
            </Box>
          </Button>
        ) : null}
      </Box>
    </Box>
  );
};

MainHeaderComponent.propTypes = {
  confidential: PropTypes.bool,
  hideHelp: PropTypes.bool,
  hideMemberships: PropTypes.bool,
  projectName: PropTypes.string,
  showLogin: PropTypes.bool,
  showLogout: PropTypes.bool,
  teamName: PropTypes.string,
};

export default MainHeaderComponent;
