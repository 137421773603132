// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import React, { useEffect, useState } from 'react';
import indexOf from 'lodash/indexOf';
import { Tabs, Tab, Box, Heading } from 'grommet';
import debugLogger from '../../lib/debug';
import { DataSource } from '../../lib/datasource';
import * as log from '../../lib/debug';
import ViewHeader from '../../components/ViewHeader';
import View from '../../components/View';
import {
  settings,
  HosterServiceList,
  HosterServiceCatalogList,
} from './index.js';
import * as c from '../../routes/consts.js';
import { useParams } from 'react-router-dom';
import NavTabs from '../../components/HybridNav/NavTabs';
import { NAV_COMPUTE } from '../../components/HybridNav/consts.js';

const debug = debugLogger('HosterServices', log.LOG_LEVEL_WARN);

export function HosterServiceListView() {
  const [activeTab, setActiveTab] = useState(0);
  const [tabIndex, setTabIndex] = useState(activeTab);
  const { tab } = useParams();
  const ds = new DataSource(c.URL_SERVICES);

  useEffect(() => {
    const fetchAndSetTabIndex = async () => {
      await ds.Poll().Fetch();
      const newTabIndex =
        indexOf(['1', '2'], tab) !== -1
          ? Number.parseInt(tab, 10) - 1
          : activeTab;
      setTabIndex(newTabIndex);
    };

    fetchAndSetTabIndex();

    return () => {
      ds.StopPoll();
    };
  }, [ds, tab, activeTab]);

  return (
    <Box margin={{ top: 'small' }}>
      <NavTabs navSection={NAV_COMPUTE} />
      <View>
        <Heading
          level='2'
          fill={true}
          size='large'
          weight='normal'
          margin={{ top: 'small', bottom: 'none' }}
        >
          Services
        </Heading>
        <Tabs
          activeIndex={tabIndex}
          onActive={(nextIndex) => setActiveTab(nextIndex)}
        >
          <Tab title='OS Image Services'>
            {tabIndex === 0 && <HosterServiceList />}
          </Tab>
          <Tab title='Portal Service Catalog'>
            {tabIndex === 1 && <HosterServiceCatalogList />}
          </Tab>
        </Tabs>
      </View>
    </Box>
  );
}
