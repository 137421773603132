// (C) Copyright 2020-2024 Hewlett Packard Enterprise Development LP

import React from 'react';
import PropTypes from 'prop-types';
import isArray from 'lodash/isArray';
import { Text, FormField } from 'grommet';
import FormReadOnlyField from './FormReadOnlyField';

const FormStaticText = ({ col, value, validationResult = {} }) => {
  const content = isArray(value) ? value.join(', ') : value;
  if (col.isReadOnly) {
    return (
      <FormReadOnlyField label={col.displayName}>
        <Text id={col.keyPath} key={col.keyPath} color='text-strong'>
          {content}
        </Text>
      </FormReadOnlyField>
    );
  }

  // editable
  return (
    <FormField
      label={col.displayName}
      name={col.keyPath}
      error={validationResult.msg}
      htmlFor={col.keyPath}
      style={{ gridColumn: '1 / span 2' }}
    >
      <Text>{content}</Text>
    </FormField>
  );
};

FormStaticText.propTypes = {
  col: PropTypes.shape({
    displayName: PropTypes.string.isRequired,
    keyPath: PropTypes.string,
    isReadOnly: PropTypes.bool,
  }).isRequired,
  value: PropTypes.any.isRequired,
  validationResult: PropTypes.shape({
    msg: PropTypes.string,
  }),
};

export default FormStaticText;
