// (C) Copyright 2022-2024 Hewlett Packard Enterprise Development LP

import { useQuery } from 'react-query';

import auth from '../lib/auth';
import { get } from '../lib/rest';
import { toDateTime } from '../lib/formatters';
import { Edit } from 'grommet-icons';
import {
  makeRestUrl,
  SCOPE_PORTAL,
  SCOPE_HOSTER,
  URL_MACHINETYPES,
} from '../routes/consts';
import { renderLink } from './helper';

const columns = () => [
  {
    header: 'Name',
    isDefault: true,
    property: 'name',
    render: renderLink({
      base: URL_MACHINETYPES,
    }),
  },
  {
    header: 'Description',
    isDefault: true,
    property: 'description',
  },
  {
    header: 'FW baseline version',
    isDefault: true,
    property: 'fw_baseline_version_default',
  },
  {
    header: 'Weight',
    isDefault: true,
    property: 'weight',
  },
  {
    header: 'Created',
    property: 'created',
    render: ({ created }) => toDateTime(created),
  },
  {
    header: 'Modified',
    property: 'modified',
    render: ({ modified }) => toDateTime(modified),
  },
  {
    header: 'ID',
    property: 'id',
  },
  ...(auth.inScope(SCOPE_PORTAL)
    ? [
        {
          header: 'Published',
          isDefault: true,
          property: 'hoster_published',
          render: ({ hoster_id: id }) => (id === '*' ? 'yes' : 'no'),
        },
      ]
    : []),
  ...(auth.inScope(SCOPE_HOSTER)
    ? [
        {
          header: 'Edit',
          isDefault: true,
          property: 'edit',
          render: renderLink({
            base: URL_MACHINETYPES,
            icon: <Edit color='brand' />,
            isEdit: true,
          }),
        },
      ]
    : []),
];

export const getMachineTypes = async () => {
  const url = makeRestUrl(URL_MACHINETYPES);
  const response = await get(url);

  return response.json();
};

export const useGetMachineTypes = () => {
  const queryResult = useQuery(URL_MACHINETYPES, getMachineTypes);

  return {
    ...queryResult,
    columns: columns().map((column) => ({
      ...column,
      columnName: column.property,
      displayName: column.header,
    })),
  };
};
