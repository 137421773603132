// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

import React, { useMemo } from 'react';
import PropTypes, { oneOfType } from 'prop-types';
import { Box, DropButton, FormField, RadioButton, ThemeContext } from 'grommet';
import { CircleQuestion } from 'grommet-icons';

const FormRadioGroupWithTips = ({
  col,
  value,
  data,
  onChange,
  validationResult = {},
  ...rest
}) => {
  const options = useMemo(() => data, [data]);

  const inputStyle = { style: {} };
  if (col.width) {
    inputStyle.style.minWidth = col.width;
    inputStyle.style.maxWidth = col.width;
  }
  if (col.minWidth) {
    inputStyle.style.minWidth = col.minWidth;
  }
  if (col.maxWidth) {
    inputStyle.style.maxWidth = col.maxWidth;
  }

  const theme = {
    radioButtonGroup: {
      container: {
        direction: col.direction === 'row' ? 'row-responsive' : 'column',
      },
    },
  };
  return (
    <FormField
      label={col.isRequired ? `${col.displayName}*` : col.displayName}
      name={col.keyPath}
      htmlFor={col.keyPath}
      style={{ gridColumn: '1 / span 2', ...inputStyle.style }}
      error={validationResult.msg}
    >
      <ThemeContext.Extend value={theme}>
        <Box role='radiogroup' gap='small' {...inputStyle} {...rest}>
          {options.map(({ name, tip, id: optionValue }, i) => (
            <Box align='center' direction='row' key={optionValue} gap='xxsmall'>
              <RadioButton
                name={col.keyPath}
                disabled={col.isReadOnly}
                checked={optionValue === value}
                label={name}
                value={optionValue}
                onChange={() => {
                  onChange(col.keyPath, optionValue);
                }}
              />
              {tip && (
                <DropButton
                  dropAlign={{ top: 'bottom', left: 'left' }}
                  dropContent={
                    <Box pad='medium' width='medium'>
                      {tip}
                    </Box>
                  }
                  icon={<CircleQuestion />}
                />
              )}
            </Box>
          ))}
        </Box>
      </ThemeContext.Extend>
    </FormField>
  );
};

FormRadioGroupWithTips.propTypes = {
  col: PropTypes.shape({
    displayName: PropTypes.string.isRequired,
    keyPath: PropTypes.string,
    isReadOnly: PropTypes.bool,
    width: PropTypes.any,
    minWidth: PropTypes.any,
    maxWidth: PropTypes.any,
  }).isRequired,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: oneOfType([PropTypes.string, PropTypes.bool]),
      name: PropTypes.string,
      disabled: PropTypes.bool,
      required: PropTypes.bool,
    }),
  ),
  validationResult: PropTypes.shape({
    msg: PropTypes.string,
  }),
};

export default FormRadioGroupWithTips;
