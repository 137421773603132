// (C) Copyright 2023-2024 Hewlett Packard Enterprise Development LP

import React from 'react';
import FormDropDown from './FormDropDown';
import FormText from './FormText';
import { countries as countries3 } from '../../../data/generated/countries3';
import { countries as countries2 } from '../../../data/generated/countries2';

const FormCountryDropDown = (props) => {
  const country2 = countries2.find(({ alpha2 }) => alpha2 === props.value);
  const country3 = countries3.find(({ alpha3 }) => alpha3 === props.value);

  if (country2 === undefined && country3 === undefined) {
    const handleChange = (e) => {
      const keyPath = e.target.id;
      const { value } = e.target;
      props.onChange(keyPath, value);
    };

    return <FormText {...props} onChange={handleChange} value={props.value} />;
  }

  const data =
    country2 === undefined
      ? countries3.map(({ alpha3, name }) => ({ id: alpha3, name }))
      : countries2.map(({ alpha2, name }) => ({ id: alpha2, name }));
  const value = country2 === undefined ? country3.alpha3 : country2.alpha2;

  return <FormDropDown {...props} data={data} value={value} />;
};

export default FormCountryDropDown;
