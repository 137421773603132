// (C) Copyright 2023-2024 Hewlett Packard Enterprise Development LP

import { Text } from 'grommet';
import PropTypes from 'prop-types';
import { byteToGb } from '../../utils';
import { BarLabel } from '../../services/capacityPools';
import { useGetVolumes } from '../../services/volumes';

export const AllocatedCapacity = ({ col, data }) => {
  const { data: volumes = [] } = useGetVolumes();
  const allocated = volumes
    .filter(({ capacityPoolID }) => capacityPoolID === data.id)
    .reduce((acc, { capacity }) => acc + capacity, 0);
  const allocatedGb = byteToGb(allocated, 'GB', true);
  const showLabel = col !== undefined;

  return (
    <>
      {showLabel && <Text weight={600}>Allocated capacity</Text>}
      <BarLabel>
        {allocatedGb}
        {showLabel ? ' GB' : ''}
      </BarLabel>
    </>
  );
};

AllocatedCapacity.propTypes = {
  col: PropTypes.object,
  data: PropTypes.object,
};
