// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

import React from 'react';
import { useQueries } from 'react-query';
import { Button } from 'grommet';

import auth from '../lib/auth';
import { errorInfo, get, post } from '../lib/rest';
import * as c from '../routes/consts';
import { getDefaultQueriesResponse, renderLink } from './helper';
import { toDateTime } from '../lib/formatters';
import { Edit } from 'grommet-icons';

const columns = ({ actionCb, volumeFlavors, role = '' }) => [
  {
    header: 'Name',
    isDefault: true,
    property: 'name',
    render: renderLink({
      base: c.URL_VOLUMES,
    }),
  },
  {
    header: 'Description',
    property: 'description',
    isDefault: true,
  },
  {
    header: 'ID',
    property: 'id',
  },
  {
    header: 'Created',
    property: 'created',
    render: ({ created }) => toDateTime(created),
  },
  {
    header: 'Modified',
    property: 'modified',
    render: ({ modified }) => toDateTime(modified),
  },
  {
    header: 'Size (GB)',
    property: 'capacity',
    isDefault: true,
    render: ({ capacity }) => Math.round(capacity / 976562.5), // convert returned Operator API capacity from KiB to GB.
  },
  {
    header: 'State',
    property: 'state',
    isDefault: true,
  },
  {
    header: 'Shared count',
    property: 'export_cnt',
    isDefault: true,
    render: ({ shareable, export_cnt }) => {
      if (shareable) {
        return export_cnt;
      }
      return 'N/A';
    },
  },
  {
    header: 'Flavor',
    property: 'volumeFlavorID',
    isDefault: true,
    render: renderLink({
      base: c.URL_VOLUME_FLAVOR,
      foreignData: volumeFlavors,
      foreignKey: 'volumeFlavorID',
    }),
  },
  ...(role.includes('_viewer')
    ? []
    : [
        {
          header: 'Actions',
          property: 'actions',
          render: ({ id, state }) => {
            const handleClick = (e) => {
              e.preventDefault();
              e.stopPropagation();

              const url = c.makeRestUrl(
                c.setId(`${c.makeItemUrl(c.URL_VOLUMES)}/snapshot`, id),
              );

              post(url, {})
                .then(({ ok, status, statusText }) => {
                  if (!ok) {
                    actionCb(
                      `Unable to snapshot, please try again: ${status} ${statusText}.`,
                      'danger',
                    );
                  }
                })
                .catch((err) =>
                  errorInfo(err, ({ text }) => {
                    actionCb(
                      `Unable to snaphot, please try again: ${text}.`,
                      'danger',
                    );
                  }),
                );

              post(url, {})
                .then(({ ok, status, statusText }) => {
                  if (!ok) {
                    actionCb(
                      `Unable to snapshot, please try again: ${status} ${statusText}.`,
                      'danger',
                    );
                  }
                })
                .catch((err) =>
                  errorInfo(err, ({ text }) => {
                    actionCb(
                      `Unable to snaphot, please try again: ${text}.`,
                      'danger',
                    );
                  }),
                );
            };

            // default element state to enabled
            // if there's an update in progress, disable the element
            const disabled = !['allocated', 'visible'].includes(state);

            return (
              <Button
                disabled={disabled}
                label='Snapshot'
                onClick={handleClick}
              />
            );
          },
        },
      ]),
  ...(auth.inScope(c.SCOPE_HOSTER) || auth.inScope(c.SCOPE_PROJECT)
    ? [
        {
          header: 'Edit',
          isDefault: true,
          property: 'edit',
          render: renderLink({
            base: c.URL_VOLUMES,
            icon: <Edit color='brand' />,
            isEdit: true,
          }),
        },
      ]
    : []),
];

export const getVolumes = async () => {
  const response = await get(c.makeRestUrl(c.URL_VOLUMES));
  return response.json();
};

export const getVolumeFlavors = async () => {
  const response = await get(c.makeRestUrl(c.URL_VOLUME_FLAVOR));
  return response.json();
};

export const useGetVolumes = (actionCb) => {
  const queryResults = useQueries([
    {
      queryKey: c.URL_VOLUMES,
      queryFn: getVolumes,
    },
    {
      queryKey: c.URL_VOLUME_FLAVOR,
      queryFn: getVolumeFlavors,
    },
  ]);

  const isLoaded = queryResults.every(
    ({ error, isLoading }) => !isLoading && !error,
  );

  if (isLoaded) {
    const [volumes, volumeFlavors] = queryResults;

    return {
      columns: columns({
        actionCb,
        volumeFlavors: volumeFlavors.data,
        role: auth.activeRoleDef?.id,
      })
        // adding columnName and displayName for backwards compatibility
        .map((column) => ({
          ...column,
          columnName: column.property,
          displayName: column.header,
        })),
      data: volumes.data,
      isLoading: false,
      refetch: () => {
        volumes.refetch();
        volumeFlavors.refetch();
      },
      error: null,
    };
  }

  return getDefaultQueriesResponse(queryResults);
};
