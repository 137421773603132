// (C) Copyright 2022-2024 Hewlett Packard Enterprise Development LP

import React from 'react';

import ListPageWithNav from '../../containers/generic/ListPageWithNav';
import { URL_SWITCHPORTS } from '../../routes/consts';
import { useGetSwitchPorts } from '../../services/switchPorts';
import { NAV_NETWORKING } from '../../components/HybridNav/consts.js';

const SwitchPortListView = () => (
  <ListPageWithNav
    navSection={NAV_NETWORKING}
    actions={{}}
    path={URL_SWITCHPORTS}
    sort={{
      property: 'name',
      direction: 'asc',
    }}
    title='Switch ports'
    useGetData={useGetSwitchPorts}
  />
);

export default SwitchPortListView;
