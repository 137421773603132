// (C) Copyright 2020-2024 Hewlett Packard Enterprise Development LP

import React, { useContext } from 'react';
import { Box, Heading, ResponsiveContext, Text } from 'grommet';

export const cardSizes = {
  small: '310px',
  medium: '384px',
  large: '384px',
  xlarge: '384px',
};

export const Card = ({ children, color, elevation, ...rest }) => {
  const size = useContext(ResponsiveContext);
  return (
    <Box
      round='small'
      background={color}
      elevation={elevation}
      width={cardSizes[size]}
      flex={false}
      justify='between'
      {...rest}
      overflow='none'
    >
      {children}
    </Box>
  );
};

export const CardHeading = ({ icon, title, subtitle, children, ...rest }) => (
  <Box
    direction='row'
    pad={{ horizontal: 'medium', top: 'medium', bottom: 'small' }}
    justify='between'
    {...rest}
  >
    <Box direction='row' gap='medium' align='center'>
      {icon}
      <Box>
        <Heading level={3} color='text-strong' size='large' margin='none'>
          {title}
        </Heading>
        {subtitle && <Text color='text-strong'>{subtitle}</Text>}
      </Box>
    </Box>
    {children}
  </Box>
);

export const CardFooter = ({ children, ...rest }) => (
  <Box
    pad={{ horizontal: 'medium', vertical: 'small' }}
    background='background-contrast'
    {...rest}
  >
    {children}
  </Box>
);
export const CardBody = ({ children, ...rest }) => (
  <Box pad={{ horizontal: 'medium', bottom: 'medium' }} {...rest}>
    {children}
  </Box>
);
