// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

import { hpe } from 'grommet-theme-hpe';
import { deepMerge } from 'grommet/utils';

export const theme = deepMerge(hpe, {
  defaultMode: 'dark',
  /*
  'graph-0': 'orange!'
  'graph-1': 'blue!'
  'graph-2': 'purple!'
  'graph-3': 'yellow!'
  'graph-4': 'teal!'
  'graph-5': '#17EBA0'
  */
  dashboard: {
    twoColor: ['graph-1', 'graph-3'],
    fiveColor: ['graph-1', 'graph-3', 'graph-0', 'graph-2', 'graph-4'],
  },
  // temporary workaround of Layer plain={true} elevation
  // https://github.com/grommet/grommet/issues/5129
  layer: {
    container: {
      elevation: 'none',
    },
  },
  listView: {
    actionButton: {
      color: 'brand',
    },
  },
  select: {
    container: {
      text: {
        margin: {
          horizontal: 'small',
          vertical: 'xsmall',
        },
      },
    },
  },
  table: {
    header: { extend: { fontWeight: '600' } },
    zebra: {
      dark: {
        odd: {
          main: '#263040',
          hover: '#37404f',
          selected: {
            main: '#17EBA008',
            hover: '#17EBA066',
          },
        },
        even: {
          main: '#404b5c',
          hover: '#505969',
          selected: {
            main: '#17EBA029',
            hover: '#17EBA066',
          },
        },
      },
      light: {
        odd: {
          main: '#efefef',
          hover: '#e6e6e6',
          selected: {
            main: '#17EBA03D',
            hover: '#17EBA080',
          },
        },
        even: {
          main: '#ffffff',
          hover: '#f5f5f5',
          selected: {
            main: '#17EBA01A',
            hover: '#17EBA080',
          },
        },
      },
    },
  },
  global: {
    colors: {
      'graph-5': '#17EBA0',
      'health-status-ok-bg': {
        dark: '#204647',
        light: '#c8fae8',
      },
      'health-status-ok-icon': {
        dark: '#1ab281',
        light: '#17eba0',
      },
      'health-status-critical-bg': {
        dark: '#4c2f3c',
        light: '#fed9d9',
      },
      'health-status-critical-icon': {
        dark: '#c04b50',
        light: '#fc5a5a',
      },
      'health-status-warning-bg': {
        dark: '#514733',
        light: '#ffefd2',
      },
      'health-status-warning-icon': {
        dark: '#c3943f',
        light: '#ffbc44',
      },
      'health-status-unknown-bg': {
        dark: '#2d3849',
        light: '#efefef',
      },
      'health-status-unknown-icon': {
        dark: '#445267',
        light: '#cccccc',
      },
    },
    breakpoints: {
      xsmall: {
        value: 375,
      },
      small: {
        value: 568,
        edgeSize: {
          none: '0px',
          small: '6px',
          medium: '12px',
          large: '24px',
        },
      },
      medium: {
        value: 768,
        edgeSize: {
          none: '0px',
          small: '12px',
          medium: '24px',
          large: '48px',
        },
      },
      large: {
        value: 1024,
        edgeSize: {
          none: '0px',
          small: '12px',
          medium: '24px',
          large: '48px',
        },
      },
      xlarge: {
        value: 1366,
        edgeSize: {
          none: '0px',
          small: '12px',
          medium: '24px',
          large: '48px',
        },
      },
    },
  },
});
