// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

import { useQuery } from 'react-query';

import { SCOPE_HOSTER, URL_VOLUME_FLAVOR } from '../routes/consts';
import { renderLink } from './helper';
import { getVolumeFlavors } from './volumes';
import auth from '../lib/auth';
import { toDateTime } from '../lib/formatters';
import { Edit } from 'grommet-icons';

const columns = () => [
  {
    header: 'Name',
    isDefault: true,
    property: 'name',
    render: renderLink({
      base: URL_VOLUME_FLAVOR,
    }),
  },
  {
    header: 'Description',
    property: 'description',
    isDefault: true,
  },
  {
    header: 'ID',
    property: 'id',
  },
  {
    header: 'Project visible',
    property: 'project_use',
    isDefault: true,
    render: ({ project_use: use }) => (use ? 'Yes' : 'No'),
  },
  {
    header: 'Hoster visible',
    property: 'hoster_use',
    isDefault: true,
    render: ({ hoster_use: use }) => (use ? 'Yes' : 'No'),
  },
  {
    header: 'Created',
    property: 'created',
    render: ({ created }) => toDateTime(created),
  },
  {
    header: 'Modified',
    property: 'modified',
    render: ({ modified }) => toDateTime(modified),
  },
  ...(auth.inScope(SCOPE_HOSTER)
    ? [
        {
          header: 'Edit',
          isDefault: true,
          property: 'edit',
          render: renderLink({
            base: URL_VOLUME_FLAVOR,
            icon: <Edit color='brand' />,
            isEdit: true,
          }),
        },
      ]
    : []),
];

export const useGetVolumeFlavors = () => {
  const { data, error, isLoading, isError, refetch } = useQuery(
    'volume-flavors',
    getVolumeFlavors,
  );

  return {
    columns: columns().map((column) => ({
      ...column,
      columnName: column.property,
      displayName: column.header,
    })),
    data,
    error,
    isLoading,
    isError,
    refetch,
  };
};
