// (C) Copyright 2023-2024 Hewlett Packard Enterprise Development LP

/*
DO NOT MODIFY - GENERATED SOURCES - SEE model/enums/gen-enum.go
*/

export const countries = [
  {
    alpha3: 'USA',
    'country-code': '840',
    name: 'United States of America',
  },
  {
    alpha3: 'AFG',
    'country-code': '004',
    name: 'Afghanistan',
  },
  {
    alpha3: 'ALA',
    'country-code': '248',
    name: 'Åland Islands',
  },
  {
    alpha3: 'ALB',
    'country-code': '008',
    name: 'Albania',
  },
  {
    alpha3: 'DZA',
    'country-code': '012',
    name: 'Algeria',
  },
  {
    alpha3: 'ASM',
    'country-code': '016',
    name: 'American Samoa',
  },
  {
    alpha3: 'AND',
    'country-code': '020',
    name: 'Andorra',
  },
  {
    alpha3: 'AGO',
    'country-code': '024',
    name: 'Angola',
  },
  {
    alpha3: 'AIA',
    'country-code': '660',
    name: 'Anguilla',
  },
  {
    alpha3: 'ATA',
    'country-code': '010',
    name: 'Antarctica',
  },
  {
    alpha3: 'ATG',
    'country-code': '028',
    name: 'Antigua and Barbuda',
  },
  {
    alpha3: 'ARG',
    'country-code': '032',
    name: 'Argentina',
  },
  {
    alpha3: 'ARM',
    'country-code': '051',
    name: 'Armenia',
  },
  {
    alpha3: 'ABW',
    'country-code': '533',
    name: 'Aruba',
  },
  {
    alpha3: 'AUS',
    'country-code': '036',
    name: 'Australia',
  },
  {
    alpha3: 'AUT',
    'country-code': '040',
    name: 'Austria',
  },
  {
    alpha3: 'AZE',
    'country-code': '031',
    name: 'Azerbaijan',
  },
  {
    alpha3: 'BHS',
    'country-code': '044',
    name: 'Bahamas',
  },
  {
    alpha3: 'BHR',
    'country-code': '048',
    name: 'Bahrain',
  },
  {
    alpha3: 'BGD',
    'country-code': '050',
    name: 'Bangladesh',
  },
  {
    alpha3: 'BRB',
    'country-code': '052',
    name: 'Barbados',
  },
  {
    alpha3: 'BLR',
    'country-code': '112',
    name: 'Belarus',
  },
  {
    alpha3: 'BEL',
    'country-code': '056',
    name: 'Belgium',
  },
  {
    alpha3: 'BLZ',
    'country-code': '084',
    name: 'Belize',
  },
  {
    alpha3: 'BEN',
    'country-code': '204',
    name: 'Benin',
  },
  {
    alpha3: 'BMU',
    'country-code': '060',
    name: 'Bermuda',
  },
  {
    alpha3: 'BTN',
    'country-code': '064',
    name: 'Bhutan',
  },
  {
    alpha3: 'BOL',
    'country-code': '068',
    name: 'Bolivia (Plurinational State of)',
  },
  {
    alpha3: 'BES',
    'country-code': '535',
    name: 'Bonaire, Sint Eustatius and Saba',
  },
  {
    alpha3: 'BIH',
    'country-code': '070',
    name: 'Bosnia and Herzegovina',
  },
  {
    alpha3: 'BWA',
    'country-code': '072',
    name: 'Botswana',
  },
  {
    alpha3: 'BVT',
    'country-code': '074',
    name: 'Bouvet Island',
  },
  {
    alpha3: 'BRA',
    'country-code': '076',
    name: 'Brazil',
  },
  {
    alpha3: 'IOT',
    'country-code': '086',
    name: 'British Indian Ocean Territory',
  },
  {
    alpha3: 'BRN',
    'country-code': '096',
    name: 'Brunei Darussalam',
  },
  {
    alpha3: 'BGR',
    'country-code': '100',
    name: 'Bulgaria',
  },
  {
    alpha3: 'BFA',
    'country-code': '854',
    name: 'Burkina Faso',
  },
  {
    alpha3: 'BDI',
    'country-code': '108',
    name: 'Burundi',
  },
  {
    alpha3: 'KHM',
    'country-code': '116',
    name: 'Cambodia',
  },
  {
    alpha3: 'CMR',
    'country-code': '120',
    name: 'Cameroon',
  },
  {
    alpha3: 'CAN',
    'country-code': '124',
    name: 'Canada',
  },
  {
    alpha3: 'CPV',
    'country-code': '132',
    name: 'Cabo Verde',
  },
  {
    alpha3: 'CYM',
    'country-code': '136',
    name: 'Cayman Islands',
  },
  {
    alpha3: 'CAF',
    'country-code': '140',
    name: 'Central African Republic',
  },
  {
    alpha3: 'TCD',
    'country-code': '148',
    name: 'Chad',
  },
  {
    alpha3: 'CHL',
    'country-code': '152',
    name: 'Chile',
  },
  {
    alpha3: 'CHN',
    'country-code': '156',
    name: 'China',
  },
  {
    alpha3: 'CXR',
    'country-code': '162',
    name: 'Christmas Island',
  },
  {
    alpha3: 'CCK',
    'country-code': '166',
    name: 'Cocos (Keeling) Islands',
  },
  {
    alpha3: 'COL',
    'country-code': '170',
    name: 'Colombia',
  },
  {
    alpha3: 'COM',
    'country-code': '174',
    name: 'Comoros',
  },
  {
    alpha3: 'COG',
    'country-code': '178',
    name: 'Congo',
  },
  {
    alpha3: 'COD',
    'country-code': '180',
    name: 'Congo (Democratic Republic of the)',
  },
  {
    alpha3: 'COK',
    'country-code': '184',
    name: 'Cook Islands',
  },
  {
    alpha3: 'CRI',
    'country-code': '188',
    name: 'Costa Rica',
  },
  {
    alpha3: 'CIV',
    'country-code': '384',
    name: "Côte d'Ivoire",
  },
  {
    alpha3: 'HRV',
    'country-code': '191',
    name: 'Croatia',
  },
  {
    alpha3: 'CUB',
    'country-code': '192',
    name: 'Cuba',
  },
  {
    alpha3: 'CUW',
    'country-code': '531',
    name: 'Curaçao',
  },
  {
    alpha3: 'CYP',
    'country-code': '196',
    name: 'Cyprus',
  },
  {
    alpha3: 'CZE',
    'country-code': '203',
    name: 'Czech Republic',
  },
  {
    alpha3: 'DNK',
    'country-code': '208',
    name: 'Denmark',
  },
  {
    alpha3: 'DJI',
    'country-code': '262',
    name: 'Djibouti',
  },
  {
    alpha3: 'DMA',
    'country-code': '212',
    name: 'Dominica',
  },
  {
    alpha3: 'DOM',
    'country-code': '214',
    name: 'Dominican Republic',
  },
  {
    alpha3: 'ECU',
    'country-code': '218',
    name: 'Ecuador',
  },
  {
    alpha3: 'EGY',
    'country-code': '818',
    name: 'Egypt',
  },
  {
    alpha3: 'SLV',
    'country-code': '222',
    name: 'El Salvador',
  },
  {
    alpha3: 'GNQ',
    'country-code': '226',
    name: 'Equatorial Guinea',
  },
  {
    alpha3: 'ERI',
    'country-code': '232',
    name: 'Eritrea',
  },
  {
    alpha3: 'EST',
    'country-code': '233',
    name: 'Estonia',
  },
  {
    alpha3: 'ETH',
    'country-code': '231',
    name: 'Ethiopia',
  },
  {
    alpha3: 'FLK',
    'country-code': '238',
    name: 'Falkland Islands (Malvinas)',
  },
  {
    alpha3: 'FRO',
    'country-code': '234',
    name: 'Faroe Islands',
  },
  {
    alpha3: 'FJI',
    'country-code': '242',
    name: 'Fiji',
  },
  {
    alpha3: 'FIN',
    'country-code': '246',
    name: 'Finland',
  },
  {
    alpha3: 'FRA',
    'country-code': '250',
    name: 'France',
  },
  {
    alpha3: 'GUF',
    'country-code': '254',
    name: 'French Guiana',
  },
  {
    alpha3: 'PYF',
    'country-code': '258',
    name: 'French Polynesia',
  },
  {
    alpha3: 'ATF',
    'country-code': '260',
    name: 'French Southern Territories',
  },
  {
    alpha3: 'GAB',
    'country-code': '266',
    name: 'Gabon',
  },
  {
    alpha3: 'GMB',
    'country-code': '270',
    name: 'Gambia',
  },
  {
    alpha3: 'GEO',
    'country-code': '268',
    name: 'Georgia',
  },
  {
    alpha3: 'DEU',
    'country-code': '276',
    name: 'Germany',
  },
  {
    alpha3: 'GHA',
    'country-code': '288',
    name: 'Ghana',
  },
  {
    alpha3: 'GIB',
    'country-code': '292',
    name: 'Gibraltar',
  },
  {
    alpha3: 'GRC',
    'country-code': '300',
    name: 'Greece',
  },
  {
    alpha3: 'GRL',
    'country-code': '304',
    name: 'Greenland',
  },
  {
    alpha3: 'GRD',
    'country-code': '308',
    name: 'Grenada',
  },
  {
    alpha3: 'GLP',
    'country-code': '312',
    name: 'Guadeloupe',
  },
  {
    alpha3: 'GUM',
    'country-code': '316',
    name: 'Guam',
  },
  {
    alpha3: 'GTM',
    'country-code': '320',
    name: 'Guatemala',
  },
  {
    alpha3: 'GGY',
    'country-code': '831',
    name: 'Guernsey',
  },
  {
    alpha3: 'GIN',
    'country-code': '324',
    name: 'Guinea',
  },
  {
    alpha3: 'GNB',
    'country-code': '624',
    name: 'Guinea-Bissau',
  },
  {
    alpha3: 'GUY',
    'country-code': '328',
    name: 'Guyana',
  },
  {
    alpha3: 'HTI',
    'country-code': '332',
    name: 'Haiti',
  },
  {
    alpha3: 'HMD',
    'country-code': '334',
    name: 'Heard Island and McDonald Islands',
  },
  {
    alpha3: 'VAT',
    'country-code': '336',
    name: 'Holy See',
  },
  {
    alpha3: 'HND',
    'country-code': '340',
    name: 'Honduras',
  },
  {
    alpha3: 'HKG',
    'country-code': '344',
    name: 'Hong Kong',
  },
  {
    alpha3: 'HUN',
    'country-code': '348',
    name: 'Hungary',
  },
  {
    alpha3: 'ISL',
    'country-code': '352',
    name: 'Iceland',
  },
  {
    alpha3: 'IND',
    'country-code': '356',
    name: 'India',
  },
  {
    alpha3: 'IDN',
    'country-code': '360',
    name: 'Indonesia',
  },
  {
    alpha3: 'IRN',
    'country-code': '364',
    name: 'Iran (Islamic Republic of)',
  },
  {
    alpha3: 'IRQ',
    'country-code': '368',
    name: 'Iraq',
  },
  {
    alpha3: 'IRL',
    'country-code': '372',
    name: 'Ireland',
  },
  {
    alpha3: 'IMN',
    'country-code': '833',
    name: 'Isle of Man',
  },
  {
    alpha3: 'ISR',
    'country-code': '376',
    name: 'Israel',
  },
  {
    alpha3: 'ITA',
    'country-code': '380',
    name: 'Italy',
  },
  {
    alpha3: 'JAM',
    'country-code': '388',
    name: 'Jamaica',
  },
  {
    alpha3: 'JPN',
    'country-code': '392',
    name: 'Japan',
  },
  {
    alpha3: 'JEY',
    'country-code': '832',
    name: 'Jersey',
  },
  {
    alpha3: 'JOR',
    'country-code': '400',
    name: 'Jordan',
  },
  {
    alpha3: 'KAZ',
    'country-code': '398',
    name: 'Kazakhstan',
  },
  {
    alpha3: 'KEN',
    'country-code': '404',
    name: 'Kenya',
  },
  {
    alpha3: 'KIR',
    'country-code': '296',
    name: 'Kiribati',
  },
  {
    alpha3: 'PRK',
    'country-code': '408',
    name: "Korea (Democratic People's Republic of)",
  },
  {
    alpha3: 'KOR',
    'country-code': '410',
    name: 'Korea (Republic of)',
  },
  {
    alpha3: 'KWT',
    'country-code': '414',
    name: 'Kuwait',
  },
  {
    alpha3: 'KGZ',
    'country-code': '417',
    name: 'Kyrgyzstan',
  },
  {
    alpha3: 'LAO',
    'country-code': '418',
    name: "Lao People's Democratic Republic",
  },
  {
    alpha3: 'LVA',
    'country-code': '428',
    name: 'Latvia',
  },
  {
    alpha3: 'LBN',
    'country-code': '422',
    name: 'Lebanon',
  },
  {
    alpha3: 'LSO',
    'country-code': '426',
    name: 'Lesotho',
  },
  {
    alpha3: 'LBR',
    'country-code': '430',
    name: 'Liberia',
  },
  {
    alpha3: 'LBY',
    'country-code': '434',
    name: 'Libya',
  },
  {
    alpha3: 'LIE',
    'country-code': '438',
    name: 'Liechtenstein',
  },
  {
    alpha3: 'LTU',
    'country-code': '440',
    name: 'Lithuania',
  },
  {
    alpha3: 'LUX',
    'country-code': '442',
    name: 'Luxembourg',
  },
  {
    alpha3: 'MAC',
    'country-code': '446',
    name: 'Macao',
  },
  {
    alpha3: 'MKD',
    'country-code': '807',
    name: 'Macedonia (the former Yugoslav Republic of)',
  },
  {
    alpha3: 'MDG',
    'country-code': '450',
    name: 'Madagascar',
  },
  {
    alpha3: 'MWI',
    'country-code': '454',
    name: 'Malawi',
  },
  {
    alpha3: 'MYS',
    'country-code': '458',
    name: 'Malaysia',
  },
  {
    alpha3: 'MDV',
    'country-code': '462',
    name: 'Maldives',
  },
  {
    alpha3: 'MLI',
    'country-code': '466',
    name: 'Mali',
  },
  {
    alpha3: 'MLT',
    'country-code': '470',
    name: 'Malta',
  },
  {
    alpha3: 'MHL',
    'country-code': '584',
    name: 'Marshall Islands',
  },
  {
    alpha3: 'MTQ',
    'country-code': '474',
    name: 'Martinique',
  },
  {
    alpha3: 'MRT',
    'country-code': '478',
    name: 'Mauritania',
  },
  {
    alpha3: 'MUS',
    'country-code': '480',
    name: 'Mauritius',
  },
  {
    alpha3: 'MYT',
    'country-code': '175',
    name: 'Mayotte',
  },
  {
    alpha3: 'MEX',
    'country-code': '484',
    name: 'Mexico',
  },
  {
    alpha3: 'FSM',
    'country-code': '583',
    name: 'Micronesia (Federated States of)',
  },
  {
    alpha3: 'MDA',
    'country-code': '498',
    name: 'Moldova (Republic of)',
  },
  {
    alpha3: 'MCO',
    'country-code': '492',
    name: 'Monaco',
  },
  {
    alpha3: 'MNG',
    'country-code': '496',
    name: 'Mongolia',
  },
  {
    alpha3: 'MNE',
    'country-code': '499',
    name: 'Montenegro',
  },
  {
    alpha3: 'MSR',
    'country-code': '500',
    name: 'Montserrat',
  },
  {
    alpha3: 'MAR',
    'country-code': '504',
    name: 'Morocco',
  },
  {
    alpha3: 'MOZ',
    'country-code': '508',
    name: 'Mozambique',
  },
  {
    alpha3: 'MMR',
    'country-code': '104',
    name: 'Myanmar',
  },
  {
    alpha3: 'NAM',
    'country-code': '516',
    name: 'Namibia',
  },
  {
    alpha3: 'NRU',
    'country-code': '520',
    name: 'Nauru',
  },
  {
    alpha3: 'NPL',
    'country-code': '524',
    name: 'Nepal',
  },
  {
    alpha3: 'NLD',
    'country-code': '528',
    name: 'Netherlands',
  },
  {
    alpha3: 'NCL',
    'country-code': '540',
    name: 'New Caledonia',
  },
  {
    alpha3: 'NZL',
    'country-code': '554',
    name: 'New Zealand',
  },
  {
    alpha3: 'NIC',
    'country-code': '558',
    name: 'Nicaragua',
  },
  {
    alpha3: 'NER',
    'country-code': '562',
    name: 'Niger',
  },
  {
    alpha3: 'NGA',
    'country-code': '566',
    name: 'Nigeria',
  },
  {
    alpha3: 'NIU',
    'country-code': '570',
    name: 'Niue',
  },
  {
    alpha3: 'NFK',
    'country-code': '574',
    name: 'Norfolk Island',
  },
  {
    alpha3: 'MNP',
    'country-code': '580',
    name: 'Northern Mariana Islands',
  },
  {
    alpha3: 'NOR',
    'country-code': '578',
    name: 'Norway',
  },
  {
    alpha3: 'OMN',
    'country-code': '512',
    name: 'Oman',
  },
  {
    alpha3: 'PAK',
    'country-code': '586',
    name: 'Pakistan',
  },
  {
    alpha3: 'PLW',
    'country-code': '585',
    name: 'Palau',
  },
  {
    alpha3: 'PSE',
    'country-code': '275',
    name: 'Palestine, State of',
  },
  {
    alpha3: 'PAN',
    'country-code': '591',
    name: 'Panama',
  },
  {
    alpha3: 'PNG',
    'country-code': '598',
    name: 'Papua New Guinea',
  },
  {
    alpha3: 'PRY',
    'country-code': '600',
    name: 'Paraguay',
  },
  {
    alpha3: 'PER',
    'country-code': '604',
    name: 'Peru',
  },
  {
    alpha3: 'PHL',
    'country-code': '608',
    name: 'Philippines',
  },
  {
    alpha3: 'PCN',
    'country-code': '612',
    name: 'Pitcairn',
  },
  {
    alpha3: 'POL',
    'country-code': '616',
    name: 'Poland',
  },
  {
    alpha3: 'PRT',
    'country-code': '620',
    name: 'Portugal',
  },
  {
    alpha3: 'PRI',
    'country-code': '630',
    name: 'Puerto Rico',
  },
  {
    alpha3: 'QAT',
    'country-code': '634',
    name: 'Qatar',
  },
  {
    alpha3: 'REU',
    'country-code': '638',
    name: 'Réunion',
  },
  {
    alpha3: 'ROU',
    'country-code': '642',
    name: 'Romania',
  },
  {
    alpha3: 'RUS',
    'country-code': '643',
    name: 'Russian Federation',
  },
  {
    alpha3: 'RWA',
    'country-code': '646',
    name: 'Rwanda',
  },
  {
    alpha3: 'BLM',
    'country-code': '652',
    name: 'Saint Barthélemy',
  },
  {
    alpha3: 'SHN',
    'country-code': '654',
    name: 'Saint Helena, Ascension and Tristan da Cunha',
  },
  {
    alpha3: 'KNA',
    'country-code': '659',
    name: 'Saint Kitts and Nevis',
  },
  {
    alpha3: 'LCA',
    'country-code': '662',
    name: 'Saint Lucia',
  },
  {
    alpha3: 'MAF',
    'country-code': '663',
    name: 'Saint Martin (French part)',
  },
  {
    alpha3: 'SPM',
    'country-code': '666',
    name: 'Saint Pierre and Miquelon',
  },
  {
    alpha3: 'VCT',
    'country-code': '670',
    name: 'Saint Vincent and the Grenadines',
  },
  {
    alpha3: 'WSM',
    'country-code': '882',
    name: 'Samoa',
  },
  {
    alpha3: 'SMR',
    'country-code': '674',
    name: 'San Marino',
  },
  {
    alpha3: 'STP',
    'country-code': '678',
    name: 'Sao Tome and Principe',
  },
  {
    alpha3: 'SAU',
    'country-code': '682',
    name: 'Saudi Arabia',
  },
  {
    alpha3: 'SEN',
    'country-code': '686',
    name: 'Senegal',
  },
  {
    alpha3: 'SRB',
    'country-code': '688',
    name: 'Serbia',
  },
  {
    alpha3: 'SYC',
    'country-code': '690',
    name: 'Seychelles',
  },
  {
    alpha3: 'SLE',
    'country-code': '694',
    name: 'Sierra Leone',
  },
  {
    alpha3: 'SGP',
    'country-code': '702',
    name: 'Singapore',
  },
  {
    alpha3: 'SXM',
    'country-code': '534',
    name: 'Sint Maarten (Dutch part)',
  },
  {
    alpha3: 'SVK',
    'country-code': '703',
    name: 'Slovakia',
  },
  {
    alpha3: 'SVN',
    'country-code': '705',
    name: 'Slovenia',
  },
  {
    alpha3: 'SLB',
    'country-code': '090',
    name: 'Solomon Islands',
  },
  {
    alpha3: 'SOM',
    'country-code': '706',
    name: 'Somalia',
  },
  {
    alpha3: 'ZAF',
    'country-code': '710',
    name: 'South Africa',
  },
  {
    alpha3: 'SGS',
    'country-code': '239',
    name: 'South Georgia and the South Sandwich Islands',
  },
  {
    alpha3: 'SSD',
    'country-code': '728',
    name: 'South Sudan',
  },
  {
    alpha3: 'ESP',
    'country-code': '724',
    name: 'Spain',
  },
  {
    alpha3: 'LKA',
    'country-code': '144',
    name: 'Sri Lanka',
  },
  {
    alpha3: 'SDN',
    'country-code': '729',
    name: 'Sudan',
  },
  {
    alpha3: 'SUR',
    'country-code': '740',
    name: 'Suriname',
  },
  {
    alpha3: 'SJM',
    'country-code': '744',
    name: 'Svalbard and Jan Mayen',
  },
  {
    alpha3: 'SWZ',
    'country-code': '748',
    name: 'Swaziland',
  },
  {
    alpha3: 'SWE',
    'country-code': '752',
    name: 'Sweden',
  },
  {
    alpha3: 'CHE',
    'country-code': '756',
    name: 'Switzerland',
  },
  {
    alpha3: 'SYR',
    'country-code': '760',
    name: 'Syrian Arab Republic',
  },
  {
    alpha3: 'TWN',
    'country-code': '158',
    name: 'Taiwan',
  },
  {
    alpha3: 'TJK',
    'country-code': '762',
    name: 'Tajikistan',
  },
  {
    alpha3: 'TZA',
    'country-code': '834',
    name: 'Tanzania, United Republic of',
  },
  {
    alpha3: 'THA',
    'country-code': '764',
    name: 'Thailand',
  },
  {
    alpha3: 'TLS',
    'country-code': '626',
    name: 'Timor-Leste',
  },
  {
    alpha3: 'TGO',
    'country-code': '768',
    name: 'Togo',
  },
  {
    alpha3: 'TKL',
    'country-code': '772',
    name: 'Tokelau',
  },
  {
    alpha3: 'TON',
    'country-code': '776',
    name: 'Tonga',
  },
  {
    alpha3: 'TTO',
    'country-code': '780',
    name: 'Trinidad and Tobago',
  },
  {
    alpha3: 'TUN',
    'country-code': '788',
    name: 'Tunisia',
  },
  {
    alpha3: 'TUR',
    'country-code': '792',
    name: 'Turkey',
  },
  {
    alpha3: 'TKM',
    'country-code': '795',
    name: 'Turkmenistan',
  },
  {
    alpha3: 'TCA',
    'country-code': '796',
    name: 'Turks and Caicos Islands',
  },
  {
    alpha3: 'TUV',
    'country-code': '798',
    name: 'Tuvalu',
  },
  {
    alpha3: 'UGA',
    'country-code': '800',
    name: 'Uganda',
  },
  {
    alpha3: 'UKR',
    'country-code': '804',
    name: 'Ukraine',
  },
  {
    alpha3: 'ARE',
    'country-code': '784',
    name: 'United Arab Emirates',
  },
  {
    alpha3: 'GBR',
    'country-code': '826',
    name: 'United Kingdom of Great Britain and Northern Ireland',
  },
  {
    alpha3: 'UMI',
    'country-code': '581',
    name: 'United States Minor Outlying Islands',
  },
  {
    alpha3: 'URY',
    'country-code': '858',
    name: 'Uruguay',
  },
  {
    alpha3: 'UZB',
    'country-code': '860',
    name: 'Uzbekistan',
  },
  {
    alpha3: 'VUT',
    'country-code': '548',
    name: 'Vanuatu',
  },
  {
    alpha3: 'VEN',
    'country-code': '862',
    name: 'Venezuela (Bolivarian Republic of)',
  },
  {
    alpha3: 'VNM',
    'country-code': '704',
    name: 'Viet Nam',
  },
  {
    alpha3: 'VGB',
    'country-code': '092',
    name: 'Virgin Islands (British)',
  },
  {
    alpha3: 'VIR',
    'country-code': '850',
    name: 'Virgin Islands (U.S.)',
  },
  {
    alpha3: 'WLF',
    'country-code': '876',
    name: 'Wallis and Futuna',
  },
  {
    alpha3: 'ESH',
    'country-code': '732',
    name: 'Western Sahara',
  },
  {
    alpha3: 'YEM',
    'country-code': '887',
    name: 'Yemen',
  },
  {
    alpha3: 'ZMB',
    'country-code': '894',
    name: 'Zambia',
  },
  {
    alpha3: 'ZWE',
    'country-code': '716',
    name: 'Zimbabwe',
  },
];
