// (C) Copyright 2016-2024 Hewlett Packard Enterprise Development LP

import * as c from '../../routes/consts.js';
import MetaBuilder, { SMALL } from '../../containers/generic/MetaBuilder';
import ItemViewContainer from '../../containers/generic/ItemViewContainer';
import CreateViewContainer from '../../containers/generic/CreateViewContainer';
import EditViewContainer from '../../containers/generic/EditViewContainer';
import { LABEL_TAGS } from '../../components/HybridNav/consts.js';

const EXTERNAL_NAME = c.URL_TAGS;

export const settings = {
  authUrl: EXTERNAL_NAME, // used to filter create/trash icons from the view; using the defined roledef permissions mappings to this auth URL entry
  homeUrl: c.makeSecUrl(EXTERNAL_NAME), // homepage for this list view (e.g. /s/hosters/:pid/) is where this view is located; for return from create / item views
  homeLabel: LABEL_TAGS,
  baseUrl: EXTERNAL_NAME, // base url to be used for creating all associated URLS for this reasource, e.g. pageItem, pageCreate, restUrl, restItemUrl
  stateKey: EXTERNAL_NAME,
};

function metaCreate(props) {
  const m = new MetaBuilder(props);

  m.addColumn('name', 'Name').Default().Input().Required().MaxWidth(SMALL);
  m.addColumn('purpose', 'Purpose')
    .Default()
    .Input()
    .Required()
    .MaxWidth(SMALL);

  return m;
}

export { default as TagListView } from './TagListView';

export const TagItemView = ItemViewContainer({
  ...settings,
  allowEdit: true,
  meta: (props) => metaCreate(props), // use edit meta
  title: 'Tag',
});

export const TagCreateView = CreateViewContainer({
  ...settings,
  meta: (props) => metaCreate(props),
  title: 'Create tag',
});

export const TagEditView = EditViewContainer({
  ...settings,
  meta: (props) => metaCreate(props),
  title: 'Edit tag',
});
