// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { HorizontalSelect } from '../HorizontalSelect';
import { Image } from './Image';

// biome-ignore lint/style/useDefaultParameterLast: <explanation>
const processImages = (images = [], value) => {
  // make a set of unique flavor names
  const flavorSet = images.reduce(
    (prev, { flavor }) => ({
      // biome-ignore lint/performance/noAccumulatingSpread: <explanation>
      ...prev,
      [flavor]: true,
    }),
    {},
  );

  // make one option for each unique flavor
  return Object.keys(flavorSet)
    .map((flavor) => {
      const flavorImages = images
        .filter((image) => image.flavor === flavor)
        .sort((a, b) =>
          a.version.localeCompare(b.version, undefined, {
            sensitivity: 'base',
          }),
        );

      const selectedImage =
        value && flavorImages.includes(value) ? value : flavorImages[0];
      return {
        flavor,
        id: flavor,
        images: flavorImages,
        selectedImage,
      };
    })
    .sort((a, b) =>
      a.flavor.localeCompare(b.flavor, undefined, { sensitivity: 'base' }),
    );
};

export const ImageSelect = ({ images, onChange, value, ...rest }) => {
  const flavors = useMemo(() => processImages(images, value), [images, value]);

  const onFlavorChange = (evt) => {
    if (onChange) {
      onChange({ value: evt.value?.selectedImage });
    }
  };

  const valueOpt = value && flavors.find((opt) => opt.selectedImage === value);

  return (
    <HorizontalSelect
      options={flavors}
      ValueComponent={Image}
      onChange={onFlavorChange}
      value={valueOpt}
      {...rest}
    />
  );
};

ImageSelect.propTypes = {
  value: PropTypes.string,
  images: PropTypes.array,
  onChange: PropTypes.func,
};
