// (C) Copyright 2020-2024 Hewlett Packard Enterprise Development LP

import React from 'react';
import PropTypes from 'prop-types';
import isArray from 'lodash/isArray';
import { Box, Text, FormField, TextInput, MaskedInput } from 'grommet';
import { Link } from '../../../components';
import * as c from '../../../routes/consts.js';
import FormReadOnlyField from './FormReadOnlyField';

const FormText = ({
  col,
  value,
  prefix,
  onChange,
  validationResult = {},
  ...rest
}) => {
  let content = value;
  // read-only
  if (col.isReadOnly) {
    if (col.makeLink) {
      let baseUrl;
      let url;

      if (col.makeLinkExt) {
        return (
          <FormReadOnlyField label={col.displayName}>
            <Text id={col.keyPath} key={col.keyPath} color='text-strong'>
              {prefix}
              <Link
                external={true}
                to={`${col.makeLinkBaseUrl}://${value}`}
                title={value}
                target='_blank'
              />
              ,
            </Text>
          </FormReadOnlyField>
        );
      }

      const { source_id, source_type } = col.mb.dataSources[0].Data() || {};

      if (
        source_id === 'SYSTEM' &&
        (source_type === 'projects' || col.makeLinkBaseUrl === 'projects')
      ) {
        return (
          <FormReadOnlyField label={col.displayName}>
            <Text id={col.keyPath} key={col.keyPath} color='text-strong'>
              {prefix}
              {content}
            </Text>
          </FormReadOnlyField>
        );
      }

      if (typeof col.makeLinkBaseUrl === 'function') {
        baseUrl = col.makeLinkBaseUrl(source_type);
        url = c.setId(c.makeSecItemUrl(baseUrl), source_id);
      } else {
        baseUrl = c.makeSecItemUrl(col.makeLinkBaseUrl);
      }

      if (!isArray(value)) {
        if (!url) {
          url = c.setId(baseUrl, value);
        }

        content = <Link to={url} title={value} />;
      } else {
        content = [];

        for (let i = 0; i < value.length; i++) {
          if (value[i] === 'SYSTEM') {
            content.push(
              <Text color='text-strong' key={i}>
                {value[i]}
              </Text>,
            );
          } else {
            content.push(
              <Link
                to={c.setId(baseUrl, value[i])}
                key={i}
                title={value[i]}
                style={{
                  marginRight: i !== value.length - 1 ? '.5rem' : '0rem',
                }}
              />,
            );
          }
        }
      }
    } else if (isArray(value)) {
      content = value.join(', ');
    }

    return (
      <FormReadOnlyField label={col.displayName}>
        <Text id={col.keyPath} key={col.keyPath} color='text-strong'>
          {prefix}
          {content}
        </Text>
      </FormReadOnlyField>
    );
  }

  const evaluateLabel = (col) => {
    if (col.isRequired || col.fRequired?.()) {
      return `${col.displayName}*`;
    }
    return col.displayName;
  };

  const inputStyle = { style: {} };

  if (col.maxWidth) {
    inputStyle.style.maxWidth = col.maxWidth;
  }

  return (
    <FormField
      label={evaluateLabel(col)}
      info={col.info}
      name={col.keyPath}
      error={validationResult.msg}
      htmlFor={col.keyPath}
      style={{ gridColumn: '1 / span 2', ...inputStyle.style }}
    >
      {col.mask ? (
        <MaskedInput
          id={col.keyPath}
          key={col.keyPath}
          name={col.keyPath}
          onChange={onChange}
          mask={col.mask}
          value={content}
          {...rest}
        />
      ) : (
        <Box direction='row'>
          {prefix && (
            <Box
              flex={false}
              justify='center'
              background='background-contrast'
              pad={{ horizontal: 'small' }}
            >
              {prefix}
            </Box>
          )}
          <TextInput
            plain
            id={col.keyPath}
            key={col.keyPath}
            name={col.keyPath}
            onChange={onChange}
            placeholder={col.inputDefaultValue}
            value={content}
            {...rest}
          />
        </Box>
      )}
    </FormField>
  );
};

FormText.propTypes = {
  col: PropTypes.shape({
    displayName: PropTypes.string.isRequired,
    makeLink: PropTypes.bool,
    makeLinkBaseUrl: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    keyPath: PropTypes.string,
    inputDefaultValue: PropTypes.any,
  }).isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func,
  prefix: PropTypes.string,
  validationResult: PropTypes.shape({
    msg: PropTypes.string,
  }),
};

export default FormText;
