// (C) Copyright 2021-2022, 2024 Hewlett Packard Enterprise Development LP

import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Anchor, Box } from 'grommet';
import { Share } from 'grommet-icons';
import IconButton from './generic/IconButton';
import TruncateText from './Griddle/TruncateText';

const Link = ({ external, to, title, icon, children, ...rest }) => {
  const history = useHistory();

  const onClick = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();

    if (!external) {
      history.push(to);
    } else {
      const newWindow = window.open(to);
      newWindow.opener = null;
    }
  };

  return icon ? (
    <IconButton
      icon={icon}
      size='small'
      style={{ width: '26px' }}
      onClick={onClick}
      title={title}
      tip={title}
      href={to}
      {...rest}
    />
  ) : (
    <Anchor color='brand' href={to} onClick={onClick} {...rest}>
      <Box align='center' direction='row' gap='xsmall'>
        <TruncateText content={title} />
        {external && (
          <Share
            color='brand'
            style={{
              height: 16,
              width: 16,
              marginBottom: 3,
            }}
          />
        )}
      </Box>
      {children}
    </Anchor>
  );
};

Link.propTypes = {
  to: PropTypes.string,
  title: PropTypes.string,
  icon: PropTypes.node,
  children: PropTypes.node,
};

export default Link;
