// (C) Copyright 2022-2024 Hewlett Packard Enterprise Development LP

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, FormField, TextInput, Text } from 'grommet';

const defaultForm = {
  client: '',
  name: '',
  provider: '',
};

const serviceNetworkTypes = ['service-vlan', 'service-vni'];

const NamesField = (props) => {
  const { col, validationResult } = props;

  const isView = useMemo(() => props.col.mb.props.mode === 'view', [props]);
  const [form, setForm] = useState(defaultForm);
  const [changed, setChanged] = useState(false);

  const isMulti = useMemo(() => {
    const { type } = props.data;

    return serviceNetworkTypes.includes(type);
  }, [props]);

  const isServiceNetworkEdit = useMemo(() => {
    const { id, type } = props.data;

    return !!id && serviceNetworkTypes.includes(type);
  }, [props]);

  const setParentFormValue = useCallback(
    (key, value) => col.mb.view.setFormValue(key, value),
    [col.mb.view],
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    const { name } = props.data;

    if (name) {
      setForm({
        client: `${name}`,
        name,
        provider: `${name}-Provider`,
      });
    }
  }, []);

  const handleNameChange = useCallback(
    ({ target: { value } }) => {
      setForm((prevForm) => {
        const newForm = {
          ...prevForm,
          name: value,
        };

        if (!changed) {
          newForm.provider = `${value}-Provider`;
          newForm.client = `${value}`;
        }

        setParentFormValue('name', value);

        if (newForm.provider && newForm.client) {
          setParentFormValue('multi.provider.name', newForm.provider);
          setParentFormValue('multi.client.name', newForm.client);
        }

        return newForm;
      });
    },
    [changed, setParentFormValue],
  );

  const handleSubNameChange =
    (key) =>
    ({ target: { value } }) => {
      if (!changed) {
        setChanged(true);
      }

      setForm((prevForm) => {
        const newForm = {
          ...prevForm,
          [key]: value,
        };

        setParentFormValue(`multi.${key}.name`, value);

        return newForm;
      });
    };

  return !isView ? (
    <Box style={{ gridColumn: '1 / span 2', width: '400px' }}>
      <FormField error={validationResult.msg} label='Name' required>
        <TextInput onChange={handleNameChange} value={form.name} />
      </FormField>
      {isMulti && !isServiceNetworkEdit && (
        <Box
          border
          margin={{ top: '-7px' }}
          pad={{ horizontal: 'small', vertical: 'xsmall' }}
          round='4px'
        >
          <FormField error={validationResult.msg.name} label='Name-Client'>
            <TextInput
              onChange={handleSubNameChange('client')}
              value={form.client}
            />
          </FormField>
          <FormField error={validationResult.msg.name} label='Name-Provider'>
            <TextInput
              onChange={handleSubNameChange('provider')}
              value={form.provider}
            />
          </FormField>
        </Box>
      )}
    </Box>
  ) : (
    <Box style={{ gridColumn: '1 / span 2' }}>
      <div
        style={{
          display: 'grid',
          'box-sizing': 'border-box',
          width: '100%',
          'grid-template-columns': 'auto 1fr',
          gap: '160px',
        }}
      >
        <Text
          style={{
            'font-size': '18px',
            'line-height': '24px',
            'font-weight': '600',
          }}
        >
          Name
        </Text>
        <Text
          style={{
            'font-size': '18px',
            'line-height': '24px',
          }}
        >
          {form.name}
        </Text>
      </div>
    </Box>
  );
};

export default NamesField;

NamesField.propTypes = {
  col: PropTypes.object,
  data: PropTypes.object,
  validationResult: PropTypes.object,
};
