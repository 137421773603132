// (C) Copyright 2022-2024 Hewlett Packard Enterprise Development LP

import { useQueries } from 'react-query';

import { get } from '../lib/rest';
import { toDateTime } from '../lib/formatters';
import { makeRestUrl, URL_ARRAYS, URL_PODS, URL_RACKS } from '../routes/consts';
import { getDefaultQueriesResponse, renderLink, Status } from './helper';
import HealthStatus from '../components/HealthStatus';
import { getAlerts } from './alerts';
import TruncateText from '../components/Griddle/TruncateText';

const columns = () => [
  {
    header: 'Alerts',
    isDefault: true,
    property: 'alerts',
    render: ({ alerts: { content, status, tooltip } }) => (
      <Status content={content} status={status} tooltip={tooltip} />
    ),
  },
  {
    header: 'Health',
    isDefault: true,
    property: 'health_status',
    render: ({ health_status: status }) => <HealthStatus status={status} />,
  },
  {
    header: 'Name',
    isDefault: true,
    property: 'name',
    render: renderLink({
      base: URL_ARRAYS,
    }),
  },
  {
    header: 'ID',
    property: 'id',
    render: ({ id }) => <TruncateText content={id} />,
  },
  {
    header: 'Created',
    property: 'created',
    render: ({ created }) => toDateTime(created),
  },
  {
    header: 'Modified',
    property: 'modified',
    render: ({ modified }) => toDateTime(modified),
  },
  {
    header: 'Pod ID',
    property: 'podID',
    render: renderLink({
      base: URL_PODS,
      idKey: 'podID',
      titleKey: 'podID',
    }),
  },
  {
    header: 'Rack ID',
    property: 'rackID',
    render: renderLink({
      base: URL_RACKS,
      idKey: 'rackID',
      titleKey: 'rackID',
    }),
  },
  {
    header: 'Array connection',
    property: 'acs',
  },
  {
    header: 'Driver',
    isDefault: true,
    property: 'driver',
  },
  {
    header: 'Last seen',
    isDefault: true,
    property: 'lastSeen',
    render: ({ lastSeen }) => toDateTime(lastSeen),
  },
];

export const getArrays = async () => {
  const url = makeRestUrl(URL_ARRAYS);
  const response = await get(url);

  return response.json();
};

export const useGetArrays = () => {
  const queryResults = useQueries([
    {
      queryKey: URL_ARRAYS,
      queryFn: getArrays,
    },
    {
      queryKey: 'ARRAY_ALERTS',
      queryFn: () => getAlerts({ source_type: 'arrays' }),
    },
  ]);

  const isLoaded = queryResults.every(
    ({ error, isLoading }) => !isLoading && !error,
  );

  if (isLoaded) {
    const [arrays, alerts] = queryResults;

    return {
      columns: columns()
        // adding columnName and displayName for backwards compatibility
        .map((column) => ({
          ...column,
          columnName: column.property,
          displayName: column.header,
        })),
      data: arrays.data.map((array) => {
        const filteredAlerts = alerts.data.filter(
          ({ source_id: sourceId }) => sourceId === array.id,
        );
        const count = filteredAlerts.length;
        const content = count || '';
        const status = count ? 'status-warning' : '';
        const tooltip = count
          ? `${count} active alert${count > 1 ? 's' : ''}`
          : '';

        return {
          alerts: {
            content,
            status,
            tooltip,
          },
          ...array,
        };
      }),
      isLoading: false,
      refetch: () => {
        arrays.refetch();
        alerts.refetch();
      },
      error: null,
    };
  }

  return getDefaultQueriesResponse(queryResults);
};
