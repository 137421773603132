// (C) Copyright 2022-2024 Hewlett Packard Enterprise Development LP

import React from 'react';

import ListPageWithNav from '../../containers/generic/ListPageWithNav';
import { URL_PORTALS } from '../../routes/consts';
import { useGetPortals } from '../../services/portals';
import { NAV_TEAMS_PORTAL } from '../../components/HybridNav/consts.js';

const PortalListView = () => (
  <ListPageWithNav
    navSection={NAV_TEAMS_PORTAL}
    path={URL_PORTALS}
    sort={{
      property: 'name',
      direction: 'asc',
    }}
    title='Portals'
    useGetData={useGetPortals}
  />
);

export default PortalListView;
