// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import React, { useContext, useEffect, useState } from 'react';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import sortBy from 'lodash/sortBy';
import { Box, Grid, ThemeContext, Select, Text, Meter } from 'grommet';
import { PieChart, BarChart } from 'grommet-icons';

import {
  Bar,
  DashboardPanel,
  Doughnut,
  Pie,
  View,
  ViewHeader,
} from '../../components';
import Legends from '../../components/Legends';
import { useGetDashboard } from '../../services/dashboard';
import { useMembershipContext } from '../../utils/context/membershipContext';

const MAX_SLICES = 4;

const locations = undefined; // TBD

const LocationSelect = () =>
  !locations ? null : (
    <Box flex={false}>
      <Select
        labelKey='label'
        valueKey='id'
        options={[
          { label: 'All locations', id: '__all__' },
          ...locations.items,
        ]}
        value={{ label: 'All locations', id: '__all__' }}
        plain
      />
    </Box>
  );

const MachineSizeUsage = ({ data }) => {
  if (!data?.summary) {
    return null;
  }

  const { allocated, other, total, unallocated } = data.summary;

  return (
    <DashboardPanel
      title='Machine size usage'
      data={data}
      subtitle={`${total} machines`}
    >
      <Legends
        margin={{ bottom: 'small', top: '-20px' }}
        values={[
          { label: 'Allocated', color: 'graph-2', value: allocated },
          { label: 'Others', color: 'graph-0', value: other },
          { label: 'Unallocated', color: 'graph-5', value: unallocated },
        ]}
      />
      <Box gap='small' fill='horizontal'>
        {data.items?.map(({ label, total: max, values }, index) => (
          <Box
            key={`size${index}`}
            align='stretch'
            justify='start'
            direction='column'
            fill='horizontal'
          >
            <Box align='center' direction='row' gap='small' justify='between'>
              <Text style={{ minWidth: '20px' }} textAlign='end'>
                {values.find((val) => val.name === 'Allocated')?.value}
              </Text>
              <Text weight='bold' color='text-strong'>
                {label}
              </Text>
              <Box flex />
              <Text>
                {values.find((val) => val.name === 'Unallocated')?.value}
              </Text>
            </Box>
            <Meter
              max={max}
              background='background-contrast'
              size='full'
              thickness='16px'
              type='bar'
              values={values}
              style={{ borderRadius: '4px' }}
            />
          </Box>
        ))}
      </Box>
    </DashboardPanel>
  );
};

const projectUsage = (item, attribute) => {
  let values = [];
  let total = 0;

  if (item.projects !== undefined) {
    let sProjects = sortBy(item.projects, [attribute])
      .reverse()
      .filter((x) => x[attribute] > 0);
    // summarize any entries over 'max'
    if (sProjects.length > MAX_SLICES + 1) {
      const tail = sProjects.slice(MAX_SLICES);
      sProjects = sProjects.slice(0, MAX_SLICES);
      const summary = {
        name: 'Others',
        id: 'others',
      };
      summary[attribute] = tail
        .map((x) => x[attribute])
        .reduce((a, x) => (a += x));
      sProjects.push(summary);
    }

    values = sProjects.map((x) => {
      total += x[attribute];
      return { label: x.name, value: x[attribute] };
    });
  }

  return {
    total,
    values,
  };
};

const setupSizeUsage = ({
  machine_pool_summary: machinePoolSummary,
  projects,
}) => {
  if (!projects || !machinePoolSummary) {
    return {};
  }

  const { machine_pools: items, summary } = machinePoolSummary;
  const body = items.slice(0, MAX_SLICES);
  const tail = items.slice(MAX_SLICES);

  if (projects?.length && tail.length) {
    // summarize any entries over 'max'
    const tailSummary = tail.reduce(
      (acc, cur) => ({
        name: 'Others',
        id: 'others',
        total: acc.total + cur.total,
        allocated: acc.allocated + cur.allocated,
        unallocated: acc.unallocated + cur.unallocated,
        other: acc.other + cur.other,
      }),
      {
        allocated: 0,
        other: 0,
        total: 0,
        unallocated: 0,
      }
    );

    body.push(tailSummary);
  }

  const values = body.map((item) => ({
    label: item.name,
    total: item.total,
    values: [
      { name: 'Allocated', value: item.allocated, color: 'graph-2' },
      { name: 'Others', value: item.other, color: 'graph-0' },
      { name: 'Unallocated', value: item.unallocated, color: 'graph-5' },
    ],
  }));

  return {
    summary,
    total: summary.total,
    values,
    items: values,
  };
};

// setup data for machine inventory doughnut graph
const setupData1 = ({ machine_pool_summary: machinePoolSummary }) => {
  if (!machinePoolSummary) {
    return {};
  }

  const {
    summary: { allocated, other, total, unallocated },
  } = machinePoolSummary;

  return {
    total,
    values: [
      { label: 'Allocated', value: allocated, color: 'graph-2' },
      { label: 'Unallocated', value: unallocated, color: 'graph-5' },
      { label: 'Other', value: other, color: 'graph-3' },
    ],
  };
};

// setup data for hosts by project pie chart
const setupData2 = (item) => {
  return projectUsage(item, 'num_hosts');
};

// setup data for volume usage pie chart
const setupHosterVolumeUsage = (item) => {
  const total = get(item, 'volume_pool.total', 0);
  const allocated = get(item, 'volume_pool.allocated', 0);

  return {
    total,
    values: [
      { label: 'Allocated', value: allocated, color: 'graph-2' },
      { label: 'Unallocated', value: total - allocated, color: 'graph-5' },
    ],
  };
};

// setup data for volume capacity usage pie chart
const setupHosterVolumeCapacityUsage = (item) => {
  const total = get(item, 'volume_pool.totalCapacity', 0);
  const allocated = get(item, 'volume_pool.allocatedCapacity', 0);

  return {
    total,
    values: [
      { label: 'Used', value: allocated },
      { label: 'Free', value: total - allocated },
    ],
  };
};

// setup data for volumes by project pie chart
const setupProjectVolumeUsage = (item) => projectUsage(item, 'num_volumes');

// setup data for capacity by project pie chart
const setupProjectCapacityUsage = (item) =>
  projectUsage(item, 'usedVolumeCapacity');

const HosterDashboard = () => {
  const theme = useContext(ThemeContext);
  const { activeMembership } = useMembershipContext();
  const { data } = useGetDashboard(activeMembership);

  const [data1, setData1] = useState(() => setupData1({}));
  const [data2, setData2] = useState(() => setupData2({}));
  const [volumeUsage, setVolumeUsage] = useState(() =>
    setupHosterVolumeUsage({})
  );
  const [storageCapacityUsage, setStorageCapacityUsage] = useState(() =>
    setupHosterVolumeCapacityUsage({})
  );
  const [volumeProjectUsage, setVolumeProjectUsage] = useState(() =>
    setupProjectVolumeUsage({})
  );
  const [storageCapacityByProject, setStorageCapacityByProject] = useState(() =>
    setupProjectCapacityUsage({})
  );
  const [sizeUsage, setSizeUsage] = useState(() => setupSizeUsage({}));

  useEffect(() => {
    if (!isNil(data)) {
      setData1(setupData1(data));
      setData2(setupData2(data));
      setVolumeUsage(setupHosterVolumeUsage(data));
      setStorageCapacityUsage(setupHosterVolumeCapacityUsage(data));
      setVolumeProjectUsage(setupProjectVolumeUsage(data));
      setStorageCapacityByProject(setupProjectCapacityUsage(data));
      setSizeUsage(setupSizeUsage(data));
    }
  }, [data, theme]);

  return (
    <View>
      <ViewHeader title controls={<LocationSelect />}>
        Overview
      </ViewHeader>
      <div>
        <Grid
          gap='medium'
          align='start'
          columns={{ count: 'fill', size: '392px' }}
        >
          <DashboardPanel
            title='Machine inventory'
            data={data1}
            subtitle={`${data1.total} machines`}
            views={[
              { id: 'pie', Icon: PieChart, content: Doughnut },
              { id: 'bar', Icon: BarChart, content: Bar },
            ]}
            defaultView='bar'
            storageKey='machine_inventory_view'
          />

          <DashboardPanel
            title='Hosts by project'
            data={data2}
            subtitle={`${data2.total} hosts`}
            views={[
              { id: 'pie', Icon: PieChart, content: Pie },
              { id: 'bar', Icon: BarChart, content: Bar },
            ]}
            defaultView='bar'
            storageKey='hosts_by_project_view'
          />

          <MachineSizeUsage data={sizeUsage} />

          <DashboardPanel
            title='Volume usage'
            data={volumeUsage}
            subtitle={`${volumeUsage.total} volumes`}
            views={[
              { id: 'pie', Icon: PieChart, content: Doughnut },
              { id: 'bar', Icon: BarChart, content: Bar },
            ]}
            defaultView='bar'
            storageKey='volumes_view'
          />

          <DashboardPanel
            title='Volumes by project'
            data={volumeProjectUsage}
            subtitle={`${volumeProjectUsage.total} volumes`}
            views={[
              { id: 'pie', Icon: PieChart, content: Pie },
              { id: 'bar', Icon: BarChart, content: Bar },
            ]}
            defaultView='bar'
            storageKey='volumes_by_project_view'
          />

          <DashboardPanel
            title='Storage capacity'
            data={storageCapacityUsage}
            subtitle={`${storageCapacityUsage.total} GiB storage`}
            views={[
              { id: 'pie', Icon: PieChart, content: Doughnut },
              { id: 'bar', Icon: BarChart, content: Bar },
            ]}
            defaultView='bar'
            storageKey='storage_capacity_view'
          />

          <DashboardPanel
            title='Storage capacity by project'
            data={storageCapacityByProject}
            subtitle={`${storageCapacityByProject.total} GiB storage`}
            views={[
              { id: 'pie', Icon: PieChart, content: Pie },
              { id: 'bar', Icon: BarChart, content: Bar },
            ]}
            defaultView='bar'
            storageKey='storage_capacity_by_project_view'
          />
        </Grid>
      </div>
    </View>
  );
};

export default HosterDashboard;
