// (C) Copyright 2022-2024 Hewlett Packard Enterprise Development LP

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { Box, Button, CheckBox, Drop, FormField, Select, Text } from 'grommet';
import { FormAdd, FormClose, Trash } from 'grommet-icons';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import styled from 'styled-components';
import { isNil } from 'lodash';
import useSwitchTypes from '../../../utils/hooks/useSwitchTypes';
import { POD_TYPE_L2 } from '../../../data/pod';

const dropFieldMap = {
  'Uplink Ports': 'uplink',
  'Server Ports': 'servers',
};

const Value = ({ disabled, handleUnselect, index, label, onDrop, value }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'port',
    item: {
      index,
      label,
      value,
    },
    canDrag: () => !disabled,
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();

      if (item && dropResult) {
        onDrop(index, item, dropResult);
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  const handleClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    handleUnselect();
  };

  return (
    <Box
      align='center'
      background='selected-background'
      direction='row'
      justify='between'
      margin='xsmall'
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
      pad={{ vertical: 'xxsmall', left: 'small', right: 'xsmall' }}
      ref={drag}
      round='xsmall'
      style={{
        cursor: disabled ? 'normal' : 'move',
        opacity: isDragging ? 0.5 : 1,
        minWidth: 66,
      }}
    >
      <Text textAlign='center' style={{ minWidth: 20 }}>
        {value}
      </Text>
      {!disabled ? (
        <Button
          icon={<FormClose />}
          onClick={handleClick}
          style={{
            height: 'auto',
            padding: 0,
            width: 'auto',
          }}
        />
      ) : null}
    </Box>
  );
};

Value.propTypes = {
  disabled: PropTypes.bool,
  handleUnselect: PropTypes.func,
  index: PropTypes.number,
  label: PropTypes.string,
  onDrop: PropTypes.func,
  value: PropTypes.string,
};

const MultiCheckboxSelect = ({
  disabled,
  index,
  label,
  options = [],
  onChange,
  onDrop,
  usedPorts,
  values = [],
}) => {
  const [showDrop, setShowDrop] = useState(false);
  const targetRef = useRef();
  const [{ canDrop }, drop] = useDrop(() => ({
    accept: 'port',
    drop: () => ({
      index,
      label,
    }),
    canDrop: (item) => item.index !== index || item.label !== label,
    collect: (monitor) => ({
      canDrop: monitor.canDrop(),
    }),
  }));

  return (
    <Box fill='horizontal' ref={drop}>
      <FormField label={label} disabled={disabled}>
        <Box
          direction='row'
          onClick={() => {
            if (!disabled) {
              setShowDrop(true);
            }
          }}
          ref={targetRef}
          style={{
            boxShadow: canDrop ? '0 0 2px 2px #00e8cf' : 'none',
            minHeight: 42,
          }}
          wrap
        >
          {values.map((value) => (
            <Value
              disabled={disabled}
              handleUnselect={onChange(value)}
              index={index}
              key={value}
              label={label}
              onDrop={onDrop}
              value={value}
            />
          ))}
        </Box>
      </FormField>
      {showDrop && (
        <Drop
          align={{ top: 'bottom', left: 'left' }}
          onClickOutside={() => setShowDrop(false)}
          onClose={() => {}}
          target={targetRef.current}
        >
          <Box pad='xxsmall'>
            {options
              .filter((option) => !usedPorts.includes(option))
              .map((option) => (
                <CheckBox
                  key={option}
                  label={option}
                  onChange={onChange(option)}
                  checked={values.includes(option)}
                />
              ))}
          </Box>
        </Drop>
      )}
    </Box>
  );
};

MultiCheckboxSelect.propTypes = {
  disabled: PropTypes.bool,
  index: PropTypes.number,
  label: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func,
  onDrop: PropTypes.func,
  usedPorts: PropTypes.array,
  values: PropTypes.array,
};

const defaultPort = {
  speed: '',
  ports: [],
};

const defaultForm = {
  servers: [defaultPort],
  uplink: defaultPort,
};

const Container = styled(Box)`
  grid-column: 1 / span 2;

  .server-row:not(:first-child) {
    label {
      display: none;
    }
  }
`;

const FormPortUsage = (props) => {
  const [form, setForm] = useState(defaultForm);
  const [isDirty, setIsDirty] = useState(false);
  const isView = useMemo(() => props.col.mb.props.mode === 'view', [props]);

  useEffect(() => {
    if (!isView && isDirty) {
      const payload = {
        server_ports: form.servers,
        uplink_ports: [form.uplink],
      };

      props.col.mb.view.setFormValue('port_usage2', payload);
    }
  }, [form, isDirty, isView, props.col.mb.view]);

  const isL2 = useMemo(() => {
    const parentForm = props.col.mb.view.getForm();

    const fabricType = parentForm.fabric_type;

    return fabricType === POD_TYPE_L2;
  }, [props]);

  const switchSet = useMemo(() => {
    const switchSets = props.col.mb.view.getForm();
    return switchSets || {};
  }, [props]);

  const switchTypes = useMemo(
    () => props.col?.customComponentMetadata?.dsSwitchTypes.data || [],
    [props],
  );

  const availableSwitchTypes = useMemo(() => {
    const rackSwitchTypeIds = switchSet.switch_types || [];

    if (!isNil(switchTypes)) {
      return rackSwitchTypeIds.map((switchTypeId) =>
        switchTypes.find(({ id }) => id === switchTypeId),
      );
    }
    return rackSwitchTypeIds;
  }, [switchSet.switch_types, switchTypes]);

  const usedPorts = useMemo(
    () => [
      ...form.servers.reduce((acc, { ports }) => [...acc, ...ports], []),
      ...form.uplink.ports,
    ],
    [form],
  );

  const usedSpeeds = useMemo(
    () => [...form.servers.map(({ speed }) => speed), ...form.uplink.speed],
    [form],
  );

  const { availablePorts, availableSpeeds } = useSwitchTypes(
    availableSwitchTypes,
    usedPorts,
  );

  const speedOptions = useMemo(
    () =>
      availableSpeeds.map((speed) => ({
        disabled: usedSpeeds.includes(speed),
        label: speed,
        value: speed,
      })),
    [availableSpeeds, usedSpeeds],
  );

  useEffect(() => {
    if (
      availableSwitchTypes.length === 0 ||
      (availableSwitchTypes.length > 1 && speedOptions.length <= 0)
    ) {
      setForm(defaultForm);
    }
  }, [availableSwitchTypes, speedOptions]);

  const sortPorts = useCallback(
    (ports) =>
      ports
        ? ports.sort((a, b) => {
            const [parentA, childA] = a.split(':');
            const [parentB, childB] = b.split(':');
            const portA = `${parentA.split('/').join('')}${childA || ''}`;
            const portB = `${parentB.split('/').join('')}${childB || ''}`;

            return portA - portB;
          })
        : [],
    [],
  );

  const sortForm = useCallback(
    (formData) => ({
      servers: formData.servers
        .sort((a, b) => {
          const speedA = a.speed.slice(0, -2);
          const speedB = b.speed.slice(0, -2);

          return speedA - speedB;
        })
        .map(({ speed, ports }) => ({
          speed,
          ports: ports?.length > 0 ? sortPorts(ports) : [],
        })),
      uplink: {
        ...formData.uplink,
        ports: sortPorts(formData.uplink.ports),
      },
    }),
    [sortPorts],
  );

  useEffect(() => {
    const usage = switchSet.port_usage2 || switchSet.port_usage;
    let uplink;

    if (usage) {
      if (switchSet.port_usage2) {
        uplink =
          usage.uplink_ports && usage.uplink_ports[0]
            ? usage.uplink_ports[0]
            : defaultPort;
      } else {
        uplink = {
          ports: usage.uplink_ports || [],
          speed: usage.uplink_speed || '',
        };
      }

      const formData = {
        servers: switchSet.port_usage2
          ? usage.server_ports || []
          : [
              {
                ports: usage.server_ports || [],
                speed: usage.server_speed || '',
              },
            ],
        uplink,
      };

      if (switchSet.port_usage2) {
        setForm(sortForm(formData));
      } else {
        setForm(formData);
      }
    }
  }, [sortForm, switchSet]);

  const handleSpeedChange = (position) => (event) => {
    if (!isDirty) {
      setIsDirty(true);
    }

    const portOptions = availablePorts[event.target.value];

    if (position === 'uplink') {
      setForm((prevForm) => ({
        ...prevForm,
        uplink: {
          ports: prevForm.uplink.ports.filter((port) =>
            portOptions.includes(port),
          ),
          speed: event.target.value,
        },
      }));
    } else {
      setForm((prevForm) => ({
        ...prevForm,
        servers: [
          ...prevForm.servers.slice(0, position),
          {
            ports: prevForm.servers[position].ports.filter((port) =>
              portOptions.includes(port),
            ),
            speed: event.target.value,
          },
          ...prevForm.servers.slice(position + 1),
        ],
      }));
    }
  };

  const handlePortChange = (position) => (port) => (event) => {
    if (!isDirty) {
      setIsDirty(true);
    }

    if (event) {
      event.persist();
    }

    if (position === 'uplink') {
      setForm((prevForm) => ({
        ...prevForm,
        uplink: {
          ...prevForm.uplink,
          ports: event?.target.checked
            ? sortPorts([...prevForm.uplink.ports, port])
            : prevForm.uplink.ports.filter((prevPort) => prevPort !== port),
        },
      }));
    } else {
      setForm((prevForm) => ({
        ...prevForm,
        servers: [
          ...prevForm.servers.slice(0, position),
          {
            ...prevForm.servers[position],
            ports: event?.target.checked
              ? sortPorts([...prevForm.servers[position].ports, port])
              : prevForm.servers[position].ports.filter(
                  (prevPort) => prevPort !== port,
                ),
          },
          ...prevForm.servers.slice(position + 1),
        ],
      }));
    }
  };

  const handleDelete = (index) => () => {
    if (!isDirty) {
      setIsDirty(true);
    }

    setForm((prevForm) => ({
      ...prevForm,
      servers: [
        ...prevForm.servers.slice(0, index),
        ...prevForm.servers.slice(index + 1),
      ],
    }));
  };

  const handleAdd = () => {
    setForm((prevForm) => ({
      ...prevForm,
      servers: [
        ...prevForm.servers,
        {
          ...defaultPort,
          speed: speedOptions.find(({ disabled }) => !disabled).value,
        },
      ],
    }));
  };

  const handleDrop = (index, item, dropResult) => {
    if (!isDirty) {
      setIsDirty(true);
    }

    setForm((prevForm) => {
      const formData = { ...prevForm };
      const fromField = dropFieldMap[item.label];
      const toField = dropFieldMap[dropResult.label];

      if (fromField === 'servers') {
        formData.servers[index].ports = prevForm.servers[index].ports.filter(
          (port) => item.value !== port,
        );
      } else {
        formData.uplink.ports = prevForm.uplink.ports.filter(
          (port) => item.value !== port,
        );
      }

      if (toField === 'servers') {
        formData.servers[dropResult.index].ports = sortPorts([
          ...prevForm.servers[dropResult.index].ports,
          item.value,
        ]);
      } else {
        formData.uplink.ports = sortPorts([
          ...prevForm.uplink.ports,
          item.value,
        ]);
      }

      return formData;
    });
  };

  if (!speedOptions.length) {
    return (
      <Box>
        <Text>
          No port options exist in common across all selected Switch Types.
          Please change selected Switch Types to see Port Usage options.
        </Text>
      </Box>
    );
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <Container>
        {form.servers.map(({ speed, ports }, i) => (
          <Box className='server-row' direction='row' justify='start' key={i}>
            <Box margin={{ right: 'small' }}>
              <FormField label='Speed*'>
                <Select
                  disabled={!speedOptions.length || isView}
                  disabledKey='disabled'
                  labelKey='label'
                  options={speedOptions}
                  onChange={handleSpeedChange(i)}
                  style={{ height: 42 }}
                  value={speed}
                  valueKey={{ key: 'value', reduce: true }}
                />
              </FormField>
            </Box>
            <MultiCheckboxSelect
              disabled={!speed || isView}
              index={i}
              label='Server Ports*'
              options={availablePorts[speed]}
              onChange={handlePortChange(i)}
              onDrop={handleDrop}
              usedPorts={usedPorts}
              values={ports}
            />
            <Box
              align='end'
              justify='end'
              pad={{ vertical: 'small' }}
              width='xsmall'
            >
              {i > 0 && !isView ? (
                <Button
                  disabled={isView}
                  icon={<Trash color='brand' />}
                  onClick={handleDelete(i)}
                />
              ) : null}
            </Box>
          </Box>
        ))}
        <Box direction='row' pad={{ vertical: 'small' }}>
          {!isView ? (
            <Button
              disabled={form.servers.length >= availableSpeeds.length || isView}
              icon={<FormAdd />}
              label='Add Port Speed'
              onClick={handleAdd}
              reverse
              secondary
            />
          ) : null}
        </Box>

        {isL2 ? (
          <Box direction='row' justify='start' margin={{ top: 'medium' }}>
            <Box width='small' margin={{ right: 'small' }}>
              <FormField label='Speed'>
                <Select
                  disabled={!speedOptions.length || isView}
                  labelKey='label'
                  options={speedOptions}
                  onChange={handleSpeedChange('uplink')}
                  style={{ height: 42 }}
                  value={form.uplink.speed}
                  valueKey={{ key: 'value', reduce: true }}
                />
              </FormField>
            </Box>
            <MultiCheckboxSelect
              disabled={!form.uplink.speed || isView}
              index={0}
              label='Uplink Ports'
              options={availablePorts[form.uplink.speed]}
              onChange={handlePortChange('uplink')}
              onDrop={handleDrop}
              usedPorts={usedPorts}
              values={form.uplink.ports}
            />
            <Box width='xsmall' />
          </Box>
        ) : null}
      </Container>
    </DndProvider>
  );
};

export default FormPortUsage;

FormPortUsage.propTypes = {
  data: PropTypes.object,
  col: PropTypes.object,
};
