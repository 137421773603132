// (C) Copyright 2016-2024 Hewlett Packard Enterprise Development LP

export const param = (name, url) => {
  if (!url) url = window.location.href;
  name = name.replace(/[[\]]/g, '\\$&').toLowerCase(); // This is just to avoid case sensitiveness for query parameter name
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const makeUrlValue = (prefix, key, val) =>
  `${prefix + key}=${encodeURIComponent(val)}`;

export default {
  param,
  makeUrlValue,
};
