// (C) Copyright 2016-2022, 2024 Hewlett Packard Enterprise Development LP

import React from 'react';
import PropTypes from 'prop-types';
import { Button, Heading, Box } from 'grommet';
import {
  CloudDownload,
  DocumentMissing,
  DocumentUpdate,
  Download,
  FormAdd,
  Trash,
} from 'grommet-icons';
import { useHistory } from 'react-router-dom';
import auth from '../lib/auth';
import IconButton from './generic/IconButton';
import Link from './Link';
import styled from 'styled-components';

const StyledButton = styled.div`
  display: block;
`;

const ViewHeader = ({
  children,
  controls,
  createLabel,
  onCreate,
  onDelete,
  onImport,
  onPublish,
  onUpdate,
  onUnpublish,
  level = 2,
}) => {
  const history = useHistory();
  const label = typeof createLabel === 'boolean' ? '' : createLabel;
  const isViewer = auth.activeRoleDef?.id?.includes('_viewer');

  const handleCreate = () => {
    if (onCreate) {
      onCreate();
    } else {
      history.push(`${history.location.pathname}/create`);
    }
  };

  return (
    <Heading
      level={level}
      fill={true}
      size='large'
      weight='normal'
      style={{ position: 'relative' }}
    >
      <Box direction='row' justify='between'>
        <Box alignSelf='start'>{children}</Box>
        <Box alignSelf='end' direction='row'>
          <Box direction='row' margin={{ top: 'xsmall' }}>
            {controls}
            {onDelete && (
              <IconButton
                icon={<Trash color='brand' />}
                onClick={onDelete}
                tip='Delete'
              />
            )}
            {onImport && (
              <IconButton
                title='Import'
                icon={<CloudDownload />}
                onClick={onImport}
              />
            )}
            {onUpdate && (
              <IconButton
                title='Update'
                icon={<Download />}
                onClick={onUpdate}
              />
            )}
            {onUnpublish && (
              <IconButton
                icon={<DocumentMissing color='brand' />}
                onClick={onUnpublish}
                tip='Unpublish'
              />
            )}
            {onPublish && (
              <IconButton
                icon={<DocumentUpdate color='brand' />}
                onClick={onPublish}
                tip='Publish'
              />
            )}
          </Box>
          {!isViewer && createLabel && (
            <StyledButton>
              <Button label={label} secondary onClick={handleCreate} />
            </StyledButton>
          )}
        </Box>
      </Box>
    </Heading>
  );
};

ViewHeader.propTypes = {
  children: PropTypes.node,
  controls: PropTypes.object,
  createLabel: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.bool,
  ]),
  onCreate: PropTypes.func,
  onDelete: PropTypes.func,
  onImport: PropTypes.func,
  onPublish: PropTypes.func,
  onUpdate: PropTypes.func,
  onUnpublish: PropTypes.func,
};

export default ViewHeader;
