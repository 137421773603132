// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import React, { useState } from 'react';
import { Box, Button, DropButton, Text } from 'grommet';
import { User } from 'grommet-icons';
import { useHistory } from 'react-router-dom';

import { useMembershipContext } from '../../utils/context/membershipContext';
import { useGetHosters } from '../../services/hosters';
import auth from '../../lib/auth';

const getMemberDesc = (member, hosters = []) => {
  let teamID = '';
  const {
    hoster_id: hosterId,
    role_id: roleId,
    scope,
    team_name: teamName,
  } = member;

  switch (scope) {
    case 'hoster':
      teamID = `HOSTER: ${teamName}`;
      break;
    case 'project':
      // biome-ignore lint/correctness/noSwitchDeclarations: <explanation>
      const host = hosters.find(({ id }) => id === hosterId);
      teamID = `PROJECT: ${host ? `${host.name}:` : ''}${teamName}`;
      break;
    case 'portal':
      teamID = `PORTAL: ${teamName}`;
      break;
    default:
      teamID = 'ERROR';
  }

  return `${teamID} | ROLE: ${roleId}`;
};

const MemberDropdown = () => {
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const { activeMembership, memberships, role, setContext } =
    useMembershipContext();
  const { data: hosters = [] } = useGetHosters(role?.scope);

  if (memberships?.length < 2) {
    return null;
  }

  const handleMembershipClick = (membership) => () => {
    if (membership.id === activeMembership.id) {
      return;
    }

    auth.setActiveMemberID(membership.id);
    auth.setActiveMember(membership);
    setContext((prev) => ({
      ...prev,
      activeMembership: membership,
      role: {},
    }));
    setOpen(false);
    history.push('/');
  };

  return (
    <DropButton
      aria-label='User role'
      icon={<User />}
      dropAlign={{ top: 'bottom', right: 'right' }}
      dropContent={
        <Box>
          {memberships?.map((membership) => (
            <Button
              active={membership.id === activeMembership.id}
              key={membership.id}
              hoverIndicator
              onClick={handleMembershipClick(membership)}
            >
              <Box
                direction='row'
                pad={{ horizontal: 'small', vertical: 'xsmall' }}
              >
                <Text>{getMemberDesc(membership, hosters)}</Text>
              </Box>
            </Button>
          ))}
        </Box>
      }
      dropProps={{
        onClickOutside: () => setOpen(!open),
      }}
      onClick={() => setOpen(!open)}
      open={open}
      style={{
        padding: '6px',
      }}
      tip='User Role'
    />
  );
};

export default MemberDropdown;
