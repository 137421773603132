// (C) Copyright 2022-2024 Hewlett Packard Enterprise Development LP

import React from 'react';

import ListPageWithNav from '../../containers/generic/ListPageWithNav';
import { URL_USERS } from '../../routes/consts';
import { useGetUsers } from '../../services/users';
import { NAV_TEAMS_PORTAL } from '../../components/HybridNav/consts.js';

const UserListView = () => (
  <ListPageWithNav
    navSection={NAV_TEAMS_PORTAL}
    actions={{}}
    path={URL_USERS}
    sort={{
      property: 'name',
      direction: 'asc',
    }}
    title='Users'
    useGetData={useGetUsers}
  />
);

export default UserListView;
