// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Header, Layer, ResponsiveContext, Text } from 'grommet';
import { Close } from 'grommet-icons';
import auth from '../../lib/auth';
import * as c from '../../routes/consts';
import { AddHostPanel } from '../../components/host/AddHostPanel';
import { useGetAvailableResources, createHost } from '../../services/hosts';
import { useGetAvailableStorageResources } from '../../services/storage';
import { useGetProjects } from '../../services/projects';
import IconButton from '../../components/generic/IconButton';
import { useGetNetworks } from '../../services/networks';

const processForm = ({
  name,
  description,
  labels,
  location: { id: locationId, country, data_center: dataCenter, region },
  machine_size: { id: machineSizeId, name: machineSizeName },
  networks,
  network_for_default_route: defaultRoute,
  network_untagged: networkUntagged,
  ssh_keys: sshKeys,
  image: { flavor: svcFlavor, version: svcVersion },
  volumes,
  project,
  ...rest
}) => {
  const projectId = project?.id || '';

  return {
    description,
    labels,
    location: {
      country,
      data_center: dataCenter,
      region,
    },
    location_id: locationId,
    machine_size_id: machineSizeId,
    machine_size_name: machineSizeName,
    name,
    network_for_default_route: defaultRoute.value,
    network_untagged: networkUntagged.value,
    networks: [...networks.map((network) => network.value)],
    project_id: projectId === '__self' ? '' : projectId,
    service_nets_provider_mac: networks
      .filter(({ network }) => network.subtype === 'service-provider')
      .reduce(
        (acc, { value }) => ({
          // biome-ignore lint: <explanation>
          ...acc,
          [value]: rest[value] || '',
        }),
        {},
      ),
    ssh_key_ids: sshKeys.map((item) => item.key),
    svc_flavor: svcFlavor,
    svc_version: svcVersion,
    volumes: volumes ? volumes.map(({ volume }) => volume) : [],
  };
};

export const AddHost = ({ onHide }) => {
  const size = useContext(ResponsiveContext);
  const isIAM = auth.isIAM();
  const topNav = isIAM || size === 'small';
  const [submitError, setSubmitError] = useState();
  const [projectId, setProjectId] = useState();
  const {
    isLoading,
    availableResources: resources,
    getAvailableResources,
  } = useGetAvailableResources();

  useEffect(() => {
    getAvailableResources(projectId);
  }, [getAvailableResources, projectId]);

  const {
    isLoading: isStorageLoading,
    availableStorageResources,
    getAvailableStorageResources,
  } = useGetAvailableStorageResources();

  useEffect(() => {
    getAvailableStorageResources(projectId);
  }, [getAvailableStorageResources, projectId]);

  const {
    isLoading: isProjectsLoading,
    data: projects,
    refetch,
  } = useGetProjects();

  const { data: networks } = useGetNetworks();

  const inHosterScope = auth.inScope(c.SCOPE_HOSTER);

  // biome-ignore lint: <explanation>
  useEffect(() => {
    if (inHosterScope) {
      refetch();
    }
  }, []);

  const onSubmit = async ({ value }) => {
    const postBody = processForm(value);
    try {
      setSubmitError(null);
      await createHost(postBody);
      onHide();
    } catch (e) {
      let error = e.toString();
      if (e.response) {
        error = await e.response.text();
      }
      setSubmitError(`Unable to create host: ${error}`);
    }
  };

  const loading =
    (isLoading && !resources) ||
    (isStorageLoading && !availableStorageResources) ||
    (isProjectsLoading && !projects);

  return (
    <Layer
      modal={false}
      margin={{
        left: topNav ? 0 : '192px',
        top: '75px',
      }}
      position='right'
      full
      animate={false}
      onEsc={onHide}
    >
      <Box fill='vertical'>
        <Header
          elevation='xsmall'
          gap='none'
          pad={{ bottom: 'small', horizontal: 'medium', top: 'medium' }}
          style={{ zIndex: 1 }}
        >
          <Text size='xxlarge' weight='bold'>
            Create host
          </Text>
          <IconButton icon={<Close />} onClick={onHide} />
        </Header>

        {loading ? (
          <Box fill align='center' justify='center' margin={{ top: '54px' }}>
            <Text>Initializing...</Text>
          </Box>
        ) : (
          <AddHostPanel
            projects={projects}
            resources={resources || {}}
            storageResources={availableStorageResources || {}}
            onSubmit={onSubmit}
            onHide={onHide}
            error={submitError}
            setProjectId={setProjectId}
            networks={networks}
          />
        )}
      </Box>
    </Layer>
  );
};

AddHost.propTypes = {
  onHide: PropTypes.func,
};
