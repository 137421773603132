// (C) Copyright 2020-2024 Hewlett Packard Enterprise Development LP

import PropTypes from 'prop-types';
import React from 'react';
import { Box, Text } from 'grommet';
import { Link } from '..';

const CoreInfo = ({ description, detail, icon, name, url }) => (
  <Box direction='row' align='start' gap='small'>
    <Box style={{ minWidth: 'min-content' }} pad={{ vertical: 'xxsmall' }}>
      {icon}
    </Box>
    <Box>
      <Link to={url} size='large' title={name} />
      <Text size='small'>{detail}</Text>
      <Text>{description}</Text>
    </Box>
  </Box>
);

CoreInfo.propTypes = {
  description: PropTypes.string,
  detail: PropTypes.string,
  icon: PropTypes.node,
  name: PropTypes.string,
  url: PropTypes.string,
};

export default CoreInfo;
