// (C) Copyright 2020-2024 Hewlett Packard Enterprise Development LP

import React from 'react';
import PropTypes from 'prop-types';
import { Box, CheckBox, FormField, Text } from 'grommet';

const FormCheckBox = ({ col, value, validationResult, onChange, ...rest }) => {
  const checked = col.keyPath === 'no_switch_lag' ? !value : !!value;

  if (col.isReadOnly) {
    return (
      <Box style={{ gridColumn: '1 / span 2', pointerEvents: 'none' }}>
        <CheckBox
          name={col.keyPath}
          label={col.displayName}
          key={col.keyPath}
          checked={checked}
          color='text-strong'
          {...rest}
        />
        <Box>
          <Text>{col.inputHelp}</Text>
        </Box>
      </Box>
    );
  }

  const inputStyle = { style: {} };

  if (col.maxWidth) {
    inputStyle.style.maxWidth = col.maxWidth;
  }

  return (
    <Box style={{ gridColumn: '1 / span 2', ...inputStyle.style }}>
      <FormField
        name={col.keyPath}
        error={validationResult.msg}
        help={col.inputHelp}
      >
        <CheckBox
          name={col.keyPath}
          label={col.displayName}
          key={col.keyPath}
          checked={checked}
          onChange={onChange}
          {...rest}
        />
      </FormField>
    </Box>
  );
};

FormCheckBox.propTypes = {
  col: PropTypes.shape({
    displayName: PropTypes.string.isRequired,
    keyPath: PropTypes.string.isRequired,
  }).isRequired,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func,
  validationResult: PropTypes.shape({
    msg: PropTypes.string,
  }),
};

export default FormCheckBox;
