// (C) Copyright 2016-2024 Hewlett Packard Enterprise Development LP

import isBoolean from 'lodash/isBoolean';
import isNil from 'lodash/isNil';

export const assertBool = (v, name) => {
  if (!isBoolean(v)) throw new Error(`${name} not boolean`);
};

export const assertDefined = (v, name, msg) => {
  // biome-ignore lint: <explanation>
  if (isNil(msg)) msg = '';
  if (isNil(v)) throw new Error(`${name} is nil:${msg}`);
};

export default {
  bool: assertBool,
  defined: assertDefined,
};
