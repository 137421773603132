// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, FormField, Select, Text, TextInput } from 'grommet';
import { FormAdd, Trash } from 'grommet-icons';
import styled from 'styled-components';
import { Link } from '../../../components';

const Table = styled(Box)`
  margin: 0 -24px;
  padding: 24px 0;

  table {
    border-spacing: 0;

    th,
    td {
      padding: 8px 24px;

      &.ip {
        width: 240px;
      }

      &.type {
        width: 230px;
      }

      &.description {
        width: 280px;
      }

      &.release {
        text-align: center;
        width: 80px;
      }
    }

    th {
      border-bottom: 1px solid #999999;
      text-align: left;
    }

    td {
      > * {
        min-height: 40px;
      }

      .ip-count {
        > div {
          &:first-child {
            > div {
              border-radius: 4px 0 0 4px;
            }
          }

          &:last-child {
            flex: 0 0 50px;

            > div {
              border-left: none;
              border-radius: 0 4px 4px 0;
            }

            input,
            label {
              text-align: right;
            }
          }
        }
      }
    }
  }
`;

const defaultIpPool = {
  base: '',
  block: '',
  count: 1,
  external: true,
  usage: '',
};

const FormIpPools = ({ col }) => {
  const [ipPools, setIpPools] = useState([]);
  const [allocations, setAllocations] = useState([]);
  const [releases, setReleases] = useState([]);
  const [dirty, setDirty] = useState({});
  const [adding, setAdding] = useState(0);

  const isEdit = useMemo(() => col.mb.props.mode === 'edit', [col]);

  const data = useMemo(
    () => col?.customComponentMetadata?.ds.data || { pool: { fragments: [] } },
    [col?.customComponentMetadata?.ds.data],
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const ipOptions = useMemo(() => {
    const {
      pool: { fragments },
    } = data;

    if (fragments) {
      const list = fragments.map(({ base, count }) => {
        const value = `${base} (${count})`;

        return {
          label: value,
          value,
        };
      });

      return list;
    }
    return [];
  }, [ipPools]);

  useEffect(() => {
    col.mb.view.setFormValue('use_records', ipPools);
  }, [col.mb.view, ipPools]);

  useEffect(() => {
    const records = data?.use_records;

    if (records) {
      setIpPools(records);
    }
  }, [data]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (adding) {
      const newAllocations = ipPools
        .slice(adding - 1)
        .map(({ block, ...rest }) => rest);
      setAllocations(newAllocations);
    }
  }, [ipPools]);

  useEffect(() => {
    col.mb.view.setFormValue('allocations', allocations);
  }, [col.mb.view, allocations]);

  useEffect(() => {
    col.mb.view.setFormValue('releases', releases);
  }, [col.mb.view, releases]);

  const handleAllocateClick = () => {
    if (!adding) {
      setAdding(ipPools.length + 1);
    }

    setIpPools((previousIpPools) => [...previousIpPools, defaultIpPool]);
  };

  const handleIpPoolInputChange =
    (index, key) =>
    ({ value, target }) => {
      if (!dirty[index] && dirty[index] && !dirty[index][key]) {
        setDirty((prevDirty) => ({
          ...prevDirty,
          [index]: {
            ...prevDirty[index],
            [key]: true,
          },
        }));
      }

      const inputValue = value || target.value;

      setIpPools((prevIpPools) => [
        ...prevIpPools.slice(0, index),
        {
          ...prevIpPools[index],
          [key]: key === 'count' ? Number(inputValue) : inputValue,
        },
        ...prevIpPools.slice(index + 1),
      ]);
    };

  const handleDelete = (index) => (e) => {
    e.preventDefault();

    const ipToRelease = ipPools[index].base;

    if (adding) {
      if (allocations.length === 1) {
        setAdding(0);
      }
    }

    if ((adding && index < adding - 1) || !adding) {
      setReleases([...releases, ipToRelease]);
    }

    setIpPools((prevIpPools) => [
      ...prevIpPools.slice(0, index),
      ...prevIpPools.slice(index + 1),
    ]);
  };

  const getUsage = ({ deviceId, external, hostId }) => {
    if (hostId) {
      return 'Host';
    }
    if (deviceId) {
      return 'Device';
    }
    if (external) {
      return 'Manual';
    }
    return '';
  };

  const getDescription = ({ deviceId, hostId, usage }) => {
    if (hostId) {
      return <Link to={`/s/hosts/${hostId}`} title={hostId} />;
    }

    if (deviceId) {
      return <Link to={`/s/devices/${deviceId}`} title={deviceId} />;
    }

    return <Text>{usage ?? ''}</Text>;
  };

  return (
    <Table>
      <table>
        <thead>
          <tr>
            <th className='ip'>
              <Text weight='bold'>IP address</Text>
            </th>
            <th className='type'>
              <Text weight='bold'>Allocation type</Text>
            </th>
            <th className='description'>
              <Text weight='bold'>Usage description / Used by ID</Text>
            </th>
            <th className='allocation'>
              <Text weight='bold'>Allocated by</Text>
            </th>
            <th className='release'>
              <Text weight='bold' textAlign='center'>
                Release
              </Text>
            </th>
          </tr>
        </thead>
        <tbody pad={{ vertical: 'small' }}>
          {ipPools.length
            ? ipPools.map(
                (
                  {
                    allocated_by: allocatedBy,
                    base,
                    block,
                    count,
                    device_id: deviceId,
                    external,
                    host_id: hostId,
                    usage,
                  },
                  i
                ) => {
                  const active = adding === i + 1;
                  const canEdit = adding && i + 1 >= adding;

                  return (
                    <tr key={`ip-pool-${i}`}>
                      <td className='ip'>
                        {!canEdit ? (
                          <Box>
                            <Text>{base}</Text>
                          </Box>
                        ) : (
                          <FormField
                            disabled={!active}
                            label={active ? 'Available IP blocks' : ''}
                          >
                            <Select
                              disabledKey='disabled'
                              labelKey='label'
                              onChange={handleIpPoolInputChange(i, 'block')}
                              options={ipOptions}
                              value={block}
                              valueKey={{ key: 'value', reduce: true }}
                            />
                          </FormField>
                        )}
                      </td>
                      <td className='type'>
                        {canEdit ? (
                          <Box direction='row' className='ip-count'>
                            <FormField
                              label={active ? 'Address requested*' : ''}
                            >
                              <TextInput
                                onChange={handleIpPoolInputChange(i, 'base')}
                                value={base}
                                required
                              />
                            </FormField>
                            <FormField label={active ? 'Count*' : ''}>
                              <TextInput
                                onChange={handleIpPoolInputChange(i, 'count')}
                                value={count}
                                required
                              />
                            </FormField>
                          </Box>
                        ) : (
                          getUsage({ hostId, deviceId, external })
                        )}
                      </td>
                      <td className='description'>
                        {canEdit ? (
                          <FormField label={active ? 'Usage description*' : ''}>
                            <TextInput
                              required
                              onChange={handleIpPoolInputChange(i, 'usage')}
                              value={usage}
                            />
                          </FormField>
                        ) : (
                          getDescription({ hostId, deviceId, external, usage })
                        )}
                      </td>
                      <td className='allocation'>
                        <Text truncate>{allocatedBy}</Text>
                      </td>
                      <td className='release'>
                        <div>
                          {isEdit &&
                          (!adding || (adding && i + 1 >= adding)) &&
                          external ? (
                            <Button
                              icon={<Trash color='brand' />}
                              onClick={handleDelete(i)}
                            />
                          ) : null}
                        </div>
                      </td>
                    </tr>
                  );
                }
              )
            : null}
        </tbody>
      </table>
      <Box direction='row' pad={{ vertical: 'small', horizontal: 'medium' }}>
        <Button
          disabled={!isEdit}
          icon={<FormAdd />}
          label='Allocate'
          onClick={handleAllocateClick}
          reverse
          secondary
        />
      </Box>
    </Table>
  );
};

export default FormIpPools;

FormIpPools.propTypes = {
  col: PropTypes.object,
};
