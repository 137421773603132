// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import defaultTo from 'lodash/defaultTo';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import * as c from '../../routes/consts.js';
import * as mb from '../../containers/generic/MetaBuilder';
import MetaBuilder, {
  ValidationResult,
} from '../../containers/generic/MetaBuilder';
import ItemViewContainer from '../../containers/generic/ItemViewContainer';
import CreateViewContainer from '../../containers/generic/CreateViewContainer';
import EditViewContainer from '../../containers/generic/EditViewContainer';
import debugLogger from '../../lib/debug';
import * as log from '../../lib/debug';
import * as REGEX from '../../data/regex';
import { rackConfigEditMeta } from '../RackTemplate';
import { POD_TYPE_L3_WITH_VXLANS } from '../../data/pod';
import { EDGE_RACK, DATA_CENTER_RACK, EDGE_CONTROLLER } from '../../data/rack';
import assignUniqueIDs from '../../lib/uniqueids';
import { FormRackType } from './FormRackType';
import { LABEL_RACKS } from '../../components/HybridNav/consts.js';

const debug = debugLogger('Rack', log.LOG_LEVEL_WARN);

const EXTERNAL_NAME = c.URL_RACKS;

export const settings = {
  authUrl: EXTERNAL_NAME, // used to filter create/trash icons from the view; using the defined roledef permissions mappings to this auth URL entry
  homeUrl: c.makeSecUrl(EXTERNAL_NAME), // homepage for this list view (e.g. /s/hosters/:pid/) is where this view is located; for return from create / item views
  baseUrl: EXTERNAL_NAME, // base url to be used for creating all associated URLS for this reasource, e.g. pageItem, pageCreate, restUrl, restItemUrl
  stateKey: EXTERNAL_NAME,
  homeLabel: LABEL_RACKS,
};

function updateName(view, dsPods, dsRackTemplate, ds) {
  let prefix = '';
  const name = view.formDefaultTo('_temp.name', '');
  const data = dsPods.Data();
  const pod_id = view.formDefaultTo('pod_id');
  if (!isEmpty(data) && !isEmpty(pod_id)) {
    const pod = data.find((p) => p.id === pod_id);
    if (!isNil(pod)) {
      prefix = `${pod.name}-`;
    }
  }
  view.setFormValue('_temp.prefix', prefix);
  view.setFormValue('name', prefix + name);
  updateSwitchNames(view, dsRackTemplate, ds);
}

function updateSwitchNames(view, dsRackTemplate, ds) {
  const data = dsRackTemplate.Data();
  const rack_id = view.formDefaultTo('template_id', '');
  const rackName = view.formDefaultTo('name', '');
  if (!isEmpty(data) && !isEmpty(rack_id)) {
    const rackTemplate = data.find((r) => r.id === rack_id);
    if (isNil(rackTemplate)) {
      return;
    }
    const names = [];
    for (const i in rackTemplate.switch_sets) {
      const ss = rackTemplate.switch_sets[i];
      let count = 1;
      if (ss.ha === true) {
        count = 2;
      }
      for (let c = 0; c < count; c++) {
        const swName = `${rackName}-${ss.switch_name_suffix[c]}`;
        for (const j in ss.uplink_sets) {
          const us = ss.uplink_sets[j];
          if (us.type === 'BGP' || us.type === 'BGP HA') {
            // biome-ignore lint/style/useConst: <explanation>
            for (let k in us.ports) {
              names.push(swName);
            }
          }
        }
      }
    }
    const ports = view.formDefaultTo('additional_config.l3_config.ports', '');
    if (ports.length === 0) {
      return;
    }

    for (const idx in names) {
      const name = names[idx];
      if (!isEmpty(ports)) {
        ports[idx].switch = name;
      }
    }
    assignUniqueIDs(ports);
    view.setFormValue('additional_config.l3_config.ports', ports);
    ds.Set('additional_config.l3_config.ports', ports);
  }
}

const isEdgeRack = (m) => m.view.formDefaultTo('type', '') === EDGE_RACK;
const isDataCenterRack = (m) =>
  m.view.formDefaultTo('type', '') === DATA_CENTER_RACK;

const isVlanValid = (force, required) => (value) => {
  if (force === true) {
    return new ValidationResult(true, '');
  }

  if ((value === undefined || value === '') && required !== true) {
    return new ValidationResult(true, '');
  }

  // biome-ignore lint/suspicious/noGlobalIsNan: <explanation>
  if (isNaN(Number(value))) {
    return new ValidationResult(false, 'must be a number.');
  }

  if (value < REGEX.VLAN_MIN || REGEX.VLAN_MAX < value) {
    return new ValidationResult(false, REGEX.VLAN_MSG);
  }

  return new ValidationResult(true, '');
};

function metaCreate(props) {
  const m = new MetaBuilder(props);

  m.formDefaults((form) => {
    form.switches = defaultTo(form.switches, []);
    form.template_config = defaultTo(form.template_config, { switch_sets: [] });
    form.vlan_pool_id = defaultTo(form.vlan_pool_id, {});
    form.lag_id_pool_ids = defaultTo(form.lag_id_pool_ids, {});
    form.additional_config = defaultTo(form.additional_config, {
      bmc: { dns: [] },
    });
  });

  const ds = m.newStaticDataSource({});
  const dsPods = m.newDataSource(c.URL_PODS);
  const dsRackTemplate = m.newDataSource(c.URL_RACKTEMPLATES);

  m.addSection('1').NoChrome().MaxWidth(m.LARGE);

  let podTemplateMap = new Map();

  const setPodId = (id) => {
    const pod = dsPods.Data().find((p) => p.id === id);

    if (isNil(pod)) {
      podTemplateMap = new Map();
    } else {
      podTemplateMap = new Map(
        defaultTo(pod.rack_templates, []).map((tid) => [tid, tid]),
      );
    }

    dsRackTemplate.Filter((t) => podTemplateMap.has(t.id));
  };

  m.addColumn('pod_id', 'Pod')
    .Input()
    .DataXform(dsPods, (json) => json.map((t) => ({ id: t.id, name: t.name })))
    .DropDown()
    .Required()
    .Width(mb.SMALL)
    .OnInit(() => {
      updateName(m.view, dsPods, dsRackTemplate, ds);
      setPodId(m.view.formDefaultTo('pod_id'));
    })
    .OnChange((newVal) => {
      if (isL3Pod(newVal, dsPods)) {
        m.view.setFormValue('_temp.L3', 'true');
        if (isVXLANPod(newVal, dsPods)) {
          m.view.setFormValue('_temp.VXLAN', 'true');
        } else {
          m.view.setFormValue('_temp.VXLAN', 'false');
        }
      } else {
        m.view.setFormValue('_temp.L3', 'false');
        m.view.setFormValue('_temp.VXLAN', 'false');
      }
      m.view.setFormValue('template_id', '');
      updateName(m.view, dsPods, dsRackTemplate, ds);
      setPodId(newVal);
    })
    .CustomValidator((val) => {
      if (!podTemplateMap.has(m.view.formDefaultTo('template_id'))) {
        return new ValidationResult(
          false,
          `Rack template ${val} is not available in the selected pod.`,
        );
      }
      return new ValidationResult(true, '');
    });

  m.addColumn('_temp.name', 'Name')
    .Input()
    .Required()
    .MaxLength(150)
    .MaxWidth(mb.SMALL)
    .PrefixKeyPath('_temp.prefix')
    .Width(mb.SMALL)
    .OnChange(() => {
      updateName(m.view, dsPods, dsRackTemplate, ds);
    });

  m.addColumn('description', 'Description').Input().MaxWidth(mb.SMALL);

  m.addColumn('type', 'Type')
    .Input()
    .MaxWidth(mb.SMALL)
    .Custom(FormRackType, {});

  m.addColumn('store_id', 'Store ID')
    .Input()
    .MaxWidth(mb.SMALL)
    .RequiredSometimes(() => isEdgeRack(m))
    .Visible(() => isEdgeRack(m));

  m.addColumn('controller_config.provision_vid', 'Maintenance network VID')
    .Input()
    .MaxWidth(mb.SMALL)
    .Visible(() => isEdgeRack(m))
    .RequiredSometimes(() => isEdgeRack(m))
    .CustomValidator(isVlanValid(isDataCenterRack(m), isEdgeRack(m)));

  m.addColumn('controller_config.bmc_cidr', 'BMC network CIDR')
    .Input()
    .MaxWidth(mb.SMALL)
    .Visible(() => isEdgeRack(m))
    .RegEx(REGEX.IPV4_CIDR, REGEX.IPV4_CIDR_MSG);

  m.addColumn('controller_config.bmc_vid', 'BMC network VID')
    .Input()
    .MaxWidth(mb.SMALL)
    .Visible(() => isEdgeRack(m))
    .CustomValidator(isVlanValid(isDataCenterRack(m)));

  m.addColumn('template_id', 'Rack template')
    .Input()
    .DataXform(dsRackTemplate, (json) =>
      json.map((t) => ({ id: t.id, name: t.name })),
    )
    .DropDown()
    .RequiredSometimes(() => isDataCenterRack(m))
    .Visible(() => isDataCenterRack(m))
    .Width(mb.SMALL);

  return m;
}

function isL3Pod(newVal, dsPod) {
  const data = dsPod.Data();
  const pod_id = newVal;
  if (!isEmpty(data) && !isEmpty(pod_id)) {
    const pod = data.find((p) => p.id === pod_id);
    if (!isNil(pod)) {
      if (pod.pod_type === POD_TYPE_L3_WITH_VXLANS) {
        return true;
      }
    }
  }
}

function isVXLANTopo(switchSetList) {
  for (const i in switchSetList) {
    const ss = switchSetList[i];
    if (ss.fabric_type === POD_TYPE_L3_WITH_VXLANS) {
      return true;
    }
  }
  return false;
}

function isVXLAN(rack) {
  return isVXLANTopo(rack.template_config.switch_sets);
}

function isVXLANPod(newVal, dsPod) {
  const data = dsPod.Data();
  const pod_id = newVal;
  if (!isEmpty(data) && !isEmpty(pod_id)) {
    const pod = data.find((p) => p.id === pod_id);
    if (!isNil(pod)) {
      if (pod.pod_type === POD_TYPE_L3_WITH_VXLANS) {
        return true;
      }
    }
  }
}

function metaEdit(props) {
  const m = new MetaBuilder(props);
  const DS_RACK_TEMPLATE = 'racktemplate';
  const dsPods = m.newDataSource(c.URL_PODS);
  const dsRackTemplate = m.newDataSource(c.URL_RACKS, DS_RACK_TEMPLATE);
  const dsControllers = m.newDataSource(c.URL_RACKCONTROLLERS);

  m.formDefaults((form) => {
    form.switches = defaultTo(form.switches, []);
  });

  const id = m.view.prop('itemId', false);

  const ds = m
    .newDataSource(c.URL_RACKS)
    .Item(id)
    .OnLoad((json) => {
      debug.debug('got rack:', json);
      m.view.initForm(json.data);
      if (isVXLAN(json.data)) {
        m.view.setFormValue('_temp.VXLAN', 'true');
      } else {
        m.view.setFormValue('_temp.VXLAN', 'false');
      }
      dsRackTemplate.SetData(json.data.template_config);
    });

  m.addTab('General');

  m.addSection('1').NoChrome().MaxWidth(m.LARGE);

  m.addColumn('name', 'Name').Input().ReadOnly().MaxWidth(mb.SMALL);

  if (props.readOnly) {
    m.addColumn('id', 'ID').Input().ReadOnly().MaxWidth(mb.SMALL);
  }

  m.addColumn('pod_id', 'Pod')
    .Input()
    .DataXform(dsPods, (json) => json.map((t) => ({ id: t.id, name: t.name })))
    .DropDown()
    .ReadOnly()
    .MaxWidth(mb.SMALL);

  m.addColumn('description', 'Description')
    .Input()
    .MaxWidth(500)
    .MaxWidth(mb.SMALL);

  if (props.mode === 'view') {
    m.addColumn('type', 'Type').Input();
    m.addColumn('store_id', 'Store ID')
      .Input()
      .Visible(() => isEdgeRack(m));
  }

  m.addColumn('controller_config.provision_vid', 'Maintenance network VID')
    .Input()
    .Visible(() => isEdgeRack(m))
    .CustomValidator(isVlanValid(isDataCenterRack(m), isEdgeRack(m)));

  m.addColumn('controller_config.bmc_cidr', 'BMC network CIDR')
    .Input()
    .Visible(() => isEdgeRack(m));

  m.addColumn('controller_config.bmc_vid', 'BMC network VID')
    .Input()
    .Visible(() => isEdgeRack(m))
    .CustomValidator(isVlanValid(isDataCenterRack(m)));

  if (props.readOnly) {
    m.addColumn('portal_comm_okay', 'Comm status')
      .ReadOnly()
      .Input()
      .FieldXform((val) => (val ? 'OK' : 'NC'));
    m.addColumn('portal_comm_reason', 'Comm status reason').ReadOnly().Input();
  }

  // Display BMC subnet information if type is 'routable'.
  m.addSection('BMC Network Information')
    .MaxWidth(mb.LARGE)
    .Visible(
      () =>
        m.view.formDefaultTo('additional_config.bmc.routing', '') ===
        'routable',
    );
  m.addField('additional_config.bmc.routing', 'Type');
  m.addField('additional_config.bmc.network', 'Network');
  m.addField('additional_config.bmc.netmask', 'Netmask');
  m.addField('additional_config.bmc.dns', 'DNS');

  m.addTab('Devices');

  m.addSection('Controller')
    .MaxWidth(mb.LARGE)
    .Visible(() => isEdgeRack(m));

  if (props.mode === 'view') {
    m.addColumn('controller_id', 'Controllers')
      .MaxWidth(mb.SMALL)
      .DropDown('Select controller', '', true)
      .ReadOnly()
      .DataXform(dsControllers, (json) =>
        json.map((c) => ({ id: c.id, name: c.name })),
      );
  } else {
    m.addColumn('controller_id', 'Controllers')
      .Input()
      .MaxWidth(mb.SMALL)
      .DropDown('Select controller', '', true)
      .DataXform(dsControllers, (json) =>
        json
          .filter(
            ({ rack_id, type }) =>
              type === EDGE_CONTROLLER &&
              (rack_id === '' ||
                rack_id !== m.view.formDefaultTo('controller_id')),
          )
          .map(({ id: controllerId, name }) => ({ id: controllerId, name })),
      );
  }

  // Switches
  m.addSection('Switches')
    .Visible(() => isDataCenterRack(m))
    .MaxWidth(1400);

  m.newDataSource(c.URL_SWITCHES, c.URL_SWITCHES).Filter(
    (s) => isEmpty(s.rack_id) || s.rack_id === id,
  );

  const t2 = m
    .addInputTable('switches', 'Switches')
    .DataXform(ds, (json) => defaultTo(json.switches, []));

  // ---------------------------------------------------------
  t2.OnReset((data) => {
    const newData = { ...data, mgmt_address: '' };
    ds.Update('switches', data, newData, true);
    m.view.updateFormValue('switches', data, newData, true);
  });

  t2.EditDialogMeta(switchSlotEdit); // use same for now
  t2.OnEdit((nextData, oldData) => {
    ds.Update('switches', oldData, nextData, true);
    m.view.updateFormValue('switches', oldData, nextData, true);
  });

  //--------------------------------------------------------------

  t2.addField('name', 'Name')
    .ReadOnly()
    .Input()
    .CellXform((rowData) => rowData.name);

  t2.addField('fabric', 'Fabric')
    .ReadOnly()
    .Input()
    .CellXform((rowData) => rowData.fabric);

  t2.addField('id', 'Device ID')
    .ReadOnly()
    .Input()
    .CellXform((rowData) => rowData.id);

  t2.addField('mgmt_address', 'Address')
    .Input()
    .CellXform((rowData) => rowData.mgmt_address)

    .OnCellChange((row, rowData, newVal, oldVal) => {
      debug.debug('TABLE:onChange::', row, rowData, newVal, oldVal);
    });

  t2.addField('config_version', 'Config version')
    .ReadOnly()
    .Input()
    .CellXform((rowData) => rowData.config_version);

  m.addTab('Base config').Visible(() => isDataCenterRack(m));

  m.addSection('Base config').NoChrome();

  m.addColumn('template_id', 'Rack template').Input().ReadOnly();

  rackConfigEditMeta(props, m.Namespace('template_config'));

  return m;
}

function switchSlotEdit(props) {
  const m = new MetaBuilder(props);

  const rackId = m.parent.view.prop('itemId', false);

  m.getDataSource(c.URL_SWITCHES).Filter(
    (s) => isEmpty(s.rack_id) || s.rack_id === rackId,
  );

  m.addField('name', 'Name').Input().ReadOnly().MaxWidth(mb.SMALL);

  m.addField('mgmt_address', 'Management address')
    .Input()
    .RegEx(REGEX.IPV4_ADDRESS, REGEX.IPV4_ADDRESS_MSG);

  m.addField('config_version', 'Config version').Input().ReadOnly();

  return m;
}

export { default as RackListView } from './RackListView';

export const RackItemView = ItemViewContainer({
  ...settings,
  allowEdit: true,
  meta: (props) => metaEdit(props), // use edit meta
  title: 'Rack',
});

export const RackCreateView = CreateViewContainer({
  ...settings,
  meta: (props) => metaCreate(props),
  title: 'Create rack',
});

export const RackEditView = EditViewContainer({
  ...settings,
  meta: (props) => metaEdit(props),
  title: 'Edit rack',
});
