// (C) Copyright 2016-2024 Hewlett Packard Enterprise Development LP

import forOwn from 'lodash/forOwn';
import isString from 'lodash/isString';
import qs from './querystring.js';

export const REST_URL_OVERRIDE = 'resturl';
export const REST_PROTO_OVERRIDE = 'restproto';
export const REST_HOST_OVERRIDE = 'resthost';
export const REST_PORT_OVERRIDE = 'restport';
export const IS_FIRST_SETTING = true;

class Settings {
  constructor() {
    // get settings from querystring
    this.in = {};

    // simple url override
    const urlOverride = this.addInput(REST_URL_OVERRIDE);
    if (isString(urlOverride) && urlOverride.length > 0) {
      this.restUrlOverride = urlOverride;
      this.restUrlPrefix = urlOverride;
      return;
    }

    // url parts override
    const protoOverride = this.addInput(REST_PROTO_OVERRIDE);
    const hostOverride = this.addInput(REST_HOST_OVERRIDE);
    const portOverride = this.addInput(REST_PORT_OVERRIDE);

    // restUrlPrefix with overrides that allow developing GUI against any steeld
    let proto = window.location.protocol;
    let host = window.location.hostname;
    // use golang template
    let port = '{{.RestPort}}';
    // but if template not applied, fall back to defaults
    if (port.startsWith('{')) {
      port = process.env.REACT_APP_BACKEND_PORT;
    }
    const proxy = process.env.REACT_APP_PROXY_CORS_URL || '';

    if (isString(protoOverride) && protoOverride.length > 0) {
      proto = `${protoOverride}:`;
    }
    if (isString(hostOverride) && hostOverride.length > 0) {
      host = hostOverride;
    }
    if (isString(portOverride) && portOverride.length > 0) {
      port = portOverride;
    }

    this.restUrlPrefix =
      process.env.REACT_APP_QUATTRO_API_URI || `${proto}//${host}:${port}`;
    this.proxy = proxy;
  }

  addInput = (key) => {
    this.in[key] = qs.param(key);
    return this.in[key];
  };

  exportQueryString = (isFirst = false) => {
    let out = '';
    forOwn(this.in, (value, key) => {
      if (isString(value)) {
        if (isFirst) {
          out += qs.makeUrlValue('?', key, value);
          // biome-ignore lint: <explanation>
          isFirst = false;
        } else {
          out += qs.makeUrlValue('&', key, value);
        }
      }
    });
    return out;
  };
}

export const settings = new Settings();

export default settings;
