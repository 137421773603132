// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

import React from 'react';
import { Box, Layer } from 'grommet';

import Alert from './Alert';

export default function Alerts({ alerts }) {
  return (
    <Layer
      position='bottom'
      modal={false}
      margin={{ vertical: 'medium', horizontal: 'small' }}
      responsive={false}
      plain
    >
      <Box gap='medium'>
        {alerts.map(({ style, message }, i) => (
          <Alert key={`${style}-${i}`} status={style}>
            {message}
          </Alert>
        ))}
      </Box>
    </Layer>
  );
}
