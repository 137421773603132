// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

import React, { useEffect, useMemo, useState } from 'react';
import { Box, Button, FormField, Text, TextInput } from 'grommet';
import { FormAdd, Trash } from 'grommet-icons';

const defaultIpSource = {
  base: '',
  count: '',
};

const FormIpSourceInline = ({ col, data, validationResult = [] }) => {
  const [sources, setIpSources] = useState([]);
  const [dirty, setDirty] = useState({});

  const isView = useMemo(() => col.mb.props.mode === 'view', [col.mb.props]);

  useEffect(() => {
    setIpSources(data.sources);
  }, [data]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    col.mb.view.setFormValue('sources', sources);
  }, [sources]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    sources.forEach((_, i) =>
      setDirty((prevDirty) => ({
        ...prevDirty,
        [i]: true,
      })),
    );
  }, []);

  const addIpSource = (e) => {
    setIpSources((currentIpSources) => [...currentIpSources, defaultIpSource]);
  };

  const handleIpResourceInputChange =
    (index, key) =>
    ({ option, target }) => {
      if (!dirty[index] && dirty[index] && !dirty[index][key]) {
        setDirty((prevDirty) => ({
          ...prevDirty,
          [index]: {
            ...prevDirty[index],
            [key]: true,
          },
        }));
      }

      const inputValue = option || target.value;

      setIpSources((currentIpSources) => [
        ...currentIpSources.slice(0, index),
        {
          ...currentIpSources[index],
          [key]: key === 'count' ? Number(inputValue) : inputValue,
        },
        ...currentIpSources.slice(index + 1),
      ]);
    };

  const handleDelete = (index) => (e) => {
    e.preventDefault();

    setIpSources((currentIpSources) => [
      ...currentIpSources.slice(0, index),
      ...currentIpSources.slice(index + 1),
    ]);
  };

  if (isView) {
    return (
      <Box pad={{ vertical: 'medium' }}>
        <Box
          direction='row'
          border={{ side: 'bottom', style: 'solid', color: 'border' }}
          justify='between'
        >
          <Box flex style={{ minWidth: 220 }}>
            <Text margin={{ horizontal: 'small' }} weight='bold'>
              Start/Base
            </Text>
          </Box>
          <Box flex>
            <Text margin={{ horizontal: 'small' }} weight='bold'>
              Count
            </Text>
          </Box>
          <Box flex>
            <Text margin={{ horizontal: 'small' }} weight='bold' />
          </Box>
        </Box>
        <Box pad={{ vertical: 'small' }}>
          {!!sources.length &&
            sources.map(({ base, count }, i) => (
              <Box
                direction='row'
                gap='small'
                justify='between'
                key={`resource-${i}`}
                margin={{ vertical: 'xsmall' }}
              >
                <Box flex style={{ minWidth: 220 }}>
                  <Text>{base}</Text>
                </Box>
                <Box flex>
                  <Text>{count}</Text>
                </Box>
                <Box />
              </Box>
            ))}
        </Box>
      </Box>
    );
  }

  return (
    <Box pad={{ vertical: 'medium' }}>
      <Box
        direction='row'
        border={{ side: 'bottom', style: 'solid', color: 'border' }}
        justify='between'
      >
        <Box flex style={{ minWidth: 220 }}>
          <Text margin={{ horizontal: 'small' }} weight='bold'>
            Start/Base
          </Text>
        </Box>
        <Box flex>
          <Text margin={{ horizontal: 'small' }} weight='bold'>
            Count
          </Text>
        </Box>
        <Box flex>
          <Text margin={{ horizontal: 'small' }} weight='bold' />
        </Box>
      </Box>
      <Box pad={{ vertical: 'small' }}>
        {!!sources.length &&
          sources.map(({ base, count }, i) => (
            <Box
              direction='row'
              gap='small'
              justify='between'
              key={`resource-${i}`}
              margin={{ vertical: 'xsmall' }}
            >
              <Box flex style={{ minWidth: 220 }}>
                <FormField
                  error={dirty[i]?.base && validationResult.msg[i]?.base}
                >
                  <TextInput
                    onChange={handleIpResourceInputChange(i, 'base')}
                    value={base}
                  />
                </FormField>
              </Box>
              <Box flex>
                <FormField
                  error={dirty[i]?.cidr && validationResult.msg[i]?.count}
                >
                  <TextInput
                    onChange={handleIpResourceInputChange(i, 'count')}
                    value={count}
                  />
                </FormField>
              </Box>
              <Button
                icon={<Trash color='brand' />}
                onClick={handleDelete(i)}
                tip='Delete'
              />
            </Box>
          ))}
        <Box direction='row' pad={{ vertical: 'small' }}>
          <Button
            icon={<FormAdd />}
            label='Add IP Address Sources'
            onClick={addIpSource}
            reverse
            secondary
          />
        </Box>
      </Box>
    </Box>
  );
};

export default FormIpSourceInline;
