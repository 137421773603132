// (C) Copyright 2018-2024 Hewlett Packard Enterprise Development LP

import PropTypes from 'prop-types';

import React from 'react';
import { Box } from 'grommet';
import defaultTo from 'lodash/defaultTo';
import { Alert } from '..';
import debugLogger from '../../lib/debug';
import * as log from '../../lib/debug';

const debug = debugLogger('ModalControl', log.LOG_LEVEL_WARN);

// helper for toggling modals, with support for multiple modals per keyPath
export class AlertsControl {
  constructor(view) {
    this.id = 0;
    this.view = view;
  }

  add = (msg, style = 'info', timeout = 5000) => {
    debug.log('addAlert', msg, style, timeout);

    const id = this.id++;
    const a = { id, msg, modified: 1, style };
    this.view.setState([...this.view.getState(), a]);
    setTimeout(() => {
      this.remove(id);
    }, timeout);
  };

  remove = (id) => {
    const visible = this.view.getState().filter((a) => a.id !== id);
    this.view.setState(visible);
  };
}

const AlertsList = ({ alerts }) => {
  debug.log('renderAlerts');
  if (alerts.length === 0) {
    return null;
  }

  return (
    <Box gap='small'>
      {alerts.map((item) => (
        <Alert key={item.id} status={defaultTo(item.style, 'info')}>
          {item.msg}
        </Alert>
      ))}
    </Box>
  );
};

AlertsList.propTypes = {
  alerts: PropTypes.array,
};

export { AlertsList };
