// (C) Copyright 2023-2024 Hewlett Packard Enterprise Development LP

import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  CheckBox,
  Collapsible,
  FormField,
  Text,
  TextInput,
} from 'grommet';

import FormReadOnlyField from './FormReadOnlyField';

const defaultForm = {
  vid: 0,
  vni: 0,
};

const errorMessage = {
  vid: 'Must be between 2 and 4094',
  vni: 'Must be between 1 and 16,777,215',
};

const toggleLabel = {
  vid: 'Specify VLAN ID manually',
  vni: 'Specify VLAN ID and VNI manually',
};

const FormVLAN = ({ col, data }) => {
  const [form, setForm] = useState(defaultForm);
  const [openVlan, setOpenVlan] = useState(false);
  const [errors, setErrors] = useState({});
  const setParent = col.mb.view.setFormValue;
  const { locations = [] } = col?.customComponentMetadata?.dsAvail.data || {};
  const location = locations.find(({ id }) => id === data.location_id);
  const support = {
    vid: location?.private_net_vid_support,
    vni: location?.private_net_vni_support,
  };

  const isViewOnly = useMemo(() => {
    const { mode } = col.mb.props;

    return mode === 'view' || mode === 'edit';
  }, [col.mb.props]);

  useEffect(() => {
    setParent('vid', +form.vid);
  }, [form.vid, setParent]);

  useEffect(() => {
    setParent('vni', +form.vni);
  }, [form.vni, setParent]);

  const handleToggle = () => {
    if (openVlan) {
      setForm(defaultForm);
    }

    setOpenVlan((open) => !open);
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    setOpenVlan(false);
    setForm(defaultForm);
  }, [location]);

  const validateVlan = (vlan) => vlan >= 2 && vlan <= 4094;

  const validateVni = (vni) => vni >= 1 && vni <= 16777215;

  const validate = (field, value) => {
    if (field === 'vid') {
      return validateVlan(value);
    }

    if (field === 'vni') {
      return validateVni(value);
    }

    return true;
  };

  const handleInputChange = (field) => (e) => {
    const { value } = e.target;

    setForm((prev) => ({
      ...prev,
      [field]: e.target.value,
    }));

    const valid = validate(field, +value);

    if (valid && errors[field]) {
      setErrors((prev) => ({
        ...prev,
        [field]: '',
      }));
    }

    if (!valid && !errors[field]) {
      setErrors((prev) => ({
        ...prev,
        [field]: errorMessage[field],
      }));
    }
  };

  return isViewOnly ? (
    <>
      <FormReadOnlyField label='VLAN ID'>
        <Text key='vid' color='text-strong'>
          {data.vid}
        </Text>
      </FormReadOnlyField>
      <FormReadOnlyField label='VNI'>
        <Text key='vni' color='text-strong'>
          {data.vni}
        </Text>
      </FormReadOnlyField>
    </>
  ) : (
    <Box style={{ gridColumn: '1 / span 2', width: '400px' }}>
      <Box border pad='small' round='xsmall'>
        <CheckBox
          label={support.vni ? toggleLabel.vni : toggleLabel.vid}
          onChange={handleToggle}
          checked={openVlan}
        />
        <Collapsible direction='vertical' open={openVlan}>
          {support.vid ? (
            <FormField
              error={errors.vid}
              label='VLAN ID'
              required
              width='small'
            >
              <TextInput
                type='number'
                onChange={handleInputChange('vid')}
                value={form.vid}
              />
            </FormField>
          ) : null}
          {support.vni ? (
            <FormField error={errors.vni} label='VNI' required width='small'>
              <TextInput
                type='number'
                onChange={handleInputChange('vni')}
                value={form.vni}
              />
            </FormField>
          ) : null}
        </Collapsible>
      </Box>
    </Box>
  );
};

FormVLAN.propTypes = {
  col: PropTypes.object,
  data: PropTypes.object,
};

export default FormVLAN;
