// (C) Copyright 2016-2024 Hewlett Packard Enterprise Development LP

import React from 'react';
import { Sidebar } from 'grommet';
import NavItem from './NavItem';
import NavGroup from './NavGroup';
import * as c from '../../routes/consts.js';
import auth from '../../lib/auth';
import { useMembershipContext } from '../../utils/context/membershipContext';

const navContent = {
  portal: [
    { label: 'Overview', url: c.URL_HOME },
    { label: 'For Hosters', url: c.URL_SWITCHTYPES },
    { label: 'Teams', url: c.URL_PORTALS },
    { label: 'Tracking', url: c.URL_ALERTS },
    { label: 'Downloads', url: c.URL_DOWNLOADS },
  ],
  hoster: [
    { label: 'Overview', url: c.URL_HOME },
    { label: 'Networking', url: c.URL_PODS },
    { label: 'Racks', url: c.URL_RACKS },
    { label: 'Compute', url: c.URL_MACHINES },
    { label: 'Hosts', url: c.URL_HOSTS },
    { label: 'Storage', url: c.URL_VOLUMES },
    { label: 'Teams', url: c.URL_HOSTERS },
    { label: 'Tracking', url: c.URL_ALERTS },
    { label: 'Downloads', url: c.URL_DOWNLOADS },
  ],
  bmaas_project: [
    { label: 'Hosts', url: c.URL_HOSTS },
    { label: 'Volumes', url: c.URL_VOLUMES },
    { label: 'Networks', url: c.URL_NETWORKS },
    { label: 'IP Pools', url: c.URL_IPPOOLS },
    { label: 'Settings', url: c.URL_VOLUME_ATTACHMENTS },
  ],
  project: [
    { label: 'Networks', url: c.URL_NETWORKS },
    { label: 'IP Pools', url: c.URL_IPPOOLS },
    { label: 'Hosts', url: c.URL_HOSTS },
    { label: 'Storage', url: c.URL_VOLUMES },
    { label: 'My Team', url: c.URL_PROJECTS, itemId: 'project_id' },
    { label: 'Downloads', url: c.URL_DOWNLOADS },
  ],
};

const SideNavBar = () => {
  const { activeMembership, role } = useMembershipContext();
  let content = null;

  if (role?.id && activeMembership?.id) {
    const navItems = auth.isIAM()
      ? navContent.bmaas_project
      : navContent[activeMembership.scope] || [];

    content = navItems.map(({ label, url, items, itemId }) =>
      items ? (
        <NavGroup key={label} label={label} items={items} />
      ) : (
        <NavItem key={label} label={label} url={url} itemId={itemId} />
      ),
    );
  }

  return (
    <Sidebar
      style={{ marginTop: '38px' }}
      as='nav'
      gap='medium'
      responsive={false}
      width='small'
      pad={{ horizontal: 'small', vertical: 'small' }}
    >
      {content}
    </Sidebar>
  );
};

export default SideNavBar;
