// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

import React from 'react';
import { useParams } from 'react-router';

import ListPage from '../../containers/generic/ListPage';
import { URL_ALERTS } from '../../routes/consts';
import { useGetSourceAlerts } from '../../services/alerts';

const ArrayAlertList = () => {
  const { id } = useParams();

  return (
    <ListPage
      actions={{}}
      path={`${URL_ALERTS}?source_id=${id}`}
      sort={{
        property: 'modified',
        direction: 'asc',
      }}
      parentId={id}
      useGetData={useGetSourceAlerts}
    />
  );
};

export default ArrayAlertList;
