// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { FormField, Select, Text } from 'grommet';

import { useGetPods } from '../../services/pods';
import { useGetNetworks } from '../../services/networks';
import { useMembershipContext } from '../../utils/context/membershipContext';

const DnsUpdateDropdown = ({ col, data }) => {
  const [ipValue, setIpValue] = useState('');
  const { role } = useMembershipContext();
  const { data: pods = [] } = useGetPods(role?.scope);
  const { data: networks } = useGetNetworks();

  const setParentForm = col.mb.view.setFormValue;

  const isView = useMemo(() => col.mb.props.mode === 'view', [col.mb.props]);

  const options = useMemo(() => {
    if (!networks?.length || !pods?.length || !data?.location_id) {
      return [];
    }

    const pod = pods.find(({ id }) => id === data.location_id);

    const usedIps = networks
      .filter(
        ({ location_id: podId, dns_update_config_ip: ip }) =>
          podId === pod.id && !!ip,
      )
      .map(({ dns_update_config_ip: ip }) => ip);

    const formIp = data.dns_update_config_ip;

    return (
      pod?.dns_update_configs
        ?.filter(({ ip }) => formIp === ip || !usedIps.includes(ip))
        .map(({ ip }) => ({
          label: ip,
          value: ip,
        })) || []
    );
  }, [data.dns_update_config_ip, data.location_id, networks, pods]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (!options?.length) {
      setIpValue('');

      if (data.dns_update_config_ip) {
        setParentForm('dns_update_config_ip', '');
      }
    } else {
      setIpValue(data.dns_update_config_ip || '');
    }
  }, [data.location_id, data.dns_update_config_ip, options, setParentForm]);

  const handleChange = ({ value }) => {
    setIpValue(value);
    setParentForm('dns_update_config_ip', value);
  };

  return isView ? (
    <>
      <Text weight={600}>DNS update IP</Text>
      <Text>{data.dns_update_config_ip}</Text>
    </>
  ) : (
    <FormField
      label='DNS Server'
      style={{ gridColumn: '1 / span 2', width: '400px' }}
    >
      <Select
        clear
        labelKey='label'
        onChange={handleChange}
        value={ipValue}
        valueKey={{ key: 'value', reduce: true }}
        options={options}
        placeholder='Select DNS update config IP'
      />
    </FormField>
  );
};

DnsUpdateDropdown.propTypes = {
  col: PropTypes.object,
  data: PropTypes.object,
};

export default DnsUpdateDropdown;
