// (C) Copyright 2016-2024 Hewlett Packard Enterprise Development LP

import React from 'react';
import defaultTo from 'lodash/defaultTo';
import find from 'lodash/find';
import get from 'lodash/get';
import includes from 'lodash/includes';
import matches from 'lodash/matches';
import uniqBy from 'lodash/uniqBy';
import { Box, Text } from 'grommet';
import { StatusGoodSmall, StatusWarning } from 'grommet-icons';
import {
  makeSecUrl,
  makeSecItemUrl,
  setId,
  SCOPE_HOSTER,
  URL_AVAIL_RESOURCES,
  URL_AVAIL_STORAGE_RESOURCES,
  URL_HOSTER_SERVICES,
  URL_HOSTS,
  URL_MACHINES,
  URL_MACHINESIZES,
  URL_PODS,
  URL_PROJECTS,
  URL_VOLUME_ATTACHMENTS,
  URL_NETWORKS,
} from '../../routes/consts.js';
import * as mb from '../../containers/generic/MetaBuilder';
import MetaBuilder, {
  ValidationResult,
} from '../../containers/generic/MetaBuilder';
import ColumnLink from '../../components/Griddle/ColumnLink';
import CustomLink from '../../components/Griddle/CustomLink';
import CreateViewContainer from '../../containers/generic/CreateViewContainer';
import EditViewContainer from '../../containers/generic/EditViewContainer';
import ItemViewContainer from '../../containers/generic/ItemViewContainer';
import ColumnProgressBar, {
  ProgressBar,
} from '../../components/Griddle/ColumnProgressBar';
import ColumnFormatter from '../../components/Griddle/ColumnFormatter';
import ColumnStatus from '../../components/Griddle/ColumnStatus';
import MachineActions, {
  ConsoleLaunchButton,
  MaintenanceLaunchButton,
  ReplaceLaunchButton,
  PowerButton,
} from '../../components/Griddle/MachineActions';
import { elapsed } from '../../lib/formatters.js';
import auth from '../../lib/auth';
import { getPath } from '../../lib/deep';
import { HOSTNAME, HOSTNAME_MSG, MAC } from '../../data/regex';
import FormReadOnlyField from '../../containers/generic/MetaForm/FormReadOnlyField';
import HostEditForm from './HostEditForm';
import { toDateTime } from '../../lib/formatters';
import { TagsDisplay } from '../../components/tags/TagsDisplay';
import { progressMapFunc } from '../../services/helper';
import debugLogger, * as log from '../../lib/debug';
import { TagsEditDisplay } from '../../components/tags/TagsEditDisplay';
import { ISCSI, attachProtocolLabels } from '../../data/volume.js';
import { LABEL_HOSTS } from '../../components/HybridNav/consts.js';
const debug = debugLogger('HostsView::index', log.LOG_LEVEL_DEBUG);

const StateProgress = ({ col, value, data }) => {
  const result = progressMapFunc(data);
  return (
    <FormReadOnlyField label={col.displayName}>
      <Box direction='row' gap='small'>
        <Text id={col.keyPath} key={col.keyPath}>
          {value}
        </Text>
        {result.active && (
          <ProgressBar progress={result.progress} status={result.style} />
        )}
      </Box>
    </FormReadOnlyField>
  );
};

const PowerState = ({ col, value }) => (
  <FormReadOnlyField label={col.displayName}>
    <Box direction='row' gap='small'>
      <StatusGoodSmall
        color={value === 'ON' ? 'status-ok' : 'status-disabled'}
      />
      <Text id={col.keyPath} key={col.keyPath}>
        {value}
      </Text>
    </Box>
  </FormReadOnlyField>
);

const CommStatus = ({ col, value, data }) => (
  <FormReadOnlyField label={col.displayName}>
    <Box direction='row' gap='small'>
      {!data.portal_comm_okay && <StatusWarning color='status-warning' />}
      <Text id={col.keyPath} key={col.keyPath}>
        {value}
      </Text>
    </Box>
  </FormReadOnlyField>
);

const HostItemHeadingControls = ({ form, addAlert }) => {
  const actionInfo = {
    powerStatus: form.power_status || 'OFF',
    addAlert,
    hostID: form.id,
    machineID: form.machine_id,
    pageType: 'host',
    state: form.state,
    consoleName: form.name,
  };
  const isHosterViewer = auth.activeRoleDef?.id === 'hoster_viewer';
  const disabled = isHosterViewer || form.portal_comm_okay !== true;

  let canReplace = true;
  let canMaintenance = true;

  if (form.power_status === 'ON') {
    canReplace = false;
    canMaintenance = false;
  }

  if (form.state !== 'Ready') {
    canMaintenance = form.state === 'Failed' && form.workflow === 'Maintenance';
    canReplace =
      (form.state === 'Failed' && form.workflow === 'Replace') ||
      form.workflow === 'Maintenance' ||
      form.workflow === 'Reimage';
  }

  return (
    <Box direction='row'>
      <PowerButton disabled={disabled} info={actionInfo} />
      <ConsoleLaunchButton disabled={disabled} info={actionInfo} />
      <MaintenanceLaunchButton disabled={!canMaintenance} info={actionInfo} />
      <ReplaceLaunchButton disabled={!canReplace} info={actionInfo} />
    </Box>
  );
};

const EXTERNAL_NAME = URL_HOSTS;

function metaItem(props) {
  const m = new MetaBuilder(props);
  const id = m.view.prop('itemId', false);
  const ds = m
    .newDataSource(URL_HOSTS)
    .Item(id)
    .OnLoad((json) => {
      debug.debug('got host, json.data:', json.data);
      m.view.initForm(json.data);
    })
    .Poll();

  let attachProtocol;

  const dsVolumeAttachments = m
    .newDataSource(URL_VOLUME_ATTACHMENTS)
    .Filter((t) => t.hostID === id)
    .OnLoad((json) => {
      attachProtocol = json?.data?.[0]?.attach_protocol;
    });

  const inHosterScope = auth.inScope(SCOPE_HOSTER);
  if (inHosterScope) {
    debug.debug('In Hoster Scope');
  }

  m.addTab('Info');
  m.addSection('1').NoChrome().MaxWidth(mb.LARGE);
  m.addColumn('name', 'Name');
  m.addColumn('description', 'Description');
  m.addColumn('id', 'ID');
  m.addColumn('project_id', 'Project ID');
  // m.addColumn('portal_id', 'Portal ID')
  // m.addColumn('hoster_id', 'Hoster ID')
  m.addColumn('created', 'Created').FieldXform((created) =>
    created ? toDateTime(created) : '--'
  );
  m.addColumn('modified', 'Modified').FieldXform((modified) =>
    modified ? toDateTime(modified) : '--'
  );
  m.addColumn('brownfield', 'Imported').FieldXform((val) =>
    val === true ? 'Yes' : 'No'
  );

  m.addSection('Host State').MaxWidth(mb.LARGE);
  m.addColumn('state', 'State').Custom(StateProgress);
  m.addColumn('state_time', 'State started').FieldXform((state_time) =>
    state_time ? toDateTime(state_time) : '--'
  );
  m.addColumn('substate', 'Step');
  m.addColumn('substate_time', 'Step started').FieldXform((substate_time) =>
    substate_time ? toDateTime(substate_time) : '--'
  );
  m.addColumn('portal_comm_okay', 'Comm status')
    .FieldXform((val) => (val ? 'OK' : 'NC'))
    .Custom(CommStatus);
  m.addColumn('power_status', 'Power').Custom(PowerState);

  const eventTable = m
    .addInputTable('events', 'Events')
    .ReadOnly()
    .DataXform(ds, (json) => {
      if (json.events === undefined) {
        return [];
      }
      return json.events;
    });
  eventTable
    .addField('type', 'Event type')
    .CellXform((rowData) => rowData.type);
  eventTable
    .addField('user_name', 'User name')
    .CellXform((rowData) => rowData.user_name);
  eventTable
    .addField('time', 'Time')
    .CellXform((rowData) => toDateTime(rowData.time));

  m.addColumn('alert', 'Alert').FieldXform((val) => (val ? 'Yes' : 'No'));

  const alertTable = m
    .addInputTable('alert_info', 'Alert info')
    .ReadOnly()
    .DataXform(ds, (json) => {
      if (json.alert_info === undefined) {
        return [];
      }
      return json.alert_info;
    });
  alertTable.addField('alert', 'Alert').CellXform((rowData) => rowData.alert);
  alertTable
    .addField('workflow', 'Workflow')
    .CellXform((rowData) => rowData.workflow);
  alertTable
    .addField('host_state', 'State')
    .CellXform((rowData) => rowData.host_state);
  alertTable
    .addField('host_substate', 'Step')
    .CellXform((rowData) => rowData.host_substate);
  alertTable
    .addField('message', 'Message')
    .CellXform((rowData) => (
      <Box>
        <Text truncate="true" title={rowData.message}>
          {rowData.message}
        </Text>
      </Box>
    ));

  alertTable.addField('time', 'Time').CellXform((rowData) => rowData.time);

  m.addSection('Location').MaxWidth(mb.LARGE);
  m.addColumn('location.country', 'Country');
  m.addColumn('location.region', 'Region');
  m.addColumn('location.data_center', 'Data center');

  if (inHosterScope) {
    const urlPods = m.newDataSource(URL_PODS);
    m.addColumn('location_id', 'Pod Name')
      .DropDown()
      .ReadOnly()
      .DataXform(urlPods, (json) =>
        json.map((t) => ({ id: t.id, name: t.name }))
      )
      .ShowLink();
  }

  m.addSection('Details').MaxWidth(mb.LARGE);
  m.addColumn('svc_flavor', 'OS flavor');
  m.addColumn('svc_version', 'OS version');

  if (inHosterScope) {
    m.addColumn('svc_id', 'Image svc ID').MakeLink(URL_HOSTER_SERVICES);
    m.addColumn('machine_id', 'Machine ID').MakeLink(URL_MACHINES);
    const urlMacheSize = m.newDataSource(URL_MACHINESIZES);
    m.addColumn('machine_size_id', 'Machine size')
      .DropDown()
      .ReadOnly()
      .DataXform(urlMacheSize, (json) =>
        json.map((t) => ({ id: t.id, name: t.name }))
      )
      .ShowLink();
    m.addColumn('machine_size_id', 'Machine size ID').MakeLink(
      URL_MACHINESIZES
    );
  } else {
    m.addColumn('machine_id', 'Machine ID');
    m.addColumn('machine_size_name', 'Machine size');
  }

  m.addColumn('ssh_authorized_keys', 'SSH keys').TextArea();
  // m.addColumn('deleted', 'Deleted').FieldXform( (val) : 'true' : 'false')

  /*
  m.addSection('Connections').Table(ConnectionsListView).Expanded()
  */
  m.addSection('Networks').MaxWidth(mb.XLARGE);
  const networkTable = m
    .addInputTable('_tmp.networks', 'Network')
    .ReadOnly()
    .DataXform(ds, (json) => {
      if (json.connections === undefined) {
        return [];
      }
      return convertConnections(json);
    });
  networkTable
    .addField('network', 'Network')
    .CellXform((rowData) => rowData.network);
  networkTable
    .addField('macs', 'MAC Address')
    .CellXform((rowData) => rowData.macs);
  networkTable
    .addField('connection', 'Connection')
    .CellXform((rowData) => rowData.connection);
  networkTable.addField('ip', 'IP Address').CellXform((rowData) => rowData.ip);
  networkTable
    .addField('gateway', 'Gateway')
    .CellXform((rowData) => rowData.gateway);
  networkTable.addField('dns', 'DNS').CellXform((rowData) => rowData.dns);
  networkTable.addField('vid', 'VLAN ID').CellXform((rowData) => rowData.vid);
  networkTable.addField('vni', 'VNI').CellXform((rowData) => rowData.vni);

  m.addColumn('network_untagged', 'Untagged network').Custom(CustomLink, {
    info: (rowData) => {
      const url = setId(makeSecItemUrl(URL_NETWORKS), rowData.network_untagged);
      const network = rowData.connections?.[0]?.networks.find(
        (network) => network.network_id === rowData.network_untagged
      );

      return { url, name: network?.name ?? '' };
    },
  });

  m.addColumn('network_for_default_route', 'Network for Default Route').Custom(
    CustomLink,
    {
      info: (rowData) => {
        const url = setId(
          makeSecItemUrl(URL_NETWORKS),
          rowData.network_for_default_route
        );
        const network = rowData.connections?.[0]?.networks.find(
          (network) => network.network_id === rowData.network_for_default_route
        );

        return { url, name: network?.name ?? '' };
      },
    }
  );

  m.addSection('Connections').MaxWidth(mb.LARGE);
  const connectionTable = m
    .addInputTable('connections', 'Connection')
    .ReadOnly()
    .DataXform(ds, (json) => {
      if (json.connections === undefined) {
        return [];
      }
      return json.connections;
    });
  connectionTable.addField('name', 'Name').CellXform((rowData) => rowData.name);
  connectionTable
    .addField('ports', 'Ports')
    .CellXform((rowData) =>
      portNames(getPath(rowData, 'ports', [])).join(', ')
    );
  connectionTable
    .addField('ha', 'HA')
    .CellXform((rowData) => (rowData.ha ? 'yes' : 'no'));
  connectionTable
    .addField('speed', 'Speed')
    .CellXform((rowData) => rowData.speed);

  m.addSection('Volume attachments').MaxWidth(mb.LARGE);
  const volumeAttachmentsTable = m
    .addInputTable('volumeattachments', 'Volume attachment')
    .DataXform(dsVolumeAttachments, (json) => json || []);

  volumeAttachmentsTable
    .addField('name', 'Name')
    .CellXform((rowData) => rowData.name);

  volumeAttachmentsTable
    .addField('attach_protocol', 'Protocol')
    .CellXform(
      (rowData) => attachProtocolLabels[rowData.attach_protocol] || ''
    );

  volumeAttachmentsTable
    .addField('volumeID', 'Volume ID')
    .CellXform((rowData) => rowData.volumeID);

  volumeAttachmentsTable
    .addField('volumeTargetIQN', 'Target IQN')
    .CellXform((rowData) => rowData.volumeTargetIQN)
    .Visible(() => attachProtocol === ISCSI);

  volumeAttachmentsTable
    .addField('volumeTargetIP', 'Target IP')
    .CellXform((rowData) => rowData.volumeTargetIPAddress)
    .Visible(() => attachProtocol === ISCSI);

  m.addSection('Tags').MaxWidth(mb.LARGE);
  m.addColumn('labels', 'Tags').Custom(TagsDisplay, {
    hasSectionHeader: true,
  });

  return m;
}

function portNames(ports) {
  const result = [];
  for (const port of ports) {
    result.push(`${port.name}=${port.hw_addr}`);
  }
  return result;
}

function convertConnections({
  connections,
  service_nets_provider_mac: providerMac,
}) {
  const result = [];

  for (const connection of connections) {
    for (const network of connection.networks) {
      const item = {
        connection: connection.name,
        network: network.name,
        ip: network.ip + network.netmask,
        gateway: network.gateway,
        dns: getPath(network, 'dns', []).join(', '),
        vid: network.vlan,
        vni: network.vni,
        macs: providerMac?.[network.network_id],
      };

      result.push(item);
    }
  }

  return result;
}

function metaList(props) {
  const m = new MetaBuilder(props);
  let dsProjects;
  const inHosterScope = auth.inScope(SCOPE_HOSTER);
  if (inHosterScope) {
    dsProjects = m.newDataSource(URL_PROJECTS);
  }

  m.addRowClick((navigate) => ({ datum: { id } }) => {
    navigate(setId(makeSecItemUrl(EXTERNAL_NAME), id));
  });

  m.addColumn('alert', 'Alert').Custom(ColumnFormatter, {
    format: (rowData) => (rowData.alert ? 'yes' : 'no'),
  });
  m.addColumn('name', 'Name')
    .Custom(ColumnLink, {
      info: (rowData) => {
        const url = setId(makeSecItemUrl(EXTERNAL_NAME), rowData.id);
        const { name } = rowData;
        return { url, name };
      },
    })
    .Default()
    .Input()
    .Required();
  m.addColumn('id', 'ID');
  m.addColumn('description', 'Description').Default();

  if (inHosterScope) {
    m.addColumn('project_id', 'Project name')
      .Custom(ColumnFormatter, {
        format: ({ project_id }) => {
          const data = dsProjects.Data();
          const project = data.find((datum) => datum.project_id === project_id);
          return project?.name || '';
        },
      })
      .Default();
  }

  m.addColumn('machine_size_name', 'Machine size').Default();
  m.addColumn('portal_comm_okay', 'Comm status')
    .Custom(ColumnStatus, {
      info: ({ portal_comm_okay }) =>
        portal_comm_okay
          ? { content: 'OK' }
          : {
              content: 'NC',
              status: 'status-warning',
              tooltip: 'Management connection lost to this host',
            },
    })
    .Default();
  m.addColumn('workflow', 'Workflow').Default();
  m.addColumn('state', 'State').Default();
  m.addColumn('substate', 'Step').Default();
  m.addColumn('_elapsed', 'Elapsed')
    .Custom(ColumnFormatter, {
      format: (rowData) => elapsed(rowData.substate_time),
      update: 1000,
    })
    .Default();
  m.addColumn('progress', 'Progress')
    .Custom(ColumnProgressBar, {
      mapFunc: progressMapFunc,
    })
    .Default();
  m.addColumn('guiactions', 'Actions')
    .Custom(MachineActions, {
      info: (rowData) => {
        const hostID = rowData.id;
        const machineID = rowData.machine_id;
        const pageType = 'host';
        const consoleName = rowData.name;
        const powerStatus = rowData.power_status;
        const portalCommOkay = rowData.portal_comm_okay;
        return {
          hostID,
          machineID,
          consoleName,
          powerStatus,
          addAlert: m.view.addAlert,
          pageType,
          portalCommOkay,
        };
      },
    })
    .Default()
    .NotSearchable();
  m.addColumn('created', 'Created').FieldXform((created) =>
    created ? toDateTime(created) : '--'
  );
  m.addColumn('modified', 'Modified').FieldXform((modified) =>
    modified ? toDateTime(modified) : '--'
  );
  m.addColumn('svc_id', 'Image svc ID');
  m.addColumn('svc_flavor', 'OS flavor');
  m.addColumn('svc_version', 'OS version');
  m.addColumn('state_time', 'State started').FieldXform((state_time) =>
    state_time ? toDateTime(state_time) : '--'
  );
  m.addColumn('substate_time', 'Step started');
  m.addColumn('power_status', 'Power');
  m.addColumn('location_id', 'Location ID');
  m.addColumn('location.country', 'Country');
  m.addColumn('location.region', 'Region');
  m.addColumn('location.data_center', 'Data center');
  m.addColumn('ssh_authorized_keys', 'SSH keys').TextArea();
  m.addColumn('networks', 'Networks').Custom(ColumnFormatter, {
    format: (rowData) => getPath(rowData, 'networks', []).join(', '),
  });
  m.addColumn('deleted', 'Deleted').Custom(ColumnFormatter, {
    format: (rowData) => (rowData.deleted ? 'yes' : 'no'),
  });
  m.addColumn('portal_id', 'Portal ID');
  m.addColumn('hoster_id', 'Hoster ID');
  m.addColumn('machine_size_id', 'Machine size ID');
  m.addColumn('machine_id', 'Machine ID');
  m.addColumn('labels', 'Tags').Custom(TagsDisplay, {
    info: (rowData) => {
      const labels = rowData.labels || [];
      return {
        labels,
      };
    },
  });

  return m;
}

function metaCreate(props) {
  const m = new MetaBuilder(props);

  let dsProjects = [];
  const dsAvail = m.newDataSource(URL_AVAIL_RESOURCES);
  const dsVolAvail = m.newDataSource(URL_AVAIL_STORAGE_RESOURCES, 'dsVolAvail');
  const dsVersion = m.newStaticDataSource([]);

  const dsNetworks = m.newStaticDataSource([]);
  const dsExistingVols = m.newStaticDataSource([], 'dsExistVols');
  const dsLocsAvail = m.newStaticDataSource([], 'dsLocsAvail');
  const setNetworkData = (val) => {
    const networks = get(dsAvail.Data(), 'networks', []).filter(
      (i) => i.location_id === val
    );
    const defaultNetworks = networks.filter((i) => i.host_use !== 'Optional');
    m.view.setFormValue(
      'networks',
      defaultNetworks.map((t) => t.id)
    );
    debug.debug('setting networks to:', networks);
    dsNetworks.SetData(networks);
  };

  const setExistingVols = (val) => {
    const eVols = get(dsVolAvail.Data(), 'volumes', []).filter(
      (i) => i.pod_id === val
    );
    dsExistingVols.SetData(eVols);
    // Hide or show the ability to create volumes as well as the host based on the availability
    // of storage resources in the given location.
    vSection.Visible(
      () =>
        get(dsVolAvail.Data(), 'locations', []).filter((i) => i.id === val)
          .length > 0
    );
  };

  const setAvailableLocs = (sizeId) => {
    // filter available inventory by sizeId
    const locationsWithSize = get(dsAvail.Data(), 'inventory', [])
      .filter(({ size_id, number }) => size_id === sizeId && number > 0)
      .map(({ loc_id }) => loc_id);
    const locations = get(dsAvail.Data(), 'locations', []).filter(({ id }) =>
      includes(locationsWithSize, id)
    );
    dsLocsAvail.SetData(locations);
  };

  const inHosterScope = auth.inScope(SCOPE_HOSTER);
  if (inHosterScope) {
    debug.debug('In Hoster Scope');
    dsProjects = m.newDataSource(URL_PROJECTS);
  }

  const setVersionData = (val) => {
    const images = get(dsAvail.Data(), 'images', []).filter(
      (i) => i.flavor === val
    );
    const versions = images.map((i) => ({ id: i.version, name: i.version }));
    m.view.setFormValue('svc_version', '');
    dsVersion.SetData(versions);
  };

  m.addSection('1').NoChrome().MaxWidth(mb.LARGE);

  // If hoster, select project that will own the host (for on-behalf-of)
  if (inHosterScope) {
    m.addField('_temp.project', 'On Behalf of Project')
      .Input()
      .DropDown()
      .DataXform(dsProjects, (json) => {
        // using id of 'self' because a value of '' causes loop
        const tms = [{ id: 'self', name: 'Self' }];
        return tms.concat(json.map((t) => ({ id: t.id, name: t.name })));
      })
      .Width(mb.SMALL)
      .OnChange((val) => {
        if (val !== undefined) {
          if (val === 'self') {
            dsAvail.DeleteQuery('project');
            dsVolAvail.DeleteQuery('project');
            val = '';
          } else {
            dsAvail.AddQuery('project', val);
            dsVolAvail.AddQuery('project', val);
          }
          // TODO: Somehow dsAvail.Fetch() completion triggers Location ID OnInit().
          // Fetch available volumes first to ensure volume data is there when
          // setExistingVols() is called from Location ID OnInit().
          dsVolAvail.Fetch();
          dsAvail.Fetch();
          m.view.setFormValue('project_id', val);

          // Clear out previously selected SSH keys & volumes.
          m.view.setFormValue('ssh_key_ids', []);
          m.view.setFormValue('volumes', []);
          ds.Set('volumes', []);
        }
      });
  }

  m.addColumn('name', 'Host Name')
    .Input()
    .Required()
    .Width(mb.SMALL)
    .RegEx(HOSTNAME, HOSTNAME_MSG);

  if (props.readOnly) {
    m.addColumn('id', 'ID').Input().ReadOnly();
  }

  m.addColumn('description', 'Description').Input().Width(mb.SMALL);

  m.addColumn('svc_flavor', 'Image Flavor')
    .Input()
    .Required()
    .DropDown()
    .DataXform(dsAvail, (json) =>
      uniqBy(get(json, 'images', []), 'flavor').map((i) => ({
        id: i.flavor,
        name: i.flavor,
      }))
    )
    .Width(mb.SMALL)
    .OnChange((val) => {
      setVersionData(val);
    })
    .OnInit(() => {
      setVersionData(m.view.formDefaultTo('svc_flavor'));
    });

  m.addColumn('svc_version', 'Image version')
    .Input()
    .Required()
    .DropDown()
    .DataXform(dsVersion, (json) => json)
    .Width(mb.SMALL);

  const getLoc = (locId) => {
    const loc = defaultTo(find(dsAvail.Data().locations || [], { id: locId }), {
      data_center: '',
    });
    return loc.data_center;
  };

  const getLocsForSize = (sizeId) => {
    const inventory = get(dsAvail.Data(), 'inventory', []).filter(
      ({ size_id }) => size_id === sizeId
    );
    return inventory.map(({ loc_id }) => getLoc(loc_id));
  };

  m.addColumn('machine_size_id', 'Machine size')
    .Input()
    .Required()
    .DropDown()
    .DataXform(dsAvail, (json) =>
      get(json, 'sizes', []).map(({ id, name }) => {
        const locs = getLocsForSize(id);
        const help = locs.length > 0 ? '' : 'none available';
        return {
          id,
          name,
          help,
        };
      })
    )
    .CustomValidator((val) => {
      const locs = getLocsForSize(val);
      return new ValidationResult(
        locs.length > 0,
        locs.length > 0
          ? ''
          : 'No locations have this size available. Choose a different machine size'
      );
    })
    .OnChange((val) => {
      const szs = dsAvail.Data().sizes.filter((a) => a.id === val);
      debug.debug('szs:', szs);
      m.view.setFormValue(
        'machine_size_name',
        get(szs, ['0', 'name'], 'no name')
      );
      setAvailableLocs(val);
    })
    .OnInit(() => {
      setAvailableLocs(m.view.formDefaultTo('machine_size_id'));
    })
    .Width(mb.SMALL);

  const getCountForLoc = (loc_id, size_id) => {
    const inventory = get(dsAvail.Data(), 'inventory', []);
    const count = find(inventory, { loc_id, size_id });
    return count ? count.number : 0;
  };

  m.addColumn('location_id', 'Location')
    .Input()
    .Required()
    .DropDown('Choose a machine size first', 'Choose a machine size first')
    .DataXform(dsLocsAvail, (json) =>
      json.map(({ id, country, region, data_center }) => ({
        id,
        name: `${country}: ${region}: ${data_center}`,
      }))
    )
    .CustomValidator((val) => {
      const sizeId = get(m.view.getForm(), 'machine_size_id', '');
      const count = getCountForLoc(val, sizeId);
      return new ValidationResult(
        count > 0,
        count > 0
          ? ''
          : 'No machines of that size available at this location. Choose a different machine size or location'
      );
    })
    .OnChange((val) => {
      const locs = dsAvail.Data().locations.filter((a) => a.id === val);
      m.view.setFormValue(
        'location.country',
        get(locs, ['0', 'country'], 'no country')
      );
      m.view.setFormValue(
        'location.region',
        get(locs, ['0', 'region'], 'no region')
      );
      m.view.setFormValue(
        'location.data_center',
        get(locs, ['0', 'data_center'], 'no dc')
      );
      setNetworkData(val);
      setExistingVols(val);
    })
    .OnInit(() => {
      setNetworkData(m.view.formDefaultTo('location_id'));
      setExistingVols(m.view.formDefaultTo('location_id'));
    })
    .Width(mb.SMALL);

  m.addColumn('ssh_key_ids', 'SSH keys')
    .Input()
    .Required()
    .MultiSelect()
    .DataXform(dsAvail, (json) =>
      get(json, 'ssh_keys', []).map((k) => ({
        id: k.key,
        name: k.value,
      }))
    )
    .Width(mb.SMALL);

  m.addColumn('networks', 'Networks')
    .Input()
    .Required()
    .MultiSelect(
      'Choose a machine size and location first',
      'Choose a machine size and location first'
    )
    .DataXform(dsNetworks, (json) =>
      json.map((t) => ({
        id: t.id,
        name: t.name,
        required: t.host_use === 'Required',
      }))
    )
    .Width(mb.SMALL);

  const ds = m.newStaticDataSource({});

  const vSection = m
    .addSection('Volumes')
    .MaxWidth(mb.LARGE)
    .OverviewText('Create or add existing volumes to this host.');

  const volumesTable = m
    .addInputTable('volumes', 'New volumes')
    .DataXform(ds, (json) => {
      debug.debug('volume:DataXform', ds, json);
      return json.volumes || [];
    });

  volumesTable.AddDialogMeta(metaVolumesAdd);
  volumesTable.OnAdd((data) => {
    if (typeof data.id !== 'undefined') {
      data = dsExistingVols.data.filter((v) => v.id === data.id)[0];
      dsExistingVols.data = dsExistingVols.data.filter((v) => v.id !== data.id);
      data.flavor = dsVolAvail.data.flavor.filter(
        (f) => f.id === data.flavor_id
      )[0].name;
    }
    ds.Push('volumes', data); // add to dataset used to render the page
    m.view.addFormValue('volumes', data); // add to form to be posted/put
  });

  volumesTable.OnDelete((data) => {
    debug.debug('onDelete(volumes)', data);
    if (typeof data.id !== 'undefined') {
      dsExistingVols.data.push(data);
    }
    // remove volume
    const vMatch = matches({ name: data.name });
    ds.Remove('volumes', vMatch);
    m.view.removeFormValue('volumes', vMatch);
  });

  volumesTable.EditDialogMeta(metaVolumesAdd); // use same for now
  volumesTable.OnEdit((newData, oldData) => {
    debug.debug('onEdit(volumes)', oldData, newData);
    const vMatch = matches({ name: oldData.name });
    ds.Update('volumes', vMatch, newData, true);
    m.view.updateFormValue('volumes', vMatch, newData, true);
  });

  volumesTable.addField('name', 'Name').CellXform((rowData) => rowData.name);
  volumesTable
    .addField('description', 'Description')
    .CellXform((rowData) => rowData.description);
  volumesTable
    .addField('capacity', 'Size (GiB)')
    .CellXform((rowData) => rowData.capacity);
  volumesTable
    .addField('flavor', 'Flavor')
    .CellXform((rowData) => rowData.flavor);

  m.addSection('Tags')
    .MaxWidth(mb.LARGE)
    .OverviewText('Create and add tags to this host.');
  const tagsComponent =
    props.mode === 'create' || props.mode === 'edit'
      ? TagsEditDisplay
      : TagsDisplay;

  m.addColumn('labels', 'Tags').Input().Custom(tagsComponent);

  return m;
}

function metaVolumesAdd(props) {
  const m = new MetaBuilder(props);

  m.addField('name', 'Name')
    .Input()
    .RequiredSometimes(() => m.view.formDefaultTo('existing') === 'new')
    .Visible(() => m.view.formDefaultTo('existing') === 'new');

  m.addField('description', 'Description')
    .Input()
    .Visible(() => m.view.formDefaultTo('existing') === 'new');

  m.addField('capacity', 'Size (GiB)')
    .Input()
    .Number(1, 4294967294)
    .RequiredSometimes(() => m.view.formDefaultTo('existing') === 'new')
    .Visible(() => m.view.formDefaultTo('existing') === 'new');

  m.addField('flavor_id', 'Flavor')
    .Input()
    .DropDown()
    .DataXform(m.getDataSource('dsVolAvail'), (json) =>
      get(json, 'flavor', '').map((i) => ({ id: i.id, name: i.name }))
    )
    .OnChange((val) => {
      const flavors = m.getDataSource('dsVolAvail').data.flavor;
      const flavor = flavors.filter((f) => f.id === val);
      m.view.setFormValue('flavor', flavor[0].name);
    })
    .MaxWidth(mb.SMALL)
    .RequiredSometimes(() => m.view.formDefaultTo('existing') === 'new')
    .Visible(() => m.view.formDefaultTo('existing') === 'new');

  m.addField('flavor', 'Flavor name');

  m.addColumn('id', 'ID')
    .Input()
    .DropDown()
    .RequiredSometimes(() => m.view.formDefaultTo('existing') === 'existing')
    .Visible(() => m.view.formDefaultTo('existing') === 'existing')
    .DataXform(m.getDataSource('dsExistVols'), (json) =>
      json.map((t) => ({ id: t.id, name: t.name }))
    )
    .Width(mb.SMALL);

  m.addColumn('existing', 'Volume source')
    .Input()
    .Required()
    .RadioGroup()
    .DataXform(
      m.newStaticDataSource([
        { id: 'new', name: 'Create New' },
        { id: 'existing', name: 'Use Existing' },
      ]),
      (json) => json
    )
    .Width(mb.SMALL);

  return m;
}

function metaEdit(props) {
  const m = new MetaBuilder(props);
  const id = m.view.prop('itemId', false);
  m.newDataSource(URL_HOSTS)
    .Item(id)
    .OnLoad((json) => {
      m.view.initForm(json.data);
    });

  const dsNetworks = m.newDataSource(URL_NETWORKS);

  m.addField('service_nets_provider_mac', 'Host edit')
    .Input()
    .Custom(HostEditForm, { dsNetworks })
    .CustomValidator(({ __uniqueid, ...payload } = {}) => {
      const isValid = Object.keys(payload).every(
        (networkId) => !payload[networkId] || MAC.test(payload[networkId]),
      );

      return new ValidationResult(isValid, '');
    });

  return m;
}

export const settings = {
  // used to filter create/trash icons from the view;
  // using the defined roledef permissions mappings to this auth URL entry
  authUrl: EXTERNAL_NAME,
  // homepage for this list view (e.g. /s/hosts/:pid/) is where
  // this view is located; for return from create / item views
  homeUrl: makeSecUrl(EXTERNAL_NAME),
  homeLabel: LABEL_HOSTS,
  // base url to be used for creating all associated URLS for this reasource,
  // e.g. pageItem, pageCreate, restUrl, restItemUrl
  baseUrl: EXTERNAL_NAME,
  meta: (props) => metaList(props), //  list view by default
};

export { default as HostListView } from './HostListView';

export const HostItemView = ItemViewContainer({
  ...settings,
  allowEdit: true,
  meta: (props) => metaItem(props),
  title: 'Host',
  HeadingControls: HostItemHeadingControls,
});

export const HostCreateView = CreateViewContainer({
  ...settings,
  meta: (props) => metaCreate(props),
  title: 'Create Host',
});

export const HostEditView = EditViewContainer({
  ...settings,
  meta: (props) => metaEdit(props),
  title: 'Edit Host',
});
