// (C) Copyright 2020-2024 Hewlett Packard Enterprise Development LP

import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { Text } from 'grommet';
import * as c from '../../../routes/consts.js';
import { Link } from '../../../components';

const LinksCell = ({ col, ids, data }) => {
  if (isEmpty(data)) {
    if (isEmpty(ids) || isEmpty(ids[0])) {
      return <Text />;
    }
  }

  const links = [];
  const nameLookup = new Map();

  for (const item of data) {
    nameLookup.set(item.id, item.name);
  }

  for (const id of ids) {
    if (!isEmpty(links)) {
      links.push(<br key={links.length} />);
    }
    let friendlyName = nameLookup.get(id);
    if (friendlyName === undefined) {
      friendlyName = id;
    }

    let url = get(col, 'dataSource.baseUrl');

    if (url !== undefined) {
      url = c.setId(c.makeSecItemUrl(url), id);
    }

    if (url === undefined || !col.showLink) {
      links.push(<Text key={links.length}>{friendlyName}</Text>);
      continue;
    }

    // lookup friendly name, if available
    if (friendlyName === undefined) {
      links.push(<Link key={links.length} to={url} title={id} />);
    } else {
      links.push(<Link key={links.length} to={url} title={friendlyName} />);
    }
  }

  return <Text color='text-strong'>{links}</Text>;
};

LinksCell.propTypes = {
  col: PropTypes.shape({
    showLink: PropTypes.bool,
  }).isRequired,
  ids: PropTypes.arrayOf(PropTypes.string),
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
};
export default LinksCell;
