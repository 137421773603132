// (C) Copyright 2023-2024 Hewlett Packard Enterprise Development LP

import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, FormField, Text, TextInput } from 'grommet';
import { FormAdd, Trash } from 'grommet-icons';

import { DOMAIN, DOMAIN_MSG } from '../../../data/regex';

const FormDnsSearchDomain = ({ col, data }) => {
  const [domains, setDomains] = useState(['']);
  const [errors, setErrors] = useState({});
  const isView = useMemo(() => col.mb.props.mode === 'view', [col]);

  useEffect(() => {
    const domainData = data.dns_search_domains?.split(',');

    if (domainData?.length) {
      setDomains(domainData);
    }
  }, [data]);

  useEffect(() => {
    const value = domains.join(',');
    col.mb.view.setFormValue('dns_search_domains', value);
  }, [col.mb.view, domains]);

  useEffect(() => {
    const errorMap = {};

    domains.forEach((domain, i) => {
      const regex = new RegExp(DOMAIN);
      const error = domain && !regex.test(domain);
      errorMap[i] = error ? DOMAIN_MSG : '';
    });

    setErrors(errorMap);
  }, [domains]);

  const canAdd = useMemo(() => {
    const hasError = Object.values(errors).find((error) => !!error);
    const hasEmpty = domains.filter((domain) => !domain).length;

    return !hasError && !hasEmpty;
  }, [domains, errors]);

  const addDomain = () => {
    setDomains((prevDomains) => [...prevDomains, '']);
  };

  const handleChange =
    (index) =>
    ({ target: { value } }) => {
      setDomains((prevDomains) => [
        ...prevDomains.slice(0, index),
        value,
        ...prevDomains.slice(index + 1),
      ]);
    };

  const handleDelete = (index) => (e) => {
    e.preventDefault();

    setDomains((prevDomains) => [
      ...prevDomains.slice(0, index),
      ...prevDomains.slice(index + 1),
    ]);
  };

  return (
    <Box style={{ gridColumn: '1 / span 2' }}>
      <Text weight={isView ? 600 : 500} size={isView ? 'medium' : 'xsmall'}>
        DNS search domain
      </Text>
      {domains.map((domain, i) => (
        <Box direction='row' gap='small' key={`domain-${i}`}>
          {isView ? (
            <Box margin='xsmall'>
              <Text size='medium'>{domain}</Text>
            </Box>
          ) : (
            <FormField error={errors[i]}>
              <TextInput onChange={handleChange(i)} value={domain} />
            </FormField>
          )}
          {!isView ? (
            <Button
              icon={<Trash color='brand' />}
              onClick={handleDelete(i)}
              tip='Delete'
            />
          ) : null}
        </Box>
      ))}
      {!isView ? (
        <Box direction='row' pad={{ vertical: 'small' }}>
          <Button
            disabled={!canAdd}
            icon={<FormAdd />}
            label='Add DNS search domain'
            onClick={addDomain}
            reverse
            secondary
          />
        </Box>
      ) : null}
    </Box>
  );
};

FormDnsSearchDomain.propTypes = {
  col: PropTypes.any,
  data: PropTypes.any,
};

export default FormDnsSearchDomain;
