// (C) Copyright 2016-2024 Hewlett Packard Enterprise Development LP

import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Box, ResponsiveContext, Text } from 'grommet';
import styled from 'styled-components';
import auth from '../../lib/auth';
import { tabToSectionMap } from './consts';

const StyledLink = styled(({ isSelected, topNav, ...rest }) => (
  <NavLink {...rest} />
))`
  align-items: center;
  display: inline-flex;
  text-decoration: none;
  user-select: none;

  ${({ isSelected }) =>
    isSelected &&
    `
    background-color: #f7f7f7;
    border-radius: 15px;
    span {
      color: #333;
    }
  `}

  &.active,
  :hover {
    background-color: #f7f7f7 !important;
    border-radius: 15px;
    span {
      color: ${({ topNav }) => (topNav ? '#333' : '#333')} !important;
    }
  }

  :hover {
    background-color: ${({ topNav }) => (topNav ? 'transparent' : '#FFFFFF14')};
  }
`;

const NavButton = ({ label, path, sub }) => {
  const size = useContext(ResponsiveContext);
  const isIAM = auth.isIAM();
  const topNav = isIAM || size === 'small';
  const { pathname } = useLocation();
  const isHomePage = pathname === '/' && path === '/s/home';
  const isSelected = isHomePage
    ? true
    : (tabToSectionMap[pathname.split('/')[2]] || []).includes(
        path.split('/')[2],
      );

  return (
    <StyledLink
      activeClassName='active'
      to={path}
      topNav={topNav}
      isSelected={isSelected}
    >
      <Box pad={{ vertical: 'xsmall', horizontal: sub ? 'medium' : 'small' }}>
        <Text weight='bold' color='text'>
          {label}
        </Text>
      </Box>
    </StyledLink>
  );
};

NavButton.propTypes = {
  label: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  sub: PropTypes.bool,
};

export default NavButton;
