// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

export const POD_TYPE_L2 = 'L2';
export const POD_TYPE_L3_WITH_VXLANS = 'L3 with VXLANs';
export const POD_TYPE_PLEXXI = 'HPE Composable Fabric';
export const POD_TYPE_AFC = 'Aruba Fabric Composer';

export const DEFAULT_PODTYPE = POD_TYPE_L2;

export const CONTROLLER_TYPE_POD_CTLR = 'Pod Controller';
export const CONTROLLER_TYPE_RACK_CTLR = 'Rack Controller';
export const DEFAULT_CONTROLLER_TYPE = CONTROLLER_TYPE_POD_CTLR;

export const CONTROLLER_TYPE_ENUMS = [
  { name: 'Data-Center-Wide Controller', id: CONTROLLER_TYPE_POD_CTLR },
  { name: 'Per-Rack Controller', id: CONTROLLER_TYPE_RACK_CTLR },
];
