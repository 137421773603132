// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

import React from 'react';

import ListPageWithNav from '../../containers/generic/ListPageWithNav';
import { URL_RACKTEMPLATES } from '../../routes/consts';
import { useGetRackTemplates } from '../../services/rackTemplates';
import { NAV_RACKS } from '../../components/HybridNav/consts.js';

const RackTemplateListView = () => (
  <ListPageWithNav
    navSection={NAV_RACKS}
    actions={{
      allowCreate: 'Create rack template',
    }}
    path={URL_RACKTEMPLATES}
    sort={{
      property: 'name',
      direction: 'asc',
    }}
    title='Rack templates'
    useGetData={useGetRackTemplates}
  />
);

export default RackTemplateListView;
