// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

import { useQuery } from 'react-query';

import HealthStatus from '../components/HealthStatus';
import { toDateTime } from '../lib/formatters';
import { get } from '../lib/rest';
import { makeRestUrl, URL_SWITCHES } from '../routes/consts';
import { renderLink, Status } from './helper';

const columns = [
  {
    header: 'Status',
    isDefault: true,
    property: 'health_status',
    render: ({ health_status: status }) => (
      <HealthStatus status={status} showLabel />
    ),
  },
  {
    header: 'Name',
    isDefault: true,
    property: 'name',
    render: renderLink({
      base: URL_SWITCHES,
    }),
  },
  {
    header: 'ID',
    property: 'id',
  },
  {
    header: 'Created',
    property: 'created',
    render: ({ created }) => toDateTime(created),
  },
  {
    header: 'Modified',
    property: 'modified',
    render: ({ modified }) => toDateTime(modified),
  },
  {
    header: 'Switch type',
    property: 'type_id',
  },
  {
    header: 'Comm status',
    isDefault: true,
    property: 'portal_comm_okay',
    render: ({ portal_comm_okay: okay, unmanaged_switch: unmanaged }) => {
      const content = okay ? 'OK' : 'NC';
      const status = okay ? '' : 'status-warning';
      const tooltip = okay ? '' : 'Management connection lost to this switch';

      return unmanaged ? (
        'N/A'
      ) : (
        <Status content={content} status={status} tooltip={tooltip} />
      );
    },
  },
  {
    header: 'State',
    isDefault: true,
    property: 'state',
  },
  {
    header: 'Fabric',
    property: 'fabric',
  },
  {
    header: 'Pod ID',
    property: 'pod_id',
  },
  {
    header: 'Rack ID',
    property: 'rack_id',
  },
  {
    header: 'Config ID',
    property: 'config_id',
  },
  {
    header: 'Config ver',
    property: 'config_ver',
  },
  {
    header: 'Mgmt IPv4',
    property: 'mgmt_ipv4',
  },
  {
    header: 'Mfg',
    isDefault: true,
    property: 'info.manufacturer',
  },
  {
    header: 'Product',
    isDefault: true,
    property: 'info.product_name',
  },
  {
    header: 'Revision',
    property: 'info.label_revision',
  },
  {
    header: 'Serial number',
    isDefault: true,
    property: 'info.serial_number',
  },
  {
    header: 'Part number',
    property: 'info.part_number',
  },
  {
    header: 'Base MAC addr',
    property: 'info.base_mac_address',
  },
  {
    header: 'Platform',
    property: 'info.platform_name',
  },
  {
    header: 'HW vendor',
    property: 'info.vendor',
  },
  {
    header: 'Mfg. date',
    property: 'info.manufacture_date',
  },
  {
    header: 'HW version',
    property: 'info.device_version',
  },
  {
    header: '# of MACs',
    property: 'info.number_of_macs',
  },
];

export const getSwitches = async () => {
  const response = await get(makeRestUrl(URL_SWITCHES));

  return response.json();
};

export const useGetSwitches = () => {
  const queryResult = useQuery(URL_SWITCHES, getSwitches);

  return {
    ...queryResult,
    columns: columns.map((column) => ({
      ...column,
      columnName: column.property,
      displayName: column.header,
    })),
  };
};
