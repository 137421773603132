// (C) Copyright 2016-2024 Hewlett Packard Enterprise Development LP

import isArray from 'lodash/isArray';
import isNil from 'lodash/isNil';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';
import { sprintf } from 'sprintf-js';
import * as c from './consts.js';
import debugLogger from '../lib/debug';

const disableSecurity = false;

const debug = debugLogger('routes:permissions', false);

class Permissions {
  constructor() {
    this.permissions = new Map();

    // GUI permission map
    // no permissions for homepage
    this.addPermission(
      c.URL_HOME,
      c.GET,
      c.RESOURCE_HOME,
      c.SCOPE_ANY,
      c.ACCESS_NONE,
    );
    this.addPermission(
      c.URL_DASHBOARD,
      c.GET,
      c.RESOURCE_DASHBOARD,
      c.SCOPE_HOSTER,
      c.ACCESS_NONE,
    );
    this.addPermission(
      c.URL_DOWNLOADS,
      c.GET,
      c.RESOURCE_DOWNLOADS,
      c.SCOPE_ANY,
      c.ACCESS_NONE,
    );

    this.mapAllPermissions(c.URL_HOSTGROUPS, c.RESOURCE_HOSTGROUP);
    this.mapAllPermissions(c.URL_HOSTS, c.RESOURCE_HOST);
    this.mapAllPermissions(c.URL_NETWORKS, c.RESOURCE_NETWORK);
    this.mapAllPermissions(c.URL_NETWORKPOLICIES, c.RESOURCE_NETWORKPOLICY);
    this.mapAllPermissions(c.URL_SSHKEYS, c.RESOURCE_SSHKEY);
    this.mapAllPermissions(c.URL_MACHINES, c.RESOURCE_MACHINE);
    this.mapAllPermissions(c.URL_MACHINESIZES, c.RESOURCE_MACHINESIZE);
    this.mapAllPermissions(c.URL_MACHINETYPES, c.RESOURCE_MACHINETYPE);
    this.mapAllPermissions(c.URL_FWBASELINES, c.RESOURCE_FWBASELINE);
    this.mapAllPermissions(c.URL_OPS, c.RESOURCE_OPREQUEST);
    this.mapAllPermissions(c.URL_IPPOOLS, c.RESOURCE_IPPOOL);
    this.mapAllPermissions(c.URL_FILES, c.RESOURCE_FILE);
    this.mapAllPermissions(c.URL_SWITCHES, c.RESOURCE_SWITCH);
    this.mapAllPermissions(c.URL_SWITCHTYPES, c.RESOURCE_SWITCHTYPE);
    this.mapAllPermissions(c.URL_SWITCHPORTS, c.RESOURCE_SWITCHPORT);
    this.mapAllPermissions(c.URL_SERVICES, c.RESOURCE_SERVICE);
    this.mapAllPermissions(c.URL_HOSTER_SERVICES, c.RESOURCE_SERVICE);
    this.mapAllPermissions(c.URL_HOSTER_SERVICE_CATALOG, c.RESOURCE_SERVICE);
    this.mapAllPermissions(c.URL_PODS, c.RESOURCE_POD);
    this.mapAllPermissions(c.URL_TAGS, c.RESOURCE_TAG);
    this.mapAllPermissions(c.URL_RACKS, c.RESOURCE_RACK);
    this.mapAllPermissions(c.URL_RACKCONTROLLERS, c.RESOURCE_RACKCONTROLLER);
    this.mapAllPermissions(c.URL_RELEASES, c.RESOURCE_RELEASE);
    this.mapAllPermissions(c.URL_RACKTEMPLATES, c.RESOURCE_RACKTEMPLATE);
    this.mapAllPermissions(c.URL_DEVICES, c.RESOURCE_DEVICE);

    this.mapAllPermissions(
      c.URL_PORTALS,
      c.RESOURCE_PORTAL,
      c.SCOPE_PORTAL,
      c.ACCESS_READ,
    );
    this.mapAllPermissions(
      c.URL_HOSTERS,
      c.RESOURCE_HOSTER,
      [c.SCOPE_PORTAL, c.SCOPE_HOSTER],
      c.ACCESS_READ,
    );
    this.mapAllPermissions(
      c.URL_PROJECTS,
      c.RESOURCE_PROJECT,
      c.SCOPE_ANY,
      c.ACCESS_READ,
    );
    this.mapAllPermissions(c.URL_USERS, c.RESOURCE_USER, c.SCOPE_PORTAL);

    this.mapAllPermissions(c.URL_ARRAYS, c.RESOURCE_ARRAY);
    this.mapAllPermissions(c.URL_VOLUMES, c.RESOURCE_VOLUME);
    this.mapAllPermissions(
      c.URL_VOLUME_ATTACHMENTS,
      c.RESOURCE_VOLUME_ATTACHMENTS,
    );
    this.mapAllPermissions(c.URL_VOLUME_FLAVOR, c.RESOURCE_VOLUME_FLAVOR);
    this.mapAllPermissions(c.URL_ORGANIZATIONS, c.RESOURCE_ORGANIZATION);

    this.mapAllPermissions(c.URL_ALERTS, c.RESOURCE_ALERT);
    this.mapAllPermissions(c.URL_STORAGE_POOLS, c.RESOURCE_STORAGE_POOL);
    this.mapAllPermissions(c.URL_STORAGE_GROUPS, c.RESOURCE_STORAGE_GROUP);
    this.addPermission(
      c.URL_STORAGE_SYSTEMS,
      c.GET,
      c.RESOURCE_STORAGE_SYSTEM,
      c.SCOPE_HOSTER,
      c.ACCESS_READ,
    );
  }

  // biome-ignore lint/style/useDefaultParameterLast: <explanation>
  mapAllPermissions(baseUrl, resource, scopes = c.SCOPE_ANY, minAccess) {
    if (baseUrl === undefined || resource === undefined) {
      debug.throw(
        'mapAllPermissions: url or resource not found',
        baseUrl,
        resource,
      );
    }
    this.addPermission(
      baseUrl,
      c.GET,
      resource,
      scopes,
      minAccess > c.ACCESS_READ ? minAccess : c.ACCESS_READ,
    );
    this.addPermission(
      baseUrl,
      c.PUT,
      resource,
      scopes,
      minAccess > c.ACCESS_READ_WRITE ? minAccess : c.ACCESS_READ_WRITE,
    );
    this.addPermission(
      baseUrl,
      c.POST,
      resource,
      scopes,
      minAccess > c.ACCESS_READ_WRITE ? minAccess : c.ACCESS_READ_WRITE,
    );
    this.addPermission(
      baseUrl,
      c.DELETE,
      resource,
      scopes,
      minAccess > c.ACCESS_READ_WRITE ? minAccess : c.ACCESS_READ_WRITE,
    );
  }

  // biome-ignore lint/style/useDefaultParameterLast: <explanation>
  addPermission(url, method, resource, scopes = c.SCOPE_ANY, minAccess) {
    if (isString(scopes)) {
      scopes = [scopes];
    }

    const perm = new Permission(url, method, scopes, resource, minAccess);
    this.permissions.set(this.permKey(url, method), perm);
  }

  permKey(url, method) {
    return `${url}:${method}`;
  }

  // if (viewRoute.availble(url, this.props.roleDef))
  // is current roledef authorized to perform the method on the
  // specified route url
  authorized(url, method, roleDef = {}) {
    const key = this.permKey(url, method);
    // get route by url
    if (!this.permissions.has(key)) {
      debug.log('AUTH ERROR: no route permission entry for: + url');
      return false;
    }

    const route = this.permissions.get(key);
    return route.authorized(roleDef);
  }
}

class Permission {
  // route permission entry, defining:
  // scopes: required scope of access.
  // resource: resource access key required
  // minAccess: required min access for this route
  constructor(url, method, scopes, resource, minAccess) {
    if (!isArray(scopes)) {
      throw new Error(
        sprintf(
          'Scopes must be array: [%s], [%s], [%s], [%s]',
          url,
          method,
          scopes,
          resource,
          minAccess,
        ),
      );
    }
    if (!isString(resource)) {
      throw new Error(
        sprintf(
          'Resource must be string: [%s], [%s], [%s], [%s]',
          url,
          method,
          scopes,
          resource,
          minAccess,
        ),
      );
    }
    if (!isNumber(minAccess)) {
      throw new Error(
        sprintf(
          'MinAccess must be string: [%s], [%s], [%s], [%s]',
          url,
          method,
          scopes,
          resource,
          minAccess,
        ),
      );
    }

    this.url = url;
    this.method = method;
    this.resource = resource;
    this.minAccess = minAccess;
    this.scopes = new Map();
    for (const scope of scopes) {
      this.scopes.set(scope, true);
    }
  }

  authorized(roleDef) {
    if (disableSecurity) return true;
    debug.log(`authorized: roledef: ${roleDef}`);
    // check scope
    if (!this.scopes.has(roleDef.scope)) {
      debug.warn(
        `PERM: scope ${roleDef.scope} not found for ${this.url}`,
        this.scopes,
      );
      return false;
    }

    if (this.minAccess === c.ACCESS_NONE) {
      return true;
    }

    // check resource access entry exists
    const agVal = roleDef.access[this.resource];
    if (isNil(agVal)) {
      debug.warn(
        `PERM: roledef has no access entry for:${this.resource} for ${this.url}`,
        roleDef,
      );
      return false;
    }

    let accessGranted;

    switch (agVal) {
      case 'r':
        accessGranted = c.ACCESS_READ;
        break;
      case 'rw':
        accessGranted = c.ACCESS_READ_WRITE;
        break;
      case 'super':
        accessGranted = c.ACCESS_SUPER;
        break;
      default:
        accessGranted = c.ACCESS_NONE;
    }

    // check resource access entry min level
    if (accessGranted < this.minAccess) {
      debug.warn(
        `PERM: roledef access granted insufficient:${this.resource}, required access:${this.minAccess} for ${this.url}`,
        accessGranted,
      );
      return false;
    }

    return true;
  }
}

export const permissions = new Permissions();

export default permissions;
