// (C) Copyright 2020-2024 Hewlett Packard Enterprise Development LP

import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { Text, Box } from 'grommet';

const HelpBlock = (props) => <Text color='status-critical' {...props} />;

const FormHidden = ({ col, validationResult = {} }) => {
  const help = [];
  if (validationResult.msg) {
    help.push(<HelpBlock key='help'>{validationResult.msg}</HelpBlock>);
  }

  help.push(
    ...validationResult.cellResults.map((r, i) => (
      <HelpBlock key={`help-${i}`}>{r.msg}</HelpBlock>
    )),
  );

  if (isEmpty(help)) {
    return null;
  }

  return (
    <Box
      gap='small'
      key={col.keyPath}
      id={col.keyPath}
      style={{ gridColumn: '1 / span 2' }}
    >
      {help}
    </Box>
  );
};

export default FormHidden;
