// (C) Copyright 2022, 2024 Hewlett Packard Enterprise Development LP

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import { Box, Button, FormField, TextInput } from 'grommet';
import { Notes } from 'grommet-icons';

import auth from '../lib/auth';
import { Modal } from '../components';
import { toDateTime } from '../lib/formatters';
import {
  makeChildUrl,
  makeRestUrl,
  setParentId,
  URL_ALERTS,
  URL_ARRAYS,
  URL_HOSTS,
  URL_MACHINES,
  URL_NOTES,
  URL_RACKCONTROLLERS,
  URL_SWITCHES,
  URL_SWITCHPORTS,
} from '../routes/consts';
import { renderLink, Status } from './helper';
import IconButton from '../components/generic/IconButton';
import { errorInfo, get, post } from '../lib/rest';
import TruncateText from '../components/Griddle/TruncateText';

export const AlertActions = ({ addAlert, id }) => {
  const [modal, setModal] = useState('');
  const [note, setNote] = useState('');

  const handleClose = () => {
    setNote('');
    setModal('');
  };

  const handleAddNote = () => {
    post(setParentId(makeRestUrl(makeChildUrl(URL_ALERTS, URL_NOTES)), id), [
      { text: note },
    ])
      .then((res) => {
        if (!res.ok) {
          addAlert(`Unable to add the note: ${res.statusText}`, 'danger');
        } else {
          addAlert('Successfully added the note');
        }

        handleClose();
      })
      .catch((err) => {
        errorInfo(err, ({ text }) => {
          addAlert(`Unable to add the note: ${text}`, 'danger');
        });

        handleClose();
      });
  };

  return (
    <Box
      direction='row'
      gap='xsmall'
      onClick={(e) => e.stopPropagation()}
      focusIndicator={false}
    >
      <Box>
        <IconButton
          icon={<Notes />}
          tip='Add a note'
          onClick={() => setModal('note')}
        />
        <Modal
          autoFocus
          onHide={handleClose}
          show={modal === 'note'}
          size='medium'
        >
          <Modal.Header>
            <Modal.Title>Add Note</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Box overflow='auto' pad={{ horizontal: 'none' }}>
              <form onSubmit={() => false}>
                <Box gap='medium'>
                  <FormField label='Note' required>
                    <TextInput
                      onChange={({ target: { value } }) => setNote(value)}
                      value={note}
                    />
                  </FormField>
                </Box>
              </form>
            </Box>
            <Box
              key='buttons'
              direction='row'
              gap='medium'
              pad={{ top: 'medium' }}
              flex={false}
            >
              <Button
                type='submit'
                primary
                disabled={!note}
                label='OK'
                onClick={handleAddNote}
              />
              <Button
                secondary
                key='cancel'
                onClick={handleClose}
                label='Cancel'
              />
            </Box>
          </Modal.Body>
        </Modal>
      </Box>
    </Box>
  );
};

AlertActions.propTypes = {
  addAlert: PropTypes.func,
  id: PropTypes.string,
};

const sourceTypeToUrlMap = {
  machines: URL_MACHINES,
  hosts: URL_HOSTS,
  arrays: URL_ARRAYS,
  switches: URL_SWITCHES,
  rackcontrollers: URL_RACKCONTROLLERS,
  ports: URL_SWITCHPORTS,
};

const sourceTypeToUrl = (sourceType) => sourceTypeToUrlMap[sourceType] || '';

const columns = ({ addAlert, role = '' }) => [
  {
    header: 'Severity',
    isDefault: true,
    property: 'severity',
    render: ({ severity }) => {
      const status = `status-${severity?.toLowerCase()}`;

      return <Status icon status={status} />;
    },
  },
  {
    header: 'Name',
    property: 'name',
    render: renderLink({
      base: URL_ALERTS,
    }),
  },
  {
    header: 'ID',
    property: 'id',
  },
  {
    header: 'Created',
    property: 'created',
    render: ({ created }) => <TruncateText content={toDateTime(created)} />,
  },
  {
    header: 'Summary',
    isDefault: true,
    property: 'description.short',
    render: renderLink({
      base: URL_ALERTS,
      titleKey: 'description.short',
    }),
  },
  {
    header: 'Details',
    property: 'description.long',
  },
  {
    header: 'Type',
    property: 'type',
  },
  {
    header: 'Priority',
    property: 'priority',
  },
  {
    header: 'Modified',
    isDefault: true,
    property: 'modified',
    render: ({ modified }) => toDateTime(modified),
  },
  {
    header: 'Source type',
    isDefault: true,
    property: 'source_type',
  },
  {
    header: 'Source',
    isDefault: true,
    property: 'source_id',
    render: ({ source_type: type, ...props }) =>
      renderLink({
        base: sourceTypeToUrl(type),
        idKey: 'source_id',
        titleKey: 'source_id',
      })({ ...props, source_type: type }),
  },
  {
    header: 'Created by',
    isDefault: true,
    property: 'created_by',
  },
  {
    header: 'Created by ID',
    isDefault: true,
    property: 'created_by_id',
  },
  {
    header: 'Signature',
    isDefault: true,
    property: 'signature',
  },
  ...(role.includes('_viewer')
    ? []
    : [
        {
          header: 'Actions',
          isDefault: true,
          property: 'guiactions',
          render: ({ id }) => <AlertActions addAlert={addAlert} id={id} />,
        },
      ]),
];

const sourceAlertColumns = [
  {
    header: 'Severity',
    isDefault: true,
    property: 'severity',
    render: ({ severity }) => {
      const status = `status-${severity?.toLowerCase()}`;

      return <Status icon status={status} />;
    },
  },
  {
    header: 'Name',
    isDefault: true,
    property: 'name',
  },
  {
    header: 'ID',
    property: 'id',
    render: ({ id }) => <TruncateText content={id} />,
  },
  {
    header: 'State',
    isDefault: true,
    property: 'state',
  },
  {
    header: 'Description',
    isDefault: true,
    property: 'description.short',
  },
  {
    header: 'Modified',
    isDefault: true,
    property: 'modified',
    render: ({ modified }) => toDateTime(modified),
  },
  {
    header: 'Created',
    property: 'created',
    render: ({ created }) => toDateTime(created),
  },
];

export const getAlerts = async (apiParams = {}) => {
  const response = await get(makeRestUrl(URL_ALERTS), apiParams);
  return response.json();
};

export const useGetAlerts = ({ apiParams, addAlert }) => {
  const queryResult = useQuery([URL_ALERTS, apiParams], () =>
    getAlerts(apiParams),
  );

  return {
    ...queryResult,
    columns: columns({ addAlert, role: auth.activeRoleDef?.id }).map(
      (column) => ({
        ...column,
        columnName: column.property,
        displayName: column.header,
      }),
    ),
  };
};

export const useGetSourceAlerts = ({ parentId }) => {
  const queryResult = useQuery(`ALERTS_${parentId}`, () =>
    getAlerts({ source_id: parentId }),
  );

  return {
    ...queryResult,
    columns: sourceAlertColumns.map((column) => ({
      ...column,
      columnName: column.property,
      displayName: column.header,
    })),
  };
};
