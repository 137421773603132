// (C) Copyright 2016-2024 Hewlett Packard Enterprise Development LP

import React, { useState } from 'react';
import { Box, DataTable, Text } from 'grommet';
import styled from 'styled-components';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import { REFRESH_TIME } from '../../config';
import ViewHeader from '../../components/ViewHeader';
import View from '../../components/View';
import rest from '../../lib/rest';
import { toDateTime } from '../../lib/formatters';
import { useInterval, useStatus } from '../../utils';

const INITIAL_ROWS = 15; // number of rows to pre-load in the list

const Pre = styled.pre`
  height: auto;
  width: auto;
  max-width: 100%;
  min-width: 500px;
  min-height: 500px;
  overflow: auto;
  word-break: normal !important;
  word-wrap: normal !important;
  white-space: pre-line !important;
  font-size: smaller;
`;

const Empty = ({ children }) => (
  <Box fill align='center' justify='center'>
    <Text size='xlarge' color='text-xweak'>
      {children}
    </Text>
  </Box>
);

const Loading = () => <Empty>Loading...</Empty>;
const NoLogs = () => <Empty>no console logs for this host</Empty>;

const FileContent = ({ file }) =>
  file ? (
    <Box>
      <Pre>{file.content}</Pre>
    </Box>
  ) : (
    <Empty>{'<empty console log file>'}</Empty>
  );

const processFiles = (files) =>
  sortBy(
    files.map((file) => ({
      ...file,
      modified: toDateTime(file.modified),
      created: toDateTime(file.created),
      content: atob(file.content),
    })),
    'modified',
  );

export const HostConsoleLogsView = ({
  match: {
    params: { id },
  },
}) => {
  const [selectedRow, setSelectedRow] = useState();
  const [items, setItems] = useState();
  const [host, setHost] = useState();
  const { Status, setStatus } = useStatus('loading');

  useInterval(() => {
    if (id) {
      rest
        .get(`/rest/hosts/${id}/console-history`)
        .then((response) => response.json())
        .then(({ files, host }) => {
          const items = processFiles(files);
          setItems(items);
          setHost(host);
          if (!selectedRow && items && items.length > 0) {
            setSelectedRow(items[items.length - 1]);
          }
          setStatus(items.length > 0 ? 'success' : 'nofiles');
        })
        .catch((err) => {
          rest.handleError(err);
        });
    }
  }, REFRESH_TIME);

  const hostName = get(host, 'name', '');
  window.document.title = hostName;

  const rowProps = {};
  if (selectedRow) {
    // highlight selected row
    rowProps[selectedRow.id] = { background: 'brand' };
  }

  return (
    <View>
      <ViewHeader title>Console Logs: {hostName}</ViewHeader>
      <Box fill direction='row-responsive' gap='small'>
        <Box width='220px' flex={false}>
          <DataTable
            columns={[
              {
                property: 'modified',
                header: 'Modified',
              },
            ]}
            primaryKey='id'
            data={items}
            rowProps={rowProps}
            sort={{
              property: 'modified',
              direction: 'desc',
            }}
            onClickRow={({ datum }) => setSelectedRow(datum)}
            step={INITIAL_ROWS}
          />
        </Box>
        <Status
          loading={<Loading />}
          success={<FileContent file={selectedRow} />}
          empty={<NoLogs />}
        />
      </Box>
    </View>
  );
};

export default HostConsoleLogsView;
