// (C) Copyright 2016-2024 Hewlett Packard Enterprise Development LP

import PropTypes from 'prop-types';
import React from 'react';
import { Box, Meter, Stack, Text } from 'grommet';

const colors = {
  info: 'graph-3',
  success: 'status-ok',
  danger: 'status-critical',
  warning: 'status-warning',
};

export const ProgressBar = ({ hideLabel, progress, status }) => {
  const label = `${progress}%`;

  return (
    <Stack anchor='center' guidingChild='first'>
      <Box fill>
        <Meter
          type='bar'
          background='background-contrast'
          values={[
            {
              value: progress,
              label,
              color: colors[status],
            },
          ]}
          a11yTitle={`Progress ${label}`}
          size='full'
        />
      </Box>
      {!hideLabel ? <Text>{label}</Text> : null}
    </Stack>
  );
};
ProgressBar.propTypes = {
  hideLabel: PropTypes.bool,
  status: PropTypes.string,
  progress: PropTypes.number,
};

const ColumnProgressBar = ({ metadata, rowData }) => {
  if (!(rowData && metadata)) {
    return null;
  }

  const { mapFunc } = metadata.customComponentMetadata;
  const result = mapFunc(rowData);
  // if the result data indicates that a check should be displayed
  // instead of the progress bar, don't display anything.
  if (result.check) {
    return null;
  }
  // Note: currently no active animation style for result.active
  return <ProgressBar progress={result.progress} status={result.style} />;
};

ColumnProgressBar.propTypes = {
  rowData: PropTypes.object.isRequired,
  metadata: PropTypes.object.isRequired,
};

export default ColumnProgressBar;
