// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

import React from 'react';

import ListPageWithNav from '../../containers/generic/ListPageWithNav';
import { SCOPE_HOSTER, URL_PROJECTS } from '../../routes/consts';
import { useGetProjects } from '../../services/projects';
import { NAV_TEAMS } from '../../components/HybridNav/consts.js';
import auth from '../../lib/auth';

const ProjectListView = () => (
  <ListPageWithNav
    navSection={NAV_TEAMS}
    actions={{
      allowCreate: auth.inScope(SCOPE_HOSTER) ? 'Create project' : undefined,
    }}
    path={URL_PROJECTS}
    sort={{
      property: 'name',
      direction: 'asc',
    }}
    title='Projects'
    useGetData={useGetProjects}
  />
);

export default ProjectListView;
