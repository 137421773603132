// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import React from 'react';
import { Box, Text } from 'grommet';
import { StatusWarning, StatusGoodSmall } from 'grommet-icons';
import { toDateTime } from '../../lib/formatters';
import * as c from '../../routes/consts.js';
import MetaBuilder, { LARGE } from '../../containers/generic/MetaBuilder';
import ItemViewContainer from '../../containers/generic/ItemViewContainer';
import FormReadOnlyField from '../../containers/generic/MetaForm/FormReadOnlyField';
import HealthSummary from '../../components/HealthSummary';
import { LABEL_RACKCONTROLLERS } from '../../components/HybridNav/consts.js';

const EXTERNAL_NAME = c.URL_RACKCONTROLLERS;

export const settings = {
  authUrl: EXTERNAL_NAME, // used to filter create/trash icons from the view; using the defined roledef permissions mappings to this auth URL entry
  homeUrl: c.makeSecUrl(EXTERNAL_NAME), // homepage for this list view (e.g. /s/hosters/:pid/) is where this view is located; for return from create / item views
  baseUrl: EXTERNAL_NAME, // base url to be used for creating all associated URLS for this reasource, e.g. pageItem, pageCreate, restUrl, restItemUrl
  stateKey: EXTERNAL_NAME,
  homeLabel: LABEL_RACKCONTROLLERS,
};

const CommStatus = ({ col, value, data }) => (
  <FormReadOnlyField label={col.displayName}>
    <Box direction='row' gap='xxsmall'>
      {data.portal_comm_okay && (
        <Box margin={{ top: 'xxsmall' }}>
          <StatusGoodSmall color='status-ok' />
        </Box>
      )}
      {!data.portal_comm_okay && <StatusWarning color='status-warning' />}
      <Text id={col.keyPath} key={col.keyPath}>
        {value}
      </Text>
    </Box>
  </FormReadOnlyField>
);

function metaItem(props) {
  const m = new MetaBuilder(props);

  m.addSection('Object Info').NoChrome();
  m.addColumn('description', 'Description').FieldXform((description) =>
    description ? description : '--',
  );
  m.addColumn('type', 'Type');
  m.addColumn('unmanaged_switch', 'Mode').FieldXform((val) =>
    val === true ? 'Unmanaged switch mode' : '--',
  );
  m.addColumn('created', 'Created').FieldXform((created) =>
    created ? toDateTime(created) : '--',
  );
  m.addColumn('modified', 'Modified').FieldXform((modified) =>
    modified ? toDateTime(modified) : '--',
  );

  // Health Summary section
  m.addSection('Health summary', 'health_status').MaxWidth(LARGE).Expanded();

  m.addColumn('health_status', 'Health').Custom(HealthSummary);

  //-----------------------------------------------------------------
  m.addSection('Config/Status info').Expanded().MaxWidth(LARGE);
  m.addColumn('pod_id', 'Pod ID');
  m.addColumn('rack_id', 'Rack ID');
  m.addColumn('portal_comm_okay', 'Comm status')
    .FieldXform((val) => (val ? 'OK' : 'NC'))
    .Custom(CommStatus);
  m.addColumn('portal_comm_reason', 'Portal Comm. reason').FieldXform(
    (portalCommReason) => (portalCommReason ? portalCommReason : '--'),
  );
  m.addColumn('health_status.summary', 'Device status')
    .FieldXform((val) => (val ? 'OK' : 'NC'))
    .Custom(CommStatus);

  //-----------------------------------------------------------------
  m.addSection('Info').Expanded().MaxWidth(LARGE);
  m.addColumn('info.manufacturer', 'Mfg').FieldXform((manufacturer) =>
    manufacturer ? manufacturer : '--',
  );
  m.addColumn('info.product_name', 'Product');
  m.addColumn('info.label_revision', 'Revision').FieldXform((revision) =>
    revision ? revision : '--',
  );
  m.addColumn('info.serial_number', 'Serial number').FieldXform(
    (serialNumber) => (serialNumber ? serialNumber : '--'),
  );
  m.addColumn('info.part_number', 'Part number').FieldXform((partNumber) =>
    partNumber ? partNumber : '--',
  );
  m.addColumn('info.base_mac_address', 'Base MAC addr').FieldXform(
    (baseMacAddress) => (baseMacAddress ? baseMacAddress : '--'),
  );
  m.addColumn('info.platform_name', 'Platform').FieldXform((platformName) =>
    platformName ? platformName : '--',
  );
  m.addColumn('info.vendor', 'HW vendor').FieldXform((vendor) =>
    vendor ? vendor : '--',
  );
  m.addColumn('info.manufacture_date', 'Mfg. Date').FieldXform(
    (manufactureDate) => (manufactureDate ? manufactureDate : '--'),
  );
  m.addColumn('info.device_version', 'HW version').FieldXform(
    (deviceVersion) => (deviceVersion ? deviceVersion : '--'),
  );

  return m;
}

export { default as RackControllerListView } from './RackControllerListView';

export const RackControllerItemView = ItemViewContainer({
  ...settings,
  meta: (props) => metaItem(props),
  title: 'Controller',
});
