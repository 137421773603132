// (C) Copyright 2022-2024 Hewlett Packard Enterprise Development LP

import { useQuery } from 'react-query';

import { get } from '../lib/rest';
import { toDateTime } from '../lib/formatters';
import { makeRestUrl, URL_OPS } from '../routes/consts';
import { renderLink } from './helper';
import TruncateText from '../components/Griddle/TruncateText';

const columns = [
  {
    header: 'Name',
    isDefault: true,
    property: 'name',
    render: renderLink({
      base: URL_OPS,
    }),
  },
  {
    header: 'ID',
    property: 'id',
    render: ({ id }) => <TruncateText content={id} />,
  },
  {
    header: 'State',
    isDefault: true,
    property: 'state',
  },
  {
    header: 'Status',
    isDefault: true,
    property: 'status',
  },
  {
    header: 'Result',
    property: 'result',
  },
  {
    header: 'Result info',
    property: 'result_info',
  },
  {
    header: 'Result message',
    property: 'result_msg',
  },
  {
    header: 'Op',
    isDefault: true,
    property: 'op',
  },
  {
    header: 'Op command',
    isDefault: true,
    property: 'opcmd',
  },
  {
    header: 'Modified',
    isDefault: true,
    property: 'modified',
    render: ({ modified }) => toDateTime(modified),
  },
  {
    header: 'Created',
    property: 'created',
    render: ({ created }) => toDateTime(created),
  },
  {
    header: 'Task ID',
    property: 'task_id',
  },
  {
    header: 'Host ID',
    property: 'host_id',
  },
  {
    header: 'Machine ID',
    property: 'machine_id',
  },
  {
    header: 'Service ID',
    property: 'service_id',
  },
  {
    header: 'Device ID',
    property: 'device_id',
  },
  {
    header: 'Pre reqs',
    property: 'pre_reqs',
  },
  {
    header: 'Type name',
    property: 'type_name',
  },
  {
    header: 'Rack ID',
    property: 'rack_id',
  },
];

export const getOperations = async (apiParams = {}) => {
  const response = await get(makeRestUrl(URL_OPS), apiParams);
  return response.json();
};

export const useGetOperations = ({ apiParams }) => {
  const queryResult = useQuery([URL_OPS, apiParams], () =>
    getOperations(apiParams),
  );

  return {
    ...queryResult,
    columns: columns.map((column) => ({
      ...column,
      columnName: column.property,
      displayName: column.header,
    })),
  };
};
