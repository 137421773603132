// (C) Copyright 2016-2024 Hewlett Packard Enterprise Development LP

import {
  makeSecUrl,
  makeRestUrl,
  setId,
  URL_VOLUMES,
  URL_VOLUME_ATTACHMENTS as BASE_URL,
} from '../../routes/consts';
import ItemViewContainer from '../../containers/generic/ItemViewContainer';
import CreateViewContainer from '../../containers/generic/CreateViewContainer';
import volumeAttachmentsItemView from './volumeAttachmentsItem';
import volumeAttachmentsAddView from './volumeAttachmentsAdd';
import { LABEL_ATTACHMENTS } from '../../components/HybridNav/consts.js';

const settings = {
  // used to filter create/trash icons from the view;
  // using the defined roledef permissions mappings to this auth URL entry
  authUrl: BASE_URL,
  // homepage for this list view (e.g. /s/hosters/:pid/)
  // is where this view is located;
  // for return from create / item views
  homeUrl: makeSecUrl(BASE_URL),
  homeLabel: LABEL_ATTACHMENTS,
  baseUrl: BASE_URL,
  // base url to be used for creating all associated
  // URLS for this reasource, e.g. pageItem, pageCreate, restUrl, restItemUrl
  meta: (props) => volumeAttachmentsItemView(props),
};

export { default as VolumeAttachmentsListView } from './volumeAttachmentsList';

export const VolumeAttachmentsItemView = ItemViewContainer({
  ...settings,
  meta: (props) => volumeAttachmentsItemView(props),
  title: 'Volume attachment',
});

export const VolumeAttachmentsCreateView = CreateViewContainer({
  ...settings,
  // Require a customized function to create
  // the POST URL for the portal. Volume attachments
  // are created using /volumes/<id>/attach
  // where id is either a volumeID or a volumeGroupID.
  createUrl: (data) =>
    setId(`${makeRestUrl(URL_VOLUMES)}/:id/attach`, data.volumeID),
  meta: (props) => volumeAttachmentsAddView(props),
  title: 'Create volume attachment',
});
