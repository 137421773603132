// (C) Copyright 2016-2024 Hewlett Packard Enterprise Development LP

import * as c from '../../routes/consts.js';
import MetaBuilder, { LARGE } from '../../containers/generic/MetaBuilder';
import ColumnLink from '../../components/Griddle/ColumnLink';
import ColumnFormatter from '../../components/Griddle/ColumnFormatter';
import ItemViewContainer from '../../containers/generic/ItemViewContainer';
import debugLogger from '../../lib/debug';
import * as log from '../../lib/debug';
import { toDateTime } from '../../lib/formatters';
import HealthSummary from '../../components/HealthSummary';
import { LABEL_SWITCHES } from '../../components/HybridNav/consts.js';

const debug = debugLogger('Switch', log.LOG_LEVEL_WARN);

const BASE_URL = c.URL_SWITCHES;

export const settings = {
  authUrl: BASE_URL, // used to filter create/trash icons from the view; using the defined roledef permissions mappings to this auth URL entry
  homeUrl: c.makeSecUrl(BASE_URL), // homepage for this list view (e.g. /s/hosters/:pid/) is where this view is located; for return from create / item views
  baseUrl: BASE_URL, // base url to be used for creating all associated URLS for this reasource, e.g. pageItem, pageCreate, restUrl, restItemUrl
  meta: (props) => metabuilder(props),
  homeLabel: LABEL_SWITCHES,
};

function metabuilder(props) {
  const mb = new MetaBuilder(props);

  // setup base data source
  // TODO: ds isn't used, but we still need to do the initForm
  const id = mb.view.prop('itemId', false);
  let ds;
  debug.debug('got itemId', id);
  if (id === undefined) {
    ds = mb.newStaticDataSource({});
  } else {
    ds = mb
      .newDataSource(c.URL_SWITCHES)
      .Item(id)
      .OnLoad((json) => {
        debug.debug('got switch: ', json);
        mb.view.initForm(json.data);
      });
  }
  debug.debug('SwitchesView ds', ds);

  //-----------------------------------------------------------------
  mb.addSection('Object Info').NoChrome();
  mb.addColumn('name', 'Name')
    .Custom(ColumnLink, {
      info: (rowData) => {
        const url = c.setId(c.makeSecItemUrl(BASE_URL), rowData.id);
        const { name } = rowData;
        return { url, name };
      },
    })
    .Default()
    .Input();

  // mb.addColumn('description', 'Description')
  mb.addColumn('id', 'ID');
  mb.addColumn('created', 'Created').FieldXform((created) =>
    created ? toDateTime(created) : '--',
  );
  mb.addColumn('modified', 'Modified').FieldXform((modified) =>
    modified ? toDateTime(modified) : '--',
  );

  const dsSwitchTypes = mb.newDataSource(c.URL_SWITCHTYPES);

  mb.addColumn('type_id', 'Switch type')
    .DropDown()
    .ReadOnly()
    .DataXform(dsSwitchTypes, (json) =>
      json.map((t) => ({ id: t.id, name: t.name })),
    )
    .ShowLink();

  mb.addColumn('state', 'State').Default();

  mb.addColumn('portal_comm_okay', 'Comm status')
    .Custom(ColumnFormatter, {
      format: (rowData) => (rowData.portal_comm_okay ? 'OK' : 'NC'),
    })
    .Default()
    .FieldXform((val) => (val ? 'OK' : 'NC'));
  //   mb.addColumn('portal_comm_reason', 'Comm Reason')

  mb.addSection('Health summary', 'health_status').MaxWidth(LARGE).Expanded();

  mb.addColumn('health_status', 'Health').Custom(HealthSummary);

  //-----------------------------------------------------------------
  mb.addSection('Configuration').MaxWidth(LARGE).Expanded();

  const dsPods = mb.newDataSource(c.URL_PODS);
  const dsRacks = mb.newDataSource(c.URL_RACKS);

  mb.addColumn('pod_id', 'Pod')
    .DropDown()
    .ReadOnly()
    .DataXform(dsPods, (json) => json.map((t) => ({ id: t.id, name: t.name })))
    .ShowLink();

  mb.addColumn('rack_id', 'Rack')
    .DropDown()
    .ReadOnly()
    .DataXform(dsRacks, (json) => json.map((t) => ({ id: t.id, name: t.name })))
    .ShowLink();
  mb.addColumn('fabric', 'Fabric');
  mb.addColumn('config_id', 'Config ID');
  mb.addColumn('config_ver', 'Config ver');
  mb.addColumn('mgmt_ipv4', 'Mgmt IPv4');

  //-----------------------------------------------------------------
  mb.addSection('Switch Info').MaxWidth(LARGE).Expanded();
  mb.addColumn('info.manufacturer', 'Mfg').Default();
  mb.addColumn('info.product_name', 'Product').Default();
  mb.addColumn('info.label_revision', 'Revision');
  mb.addColumn('info.serial_number', 'Serial number').Default();
  mb.addColumn('info.part_number', 'Part number');
  mb.addColumn('info.base_mac_address', 'Base MAC addr');
  mb.addColumn('info.platform_name', 'Platform');
  mb.addColumn('info.vendor', 'HW vendor');
  mb.addColumn('info.manufacture_date', 'Mfg. date');
  mb.addColumn('info.device_version', 'HW version');
  mb.addColumn('info.number_of_macs', '# of MACs');

  return mb;
}

export { default as SwitchListView } from './SwitchListView';

export const SwitchItemView = ItemViewContainer({
  ...settings,
  title: 'Switch',
});
