// (C) Copyright 2022-2024 Hewlett Packard Enterprise Development LP

import React from 'react';

import ListPageWithNav from '../../containers/generic/ListPageWithNav';
import { URL_STORAGE_POOLS } from '../../routes/consts';
import { useGetCapacityPools } from '../../services/capacityPools';
import { NAV_STORAGE } from '../../components/HybridNav/consts.js';

const CapacityPoolListView = () => (
  <ListPageWithNav
    navSection={NAV_STORAGE}
    actions={{}}
    noSelect
    path={URL_STORAGE_POOLS}
    sort={{
      property: 'name',
      direction: 'asc',
    }}
    title='Storage pools'
    useGetData={useGetCapacityPools}
  />
);

export default CapacityPoolListView;
