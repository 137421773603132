// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

import isNil from 'lodash/isNil';

// helper for toggling modals, with support for multiple modals per keyPath
class ModalControl {
  constructor(view, keyPath, modalName) {
    this.view = view;
    this.key = `${keyPath}._modals.${modalName}`;
  }

  show = (e) => {
    this._set(true);
    e.stopPropagation();
  };

  hide = () => {
    this._set(false);
  };

  visible = () => {
    this.view.debug.log('ModalControl::visible', this.key);
    const v = this.view.state.showModal[this.key];
    if (isNil(v)) {
      return false;
    }
    return v;
  };

  _set = (showFlag) => {
    this.view.debug.log('ModalControl::_set', this.key, showFlag);
    const { showModal } = this.view.state;
    showModal[this.key] = showFlag;
    this.view.setState({ showModal });
  };
}

export default ModalControl;
