// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

import React, { useState } from 'react';
import {
  Box,
  Button,
  Form,
  FormField,
  Paragraph,
  TextArea,
  TextInput,
} from 'grommet';
import Modal from '../../components/Modal';
import { createSshKey } from '../../services/sshkeys';

export const AddSshKey = ({ onHide, onKeyAdded, project }) => {
  const [error, setError] = useState();

  const onSubmit = async ({ value }) => {
    try {
      setError(null);
      const payload = {
        ...value,
        ...(project.id === '__self' ? {} : { project_id: project.id }),
      };
      const newKey = await createSshKey(payload);
      onKeyAdded(newKey);
      onHide();
    } catch (e) {
      let error = e.toString();
      if (e.response) {
        error = await e.response.text();
      }
      setError(`Unable to create SSH Key: ${error}`);
    }
  };

  return (
    <Modal show onHide={onHide} size='medium'>
      <Modal.Header onDismiss={onHide}>
        <Modal.Title>Add SSH Key</Modal.Title>
      </Modal.Header>
      <Box pad={{ horizontal: 'medium' }} overflow='auto'>
        <Form onSubmit={onSubmit}>
          <FormField
            label='Name'
            name='name'
            htmlFor='name'
            required
            component={TextInput}
            placeholder='Enter a name for the SSH key'
          />
          <FormField
            label='SSH Key'
            name='key'
            htmlFor='key'
            required
            component={TextArea}
          />
          {error && (
            <Box
              border
              round='xsmall'
              pad='small'
              margin={{ top: 'medium' }}
              background='validation-critical'
            >
              <Paragraph>{error}</Paragraph>
            </Box>
          )}
          <Box direction='row' gap='medium' pad={{ vertical: 'medium' }}>
            <Button type='submit' primary label='Create SSH Key' />
            <Button label='Cancel' onClick={onHide} />
          </Box>
        </Form>
      </Box>
    </Modal>
  );
};
