// (C) Copyright 2022-2024 Hewlett Packard Enterprise Development LP

import React from 'react';

import ListPageWithNav from '../../containers/generic/ListPageWithNav';
import { URL_ORGANIZATIONS } from '../../routes/consts';
import { useGetOrganizations } from '../../services/organizations';
import { NAV_TEAMS } from '../../components/HybridNav/consts.js';

const OrganizationListView = () => (
  <ListPageWithNav
    navSection={NAV_TEAMS}
    actions={{
      allowCreate: 'Create organization',
    }}
    path={URL_ORGANIZATIONS}
    sort={{
      property: 'name',
      direction: 'asc',
    }}
    title='Organizations'
    useGetData={useGetOrganizations}
  />
);

export default OrganizationListView;
