// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

import React from 'react';

import ListPageWithNav from '../../containers/generic/ListPageWithNav';
import { URL_SERVICES } from '../../routes/consts';
import { useGetServices } from '../../services/services';
import { NAV_FOR_HOSTERS } from '../../components/HybridNav/consts.js';

const ServiceListView = () => (
  <ListPageWithNav
    navSection={NAV_FOR_HOSTERS}
    actions={{
      allowCreate: 'Add Service',
      allowPublish: true,
    }}
    path={URL_SERVICES}
    sort={{
      property: 'name',
      direction: 'asc',
    }}
    title='Services'
    useGetData={useGetServices}
  />
);

export default ServiceListView;
