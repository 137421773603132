// (C) Copyright 2016-2024 Hewlett Packard Enterprise Development LP

import * as c from '../../routes/consts.js';
import MetaBuilder, * as mb from '../../containers/generic/MetaBuilder';
import ColumnLink from '../../components/Griddle/ColumnLink';
import ItemViewContainer from '../../containers/generic/ItemViewContainer';
import CreateViewContainer from '../../containers/generic/CreateViewContainer';
import EditViewContainer from '../../containers/generic/EditViewContainer';
import * as REGEX from '../../data/regex.js';

const BASE_URL = c.URL_HOSTERS;
const ITEM_URL = c.makeSecChildItemUrl(c.URL_HOSTERS, c.URL_MEMBERS);

const settings = {
  authUrl: BASE_URL, // used to filter create/trash icons from the view; using the defined roledef permissions mappings to this auth URL entry
  homeUrl: c.makeSecChildUrl(BASE_URL, ''), // homepage for this list view (e.g. /s/hosters/:pid/) is where this view is located; for return from create / item views
  baseUrl: c.makeChildUrl(BASE_URL, c.URL_MEMBERS), // base url to be used for creating all associated URLS for this reasource, e.g. pageItem, pageCreate, restUrl, restItemUrl
};

function metaCreate(props) {
  const m = new MetaBuilder(props);

  m.addColumn('email', 'Email')
    .Default()
    .Input()
    .Required()
    .MinLength(3)
    .RegEx(REGEX.EMAIL, REGEX.EMAIL_MSG)
    .MaxWidth(mb.SMALL);

  m.addColumn('role_id', 'Role ID')
    .Input('hoster_admin')
    .DropDown()
    .MaxWidth(mb.SMALL)
    .DataXform(
      m.newStaticDataSource([
        { id: 'hoster_owner', name: 'Owner' },
        { id: 'hoster_admin', name: 'Admin' },
        { id: 'hoster_viewer', name: 'Viewer' },
      ]),
      (json) => json,
    );

  return m;
}

function metaEdit(props) {
  const m = new MetaBuilder(props);

  m.newDataSource(props.itemUrl)
    .Item(props.itemId)
    .OnLoad((json) => {
      m.view.initForm(json.data);
    });

  // resource base
  m.addColumn('name', 'Name')
    .Custom(ColumnLink, {
      info: (rowData) => {
        const url = c.setParentId(
          c.setId(ITEM_URL, rowData.id),
          m.view.prop('parentId'),
        );
        const { name } = rowData;
        return { url, name };
      },
    })
    .Default()
    .Width(mb.SMALL);

  m.addColumn('email', 'Email')
    .Default()
    .Input()
    .ReadOnly()
    .MinLength(3)
    .Width(mb.SMALL)
    .RegEx(REGEX.EMAIL, REGEX.EMAIL_MSG);

  const idCol = m.addColumn('id', 'ID');
  if (props.mode === 'edit') {
    idCol.ReadOnly();
  }

  m.addColumn('created', 'Created').Default();
  m.addColumn('modified', 'Modified').Default();

  // state
  m.addColumn('state', 'State').Default();

  m.addColumn('portal_id', 'Portal ID');
  m.addColumn('hoster_id', 'Hoster ID');
  m.addColumn('project_id', 'Project ID');

  m.addColumn('user_id', 'User ID');
  m.addColumn('role_id', 'Role ID')
    .Input('hoster_admin')
    .DropDown()
    .MaxWidth(mb.SMALL)
    .DataXform(
      m.newStaticDataSource([
        { id: 'hoster_admin', name: 'Admin' },
        { id: 'hoster_owner', name: 'Owner' },
      ]),
      (json) => json,
    )
    .Default();

  m.addColumn('scope', 'Scope');

  return m;
}

export { default as HosterMemberListView } from './HosterMemberList';

export const HosterMemberItemView = ItemViewContainer({
  ...settings,
  meta: (props) => metaEdit(props),
  title: 'Hoster member',
  allowEdit: true,
});

export const HosterMemberCreateView = CreateViewContainer({
  ...settings,
  meta: (props) => metaCreate(props),
  title: 'Create hoster member',
});

export const HosterMemberEditView = EditViewContainer({
  ...settings,
  meta: (props) => metaEdit(props),
  title: 'Edit hoster member',
});
