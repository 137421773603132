// (C) Copyright 2016-2024 Hewlett Packard Enterprise Development LP

import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

export default function ColumnFormatter(props) {
  const [, setState] = useState();
  const forceUpdate = useCallback(() => setState({}), []);

  const timeout = useRef(null);

  const update = useMemo(
    () => props.metadata?.customComponentMetadata?.update,
    [props.metadata],
  );

  const format = useMemo(
    () => props.metadata?.customComponentMetadata?.format,
    [props.metadata],
  );

  const scheduleUpdate = useCallback(() => {
    if (update) {
      timeout.current = setTimeout(() => refresh(), update);
    }
  }, [update]);

  const refresh = () => {
    forceUpdate();
    scheduleUpdate();
  };

  useEffect(() => {
    scheduleUpdate();

    return () => clearTimeout(timeout.current);
  }, [scheduleUpdate]);

  if (!format || !props.rowData) {
    return null;
  }

  return <p>{`${format(props.rowData)}`}</p>;
}
