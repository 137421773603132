// (C) Copyright 2022-2024 Hewlett Packard Enterprise Development LP

import React from 'react';

import ListPageWithNav from '../../containers/generic/ListPageWithNav';
import { SCOPE_PORTAL, URL_HOSTERS } from '../../routes/consts';
import { useGetHostersData } from '../../services/hosters';
import { useMembershipContext } from '../../utils/context/membershipContext';
import {
  NAV_TEAMS,
  NAV_TEAMS_PORTAL,
} from '../../components/HybridNav/consts.js';
import auth from '../../lib/auth';

const HosterListView = () => {
  const { activeMembership } = useMembershipContext();
  const navSection =
    activeMembership?.scope === 'portal' ? NAV_TEAMS_PORTAL : NAV_TEAMS;

  return (
    <ListPageWithNav
      navSection={navSection}
      actions={{
        allowCreate: auth.inScope(SCOPE_PORTAL) ? 'Create hoster' : undefined,
      }}
      path={URL_HOSTERS}
      sort={{
        property: 'name',
        direction: 'asc',
      }}
      title='Hosters'
      useGetData={useGetHostersData}
    />
  );
};

export default HosterListView;
