// (C) Copyright 2016-2024 Hewlett Packard Enterprise Development LP

import * as c from '../../routes/consts.js';
import MetaBuilder, {
  LARGE,
  SMALL,
} from '../../containers/generic/MetaBuilder';
import ColumnLink from '../../components/Griddle/ColumnLink';
import ItemViewContainer from '../../containers/generic/ItemViewContainer';
import EditViewContainer from '../../containers/generic/EditViewContainer';
import { PortalMemberListView } from './members.js';
import auth from '../../lib/auth';
import perm from '../../routes/permissions';
import { LABEL_PORTALS } from '../../components/HybridNav/consts.js';

const BASE_URL = c.URL_PORTALS;
const CERT_URL = c.URL_CERT;

const settings = {
  authUrl: BASE_URL, // used to filter create/trash icons from the view; using the defined roledef permissions mappings to this auth URL entry
  homeUrl: c.makeSecUrl(BASE_URL), // homepage for this list view (e.g. /s/hosters/:pid/) is where this view is located; for return from create / item views
  homeLabel: LABEL_PORTALS,
  baseUrl: BASE_URL, // base url to be used for creating all associated URLS for this reasource, e.g. pageItem, pageCreate, restUrl, restItemUrl
  meta: (props) => metabuilder(props),
};

function metabuilder(props) {
  const mb = new MetaBuilder(props);

  mb.addRowClick((navigate) => ({ datum: { id } }) => {
    navigate(c.setId(c.makeSecItemUrl(BASE_URL), id));
  });

  mb.addColumn('name', 'Name')
    .Custom(ColumnLink, {
      info: (rowData) => {
        const url = c.setId(c.makeSecItemUrl(BASE_URL), rowData.id);
        const { name } = rowData;
        return { url, name };
      },
    })
    .Default()
    .Input()
    .Required();

  mb.addColumn('id', 'ID');
  mb.addColumn('desc', 'Description').Default();

  mb.addColumn('created', 'Created').Default();
  mb.addColumn('modified', 'Modified').Default();

  return mb;
}

const dateFormatter = (value) =>
  value ? new Date(value).toLocaleString() : '';

function metaEdit(props) {
  const m = new MetaBuilder(props);

  m.newDataSource(props.itemUrl)
    .Item(props.itemId)
    .OnLoad((json) => {
      m.view.initForm(json.data);
    });

  //-----------------------------------------
  m.addSection('Basic Info').Expanded().MaxWidth(LARGE);
  //-----------------------------------------

  // resource base
  m.addColumn('name', 'Name')
    .Custom(ColumnLink, {
      info: (rowData) => {
        const url = c.setId(c.makeSecItemUrl(BASE_URL), rowData.id);
        const { name } = rowData;
        return { url, name };
      },
    })
    .Default()
    .Input()
    .MaxWidth(SMALL)
    .Required()
    .RegEx('(?=^.{0,128}$)(^[ -~]+$)');

  m.addColumn('id', 'ID');
  m.addColumn('created', 'Created').Default();
  m.addColumn('modified', 'Modified').Default();

  // state
  // m.addColumn('state', 'State').Default()

  //-----------------------------------------
  m.addSection('Embedded CA certificate - gRPC').Expanded().MaxWidth(LARGE);
  //-----------------------------------------

  const certDs = m.newDataSource(CERT_URL);
  // const certDs = m.newStaticDataSource("mockCertData")

  // portal certificate
  m.addColumn('serial', 'Serial')
    .Input()
    .ReadOnly()
    .FieldXform(() => {
      if (certDs.Data()) return certDs.Data()[0].serial;
    });

  m.addColumn('issuer', 'Issuer')
    .Input()
    .ReadOnly()
    .FieldXform(() => {
      if (certDs.Data()) return certDs.Data()[0].issuer;
    });

  m.addColumn('valid_from', 'Valid From')
    .Input()
    .ReadOnly()
    .FieldXform(() => {
      if (certDs.Data()) return dateFormatter(certDs.Data()[0].valid_from);
    });

  m.addColumn('valid_to', 'Valid To')
    .Input()
    .ReadOnly()
    .FieldXform(() => {
      if (certDs.Data()) return dateFormatter(certDs.Data()[0].valid_to);
    });

  //-----------------------------------------
  m.addSection('Profile').Expanded().MaxWidth(LARGE);
  //-----------------------------------------

  // hoster profile
  m.addColumn('profile.team_name', 'Team Name').Input().MaxWidth(SMALL);
  m.addColumn('profile.team_desc', 'Team Description').Input().MaxWidth(SMALL);
  m.addColumn('profile.company', 'Company').Input().MaxWidth(SMALL);
  m.addColumn('profile.address', 'Address').Input().MaxWidth(SMALL);
  m.addColumn('profile.email', 'Email').Input().MaxWidth(SMALL);
  // m.addColumn('profile.email_verified', 'Email Verified')
  m.addColumn('profile.phone_number', 'Phone').Input().MaxWidth(SMALL);
  // m.addColumn('profile.phone_verified', 'Phone Verified')

  //-----------------------------------------
  if (perm.authorized(c.URL_PORTALS, c.PUT, auth.activeRoleDef)) {
    m.addSection('Members')
      .Table(PortalMemberListView)
      .Expanded()
      .MaxWidth(1200);
  }
  //-----------------------------------------

  return m;
}

export { default as PortalListView } from './PortalListView';

export const PortalItemView = ItemViewContainer({
  ...settings,
  meta: (props) => metaEdit(props),
  allowEdit: true,
  title: 'Portals',
});

export const PortalEditView = EditViewContainer({
  ...settings,
  meta: (props) => metaEdit(props),
  title: 'Edit Portal',
});
