// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'grommet';
import styled from 'styled-components';

const Tag = styled.div`
  white-space: nowrap;
`;

export const Badge = ({ tag }) => (
  <Box
    align='center'
    background='background-contrast'
    direction='row'
    height='28px'
    justify='center'
    margin={{
      bottom: '9px',
      right: '9px',
    }}
    pad={{ horizontal: '9px' }}
    round='xxsmall'
  >
    <Tag>{tag}</Tag>
  </Box>
);

Badge.propTypes = {
  tag: PropTypes.string.isRequired,
};
