// (C) Copyright 2022-2024 Hewlett Packard Enterprise Development LP

import React from 'react';

import ListPageWithNav from '../../containers/generic/ListPageWithNav';
import { URL_OPS } from '../../routes/consts';
import { useGetOperations } from '../../services/operations';
import { NAV_TRACKING } from '../../components/HybridNav/consts.js';

const OperationListView = () => (
  <ListPageWithNav
    navSection={NAV_TRACKING}
    path={URL_OPS}
    sort={{
      property: 'name',
      direction: 'asc',
    }}
    title='Operations'
    useGetData={useGetOperations}
    limitRecords={true}
  />
);

export default OperationListView;
