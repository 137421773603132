// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

export const isEmpty = (obj) =>
  // biome-ignore lint: <null check>
  [Object, Array].includes((obj || {}).constructor) &&
  !Object.entries(obj || {}).length;

export const difference = (arrayA = [], arrayB = [], comparator = () => true) =>
  arrayA.filter(
    (valueA) => !arrayB.some((valueB) => comparator(valueA, valueB)),
  );

export const intersection = (
  arrayA = [],
  arrayB = [],
  comparator = () => true,
) =>
  arrayA.filter((valueA) =>
    arrayB.some((valueB) => comparator(valueA, valueB)),
  );

export const intersectionBy = (arrayA = [], arrayB = [], key = 'id') =>
  arrayA.filter((valueA) =>
    arrayB.some((valueB) => valueA[key] === valueB[key]),
  );

export const get = (object, path = '') => {
  if (!object || !Object.keys(object).length) {
    return null;
  }

  let index = 0;
  let value = object;
  const regex = /\[\d{1,}\]$/;

  const keys = path.split('.').reduce((acc, cur) => {
    let array;

    if (regex.test(cur)) {
      const [path, index] = cur.split('[');
      array = [path, `[${index}`];
    } else {
      array = [cur];
    }
    // biome-ignore lint: <explanation>
    return [...acc, ...array];
  }, []);

  while (index < keys.length) {
    const key = keys[index];
    value = value[key];
    index++;
  }

  return value || '';
};
