// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

import React, { useState } from 'react';

import ListPageWithNav from '../../containers/generic/ListPageWithNav';
import { URL_RACKCONTROLLERS } from '../../routes/consts';
import { useGetRackControllers } from '../../services/rackControllers';
import UpdateModal from './UpdateModal';
import { NAV_RACKS } from '../../components/HybridNav/consts.js';

const RackControllerListView = () => {
  const [selected, setSelected] = useState([]);

  return (
    <>
      <ListPageWithNav
        navSection={NAV_RACKS}
        actions={{
          allowUpdate: (data) => setSelected(data),
        }}
        path={URL_RACKCONTROLLERS}
        sort={{
          property: 'name',
          direction: 'asc',
        }}
        title='Controllers'
        useGetData={useGetRackControllers}
        limitRecords={true}
      />
      <UpdateModal selected={selected} onClose={() => setSelected([])} />
    </>
  );
};

export default RackControllerListView;
