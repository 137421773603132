// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

// string comparisons
export const machOperators = [
  'contains',
  'containsnocase',
  'match',
  'omits',
  'omitsnocase',
  'startswith',
  'startswithnocase',
  'endswith',
  'endswithnocase',
  '==',
  '!=',
  '<',
  '<=',
  '>',
  '>=',
];

export const subattributeOperators = [
  'contains',
  'containsnocase',
  'omits',
  'omitsnocase',
  'startswith',
  'startswithnocase',
  'endswith',
  'endswithnocase',
  '==',
  '!=',
  '<',
  '<=',
  '>',
  '>=',
];
