// (C) Copyright 2020-2024 Hewlett Packard Enterprise Development LP

import React from 'react';
import { Grommet } from 'grommet';
import { theme } from '../styles/theme';
import { useDarkMode } from '../utils';

export const ThemeMode = ({ children }) => {
  const { value } = useDarkMode(theme.defaultMode === 'dark');
  const themeMode = value ? 'dark' : 'light';

  return (
    <Grommet full theme={theme} themeMode={themeMode}>
      {children}
    </Grommet>
  );
};
