// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

import React from 'react';
import { Moon, Sun } from 'grommet-icons';
import IconButton from './generic/IconButton';
import { useDarkMode } from '../utils';

export const ThemeModeToggle = ({ ...props }) => {
  const darkMode = useDarkMode();
  return (
    <IconButton
      icon={darkMode.value ? <Sun /> : <Moon />}
      onClick={() => darkMode.toggle()}
      style={{
        padding: '6px',
      }}
      tip={darkMode.value ? 'Light Mode' : 'Dark Mode'}
      {...props}
    />
  );
};
