// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import * as c from '../../routes/consts.js';

export const NAV_NETWORKING = 'pods';
export const NAV_RACKS = 'racks';
export const NAV_COMPUTE = 'machines';
export const NAV_COMPUTE_PROJECT = 'hosts';
export const NAV_HOSTS = 'hosts';
export const NAV_STORAGE = 'volumes';
export const NAV_TEAMS = 'hosters';
export const NAV_TEAMS_PORTAL = 'portals';
export const NAV_TRACKING = 'alerts';
export const NAV_DOWLOADS = 'downloads';
export const NAV_FOR_HOSTERS = 'switchtypes';
export const NAV_SETTINGS = 'volumeattachments';

// Networking:
export const LABEL_PODS = 'Pods';
export const LABEL_NETWORKS = 'Networks';
export const LABEL_PRIVATE_NETWORKS = 'Private networks';
export const LABEL_IPPOOLS = 'IP pools';
export const LABEL_SWITCHES = 'Switches';
export const LABEL_SWITCHPORTS = 'Switch ports';
export const LABEL_SWITCHTYPES = 'Switch types';
// Racks:
export const LABEL_RACKS = 'Racks';
export const LABEL_RACKCONTROLLERS = 'Controllers';
export const LABEL_RACKTEMPLATES = 'Rack templates';
export const LABEL_RELEASES = 'Software releases';
// Compute:
export const LABEL_MACHINES = 'Machines';
export const LABEL_MACHINESIZES = 'Machine sizes';
export const LABEL_MACHINETYPES = 'Machine types';
export const LABEL_FMBASELINES = 'FW baselines';
export const LABEL_HOSTERSERVICES = 'Services';
export const LABEL_SERVICES = 'Services';
export const LABEL_TAGS = 'Tags';
// Hosts:
export const LABEL_HOSTS = 'Hosts';
export const LABEL_SSH_KEYS = 'SSH keys';
// Storage:
export const LABEL_VOLUMES = 'Volumes';
export const LABEL_ATTACHMENTS = 'Attachments';
export const LABEL_FLAVORS = 'Flavors';
export const LABEL_STORAGE_GROUPS = 'Storage groups';
export const LABEL_STORAGE_POOLS = 'Storage pools';
export const LABEL_STORAGE_SYSTEMS = 'Storage systems';
// Teams:
export const LABEL_MY_TEAM = 'My team';
export const LABEL_PROJECTS = 'Projects';
export const LABEL_ORGANIZATIONS = 'Organizations';
// Tracking:
export const LABEL_ALERTS = 'Alerts';
export const LABEL_OPERATIONS = 'Operations';
export const LABEL_FILES = 'Files';

export const LABEL_PORTALS = 'Portals';
export const LABEL_HOSTERS = 'Hosters';
export const LABEL_USERS = 'Users';
export const LABEL_VOLUME_ATTACHMENTS = 'Volume Attachments';
export const LABEL_DOWLOADS = 'Downloads';

export const tabToSectionMap = {
  [c.URL_PODS]: [NAV_NETWORKING],
  [c.URL_NETWORKS]: [NAV_NETWORKING],
  [c.URL_IPPOOLS]: [NAV_NETWORKING],
  [c.URL_SWITCHES]: [NAV_NETWORKING],
  [c.URL_SWITCHPORTS]: [NAV_NETWORKING],
  [c.URL_SWITCHTYPES]: [NAV_NETWORKING],

  [c.URL_RACKS]: [NAV_RACKS],
  [c.URL_RACKCONTROLLERS]: [NAV_RACKS],
  [c.URL_RACKTEMPLATES]: [NAV_RACKS],
  [c.URL_RELEASES]: [NAV_RACKS, NAV_FOR_HOSTERS],

  [c.URL_MACHINES]: [NAV_COMPUTE],
  [c.URL_MACHINESIZES]: [NAV_COMPUTE],
  [c.URL_MACHINETYPES]: [NAV_COMPUTE],
  [c.URL_FWBASELINES]: [NAV_COMPUTE],
  [c.URL_HOSTER_SERVICES]: [NAV_COMPUTE],
  [c.URL_TAGS]: [NAV_COMPUTE],

  [c.URL_HOSTS]: [NAV_HOSTS],
  [c.URL_SSHKEYS]: [NAV_HOSTS],

  [c.URL_VOLUMES]: [NAV_STORAGE],
  [c.URL_VOLUME_ATTACHMENTS]: [NAV_STORAGE],
  [c.URL_VOLUME_FLAVOR]: [NAV_STORAGE],
  [c.URL_STORAGE_GROUPS]: [NAV_STORAGE],
  [c.URL_STORAGE_POOLS]: [NAV_STORAGE],
  [c.URL_STORAGE_SYSTEMS]: [NAV_STORAGE],

  [c.URL_HOSTERS]: [NAV_TEAMS, NAV_TEAMS_PORTAL],
  [c.URL_PROJECTS]: [NAV_TEAMS],
  [c.URL_ORGANIZATIONS]: [NAV_TEAMS],

  [c.URL_ALERTS]: [NAV_TRACKING],
  [c.URL_OPS]: [NAV_TRACKING],
  [c.URL_FILES]: [NAV_TRACKING],

  [c.URL_SERVICES]: [NAV_FOR_HOSTERS],

  [c.URL_PORTALS]: [NAV_TEAMS_PORTAL],
  [c.URL_USERS]: [NAV_TEAMS_PORTAL],
};
