// (C) Copyright 2022-2024 Hewlett Packard Enterprise Development LP

import React from 'react';

import ListPageWithNav from '../../containers/generic/ListPageWithNav';
import { URL_SWITCHES } from '../../routes/consts';
import { useGetSwitches } from '../../services/switches';
import { NAV_NETWORKING } from '../../components/HybridNav/consts.js';

const SwitchListView = () => (
  <ListPageWithNav
    navSection={NAV_NETWORKING}
    path={URL_SWITCHES}
    sort={{
      property: 'name',
      direction: 'asc',
    }}
    title='Switches'
    useGetData={useGetSwitches}
  />
);

export default SwitchListView;
