// (C) Copyright 2023-2024 Hewlett Packard Enterprise Development LP

import { Text } from 'grommet';
import * as c from '../../routes/consts.js';
import * as mb from '../../containers/generic/MetaBuilder';
import MetaBuilder from '../../containers/generic/MetaBuilder';
import ItemViewContainer from '../../containers/generic/ItemViewContainer';
import CreateViewContainer from '../../containers/generic/CreateViewContainer';
import HealthStatus from '../../components/HealthStatus';
import auth from '../../lib/auth';
import { toDateTime } from '../../lib/formatters';
import StorageGroupsAlertsList from './StorageGroupsAlertsListView';
import { AllocatedCapacity } from '../StoragePoolsView/AllocatedCapacity.jsx';
import { LABEL_STORAGE_GROUPS } from '../../components/HybridNav/consts.js';

const BASE_URL = c.URL_STORAGE_GROUPS;

const settings = {
  authUrl: BASE_URL, // used to filter create/trash icons from the view; using the defined roledef permissions mappings to this auth URL entry
  homeUrl: c.makeSecUrl(BASE_URL), // homepage for this list view (e.g. /s/hosters/:pid/) is where this view is located; for return from create / item views
  homeLabel: LABEL_STORAGE_GROUPS,
  baseUrl: BASE_URL, // base url to be used for creating all associated URLS for this reasource, e.g. pageItem, pageCreate, restUrl, restItemUrl
};

function metaCreate(props) {
  const m = new MetaBuilder(props);

  const dsPods = m.newDataSource(c.URL_PODS);
  const dsRacks = m.newDataSource(c.URL_RACKS);
  const dsArrayTypes = m.newDataSource(c.URL_ARRAYTYPES);
  const member = auth.getActiveMember();

  m.addColumn('driver', 'Driver').Input('alletra').Hidden();
  m.addColumn('hoster_id', 'Hoster ID').Input(member?.hoster_id).Hidden();
  m.addColumn('portal_id', 'Portal ID').Input(member?.portal_id).Hidden();

  m.addColumn('name', 'Name').Input().Required().MaxWidth(mb.SMALL);

  m.addColumn('rackID', 'Rack')
    .Input()
    .Required()
    .DataXform(dsRacks, (json) => json.map((t) => ({ id: t.id, name: t.name })))
    .DropDown()
    .Width(mb.SMALL);

  m.addColumn('podID', 'Pod')
    .Input()
    .Required()
    .DataXform(dsPods, (json) => json.map((t) => ({ id: t.id, name: t.name })))
    .DropDown()
    .Width(mb.SMALL);

  m.addColumn('acs', 'Array connection string')
    .Input()
    .Required()
    .MaxWidth(mb.SMALL);

  m.addColumn('driver', 'Type')
    .Input()
    .Required()
    .DropDown()
    .DataXform(dsArrayTypes, (json) =>
      json.map((t) => ({ id: t.id, name: t.name })),
    )
    .Width(mb.XXSMALL);

  return m;
}

const DS_STORAGE_POOLS = 'capacitypools';
const DS_STORAGE_SYSTEMS = 'storagesystems';

function metabuilder(props) {
  const m = new MetaBuilder(props);
  const id = m.view.prop('itemId', false);

  const dsStoragePools = m
    .newDataSource(c.URL_STORAGE_POOLS, DS_STORAGE_POOLS)
    .Filter(({ storage_group_id }) => storage_group_id === id);

  const dsStorageSystems = m
    .newDataSource(c.URL_STORAGE_SYSTEMS, DS_STORAGE_SYSTEMS)
    .Filter(({ storage_group_id }) => storage_group_id === id);

  m.newDataSource(BASE_URL)
    .Item(id)
    .OnLoad((json) => {
      m.view.initForm(json.data);
    });

  m.addColumn('name', 'Name');

  m.addColumn('id', 'ID');

  m.addColumn('podID', 'Pod').MakeLink(c.URL_PODS);

  m.addColumn('driver', 'Driver');

  m.addColumn('management_ip', 'Management IP address');

  m.addColumn('storage_ha_configured', 'High availability storage').FieldXform(
    (storage_ha_configured) => (storage_ha_configured ? 'Yes' : 'No'),
  );

  m.addColumn('lastSeen', 'Last seen').FieldXform((lastSeen) =>
    lastSeen ? toDateTime(lastSeen) : '--',
  );

  m.addSection('Storage pools').MaxWidth(mb.LARGE);

  const storagePoolsTable = m
    .addInputTable('storagepools', 'Storage pools')
    .DataXform(dsStoragePools, (json) => json);

  storagePoolsTable
    .addField('capacityPoolName', 'Name')
    .ReadOnly()
    .CellXform((rowData) => rowData.capacityPoolName);

  storagePoolsTable
    .addField('capacity', 'Storage utilization GB')
    .ReadOnly()
    .CellXform((rowData) => <AllocatedCapacity data={{ id: rowData?.id }} />);

  storagePoolsTable
    .addField('totalVolumes', 'Volume count')
    .ReadOnly()
    .CellXform((rowData) => (
      <Text textAlign='end'>{rowData.totalVolumes}</Text>
    ));

  storagePoolsTable
    .addField('storage_type', 'Storage type')
    .ReadOnly()
    .CellXform((rowData) => rowData.storage_type);

  m.addSection('Storage systems').MaxWidth(mb.LARGE);

  const storageSystemsTable = m
    .addInputTable('storagesystems', 'Storage systems')
    .DataXform(dsStorageSystems, (json) => json);

  storageSystemsTable
    .addField('name', 'Name')
    .ReadOnly()
    .CellXform((rowData) => rowData.name);

  storageSystemsTable
    .addField('health_status', 'Health')
    .ReadOnly()
    .CellXform((rowData) => (
      <HealthStatus status={rowData.health_status} showLabel />
    ));

  m.addSection('Alerts')
    .Table(StorageGroupsAlertsList)
    .Expanded()
    .MaxWidth(mb.LARGE);

  return m;
}

export { StorageGroupsListView } from './StorageGroupsListView';

export const StorageGroupItemView = ItemViewContainer({
  ...settings,
  meta: (props) => metabuilder(props),
  title: 'Storage group',
});

export const StorageGroupCreateView = CreateViewContainer({
  ...settings,
  meta: (props) => metaCreate(props),
  title: 'Create storage group',
});
