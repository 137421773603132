// (C) Copyright 2016-2024 Hewlett Packard Enterprise Development LP

import * as c from '../../routes/consts.js';
import MetaBuilder, { LARGE } from '../../containers/generic/MetaBuilder';
import HealthSummary from '../../components/HealthSummary';
import debugLogger from '../../lib/debug';
import * as log from '../../lib/debug';
import { MachineAlertListView } from './alerts';
import { Tags } from './components/Tags';
import CommStatus from './components/ComStatus';
import { LABEL_MACHINES } from '../../components/HybridNav/consts.js';
import { toDateTime } from '../../lib/formatters';
const debug = debugLogger('MachinesView::index', log.LOG_LEVEL_DEBUG);
const BASE_URL = c.URL_MACHINES;

export const settings = {
  authUrl: BASE_URL, // used to filter create/trash icons from the view; using the defined roledef permissions mappings to this auth URL entry
  homeUrl: c.makeSecUrl(BASE_URL), // homepage for this list view (e.g. /s/hosters/:pid/) is where this view is located; for return from create / item views
  baseUrl: BASE_URL, // base url to be used for creating all associated URLS for this reasource, e.g. pageItem, pageCreate, restUrl, restItemUrl
  homeLabel: LABEL_MACHINES,
  meta: (props) => metabuilder(props),
};

const bmcType = (managedVia, type) =>
  managedVia === 'Direct' ? 'Direct Attached' : type;

// this is the machine ITEM (detail) view
function metabuilder(props) {
  const mb = new MetaBuilder(props);

  // setup base data source
  const id = mb.view.prop('itemId', false);
  let ds;

  if (id === undefined) {
    ds = mb.newStaticDataSource({});
  } else {
    ds = mb
      .newDataSource(c.URL_MACHINES)
      .Item(id)
      .OnLoad((json) => {
        debug.debug('got machine: ', json);
        mb.view.initForm(json.data);
      })
      .Poll();
  }

  mb.addSection('Basic info').NoChrome();

  mb.addColumn('name', 'Name').Default();

  mb.addColumn('id', 'ID');

  mb.addColumn('created', 'Created')
    .FieldXform((created) => (created ? toDateTime(created) : '--'))
    .ReadOnly();

  mb.addColumn('modified', 'Modified')
    .FieldXform((modified) => (modified ? toDateTime(modified) : '--'))
    .ReadOnly();

  const dsPods = mb.newDataSource(c.URL_PODS);
  const dsRacks = mb.newDataSource(c.URL_RACKS);
  mb.addColumn('pod_id', 'Pod')
    .DropDown()
    .ReadOnly()
    .DataXform(dsPods, (json) => json.map((t) => ({ id: t.id, name: t.name })))
    .ShowLink();

  mb.addColumn('rack_id', 'Rack')
    .DropDown()
    .ReadOnly()
    .DataXform(dsRacks, (json) => json.map((t) => ({ id: t.id, name: t.name })))
    .ShowLink();

  mb.addColumn('brownfield', 'Imported').FieldXform((val) =>
    val === true ? 'Yes' : 'No',
  );

  mb.addSection('Health summary', 'bmc.health_status')
    .Expanded()
    .MaxWidth(LARGE);

  mb.addColumn('bmc.health_status', 'Health').Custom(HealthSummary);

  mb.addSection().Table(MachineAlertListView).Expanded().MaxWidth(LARGE);

  //-----------------------------------------------------------------
  mb.addSection('Machine state').Expanded().MaxWidth(LARGE);

  mb.addColumn('state', 'State').ModXform(
    ({ v2_lifecycle: v2Lifecycle, machine_type_id: machineTypeId, state }) =>
      !v2Lifecycle &&
      machineTypeId === '' &&
      !state.startsWith('New') &&
      state !== 'Authentication Required' &&
      state !== 'Unknown Device'
        ? `${state} - no matching MachineType`
        : state,
  );

  mb.addColumn('state_reason', 'State reason');

  mb.addColumn('previous_state', 'Previous state');

  mb.addColumn('error_message', 'Error message');

  mb.addColumn('portal_comm_okay', 'Comm status')
    .Default()
    .FieldXform((val) => (val ? 'OK' : 'NC'))
    .Custom(CommStatus);

  mb.addColumn('portal_comm_reason', 'Comm status reason');

  mb.addColumn('bmc.post_state', 'POST state');

  //-----------------------------------------------------------------
  mb.addSection('Hardware').Expanded().MaxWidth(LARGE);

  mb.addColumn('hw.serial_num', 'Serial #').ModXform(
    ({ hw, bmc }) => hw?.serial_num || bmc?.system_serial,
  );

  mb.addColumn('hw.manuf', 'Manuf.').ModXform(
    ({ hw, bmc }) => hw?.manuf || bmc?.manufacturer,
  );

  mb.addColumn('hw.model', 'Model').ModXform(
    ({ hw, bmc }) => hw?.model || bmc?.model,
  );

  mb.addColumn('hw.cpu_model', 'CPU model');

  mb.addColumn('hw.cpus', 'CPUs');

  mb.addColumn('hw.cores', 'Cores');

  mb.addColumn('hw.ram', 'RAM');

  mb.addSection('BIOS Info').Expanded().MaxWidth(LARGE);

  mb.addColumn('hw.bios_vendor', 'BIOS vendor');

  mb.addColumn('bmc.bios_version', 'BIOS version');

  mb.addColumn('bmc.bios_date', 'BIOS date');

  mb.addColumn('fw_baseline_version', 'FW baseline version');

  mb.addColumn('fw_baseline_id', 'FW baseline ID');

  //-----------------------------------------------------------------
  mb.addSection('BMC Info').MaxWidth(LARGE);

  mb.addColumn('bmc', 'BMC type').FieldXform((val) => {
    const managedVia = val.managed_via;
    const type = val.bmc_type;
    return bmcType(managedVia, type);
  });

  mb.addColumn('bmc.mgmt_status', 'BMC management status');

  mb.addColumn('bmc.hw_addr', 'BMC MAC');

  mb.addColumn('bmc.user_name', 'BMC user');

  mb.addColumn('bmc.password', 'BMC password').Password();

  mb.addColumn('bmc.ip_addr', 'BMC IP').MakeLink('https', true);

  mb.addColumn('bmc.expired', 'BMC IP status').FieldXform((val) =>
    val ? 'Expired' : 'Current',
  );

  mb.addColumn('bmc.hostname', 'BMC hostname');

  mb.addColumn('bmc.bmc_fw_version', 'BMC firmware version');

  mb.addColumn('bmc.security_state', 'Security state');

  mb.addColumn('bmc.secure_boot_enable', 'Secure boot enabled').FieldXform(
    (val) => (val ? 'True' : 'False'),
  );

  //-----------------------------------------------------------------
  mb.addSection('Disks').Expanded().MaxWidth(LARGE);

  const diskTable = mb
    .addInputTable('disks', 'Disks')
    .ReadOnly()
    .DataXform(ds, (json) =>
      json.hw.disks === undefined ? [] : json.hw.disks,
    );

  diskTable.addField('type', 'Disk type').CellXform((rowData) => rowData.type);

  diskTable
    .addField('size', 'Disk size (GB)')
    .CellXform((rowData) => rowData.size);

  diskTable
    .addColumn('controller_id', 'Array controller')
    .DataXform(ds, (json, i) => {
      const controller_id = json?.hw?.disks[i]?.controller_id;
      const controller = json?.hw?.storage_controllers?.find(
        ({ id }) => id === controller_id,
      );
      return controller?.model;
    });

  //-----------------------------------------------------------------
  mb.addSection('NIC info').Expanded().MaxWidth('100%');

  const nicTable = mb
    .addInputTable('nics', 'NIC info')
    .ReadOnly()
    .DataXform(ds, (json) => json.nics || []);

  nicTable.addField('name', 'Name').CellXform((rowData) => rowData.name);

  nicTable
    .addField('hw_addr', 'MAC address')
    .CellXform((rowData) => rowData.hw_addr);

  nicTable
    .addField('switch_id', 'Switch')
    .DropDown()
    .ReadOnly()
    .DataXform(mb.newDataSource(c.URL_SWITCHES), (json) =>
      json.map((t) => ({ id: t.id, name: t.name })),
    )
    .ShowLink();

  nicTable
    .addField('switch_port', 'Switch port')
    .CellXform((rowData) => rowData.switch_port);

  nicTable.addField('speeds', 'Speeds').CellXform((rowData) => rowData.speeds);

  //-----------------------------------------------------------------
  mb.addSection('FC adapter info').Expanded().MaxWidth(LARGE);

  const fcTable = mb
    .addInputTable('fcAdapters', 'FC adapter info')
    .ReadOnly()
    .DataXform(ds, (json) =>
      json.hw.fc_adapters === undefined ? [] : json.hw.fc_adapters,
    );

  fcTable
    .addField('port_state', 'Port state')
    .CellXform((rowData) => rowData.port_state);

  fcTable
    .addField('port_name', 'Port name')
    .CellXform((rowData) => rowData.port_name);

  fcTable
    .addField('hba_model', 'HBA model name')
    .CellXform((rowData) => rowData.hba_model);

  fcTable.addField('speed', 'Speed').CellXform((rowData) => rowData.speed);

  fcTable
    .addField('supported_speeds', 'Supported speeds')
    .CellXform((rowData) => rowData.supported_speeds);

  //-----------------------------------------------------------------
  mb.addSection('Other').Expanded().MaxWidth(LARGE);

  mb.addColumn('host', 'Host').MakeLink(c.URL_HOSTS);

  mb.addColumn('owner', 'Owner').MakeLink(c.URL_PROJECTS);

  const dsMachineSizes = mb.newDataSource(c.URL_MACHINESIZES);
  const dsMachineTypes = mb.newDataSource(c.URL_MACHINETYPES);

  mb.addColumn('machine_size_ids', 'Machine sizes')
    .MultiSelect() // using a readonly dropdown will lookup friendly name and provide a hyperlink
    .ReadOnly()
    .DataXform(dsMachineSizes, (json) =>
      json.map((t) => ({ id: t.id, name: t.name })),
    )
    .ShowLink();

  mb.addColumn('machine_type_id', 'Machine type')
    .DropDown()
    .ReadOnly()
    .DataXform(dsMachineTypes, (json) =>
      json.map((t) => ({ id: t.id, name: t.name })),
    )
    .ShowLink();

  mb.addColumn('tags', 'Tags').Custom(({ col, value: tagList = [], data }) => {
    const { displayName } = col;
    const machineId = data?.id;
    return Tags({ displayName, tagList, machineId });
  });

  return mb;
}

export { default as MachineListView } from './MachineListView';
