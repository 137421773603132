// (C) Copyright 2016-2024 Hewlett Packard Enterprise Development LP

import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Box, Button, Text } from 'grommet';
import { FormDown, FormUp } from 'grommet-icons';
import NavItem from './NavItem';

const Collapsible = ({ label, items }) => {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <Box flex={false}>
      <Button hoverIndicator onClick={() => setCollapsed(!collapsed)} plain>
        <Box
          direction='row'
          justify='between'
          align='center'
          pad={{ vertical: 'xsmall', horizontal: 'small' }}
        >
          <Text weight='bold' color='text-strong'>
            {label}
          </Text>
          {collapsed ? <FormDown /> : <FormUp />}
        </Box>
      </Button>
      {!collapsed &&
        items.map((item, idx) => (
          <NavItem
            // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
            key={idx}
            {...item}
            sub
          />
        ))}
    </Box>
  );
};

const NavGroup = (props) => <Collapsible {...props} />;

NavGroup.propTypes = {
  label: PropTypes.string,
};

export default NavGroup;
