// (C) Copyright 2022-2024 Hewlett Packard Enterprise Development LP

import { useQuery } from 'react-query';

import { get } from '../lib/rest';
import { toDateTime } from '../lib/formatters';
import { renderLink } from './helper';
import auth from '../lib/auth';
import { makeRestUrl, URL_TAGS, SCOPE_HOSTER } from '../routes/consts';
import { Edit } from 'grommet-icons';

const columns = () => [
  {
    header: 'Name',
    isDefault: true,
    property: 'name',
  },
  {
    header: 'Purpose',
    isDefault: true,
    property: 'purpose',
  },
  {
    header: 'ID',
    property: 'id',
  },
  {
    header: 'Created',
    property: 'created',
    render: ({ created }) => toDateTime(created),
  },
  {
    header: 'Modified',
    property: 'modified',
    render: ({ modified }) => toDateTime(modified),
  },
  ...(auth.inScope(SCOPE_HOSTER)
    ? [
        {
          header: 'Edit',
          isDefault: true,
          property: 'edit',
          render: renderLink({
            base: URL_TAGS,
            icon: <Edit color='brand' />,
            isEdit: true,
          }),
        },
      ]
    : []),
];

export const getTags = async () => {
  const response = await get(makeRestUrl(URL_TAGS));

  return response.json();
};

export const useGetTags = () => {
  const queryResult = useQuery(URL_TAGS, getTags);

  return {
    ...queryResult,
    columns: columns().map((column) => ({
      ...column,
      columnName: column.property,
      displayName: column.header,
    })),
  };
};
