// (C) Copyright 2016-2024 Hewlett Packard Enterprise Development LP

import rest from './rest.js';
import * as log from './debug';
import debugLogger from './debug';

const debug = debugLogger('lib/time', log.LOG_LEVEL_WARN);

// This Time class provides a basic correction between browser and portal
// time.  A future version could be configured to query to the portal
// on an interval to make sure that they stay in sync.

class Time {
  constructor() {
    debug.debug('Time constructor called');
    this.offset = 0;

    rest
      .get('/info/time')
      .then((response) => response.json())
      .then((json) => this.setOffset(json))
      .catch(() => this.clearOffset());
  }

  setOffset = (dateString) => {
    debug.debug('Portal date string: ', dateString);
    const portalTime = new Date(dateString);
    const browserTime = new Date();
    this.offset = browserTime.getTime() - portalTime.getTime();
    debug.debug(
      'offset, portalTime, browsertime: ',
      this.offset,
      portalTime,
      browserTime,
    );
  };

  clearOffset = () => {
    this.offset = 0;
  };

  portal = () => {
    const browserTime = new Date();
    return new Date(browserTime.getTime() - this.offset);
  };
}

export const time = new Time();

export default time;
