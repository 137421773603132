// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  CheckBox,
  Form,
  FormField,
  MaskedInput,
  Select,
  TextInput,
} from 'grommet';
import styled from 'styled-components';
import Modal from '../Modal';
import { useGetVolumeFlavorMetaAlletra } from '../../utils/hooks';
import { MIN_GIB_ALLETRA_9K } from '../../lib/consts';
import isEmpty from 'lodash/isEmpty';

import { useDarkMode } from '../../utils';

const StyledFormField = styled(FormField)`
  ${({ disabled, darkMode }) => disabled === true
  ? `
label {
  color: ${darkMode ? 'rgb(255, 255, 255)': 'rgb(85, 85, 85)'};
  & + div {
    border: none;
  }
}
button {
  opacity: 1;
}
input {
  padding-left: 0;
}
svg {
  display: none;
}
` : ''}
`;

export const AddVolume = ({ storageResources, onHide, onSubmit }) => {
  const defaultFormValues = {
    name: '',
    description: '',
    capacity: '',
    flavor: '',
    storagePool: '',
    volumeCollection: '',
  };

  const { value: lightMode } = useDarkMode()
  let darkMode = useMemo(() => lightMode === true, [lightMode])
 

  const [formValues, setFormValues] = useState(defaultFormValues);
  const [formErrors, setFormErrors] = useState({});

  const volumeFlavorMetaAlletra = useGetVolumeFlavorMetaAlletra();

  const flavorOptions = useMemo(() => {
    const flavors = storageResources?.inventory.flatMap(
      ({ flavor_infos }) => flavor_infos,
    );

    const uniqueFlavors = Array.from(new Set(flavors.map(({ id }) => id))).map(
      (uniqueId) => flavors.find(({ id }) => id === uniqueId),
    );

    return (
      uniqueFlavors.map(({ name, id }) => ({
        label: name,
        value: id,
      })) || []
    );
  }, [storageResources?.inventory]);

  useEffect(() => {
    const shouldSetFlavor =
      flavorOptions.length === 1 && !formValues.flavor?.value;

    if (shouldSetFlavor) {
      setFormValues((prev) => ({
        ...prev,
        flavor: flavorOptions[0],
      }));
    }
  }, [flavorOptions, formValues]);

  const storagePoolOptions = useMemo(() => {
    const defaultStoragePool = { label: 'Auto', value: '' };

    if (storageResources.storage_pools === undefined) {
      return [defaultStoragePool];
    }

    const flavorId = formValues.flavor.value;

    if (flavorId === undefined) {
      return [defaultStoragePool];
    }

    const flavorInInventory = storageResources.inventory?.some((item) => {
      if (item.flavor_infos && formValues.flavor?.value) {
        return item.flavor_infos.some(
          (info) => info.id === formValues.flavor.value,
        );
      }
      return false;
    });

    if (flavorInInventory.length === 0) {
      return [defaultStoragePool];
    }

    const storagePoolIds = storageResources?.inventory
      .filter(({ flavor_infos }) =>
        flavor_infos.find(({ id }) => id === formValues.flavor?.value),
      )
      .map(({ storage_pool_id }) => storage_pool_id);

    const storagePools = storagePoolIds
      .map((storagePoolId) =>
        storageResources?.storage_pools.find(({ id }) => id === storagePoolId),
      )
      .map(({ name, id }) => ({
        label: name,
        value: id,
      }));

    const availableStoragePools = [defaultStoragePool, ...storagePools];

    return availableStoragePools;
  }, [
    storageResources.storage_pools,
    storageResources.inventory,
    formValues.flavor.value,
  ]);

  const handleChange = (form) => {
    const values = {
      ...form,
    };

    const selectedFlavor = values?.flavor?.value;
    const isFlavorAlletra9k = volumeFlavorMetaAlletra?.result?.[selectedFlavor];
    const volumeCapacity = parseFloat(values?.capacity);

    if (isFlavorAlletra9k && volumeCapacity) {
      if (volumeCapacity < MIN_GIB_ALLETRA_9K) {
        setFormErrors({
          ...formErrors,
          capacity: `Value must be greater than or equal to ${MIN_GIB_ALLETRA_9K}GiB`,
        });
      } else {
        let { capacity, ...restErrors } = formErrors;
        setFormErrors({ ...restErrors });
      }
    }

    const shouldSetStorage =
      storagePoolOptions.length === 1 && form.flavor.value !== undefined;
    const hasFlavorInInventory = storageResources.inventory?.some(
      ({ flavor_infos }) => {
        return flavor_infos?.some(({ id }) => id === form.flavor.value);
      },
    );

    if (shouldSetStorage) {
      values.storagePool = storagePoolOptions[0];
    }

    if (!hasFlavorInInventory && !shouldSetStorage) {
      values.storagePool = storagePoolOptions[0];
    }

    if (values.storagePool === '' || values.storagePool?.label === 'Auto') {
      values.volumeCollection = '';
    }

    setFormValues(values);
  };

  const volumeCollectionOptions = useMemo(() => {
    const { inventory, volume_collections } = storageResources;
    const defaultVolumeOption = { label: 'None', value: '' };

    const volumeCollectionIds = [
      ...new Set(inventory.flatMap((inv) => inv.volume_collection_ids)),
    ];

    const matchingCollections = volume_collections?.filter(
      (collection) =>
        volumeCollectionIds.includes(collection.id) &&
        collection.storage_pool_ids.includes(formValues.storagePool.value),
    );

    const volumeOptions = [
      defaultVolumeOption,
      ...matchingCollections.map(({ name, id }) => ({
        label: name,
        value: id,
      })),
    ];

    return volumeOptions;
  }, [
    storageResources.volume_collections,
    storageResources?.locations,
    formValues.storagePool.value,
  ]);

  return (
    <Modal show onHide={onHide} size='medium'>
      <Modal.Header onDismiss={onHide}>
        <Modal.Title>Add New Volume</Modal.Title>
      </Modal.Header>
      <Box pad={{ horizontal: 'medium' }} overflow='auto'>
        <Form onSubmit={onSubmit} onChange={handleChange} value={formValues}>
          <FormField
            label='Name'
            name='name'
            htmlFor='name'
            required
            component={TextInput}
            placeholder='Enter a name for the volume'
          />
          <FormField
            label='Description'
            name='description'
            htmlFor='description'
            component={TextInput}
            placeholder='Enter a description for the volume'
          />
          <FormField
            label='Volume Capacity (GB)'
            name='capacity'
            htmlFor='capacity'
            required
            component={MaskedInput}
            placeholder='Enter a capacity in GB'
            mask={[{ regexp: /^[0-9]+$/ }]}
            error={formErrors.capacity}
          />
          <StyledFormField
            component={Select}
            disabled={flavorOptions.length === 1}
            htmlFor='flavor'
            id='flavor'
            label='Volume Flavor'
            labelKey='label'
            name='flavor'
            options={flavorOptions}
            placeholder='Select volume flavor...'
            required
            valueKey='value'
            darkMode={darkMode}
          />
          {formValues.flavor.value ? (
            <StyledFormField
              component={Select}
              disabled={storagePoolOptions.length === 1}
              htmlFor='storagePool'
              id='storagePool'
              label='Storage pool'
              labelKey='label'
              name='storagePool'
              options={storagePoolOptions}
              placeholder='Select storage pool...'
              valueKey='value'
              darkMode={darkMode}
            />
          ) : null}
          {formValues.storagePool !== '' &&
          formValues.storagePool?.label !== 'Auto' ? (
            <StyledFormField
              component={Select}
              htmlFor='volumeCollection'
              id='volumeCollection'
              label='Volume Collection'
              labelKey='label'
              name='volumeCollection'
              options={volumeCollectionOptions}
              placeholder='Select volume collection...'
              valueKey='value'
              darkMode={darkMode}
            />
          ) : null}
          <FormField name='shareable'>
            <CheckBox name='shareable' label='Shareable' />
          </FormField>
          <Box direction='row' gap='medium' pad={{ vertical: 'medium' }}>
            <Button
              type='submit'
              primary
              label='Add new volume'
              disabled={!isEmpty(formErrors)}
            />
            <Button label='Cancel' onClick={onHide} />
          </Box>
        </Form>
      </Box>
    </Modal>
  );
};
