// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import React from 'react';
import { Box, Text, Footer } from 'grommet';

import { getVersion } from '../lib/version';

const LightText = (props) => (
  <Text color='text-xweak' size='xsmall' {...props} />
);

const ViewFooter = (props) => {
  const year = new Date().getFullYear();

  return (
    <Footer justify='center' pad='small'>
      <LightText>
        © 2017-{year} Hewlett Packard Enterprise Development LP
      </LightText>
      <LightText>{getVersion()}</LightText>
    </Footer>
  );
};

export default ViewFooter;
