// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

import { useEffect, useState } from 'react';
import { netSpeeds } from '../../data/network';

export default function useSwitchTypes(switchTypes = [], usedPorts = []) {
  const [commonPorts, setCommonPorts] = useState({
    availablePorts: {},
    availableSpeeds: [],
  });

  const reset = () => {
    setCommonPorts({
      availablePorts: {},
      availableSpeeds: [],
    });
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    const [first, ...rest] = switchTypes;

    if (first) {
      const commonPortsArray = rest.length
        ? first.ports.filter(({ name }) =>
            rest.every(({ ports }) => ports.find((port) => port.name === name)),
          )
        : first.ports;

      if (commonPortsArray.length) {
        const allSpeeds = commonPortsArray.reduce(
          (acc, cur) => ({
            // biome-ignore lint/performance/noAccumulatingSpread: <explanation>
            ...acc,
            ...cur.port_speeds.reduce(
              (speeds, speed) => ({
                // biome-ignore lint/performance/noAccumulatingSpread: <explanation>
                ...speeds,
                [speed]: acc[speed] ? [...acc[speed], cur.name] : [cur.name],
              }),
              {},
            ),
          }),
          {},
        );

        if (usedPorts.length) {
          // Remove parent/children ports
          // biome-ignore lint/complexity/noForEach: <explanation>
          Object.keys(allSpeeds).forEach((speed) => {
            allSpeeds[speed] = allSpeeds[speed].filter((port) => {
              const [parentPort] = port.split(':');

              return usedPorts.every((usedPort) => {
                const [usedParentPort, usedChildPort] = usedPort.split(':');

                return usedChildPort
                  ? usedParentPort !== port
                  : usedPort !== parentPort;
              });
            });
          });
        }

        setCommonPorts({
          availablePorts: allSpeeds,
          availableSpeeds: netSpeeds.filter((netSpeed) =>
            Object.keys(allSpeeds).includes(netSpeed),
          ),
        });
      } else {
        reset();
      }
    } else {
      reset();
    }
  }, [switchTypes, usedPorts]);

  return commonPorts;
}
