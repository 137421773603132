// (C) Copyright 2016-2022, 2024 Hewlett Packard Enterprise Development LP

import defaultTo from 'lodash/defaultTo';
import differenceWith from 'lodash/differenceWith';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import * as c from '../../routes/consts.js';
import * as mb from '../../containers/generic/MetaBuilder';
import MetaBuilder, {
  ValidationResult,
} from '../../containers/generic/MetaBuilder';
import ItemViewContainer from '../../containers/generic/ItemViewContainer';
import CreateViewContainer from '../../containers/generic/CreateViewContainer';
import EditViewContainer from '../../containers/generic/EditViewContainer';
import { machOperators, subattributeOperators } from '../../data/machine.js';
import debugLogger, * as log from '../../lib/debug';
import FormInlineSubattributes from '../../containers/generic/MetaForm/FormInlineSubattributes';
import { LABEL_MACHINESIZES } from '../../components/HybridNav/consts.js';
import { toDateTime } from '../../lib/formatters';

const debug = debugLogger('MachineSize', log.LOG_LEVEL_WARN);

const EXTERNAL_NAME = c.URL_MACHINESIZES;

export const settings = {
  authUrl: EXTERNAL_NAME, // used to filter create/trash icons from the view; using the defined roledef permissions mappings to this auth URL entry
  homeUrl: c.makeSecUrl(EXTERNAL_NAME), // homepage for this list view (e.g. /s/hosters/:pid/) is where this view is located; for return from create / item views
  homeLabel: LABEL_MACHINESIZES,
  baseUrl: EXTERNAL_NAME, // base url to be used for creating all associated URLS for this reasource, e.g. pageItem, pageCreate, restUrl, restItemUrl
  stateKey: EXTERNAL_NAME,
};

function metaCreate(props) {
  const m = new MetaBuilder(props);

  m.formDefaults((form) => {
    form.classifiers = defaultTo(form.classifiers, []);
    form.details = defaultTo(form.details, {});
    form.details.bullets = defaultTo(form.details.bullets, []);
  });

  const id = m.view.prop('itemId', false);

  let ds;

  debug.debug('got itemId', id);

  if (id === undefined) {
    ds = m.newStaticDataSource({});
  } else {
    ds = m
      .newDataSource(c.URL_MACHINESIZES)
      .Item(id)
      .OnLoad((json) => {
        debug.debug('got machinesize:', json);
        const form = json.data;
        form.details = defaultTo(form.details, {});
        form.details.bullets = defaultTo(form.details.bullets, []);
        m.view.initForm(form);
      });
  }

  m.addSection('1').NoChrome().MaxWidth(mb.LARGE);

  m.addColumn('name', 'Name').Input().Required().MaxLength(150).MaxWidth(mb.SMALL);

  m.addColumn('description', 'Description').Input().MaxWidth(mb.SMALL);

  m.addColumn('id', 'ID').ReadOnly();
  m.addColumn('modified', 'Modified')
    .FieldXform((modified) => (modified ? toDateTime(modified) : '--'))
    .ReadOnly();
  m.addColumn('created', 'Created')
    .FieldXform((created) => (created ? toDateTime(created) : '--'))
    .ReadOnly();
  m.addColumn('weight', 'Weight')
    .Input(50)
    .Number(1, 100, 'Must be an integer value in the range 1 to 100.')
    .Required()
    .MaxWidth(mb.SMALL);

  m.addField('project_use', 'Visible to Projects users when creating Hosts')
    .Input()
    .MaxWidth(mb.SMALL)
    .CheckBox(false);

  m.addField('hoster_use', 'Visible to Hoster users When creating Hosts')
    .Input()
    .MaxWidth(mb.SMALL)
    .CheckBox(false);

  m.addSection('Classifiers')
    .MaxWidth(mb.LARGE)
    .OverviewText(
      'Classifiers are used to select which machines belong to this Machine Size. A machine will be assigned the Machine Size that has any matching classifier (logical OR) and the highest weight value',
    );

  const t = m
    .addInputTable('classifiers', 'Classifier')
    .DataXform(ds, (json) => json.classifiers || [])
    .Required();

  t.AddDialogMeta(metaClassifierAdd, 'large');

  t.OnAdd((data) => {
    const form = m.view.getForm();
    const classifiers = m.view.state('form.classifiers');
    debug.debug('onAdd(classifiers): form', form, classifiers, data);

    ds.Push('classifiers', data); // add to dataset used to render the page
    m.view.addFormValue('classifiers', data); // add to form to be posted/put
  });

  t.OnDelete((data) => {
    debug.debug('onDelete(classifiers)', data);
    ds.Remove('classifiers', data);
    m.view.removeFormValue('classifiers', data);
  });

  t.EditDialogMeta(metaClassifierAdd, 'large'); // use same for now
  t.OnEdit((nextData, oldData) => {
    debug.debug('onEdit(classifiers)', oldData, nextData);
    ds.Update('classifiers', oldData, nextData, true);
    m.view.updateFormValue('classifiers', oldData, nextData, true);
  });

  const fName = t.addField('name', 'Name').CellXform((rowData) => rowData.name);

  if (m.readOnly) {
    fName.ViewModalItemLink();
  }

  m.addSection('Detailed description')
    .MaxWidth(mb.LARGE)
    .OverviewText(
      'Detailed description to aid user in their selection of appropriate Machine Size when creating a Host'
    );

  m.addColumn('details.collection', 'Collection').Input().MaxWidth(mb.SMALL);

  m.addColumn('details.banner1', 'Banner 1').Input().MaxWidth(mb.SMALL);

  m.addColumn('details.banner2', 'Banner 2').Input().MaxWidth(mb.SMALL);

  m.addColumn('details.info_link', 'Link to more info')
    .Input()
    .MaxWidth(mb.SMALL);

  m.addColumn('details.bullets[0]', 'Bullet 1').Input().MaxWidth(mb.SMALL);

  m.addColumn('details.bullets[1]', 'Bullet 2').Input().MaxWidth(mb.SMALL);

  m.addColumn('details.bullets[2]', 'Bullet 3').Input().MaxWidth(mb.SMALL);

  m.addColumn('details.bullets[3]', 'Bullet 4').Input().MaxWidth(mb.SMALL);

  m.addColumn('details.bullets[4]', 'Bullet 5').Input().MaxWidth(mb.SMALL);

  m.addColumn('details.bullets[5]', 'Bullet 6').Input().MaxWidth(mb.SMALL);

  m.addColumn('details.tooltip', 'Tooltip').Input().TextArea().MaxWidth(mb.SMALL);

  const dsProjects = m.newDataSource(c.URL_PROJECTS);
  m.addSection('Restricted use')
    .MaxWidth(mb.LARGE)
    .OverviewText(
      'Set restricted use for this machine size to the selected projects below. If no restrictions are set the machine size is available to anyone.'
    );
  m.addColumn('permitted_projects', 'Permitted')
    .Input()
    .MultiSelect()
    .DataXform(dsProjects, (json) =>
      json.map((t) => ({ id: t.id, name: t.name })),
    )
    .Width(mb.SMALL);

  return m;
}

function metaClassifierAdd(props) {
  const m = new MetaBuilder(props);

  m.formDefaults((form) => {
    form.rules = defaultTo(form.rules, []);
  });

  // if edit, then remove item being edited from unique set
  const origForm = m.view.propDefaultTo('formData', {});
  const dsOrigForm = m.newStaticDataSource(origForm);

  // unique name for classifier
  let other_classifiers = m.parent.view.formDefaultTo('classifiers', []);
  if (!isEmpty(origForm)) {
    other_classifiers = differenceWith(other_classifiers, [origForm], isEqual);
  }

  m.addField('name', 'Name').Input().Required().UniqueIn(other_classifiers);

  // rules
  m.addSection('Rules').OverviewText(
    "All rules within a classifier must match a machine's attributes for a machine to match the classifier (Logical AND).",
  );

  const rulesTable = m
    .addInputTable('rules', 'Rules')
    .DataXform(dsOrigForm, (json) => json.rules || [])
    .Required();

  rulesTable.AddDialogMeta(metaRuleAdd, 'large');

  rulesTable.OnAdd((data) => {
    debug.debug('onAdd(rules)', data);
    dsOrigForm.Push('rules', data);
    m.view.addFormValue('rules', data);
  });

  rulesTable.OnDelete((data) => {
    debug.debug('onDelete(rules)', data);
    dsOrigForm.Remove('rules', data);
    m.view.removeFormValue('rules', data);
  });

  rulesTable.EditDialogMeta(metaRuleAdd, 'large');

  rulesTable.OnEdit((nextData, oldData) => {
    debug.debug('onEdit(rules)', oldData, nextData);
    dsOrigForm.Update('rules', oldData, nextData, true);
    m.view.updateFormValue('rules', oldData, nextData, true);
  });

  rulesTable
    .addField('attribute', 'Attribute')
    .CellXform((rowData) => rowData.attribute);

  rulesTable
    .addField('operator', 'Operator')
    .CellXform((rowData) => rowData.operator);

  rulesTable.addField('value', 'Value').CellXform((rowData) => rowData.value);

  rulesTable
    .addField('subattributes', '') // Hide column but pass the data
    .Custom()
    .CellXform((rowData) => rowData.subattribute_rules);

  return m;
}

function metaRuleAdd(props) {
  const m = new MetaBuilder(props);

  const machineSizeClassifierinfo = m.newDataSource(
    c.URL_MACHINE_SIZE_CLASSIFIER_INFO,
  );
  const dsOperators = m.newStaticDataSource(machOperators);

  const hasSubattributes = () => {
    const { attribute } = m.view.getForm();
    const { rule_attributes } = machineSizeClassifierinfo.Data() || {
      rule_attributes: [],
    };
    const attributeData = rule_attributes.find(
      ({ name }) => attribute === name,
    );
    return attributeData?.has_subattributes;
  };

  const withSubattributes = () => hasSubattributes();
  const withoutSubattributes = () => !hasSubattributes();

  // TODO: validate unique rule for all 3 attributes

  m.addField('attribute', 'Attribute', true)
    .Input()
    .Required()
    .DropDown()
    .DataXform(machineSizeClassifierinfo, (json) =>
      json.rule_attributes.map((t) => ({ ...t, id: t.name })),
    )
    .OnInit(() => {
      if (hasSubattributes()) {
        dsOperators.SetData(['match']);
      }
    })
    .OnChange(() => {
      if (hasSubattributes()) {
        m.view.setFormValue('operator', 'match');
        dsOperators.SetData(['match']);
      } else {
        m.view.setFormValue('subattribute_rules', []);
        dsOperators.SetData(machOperators);
      }
    });

  m.addField('operator', 'Operator')
    .Input()
    .Required()
    .DropDown()
    .DataXform(dsOperators, (json) => json.map((t) => ({ id: t, name: t })))
    .CustomValidator((value) => {
      if (withSubattributes() && value !== 'match') {
        return new ValidationResult(
          false,
          "'match' is only allowed for attribute with subattributes",
        );
      }

      return new ValidationResult(true, '');
    });

  m.addField('value', 'Value')
    .Input()
    .RequiredSometimes(withoutSubattributes)
    .Visible(withoutSubattributes);

  m.addField('subattribute_rules', 'Subattributes')
    .Input()
    .Custom(FormInlineSubattributes, {
      operators: subattributeOperators,
      attributes: machineSizeClassifierinfo,
    })
    .Visible(withSubattributes)
    .CustomValidator((value) => {
      if (
        !value ||
        (!value.length && !hasSubattributes()) ||
        (value.length &&
          value.every((row) => Object.keys(row).every((key) => !!row[key])))
      ) {
        return new ValidationResult(true);
      }
      const validations = value.map((subattribute) =>
        Object.keys(subattribute).reduce(
          (acc, key) =>
            !subattribute[key] ? { ...acc, [key]: `${key} is required` } : acc,
          {},
        ),
      );

      return new ValidationResult(false, validations);
    });

  return m;
}

export { default as MachineSizeListView } from './MachineSizeListView';

export const MachineSizeItemView = ItemViewContainer({
  ...settings,
  allowEdit: true,
  meta: (props) => metaCreate(props),
  title: 'Machine size',
});

export const MachineSizeCreateView = CreateViewContainer({
  ...settings,
  meta: (props) => metaCreate(props),
  title: 'Create machine size',
});

export const MachineSizeEditView = EditViewContainer({
  ...settings,
  meta: (props) => metaCreate(props),
  title: 'Edit machine size',
});
