// (C) Copyright 2022-2024 Hewlett Packard Enterprise Development LP

import React, { useMemo } from 'react';

import auth from '../../lib/auth';
import ListPageWithNav from '../../containers/generic/ListPageWithNav';
import { SCOPE_PROJECT, URL_NETWORKS } from '../../routes/consts';
import { useGetNetworks } from '../../services/networks';
import { NAV_NETWORKING } from '../../components/HybridNav/consts.js';

const NetworkListView = () => {
  const title = useMemo(
    () => (auth.inScope(SCOPE_PROJECT) ? 'My networks' : 'Network'),
    [],
  );

  return (
    <ListPageWithNav
      navSection={NAV_NETWORKING}
      actions={{
        allowCreate: 'Create network',
      }}
      path={URL_NETWORKS}
      sort={{
        property: 'name',
        direction: 'asc',
      }}
      title={title}
      useGetData={useGetNetworks}
    />
  );
};

export default NetworkListView;
