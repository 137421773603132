// (C) Copyright 2018-2024 Hewlett Packard Enterprise Development LP

import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'grommet';
import { Modal } from '..';

const ErrorDialog = ({
  title,
  body,
  onAction1,
  action1Text = 'Action 1',
  onDismiss,
  dismissText = 'OK',
  show,
  children,
}) => (
  <Modal show={show} onHide={onDismiss}>
    <Modal.Header onDismiss={onDismiss}>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>

    <Modal.Body>
      {children}
      {body}
    </Modal.Body>

    <Modal.Footer>
      {onAction1 && (
        <Button key='buttonAction1' onClick={onAction1} label={action1Text} />
      )}
      {onDismiss && (
        <Button
          key='buttonDismiss'
          primary
          onClick={onDismiss}
          label={dismissText}
        />
      )}
    </Modal.Footer>
  </Modal>
);

ErrorDialog.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  children: PropTypes.node,
  onAction1: PropTypes.func,
  action1Text: PropTypes.string,
  onDismiss: PropTypes.func,
  dismissText: PropTypes.string,
  show: PropTypes.bool,
};

export default ErrorDialog;
