// (C) Copyright 2016-2022, 2024 Hewlett Packard Enterprise Development LP

import { jwtDecode } from 'jwt-decode';
import isArray from 'lodash/isArray';
import isString from 'lodash/isString';
import { OKTA, URL_LOGOUT, URL_USER_INFO } from '../routes/consts';
import debugLogger, * as log from './debug';
import { get } from './rest';

const debug = debugLogger('lib/auth', log.LOG_LEVEL_WARN);

const userTokenKey = 'userToken';
const activeMemberKey = 'activeMember';
const userProfileKey = 'userProfile';
const activeMemberID = 'activeMemberID';
const tenantID = 'tenantID';
const activeProjectID = 'activeProjectID';

class Authentication {
  constructor() {
    this.authListeners = [];
    // If we're in development mode and have a password,
    // login directly without showing the login dialog.
    this.directLogin =
      process.env.NODE_ENV === 'development' &&
      process.env.REACT_APP_QUATTRO_PORTAL_PASSWORD;
  }

  OnAuthenticated(f) {
    this.authListeners.push(f);
    return this;
  }

  notifyAuthListeners(result, err) {
    for (const l of this.authListeners) {
      l(result, err);
    }
  }

  login = () => {
    if (this.auth_provider === OKTA) {
      this.oktaAuth.token.getWithRedirect({
        scopes: ['openid', 'email', 'profile', 'offline_access'],
        loginHint: '@hpe.com',
      });
    }
  };

  logout() {
    get(URL_LOGOUT)
      .then((response) => response.json())
      .then(({ logoutUrl }) => {
        if (logoutUrl) {
          debug.debug('logout ok');
          sessionStorage.clear();
          window.location.href = logoutUrl;
        }
      })
      .catch((err) => {
        debug.debug('logout error', err);
      });
  }

  getAccessToken() {
    return sessionStorage.getItem(userTokenKey);
  }

  isIAM() {
    // If the token contains a tenantId, it should be from IAM.
    // tenantId matches Organization.TenantID in quake
    const token = this.getAccessToken();
    if (token) {
      const decoded = jwtDecode(token);
      return !!decoded.tenantId;
    }
    return false;
  }

  setTenantID(id) {
    sessionStorage.setItem(tenantID, id);
  }

  getTenantID() {
    return sessionStorage.getItem(tenantID);
  }

  setActiveMemberID(id) {
    sessionStorage.setItem(activeMemberID, id);
    // localStorage is required to pass into the serial console window
    localStorage.setItem(activeMemberID, id);
  }

  getActiveMemberID() {
    return sessionStorage.getItem(activeMemberID);
  }

  setActiveProjectID(id) {
    sessionStorage.setItem(activeProjectID, id);
  }

  getActiveProjectID() {
    return sessionStorage.getItem(activeProjectID);
  }

  setActiveScope(scope) {
    this.activeScope = scope;
    debug.log('setActiveScope', scope);
  }

  setActiveRole(roleDef) {
    this.activeRoleDef = roleDef;
    debug.log('setActiveRoleDef', roleDef);
  }

  inScope(scopes) {
    debug.log('inScope', scopes, this.activeScope);

    if (this.activeScope === undefined) {
      debug.log('inScope: no activeScope found!');
      return false;
    }

    if (isString(scopes)) {
      scopes = [scopes];
    }

    if (!isArray(scopes)) {
      throw new Error(`Scopes must be array:${scopes}`);
    }

    for (const scope of scopes) {
      if (scope === this.activeScope) {
        return true;
      }
    }
    return false;
  }

  setActiveMember(metadata) {
    const value = JSON.stringify(metadata);
    sessionStorage.setItem(activeMemberKey, value);
  }

  getActiveMember() {
    return JSON.parse(sessionStorage.getItem(activeMemberKey));
  }

  async getUserInfo() {
    const activeMemberId = this.getActiveMemberID();
    if (activeMemberId) {
      return get(URL_USER_INFO)
        .then(async (userInfo) => JSON.parse(await userInfo.text()))
        .catch((err) => {
          debug.throw('getUserInfo, err:', err);
        });
    }
    debug.throw('getUserInfo: no activeMemberID found!');
  }

  async getUserProfile() {
    const userProfile = sessionStorage.getItem(userProfileKey);
    if (userProfile) {
      return JSON.parse(userProfile);
    }
    if (this.getActiveMemberID()) {
      const userInfo = await this.getUserInfo();
      if (userInfo) {
        sessionStorage.setItem(userProfileKey, JSON.stringify(userInfo));
        return userInfo;
      }
      debug.throw('getUserProfile: no userInfo found!');
    }
  }

  securityDisabled() {
    return JSON.parse(process.env.REACT_APP_DISABLE_SECURITY);
  }
}

const _authentication = new Authentication();

export default _authentication;
