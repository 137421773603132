// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import React from 'react';
import { Box, Button, Text } from 'grommet';
import { URL_LOGIN } from '../../routes/consts';

export default function Welcome(props) {
  return (
    <Box
      fill
      background='dark-1'
      align='center'
      justify='center'
      style={{ height: '100vh' }}
    >
      <Box pad='large' gap='medium'>
        <Box round='small' overflow='hidden'>
          <Button plain href={URL_LOGIN}>
            {({ hover }) => (
              <Box
                pad={{ horizontal: 'large', vertical: 'medium' }}
                background={hover ? 'light-2' : 'brand'}
              >
                <Text size='xlarge' weight='bold'>
                  Login
                </Text>
              </Box>
            )}
          </Button>
        </Box>
        {props?.error &&
          props.error !== '' &&
          typeof props.error === 'string' && (
            <Text color='status-critical'>{props.error}</Text>
          )}
      </Box>
    </Box>
  );
}
