// (C) Copyright 2020-2024 Hewlett Packard Enterprise Development LP

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import isArray from 'lodash/isArray';
import isArrayLike from 'lodash/isArrayLike';
import isEmpty from 'lodash/isEmpty';
import { Box, FormField, Select, Text } from 'grommet';
import { FormClose } from 'grommet-icons';
import LinksCell from './LinksCell';
import FormReadOnlyField from './FormReadOnlyField';
import debugLogger, * as log from '../../../lib/debug';

const debug = debugLogger('FormMultiDropDown', log.LOG_LEVEL_WARN);

const Placeholder = ({ children }) => (
  <Box pad={{ horizontal: 'small' }}>
    <Text color='text-weak'>{children}</Text>
  </Box>
);

const SelectValue = ({ onClick, children }) => (
  <Box
    background='selected-background'
    round='xsmall'
    direction='row'
    gap='xsmall'
    align='center'
    pad={{ horizontal: 'small', vertical: 'xsmall' }}
    margin='xxsmall'
  >
    <Text style={{ wordBreak: 'break-all' }}>{children}</Text>
    {onClick && <FormClose onClick={onClick} />}
  </Box>
);

const MultiSelect = ({
  allowCreate,
  options,
  placeholder,
  values = [],
  onChange,
  ...rest
}) => {
  const [searchValue, setSearchValue] = useState('');
  const onSearch = (text) => {
    setSearchValue(text);
  };

  const onSelectOption = (event) => {
    // The selection in 'value' in the event will be what item
    // was selected but won't include the other existing selections
    // from the 'values' prop. We need to add the new selection to
    // the old ones
    // If value contains an 'undefined' element, its the created one.
    // Grommet Select tries to map values in on change to existing
    // options and will come up with undefined for the new one.
    // Deal with that here.
    const addedValues = event.value.map((value) => value || searchValue);

    const nextValue = [...values, ...addedValues];
    onChange(nextValue);
  };

  // Only show items that aren't selected already. Since all the
  // selected items can be seen and de-selected in the main input
  // we don't need them cluttering up the drop down
  const remainingOpts = options.filter(({ value }) => !values.includes(value));

  const opts =
    allowCreate && searchValue
      ? [{ label: `Create '${searchValue}'`, value: searchValue }]
      : remainingOpts;

  return (
    <Select
      multiple
      plain
      closeOnChange={allowCreate}
      placeholder={placeholder}
      options={opts}
      value={values}
      labelKey='label'
      valueKey={{ key: 'value', reduce: true }}
      disabledKey='disabled'
      valueLabel={
        <Box wrap direction='row' height={{ min: '42px' }} align='center'>
          {values.length === 0 ? (
            <Placeholder>{placeholder}</Placeholder>
          ) : (
            values.map((value) => {
              const item = find(options, { value }) || {
                value,
                label: value,
              };
              return (
                <SelectValue
                  key={item.value}
                  onClick={(event) => {
                    event.stopPropagation();
                    onChange(values.filter((value) => value !== item.value));
                  }}
                >
                  {item.label}
                </SelectValue>
              );
            })
          )}
        </Box>
      }
      onChange={onSelectOption}
      searchPlaceholder={
        allowCreate ? 'Type to create a new option' : undefined
      }
      onSearch={allowCreate ? onSearch : undefined}
      onClose={() => setSearchValue('')}
      {...rest}
    />
  );
};

const getMultiValue = (options, valueCSV) => {
  const valuesArray = valueCSV ? valueCSV.split(',') : [];
  return valuesArray;
};

const getMultiOptions = (items) => {
  if (!isArrayLike(items)) {
    debug.throw('getMultiOptions() requires an items array, got: ', items);
  }
  return items.map(({ id, name, disabled, required }) => ({
    value: id,
    label: name,
    disabled,
    clearableValue: !required,
  }));
};

const FormMultiDropDown = ({
  col,
  value,
  data,
  onChange,
  validationResult = {},
  ...rest
}) => {
  let ids = [];

  if (isArray(value)) {
    ids = value;
    value = value.join(','); // multiselect is an list of csv
  } else {
    value = '';
  }

  // Because of the way that the MetaForm handles state, we need a way to pass in the form data into the functions.
  // this allows the readOnly function to get the form data, to make logic decisions.
  // isReadOnly is either a boolean or function...
  if (
    typeof col.isReadOnly === 'function'
      ? col.isReadOnly(col.mb.view.getForm())
      : col.isReadOnly
  ) {
    return (
      <FormReadOnlyField label={col.displayName}>
        <LinksCell col={col} ids={ids} data={data || []} />
      </FormReadOnlyField>
    );
  }

  const options = getMultiOptions(data || []);
  const values = getMultiValue(options, value);
  const placeholder = isEmpty(data) ? col.placeholderIfEmpty : col.placeholder;

  const inputStyle = { style: {} };

  if (col.width) {
    inputStyle.style.minWidth = col.width;
    inputStyle.style.maxWidth = col.width;
  }
  if (col.minWidth) {
    inputStyle.style.minWidth = col.minWidth;
  }
  if (col.maxWidth) {
    inputStyle.style.maxWidth = col.maxWidth;
  }

  return (
    <FormField
      label={col.isRequired ? `${col.displayName}*` : col.displayName}
      name={col.keyPath}
      error={validationResult.msg}
      style={{ gridColumn: '1 / span 2', ...inputStyle.style }}
    >
      <MultiSelect
        allowCreate={!!col.allowCreate}
        placeholder={placeholder}
        options={options}
        values={values}
        onChange={(value) => {
          onChange(col.keyPath, value);
        }}
        {...inputStyle}
        {...rest}
      />
    </FormField>
  );
};

FormMultiDropDown.propTypes = {
  col: PropTypes.shape({
    displayName: PropTypes.string.isRequired,
    keyPath: PropTypes.string,
    isReadOnly: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    placeholderIfEmpty: PropTypes.string,
    placeholder: PropTypes.string,
    usuallySingleSelect: PropTypes.bool,
    width: PropTypes.any,
    minWidth: PropTypes.any,
    maxWidth: PropTypes.any,
  }).isRequired,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      disabled: PropTypes.bool,
      required: PropTypes.bool,
    }),
  ),
  validationResult: PropTypes.shape({
    msg: PropTypes.string,
  }),
};

export default FormMultiDropDown;
