// (C) Copyright 2020-2024 Hewlett Packard Enterprise Development LP

import React, { useMemo } from 'react';
import find from 'lodash/find';
import get from 'lodash/get';
import { Box } from 'grommet';
import { Card, CardBody, CardFooter, CardHeading } from './Card';
import { createPersistedState } from '../utils';
import IconButton from './generic/IconButton';

const hasData = (graphData) => {
  const total = get(graphData, 'total', 0);
  return total > 0;
};

const defaultViews = [];

const ViewToggle = ({ views = defaultViews, view, onChange }) => {
  const onClick = (nextView) => {
    onChange?.(nextView);
  };

  return (
    <Box direction='row' gap='xsmall' justify='end' align='start'>
      {views
        .filter((viewItem) => viewItem.id !== view)
        .map(({ id, Icon }) => (
          <IconButton
            icon={<Icon color='brand' />}
            key={id}
            onClick={() => onClick(id)}
            tip={`Display as ${id}`}
          />
        ))}
    </Box>
  );
};

export const DashboardPanel = ({
  title,
  icon,
  subtitle,
  data,
  footer,
  views,
  defaultView = 'none',
  storageKey = 'panelView',
  children,
}) => {
  const usePersistedViewState = useMemo(
    () => createPersistedState(storageKey),
    [storageKey],
  );
  const [view, setView] = usePersistedViewState(defaultView);
  const ActiveView = get(find(views, { id: view }), 'content', undefined);
  return hasData(data) ? (
    <Card width='auto' color='background-front' height='400px'>
      <CardHeading title={title} icon={icon} subtitle={subtitle}>
        <ViewToggle
          onChange={(option) => setView(option)}
          view={view}
          views={views}
        />
      </CardHeading>
      <CardBody pad='medium' fill>
        {ActiveView && <ActiveView data={data} />}
        {children}
      </CardBody>
      {footer && <CardFooter>{footer}</CardFooter>}
    </Card>
  ) : null;
};
DashboardPanel.ViewToggle = ViewToggle;
