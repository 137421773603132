// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

import React, { useMemo } from 'react';
import { Box, Text } from 'grommet';

import { get, isEmpty } from '../utils/lodash';
import HealthStatus, { icons } from './HealthStatus';
import FormLabel from '../containers/generic/MetaForm/FormLabel';

const a9kModels = ['9060', '9080', 'MP'];
const a9kModelRegex = new RegExp(`\\b(${a9kModels.join('|')})\\b`, 'ig');

const HealthSummary = ({ col, data }) => {
  const { customComponentMetadata = {}, displayName, keyPath } = col;
  const { summaryOnly: isSummaryOnly } = customComponentMetadata;

  const healthStatus = useMemo(() => get(data, keyPath), [data, keyPath]);

  const shouldHideDetails = useMemo(() => a9kModelRegex.test(data.model), [data]);

  const details = useMemo(() => {
    if (!healthStatus?.details) {
      return null;
    }

    if (healthStatus.details) {
      const filteredDetails = Object.keys(healthStatus.details).filter(
        (key) => !key.startsWith('__'),
      );

      if (data.resource_type === 'array') {
        return filteredDetails.reduce((acc, cur) => {
          const [type, name, part] = cur.split('_').slice(2);
          const device = `${type} ${name}`;

          if (name) {
            if (acc[device]) {
              acc[device][part] = healthStatus.details[cur];
            } else if (part) {
              acc[device] = {
                [part]: healthStatus.details[cur],
              };
            } else {
              acc[device] = healthStatus.details[cur];
            }
          }

          return acc;
        }, {});
      }

      return {
        details: healthStatus.details,
      };
    }

    return null;
  }, [healthStatus, data.resource_type]);

  if (isEmpty(healthStatus)) {
    return null;
  }

  if (isSummaryOnly) {
    return (
      <>
        <Box justify='center'>
          <FormLabel>{displayName}</FormLabel>
        </Box>
        <HealthStatus status={healthStatus} />
      </>
    );
  }

  return (
    <Box gap='xsmall'>
      <Box direction='row'>
        <Box pad='xsmall' width='216px'>
          <Text weight={500}>Summary</Text>
        </Box>
        <Box direction='row' gap='xxsmall' pad='xxsmall'>
          <Box pad='xxsmall'>{icons[healthStatus.summary]}</Box>
          <Text>{healthStatus.summary}</Text>
        </Box>
      </Box>
      {!shouldHideDetails ? (
        <>
        {healthStatus.reason && (
          <Box direction='row'>
            <Box pad='xsmall' width='216px'>
              <Text weight={500}>Reason</Text>
            </Box>
            <Box direction='row' pad='xsmall'>
              <Text>{healthStatus.reason || '-'}</Text>
            </Box>
          </Box>
        )}
        <Box pad={{ horizontal: 'xsmall' }}>
          {details
            ? Object.keys(details).map((device) => (
                <Box
                  key={device}
                  pad={{ horizontal: 'none', vertical: 'xsmall' }}
                >
                  <Box pad={{ bottom: 'xsmall' }} direction='row' gap='small'>
                    <Text style={{ textTransform: 'capitalize' }} weight='bold'>
                      {device}
                    </Text>
                    {typeof details[device] === 'string' ? (
                      <Text>{details[device]}</Text>
                    ) : null}
                  </Box>
                  {typeof details[device] === 'object' ? (
                    <Box gap='xsmall'>
                      {Object.keys(details[device]).map((name) => {
                        const value = details[device][name];

                        return typeof value === 'string' ? (
                          <Box key={name} direction='row'>
                            <Box weight={500} width='100%'>
                              <Text truncate="tip">{name}</Text>
                            </Box>
                            <Box direction='row' gap='xxsmall' pad='xxsmall' width='240px'>
                              <Box pad={{ top: 'xxsmall', left: 'small', right: 'small' }}>
                                {icons[value]}
                              </Box>
                              <Text>{value}</Text>
                            </Box>
                          </Box>
                        ) : null;
                      })}
                    </Box>
                  ) : null}
                </Box>
              ))
            : null}
        </Box>
        </>
      ) : null}
    </Box>
  );
};

export default HealthSummary;
