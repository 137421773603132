// (C) Copyright 2022-2024 Hewlett Packard Enterprise Development LP

import { useQuery } from 'react-query';
import { get } from '../lib/rest';

const URL_MEMBERSHIP = '/myuser/memberships';
const URL_ROLE = '/rest/roledef';

export const getMembership = async () => {
  const response = await get(URL_MEMBERSHIP);

  return response.json();
};

export const useGetMembership = (enabled) => {
  const queryResult = useQuery(URL_MEMBERSHIP, getMembership, {
    enabled,
  });

  return queryResult;
};

export const getRole = async () => {
  const response = await get(URL_ROLE);

  return response.json();
};

export const useGetRole = (id) => {
  const queryResult = useQuery(URL_ROLE, getRole, {
    enabled: !!id,
  });

  return queryResult;
};
