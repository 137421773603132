// (C) Copyright 2023-2024 Hewlett Packard Enterprise Development LP

import React, { useEffect, useState } from 'react';
import { Box, RadioButton, Text } from 'grommet';
import { EDGE_RACK, DATA_CENTER_RACK } from '../../data/rack';

export const FormRackType = ({ col, data }) => {
  const [value, setValue] = useState(DATA_CENTER_RACK);
  const { setFormValue } = col.mb.view;
  const { type } = data;

  useEffect(() => {
    if (type) {
      setValue(data.type);
    }
  }, [data.type, type]);

  useEffect(() => {
    setFormValue('type', value);
  }, [setFormValue, value]);

  const handleChange = (rack) => () => setValue(rack);

  return (
    <Box style={{ gridColumn: '1/span 2', width: '400px' }}>
      <Box pad='xsmall'>
        <Text>Type</Text>
      </Box>
      <Box border pad='small' round='xsmall'>
        <RadioButton
          checked={value === DATA_CENTER_RACK}
          label='Data center rack'
          onChange={handleChange(DATA_CENTER_RACK)}
        />
        <RadioButton
          checked={value === EDGE_RACK}
          label='Edge rack'
          onChange={handleChange(EDGE_RACK)}
        />
      </Box>
    </Box>
  );
};
