// (C) Copyright 2016-2024 Hewlett Packard Enterprise Development LP

import React from 'react';
import ViewHeader from '../../components/ViewHeader';
import View from '../../components/View';

const HomeView = () => (
  <View>
    <ViewHeader title>Overview</ViewHeader>
  </View>
);

export default HomeView;
