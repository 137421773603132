// (C) Copyright 2020-2024 Hewlett Packard Enterprise Development LP

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import capitalize from 'lodash/capitalize';
import { Box, Meter, Text, ThemeContext } from 'grommet';

const BarMeter = ({ label, value, total, color }) => (
  <Box gap='xsmall'>
    <Box direction='row' gap='large' justify='between'>
      <Text>{label}</Text>
      <Text color='text-strong'>{value}</Text>
    </Box>
    <Meter
      max={total}
      background='background-contrast'
      size='full'
      thickness='16px'
      type='bar'
      values={[
        {
          label,
          value,
          color,
        },
      ]}
      style={{ borderRadius: '4px' }}
    />
  </Box>
);

export const Bar = ({ data }) => {
  const theme = useContext(ThemeContext);
  const colors = theme.dashboard.fiveColor;
  const values = data.values.map((item, index) => ({
    color: colors[index % colors.length],
    ...item,
  }));

  // values.push({ label: 'Problem', value: PROBLEMS, color: 'status-critical' })
  // values[0].value = values[0].value - PROBLEMS

  return (
    <Box height='100vh' gap='small'>
      {values.map(({ value, label, color }, index) => (
        <BarMeter
          key={`bar_${index}`}
          value={value}
          color={color}
          label={capitalize(label)}
          total={data.total}
        />
      ))}
    </Box>
  );
};

Bar.propTypes = {
  data: PropTypes.shape({
    total: PropTypes.number,
    values: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.number,
        color: PropTypes.string,
      }),
    ),
  }),
};

export const StackedBarMeter = ({ values, total, ...rest }) => {
  const theme = useContext(ThemeContext);
  const colors = theme.dashboard.fiveColor;

  return (
    <Box
      height='16px'
      style={{ borderRadius: '4px' }}
      fill='horizontal'
      background='background-contrast'
      direction='row'
      overflow='hidden'
      {...rest}
    >
      {values?.map(({ value, color }, index) => (
        <Box
          key={`value${index}`}
          width={`${(value / total) * 100}%`}
          fill='vertical'
          background={color || colors[index % colors.length]}
        />
      ))}
    </Box>
  );
};

StackedBarMeter.propTypes = {
  data: PropTypes.shape({
    total: PropTypes.number,
    values: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.number,
        color: PropTypes.string,
      }),
    ),
  }),
};
