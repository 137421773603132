// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import React from 'react';

import ListPageWithNav from '../../containers/generic/ListPageWithNav';
import { URL_VOLUME_FLAVOR } from '../../routes/consts';
import { useGetVolumeFlavors } from '../../services/volumeFlavors';
import { NAV_STORAGE } from '../../components/HybridNav/consts.js';

const VolumeFlavorListView = () => (
  <ListPageWithNav
    navSection={NAV_STORAGE}
    actions={{
      allowCreate: 'Create volume flavor',
    }}
    path={URL_VOLUME_FLAVOR}
    sort={{
      property: 'name',
      direction: 'asc',
    }}
    title='Volume flavors'
    useGetData={useGetVolumeFlavors}
  />
);

export default VolumeFlavorListView;
