// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import React from 'react';
import { Box } from 'grommet';

const View = ({ children }) => (
  <Box flex='grow' pad={{ left: 'none', top: 'small' }}>
    {children}
  </Box>
);
export default View;
