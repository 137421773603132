// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

import React, { useMemo } from 'react';
import isArray from 'lodash/isArray';
import { Box, Select, Text } from 'grommet';
import PropTypes from 'prop-types';
import { FormClose } from 'grommet-icons';

const PlaceHolder = (props) => (
  <Text color='text-weak' margin={{ horizontal: 'small' }} {...props} />
);

const ValueComponent = ({ label, required, onRemove }) => (
  <Box
    align='center'
    direction='row'
    gap='xsmall'
    pad={{ horizontal: 'xsmall' }}
    margin='xsmall'
    background='selected-background'
    round='xxsmall'
    width='max-content'
  >
    <Text size='small' color='text-strong' truncate>
      {label}
    </Text>
    {!required && (
      <Box>
        <FormClose onClick={onRemove} />
      </Box>
    )}
  </Box>
);

export const MultiSelect = (props) => {
  const {
    options,
    value = [],
    labelKey = 'label',
    valueKey = 'value',
    placeholder,
    onChange,
    ...rest
  } = props;

  let selectedOptions = [];

  if (isArray(value)) {
    selectedOptions = value;
  } else if (value !== null && value !== undefined) {
    selectedOptions = [value];
  }

  const availableOptions = useMemo(
    () =>
      options.filter((option) =>
        option instanceof Object
          ? value.every(
              (selectedOption) => selectedOption.value !== option.value,
            )
          : value.indexOf(option) === -1,
      ),
    [value, options],
  );

  const onSelectOption = (event) => {
    const nextValue = [...value, ...event.value];
    if (onChange) {
      onChange({ value: nextValue });
    }
  };

  return (
    <Select
      multiple
      plain
      onChange={onSelectOption}
      closeOnChange={false}
      placeholder={placeholder}
      options={availableOptions}
      labelKey={labelKey}
      valueKey={valueKey}
      valueLabel={
        <Box wrap direction='row' height={{ min: '36px' }} align='center'>
          {selectedOptions && selectedOptions.length > 0 ? (
            selectedOptions.map((option) => (
              <ValueComponent
                {...option}
                label={option[labelKey]}
                key={option[valueKey]}
                onRemove={(event) => {
                  event.stopPropagation();
                  if (onChange) {
                    onChange({
                      value: selectedOptions.filter((item) => item !== option),
                    });
                  }
                }}
              />
            ))
          ) : (
            <PlaceHolder>{placeholder}</PlaceHolder>
          )}
        </Box>
      }
      {...rest}
    />
  );
};

ValueComponent.propTypes = {
  onRemove: PropTypes.func,
  label: PropTypes.string,
  required: PropTypes.bool,
};

MultiSelect.propTypes = {
  onChange: PropTypes.func,
  labelKey: PropTypes.string,
  valueKey: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.array,
  placeholder: PropTypes.string,
};
