// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import MetaBuilder from '../../containers/generic/MetaBuilder';
import { FC, attachProtocolLabels } from '../../data/volume';
import auth from '../../lib/auth';
import {
  URL_VOLUME_ATTACHMENTS as BASE_URL,
  URL_VOLUMES,
  URL_HOSTS,
  SCOPE_HOSTER,
} from '../../routes/consts';

export default function volumeAttachmentsItem(props) {
  const m = new MetaBuilder(props);
  const id = m.view.prop('itemId', false);

  m.newDataSource(BASE_URL)
    .Item(id)
    .OnLoad((json) => {
      m.view.initForm(json.data);
    });

  const isFc = () => m.view.formDefaultTo('attach_protocol') === FC;

  m.addColumn('name', 'Name');

  m.addColumn('state', 'State');

  m.addColumn('hostID', 'Host ID').MakeLink(URL_HOSTS);

  m.addColumn('volumeID', 'Volume ID').MakeLink(URL_VOLUMES);

  m.addColumn('lun', 'Logical Unit Number');

  m.addColumn('attach_protocol', 'Protocol').FieldXform(
    (value) => attachProtocolLabels[value] || '',
  );

  m.addColumn('monitored', 'Monitored').FieldXform((monitored) =>
    monitored ? 'yes' : 'no',
  );

  const inHosterScope = auth.inScope(SCOPE_HOSTER);
  if (inHosterScope) {
    m.addColumn('arrayHostID', 'Array Host Entity ID');
  }

  m.addColumn('id', 'ID');

  m.addColumn('hostIPAddress', 'Host IP').Visible(() => !isFc());

  m.addColumn('iqn', 'Host IQN').Visible(() => !isFc());

  m.addColumn('chapUserName', 'CHAP User').Visible(() => !isFc());

  m.addColumn('volumeTargetIPAddress', 'Target IP').Visible(() => !isFc());

  m.addColumn('volumeTargetIQN', 'Target IQN').Visible(() => !isFc());

  return m;
}
