// (C) Copyright 2022-2024 Hewlett Packard Enterprise Development LP

import { useQuery } from 'react-query';

import { get } from '../lib/rest';
import { toDateTime } from '../lib/formatters';
import { makeRestUrl, URL_RELEASES } from '../routes/consts';
import { renderLink } from './helper';

const columns = [
  {
    header: 'Name',
    isDefault: true,
    property: 'name',
    render: renderLink({
      base: URL_RELEASES,
    }),
  },
  {
    header: 'Version',
    isDefault: true,
    property: 'version',
  },
  {
    header: 'Description',
    isDefault: true,
    property: 'description',
  },
  {
    header: 'Created',
    property: 'created',
    render: ({ created }) => toDateTime(created),
  },
  {
    header: 'Modified',
    property: 'modified',
    render: ({ modified }) => toDateTime(modified),
  },
];

export const getReleases = async () => {
  const url = makeRestUrl(URL_RELEASES);
  const response = await get(url);

  return response.json();
};

export const useGetReleases = () => {
  const queryResult = useQuery(URL_RELEASES, getReleases);

  return {
    ...queryResult,
    columns: columns.map((column) => ({
      ...column,
      columnName: column.property,
      displayName: column.header,
    })),
  };
};
