// (C) Copyright 2016-2024 Hewlett Packard Enterprise Development LP

import PropTypes from 'prop-types';
import React from 'react';
import { Link } from '..';

const LinkComponent = ({ metadata, rowData }) => {
  if (!(rowData && metadata)) {
    return null;
  }

  const infoFunc = metadata.customComponentMetadata.info;
  const info = infoFunc(rowData);

  return (
    <Link
      to={info.url}
      icon={info.icon}
      style={info.style}
      tip={info.tip}
      title={info.name}
      external={info.external}
    />
  );
};

LinkComponent.propTypes = {
  rowData: PropTypes.object,
  metadata: PropTypes.object,
};

export default LinkComponent;
