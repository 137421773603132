// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import React from 'react';

import ListPage from '../../containers/generic/ListPage';
import { URL_VOLUME_GROUPS } from '../../routes/consts';
import { useGetVolumeGroups } from '../../services/volumeGroups';

const VolumeGroupListView = () => (
  <ListPage
    actions={{}}
    path={URL_VOLUME_GROUPS}
    sort={{
      property: 'name',
      direction: 'asc',
    }}
    title='Volume Groups'
    useGetData={useGetVolumeGroups}
  />
);

export default VolumeGroupListView;
