// (C) Copyright 2023-2024 Hewlett Packard Enterprise Development LP

import React, { useMemo } from 'react';
import { Box, Tag } from 'grommet';
import { useMembershipContext } from '../../utils/context/membershipContext';

export const TagsDisplay = (props) => {
  const { activeMembership } = useMembershipContext();
  const labels = props?.data?.labels || {};

  const role = useMemo(
    () => activeMembership?.role_id,
    [activeMembership?.role_id],
  );

  const canView = useMemo(
    () => ['hoster_owner', 'hoster_admin', 'hoster_viewer'].includes(role),
    [role],
  );

  if (!props || !canView) return null;

  const tags = Object.entries(labels)
    .filter(([key]) => key !== '__uniqueid')
    .map(([key, value]) => (
      <Box key={key} margin='small'>
        <Tag alignSelf='start' name={key} value={value} />
      </Box>
    ));

  return (
    <Box direction='row' wrap={true}>
      {tags?.length > 0 ? tags : 'There are no Tags to display'}
    </Box>
  );
};
