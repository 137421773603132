// (C) Copyright 2016-2024 Hewlett Packard Enterprise Development LP

import * as c from '../../routes/consts.js';
import MetaBuilder, { LARGE } from '../../containers/generic/MetaBuilder';
import ColumnLink from '../../components/Griddle/ColumnLink';
import { toDateTime } from '../../lib/formatters';
import ItemViewContainer from '../../containers/generic/ItemViewContainer';
import CreateViewContainer from '../../containers/generic/CreateViewContainer';
import HealthSummary from '../../components/HealthSummary';
import { LABEL_SWITCHPORTS } from '../../components/HybridNav/consts.js';

const EXTERNAL_NAME = c.URL_SWITCHPORTS;

const settings = {
  authUrl: EXTERNAL_NAME, // used to filter create/trash icons from the view; using the defined roledef permissions mappings to this auth URL entry
  homeUrl: c.makeSecUrl(EXTERNAL_NAME), // homepage for this list view (e.g. /s/hosters/:pid/) is where this view is located; for return from create / item views
  baseUrl: EXTERNAL_NAME, // base url to be used for creating all associated URLS for this reasource, e.g. pageItem, pageCreate, restUrl, restItemUrl
  stateKey: EXTERNAL_NAME,
  meta: (props) => metabuilder(props),
  homeLabel: LABEL_SWITCHPORTS,
};

function metabuilder(props) {
  const mb = new MetaBuilder(props);

  mb.addColumn('name', 'Name')
    .Custom(ColumnLink, {
      info: (rowData) => {
        const url = c.setId(c.makeSecItemUrl(EXTERNAL_NAME), rowData.id);
        const { name } = rowData;
        return { url, name };
      },
    })
    .Default()
    .Input()
    .Required();

  mb.addColumn('id', 'ID');

  mb.addColumn('created', 'Created').FieldXform((created) =>
    created ? toDateTime(created) : '--',
  );
  mb.addColumn('modified', 'Modified').FieldXform((modified) =>
    modified ? toDateTime(modified) : '--',
  );

  mb.addColumn('rack_id', 'Rack ID').Default().MakeLink(c.URL_RACKS);
  mb.addColumn('switch_id', 'Switch ID').Default().MakeLink(c.URL_SWITCHES);
  mb.addColumn('switch', 'Switch name');
  mb.addColumn('fabric', 'Fabric');
  mb.addColumn('port_name', 'Port name').Default();
  mb.addColumn('role', 'Port role').Default();
  mb.addColumn('port_speed', 'Port speed').Default();

  mb.addColumn('host_id', 'Host ID').MakeLink(c.URL_HOSTS);
  mb.addColumn('machine_id', 'Machine ID').MakeLink(c.URL_MACHINES);
  mb.addColumn('device_id', 'Device ID').MakeLink(c.URL_DEVICES);

  // Health Summary section
  mb.addSection('Health summary', 'health_status').MaxWidth(LARGE).Expanded();

  mb.addColumn('health_status', 'Health').Custom(HealthSummary);

  return mb;
}

export { default as SwitchPortListView } from './SwitchPortListView';

export const SwitchPortItemView = ItemViewContainer({
  ...settings,
  meta: (props) => metabuilder(props),
  title: 'Switch port',
});

export const SwitchPortCreateView = CreateViewContainer({
  ...settings,
  title: 'Create switch port',
});
