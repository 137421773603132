// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

import React from 'react';
import { Box, Text, Tip } from 'grommet';
import {
  StatusCriticalSmall,
  StatusGoodSmall,
  StatusInfoSmall,
  StatusWarningSmall,
} from 'grommet-icons';

import { get, isEmpty } from '../utils/lodash';
import {
  STATUS_CRITICAL,
  STATUS_NOT_PRESENT,
  STATUS_OK,
  STATUS_UNKNOWN,
  STATUS_WARNING,
} from '../lib/consts';

export const icons = {
  [STATUS_OK]: <StatusGoodSmall color='status-ok' />,
  [STATUS_WARNING]: <StatusWarningSmall color='status-warning' />,
  [STATUS_CRITICAL]: <StatusCriticalSmall color='status-critical' />,
  [STATUS_NOT_PRESENT]: <StatusInfoSmall color='status-unknown' />,
  [STATUS_UNKNOWN]: <StatusInfoSmall color='status-unknown' />,
};

const HealthStatus = ({ status, metadata, rowData, showLabel }) => {
  const health = status || get(rowData, metadata.keyPath);

  if (isEmpty(health)) {
    return null;
  }

  if (showLabel) {
    return (
      <Box direction='row' gap='xsmall'>
        <Box pad='xxsmall'>{icons[health.summary]}</Box>
        {showLabel ? (
          <Box justify='center' align='left'>
            {health.summary}
          </Box>
        ) : null}
      </Box>
    );
  }

  return (
    <Tip
      content={
        <Box
          background='background'
          direction='row'
          justify='center'
          pad={{ horizontal: 'small', vertical: 'xsmall' }}
          style={{
            border: '1px solid #e5e5e5',
            borderRadius: 4,
          }}
        >
          <Text weight='bold'>{health.summary}</Text>
          {health.reason ? <Text>: {health.reason}</Text> : null}
        </Box>
      }
      dropProps={{ align: { top: 'bottom' } }}
      plain
    >
      <Box pad='small'>{icons[health.summary]}</Box>
    </Tip>
  );
};

export default HealthStatus;
