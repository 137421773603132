// (C) Copyright 2022-2024 Hewlett Packard Enterprise Development LP

import React from 'react';

import ListPageWithNav from '../../containers/generic/ListPageWithNav';
import { URL_SWITCHTYPES } from '../../routes/consts';
import { useGetSwitchTypes } from '../../services/switchTypes';
import { useMembershipContext } from '../../utils/context/membershipContext';
import {
  NAV_FOR_HOSTERS,
  NAV_NETWORKING,
} from '../../components/HybridNav/consts.js';

export const SwitchTypeListView = () => {
  const { activeMembership } = useMembershipContext();
  const actions =
    activeMembership?.role_id === 'portal_admin' ? { allowPublish: true } : {};
  const canSelect = activeMembership?.role_id === 'portal_admin';
  const navSection =
    activeMembership?.scope === 'portal' ? NAV_FOR_HOSTERS : NAV_NETWORKING;
  return (
    <ListPageWithNav
      navSection={navSection}
      actions={actions}
      noSelect={!canSelect}
      path={URL_SWITCHTYPES}
      sort={{
        property: 'name',
        direction: 'asc',
      }}
      title='Switch types'
      useGetData={useGetSwitchTypes}
    />
  );
};
