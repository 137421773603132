// (C) Copyright 2022-2024 Hewlett Packard Enterprise Development LP

import React from 'react';

import ListPageWithNav from '../../containers/generic/ListPageWithNav';
import { URL_SSHKEYS } from '../../routes/consts';
import { useGetSSHKeys } from '../../services/sshkeys';
import { NAV_HOSTS, NAV_SETTINGS } from '../../components/HybridNav/consts.js';
import auth from '../../lib/auth';

const SSHKeyListView = () => {
  const navSection = auth.isIAM() ? NAV_SETTINGS : NAV_HOSTS;

  return (
    <ListPageWithNav
      navSection={navSection}
      actions={{
        allowCreate: 'Create SSH key',
      }}
      path={URL_SSHKEYS}
      sort={{
        property: 'name',
        direction: 'asc',
      }}
      title='SSH keys'
      useGetData={useGetSSHKeys}
    />
  );
};

export default SSHKeyListView;
