// (C) Copyright 2016-2024 Hewlett Packard Enterprise Development LP

import defaultTo from 'lodash/defaultTo';
import differenceWith from 'lodash/differenceWith';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import matches from 'lodash/matches';
import * as c from '../../routes/consts.js';
import MetaBuilder from '../../containers/generic/MetaBuilder';
import ItemViewContainer from '../../containers/generic/ItemViewContainer';
import CreateViewContainer from '../../containers/generic/CreateViewContainer';
import EditViewContainer from '../../containers/generic/EditViewContainer';
import {
  policyAttributesIn,
  policyAttributesOut,
  policyActions,
} from '../../data/networkpolicies.js';
import debugLogger from '../../lib/debug';
import * as log from '../../lib/debug';

const debug = debugLogger('NetworkPolicies', log.LOG_LEVEL_WARN);

const EXTERNAL_NAME = c.URL_NETWORKPOLICIES;

export const settings = {
  authUrl: EXTERNAL_NAME, // used to filter create/trash icons from the view; using the defined roledef permissions mappings to this auth URL entry
  homeUrl: c.makeSecUrl(EXTERNAL_NAME), // homepage for this list view (e.g. /s/hosters/:pid/) is where this view is located; for return from create / item views
  baseUrl: EXTERNAL_NAME, // base url to be used for creating all associated URLS for this reasource, e.g. pageItem, pageCreate, restUrl, restItemUrl
  stateKey: EXTERNAL_NAME,
};

function metaCreate(props) {
  const m = new MetaBuilder(props);

  m.formDefaults((form) => {
    form.ingress_rules = defaultTo(form.ingress_rules, []);
    form.egress_rules = defaultTo(form.egress_rules, []);
  });

  const id = m.view.prop('itemId', false);

  let ds;

  debug.debug('got itemId', id);

  if (id === undefined) {
    ds = m.newStaticDataSource({});
  } else {
    ds = m
      .newDataSource(c.URL_NETWORKPOLICIES)
      .Item(id)
      .OnLoad((json) => {
        debug.debug('got network policy:', json);
        m.view.initForm(json.data);
      });
  }

  m.addSection('1').NoChrome().MaxWidth(1200);

  m.addColumn('name', 'Name').Input().Required().MaxWidth(800);

  m.addColumn('description', 'Description').Input().MaxWidth(800);

  m.addColumn('id', 'ID').ReadOnly();
  m.addColumn('created', 'Created').ReadOnly();
  m.addColumn('modified', 'Modified').ReadOnly();

  // section for ingress rules
  m.addSection('Inbound Rules')
    .MaxWidth(1200)
    .OverviewText(
      'Inbound rules control the incoming traffic that is allowed to reach the instance.',
    );

  const ingress = m
    .addInputTable('ingress_rules', 'Ingress Rule')
    .DataXform(ds, (json) => json.ingress_rules || [])
    .Required();

  ingress.AddDialogMeta(metaPoliciesAddIngress);
  ingress.OnAdd((data) => {
    const form = m.view.getForm();

    const ingress_rules = m.view.state('form.ingress_rules');
    debug.debug('onAdd(ingress_rules): form', form, ingress_rules, data);

    ds.Push('ingress_rules', data); // add to dataset used to render the page
    m.view.addFormValue('ingress_rules', data); // add to form to be posted/put
  });

  ingress.OnDelete((data) => {
    debug.debug('onDelete(ingress_rules)', data);

    const match = matches(data); //  <<<<<<<<<<<<<<<<<<<<<<<<<<< name field
    ds.Remove('ingress_rules', match);
    m.view.removeFormValue('ingress_rules', match);
  });

  ingress.EditDialogMeta(metaPoliciesAddIngress); // use same for now
  ingress.OnEdit((nextData, oldData) => {
    debug.debug('onEdit(ingress_rules)', oldData, nextData);

    const match = matches(oldData); // <<<<<<<<<<<<<<<<<<<<<<<<<<<< name field
    ds.Update('ingress_rules', match, nextData, true);
    m.view.updateFormValue('ingress_rules', match, nextData, true);
  });

  const fIName = ingress
    .addField('name', 'Rule Name')
    .CellXform((rowData) => rowData.name);

  if (m.readOnly) {
    fIName.ViewModalItemLink();
  }

  ingress.addField('id', 'ID').CellXform((rowData) => rowData.id);

  ingress.addField('type', 'Type').CellXform((rowData) => rowData.type);

  ingress
    .addField('ipaddress', 'Source')
    .CellXform((rowData) => rowData.ipaddress);

  ingress
    .addField('ipprotocol', 'Protocol')
    .CellXform((rowData) => rowData.ipprotocol);

  ingress
    .addField('port_range', 'Port Range')
    .CellXform((rowData) => rowData.port_range);

  ingress.addField('action', 'Action').CellXform((rowData) => rowData.action);

  ingress
    .addField('description', 'Description')
    .CellXform((rowData) => rowData.description);

  // section for egress rules
  m.addSection('Outbound Rules')
    .MaxWidth(1200)
    .OverviewText(
      'Outbound rules control the outgoing traffic that is allowed to leave the instance.',
    );

  const egress = m
    .addInputTable('egress_rules', 'Egress Rule')
    .DataXform(ds, (json) => json.egress_rules || [])
    .Required();

  egress.AddDialogMeta(metaPoliciesAddEgress);
  egress.OnAdd((data) => {
    const form = m.view.getForm();

    const egress_rules = m.view.state('form.egress_rules');
    debug.debug('onAdd(egress_rules): form', form, egress_rules, data);

    ds.Push('egress_rules', data); // add to dataset used to render the page
    m.view.addFormValue('egress_rules', data); // add to form to be posted/put
  });

  egress.OnDelete((data) => {
    debug.debug('onDelete(egress_rules)', data);

    const match = matches(data); //  <<<<<<<<<<<<<<<<<<<<<<<<<<< name field
    ds.Remove('egress_rules', match);
    m.view.removeFormValue('egress_rules', match);
  });

  egress.EditDialogMeta(metaPoliciesAddEgress); // use same for now
  egress.OnEdit((nextData, oldData) => {
    debug.debug('onEdit(egress_rules)', oldData, nextData);

    const match = matches(oldData); // <<<<<<<<<<<<<<<<<<<<<<<<<<<< name field
    ds.Update('egress_rules', match, nextData, true);
    m.view.updateFormValue('egress_rules', match, nextData, true);
  });

  const fEName = egress
    .addField('name', 'Rule Name')
    .CellXform((rowData) => rowData.name);

  if (m.readOnly) {
    fEName.ViewModalItemLink();
  }

  egress.addField('id', 'ID').CellXform((rowData) => rowData.id);

  egress.addField('type', 'Type').CellXform((rowData) => rowData.type);

  egress
    .addField('ipaddress', 'Destination')
    .CellXform((rowData) => rowData.ipaddress);

  egress
    .addField('ipprotocol', 'Protocol')
    .CellXform((rowData) => rowData.ipprotocol);

  egress
    .addField('port_range', 'Port Range')
    .CellXform((rowData) => rowData.port_range);

  egress.addField('action', 'Action').CellXform((rowData) => rowData.action);

  egress
    .addField('description', 'Description')
    .CellXform((rowData) => rowData.description);

  return m;
}

function metaPoliciesAddIngress(props) {
  const m = new MetaBuilder(props);

  // if edit, then remove item being edited from unique set
  const origForm = m.view.propDefaultTo('formData', {});

  const dsAttributes = m.newStaticDataSource(policyAttributesIn);
  const dsActions = m.newStaticDataSource(policyActions);

  // unique name for classifier
  let other_policies = m.parent.view.formDefaultTo('ingress_rules', []);
  if (!isEmpty(origForm)) {
    other_policies = differenceWith(other_policies, [origForm], isEqual);
  }

  m.addField('name', 'Name').Input().Required().UniqueIn(other_policies);

  // rules
  m.addSection('Rules');
  // .OverviewText('IP Protocols are automatically filled.')

  m.addField('type', 'Type')
    .Input()
    .Required()
    .DropDown()
    .DataXform(dsAttributes, (json) => json.map((t) => ({ id: t, name: t })));

  m.addField('id', 'ID').Input().Required();

  m.addField('ipaddress', 'Source').Input().Required();

  m.addField('ipprotocol', 'Protocol').Input().Required();

  m.addField('port_range', 'Port Range').Input().Required();

  m.addField('action', 'Action')
    .Input()
    .Required()
    .DropDown()
    .DataXform(dsActions, (json) => json.map((t) => ({ id: t, name: t })));

  m.addField('description', 'Description').Input();

  return m;
}

function metaPoliciesAddEgress(props) {
  const m = new MetaBuilder(props);

  const origForm = m.view.propDefaultTo('formData', {});

  const dsAttributes = m.newStaticDataSource(policyAttributesOut);
  const dsActions = m.newStaticDataSource(policyActions);

  // unique name for classifier
  let other_policies = m.parent.view.formDefaultTo('egress_rules', []);
  if (!isEmpty(origForm)) {
    other_policies = differenceWith(other_policies, [origForm], isEqual);
  }

  m.addField('name', 'Name').Input().Required().UniqueIn(other_policies);

  // rules
  m.addSection('Rules');
  // .OverviewText('IP Protocols are automatically filled.')

  m.addField('type', 'Type')
    .Input()
    .Required()
    .DropDown()
    .DataXform(dsAttributes, (json) => json.map((t) => ({ id: t, name: t })));

  m.addField('id', 'ID').Input().Required();

  m.addField('ipaddress', 'Destination').Input().Required();

  m.addField('ipprotocol', 'Protocol').Input().Required();

  m.addField('port_range', 'Port Range').Input().Required();

  m.addField('action', 'Action')
    .Input()
    .Required()
    .DropDown()
    .DataXform(dsActions, (json) => json.map((t) => ({ id: t, name: t })));

  m.addField('description', 'Description').Input();

  return m;
}

export { default as NetworkPoliciesListView } from './NetworkPolicyListView';

export const NetworkPoliciesItemView = ItemViewContainer({
  ...settings,
  allowEdit: true,
  meta: (props) => metaCreate(props),
  title: 'Network Policy Details',
});

export const NetworkPoliciesCreateView = CreateViewContainer({
  ...settings,
  meta: (props) => metaCreate(props),
  title: 'Create Network Policy',
});

export const NetworkPoliciesEditView = EditViewContainer({
  ...settings,
  meta: (props) => metaCreate(props),
  title: 'Edit Network Policy',
});
