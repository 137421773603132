// (C) Copyright 2022-2024 Hewlett Packard Enterprise Development LP

import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, DataTable, Form, FormField, Select } from 'grommet';

import { Modal } from '../../components';
import { useGetReleases } from '../../services/releases';
import { useGetRackControllers } from '../../services/rackControllers';
import { AlertsContext } from '../../utils/context/alertsContext';
import { AlertsList } from '../../components/controls/AlertsList';
import {
  makeRestUrl,
  setId,
  URL_RACKCONTROLLER_UPGRADE,
} from '../../routes/consts';
import { errorInfo, put } from '../../lib/rest';

const UpdateModal = ({ selected, onClose }) => {
  const [release, setRelease] = useState('');
  const [cancelLabel, setCancelLabel] = useState('Cancel');
  const { addAlert, alerts } = useContext(AlertsContext);
  const { data: releases } = useGetReleases();
  const { data: controllers, refetch } = useGetRackControllers({ addAlert });
  const pollRef = useRef(null);

  const versions = useMemo(
    () => releases?.map(({ version }) => version) || [],
    [releases],
  );

  useEffect(() => {
    if (versions.length && !release) {
      setRelease(versions[0]);
    }
  }, [release, versions]);

  const handleCancelClick = () => {
    clearInterval(pollRef.current);
    onClose();
  };

  const handleApplyClick = () => {
    const ids = selected
      .filter(
        ({ update_request: updateRequest, version }) =>
          version !== release && updateRequest.version !== release,
      )
      .map(({ id }) => id);

    const promises = ids.map((id) => {
      const url = makeRestUrl(setId(URL_RACKCONTROLLER_UPGRADE, id));
      const version = { version: release };

      return put(url, version);
    });

    if (promises.length) {
      Promise.all(promises)
        .then((resArray) => {
          // biome-ignore lint/complexity/noForEach: <explanation>
          resArray.forEach(({ ok, status }) => {
            if (!ok) {
              addAlert(`Apply Upgrade: ${status}`, 'danger');
            }
          });

          pollRef.current = setInterval(() => {
            try {
              refetch();
              const isDone = controllers.every(
                ({ update_request_status: update }) =>
                  ['success', 'failure'].includes(update.version_status),
              );

              if (isDone) {
                setCancelLabel('Done');
              }
            } catch (err) {
              addAlert('We are unable to get the status of upgrade', 'danger');
              clearInterval(pollRef.current);
            }
          }, 10000);
        })
        .catch((err) =>
          errorInfo(err, (errInfo) => {
            addAlert(`Apply Upgrade: ${errInfo.text}`, 'danger');
          }),
        );
    }
  };

  return (
    <Modal show={!!selected.length} onHide={onClose} bsSize='large'>
      <Modal.Header>
        <Modal.Title>Software Upgrade</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AlertsList alerts={alerts} />
        <Form>
          <FormField
            component={Select}
            label='Version'
            onSelect={(value) => setRelease(value)}
            options={versions}
            required
            value={release}
          />
        </Form>
        <DataTable
          data={controllers}
          primaryKey={false}
          columns={[
            {
              header: 'Name',
              property: 'name',
            },
            {
              header: 'Rack ID',
              property: 'rack_id',
            },
            {
              header: 'Running Version',
              property: 'version',
            },
            {
              header: 'Upgrade Status',
              property: 'upgrade_status',
              render: (rowData) => {
                const {
                  portal_comm_okay: okay,
                  update_request_status: status,
                  update_request_version: requestVersion,
                  version,
                } = rowData;

                if (okay === false) {
                  return 'Device is not communicating with portal.';
                }

                if (release === '') {
                  return 'No release selected';
                }

                if (release === version) {
                  return 'Device is up-to-date.';
                }

                if (requestVersion !== release) {
                  return `Update to ${release} not started.`;
                }

                if (
                  status.version !== release &&
                  status.version_status === 'pending'
                ) {
                  return `Update to ${release} in progress.`;
                }

                if (status.version_status === 'success') {
                  return `Update to ${release} succeeded.`;
                }

                if (status.version_status === 'failure') {
                  return `Update to ${release} failed: ${status.message}`;
                }

                return 'Unknown';
              },
            },
          ]}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button label='Apply' onClick={handleApplyClick} primary />
        <Button label={cancelLabel} onClick={handleCancelClick} />
      </Modal.Footer>
    </Modal>
  );
};

UpdateModal.propTypes = {
  onClose: PropTypes.func,
  selected: PropTypes.array,
};

export default UpdateModal;
