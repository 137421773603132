// (C) Copyright 2016-2024 Hewlett Packard Enterprise Development LP

import ItemViewContainer from '../../containers/generic/ItemViewContainer';
import CreateViewContainer from '../../containers/generic/CreateViewContainer';
import EditViewContainer from '../../containers/generic/EditViewContainer';
import { metaCreate } from './helpers';
import * as c from '../../routes/consts';
import { LABEL_PODS } from '../../components/HybridNav/consts.js';

const EXTERNAL_NAME = c.URL_PODS;

export const settings = {
  // used to filter create/trash icons from the view;
  // using the defined roledef permissions mappings to this auth URL entry
  authUrl: EXTERNAL_NAME,
  // homepage for this list view (e.g. /s/hosters/:pid/) is where
  // this view is located; for return from create / item views
  homeUrl: c.makeSecUrl(EXTERNAL_NAME),
  baseUrl: EXTERNAL_NAME,
  // base url to be used for creating all associated URLS for this reasource,
  // e.g. pageItem, pageCreate, restUrl, restItemUrl
  stateKey: EXTERNAL_NAME,
  homeLabel: LABEL_PODS,
};

export { default as PodListView } from './PodListView';

export const PodItemView = ItemViewContainer({
  ...settings,
  allowEdit: true,
  meta: (props) => metaCreate(props), // use edit meta
  title: 'Data center (Pod)',
});

export const PodCreateView = CreateViewContainer({
  ...settings,
  meta: (props) => metaCreate(props),
  title: 'Create data center (Pod)',
});

export const PodEditView = EditViewContainer({
  ...settings,
  meta: (props) => metaCreate(props),
  title: 'Edit data center (Pod)',
  allowSaveAsNew: true,
  saveAsNewPrep: (item) => ({
    ...item,
    domain_id_pool_id: '',
    vlan_pool_id: '',
    networks: item.networks.map((network) => ({
      ...network,
      id: '',
    })),
  }),
});
