// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

export const rackControlOptions = [
  { val: 'single', text: 'Single Rack Controller' },
  { val: 'embedded', text: 'Switch Embedded Controllers' },
];

export const SINGLE = 'single';
export const DUAL = 'dual';
export const EMBEDDED = 'embedded';
export const RACK_CONTROL_DEFAULT = SINGLE;
export const EDGE_RACK = 'Edge Rack';
export const DATA_CENTER_RACK = 'Data Center Rack';
export const EDGE_CONTROLLER = 'Edge Controller';
export const POD_CONTROLLER = 'Pod Controller';
