// (C) Copyright 2020-2024 Hewlett Packard Enterprise Development LP

import { useState } from 'react';

export const useStatus = (initialState) => {
  const [status, setStatus] = useState(initialState);

  const Status = (props) => props[status] || null;

  return { Status, setStatus };
};
