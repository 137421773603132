// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Tab, Tabs } from 'grommet';
import { useLocation, useHistory } from 'react-router-dom';
import * as nav from './consts.js';
import * as c from '../../routes/consts.js';
import auth from '../../lib/auth';
import { useMembershipContext } from '../../utils/context/membershipContext';

const tabs = {
  hoster: {
    [nav.NAV_NETWORKING]: [
      { label: nav.LABEL_PODS, path: c.makeSecUrl(c.URL_PODS) },
      {
        label: nav.LABEL_NETWORKS,
        path: c.makeSecUrl(c.URL_NETWORKS),
      },
      { label: nav.LABEL_IPPOOLS, path: c.makeSecUrl(c.URL_IPPOOLS) },
      { label: nav.LABEL_SWITCHES, path: c.makeSecUrl(c.URL_SWITCHES) },
      { label: nav.LABEL_SWITCHPORTS, path: c.makeSecUrl(c.URL_SWITCHPORTS) },
      { label: nav.LABEL_SWITCHTYPES, path: c.makeSecUrl(c.URL_SWITCHTYPES) },
    ],
    [nav.NAV_RACKS]: [
      { label: nav.LABEL_RACKS, path: c.makeSecUrl(c.URL_RACKS) },
      {
        label: nav.LABEL_RACKCONTROLLERS,
        path: c.makeSecUrl(c.URL_RACKCONTROLLERS),
      },
      {
        label: nav.LABEL_RACKTEMPLATES,
        path: c.makeSecUrl(c.URL_RACKTEMPLATES),
      },
      { label: nav.LABEL_RELEASES, path: c.makeSecUrl(c.URL_RELEASES) },
    ],
    [nav.NAV_COMPUTE]: [
      { label: nav.LABEL_MACHINES, path: c.makeSecUrl(c.URL_MACHINES) },
      { label: nav.LABEL_MACHINESIZES, path: c.makeSecUrl(c.URL_MACHINESIZES) },
      { label: nav.LABEL_MACHINETYPES, path: c.makeSecUrl(c.URL_MACHINETYPES) },
      { label: nav.LABEL_FMBASELINES, path: c.makeSecUrl(c.URL_FWBASELINES) },
      {
        label: nav.LABEL_HOSTERSERVICES,
        path: c.makeSecUrl(c.URL_HOSTER_SERVICES),
      },
      { label: nav.LABEL_TAGS, path: c.makeSecUrl(c.URL_TAGS) },
    ],
    [nav.NAV_HOSTS]: [
      { label: nav.LABEL_HOSTS, path: c.makeSecUrl(c.URL_HOSTS) },
      { label: nav.LABEL_SSH_KEYS, path: c.makeSecUrl(c.URL_SSHKEYS) },
    ],
    [nav.NAV_STORAGE]: [
      { label: nav.LABEL_VOLUMES, path: c.makeSecUrl(c.URL_VOLUMES) },
      {
        label: nav.LABEL_ATTACHMENTS,
        path: c.makeSecUrl(c.URL_VOLUME_ATTACHMENTS),
      },
      { label: nav.LABEL_FLAVORS, path: c.makeSecUrl(c.URL_VOLUME_FLAVOR) },
      {
        label: nav.LABEL_STORAGE_GROUPS,
        path: c.makeSecUrl(c.URL_STORAGE_GROUPS),
      },
      {
        label: nav.LABEL_STORAGE_POOLS,
        path: c.makeSecUrl(c.URL_STORAGE_POOLS),
      },
      {
        label: nav.LABEL_STORAGE_SYSTEMS,
        path: c.makeSecUrl(c.URL_STORAGE_SYSTEMS),
      },
    ],
    [nav.NAV_TEAMS]: [
      { label: nav.LABEL_MY_TEAM, path: c.makeSecUrl(c.URL_HOSTERS) },
      { label: nav.LABEL_PROJECTS, path: c.makeSecUrl(c.URL_PROJECTS) },
      {
        label: nav.LABEL_ORGANIZATIONS,
        path: c.makeSecUrl(c.URL_ORGANIZATIONS),
      },
    ],
    [nav.NAV_TRACKING]: [
      { label: nav.LABEL_ALERTS, path: c.makeSecUrl(c.URL_ALERTS) },
      { label: nav.LABEL_OPERATIONS, path: c.makeSecUrl(c.URL_OPS) },
      { label: nav.LABEL_FILES, path: c.makeSecUrl(c.URL_FILES) },
    ],
  },
  portal: {
    [nav.NAV_FOR_HOSTERS]: [
      { label: nav.LABEL_SWITCHTYPES, path: c.makeSecUrl(c.URL_SWITCHTYPES) },
      { label: nav.LABEL_RELEASES, path: c.makeSecUrl(c.URL_RELEASES) },
      { label: nav.LABEL_SERVICES, path: c.makeSecUrl(c.URL_SERVICES) },
    ],
    [nav.NAV_TEAMS_PORTAL]: [
      { label: nav.LABEL_PORTALS, path: c.makeSecUrl(c.URL_PORTALS) },
      { label: nav.LABEL_HOSTERS, path: c.makeSecUrl(c.URL_HOSTERS) },
      { label: nav.LABEL_USERS, path: c.makeSecUrl(c.URL_USERS) },
    ],
    [nav.NAV_TRACKING]: [
      { label: nav.LABEL_ALERTS, path: c.makeSecUrl(c.URL_ALERTS) },
      { label: nav.LABEL_FILES, path: c.makeSecUrl(c.URL_FILES) },
    ],
  },
  project: {
    [nav.NAV_COMPUTE_PROJECT]: [
      { label: nav.LABEL_HOSTS, path: c.makeSecUrl(c.URL_HOSTS) },
      { label: nav.LABEL_SSH_KEYS, path: c.makeSecUrl(c.URL_SSHKEYS) },
    ],
    [nav.NAV_STORAGE]: [
      { label: nav.LABEL_VOLUMES, path: c.makeSecUrl(c.URL_VOLUMES) },
      {
        label: nav.LABEL_ATTACHMENTS,
        path: c.makeSecUrl(c.URL_VOLUME_ATTACHMENTS),
      },
    ],
  },
  bmaas_project: {
    [nav.NAV_SETTINGS]: [
      {
        label: nav.LABEL_VOLUME_ATTACHMENTS,
        path: c.makeSecUrl(c.URL_VOLUME_ATTACHMENTS),
      },
      { label: nav.LABEL_SSH_KEYS, path: c.makeSecUrl(c.URL_SSHKEYS) },
      { label: nav.LABEL_DOWLOADS, path: c.makeSecUrl(c.URL_DOWNLOADS) },
    ],
  },
};

const NavTabs = ({ navSection }) => {
  const { activeMembership, role } = useMembershipContext();
  let roleTabs = null;
  if (role?.id && activeMembership?.id) {
    roleTabs = auth.isIAM()
      ? tabs.bmaas_project
      : tabs[activeMembership.scope] || [];
  }

  if (roleTabs?.[navSection]) {
    const history = useHistory();
    const { pathname } = useLocation();
    const tabIndex = useMemo(
      () => roleTabs[navSection].findIndex(({ path }) => path === pathname),
      [navSection, pathname, roleTabs[navSection]],
    );
    const [index, setIndex] = useState(tabIndex);
    const onActive = useCallback(
      (nextIndex) => {
        const { path } = roleTabs[navSection][nextIndex];
        setIndex(nextIndex);
        history.push(path);
      },
      [navSection, history, roleTabs[navSection]],
    );

    return (
      <Tabs activeIndex={index} onActive={onActive} justify='start'>
        {roleTabs[navSection]?.map((tab) => (
          <Tab key={tab.label} title={tab.label} />
        ))}
      </Tabs>
    );
  }
  return null;
};

export default NavTabs;

NavTabs.propTypes = {
  navSection: PropTypes.string,
};
