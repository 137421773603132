// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import * as c from '../../routes/consts.js';
import * as mb from '../../containers/generic/MetaBuilder';
import MetaBuilder from '../../containers/generic/MetaBuilder';
import ItemViewContainer from '../../containers/generic/ItemViewContainer';
import debugLogger from '../../lib/debug';
import * as log from '../../lib/debug';
import * as REGEX from '../../data/regex.js';
import { LABEL_RELEASES } from '../../components/HybridNav/consts.js';

const debug = debugLogger('Release', log.LOG_LEVEL_WARN);

const EXTERNAL_NAME = c.URL_RELEASES;

const DS_RELEASE = 'release';

/*
const SERVICE_SHA_256 = 'sha256sum'
const SERVICE_SHA_512 = 'sha512sum'
const SERVICE_HASHES = [SERVICE_SHA_256, SERVICE_SHA_512]
*/

export const settings = {
  authUrl: EXTERNAL_NAME, // used to filter create/trash icons from the view; using the defined roledef permissions mappings to this auth URL entry
  homeUrl: c.makeSecUrl(EXTERNAL_NAME), // homepage for this list view (e.g. /s/hosters/:pid/) is where this view is located; for return from create / item views
  baseUrl: EXTERNAL_NAME, // base url to be used for creating all associated URLS for this reasource, e.g. pageItem, pageCreate, restUrl, restItemUrl
  stateKey: EXTERNAL_NAME,
  homeLabel: LABEL_RELEASES,
};

function metaEdit(props) {
  const m = new MetaBuilder(props);

  const id = m.view.prop('itemId', false);

  const ds = m
    .newDataSource(c.URL_RELEASES, DS_RELEASE)
    .Item(id)
    .OnLoad((json) => {
      debug.debug('got release:', json);
      m.view.initForm(json.data);
    });

  m.addColumn('name', 'Name').Input().MaxWidth(mb.SMALL);

  if (props.readOnly) {
    m.addColumn('id', 'ID').Input().MaxWidth(mb.SMALL);
  }

  m.addColumn('description', 'Description').Input().MaxWidth(mb.SMALL);

  m.addColumn('notes', 'Notes').Input().TextArea().MaxWidth(mb.SMALL);

  m.addColumn('version', 'Version')
    .Input()
    .RegEx(REGEX.VERSION, REGEX.VERSION_MSG)
    .MaxWidth(mb.SMALL);

  m.addSection().Expanded().MaxWidth(mb.LARGE);

  const table = m
    .addInputTable('base_platforms', 'Platforms')
    .Required()
    .DataXform(ds, (json) => json.base_platforms || []);

  table.addField('name', 'Platform').CellXform((rowData) => rowData.name);

  table
    .addField('version', 'Platform Version')
    .CellXform((rowData) => rowData.version);

  return m;
}

export { default as ReleaseListView } from './ReleaseListView';

export const ReleaseItemView = ItemViewContainer({
  ...settings,
  allowEdit: false,
  meta: (props) => metaEdit(props), // use edit meta
  title: 'Release',
});
