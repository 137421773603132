// (C) Copyright 2022-2024 Hewlett Packard Enterprise Development LP

import { useQuery } from 'react-query';

import { get } from '../lib/rest';
import { toDateTime } from '../lib/formatters';
import auth from '../lib/auth';
import { makeRestUrl, SCOPE_PORTAL, URL_FILES } from '../routes/consts';
import { renderLink } from './helper';
import TruncateText from '../components/Griddle/TruncateText';

const columns = [
  {
    header: 'Name',
    isDefault: true,
    property: 'name',
    render: renderLink({
      base: URL_FILES,
    }),
  },
  {
    header: 'ID',
    property: 'id',
    render: ({ id }) => <TruncateText content={id} />,
  },
  {
    header: 'Created',
    property: 'created',
    render: ({ created }) => toDateTime(created),
  },
  {
    header: 'Modified',
    isDefault: true,
    property: 'modified',
    render: ({ modified }) => toDateTime(modified),
  },
  {
    header: 'Type',
    isDefault: true,
    property: 'type',
  },
  {
    header: 'Encoding',
    isDefault: true,
    property: 'encoding',
  },
  {
    header: 'Filename',
    isDefault: true,
    property: 'file_name',
  },
  ...(auth.inScope(SCOPE_PORTAL)
    ? [
        {
          header: 'Published',
          isDefault: true,
          property: 'hoster_published',
          render: ({ hoster_id: hosterId }) =>
            hosterId === '*' ? 'yes' : 'no',
        },
      ]
    : []),
  {
    header: 'Content',
    property: 'content',
  },
];

export const getFiles = async (apiParams = {}) => {
  const response = await get(makeRestUrl(URL_FILES), apiParams);
  return response.json();
};

export const useGetFiles = ({ apiParams }) => {
  const queryResult = useQuery([URL_FILES, apiParams], () =>
    getFiles(apiParams),
  );

  return {
    ...queryResult,
    columns: columns.map((column) => ({
      ...column,
      columnName: column.property,
      displayName: column.header,
    })),
  };
};
