// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

import React from 'react';
import PropTypes from 'prop-types';
import { Box, Text } from 'grommet';
import { Hpe } from 'grommet-icons';

export const Title = ({ projectName, teamName }) => {
  const subtitle = projectName || teamName;

  return (
    <Box direction='row' gap='small' align='center'>
      <Hpe color='brand' size='30px' />
      <Box>
        <Text size='large' weight='bold' color='text-strong'>
          HPE{' '}
          <Text size='large' weight='normal' color='text-strong'>
            GreenLake Metal
          </Text>
          {subtitle ? (
            <Text size='large' weight='normal'>
              {` \u2014 ${subtitle}`}
            </Text>
          ) : null}
        </Text>
      </Box>
    </Box>
  );
};

Title.propTypes = {
  projectName: PropTypes.string,
  teamName: PropTypes.string,
};
