// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import React from 'react';

import { useGetVolumeAttachments } from '../../services/volumeAttachments';
import ListPageWithNav from '../../containers/generic/ListPageWithNav';
import { URL_VOLUME_ATTACHMENTS } from '../../routes/consts';
import {
  NAV_STORAGE,
  NAV_SETTINGS,
} from '../../components/HybridNav/consts.js';
import auth from '../../lib/auth';

const VolumeAttachmentListView = () => {
  const navSection = auth.isIAM() ? NAV_SETTINGS : NAV_STORAGE;

  return (
    <ListPageWithNav
      navSection={navSection}
      actions={{
        allowCreate: 'Create volume attachment',
      }}
      path={URL_VOLUME_ATTACHMENTS}
      sort={{
        property: 'name',
        direction: 'asc',
      }}
      title='Volume attachments'
      useGetData={useGetVolumeAttachments}
    />
  );
};

export default VolumeAttachmentListView;
