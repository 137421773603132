// (C) Copyright 2023-2024 Hewlett Packard Enterprise Development LP

export const GIB_TO_GB = 1.073741824;

export const byteToGib = (byte) => {
  if (!byte || byte < 0) {
    return 0;
  }

  return byte / 1024 / 1024;
};

export const gibToGb = (gib) => {
  if (!gib || gib < 0) {
    return 0;
  }

  return gib * GIB_TO_GB;
};

export const byteToGb = (byte, unit, hide) => {
  if (!byte || byte < 0) {
    return 0;
  }

  const number = gibToGb(byteToGib(byte));
  const nf = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  return unit
    ? `${nf.format(number)}${!hide ? ' ' : ''}${!hide ? unit : ''}`
    : number;
};
