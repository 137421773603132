// (C) Copyright 2020-2024 Hewlett Packard Enterprise Development LP

export const createStorage = (provider) => ({
  get(key, defaultValue) {
    const json = provider.getItem(key);

    try {
      return json === null
        ? typeof defaultValue === 'function'
          ? defaultValue()
          : defaultValue
        : JSON.parse(json);
    } catch {
      return defaultValue;
    }
  },
  set(key, value) {
    provider.setItem(key, JSON.stringify(value));
  },
});
