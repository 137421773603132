// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

import React from 'react';
import { Button } from 'grommet';
import styled from 'styled-components';

const StyledButton = styled(Button)`
  min-width: 36px;
  padding: 6px;
  white-space: nowrap;

  &[disabled] {
    background-color: transparent;
    opacity: 0.3;
  }
`;

const IconButton = (props) => (
  <StyledButton {...props} primary={!!props.label} reverse />
);

export default IconButton;
