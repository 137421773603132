// (C) Copyright 2016-2024 Hewlett Packard Enterprise Development LP

import React, { useState, useEffect } from 'react';
import { Box, Text } from 'grommet';
import rest from '../../lib/rest';
import debugLogger from '../../lib/debug';
import { View, ViewHeader } from '../../components';
import MetaForm from '../../containers/generic/MetaForm';
import MetaBuilder from '../../containers/generic/MetaBuilder';
import * as log from '../../lib/debug';
import { param } from '../../lib/querystring';

const debug = debugLogger('Invite', log.LOG_LEVEL_WARN);

const INVITE_URL = '/info/invite';

const InviteLayout = ({ children }) => (
  <View>
    <ViewHeader>Invite</ViewHeader>
    {children}
  </View>
);

// States for this page:
//    'pending'    Page is loading and it is making initial REST call
//    'initial'    Initial return.  Ignore password error because user hasn't entered it yet
//    'error'      Shows any error
//    'done'       Shows accepted or rejected

const Invite = () => {
  const [mode, setMode] = useState('pending');
  const [error, setError] = useState('');
  const response = param('response');
  const invite = param('invite');
  const reference = param('reference');

  useEffect(() => {
    // accept invite

    debug.debug('state.invite', INVITE_URL);

    rest
      .post(INVITE_URL, { response, invite_id: invite, reference })
      .then(() => {
        setMode('done');
        setError('');
      })
      .catch((err) => {
        // if password required or otherwise invalid a non 200 status will return
        rest.errorInfo(err, ({ status, text }) => {
          debug.debug('Initial REST call returned: ', status, text);
          if (status === 406) {
            setMode('initial');
          } else {
            setMode('error');
            setError(text);
          }
        });
      });
  }, [response, invite, reference]);

  const onSubmit = ({ password }) => {
    // accept invite with password

    debug.debug('state.invite.withpassword', INVITE_URL);

    return rest
      .post(INVITE_URL, { response, invite_id: invite, reference, password })
      .then(() => {
        setMode('done');
        setError('');
      })
      .catch((err) => {
        rest.errorInfo(err, ({ status, text }) => {
          debug.error('Error:', status, text);
          setMode('error');
          setError(text);
        });
      });

    // e.preventDefault()
  };

  switch (mode) {
    case 'pending':
      return (
        <InviteLayout>
          <Text size='large'>Checking invite...</Text>
        </InviteLayout>
      );
    case 'done':
      return (
        <InviteLayout>
          <Text size='large'>
            {`Invite ${response === 'accept' ? 'accepted' : 'rejected'}`}
          </Text>
        </InviteLayout>
      );
    case 'error':
      return (
        <InviteLayout>
          <Text color='status-critical'>Error: {error}</Text>
        </InviteLayout>
      );
    case 'initial':
      // biome-ignore lint: <explanation>
      let errText = error;

      if (errText.length > 0) {
        const msgIdx = errText.lastIndexOf('= ');

        if (msgIdx !== -1) {
          errText = errText.substring(msgIdx + 1, errText.length);
        }
      }

      return (
        <InviteLayout>
          <Box>
            <p> Enter a password for your new user account:</p>
            <br />

            <MetaForm
              meta={(props) => {
                const m = new MetaBuilder(props);
                const pass = m
                  .addField('password', 'Password')
                  .Input()
                  .Required()
                  .Password()
                  .MinLength(8);

                m.addField('confirmPassword', 'Confirm Password')
                  .Input()
                  .Required()
                  .Password()
                  .MustMatch(pass)
                  .MinLength(8);

                return m;
              }}
              className='Invite'
              submitLabel='Create'
              onSubmit={onSubmit}
            />
          </Box>
        </InviteLayout>
      );
    default:
      return (
        <InviteLayout>
          <Text size='large'>Unknown state</Text>
        </InviteLayout>
      );
  }
};

export default Invite;
