// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import React from 'react';
import { Text, Tip } from 'grommet';

const Content = ({ content, maxWidth }) => (
  <Text truncate={true} style={{ maxWidth: `${maxWidth}px` }}>
    {content}
  </Text>
);

export default function TruncateText({ content, maxWidth = 400 }) {
  const showTip = maxWidth / 9 < content?.length;

  if (showTip) {
    return (
      <Tip content={<Text wordBreak='break-all'>{content}</Text>}>
        <Content content={content} maxWidth={maxWidth} />
      </Tip>
    );
  }

  return <Content content={content} maxWidth={maxWidth} />;
}
