// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, FormField, Grid, SelectMultiple, Text } from 'grommet';
import { FormAdd, Trash } from 'grommet-icons';
import styled from 'styled-components';
import { isEmpty } from '../../../utils';
import { Link, Modal } from '../../../components';
import { getProjectResources } from '../../../services/projects';
import { getServices } from '../../../services/services';
import { toDateTime } from '../../../lib/formatters';
import { useMembershipContext } from '../../../utils/context/membershipContext';
import perm from '../../../routes/permissions';
import { GET, URL_SERVICES } from '../../../routes/consts';

const Table = styled.div`
  padding: 24px 0;
  table {
    border-spacing: 0;
    th,
    td {
      padding: 8px 24px;
      &.description {
        width: 180px;
      }
      &.redundancy-mode {
        width: 190px;
      }
      &.buttons {
        text-align: center;
      }
    }
    th {
      border-bottom: 1px solid #999999;
      text-align: left;
    }
    td {
      vertical-align: middle;
      > * {
        min-height: 40px;
      }
    }
  }
`;

const DetailModal = ({ data, setModal }) => {
  if (!data) {
    return null;
  }

  const { created, modified, description, id, name, origin, type } = data;

  const handleClose = () => setModal(null);

  return (
    <Modal show onHide={handleClose} size='large'>
      <Modal.Header onDismiss={handleClose}>
        <Modal.Title>Custom Network Connection</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Grid columns={['small', 'auto']} gap='medium'>
          <Box>
            <Text weight={600}>Name</Text>
          </Box>
          <Box>
            <Text>{name}</Text>
          </Box>
          <Box>
            <Text weight={600}>Description</Text>
          </Box>
          <Box>
            <Text>{description}</Text>
          </Box>
          <Box>
            <Text weight={600}>ID</Text>
          </Box>
          <Box>
            <Link to={`/s/services/${id}`} title={id} />
          </Box>
          <Box>
            <Text weight={600}>Origin</Text>
          </Box>
          <Box>
            <Text>{origin}</Text>
          </Box>
          <Box>
            <Text weight={600}>Created</Text>
          </Box>
          <Box>
            <Text>{toDateTime(created)}</Text>
          </Box>
          <Box>
            <Text weight={600}>Modified</Text>
          </Box>
          <Box>
            <Text>{toDateTime(modified)}</Text>
          </Box>
          <Box>
            <Text weight={600}>Type</Text>
          </Box>
          <Box>
            <Text>{type}</Text>
          </Box>
        </Grid>
      </Modal.Body>
      <Modal.Footer>
        <Button label='Close' onClick={handleClose} secondary />
      </Modal.Footer>
    </Modal>
  );
};

DetailModal.propTypes = {
  data: PropTypes.object,
  setModal: PropTypes.func,
};

const AddModal = (props) => {
  const { allImages, data, images, onSubmit, projectId, setModal } = props;
  const [values, setValues] = useState([]);

  if (!data) {
    return null;
  }

  const imageOptions = images
    .filter((image) => {
      if (data.some((service) => service.id === image.id)) {
        return false;
      }

      if (image.restricted) {
        const originalImage = allImages.find(({ id }) => id === image.id);

        return originalImage.permitted_projects.includes(projectId);
      }

      return true;
    })
    .map((image) => ({
      ...image,
      label: image.name,
    }));

  const handleClose = () => setModal(null);

  const handleAdd = () => {
    onSubmit(values);
    setModal(null);
  };

  const handleChange = ({ value }) => {
    setValues(value);
  };

  return (
    <Modal show onHide={handleClose} size='large'>
      <Modal.Header onDismiss={handleClose}>
        <Modal.Title>Add permitted images</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormField style={{ width: '400px' }}>
          <SelectMultiple
            labelKey='label'
            multiple
            onChange={handleChange}
            options={imageOptions}
            placeholder='Select images'
            value={values}
            valueKey='id'
          />
        </FormField>
      </Modal.Body>
      <Modal.Footer>
        <Button label='Add' onClick={handleAdd} primary />
        <Button label='Close' onClick={handleClose} secondary />
      </Modal.Footer>
    </Modal>
  );
};

AddModal.propTypes = {
  allImages: PropTypes.array,
  data: PropTypes.array,
  images: PropTypes.array,
  onSubmit: PropTypes.func,
  projectId: PropTypes.string,
  setModal: PropTypes.func,
};

const FormPermittedServices = (props) => {
  const [isDirty, setIsDirty] = useState(false);
  const [services, setServices] = useState([]);
  const [modal, setModal] = useState(null);
  const [addModal, setAddModal] = useState(null);
  const [images, setImages] = useState([]);
  const [allImages, setAllImages] = useState([]);
  const { role } = useMembershipContext();

  const isView = useMemo(() => props.col.mb.props.mode === 'view', [props]);
  const isCreate = useMemo(() => props.col.mb.props.mode === 'create', [props]);

  useEffect(() => {
    if (perm.authorized(URL_SERVICES, GET, role)) {
      getServices()
        .then((data) => {
          setAllImages(data);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [role]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    getProjectResources()
      .then(({ services: resourceImages }) => {
        const data = resourceImages.filter(
          ({ restricted }) => !isCreate || !restricted,
        );

        setImages(data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [isCreate, props.data?.id]);

  useEffect(() => {
    if (isEmpty(props.data?.permitted_services)) {
      return;
    }

    const permittedImageIds = props.data.permitted_services;

    if (!perm.authorized(URL_SERVICES, GET, role)) {
      setServices(permittedImageIds);
    } else {
      const permittedImages = permittedImageIds
        .map((serviceId) => {
          const data = allImages.length ? allImages : images;

          return data.find((image) => image.id === serviceId);
        })
        .filter((service) => !!service);

      setServices(permittedImages);
    }
  }, [allImages, images, props.data, role]);

  useEffect(() => {
    if (isDirty) {
      const set = props.col.mb.view.setFormValue;
      const values = services.map(({ id }) => id);
      set('permitted_services', values);
    }
  }, [isDirty, services, props.col.mb.view]);

  const setDirty = () => {
    if (!isDirty) {
      setIsDirty(true);
    }
  };

  const handleDelete = (index) => () => {
    setDirty();
    setServices((prev) => [...prev.slice(0, index), ...prev.slice(index + 1)]);
  };

  const handleSubmitAdd = (selectedImages) => {
    setDirty();
    setServices((prev) => [...prev, ...selectedImages]);
  };

  return (
    <Table style={{ gridColumn: '1 / span 2' }}>
      <table>
        <thead>
          <tr>
            {(perm.authorized(URL_SERVICES, GET, role)
              ? ['Name', 'Origin', 'Type']
              : ['ID']
            ).map((heading) => (
              <th
                className={heading.toLocaleLowerCase().replace(' ', '-')}
                key={heading}
              >
                <Text weight='bold'>{heading}</Text>
              </th>
            ))}
            <th className='buttons' />
          </tr>
        </thead>
        <tbody>
          {services.map((image, i) => (
            <tr
              key={image.id || image}
              onClick={isView ? () => setModal(image) : undefined}
            >
              <td className='name'>
                <Text>{image.name}</Text>
              </td>
              {perm.authorized(URL_SERVICES, GET, role) ? (
                <>
                  <td className='origin'>
                    <Text>{image.origin}</Text>
                  </td>
                  <td className='type'>
                    <Text>{image.type}</Text>
                  </td>
                </>
              ) : null}
              <td className='buttons'>
                {!isView ? (
                  <Box direction='row' gap='xsmall'>
                    <Button
                      icon={<Trash color='brand' />}
                      onClick={handleDelete(i)}
                    />
                  </Box>
                ) : null}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {!isView ? (
        <Box direction='row' pad={{ vertical: 'small' }}>
          <Button
            icon={<FormAdd />}
            label='Add image'
            onClick={() => setAddModal(services)}
            reverse
            secondary
          />
        </Box>
      ) : null}
      <DetailModal data={modal} setModal={setModal} />
      <AddModal
        allImages={allImages}
        data={addModal}
        images={images}
        onSubmit={handleSubmitAdd}
        projectId={props.data?.id}
        setModal={setAddModal}
      />
    </Table>
  );
};

export default FormPermittedServices;

FormPermittedServices.propTypes = {
  data: PropTypes.object,
  col: PropTypes.object,
};
