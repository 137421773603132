// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'grommet';
import { Terminal } from 'grommet-icons';

import auth from '../../lib/auth';
import { settings } from '../../lib/settings.js';
import qs from '../../lib/querystring.js';
import IconButton from '../generic/IconButton.js';

export const ConsoleLaunchButton = ({ info, disabled }) => {
  /* ==============================================
   * Console Actions
   * ============================================ */
  const membership = auth.getActiveMemberID();

  const openConsole = (e) => {
    const query = [
      qs.makeUrlValue('?', 'deviceID', info.deviceID),
      qs.makeUrlValue('&', 'deviceType', 'controller'),
      qs.makeUrlValue('&', 'name', info.consoleName),
      qs.makeUrlValue('&', 'membershipId', membership),
      settings.exportQueryString(),
    ].join('');
    const url = `${window.location.protocol}//${window.location.host}/console/console.html${query}`;
    window.open(url, info.machineID);
    e.preventDefault();
  };

  return (
    <IconButton
      color='brand'
      icon={<Terminal />}
      tip='Open serial console window to this rack controller.'
      onClick={openConsole}
    />
  );
};

const ControllerActions = ({ metadata, rowData }) => {
  if (!(rowData && metadata)) {
    return null;
  }

  const infoFunc = metadata.customComponentMetadata.info;
  const info = infoFunc(rowData);

  return (
    <Box
      direction='row'
      onClick={(e) => e.stopPropagation()}
      focusIndicator={false}
    >
      <ConsoleLaunchButton
        a11yTitle='Open serial console window to this rack controller.'
        info={info}
      />
    </Box>
  );
};

ControllerActions.propTypes = {
  rowData: PropTypes.object.isRequired,
  metadata: PropTypes.object.isRequired,
};

export default ControllerActions;
