// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import React from 'react';
import PropTypes from 'prop-types';
import isBoolean from 'lodash/isBoolean';
import cloneDeep from 'lodash/cloneDeep';
import defaultTo from 'lodash/defaultTo';
import get from 'lodash/get';
import indexOf from 'lodash/indexOf';
import isArray from 'lodash/isArray';
import isArrayLike from 'lodash/isArrayLike';
import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';
// biome-ignore lint/suspicious/noShadowRestrictedNames: <explanation>
import isNaN from 'lodash/isNaN';
import isNil from 'lodash/isNil';
import isObject from 'lodash/isObject';
import keys from 'lodash/keys';
import merge from 'lodash/merge';
import remove from 'lodash/remove';
import set from 'lodash/set';
import unset from 'lodash/unset';

import {
  Box,
  Button,
  Grid,
  Paragraph,
  ResponsiveContext,
  Tab,
  Tabs,
} from 'grommet';
import { Edit } from 'grommet-icons';
import styled from 'styled-components';
import { Card, CardBody, CardHeading, Modal } from '../../../components';
import rest from '../../../lib/rest';
import debugLogger, * as log from '../../../lib/debug';
import { getPath, setPath } from '../../../lib/deep';
import { getUniqueIdPredicate } from '../../../lib/uniqueids';
import { MetaBuilder } from '../MetaBuilder';
import * as mb from '../MetaBuilder';
import { DataSourceResult } from '../../../lib/datasource.js';
import FormCheckBox from './FormCheckBox';
import FormHidden from './FormHidden';
import FormStaticText from './FormStaticText';
import FormText from './FormText';
import FormPassword from './FormPassword';
import FormDropDown from './FormDropDown';
import FormCountryDropDown from './FormCountryDropDown';
import FormMultiDropDown from './FormMultiDropDown';
import FormTextArea from './FormTextArea';
import ModalControl from '../../../utils/ModalControl';
import FormInputTable from './FormInputTable';
import IconButton from '../../../components/generic/IconButton';
import FormRadioGroupWithTips from './FormRadioGroupWithTips';

const debugLevel = log.LOG_LEVEL_DEBUG;

const Form = (props) => <form {...props} />;

const StyledTabs = styled(Tabs)`
  width: 100%;

  button {
    > div {
      background: none;
      border: none;
      border-radius: 4px 4px 0 0;
    }

    &[aria-selected='true'] {
      > div {
        border: 1px solid #bbbbbb;
      }
    }
  }
`;

export default class MetaForm extends React.Component {
  static propTypes = {
    meta: PropTypes.func.isRequired,
    className: PropTypes.string,
    itemData: PropTypes.object,
    itemId: PropTypes.string,
    itemUrl: PropTypes.string,
    submitLabel: PropTypes.string,
    onSubmit: PropTypes.func,
    submitLabel2: PropTypes.string,
    onSubmit2: PropTypes.func,
    disableSubmit2: PropTypes.func,
    editLabel: PropTypes.string,
    onEdit: PropTypes.func,
    cancelLabel: PropTypes.string,
    onCancel: PropTypes.func,
    readOnly: PropTypes.bool,
    showAllFields: PropTypes.bool,
    pad: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    parentView: PropTypes.object,
    parentMeta: PropTypes.object,
    dataSources: PropTypes.array,
    formData: PropTypes.object,
    mode: PropTypes.string,
    activeTab: PropTypes.number,
  };

  constructor(props) {
    super(props);

    this.debug = debugLogger(`MetaForm:${props.className}`, debugLevel);

    let form = {};
    const colMeta = {};
    const exp = [];

    if (!isEmpty(this.props.formData)) {
      form = cloneDeep(this.props.formData);
    }

    this.state = {
      form,
      validate: {},
      colMeta,
      data: {},
      expanded: exp,
      showModal: [],
      activeTab: this.props.activeTab,
      editLabel: this.props.editLabel || 'Edit',
      submitLabel: this.props.submitLabel || 'Submit',
    };

    // load meta once, but after auth has a chance to init
    // and pass in any callback funcs and settings
    const metaProps = {
      view: this, // this MetaForm
      parentView: props.parentView, // parent MetaForm for modal context & validation
      parentMeta: props.parentMeta, // parent MetaBuilder for modal context & validation
      dataSources: props.dataSources,
      className: props.className,
      readOnly: props.readOnly,
      mode: props.mode,
      itemUrl: props.itemUrl,
      itemId: props.itemId,
      formData: props.formData,
    };

    this.meta = this.props.meta(metaProps);

    if ((!this.meta) instanceof MetaBuilder) {
      this.debug.throw(
        `ERROR: no metabuilder returned, got: ${typeof this.meta}`,
        this.props.meta,
      );
    }

    // for legacy ItemViews whose meta doesn't load their data
    // we provide this convenience backup data loading, if
    // no other meta.dataSources exist
    if (
      isEmpty(this.props.formData) &&
      this.meta.dataSources.length === 0 &&
      this.props.itemUrl &&
      this.props.itemId
    ) {
      this.meta
        .newDataSource(this.props.itemUrl)
        .Item(this.props.itemId)
        .OnLoad((json) => {
          this.meta.view.initForm(json.data);
        });
    }
    for (const col of this.meta.items) {
      setPath(colMeta, col.keyPath, col);

      if (
        col.isInput &&
        isEmpty(this.props.formData) &&
        col.inputDefaultValue !== undefined
      ) {
        setPath(form, col.keyPath, col.inputDefaultValue);
      }
    }

    // data source listener populates data via setstate
    // json is expected to be an array of values that can populate a dropdown or table
    this.meta.listenData((keyPath, json) => {
      // TODO: call onInit handlers for each cell in input table as new data received
      // so the POST form JSON is populated with values immediately after
      // initial render
      this.debug.debug(`listenData(${keyPath})`, json, this.state.form);
      if (json === undefined || (isArrayLike(json) && isEmpty(json))) {
        // debug.log('Empty json for: ', keyPath)
      }

      const col = getPath(colMeta, keyPath);
      if (col === undefined) {
        this.debug.throw(`Undefined col meta for: ${keyPath}`);
        return; // skip unknown data
      }

      switch (col.type) {
        case mb.FieldTypeDropDown:
        case mb.FieldTypeCountryDropDown:
        case mb.FieldTypeRadioGroup:
          // default to first dropdown item if no form value present
          // biome-ignore lint/correctness/noSwitchDeclarations: <explanation>
          const items = json.data;
          this.debug.log('  - select one:json', items);
          // biome-ignore lint/correctness/noSwitchDeclarations: <explanation>
          const formIdValue = getPath(this.state.form, keyPath);
          if (!this.props.readOnly) {
            if (isEmpty(formIdValue)) {
              if (!isArray(items)) {
                this.debug.throw(
                  `datasource data must be array for DropDown and RadioGroup fields (${keyPath}), got: ${typeof items}`,
                  items,
                );
              }
              if (isEmpty(items)) {
                this.setFormValue(keyPath, '');
              } else {
                if (!col.optional) {
                  this.setFormValue(
                    keyPath,
                    col.inputDefaultValue || items[0].id,
                  );
                }
              }
            }
          }
          col.FireOnInit(json);
          break;

        case mb.FieldTypeInputTable:
          // InputTable delegates to OnCellInit() handlers
          this.notifyInputTableInitHandlers(keyPath, json);
          break;

        default:
          col.FireOnInit(json);
      }
      const { data } = this.state;
      data[keyPath] = json;
      this.setState({ data });
    });

    if (!isEmpty(this.meta.sections)) {
      for (const s of this.meta.sections) {
        // exp.push(s.expanded)
        // default all sections to expanded for main view
        exp.push(true);
      }
    }

    this.pollMap = {};
    this.hidden = undefined;
    this.visibilityChange = undefined;
    this.handleVisibilityChange = this.handleVisibilityChange.bind(this);
  }

  UNSAFE_componentWillMount() {
    const res = this.meta.getFormDefaults(this.state.form);
    if (!isNil(res)) {
      this.initForm(res);
    }

    this.meta.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.submitLabel !== this.state.submitLabel) {
      this.setState((prev) => ({
        ...prev,
        submitLabel: prevProps.submitLabel,
      }));
    }
  }

  componentWillUnmount() {
    this.meta.stopPoll();
    document.removeEventListener(
      this.visibilityChange,
      this.handleVisibilityChange,
    );
  }

  componentDidMount() {
    // Set the name of the hidden property and the change event for visibility
    if (typeof document.hidden !== 'undefined') {
      this.hidden = 'hidden';
      this.visibilityChange = 'visibilitychange';
    } else if (typeof document.msHidden !== 'undefined') {
      this.hidden = 'msHidden';
      this.visibilityChange = 'msvisibilitychange';
    } else if (typeof document.webkitHidden !== 'undefined') {
      this.hidden = 'webkitHidden';
      this.visibilityChange = 'webkitvisibilitychange';
    }

    if (
      typeof document.addEventListener === 'undefined' ||
      this.hidden === undefined
    ) {
      this.debug.log("Browser doesn't support Page Visibility API.");
    } else {
      document.addEventListener(
        this.visibilityChange,
        this.handleVisibilityChange,
        false,
      );
    }

    this.pollMap = this.meta.dataSources.reduce(
      (acc, cur) => ({ ...acc, [cur.name]: cur.poll }),
      {},
    );

    if (this.props.itemUrl === 'ippools' && this.props.activeTab === 2) {
      this.setState({
        submitLabel: 'Save Allocation',
      });
    }
  }

  handleVisibilityChange() {
    // stop polling when the page is tab'd away
    if (document[this.hidden]) {
      this.meta.stopPoll();
    } else {
      this.meta.startPoll(this.pollMap);
    }
  }

  clearHiddenFields = (form) => {
    // clear fields that aren't visible
    for (const col of this.meta.items) {
      if (isFunction(col.fVisible)) {
        if (!col.fVisible()) {
          unset(form, col.keyPath);
        }
      }
    }
  };

  submit = (e) => {
    const valid = this.isValid();

    // protect form state from submit event handlers, which may modify form state to clear the id, or set etag to '*'
    const form = cloneDeep(this.state.form);
    this.debug.debug(
      'submit:',
      `valid: ${valid}`,
      e,
      form,
      this.state.activeTab,
    );

    this.clearHiddenFields(form);
    this.debug.debug(
      'submit:',
      `post-process: ${valid}`,
      e,
      form,
      this.state.activeTab,
    );

    if (valid || this.props.readOnly) {
      if (form.ip_pool_id === 'new_ip_pool') {
        const { ip_pool_id, payload, ...restData } = form;

        rest
          .post('/rest/ippools', payload)
          .then((res) => res.json())
          .then((ippool) => {
            this.props.onSubmit(
              {
                ...restData,
                ip_pool_id: ippool.id,
              },
              this.state.activeTab,
              this,
            );
          })
          .catch(() => {});
      } else {
        this.props.onSubmit(form, this.state.activeTab, this);
      }
    }
    e.preventDefault();
  };

  submit2 = (e) => {
    const valid = this.isValid();

    // protect form state from submit event handlers, which may modify form state to clear the id, or set etag to '*'
    const form = cloneDeep(this.state.form);
    this.debug.debug(
      'submit2:',
      `valid: ${valid}`,
      e,
      form,
      this.state.activeTab,
    );

    this.clearHiddenFields(form);
    this.debug.debug(
      'submit2:',
      `post-process: ${valid}`,
      e,
      form,
      this.state.activeTab,
    );

    if (valid || this.props.readOnly) {
      this.props.onSubmit2(form, this.state.activeTab);
    }
    e.preventDefault();
  };

  onEdit = (e) => {
    this.debug.log('editScreen', e);
    this.props.onEdit();
    e.preventDefault();
  };

  cancelScreen = (e) => {
    this.debug.log('cancelScreen', e);
    this.props.onCancel();
    if (isObject(e) && isFunction(e.preventDefault)) {
      e.preventDefault();
    }
  };

  addAlert = (msg, style) => {
    if (isFunction(this.props.addAlert)) {
      this.props.addAlert(msg, style);
    }
  };

  initForm = (v, formPrefix = '') => {
    if (v instanceof DataSourceResult) {
      this.debug.warn(
        'initForm called with DataSourceResult, did you mean to pass DataSourceResult.data?',
        v,
        formPrefix,
      );
    }
    if (isEmpty(formPrefix)) {
      // normal case
      this.setState({ form: v });
      this.props.onInitForm && this.props.onInitForm(v);
    } else {
      // support nested meta form re-use (e.g. reuse rack template forms in rack view)
      const form = defaultTo(this.state.form, {});
      set(form, formPrefix, v);
      this.setState({ form });
    }
  };

  setFormValue = (keyPath, value) => {
    this.debug.debug(
      `setFormValue(${keyPath}) - before`,
      value,
      cloneDeep(this.state.form),
    );

    let { form } = this.state;

    const colMeta = getPath(this.state.colMeta, keyPath);
    if (colMeta !== undefined) {
      if (colMeta.isNumber) {
        if (!isNil(value) && !isEmpty(value)) {
          const v = Number(value);
          if (!isNaN(v)) {
            value = v;
          }
        }
      }
    }
    const oldVal = getPath(form, keyPath);
    if (value === undefined) {
      unset(form, keyPath);
    } else {
      form = setPath(form, keyPath, value);
    }
    const validate = setPath(this.state.validate, keyPath, true);
    this.setState({ form, validate });
    this.debug.debug(
      `setFormValue(${keyPath}) -> after`,
      value,
      cloneDeep(form),
    );
    this.notifyChangeHandlers(keyPath, value, oldVal);
  };

  addFormValue = (keyPath, value) => {
    const { form } = this.state;

    this.debug.debug(`addFormValue(${keyPath})`, value);
    let a = getPath(form, keyPath);
    if (!isArray(a)) {
      a = [];
    }
    a.push(value);
    this.setFormValue(keyPath, a);
  };

  removeFormValue = (keyPath, predicate) => {
    this.debug.debug(`removeFormValue(${keyPath})`, predicate);

    if (!isFunction(predicate)) {
      predicate = getUniqueIdPredicate(predicate);
    }

    const a = getPath(this.state.form, keyPath);

    if (isNil(a)) return;

    if (!isArray(a)) {
      this.debug.throw('Push() error: type mismatch', keyPath, predicate);
    }

    remove(a, predicate);
    this.setFormValue(keyPath, a);
  };

  updateFormValue = (keyPath, predicate, value, reset = false) => {
    this.debug.debug(`updateFormValue(${keyPath})`, predicate, value, reset);

    if (!isFunction(predicate)) {
      predicate = getUniqueIdPredicate(predicate);
    }

    const a = getPath(this.state.form, keyPath);

    if (isNil(a)) return;

    if (!isArray(a)) {
      this.debug.throw('Push() error: type mismatch', keyPath, predicate);
    }

    const matches = a.filter(predicate);
    for (const match of matches) {
      if (reset) {
        for (const key of keys(match)) {
          if (match.hasOwnProperty(key)) {
            delete match[key];
          }
        }
      }
      merge(match, value);
    }
    this.setFormValue(keyPath, a);
  };

  handleCheckboxChange = (e) => {
    const keyPath = e.target.name;
    const value = e.target.checked;
    this.debug.debug('handleCheckboxChange', e, keyPath, value);

    this.setFormValue(keyPath, keyPath === 'no_switch_lag' ? !value : value);
  };

  handleChange = (e) => {
    const keyPath = e.target.id;
    const { value } = e.target;
    this.setFormValue(keyPath, value);
  };

  handleChange2 = (keyPath, value) => {
    this.setFormValue(keyPath, value);
  };

  // change notification for simple fields
  notifyChangeHandlers = (keyPath, value, oldVal) => {
    const colMeta = getPath(this.state.colMeta, keyPath);
    if (colMeta === undefined) {
      return;
    }
    if (isFunction(colMeta.FireOnChange)) {
      colMeta.FireOnChange(value, oldVal);
    }
  };

  // change notification for composite inputs (e.g. input table) that multiplex
  // notifications for table input columns so they can handle e.g. sideeffects of
  // dropdown selection (e.g. putting additional values into the inputs of the object to be posted)
  notifyInputTableChangeHandlers = (
    baseKeyPath,
    subKeyPath,
    row,
    rawRowData,
    value,
    oldVal,
  ) => {
    const colMeta = getPath(this.state.colMeta, baseKeyPath);
    if (colMeta === undefined) {
      this.debug.log(
        'notifyInputTableChangeHandlers: no colMeta for',
        baseKeyPath,
        subKeyPath,
        row,
        rawRowData,
        value,
        oldVal,
      );
      return;
    }
    this.debug.log(
      'notifyInputTableChangeHandlers: colMeta.FireOnChange',
      colMeta,
      baseKeyPath,
      subKeyPath,
      row,
      rawRowData,
      value,
      oldVal,
    );
    colMeta.FireOnChange(subKeyPath, row, rawRowData, value, oldVal);
  };

  notifyInputTableInitHandlers = (keyPath, json) => {
    const colMeta = getPath(this.state.colMeta, keyPath);
    if (colMeta === undefined) {
      return;
    }

    colMeta.FireOnInit(json);
  };

  keyDown = (e) => {
    // if (e.keyCode === 27) this.cancelScreen() //esc key
    if (e.keyCode !== 13) return; // enter key
    if (e.shiftKey) return; // shift enter

    this.debug.log('keyDown', e.keyCode, e);

    this.submit(e);

    e.preventDefault();
    return false;
  };

  isValid = () => {
    let ok = true;
    let { validate } = this.state;
    for (const col of this.meta.items) {
      if (col.isInput) {
        validate = setPath(validate, col.keyPath, true);
        const result = col.IsValid(getPath(this.state.form, col.keyPath));
        if (!result.ok) {
          ok = false;
        }
      }
    }
    if (!ok) {
      this.setState({ validate });
      return false;
    }

    return true;
  };

  getFieldPrefix(col) {
    if (!isEmpty(col.prefixKeyPath)) {
      return get(this.state.form, col.prefixKeyPath, '');
    }
    return null;
  }

  // TODO: fix tab order, onKeyDown firing enter twice? not sure why maybe with bump to new GUI rev it's binding event
  //      handler twice, moving off deprecated Input component to new FieldGroup control may fix this, going to punt for now
  //      also want to get first field selected by default and get tab order working correctly
  newFormInput = (col, validationResult, extraProps = {}) => {
    let value = get(this.state.form, col.keyPath);

    if (isFunction(col.fVisible)) {
      if (!col.fVisible()) {
        return null;
      }
    }

    if (isNil(value)) {
      value = '';
    }

    if (col.autoFocus) {
      extraProps = {
        ...extraProps,
        autoFocus: true,
      };
    }

    const ds = this.state.data[col.keyPath];

    const inputStyle = { style: {} };
    if (col.width) {
      inputStyle.style.minWidth = col.width;
      inputStyle.style.maxWidth = col.width;
    }
    if (col.minWidth) {
      inputStyle.style.minWidth = col.minWidth;
    }
    if (col.maxWidth) {
      inputStyle.style.maxWidth = col.maxWidth;
    }

    // TODO
    const bsSize = {};
    if (col.smallSize) {
      bsSize.bsSize = 'small';
    }
    if (col.largeSize) {
      bsSize.bsSize = 'large';
    }

    if (col.fieldXform != null) {
      value = col.fieldXform(value);
    }

    if (col.modXform != null) {
      value = col.modXform(this.state.form);
    }
    switch (col.type) {
      case mb.FieldTypeCustom:
        return (
          <col.customComponent
            col={col}
            key={col.keyPath}
            value={value}
            data={this.state.form}
            onChange={this.handleChange}
            validationResult={validationResult}
            {...extraProps}
          />
        );
      case mb.FieldTypeHidden:
        return (
          <FormHidden
            col={col}
            key={col.keyPath}
            validationResult={validationResult}
          />
        );

      case mb.FieldTypeLabel:
        return (
          <FormStaticText
            col={col}
            value={value}
            key={col.keyPath}
            validationResult={validationResult}
          />
        );

      case mb.FieldTypeText:
        return (
          <FormText
            col={col}
            value={value}
            key={col.keyPath}
            prefix={this.getFieldPrefix(col)}
            validationResult={validationResult}
            onChange={this.handleChange}
            onKeyDown={this.keyDown}
            maxLength={col.maxLength}
            {...extraProps}
          />
        );

      case mb.FieldTypeCheckBox:
        return (
          <FormCheckBox
            col={col}
            value={value}
            key={col.keyPath}
            validationResult={validationResult}
            onChange={this.handleCheckboxChange}
            onKeyDown={this.keyDown}
            {...extraProps}
          />
        );

      case mb.FieldTypePassword:
        return (
          <FormPassword
            col={col}
            value={value}
            key={col.keyPath}
            validationResult={validationResult}
            onChange={this.handleChange}
            {...extraProps}
          />
        );

      case mb.FieldTypeTextArea:
        return (
          <FormTextArea
            col={col}
            value={value}
            key={col.keyPath}
            validationResult={validationResult}
            onChange={this.handleChange}
            {...extraProps}
          />
        );

      case mb.FieldTypeDropDown:
        return (
          <FormDropDown
            col={col}
            value={value}
            key={col.keyPath}
            data={(ds && ds.data) || []}
            onChange={this.handleChange2}
            validationResult={validationResult}
            clear={col.optional}
            {...extraProps}
          />
        );

      case mb.FieldTypeCountryDropDown:
        return (
          <FormCountryDropDown
            col={col}
            value={value}
            key={col.keyPath}
            onChange={this.handleChange2}
            validationResult={validationResult}
            clear={col.optional}
            {...extraProps}
          />
        );

      case mb.FieldTypeRadioGroup:
        // no data
        if (ds === undefined) {
          return (
            <FormStaticText
              col={col}
              value='No Data'
              key={col.keyPath}
              validationResult={validationResult}
            />
          );
        }
        return (
          <FormRadioGroupWithTips
            col={col}
            value={value}
            key={col.keyPath}
            data={ds.data}
            onChange={this.handleChange2}
            validationResult={validationResult}
            {...extraProps}
          />
        );

      case mb.FieldTypeMultiSelect:
        if (ds === undefined) {
          return (
            <FormStaticText
              col={col}
              value='No Data'
              key={col.keyPath}
              validationResult={validationResult}
            />
          );
        }

        return (
          <FormMultiDropDown
            col={col}
            value={value}
            key={col.keyPath}
            data={ds.data || []}
            onChange={this.handleChange2}
            validationResult={validationResult}
            {...extraProps}
          />
        );

      case mb.FieldTypeInputTable:
        if (ds === undefined) {
          return <div key={col.keyPath} style={{ gridColumn: '1 / span 2' }} />;
        }
        return (
          <FormInputTable
            col={col}
            ds={ds}
            mode={this.props.mode}
            value={value}
            key={col.keyPath}
            parentView={this}
            meta={this.meta}
            form={this.state.form}
            className={this.props.className}
            onChange2={this.handleChange2}
            notifyInputTableChangeHandlers={this.notifyInputTableChangeHandlers}
            validationResult={validationResult}
            extraProps={extraProps}
          />
        );
      default:
        throw Error(`unknown meta field type:${col.type}`);
    }
  };

  getFormInputs(fields) {
    let isValid = true;
    const { validate } = this.state;

    // TODO: refactor presentation component out
    const inputs = [];
    for (const f of fields) {
      const validateField = getPath(validate, f.keyPath);
      if (f.isInput) {
        let validationResult = new mb.ValidationResult(true, '');
        if (validateField) {
          validationResult = f.IsValid(getPath(this.state.form, f.keyPath));
          if (!validationResult?.ok) {
            isValid = false;
          }
        }
        inputs.push(this.newFormInput(f, validationResult));
      } else if (
        isBoolean(this.props.showAllFields) &&
        this.props.showAllFields === true
      ) {
        // ItemViewContainer will show all fields, regardless of if they are .Input()
        // which is being deprecated in favor of separate create, edit, item, and list meta
        inputs.push(this.newFormInput(f, new mb.ValidationResult(true, '')));
      }
    }

    return { inputs, isValid };
  }

  getPlainForm = (fields) => {
    const inputsResult = this.getFormInputs(fields);
    return (
      <Box fill='vertical'>
        <Box overflow='auto' pad={{ horizontal: this.props.pad || 'none' }}>
          <Form onSubmit={() => false}>{inputsResult.inputs}</Form>
        </Box>
        <Box
          key='buttons'
          direction='row'
          gap='medium'
          flex={false}
          pad={this.props.pad || { top: 'medium' }}
        >
          {this.getButtons(inputsResult.isValid)}
        </Box>
      </Box>
    );
  };

  handleSelect = (activeKey) => {
    this.debug.log(this.state);
    const { expanded } = this.state;
    expanded[activeKey] = !expanded[activeKey];
    this.setState({ expanded });
  };

  getSections = (sections) => {
    let isValid = true;
    const panels = [];
    for (const section of sections) {
      if (!section.IsVisible()) {
        continue;
      }
      let content = null;
      switch (section.type) {
        case mb.SectionTypeFields:
          const inputsResult = this.getFormInputs(section.fields);
          const { inputs } = inputsResult;
          if (!inputsResult.isValid) {
            isValid = false;
          }
          if (inputs.length > 0) {
            // <Panel collapsible expanded={this.state.expanded[section.id]} onSelect={this.handleSelect} eventKey={section.id} header={section.title}>
            content = inputsResult.inputs;
          }
          break;

        case mb.SectionTypeTable:
          if (
            this.props.mode !== mb.MODE_CREATE &&
            this.props.mode !== mb.MODE_EDIT
          ) {
            content = React.createElement(section.tableClass, {
              parentId: this.props.itemId,
            });
          }
          break;

        case mb.SectionTypeCustom:
          break;

        default:
          if (isEmpty(section.fields)) {
            this.debug.warn(`Empty section: ${section.title}`);
          } else {
            this.debug.throw(
              `unknown meta section type for (${section.title}), got type:`,
              section.type,
              'Check the section is not empty.'
            );
          }
      }

      const style = { style: {}, width: {} };
      if (section.width) {
        style.style.minWidth = `${section.width}px`;
        style.style.maxWidth = `${section.width}px`;
        style.width.min = `${section.width}px`;
        style.width.max = `${section.width}px`;
      }
      if (section.minWidth) {
        style.style.minWidth = `${section.minWidth}px`;
        style.width.min = `${section.minWidth}px`;
      }
      if (section.maxWidth) {
        style.style.maxWidth = `${section.maxWidth}px`;
        style.width.max = `${section.maxWidth}px`;
      }

      if (section.noChrome) {
        if (section.grid) {
          panels.push(
            <Box pad='medium' width={style.width} key={panels.length}>
              <ResponsiveContext.Consumer>
                {(size) => (
                  <Grid
                    columns={['auto', '1fr']}
                    gap={{
                      row: size === 'small' ? 'xsmall' : 'small',
                      column: size === 'small' ? 'medium' : 'large',
                    }}
                    fill='horizontal'
                  >
                    {content}
                  </Grid>
                )}
              </ResponsiveContext.Consumer>
            </Box>
          );
        } else {
          panels.push(
            <Box pad='medium' width={style.width} key={panels.length}>
              {content}
            </Box>
          );
        }
      } else {
        let sectionHeader = section.title;

        if (section.IsEditEnabled() && !section.readOnly) {
          const sectionKey = `section-${section.id}`;
          const modal = new ModalControl(this, sectionKey, sectionKey);
          const initForm = section.GetInitEditForm();

          const modalOk = (nextData) => {
            this.debug.debug('modalOk:fireEdit', nextData, initForm);
            // rawRowData is the original value, before the edit
            if (section.FireOnEdit(nextData, initForm) !== false) {
              modal.hide();
            }
            this.debug.debug('modalOk:fireEdit done', nextData, initForm);
          };

          sectionHeader = (
            <Box direction='row' gap='medium'>
              <span>{section.title}</span>
              <IconButton
                icon={<Edit color='brand' />}
                onClick={modal.show}
                tip='Edit status'
              />

              <Modal
                key={modal.key}
                show={modal.visible()}
                autoFocus
                size='medium'
              >
                <Modal.Header>
                  <Modal.Title>{section.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <MetaForm
                    meta={section.editDialogMeta}
                    className={`${this.props.className}::Modal::Edit`}
                    mode={this.props.mode}
                    parentView={this}
                    parentMeta={section.mb}
                    dataSources={this.meta.cloneToStaticDataSources()}
                    formData={initForm}
                    submitLabel='OK'
                    onSubmit={modalOk}
                    cancelLabel='Cancel'
                    onCancel={modal.hide}
                  />
                </Modal.Body>
              </Modal>
            </Box>
          );
        }

        // TODO: INPROGRESS: re-make Panel as expandable or not
        //        collapsible
        //        expanded={this.state.expanded[section.id]}

        if (!isNil(content)) {
          const background =
            typeof section.background === 'function'
              ? section.background(this.state.form)
              : section.background;

          panels.push(
            <Card
              {...style}
              background={background}
              key={panels.length}
              onSelect={this.handleSelect}
              eventKey={section.id}
              elevation={section.elevation}
            >
              {sectionHeader ? <CardHeading title={sectionHeader} /> : null}
              <CardBody
                gap='medium'
                overflow='auto'
                pad={{ left: 'medium', right: 'none', bottom: 'xsmall' }}
              >
                {section.overviewText ? (
                  <Paragraph margin='none' color='text-strong' fill>
                    {section.overviewText}
                  </Paragraph>
                ) : null}
                <ResponsiveContext.Consumer>
                  {(size) => (
                    <Grid
                      columns={['auto', '1fr']}
                      gap={{
                        row: size === 'small' ? 'xsmall' : 'small',
                        column: size === 'small' ? 'medium' : 'large',
                      }}
                    >
                      {content}
                    </Grid>
                  )}
                </ResponsiveContext.Consumer>
                {section.footerNote ? (
                  <Paragraph fill>
                    {typeof section.footerNote === 'function'
                      ? section.footerNote()
                      : section.footerNote}
                  </Paragraph>
                ) : null}
              </CardBody>
            </Card>
          );
        }
      }
    }

    return (
      <Box key='sections'>
        <Box overflow='auto' pad={{ horizontal: this.props.pad || 'none' }}>
          <Form key='form' onSubmit={() => false}>
            <Box gap='xsmall'>{panels}</Box>
          </Form>
        </Box>
        <Box
          key='buttons'
          direction='row'
          gap='medium'
          pad={this.props.pad || { top: 'medium' }}
          flex={false}
        >
          {this.getButtons(isValid)}
        </Box>
      </Box>
    );
  };

  getButtons = (isValid) => {
    const buttons = [];
    if (isFunction(this.props.onSubmit)) {
      buttons.push(
        <Button
          key='submit'
          type='submit'
          onClick={this.submit}
          primary
          disabled={!isValid}
          label={this.state.submitLabel || this.props.submitLabel}
        />,
      );
    }

    if (isFunction(this.props.onSubmit2)) {
      let disabledSubmit2 = false;

      if (isFunction(this.props.disableSubmit2)) {
        if (this.props.disableSubmit2()) {
          disabledSubmit2 = true;
        }
      }

      buttons.push(
        <Button
          key='submit2'
          type='submit'
          onClick={this.submit2}
          disabled={disabledSubmit2}
          label={this.getSubmit2Label()}
          secondary
        />,
      );
    }

    if (isFunction(this.props.onEdit)) {
      buttons.push(
        <Button
          key='edit'
          type='submit'
          onClick={this.onEdit}
          primary
          label={this.state.editLabel}
        />,
      );
    }

    if (isFunction(this.props.onCancel)) {
      buttons.push(
        <Button
          secondary
          key='cancel'
          onClick={this.cancelScreen}
          label={this.getCancelLabel()}
        />,
      );
    }

    return buttons;
  };

  getSubmit2Label() {
    return this.props.submitLabel2 || 'Submit2';
  }

  getCancelLabel() {
    return this.props.cancelLabel || 'Cancel';
  }

  getTabContent(tagsMeta) {
    const tabs = [];
    for (const tabM of tagsMeta) {
      if (tabM.IsVisible()) {
        tabs.push(
          <Tab
            disabled={
              this.props.itemUrl === 'ippools' &&
              this.props.mode === 'edit' &&
              this.state.activeTab !== tabM.id
            }
            key={`tab${tabM.id}`}
            eventKey={tabM.id}
            title={tabM.title}
          >
            {this.getSections(tabM.sections)}
          </Tab>,
        );
      }
    }

    const tabIds = this.meta.tabs.map((t) => t.id);
    let activeTab = this.meta.tabs[0].id; // 1

    if (indexOf(tabIds, this.state.activeTab) !== -1) {
      activeTab = this.state.activeTab;
    }
    return (
      <StyledTabs
        activeIndex={activeTab - 1} // Grommet tab indexes start at 0 but the tab IDs start at 1
        alignControls='start'
        onActive={(key) => {
          const activeTab = key + 1;
          const { activeTab: currentTab } = this.state;

          if (activeTab === currentTab) return;

          this.setState({ activeTab }, () => {
            if (this.props.itemUrl === 'ippools' && activeTab === 2) {
              this.setState({ submitLabel: 'Edit Allocation' });
            }
          });
        }}
      >
        {tabs}
      </StyledTabs>
    );
  }

  render() {
    if (this.meta.tabs.length > 0) {
      return this.getTabContent(this.meta.tabs);
    }
    if (this.meta.sections.length > 0) {
      return this.getSections(this.meta.sections);
    }
    return this.getPlainForm(this.meta.items);
  }
}
