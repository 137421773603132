// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

import React, { useMemo, useState } from 'react';
import { Box, CheckBox } from 'grommet';

import { HeaderCheckBox } from '../../components';

export default function useDataColumns(visibleColumns, data, noSelect) {
  const [selected, setSelected] = useState([]);

  const handleSelect =
    (item) =>
    ({ target: { checked } }) => {
      if (checked) {
        if (item.auto_config?.synced_from_remote !== true) {
          setSelected([...selected, item]);
        }
      } else {
        setSelected(selected.filter(({ id }) => item.id !== id));
      }
    };

  const handleSelectAll = ({ target: { checked } }) => {
    if (checked) {
      const checkedItems = data.filter(
        ({ auto_config }) => auto_config?.synced_from_remote !== true,
      );
      setSelected(checkedItems);
    } else {
      setSelected([]);
    }
  };

  const isIndeterminate = useMemo(
    () => !!selected.length && selected.length < data.length,
    [selected, data],
  );

  const isSelectAllChecked = useMemo(
    () => !!selected.length && selected.length === data.length,
    [selected, data],
  );

  const selectColumn = {
    property: 'selected',
    search: false,
    sortable: false,
    header: (
      <HeaderCheckBox
        key='selectall'
        indeterminate={isIndeterminate}
        checked={isSelectAllChecked}
        onChange={handleSelectAll}
        onClick={(e) => e.stopPropagation()}
      />
    ),
    render: (item) => (
      <Box className='select-checkbox' margin={{ left: '3px' }}>
        <CheckBox
          pad={{ horizontal: 'none' }}
          key={item.id}
          checked={selected.some(({ id }) => id === item.id)}
          onChange={handleSelect(item)}
          onClick={(e) => e.stopPropagation()}
        />
      </Box>
    ),
  };

  return {
    dataColumns: noSelect ? visibleColumns : [selectColumn, ...visibleColumns],
    selected,
    resetSelected: () => setSelected([]),
  };
}
