// (C) Copyright 2016-2024 Hewlett Packard Enterprise Development LP

import PropTypes from 'prop-types';
import React from 'react';
import perm from '../../routes/permissions';
import * as c from '../../routes/consts.js';
import NavButton from './NavButton';
import { useMembershipContext } from '../../utils/context/membershipContext';

const NavItem = ({ itemId, label, sub, url }) => {
  const { activeMembership, role } = useMembershipContext();

  if (perm.authorized(url, c.GET, role)) {
    const linkUrl = itemId
      ? c.setId(c.makeSecItemUrl(url), activeMembership[itemId])
      : c.makeSecUrl(url);

    return <NavButton path={linkUrl} label={label} sub={sub} />;
  }

  return null;
};

NavItem.propTypes = {
  url: PropTypes.string,
  label: PropTypes.string,
  itemId: PropTypes.string,
  sub: PropTypes.bool,
};

export default NavItem;
