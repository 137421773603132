// (C) Copyright 2020-2024 Hewlett Packard Enterprise Development LP

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ResponsiveContext, Box, Text } from 'grommet';
import FormLabel from './FormLabel';

const FormReadOnlyField = ({ label, children }) => {
  const size = useContext(ResponsiveContext);

  // In narrow mode display the label above with the same style as FormField
  // Otherwise put the label in the left column and use the window width
  return size === 'small' ? (
    <Box style={{ gridColumn: '1 / span 2' }} pad={{ top: 'small' }}>
      <Text size='xsmall'>{label}</Text>
      {children}
    </Box>
  ) : (
    <>
      <FormLabel>{label}</FormLabel>
      {children}
    </>
  );
};

FormReadOnlyField.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node,
};
export default FormReadOnlyField;
