// (C) Copyright 2023-2024 Hewlett Packard Enterprise Development LP

import React from 'react';

import { URL_STORAGE_SYSTEMS } from '../../routes/consts';
import ListPageWithNav from '../../containers/generic/ListPageWithNav';
import { useGetStorageSystems } from '../../services/storageSystems';
import { NAV_STORAGE } from '../../components/HybridNav/consts.js';

export const StorageSystemsListView = () => (
  <ListPageWithNav
    navSection={NAV_STORAGE}
    actions={{}}
    noSelect
    path={URL_STORAGE_SYSTEMS}
    sort={{
      property: 'name',
      direction: 'asc',
    }}
    title='Storage systems'
    useGetData={useGetStorageSystems}
  />
);
